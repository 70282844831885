import { Reducer } from 'redux';
import { produce } from 'immer';
import { CustomersActionTypes, CustomersActions, CustomersState } from './types';
import { isNotNull } from 'pages/BillingHistory';

const initialState: CustomersState = {
  customersCount: 0,
  customers: [],
  pagination: {
    page: isNotNull(sessionStorage.getItem('accounts__page')) ? Number(sessionStorage.getItem('accounts__page')) : 0,
    count: 25,
    startRecord: 1,
    endRecord: 25,
  },
  search: '',
  accountType: 'all',
};

const customersReducer: Reducer<CustomersState, CustomersActions> = (state = initialState, action): CustomersState => {
  return produce(state, (draft: CustomersState) => {
    switch (action.type) {
      case CustomersActionTypes.CUSTOMERS_SET_CUSTOMERS:
        draft.customers = action.payload;
        return;

      case CustomersActionTypes.SET_ACCOUNT_TYPE:
        draft.accountType = action.payload;
        return;

      case CustomersActionTypes.CUSTOMERS_SET_CUSTOMERS_COUNT:
        draft.customersCount = action.payload;
        return;

      case CustomersActionTypes.CUSTOMERS_SET_COUNT:
        draft.pagination.count = action.payload;
        draft.pagination.page = 0;
        return;

      case CustomersActionTypes.CUSTOMERS_SET_PAGE:
        draft.pagination.page = action.payload;
        return;

      case CustomersActionTypes.CUSTOMERS_SET_SHOWN:
        draft.pagination.startRecord = action.payload.startRecord;
        draft.pagination.endRecord = action.payload.endRecord;
        return;

      case CustomersActionTypes.CUSTOMERS_SET_SEARCH:
        draft.search = action.payload;
        return;

      default:
        return state;
    }
  });
};

export default customersReducer;
