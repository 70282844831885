import React, {
  ChangeEvent,
  FocusEvent,
  ForwardRefRenderFunction,
  ReactElement,
  ReactNode,
  SyntheticEvent,
  forwardRef,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useStyles } from './styles';
import { Icon } from 'components/UI/Icon';
import { useTranslation } from 'react-i18next';
import Tooltip from '@material-ui/core/Tooltip';
import cn from 'classnames';
import { IconButton } from '@material-ui/core';

interface Props {
  labelKey?: string;
  defaultValue?: string;
  name: string;
  error?: any;
  helperText?: string;
  children?: ReactNode;
  fullWidth?: boolean;
  type?: 'text' | 'password' | 'search' | 'number' | 'tooltip';
  tooltip?: any;
  tooltipHover?: {
    text: string;
    isShow: boolean;
  };
  focused?: boolean;
  className?: string;
  placeholder?: string;
  readOnly?: boolean;
  onKeyPress?: any;
  disabled?: boolean;
  min?: number | string;
  max?: number | string;
  required?: boolean;
  passwordNone?: boolean;
  maxLength?: number;
  minLength?: number;
  pattern?: string;
  icon?: React.ReactElement;
  optional?: boolean;
  onKeyDown?: (event: SyntheticEvent) => any;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => unknown;
  onBlur?: (event: FocusEvent) => unknown;
  handleChange?: (event: ChangeEvent<HTMLInputElement>) => unknown;
  hasNumberControls?: boolean;
}

/**
 * Custom text/password input element with multilingual label, show/hide password button, error display
 * @param {string} [labelKey] - key for react-i18next translation
 * @param {string} [defaultValue] - input's default value
 * @param {string} [error] - displaying if exist
 * @param {string} name - input's name
 * @param {boolean} [fullWidth] - if true sets 100% width to input outer container
 * @param {'text' | 'password'} [type="text"] - input's type
 * @param {boolean} [focused] - sets focus on input
 * @param {string} [className] -adds styles to outer div tag
 * @param {string} [placeholder] - input's placeholder
 * @param {boolean} [readOnly] -
 * @param {boolean} [onKeyPress] -
 * @param {boolean} [disabled] -
 */
const InputText: ForwardRefRenderFunction<HTMLInputElement, Props> = (
  {
    labelKey,
    defaultValue,
    error,
    name,
    fullWidth,
    type = 'text',
    focused,
    className,
    placeholder,
    readOnly,
    onKeyPress,
    tooltip,
    onKeyDown,
    disabled,
    helperText,
    min,
    max,
    required,
    passwordNone,
    maxLength,
    minLength,
    pattern,
    icon,
    onBlur,
    onChange,
    handleChange,
    optional,
    tooltipHover,
    hasNumberControls,
  },
  ref,
): ReactElement => {
  // Translation
  const { t } = useTranslation();

  // Styling
  const styles = useStyles();
  const containerClasses = [styles.container, fullWidth ? styles.fullWidth : '', className].join(' ');
  const inputClasses = [styles.input, type === 'number' ? styles.number : ''].join(' ');
  const [wrapperClasses, setWrapperClasses] = useState(!!helperText ? styles.wrapperBorderNone : '');
  const [labelClasses, setLabelClasses] = useState(styles.label);
  const [showPassword, setShowPassword] = useState(false);
  const inputElement = useRef(null as unknown as HTMLInputElement);

  const inputType = useMemo(
    () => (type === 'password' ? (!showPassword ? 'password' : 'text') : type),
    [showPassword, type],
  );

  const hasErrorCN = useMemo(() => (error ? styles.errorInputWrapper : ''), [error]);

  const handleFocus = useCallback((): void => {
    setWrapperClasses(
      !!helperText ? [styles.wrapperBorderNone, hasErrorCN].join(' ') : [styles.focused, hasErrorCN].join(' '),
    );
    setLabelClasses([styles.label, styles.focusedLabel].join(' '));
  }, [helperText, styles.focused, styles.focusedLabel, styles.label, styles.wrapperBorderNone, hasErrorCN]);

  const handleBlur = useCallback(
    (event: FocusEvent): void => {
      setWrapperClasses(!!helperText ? [styles.wrapperBorderNone, hasErrorCN].join(' ') : [hasErrorCN].join(' '));
      setLabelClasses(styles.label);
      onBlur?.(event);
    },
    [helperText, onBlur, styles.label, styles.wrapperBorderNone, hasErrorCN],
  );

  const focusInput = useCallback((): void => {
    if (!!inputElement && !!inputElement.current) {
      inputElement.current.focus();
    }
  }, []);

  const handleTogglePassword = (e: any): void => {
    e.preventDefault();
    setShowPassword((showPassword) => !showPassword);
  };

  const changeHandler = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      onChange?.(event);
      handleChange?.(event);
    },
    [onChange, handleChange],
  );

  // Focus input if focused set
  useEffect(() => {
    focused && focusInput();
  }, [focusInput, focused, name]);

  useEffect(() => {
    if (!!error) {
      setWrapperClasses((prev) => [styles.errorInputWrapper, prev].join(' '));
    } else {
      setWrapperClasses((prev) =>
        prev
          .split(' ')
          .filter((className) => className !== styles.errorInputWrapper)
          .join(' '),
      );
    }
  }, [error]);

  const maxLengthProps = maxLength ? { maxLength } : {};
  const minLengthProps = minLength ? { minLength } : {};
  const patternProps = pattern ? { pattern } : {};
  const hasMin = !!min && !!(min || +min === 0);
  const hasMax = !!max && !!(max || +max === 0);
  const minProp = hasMin ? { min } : {};
  const maxProp = hasMax ? { max } : {};

  const onDecrease = () => {
    inputElement.current?.stepDown();
  };
  const onIncrease = () => {
    inputElement.current?.stepUp();
  };

  const renderInput = (): ReactElement => {
    return !readOnly ? (
      <div className={cn(styles.inputWrapper, wrapperClasses, disabled ? styles.disabledWrapper : '', 'inputWrapper')}>
        {hasNumberControls ? (
          <IconButton className={styles.noMargin} onClick={onDecrease}>
            <Icon name="decrease" />
          </IconButton>
        ) : null}
        <input
          {...maxLengthProps}
          {...minLengthProps}
          {...patternProps}
          onChange={changeHandler}
          readOnly={readOnly}
          disabled={disabled}
          {...(inputType === 'password' && passwordNone ? { id: 'password' } : {})}
          type={inputType === 'password' && passwordNone ? 'text' : inputType}
          className={!!helperText ? [inputClasses, styles.wrapperInput].join(' ') : inputClasses}
          ref={(element: HTMLInputElement): void => {
            if (ref && typeof ref === 'function') {
              ref(element);
            } else if (ref && ref.current) {
              ref.current = element;
            }
            inputElement.current = element;
          }}
          name={name}
          defaultValue={defaultValue}
          onFocus={handleFocus}
          onBlur={handleBlur}
          {...minProp}
          {...maxProp}
          placeholder={!!placeholder ? t(placeholder) : undefined}
          onKeyPress={
            !onKeyPress
              ? type === 'number'
                ? (event: React.KeyboardEvent): void => {
                    if (
                      event.key.length === 1 &&
                      /\D/.test(event.key) &&
                      ((hasMin && min! >= 0) || event.key !== '-')
                    ) {
                      event.preventDefault();
                    }
                  }
                : undefined
              : (event: React.KeyboardEvent): void => {
                  onKeyPress(event);
                }
          }
          onKeyDown={(e: any) => {
            if (onKeyDown) {
              onKeyDown(e);
              return;
            }
            const keycode = e.keyCode || e.which;
            if (keycode == '13') {
              e.preventDefault();
            }
          }}
          autoComplete={type === 'password' ? 'off' : ''}
          required={required}
        />
        {hasNumberControls ? (
          <IconButton className={styles.noMargin} onClick={onIncrease}>
            <Icon name="increase" />
          </IconButton>
        ) : null}
        {icon}
        {!!helperText && (
          <label className={!!helperText ? [styles.label, styles.wrapperLabel].join(' ') : styles.label}>
            {t(helperText)}
          </label>
        )}
        {type === 'tooltip' && (disabled ? <div className={styles.tooltipDisabled}>{tooltip}</div> : tooltip)}
        {type === 'password' && (
          <button className={styles.adornmentButton} onClick={handleTogglePassword}>
            <Icon name={showPassword ? 'eye' : 'eye-off'} color="#9698A0" />
          </button>
        )}
        {type === 'search' && (
          <button className={styles.adornmentButton} onClick={handleTogglePassword}>
            <Icon name="search" />
          </button>
        )}
        {!!error && (
          <div className={cn(styles.error, 'error')} onClick={focusInput}>
            {typeof error === 'function' ? error() : t(error)}
          </div>
        )}
      </div>
    ) : (
      <div className={cn(styles.inputWrapper, wrapperClasses, disabled ? styles.disabledWrapper : '', 'inputWrapper')}>
        <input
          {...maxLengthProps}
          {...minLengthProps}
          {...patternProps}
          disabled={disabled}
          className={!!helperText ? [inputClasses, styles.wrapperInput].join(' ') : inputClasses}
          name={name}
          defaultValue={defaultValue}
          placeholder={!!placeholder ? t(placeholder) : undefined}
        />
      </div>
    );
  };

  return (
    <div className={containerClasses}>
      {labelKey ? (
        <label className={labelClasses}>
          {tooltipHover?.isShow ? (
            <Tooltip title={tooltipHover?.text} placement="top">
              <span className={styles.labelBold}>{t(labelKey)}</span>
            </Tooltip>
          ) : (
            <span className={styles.inputTitle}>{t(labelKey)}</span>
          )}

          {optional && <span> ({t('optional')})</span>}
          {renderInput()}
        </label>
      ) : (
        renderInput()
      )}
    </div>
  );
};

export default forwardRef(InputText);
