import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core';

export const useStyles = makeStyles(({ palette }: Theme) => ({
  inputCodeContainer: {},
  inputCodeWrapper: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    marginBottom: 40,
  },
  alignCenter: {
    alignItems: 'center',
  },
  inputCodeField: {
    '& input': {
      width: '50px',
      height: '66px',
      marginRight: '20px',
      fontWeight: 700,
      fontSize: '20px',
      textAlign: 'center',
      borderWidth: '2px',
      borderStyle: 'solid',
      borderColor: '#E9E9EE',
      color: '#212121',
      borderRadius: '10px',
      background: '#F8F8FA',
      outline: 'none',
      '-moz-appearance': 'textfield',
      '&[value=""]': {
        background: '#FFF',
      },
      '&:focus': {
        background: '#F8F8FA',
      },
      '&:last-child': {
        marginRight: 0,
      },
    },

    '& input::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },

    '& input::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
  },
  inputCodeFieldError: {
    '& input': {
      borderColor: palette.error.main,
    },
  },
  label: {
    fontSize: '0.8125rem',
    fontWeight: 700,
    color: palette.grey['700'],
    marginBottom: '0.8125rem',
  },
  error: {
    marginTop: '0.5rem',
    fontSize: '0.75rem',
    color: palette.error.main,
    padding: '0 0.25rem',
    textAlign: 'center',
    lineHeight: '18px',
  },
}));
