import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { SetupIntent } from '@stripe/stripe-js';
import { getStripeSetupIntent } from 'api';
import React, { useEffect, useMemo } from 'react';
import { useStyles } from '../AdminPricingSettings/styles';
import { useTranslation } from 'react-i18next';
import { Button, InputText } from '../../components/UI';
import cn from 'classnames';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { paymentShortSchema } from '../../utils/validation';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../store';
import { uILoadingFinish, uILoadingStart } from '../../store/ui/actions';
import { customerIsNotConnectedPaymentCardSet, getCardsInfoWithRefresh } from '../../store/customerParameters/actions';
import { CardError } from '../../store/customerParameters/types';
import { showErrorNotification } from 'utils/notifications';

type StripeForm = {
  onCloseModal: (confirm: boolean) => any;
};

type SubmitData = {
  email: string;
  name: string;
};

type FormValues = {
  email: string;
  name: string;
};

const StripeForm = ({ onCloseModal }: StripeForm) => {
  const { t } = useTranslation();

  const stripe = useStripe();
  const elements = useElements();

  // Redux
  const dispatch = useDispatch();

  const styles = useStyles();

  const labels = useMemo(
    () => ({
      name: t('Name on card'),
      email: t('Email'),
    }),
    [t],
  );

  const user: any = useSelector((state: AppState) => state.session.user);

  // Form
  const {
    register,
    formState: { errors },
    setValue,
    handleSubmit,
    reset,
  } = useForm<FormValues>({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    criteriaMode: 'firstError',
    resolver: yupResolver(paymentShortSchema(t), { abortEarly: false }),
  });

  const addNewCard = async ({ email, name }: SubmitData) => {
    const createSetupIntent = async () => {
      const setupData = await getStripeSetupIntent().catch(showErrorNotification);
      const setupIntent: SetupIntent = setupData.data?.intent;
      return setupIntent;
    };

    if (elements == null) return;

    dispatch(uILoadingStart());

    const setupIntent = await createSetupIntent();

    const cardElement = elements.getElement(CardElement);
    if (!stripe || !cardElement || !setupIntent) return;
    if (!setupIntent.client_secret) return;

    const response = await stripe.confirmCardSetup(setupIntent.client_secret, {
      payment_method: {
        card: cardElement,
        billing_details: { email, name },
      },
    });

    if (response?.error?.code === CardError.DECLINED) {
      dispatch(
        customerIsNotConnectedPaymentCardSet({
          error: CardError.DECLINED,
          toggle: true,
        }),
      );
      dispatch(uILoadingFinish());
      onCloseModal(false);
    } else if (response?.error?.code === CardError.INCOMLPLETE_NUMBER) {
      dispatch(
        customerIsNotConnectedPaymentCardSet({
          error: CardError.INCOMLPLETE_NUMBER,
          toggle: true,
        }),
      );
      dispatch(uILoadingFinish());
    } else {
      await dispatch(getCardsInfoWithRefresh());
      onCloseModal(false);
    }
  };

  // Form submit
  const onSubmit = async (data: SubmitData): Promise<any> => {
    await addNewCard(data);
  };

  useEffect(() => {
    if (user) {
      setValue('email', user.email);
    }
  }, [reset, setValue, user]);

  return (
    <div className={cn(styles.stripeContainer, styles['mt10'])}>
      {/*<form className={styles.stripeContainer} onSubmit={addNewCard}>*/}
      <form className={styles.stripe} onSubmit={handleSubmit(onSubmit)}>
        <InputText
          {...register('name')}
          labelKey={labels.name}
          maxLength={22}
          className={styles.mt22}
          error={!!errors[`name`] ? errors[`name`].message : undefined}
        />
        <InputText
          {...register('email')}
          labelKey={labels.email}
          className={styles.mt22}
          maxLength={35}
          error={!!errors[`email`] ? errors[`email`].message : undefined}
        />

        <div className={cn(styles.cardElementWrapper, styles.mt22)}>
          <label>{t('Card')}</label>
          <CardElement className={styles.cardElement} />
        </div>

        <div className={cn(styles.buttonPayment, styles.buttonPaymentLarge, styles['mt30'])}>
          <Button type="submit" labelKey={t('Add Card')} className={cn(styles.saveButton, styles.saveButtonCenter)} />
        </div>
      </form>
    </div>
  );
};

export default StripeForm;
