import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  formHeader: {
    margin: '0 0 16px',
    textAlign: 'center',
    fontSize: '30px',
    lineHeight: '40px',
    color: '#212121',
    fontWeight: 700,
  },
  verifyingForm: {
    '&:focus': {
      outline: 'none',
    },
  },
  successTitle: {
    fontWeight: 400,
    color: '#666666',
    margin: 0,
    textAlign: 'center',
    fontSize: 14,
    lineHeight: '18px',
    marginBottom: '37px',
  },
  submitButton: {
    marginTop: '24px',
  },
  disableButton: {
    background: theme.palette.grey[700],

    '&:hover': {
      background: theme.palette.grey[700],
    },
  },
  resendButtonWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  resendButton: {
    fontSize: '13px',
    lineHeight: '16px',
    fontWeight: 700,
    color: '#212121',
    padding: '10px 16px',
    background: '#E9E9EE',
    borderRadius: 10,
    margin: '0 0 0 auto',
    '&:hover': {
      background: '#E9E9EE',
    },
  },
}));
