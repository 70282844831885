import { Reducer } from 'redux';
import { produce } from 'immer';

import { PurchaseHistoryActionTypes, PurchaseHistoryActions, PurchaseHistoryState } from './types';

const initialState: PurchaseHistoryState = {
  recordsCount: 0,
  records: [],
  pagination: {
    page: 1,
    count: 25,
    startRecord: 1,
    endRecord: 10,
  },
  reference: '',
  sortBy: { columnName: 'reference', order: 'DESC' },
};

const purchaseHistoryReducer: Reducer<PurchaseHistoryState, PurchaseHistoryActions> = (
  state = initialState,
  action,
): PurchaseHistoryState => {
  return produce(state, (draft: PurchaseHistoryState) => {
    switch (action.type) {
      case PurchaseHistoryActionTypes.PH_SET_RECORDS:
        draft.records = action.payload;
        return;

      case PurchaseHistoryActionTypes.PH_SET_RECORDS_COUNT:
        draft.recordsCount = action.payload;
        return;

      case PurchaseHistoryActionTypes.PH_SET_COUNT:
        draft.pagination.count = action.payload;
        draft.pagination.page = 0;
        return;

      case PurchaseHistoryActionTypes.PH_SET_PAGE:
        draft.pagination.page = action.payload;
        return;

      case PurchaseHistoryActionTypes.PH_SET_SHOWN:
        draft.pagination.startRecord = action.payload.startRecord;
        draft.pagination.endRecord = action.payload.endRecord;
        return;

      case PurchaseHistoryActionTypes.PH_SET_SEARCH:
        draft.reference = action.payload;
        return;

      case PurchaseHistoryActionTypes.PH_TOGGLE_SORT_BY:
        if (state.sortBy.columnName === action.payload) {
          if (state.sortBy.order === 'ASC') {
            draft.sortBy.order = 'DESC';
          } else {
            draft.sortBy.order = 'ASC';
          }
        } else {
          draft.sortBy.columnName = action.payload;
          draft.sortBy.order = 'ASC';
        }
        return;

      case PurchaseHistoryActionTypes.PH_SET_SORT_BY:
        draft.sortBy = action.payload;
        return;

      default:
        return state;
    }
  });
};

export default purchaseHistoryReducer;
