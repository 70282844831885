import React, {
  ChangeEvent,
  FocusEvent,
  ForwardRefRenderFunction,
  ReactElement,
  forwardRef,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useStyles } from './styles';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';

interface Props {
  labelKey?: string;
  defaultValue?: string | number;
  fullWidth?: boolean;
  focused?: boolean;
  value?: string | number;
  name?: string;
  options: { value: string | number; label: string }[];
  onChange?: (event: ChangeEvent<HTMLSelectElement>) => unknown;
  onBlur?: (event: FocusEvent) => unknown;
  className?: string;
  hintOptionKey?: string;
  error?: any;
  disabled?: boolean;
  required?: boolean;
  bordered?: boolean;
}

const Select: ForwardRefRenderFunction<HTMLSelectElement, Props> = (
  {
    labelKey,
    onChange,
    onBlur,
    defaultValue,
    fullWidth,
    focused,
    options,
    value,
    name,
    className,
    hintOptionKey,
    error,
    disabled,
    required,
    bordered = true,
  },
  ref,
): ReactElement => {
  // Translation
  const { t } = useTranslation();
  // Styling
  const styles = useStyles();
  const containerClasses = [styles.container, fullWidth ? styles.fullWidth : '', className].join(' ');
  const [wrapperClasses, setWrapperClasses] = useState(
    [bordered ? styles.borderedSelectWrapper : '', styles.inputWrapper].join(' '),
  );
  const [labelClasses, setLabelClasses] = useState(styles.label);

  // Ref
  const inputElement = useRef(null as any);

  // Focusing
  const handleFocus = (): void => {
    setWrapperClasses((prev) => `${prev} ${styles.focused}`);
    setLabelClasses([styles.label, styles.focusedLabel].join(' '));
  };
  const handleBlur = (event: FocusEvent): void => {
    setWrapperClasses((prev) => cn(prev.split(' ').filter((cn) => cn !== styles.focused)));
    setLabelClasses(styles.label);
    onBlur?.(event);
  };
  // Can be called on click at any element to focus input
  const focusInput = (): void => {
    if (!!inputElement && !!inputElement.current) {
      inputElement.current.focus();
    }
  };

  // Focus input if focused set
  useEffect(() => {
    focused && focusInput();
  }, [focused]);

  const renderInput = (): ReactElement => {
    return (
      <div className={wrapperClasses}>
        <select
          className={bordered ? cn(styles.input, styles.bordered, error ? styles.hasError : '') : styles.input}
          name={name}
          defaultValue={hintOptionKey ? '' : defaultValue}
          onFocus={handleFocus}
          onBlur={handleBlur}
          value={value}
          onChange={onChange}
          ref={(element: HTMLSelectElement): void => {
            if (typeof ref === 'function') {
              ref(element);
            } else if (ref?.current) {
              ref.current = element;
            }
            inputElement.current = element;
          }}
          disabled={disabled}
          required={required}
        >
          {hintOptionKey && (
            <option disabled value={''}>
              {t(hintOptionKey)}
            </option>
          )}
          {(options as any[]).map((item, index) => {
            return (
              <option key={index} value={item.value}>
                {t(item.label)}
              </option>
            );
          })}
        </select>
        {!!error && (
          <div className={cn(styles.error, 'inputError')} onClick={focusInput}>
            {t(error)}
          </div>
        )}
      </div>
    );
  };

  return (
    <div className={containerClasses}>
      {labelKey ? (
        <label className={labelClasses}>
          <p className={styles.selectTitle}>{t(labelKey)}</p>
          {renderInput()}
        </label>
      ) : (
        renderInput()
      )}
    </div>
  );
};

export default forwardRef(Select);
