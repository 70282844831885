import { format } from 'date-fns';
import { MonthYearDate } from 'store/adminDashboard/types';
import { getTimezoneOffset } from 'date-fns-tz';
/**
 * Converts date string into object with number of month, year and source date string
 * @param {string} date
 */
export const convertDate = (date: string): MonthYearDate => {
  const month = new Date(date).getMonth();
  const year = new Date(date).getFullYear();
  return { month, year, date };
};

export type DateRange = {
  start: Date | string | number;
  end: Date | string | number;
};

type DateRangeReturn = {
  start: string;
  end: string;
};

export const buildDateRange = (dates: DateRange, useCurrentEndTime?: boolean): DateRangeReturn => {
  const { start, end } = dates;
  const utcStart = new Date(start);
  const utcEnd = new Date(end);
  let endPrecise = '.999';

  utcStart.setUTCHours(0, 0, 0, 0);

  if (!useCurrentEndTime) {
    utcEnd.setUTCHours(23, 59, 59, 0);
  } else {
    endPrecise = '';
  }

  const startISO = utcStart.toISOString();
  const endISO = utcEnd.toISOString();

  return {
    start: startISO.substring(0, startISO.length - 5),
    end: `${endISO.substring(0, endISO.length - 5)}${endPrecise}`,
  };
};

export const buildDateRangeLocal = (dates: DateRange, useCurrentEndTime?: boolean): DateRangeReturn => {
  const start = new Date(dates.start);
  const end = new Date(dates.end);
  let endPrecise = '.999';

  start.setHours(0, 0, 0, 0);

  if (!useCurrentEndTime) {
    end.setHours(23, 59, 59, 999);
  } else {
    endPrecise = '';
  }

  const startISO = start.toISOString();
  const endISO = end.toISOString();

  return {
    start: startISO.substring(0, startISO.length - 5),
    end: `${endISO.substring(0, endISO.length - 5)}${endPrecise}`,
  };
};

/**
 * Calculates the difference between timezone to UTC offset and browser to UTC offset in MINUTES.
 * returned value can be added to current time to emulate time in specific time zone.
 * @param timezone string representing time zone that you want to get difference with
 * @returns number
 */
export const getDiffWithTimezone = (timezone = '', referenceDate: Date): number => {
  const defaultTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const browserOffset = new Date(referenceDate).getTimezoneOffset();
  const timezoneOffset = getTimezoneOffset(timezone ?? defaultTimeZone) / 60 / 1000;
  const isBrowserAheadOfUTC = browserOffset < 0;
  const isTimeZoneAheadOfUTC = timezoneOffset < 0;
  const browserOffsetAbs = Math.abs(browserOffset);
  const timezoneOffsetAbs = Math.abs(timezoneOffset);

  if (isBrowserAheadOfUTC && isTimeZoneAheadOfUTC) return timezoneOffsetAbs - browserOffsetAbs;
  if (!isBrowserAheadOfUTC && !isTimeZoneAheadOfUTC) return -(timezoneOffsetAbs - browserOffsetAbs);
  if (isBrowserAheadOfUTC) return -(browserOffsetAbs + timezoneOffsetAbs);
  if (isTimeZoneAheadOfUTC) return browserOffsetAbs + timezoneOffsetAbs;
  return 0;
};

export const minutesToMiliseconds = (minutes: number): number => minutes * 60 * 1000;

export const getDateDiffInMsWithUTC = (timezone: string): number => {
  return getTimezoneOffset(timezone);
};

// emulate time and date at given timezone
export const getDateAtTimezone = (timezone: string, targetDate: Date): Date =>
  new Date(new Date(targetDate).getTime() + minutesToMiliseconds(getDiffWithTimezone(timezone, new Date(targetDate))));

// convert back time and date at given timezone to browser timezone real time
export const convertDateToTimezone = (timezone: string, targetDate: Date): Date =>
  new Date(new Date(targetDate).getTime() - minutesToMiliseconds(getDiffWithTimezone(timezone, new Date(targetDate))));

export const getDateFormat = (timeFormat = 'DMY'): string =>
  (timeFormat || 'DMY').toLowerCase().split('').join('/').replace('d', 'dd').replace('y', 'yy').replace('m', 'MM');

export const formatWithMinutes = (date: Date | string | number, timeFormat: string): string =>
  date && timeFormat ? `${format(new Date(date), timeFormat)} ${format(new Date(date), 'HH:mm')}` : '';

export const customFormat = (date: Date | string | number, timeFormat: string): string =>
  date && timeFormat ? format(new Date(date), timeFormat) : '';

export const getZeroedDate = (dateToModify: Date): Date => new Date(new Date(dateToModify).setHours(0, 0, 0, 0));

export const getMaximizedDate = (dateToModify: Date): Date =>
  new Date(new Date(dateToModify).setHours(23, 59, 59, 999));
