import { Dispatch } from 'redux';
import { uILoadingFinish, uILoadingStart } from 'store/ui/actions';
import {
  apiGetAdminAccountParameters,
  apiGetAdminBillingSettings,
  apiGetGlobalUserAccountParameters,
  apiPostAdminBillingSettings,
  apiTestSMTP,
  apiUpdateAdminAccountParameters,
} from 'api';
import { NotificationTypes, showErrorNotification, showNotification } from 'utils/notifications';
import {
  AdminBillingSettingsActionTypes,
  AdminData,
  AdminDataWithSomeCompaniesTogglesEnabled,
  AdminParametersActionTypes,
  AdminParametersActions,
  BillingSettings,
} from './types';
import { AxiosResponse } from 'axios';
import { getUser } from '../session/actions';
import { AppState } from 'store';
import { t } from 'i18next';
import { UserRolesEnum } from 'utils/types';

const adPSetAdminData = (adminData: AdminDataWithSomeCompaniesTogglesEnabled): AdminParametersActions => {
  return {
    type: AdminParametersActionTypes.SET_ADMIN_DATA,
    payload: adminData,
  };
};

const adPSetAdminBillingSettings = (billingSettings: any): AdminParametersActions => {
  return {
    type: AdminBillingSettingsActionTypes.SET_ADMIN_BILLING_SETTINGS,
    payload: billingSettings,
  };
};

export const getAdminParameters = () => {
  return async (dispatch: Dispatch, getState: () => AppState): Promise<any> => {
    const isAdmin = getState().session?.user?.role === UserRolesEnum.admin;

    try {
      if (isAdmin) {
        const { data } = await apiGetAdminAccountParameters();
        dispatch(
          adPSetAdminData({
            adminData: data.adminData,
            isSomeCompaniesTogglesEnabled: data.isSomeCompaniesTogglesEnabled,
          }),
        );
      } else {
        await apiGetGlobalUserAccountParameters;
      }
    } catch (e) {
      showErrorNotification(e as any);
    }
  };
};

export const getAdminBillingSettings = () => {
  return async (dispatch: Dispatch): Promise<any> => {
    dispatch(uILoadingStart());

    try {
      const { data } = await apiGetAdminBillingSettings();
      dispatch(adPSetAdminBillingSettings(data));
    } catch (e) {
      showErrorNotification(e as any);
    } finally {
      dispatch(uILoadingFinish());
    }
  };
};

export const setAdminBillingSettings = (billingSettings: BillingSettings) => {
  return async (dispatch: Dispatch): Promise<any> => {
    dispatch(uILoadingStart());
    setTimeout(() => {
      showNotification(NotificationTypes.success, t('Your changes are saved successfully!'));
      dispatch(uILoadingFinish());
    }, 2000);

    try {
      const { data } = await apiPostAdminBillingSettings(billingSettings);
      dispatch(adPSetAdminBillingSettings(data));
    } catch (e) {
      showErrorNotification(e as any);
    } finally {
    }
  };
};

export const updateAdminParameters = (adminData: AdminData) => {
  return async (dispatch: (arg: any) => void): Promise<number> => {
    dispatch(uILoadingStart());
    try {
      const response: AxiosResponse = await apiUpdateAdminAccountParameters(adminData);
      await dispatch(getUser());
      return response.status;
    } catch (e) {
      showErrorNotification(e as any);
    } finally {
      dispatch(uILoadingFinish());
    }
    return 0;
  };
};

export const testSMTP = () => {
  return async (dispatch: (arg: any) => void): Promise<number> => {
    dispatch(uILoadingStart());
    try {
      const response: AxiosResponse = await apiTestSMTP();
      return response.status;
    } catch (e) {
      showErrorNotification(e as any);
    } finally {
      dispatch(uILoadingFinish());
    }
    return 0;
  };
};
