import { lazy } from 'react';

import { OptionsArg, RoleUnion, RoleWithOptions, RouteObj } from './types';

const GTrans = lazy(() => import('pages/GTrans'));
const GlobalUserAccountParameters = lazy(() => import('components/GlobalUserAccountParameters'));
const MonitoringUserAccountParameters = lazy(() => import('pages/MonitoringUserAccountParameters'));
const WorkflowFilesMonitoring = lazy(() => import('pages/WorkflowFilesMonitoring'));
const DiscQuota = lazy(() => import('pages/DiscQuota'));
const ProductSettings = lazy(() => import('pages/ProductSettings'));
const OrganizationAccountParameters = lazy(() => import('components/OrganizationAccountParameters'));
const AccountUsers = lazy(() => import('pages/AccountUsers'));
const Dashboard = lazy(() => import('pages/Dashboard'));
const AdditionalDashboard = lazy(() => import('pages/MapDashboard'));
const Accounts = lazy(() => import('pages/Accounts'));
const AdminAccountParameters = lazy(() => import('pages/AdminAccountParameters'));
const AdminPricingSettings = lazy(() => import('pages/AdminPricingSettings'));
const SystemUsers = lazy(() => import('pages/SystemUsers/SystemUsers'));
const SystemUsersAddEditUserWrapper = lazy(() => import('components/SystemUsersAddEditUserRoute'));
const Color = lazy(() => import('pages/Settings/Color'));
const Language = lazy(() => import('pages/Settings/Language'));
const WorkflowSteps = lazy(() => import('pages/Settings/WorkflowSteps'));
const TableView = lazy(() => import('pages/Settings/TableView'));
const Helper = lazy(() => import('pages/Settings/Help'));
const CustomerAccountParameters = lazy(() => import('pages/CustomerAccountParameters'));
const AccountMonitoringSettings = lazy(() => import('pages/AccountMonitoringSettings'));
const AccountContactSettings = lazy(() => import('pages/AccountContactSettings'));
const AccountUserContactSettings = lazy(() => import('pages/AccountUserContactSettings'));
const CustomerDashboard = lazy(() => import('pages/CustomerDashboard'));
const CustomerTableView = lazy(() => import('pages/CustomerTableView'));
const CustomerHistoryTableView = lazy(() => import('pages/CustomerHistoryTableView'));
const PurchaseHistory = lazy(() => import('pages/PurchaseHistory'));
const PurchaseHistoryWorkflowStep = lazy(() => import('pages/WorkflowSteps'));
const ServersAlert = lazy(() => import('pages/ServersAlert'));
const WorkflowAlert = lazy(() => import('pages/WorkflowAlert'));
const AlertsDashboard = lazy(() => import('pages/AlertsDashboard'));
const ServersDashboard = lazy(() => import('pages/ServersDashboard'));
const Server = lazy(() => import('pages/Server'));
const HardwareAlerts = lazy(() => import('pages/HardwareAlerts'));
const ServerMonitoring = lazy(() => import('pages/ServerMonitoring'));
const CustomerServersStatus = lazy(() => import('pages/CustomerServersStatus'));
// const GlobalVersionControl = lazy(() => import('pages/GlobalVersionControl'));
// const CustomerVersionControl = lazy(() => import('pages/CustomerVersionControl'));
// const SystemAndModules = lazy(() => import('pages/SystemAndModules'));
const SLAReport = lazy(() => import('pages/SLAReport'));
// const SystemAndModulesInstallDates = lazy(() => import('pages/SystemAndModulesInstallDates'));
// const SystemAndModulesExpireDates = lazy(() => import('pages/SystemAndModulesExpireDates'));
// const CustomerInventory = lazy(() => import('pages/CustomerInventory'));
const CustomerWorkflowNotifications = lazy(() => import('pages/CustomerWorkflowNotifications'));
const CustomerAlertNotifications = lazy(() => import('pages/CustomerAlertNotifications'));
const CustomerContractExpiration = lazy(() => import('pages/CustomerContractExpiration'));
const ResourcesUsageReport = lazy(() => import('pages/ResourcesUsageReport'));
const BillingHistory = lazy(() => import('pages/BillingHistory'));
const PurchaseReport = lazy(() => import('pages/PurchaseReport'));
const AppMonitoring = lazy(() => import('pages/AppMonitoring'));

import { PaymentStatus } from '../store/customerParameters/types';
import { AccountTypesEnum, UserRolesEnum } from 'utils/types';

export const routeNames = {
  appMonitoring: '/app-monitoring',
  serversAlert: '/servers-alert',
  serverMonitoring: '/server-monitoring',
  contactSettings: '/contact-settings',
};

/////////////////////////////
// ACCOUNT SETTINGS ROUTES //
/////////////////////////////

const adminAccountParametersRoutes: RouteObj[] = [
  {
    to: 'account',
    labelKey: 'Accounts Settings',
    isInNav: true,
    shouldCollapse: true,
    subRoutes: [
      {
        to: routeNames.contactSettings,
        exact: true,
        labelKey: 'Profile Settings',
        isInNav: true,
        getComponent: () => AdminAccountParameters,
        roles: [],
      },
      {
        to: '/pricing-settings',
        exact: true,
        labelKey: 'Pricing Settings',
        isInNav: true,
        getComponent: () => AdminPricingSettings,
        roles: [
          {
            role: UserRolesEnum.admin,
            permissions: [],
            isStripeEnabled: false,
          },
        ],
      },
    ],
    roles: [UserRolesEnum.admin],
  },
];

const organizationUserAccountParametersRoutes: RouteObj[] = [
  {
    to: 'account',
    labelKey: 'Accounts Settings',
    isInNav: true,
    shouldCollapse: true,
    subRoutes: [
      {
        to: routeNames.contactSettings,
        exact: true,
        labelKey: 'Profile Settings',
        isInNav: true,
        getComponent: () => OrganizationAccountParameters,
        roles: [],
      },
      {
        to: '/product-settings',
        exact: true,
        labelKey: 'Product Settings',
        isInNav: true,
        getComponent: () => ProductSettings,
        roles: [
          {
            role: UserRolesEnum.organizationUser,
            permissionsExpanded: ['finance'],
            permissions: [],
            accountType: AccountTypesEnum.managed,
          },
        ],
      },
    ],
    roles: [UserRolesEnum.organizationUser],
  },
];

const organizationAccountParametersRoutes: RouteObj[] = [
  {
    to: 'account',
    labelKey: 'Accounts Settings',
    isInNav: true,
    shouldCollapse: true,
    subRoutes: [
      {
        to: routeNames.contactSettings,
        exact: true,
        labelKey: 'Profile Settings',
        isInNav: true,
        getComponent: () => OrganizationAccountParameters,
        roles: [],
      },
      {
        to: '/product-settings',
        exact: true,
        labelKey: 'Product Settings',
        isInNav: true,
        getComponent: () => ProductSettings,
        roles: [
          {
            role: UserRolesEnum.organization,
            permissionsExpanded: ['finance'],
            permissions: [],
            accountType: AccountTypesEnum.managed,
          },
        ],
      },
    ],
    roles: [UserRolesEnum.organization],
  },
];

const accountAccountParametersRoutes: RouteObj[] = [
  {
    to: 'account',
    labelKey: 'Accounts Settings',
    isInNav: true,
    shouldCollapse: true,
    subRoutes: [
      {
        to: routeNames.contactSettings,
        exact: true,
        labelKey: 'Profile Settings',
        isInNav: true,
        getComponent: () => AccountContactSettings,
        roles: [],
      },
      {
        to: '/monitoring-settings',
        exact: true,
        labelKey: 'Monitoring Settings',
        isInNav: true,
        getComponent: () => AccountMonitoringSettings,
        roles: [{ role: UserRolesEnum.account, permissions: [], isSubscriptionExpiredPages: true }],
      },
      {
        to: '/product-settings',
        exact: true,
        labelKey: 'Product Settings',
        isInNav: true,
        getComponent: () => ProductSettings,
        roles: [
          {
            role: UserRolesEnum.account,
            permissions: [],
            isInOrganization: true,
            accountType: AccountTypesEnum.managed,
          },
        ],
      },
    ],
    roles: [UserRolesEnum.account],
  },
];

const accountUserAccountParametersRoutes: RouteObj[] = [
  {
    to: 'account',
    labelKey: 'Accounts Settings',
    isInNav: true,
    shouldCollapse: true,
    subRoutes: [
      {
        to: routeNames.contactSettings,
        exact: true,
        labelKey: 'Profile Settings',
        isInNav: true,
        getComponent: () => AccountUserContactSettings,
        roles: [{ role: UserRolesEnum.accountUser, permissions: [], isViewUserWriteAccess: false }],
      },
      {
        to: '/product-settings',
        exact: true,
        labelKey: 'Product Settings',
        isInNav: true,
        getComponent: () => ProductSettings,
        roles: [
          {
            role: UserRolesEnum.accountUser,
            permissions: [],
            isViewUserWriteAccess: false,
            accountType: AccountTypesEnum.managed,
            isViewUserSelfWriteAccess: true,
          },
        ],
      },
    ],
    roles: [UserRolesEnum.accountUser],
  },
];

///////////////////////
// ROOT ADMIN ROUTES //
///////////////////////

const rootAdminRoutes: RouteObj[] = [
  {
    to: 'reports',
    labelKey: 'Reports',
    isInNav: true,
    shouldCollapse: true,
    subRoutes: [
      {
        to: '/dashboard',
        exact: true,
        labelKey: 'Dashboard',
        isInNav: true,
        getComponent: () => Dashboard,
        roles: [{ role: UserRolesEnum.admin, disabledBothWorkflowProductTypes: true, permissions: [] }],

        // roles: [],
      },
      {
        to: '/up-time-report',
        exact: true,
        labelKey: 'Up time report',
        isInNav: true,
        getComponent: () => SLAReport,
        roles: [
          {
            role: UserRolesEnum.admin,
            productType: ['serversMonitoring'],
            permissions: [],
          },
        ],
      },
      {
        to: '/customer-workflow-notifications',
        exact: true,
        labelKey: 'Account workflow notifications',
        isInNav: true,
        getComponent: () => CustomerWorkflowNotifications,
        roles: [{ role: UserRolesEnum.admin, productType: ['workflowMonitoring'], permissions: [] }],
      },
      {
        to: '/customer-server-notifications',
        exact: true,
        labelKey: 'Account server notifications',
        isInNav: true,
        getComponent: () => CustomerAlertNotifications,
        roles: [{ role: UserRolesEnum.admin, productType: ['serversMonitoring'], permissions: [] }],
      },
      {
        to: '/customer-contract-expiration',
        exact: true,
        labelKey: 'Account contract expiration',
        isInNav: true,
        getComponent: () => CustomerContractExpiration,
        roles: [],
      },
      {
        to: '/resources-usage-report',
        exact: true,
        labelKey: 'Resources Usage Report',
        isInNav: true,
        getComponent: () => ResourcesUsageReport,
        roles: [],
      },
      {
        to: '/workflow-files-monitoring',
        exact: true,
        labelKey: 'Workflow Events',
        isInNav: true,
        getComponent: () => WorkflowFilesMonitoring,
        roles: [{ role: UserRolesEnum.admin, permissions: [], productType: ['workflowReports'] }],
      },
      {
        to: '/disc-quota',
        exact: true,
        labelKey: 'Disc Quota',
        isInNav: true,
        getComponent: () => DiscQuota,
        roles: [{ role: UserRolesEnum.admin, permissions: [], productType: ['workflowReports'] }],
      },
    ],
    roles: [UserRolesEnum.admin],
  },
  {
    to: 'alerts',
    labelKey: 'Alerts',
    isInNav: true,
    shouldCollapse: true,
    subRoutes: [
      {
        to: '/map-dashboard',
        exact: true,
        labelKey: 'Map Dashboard',
        isInNav: true,
        getComponent: () => AdditionalDashboard,
        roles: [
          {
            role: UserRolesEnum.admin,
            permissions: [],
            productType: [],
            enabledOnlyServersMonitoring: [],
          },
        ],
      },
      {
        to: '/alerts-map-dashboard',
        exact: false,
        labelKey: 'Alerts Dashboard',
        isInNav: true,
        getComponent: () => AlertsDashboard,
        roles: [],
      },
      {
        to: '/servers-dashboard',
        exact: true,
        labelKey: 'Servers Dashboard',
        isInNav: true,
        getComponent: () => ServersDashboard,
        roles: [],
      },
      {
        to: '/servers-dashboard/server',
        exact: true,
        labelKey: '',
        isInNav: false,
        getComponent: () => Server,
        roles: [],
      },
      {
        to: '/customer-alerts',
        exact: false,
        labelKey: 'Account Alerts',
        isInNav: true,
        getComponent: () => HardwareAlerts,
        roles: [
          {
            role: UserRolesEnum.admin,
            productType: ['workflowMonitoring', 'serversMonitoring'],
            permissions: [],
          },
        ],
      },
      {
        to: routeNames.serversAlert,
        exact: false,
        labelKey: 'Servers Alert History',
        isInNav: true,
        getComponent: () => ServersAlert,
        roles: [
          {
            role: UserRolesEnum.admin,
            productType: ['serversMonitoring'],
            permissions: [],
          },
        ],
      },
      {
        to: '/workflow-alert',
        exact: false,
        labelKey: 'Workflow Alert History',
        isInNav: true,
        getComponent: () => WorkflowAlert,
        roles: [
          {
            role: UserRolesEnum.admin,
            productType: ['workflowMonitoring'],
            permissions: [],
          },
        ],
      },
    ],
    roles: [UserRolesEnum.admin],
  },
  // {
  //   to: 'version-control',
  //   labelKey: 'Version Control',
  //   isInNav: true,
  //   shouldCollapse: true,
  //   subRoutes: [
  //     {
  //       to: '/global-version-control',
  //       exact: true,
  //       labelKey: 'Global Version Control',
  //       isInNav: true,
  //       getComponent: () => GlobalVersionControl,
  //       roles: [],
  //     },
  //     {
  //       to: '/system-and-modules',
  //       exact: true,
  //       labelKey: 'Global products and modules',
  //       isInNav: true,
  //       getComponent: () => SystemAndModules,
  //       roles: [],
  //     },
  //     {
  //       to: '/customer-version-control',
  //       exact: true,
  //       labelKey: 'Account Version Control',
  //       isInNav: true,
  //       getComponent: () => CustomerVersionControl,
  //       roles: [],
  //     },
  //     {
  //       to: '/customer-inventory',
  //       exact: true,
  //       labelKey: 'Account Inventory',
  //       isInNav: true,
  //       getComponent: () => CustomerInventory,
  //       roles: [],
  //     },
  //     {
  //       to: '/system-and-modules-install-dates',
  //       exact: true,
  //       labelKey: 'Products/modules installation dates',
  //       isInNav: true,
  //       getComponent: () => SystemAndModulesInstallDates,
  //       roles: [],
  //     },
  //     {
  //       to: '/system-and-modules-expire-dates',
  //       exact: true,
  //       labelKey: 'Products/modules expiration dates',
  //       isInNav: true,
  //       getComponent: () => SystemAndModulesExpireDates,
  //       roles: [],
  //     },
  //   ],
  //   roles: [{ role: 'admin', productType: ['serversMonitoring'], permissions: [] }],
  // },
  {
    to: 'customers-and-users',
    labelKey: 'Accounts and users',
    isInNav: true,
    shouldCollapse: true,
    subRoutes: [
      {
        to: '/accounts',
        exact: true,
        labelKey: 'Accounts',
        isInNav: true,
        getComponent: () => Accounts,
        roles: [],
      },
      {
        to: '/system-users',
        exact: true,
        labelKey: 'System Users',
        isInNav: true,
        getComponent: () => SystemUsers,
        roles: [],
      },
      {
        to: '/system-users/user',
        exact: true,
        labelKey: '',
        isInNav: false,
        getComponent: () => SystemUsersAddEditUserWrapper,
        roles: [],
      },
    ],
    roles: [UserRolesEnum.admin],
  },
  {
    to: 'billing-report',
    labelKey: 'Billing Report',
    isInNav: true,
    shouldCollapse: true,
    subRoutes: [
      {
        to: '/purchase-report',
        exact: true,
        labelKey: 'Purchase Report',
        isInNav: true,
        getComponent: () => PurchaseReport,

        roles: [
          {
            role: UserRolesEnum.admin,
            productType: ['workflowReports'],
            permissions: [],
          },
        ],
      },
      {
        to: '/billing-history',
        exact: true,
        labelKey: 'Billing History',
        isInNav: true,
        getComponent: () => BillingHistory,
        roles: [
          {
            role: UserRolesEnum.admin,
            permissions: [],
            isStripeEnabled: false,
          },
        ],
      },
    ],
    roles: [UserRolesEnum.admin],
  },
  {
    to: 'settings',
    labelKey: 'Settings',
    isInNav: true,
    shouldCollapse: true,
    subRoutes: [
      {
        to: '/settings/color',
        exact: true,
        labelKey: 'Color settings',
        isInNav: true,
        getComponent: () => Color,
        roles: [],
      },
      {
        to: '/settings/language',
        exact: true,
        labelKey: 'Language',
        isInNav: true,
        getComponent: () => Language,
        roles: [],
      },
      {
        to: '/settings/workflow-steps',
        exact: true,
        labelKey: 'Flow steps',
        isInNav: true,
        getComponent: () => WorkflowSteps,
        roles: [],
      },
      {
        to: '/settings/table-view',
        exact: true,
        labelKey: 'Table View',
        isInNav: true,
        getComponent: () => TableView,
        roles: [],
      },
      {
        to: '/helper',
        exact: true,
        labelKey: 'Help',
        isInNav: true,
        getComponent: () => Helper,
        roles: [],
      },
    ],
    roles: [UserRolesEnum.admin],
  },
];

////////////////////////
// GLOBAL USER ROUTES //
////////////////////////

const globalUserRoutes: RouteObj[] = [
  {
    to: 'reports',
    labelKey: 'Reports',
    isInNav: true,
    shouldCollapse: true,
    subRoutes: [
      {
        to: '/dashboard',
        exact: true,
        labelKey: 'Dashboard',
        isInNav: true,
        getComponent: () => Dashboard,
        // roles: [],
        roles: [{ role: UserRolesEnum.globalUser, disabledBothWorkflowProductTypes: true, permissions: [] }],
      },
      {
        to: '/up-time-report',
        exact: true,
        labelKey: 'Up time report',
        isInNav: true,
        getComponent: () => SLAReport,
        roles: [
          {
            role: UserRolesEnum.globalUser,
            productType: ['serversMonitoring'],
            permissions: [],
          },
        ],
      },
      {
        to: '/customer-workflow-notifications',
        exact: true,
        labelKey: 'Account workflow notifications',
        isInNav: true,
        getComponent: () => CustomerWorkflowNotifications,
        roles: [{ role: UserRolesEnum.globalUser, productType: ['workflowMonitoring'], permissions: [] }],
      },
      {
        to: '/customer-server-notifications',
        exact: true,
        labelKey: 'Account server notifications',
        isInNav: true,
        getComponent: () => CustomerAlertNotifications,
        roles: [{ role: UserRolesEnum.globalUser, productType: ['serversMonitoring'], permissions: [] }],
      },
      {
        to: '/customer-contract-expiration',
        exact: true,
        labelKey: 'Account contract expiration',
        isInNav: true,
        getComponent: () => CustomerContractExpiration,
        roles: [],
      },
      {
        to: '/resources-usage-report',
        exact: true,
        labelKey: 'Resources Usage Report',
        isInNav: true,
        getComponent: () => ResourcesUsageReport,
        roles: [],
      },
      {
        to: '/workflow-files-monitoring',
        exact: true,
        labelKey: 'Workflow Events',
        isInNav: true,
        getComponent: () => WorkflowFilesMonitoring,
        roles: [{ role: UserRolesEnum.globalUser, permissions: [], productType: ['workflowReports'] }],
      },
      {
        to: '/disc-quota',
        exact: true,
        labelKey: 'Disc Quota',
        isInNav: true,
        getComponent: () => DiscQuota,
        roles: [{ role: UserRolesEnum.globalUser, permissions: [], productType: ['workflowReports'] }],
      },
    ],
    roles: [UserRolesEnum.globalUser],
  },
  {
    to: 'alerts',
    labelKey: 'Alerts',
    isInNav: true,
    shouldCollapse: true,
    subRoutes: [
      {
        to: '/map-dashboard',
        exact: true,
        labelKey: 'Map Dashboard',
        isInNav: true,
        getComponent: () => AdditionalDashboard,
        roles: [],
      },
      {
        to: '/alerts-map-dashboard',
        exact: false,
        labelKey: 'Alerts Dashboard',
        isInNav: true,
        getComponent: () => AlertsDashboard,
        roles: [],
      },
      {
        to: '/servers-dashboard',
        exact: true,
        labelKey: 'Servers Dashboard',
        isInNav: true,
        getComponent: () => ServersDashboard,
        roles: [],
      },
      {
        to: '/servers-dashboard/server',
        exact: true,
        labelKey: '',
        isInNav: false,
        getComponent: () => Server,
        roles: [],
      },
      {
        to: '/customer-alerts',
        exact: false,
        labelKey: 'Account Alerts',
        isInNav: true,
        getComponent: () => HardwareAlerts,
        roles: [
          {
            role: UserRolesEnum.globalUser,
            productType: ['workflowMonitoring', 'serversMonitoring'],
            permissions: [],
          },
        ],
      },
      {
        to: routeNames.serversAlert,
        exact: false,
        labelKey: 'Servers Alert History',
        isInNav: true,
        getComponent: () => ServersAlert,
        roles: [
          {
            role: UserRolesEnum.globalUser,
            productType: ['serversMonitoring'],
            permissions: [],
          },
        ],
      },
      {
        to: '/workflow-alert',
        exact: false,
        labelKey: 'Workflow Alert History',
        isInNav: true,
        getComponent: () => WorkflowAlert,
        roles: [
          {
            role: UserRolesEnum.globalUser,
            productType: ['workflowMonitoring'],
            permissions: [],
          },
        ],
      },
    ],
    roles: [UserRolesEnum.globalUser],
  },
  // {
  //   to: 'version-control',
  //   labelKey: 'Version Control',
  //   isInNav: true,
  //   shouldCollapse: true,
  //   subRoutes: [
  //     {
  //       to: '/global-version-control',
  //       exact: true,
  //       labelKey: 'Global Version Control',
  //       isInNav: true,
  //       getComponent: () => GlobalVersionControl,
  //       roles: [{ role: 'globalUser', permissions: ['writeAccess'] }],
  //     },
  //     {
  //       to: '/system-and-modules',
  //       exact: true,
  //       labelKey: 'Global products and modules',
  //       isInNav: true,
  //       getComponent: () => SystemAndModules,
  //       roles: [],
  //     },
  //     {
  //       to: '/customer-version-control',
  //       exact: true,
  //       labelKey: 'Account Version Control',
  //       isInNav: true,
  //       getComponent: () => CustomerVersionControl,
  //       roles: [{ role: 'globalUser', permissions: ['writeAccess'] }],
  //     },
  //     {
  //       to: '/customer-inventory',
  //       exact: true,
  //       labelKey: 'Account Inventory',
  //       isInNav: true,
  //       getComponent: () => CustomerInventory,
  //       roles: [{ role: 'globalUser', permissions: ['writeAccess'] }],
  //     },
  //     {
  //       to: '/system-and-modules-install-dates',
  //       exact: true,
  //       labelKey: 'Products/modules installation date',
  //       isInNav: true,
  //       getComponent: () => SystemAndModulesInstallDates,
  //       roles: [],
  //     },
  //     {
  //       to: '/system-and-modules-expire-dates',
  //       exact: true,
  //       labelKey: 'Products/modules expiration date',
  //       isInNav: true,
  //       getComponent: () => SystemAndModulesExpireDates,
  //       roles: [],
  //     },
  //   ],
  //   roles: [{ role: 'globalUser', productType: ['serversMonitoring'], permissions: [] }],
  // },
  {
    to: 'customers-and-users',
    labelKey: 'Accounts and users',
    isInNav: true,
    shouldCollapse: true,
    subRoutes: [
      {
        to: '/accounts',
        exact: true,
        labelKey: 'Accounts',
        isInNav: true,
        getComponent: () => Accounts,
        roles: [],
      },
      {
        to: '/system-users',
        exact: true,
        labelKey: 'System Users',
        isInNav: true,
        getComponent: () => SystemUsers,
        roles: [{ role: UserRolesEnum.globalUser, permissions: ['writeAccess'] }],
      },
      {
        to: '/system-users/user',
        exact: true,
        labelKey: '',
        isInNav: false,
        getComponent: () => SystemUsersAddEditUserWrapper,
        roles: [],
      },
    ],
    roles: [UserRolesEnum.globalUser],
  },
  {
    to: 'billing-report',
    labelKey: 'Billing Report',
    isInNav: true,
    shouldCollapse: true,
    subRoutes: [
      {
        to: '/purchase-report',
        exact: true,
        labelKey: 'Purchase Report',
        isInNav: true,
        getComponent: () => PurchaseReport,
        roles: [
          {
            role: UserRolesEnum.globalUser,
            productType: ['workflowReports'],
            permissions: [],
          },
        ],
      },
      {
        to: '/billing-history',
        exact: true,
        labelKey: 'Billing History',
        isInNav: true,
        getComponent: () => BillingHistory,
        roles: [
          {
            role: UserRolesEnum.globalUser,
            permissions: [],
            isStripeEnabled: false,
          },
        ],
      },
    ],
    roles: [UserRolesEnum.globalUser],
  },
  {
    to: '/account',
    exact: true,
    labelKey: 'Profile settings',
    isInNav: true,
    getComponent: () => GlobalUserAccountParameters,
    roles: [UserRolesEnum.globalUser],
  },
];

/////////////////////////////
// ORGANIZATION ROUTES //
/////////////////////////////

const organizationRoutes: RouteObj[] = [
  {
    to: 'reports',
    labelKey: 'Reports',
    isInNav: true,
    shouldCollapse: true,
    subRoutes: [
      {
        to: '/dashboard',
        exact: true,
        labelKey: 'Dashboard',
        isInNav: true,
        getComponent: () => Dashboard,
        roles: [
          {
            role: UserRolesEnum.organization,
            permissions: [''],
            permissionsExpanded: ['reports'],
            isSelfAccountOrOrgFirstLog: true,
            disabledBothWorkflowProductTypes: true,
            enabledOnlyServersMonitoring: [AccountTypesEnum.selfServed],
          },
        ],
      },
      {
        to: '/up-time-report',
        exact: true,
        labelKey: 'Up time report',
        isInNav: true,
        getComponent: () => SLAReport,
        roles: [
          {
            role: UserRolesEnum.organization,
            permissionsExpanded: ['reports'],
            productType: ['serversMonitoring'],
            showControl: ['isUptimeAccess'],
            permissions: [],
            isSubscriptionExpiredPages: true,
          },
        ],
      },
      {
        to: '/customer-contract-expiration',
        exact: true,
        labelKey: 'Account contract expiration',
        isInNav: true,
        getComponent: () => CustomerContractExpiration,
        roles: [
          {
            role: UserRolesEnum.organization,
            permissionsExpanded: ['reports'],
            permissions: [],
            isSubscriptionExpiredPages: true,
          },
        ],
      },
      {
        to: '/purchase-report',
        exact: true,
        labelKey: 'Purchase Report',
        isInNav: true,
        getComponent: () => PurchaseReport,
        roles: [
          {
            role: UserRolesEnum.organization,
            permissionsExpanded: ['finance'],
            productType: ['workflowReports'],
            accountType: AccountTypesEnum.selfServed,
            permissions: [],
            isSubscriptionExpiredPages: true,
          },
        ],
      },
      {
        to: '/resources-usage-report',
        exact: true,
        labelKey: 'Resources Usage Report',
        isInNav: true,
        getComponent: () => ResourcesUsageReport,
        roles: [{ role: UserRolesEnum.organization, permissions: [], isSubscriptionExpiredPages: true }],
      },
      {
        to: '/workflow-files-monitoring',
        exact: true,
        labelKey: 'Workflow Events',
        isInNav: true,
        getComponent: () => WorkflowFilesMonitoring,
        roles: [
          {
            role: UserRolesEnum.organization,
            permissions: [],
            productType: ['workflowReports'],
            isSubscriptionExpiredPages: true,
            enabledOnlyServersMonitoring: [AccountTypesEnum.selfServed],
          },
        ],
      },
    ],
    roles: [UserRolesEnum.organization],
  },
  {
    to: 'alerts',
    labelKey: 'Alerts',
    isInNav: true,
    shouldCollapse: true,
    subRoutes: [
      {
        to: '/map-dashboard',
        exact: true,
        labelKey: 'Map Dashboard',
        isInNav: true,
        getComponent: () => AdditionalDashboard,
        roles: [
          {
            role: UserRolesEnum.organization,
            permissionsExpanded: ['reports'],
            permissions: [],
            enabledOnlyServersMonitoring: [AccountTypesEnum.selfServed],
          },
        ],
      },
      {
        to: '/alerts-map-dashboard',
        exact: false,
        labelKey: 'Alerts Dashboard',
        isInNav: true,
        getComponent: () => AlertsDashboard,
        roles: [{ role: UserRolesEnum.organization, permissionsExpanded: ['reports'], permissions: [] }],
      },
      {
        to: '/servers-dashboard',
        exact: true,
        labelKey: 'Servers Dashboard',
        isInNav: true,
        getComponent: () => ServersDashboard,
        roles: [{ role: UserRolesEnum.organization, permissionsExpanded: ['reports'], permissions: [] }],
      },
      {
        to: '/servers-dashboard/server',
        exact: true,
        labelKey: '',
        isInNav: false,
        getComponent: () => Server,
        roles: [],
      },
      {
        to: '/customer-alerts',
        exact: false,
        labelKey: 'Account Alerts',
        isInNav: true,
        getComponent: () => HardwareAlerts,
        roles: [
          {
            role: UserRolesEnum.organization,
            permissionsExpanded: ['reports'],
            productType: ['workflowMonitoring', 'serversMonitoring'],
            permissions: [],
          },
        ],
      },
      {
        to: routeNames.serversAlert,
        exact: false,
        labelKey: 'Servers Alert History',
        isInNav: true,
        getComponent: () => ServersAlert,
        roles: [
          {
            role: UserRolesEnum.organization,
            permissionsExpanded: ['reports'],
            productType: ['serversMonitoring'],
            permissions: [],
          },
        ],
      },
      {
        to: '/workflow-alert',
        exact: false,
        labelKey: 'Workflow Alert History',
        isInNav: true,
        getComponent: () => WorkflowAlert,
        roles: [
          {
            role: UserRolesEnum.organization,
            permissionsExpanded: ['reports'],
            productType: ['workflowMonitoring'],
            permissions: [],
            enabledOnlyServersMonitoring: [AccountTypesEnum.selfServed],
          },
        ],
      },
    ],
    roles: [{ role: UserRolesEnum.organization, isSubscriptionExpiredPages: true, permissions: [] }],
  },
  // {
  //   to: 'version-control',
  //   labelKey: 'Version Control',
  //   isInNav: true,
  //   shouldCollapse: true,
  //   subRoutes: [
  //     {
  //       to: '/system-and-modules',
  //       exact: true,
  //       labelKey: 'Products and modules',
  //       isInNav: true,
  //       getComponent: () => SystemAndModules,
  //       roles: [{ role: 'enterpriseAdmin', permissionsExpanded: ['reports'], permissions: [] }],
  //     },
  //     {
  //       to: '/customer-version-control',
  //       exact: true,
  //       labelKey: 'Account Version Control',
  //       isInNav: true,
  //       getComponent: () => CustomerVersionControl,
  //       roles: [],
  //     },
  //     {
  //       to: '/customer-inventory',
  //       exact: true,
  //       labelKey: 'Account Inventory',
  //       isInNav: true,
  //       getComponent: () => CustomerInventory,
  //       roles: [],
  //     },
  //     {
  //       to: '/system-and-modules-install-dates',
  //       exact: true,
  //       labelKey: 'Products/modules installation date',
  //       isInNav: true,
  //       getComponent: () => SystemAndModulesInstallDates,
  //       roles: [{ role: 'enterpriseAdmin', permissionsExpanded: ['reports'], permissions: [] }],
  //     },
  //     {
  //       to: '/system-and-modules-expire-dates',
  //       exact: true,
  //       labelKey: 'Products/modules expiration date',
  //       isInNav: true,
  //       getComponent: () => SystemAndModulesExpireDates,
  //       roles: [{ role: 'enterpriseAdmin', permissionsExpanded: ['reports'], permissions: [] }],
  //     },
  //   ],
  //   roles: [
  //     {
  //       role: 'enterpriseAdmin',
  //       productType: ['serversMonitoring'],
  //       permissions: [],
  //       isSubscriptionExpiredPages: true,
  //     },
  //   ],
  // },
  {
    to: 'billing-report',
    labelKey: 'Billing Report',
    isInNav: true,
    shouldCollapse: true,
    subRoutes: [
      {
        to: '/billing-history',
        exact: true,
        labelKey: 'Billing History',
        isInNav: true,
        getComponent: () => BillingHistory,
        roles: [
          {
            role: UserRolesEnum.organization,
            permissions: [],
            permissionsExpanded: ['finance'],
            accountType: AccountTypesEnum.managed,
            isStripeEnabled: false,
          },
        ],
      },
    ],
    roles: [UserRolesEnum.organization],
  },
  {
    to: 'customers-and-users',
    labelKey: 'Accounts and users',
    isInNav: true,
    shouldCollapse: true,
    subRoutes: [
      {
        to: '/accounts',
        exact: true,
        labelKey: 'Accounts',
        isInNav: true,
        getComponent: () => Accounts,
        roles: [{ role: UserRolesEnum.organization, permissionsExpanded: ['accounts'], permissions: [] }],
      },
      {
        to: '/system-users',
        exact: true,
        labelKey: 'System Users',
        isInNav: true,
        getComponent: () => SystemUsers,
        roles: [],
      },
      {
        to: '/system-users/user',
        exact: true,
        labelKey: '',
        isInNav: false,
        getComponent: () => SystemUsersAddEditUserWrapper,
        roles: [],
      },
    ],
    roles: [{ role: UserRolesEnum.organization, isSubscriptionExpiredPages: true, permissions: [] }],
  },
];

/////////////////////////////
// ORGANIZATION USER ROUTES //
/////////////////////////////

const organizationUserRoutes: RouteObj[] = [
  {
    to: 'reports',
    labelKey: 'Reports',
    isInNav: true,
    shouldCollapse: true,
    subRoutes: [
      {
        to: '/dashboard',
        exact: true,
        labelKey: 'Dashboard',
        isInNav: true,
        getComponent: () => Dashboard,
        roles: [
          {
            role: UserRolesEnum.organizationUser,
            permissions: [''],
            permissionsExpanded: ['reports'],
            productType: ['serversMonitoring'],
            isSelfAccountOrOrgFirstLog: true,
            disabledBothWorkflowProductTypes: true,
            enabledOnlyServersMonitoring: [AccountTypesEnum.selfServed],
          },
        ],
      },
      {
        to: '/up-time-report',
        exact: true,
        labelKey: 'Up time report',
        isInNav: true,
        getComponent: () => SLAReport,
        roles: [
          {
            role: UserRolesEnum.organizationUser,
            permissionsExpanded: ['reports'],
            productType: ['serversMonitoring'],
            showControl: ['isUptimeAccess'],
            permissions: [],
            isSubscriptionExpiredPages: true,
          },
        ],
      },
      {
        to: '/customer-contract-expiration',
        exact: true,
        labelKey: 'Account contract expiration',
        isInNav: true,
        getComponent: () => CustomerContractExpiration,
        roles: [
          {
            role: UserRolesEnum.organizationUser,
            permissionsExpanded: ['reports'],
            permissions: [],
            isSubscriptionExpiredPages: true,
          },
        ],
      },
      {
        to: '/purchase-report',
        exact: true,
        labelKey: 'Purchase Report',
        isInNav: true,
        getComponent: () => PurchaseReport,
        roles: [
          {
            role: UserRolesEnum.organizationUser,
            permissionsExpanded: ['finance'],
            productType: ['workflowReports'],
            accountType: AccountTypesEnum.selfServed,
            permissions: [],
            isSubscriptionExpiredPages: true,
          },
        ],
      },
      {
        to: '/resources-usage-report',
        exact: true,
        labelKey: 'Resources Usage Report',
        isInNav: true,
        getComponent: () => ResourcesUsageReport,
        roles: [{ role: UserRolesEnum.organizationUser, permissions: [], isSubscriptionExpiredPages: true }],
      },
      {
        to: '/workflow-files-monitoring',
        exact: true,
        labelKey: 'Workflow Events',
        isInNav: true,
        getComponent: () => WorkflowFilesMonitoring,
        roles: [
          {
            role: UserRolesEnum.organizationUser,
            permissions: [],
            productType: ['workflowReports'],
            isSubscriptionExpiredPages: true,
            disabledBothWorkflowProductTypes: true,
          },
        ],
      },
    ],
    roles: [UserRolesEnum.organizationUser],
  },
  {
    to: 'alerts',
    labelKey: 'Alerts',
    isInNav: true,
    shouldCollapse: true,
    subRoutes: [
      {
        to: '/map-dashboard',
        exact: true,
        labelKey: 'Map Dashboard',
        isInNav: true,
        getComponent: () => AdditionalDashboard,
        roles: [
          {
            role: UserRolesEnum.organizationUser,
            permissionsExpanded: ['reports'],
            permissions: [],
            enabledOnlyServersMonitoring: [AccountTypesEnum.selfServed],
          },
        ],
      },
      {
        to: '/alerts-map-dashboard',
        exact: false,
        labelKey: 'Alerts Dashboard',
        isInNav: true,
        getComponent: () => AlertsDashboard,
        roles: [{ role: UserRolesEnum.organizationUser, permissionsExpanded: ['reports'], permissions: [] }],
      },
      {
        to: '/customer-alerts',
        exact: false,
        labelKey: 'Account Alerts',
        isInNav: true,
        getComponent: () => HardwareAlerts,
        roles: [
          {
            role: UserRolesEnum.organizationUser,
            permissionsExpanded: ['reports'],
            productType: ['workflowMonitoring', 'serversMonitoring'],
            permissions: [],
          },
        ],
      },
      {
        to: '/servers-dashboard',
        exact: true,
        labelKey: 'Servers Dashboard',
        isInNav: true,
        getComponent: () => ServersDashboard,
        roles: [
          {
            role: UserRolesEnum.organizationUser,
            permissionsExpanded: ['reports'],
            productType: ['workflowMonitoring', 'serversMonitoring'],
            permissions: [],
          },
        ],
      },
      {
        to: '/servers-dashboard/server',
        exact: true,
        labelKey: '',
        isInNav: false,
        getComponent: () => Server,
        roles: [],
      },
      {
        to: routeNames.serversAlert,
        exact: false,
        labelKey: 'Servers Alert History',
        isInNav: true,
        getComponent: () => ServersAlert,
        roles: [
          {
            role: UserRolesEnum.organizationUser,
            permissionsExpanded: ['reports'],
            productType: ['serversMonitoring'],
            permissions: [],
          },
        ],
      },
      {
        to: '/workflow-alert',
        exact: false,
        labelKey: 'Workflow Alert History',
        isInNav: true,
        getComponent: () => WorkflowAlert,
        roles: [
          {
            role: UserRolesEnum.organizationUser,
            permissionsExpanded: ['reports'],
            productType: ['workflowMonitoring'],
            permissions: [],
            enabledOnlyServersMonitoring: [AccountTypesEnum.selfServed],
          },
        ],
      },
    ],
    roles: [
      {
        role: UserRolesEnum.organizationUser,
        permissions: [],
        isSubscriptionExpiredPages: true,
      },
    ],
  },
  // {
  //   to: 'version-control',
  //   labelKey: 'Version Control',
  //   isInNav: true,
  //   shouldCollapse: true,
  //   subRoutes: [
  //     {
  //       to: '/system-and-modules',
  //       exact: true,
  //       labelKey: 'Products and modules',
  //       isInNav: true,
  //       getComponent: () => SystemAndModules,
  //       roles: [{ role: 'organizationUser', permissionsExpanded: ['reports'], permissions: [] }],
  //     },
  //     {
  //       to: '/customer-version-control',
  //       exact: true,
  //       labelKey: 'Account Version Control',
  //       isInNav: true,
  //       getComponent: () => CustomerVersionControl,
  //       roles: [],
  //     },
  //     {
  //       to: '/customer-inventory',
  //       exact: true,
  //       labelKey: 'Account Inventory',
  //       isInNav: true,
  //       getComponent: () => CustomerInventory,
  //       roles: [],
  //     },
  //     {
  //       to: '/system-and-modules-install-dates',
  //       exact: true,
  //       labelKey: 'Products/modules installation date',
  //       isInNav: true,
  //       getComponent: () => SystemAndModulesInstallDates,
  //       roles: [{ role: 'organizationUser', permissionsExpanded: ['reports'], permissions: [] }],
  //     },
  //     {
  //       to: '/system-and-modules-expire-dates',
  //       exact: true,
  //       labelKey: 'Products/modules expiration date',
  //       isInNav: true,
  //       getComponent: () => SystemAndModulesExpireDates,
  //       roles: [{ role: 'organizationUser', permissionsExpanded: ['reports'], permissions: [] }],
  //     },
  //   ],
  //   roles: [
  //     {
  //       role: 'organizationUser',
  //       productType: ['serversMonitoring'],
  //       permissions: [],
  //       isSubscriptionExpiredPages: true,
  //     },
  //   ],
  // },
  {
    to: 'billing-report',
    labelKey: 'Billing Report',
    isInNav: true,
    shouldCollapse: true,
    subRoutes: [
      {
        to: '/billing-history',
        exact: true,
        labelKey: 'Billing History',
        isInNav: true,
        getComponent: () => BillingHistory,
        roles: [
          {
            role: UserRolesEnum.organizationUser,
            permissions: [],
            permissionsExpanded: ['finance'],
            accountType: AccountTypesEnum.managed,
            isStripeEnabled: false,
          },
        ],
      },
    ],
    roles: [UserRolesEnum.organizationUser],
  },
  {
    to: 'customers-and-users',
    labelKey: 'Accounts and users',
    isInNav: true,
    shouldCollapse: true,
    subRoutes: [
      {
        to: '/accounts',
        exact: true,
        labelKey: 'Accounts',
        isInNav: true,
        getComponent: () => Accounts,
        roles: [{ role: UserRolesEnum.organizationUser, permissionsExpanded: ['accounts'], permissions: [] }],
      },
      {
        to: '/system-users',
        exact: true,
        labelKey: 'System Users',
        isInNav: true,
        getComponent: () => SystemUsers,
        roles: [],
      },
      {
        to: '/system-users/user',
        exact: true,
        labelKey: '',
        isInNav: false,
        getComponent: () => SystemUsersAddEditUserWrapper,
        roles: [],
      },
    ],
    roles: [
      {
        role: UserRolesEnum.organizationUser,
        permissions: [],
        isSubscriptionExpiredPages: true,
      },
    ],
  },
];
/////////////////////////////
// MONITORING USER ROUTES //
/////////////////////////////

const monitoringUserRoutes: RouteObj[] = [
  {
    to: 'reports',
    labelKey: 'Reports',
    isInNav: true,
    shouldCollapse: true,
    subRoutes: [
      {
        to: '/dashboard',
        exact: true,
        labelKey: 'Dashboard',
        isInNav: true,
        getComponent: () => Dashboard,
        roles: [{ role: UserRolesEnum.monitoringUser, disabledBothWorkflowProductTypes: true, permissions: [''] }],
      },
      {
        to: '/up-time-report',
        exact: true,
        labelKey: 'Up time report',
        isInNav: true,
        getComponent: () => SLAReport,
        roles: [
          {
            role: UserRolesEnum.monitoringUser,
            productType: ['serversMonitoring'],
            permissions: [],
          },
        ],
      },
      {
        to: '/workflow-files-monitoring',
        exact: true,
        labelKey: 'Workflow Events',
        isInNav: true,
        getComponent: () => WorkflowFilesMonitoring,
        roles: [{ role: UserRolesEnum.monitoringUser, permissions: [], productType: ['workflowReports'] }],
      },
    ],
    roles: [UserRolesEnum.monitoringUser],
  },
  {
    to: 'alerts',
    labelKey: 'Alerts',
    isInNav: true,
    shouldCollapse: true,
    subRoutes: [
      {
        to: '/map-dashboard',
        exact: true,
        labelKey: 'Map Dashboard',
        isInNav: true,
        getComponent: () => AdditionalDashboard,
        roles: [],
      },
      {
        to: '/alerts-map-dashboard',
        exact: false,
        labelKey: 'Alerts Dashboard',
        isInNav: true,
        getComponent: () => AlertsDashboard,
        roles: [],
      },
      {
        to: '/servers-dashboard',
        exact: true,
        labelKey: 'Servers Dashboard',
        isInNav: true,
        getComponent: () => ServersDashboard,
        roles: [],
      },
      {
        to: '/servers-dashboard/server',
        exact: true,
        labelKey: '',
        isInNav: false,
        getComponent: () => Server,
        roles: [],
      },
      {
        to: '/customer-alerts',
        exact: false,
        labelKey: 'Account Alerts',
        isInNav: true,
        getComponent: () => HardwareAlerts,
        roles: [
          {
            role: UserRolesEnum.monitoringUser,
            productType: ['workflowMonitoring', 'serversMonitoring'],
            permissions: [],
          },
        ],
      },
      {
        to: routeNames.serversAlert,
        exact: false,
        labelKey: 'Servers Alert History',
        isInNav: true,
        getComponent: () => ServersAlert,
        roles: [
          {
            role: UserRolesEnum.monitoringUser,
            productType: ['serversMonitoring'],
            permissions: [],
          },
        ],
      },
      {
        to: '/workflow-alert',
        exact: false,
        labelKey: 'Workflow Alert History',
        isInNav: true,
        getComponent: () => WorkflowAlert,
        roles: [
          {
            role: UserRolesEnum.monitoringUser,
            productType: ['workflowMonitoring'],
            permissions: [],
          },
        ],
      },
    ],
    roles: [UserRolesEnum.monitoringUser],
  },
  // {
  //   to: 'version-control',
  //   labelKey: 'Version Control',
  //   isInNav: true,
  //   shouldCollapse: true,
  //   subRoutes: [
  //     {
  //       to: '/system-and-modules',
  //       exact: true,
  //       labelKey: 'Products and modules',
  //       isInNav: true,
  //       getComponent: () => SystemAndModules,
  //       roles: [],
  //     },
  //     {
  //       to: '/customer-version-control',
  //       exact: true,
  //       labelKey: 'Account Version Control',
  //       isInNav: true,
  //       getComponent: () => CustomerVersionControl,
  //       roles: [],
  //     },
  //     {
  //       to: '/customer-inventory',
  //       exact: true,
  //       labelKey: 'Account Inventory',
  //       isInNav: true,
  //       getComponent: () => CustomerInventory,
  //       roles: [],
  //     },
  //     {
  //       to: '/system-and-modules-install-dates',
  //       exact: true,
  //       labelKey: 'Products/modules installation date',
  //       isInNav: true,
  //       getComponent: () => SystemAndModulesInstallDates,
  //       roles: [],
  //     },
  //     {
  //       to: '/system-and-modules-expire-dates',
  //       exact: true,
  //       labelKey: 'Products/modules expiration date',
  //       isInNav: true,
  //       getComponent: () => SystemAndModulesExpireDates,
  //       roles: [],
  //     },
  //   ],
  //   roles: [{ role: 'monitoringUser', productType: ['serversMonitoring'], permissions: [] }],
  // },
  {
    to: 'customers-and-users',
    labelKey: 'Accounts and users',
    isInNav: true,
    shouldCollapse: true,
    subRoutes: [
      {
        to: '/accounts',
        exact: true,
        labelKey: 'Accounts',
        isInNav: true,
        getComponent: () => Accounts,
        roles: [],
      },
      {
        to: '/system-users',
        exact: true,
        labelKey: 'System Users',
        isInNav: true,
        getComponent: () => SystemUsers,
        roles: [],
      },
      {
        to: '/system-users/user',
        exact: true,
        labelKey: '',
        isInNav: false,
        getComponent: () => SystemUsersAddEditUserWrapper,
        roles: [],
      },
    ],
    roles: [UserRolesEnum.monitoringUser],
  },
  {
    to: '/account',
    exact: true,
    labelKey: 'Profile settings',
    isInNav: true,
    getComponent: () => MonitoringUserAccountParameters,
    roles: [UserRolesEnum.monitoringUser],
  },
];

/////////////////////
// ACCOUNT ROUTES //
/////////////////////

const accountRoutes: RouteObj[] = [
  {
    to: 'customer-workflow',
    labelKey: 'Workflow',
    isInNav: true,
    shouldCollapse: true,
    subRoutes: [
      {
        to: '/dashboard',
        exact: true,
        labelKey: 'Dashboard',
        isInNav: true,
        getComponent: () => CustomerDashboard,
        roles: [
          {
            role: UserRolesEnum.account,
            permissions: [],
            isManagedUserProductsWorkflowDisabled: true,
            isSelfAccountOrOrgFirstLog: true,
            enabledOnlyServersMonitoring: [AccountTypesEnum.selfServed],
          },
        ],
      },
      {
        to: '/workflow-step',
        exact: true,
        labelKey: 'Purchase History',
        isInNav: true,
        getComponent: () => PurchaseHistoryWorkflowStep,
        roles: [
          {
            role: UserRolesEnum.account,
            permissions: [],
            productType: ['workflowReports'],
            showControl: ['isCustomerFinance'],
            accountType: AccountTypesEnum.selfServed,
            isSubscriptionExpiredPages: true,
            enabledOnlyServersMonitoring: [AccountTypesEnum.selfServed],
          },
        ],
      },
      {
        to: '/table',
        exact: true,
        labelKey: 'Table View',
        isInNav: true,
        getComponent: () => CustomerTableView,
        roles: [
          {
            role: UserRolesEnum.account,
            permissions: [],
            productType: ['workflowReports'],
            isSubscriptionExpiredPages: true,
            enabledOnlyServersMonitoring: [AccountTypesEnum.selfServed],
          },
        ],
      },
      {
        to: '/workflow-alert',
        exact: false,
        labelKey: 'Workflow Alert History',
        isInNav: true,
        getComponent: () => WorkflowAlert,
        roles: [
          {
            role: UserRolesEnum.account,
            permissions: [],
            productType: ['workflowMonitoring'],
            isSubscriptionExpiredPages: true,
            enabledOnlyServersMonitoring: [AccountTypesEnum.selfServed],
          },
        ],
      },
      {
        to: '/workflow-files-monitoring',
        exact: true,
        labelKey: 'Workflow Events',
        isInNav: true,
        getComponent: () => WorkflowFilesMonitoring,
        roles: [
          {
            role: UserRolesEnum.account,
            permissions: [],
            productType: ['workflowReports'],
            isSubscriptionExpiredPages: true,
            enabledOnlyServersMonitoring: [AccountTypesEnum.selfServed],
          },
        ],
      },
    ],
    roles: [UserRolesEnum.account],
  },
  {
    to: 'reports',
    labelKey: 'Reports',
    isInNav: true,
    shouldCollapse: true,
    subRoutes: [
      {
        to: '/resources-usage-report',
        exact: true,
        labelKey: 'Resources Usage Report',
        isInNav: true,
        getComponent: () => ResourcesUsageReport,
        roles: [{ role: UserRolesEnum.account, permissions: [], isSubscriptionExpiredPages: true }],
      },
      {
        to: '/up-time-report',
        exact: true,
        labelKey: 'Up time report',
        isInNav: true,
        getComponent: () => SLAReport,
        roles: [
          {
            role: UserRolesEnum.account,
            permissionsExpanded: ['reports'],
            productType: ['serversMonitoring'],
            showControl: ['isUptimeAccess'],
            permissions: [],
            isSubscriptionExpiredPages: true,
          },
        ],
      },
    ],
    roles: [UserRolesEnum.account],
  },
  {
    to: 'servers',
    labelKey: 'Servers',
    isInNav: true,
    shouldCollapse: true,
    subRoutes: [
      {
        to: routeNames.serverMonitoring,
        exact: false,
        labelKey: 'Server Monitoring',
        isInNav: true,
        getComponent: () => ServerMonitoring,
        roles: [
          {
            role: UserRolesEnum.account,
            permissions: [],
            productType: ['serversMonitoring'],
            isSubscriptionExpiredPages: true,
            accountType: AccountTypesEnum.managed,
          },
        ],
      },
      {
        to: '/servers-status',
        exact: true,
        labelKey: 'Servers Status',
        isInNav: true,
        getComponent: () => CustomerServersStatus,
        roles: [
          {
            role: UserRolesEnum.account,
            permissions: [],
            productType: ['serversMonitoring'],
            isSubscriptionExpiredPages: true,
          },
        ],
      },
      {
        to: routeNames.serversAlert,
        exact: false,
        labelKey: 'Servers Alert History',
        isInNav: true,
        getComponent: () => ServersAlert,
        roles: [
          {
            role: UserRolesEnum.account,
            permissions: [],
            productType: ['serversMonitoring'],
            isSubscriptionExpiredPages: true,
          },
        ],
      },
      {
        to: '/servers-dashboard',
        exact: true,
        labelKey: 'Servers Dashboard',
        isInNav: true,
        getComponent: () => ServersDashboard,
        roles: [{ role: UserRolesEnum.account, permissions: [], isSubscriptionExpiredPages: true }],
      },
      {
        to: '/servers-dashboard/server',
        exact: true,
        labelKey: '',
        isInNav: false,
        getComponent: () => Server,
        roles: [],
      },
    ],
    roles: [UserRolesEnum.account],
  },
  {
    to: 'customers-and-users',
    labelKey: 'Accounts and users',
    isInNav: true,
    shouldCollapse: true,
    subRoutes: [
      {
        to: '/system-users',
        exact: true,
        labelKey: 'System Users',
        isInNav: true,
        getComponent: () => SystemUsers,
        roles: [{ role: UserRolesEnum.account, permissions: [], isSubscriptionExpiredPages: true }],
      },
      {
        to: '/system-users/user',
        exact: true,
        labelKey: '',
        isInNav: false,
        getComponent: () => SystemUsersAddEditUserWrapper,
        roles: [{ role: UserRolesEnum.account, permissions: [], isSubscriptionExpiredPages: true }],
      },
    ],
    roles: [UserRolesEnum.account],
  },
  {
    to: 'billing-report',
    labelKey: 'Billing Report',
    isInNav: true,
    shouldCollapse: true,
    subRoutes: [
      {
        to: '/billing-history',
        exact: true,
        labelKey: 'Billing History',
        isInNav: true,
        getComponent: () => BillingHistory,
        roles: [
          {
            role: UserRolesEnum.account,
            permissions: [],
            permissionsExpanded: ['finance'],
            accountType: AccountTypesEnum.managed,
            isStripeEnabled: false,
            isInOrganization: true,
          },
        ],
      },
    ],
    roles: [UserRolesEnum.account],
  },
];

//////////////////////
// ACCOUNT USER ROUTES //
//////////////////////

const accountUserRoutes: RouteObj[] = [
  {
    to: 'customer-workflow',
    labelKey: 'Workflow',
    isInNav: true,
    shouldCollapse: true,
    subRoutes: [
      {
        to: '/dashboard',
        exact: true,
        labelKey: 'Dashboard',
        isInNav: true,
        getComponent: () => CustomerDashboard,
        roles: [
          {
            role: UserRolesEnum.accountUser,
            permissions: [],
            isManagedUserProductsWorkflowDisabled: true,
            enabledOnlyServersMonitoring: [AccountTypesEnum.selfServed],
          },
        ],
      },
      {
        to: '/table',
        exact: true,
        labelKey: 'Table View',
        isInNav: true,
        getComponent: () => CustomerTableView,
        roles: [
          {
            role: UserRolesEnum.accountUser,
            permissions: [],
            productType: ['workflowReports'],
            enabledOnlyServersMonitoring: [AccountTypesEnum.selfServed],
          },
        ],
      },
      {
        to: '/workflow-alert',
        exact: false,
        labelKey: 'Workflow Alert History',
        isInNav: true,
        getComponent: () => WorkflowAlert,
        roles: [
          {
            role: UserRolesEnum.accountUser,
            permissions: [],
            productType: ['workflowMonitoring'],
            enabledOnlyServersMonitoring: [AccountTypesEnum.selfServed],
          },
        ],
      },
      {
        to: '/workflow-step',
        exact: true,
        labelKey: 'Purchase History',
        isInNav: true,
        getComponent: () => PurchaseHistoryWorkflowStep,
        roles: [
          {
            role: UserRolesEnum.accountUser,
            permissions: [],
            accountType: AccountTypesEnum.selfServed,
            permissionsExpanded: ['finance'],
            showControl: ['isCustomerFinance'],
            enabledOnlyServersMonitoring: [AccountTypesEnum.selfServed],
          },
        ],
      },
      {
        to: '/workflow-files-monitoring',
        exact: true,
        labelKey: 'Workflow Events',
        isInNav: true,
        getComponent: () => WorkflowFilesMonitoring,
        roles: [
          {
            role: UserRolesEnum.accountUser,
            permissions: [],
            productType: ['workflowReports'],
            isSubscriptionExpiredPages: true,
            enabledOnlyServersMonitoring: [AccountTypesEnum.selfServed],
          },
        ],
      },
    ],
    roles: [UserRolesEnum.accountUser],
  },
  {
    to: 'reports',
    labelKey: 'Reports',
    isInNav: true,
    shouldCollapse: true,
    subRoutes: [
      {
        to: '/resources-usage-report',
        exact: true,
        labelKey: 'Resources Usage Report',
        isInNav: true,
        getComponent: () => ResourcesUsageReport,
        roles: [{ role: UserRolesEnum.accountUser, permissions: [], isSubscriptionExpiredPages: true }],
      },
    ],
    roles: [UserRolesEnum.accountUser],
  },
  {
    to: 'servers',
    labelKey: 'Servers',
    isInNav: true,
    shouldCollapse: true,
    subRoutes: [
      {
        to: routeNames.serverMonitoring,
        exact: false,
        labelKey: 'Server Monitoring',
        isInNav: true,
        getComponent: () => ServerMonitoring,
        roles: [
          {
            role: UserRolesEnum.accountUser,
            permissions: [],
            productType: ['serversMonitoring'],
            isSubscriptionExpiredPages: true,
            accountType: AccountTypesEnum.managed,
          },
        ],
      },
      {
        to: '/servers-status',
        exact: true,
        labelKey: 'Servers Status',
        isInNav: true,
        getComponent: () => CustomerServersStatus,
        roles: [{ role: UserRolesEnum.accountUser, permissions: [], productType: ['serversMonitoring'] }],
      },
      {
        to: routeNames.serversAlert,
        exact: false,
        labelKey: 'Servers Alert History',
        isInNav: true,
        getComponent: () => ServersAlert,
        roles: [{ role: UserRolesEnum.accountUser, permissions: [], productType: ['serversMonitoring'] }],
      },
      {
        to: '/servers-dashboard',
        exact: true,
        labelKey: 'Servers Dashboard',
        isInNav: true,
        getComponent: () => ServersDashboard,
        roles: [{ role: UserRolesEnum.accountUser, permissions: [], productType: ['serversMonitoring'] }],
      },
      {
        to: '/servers-dashboard/server',
        exact: true,
        labelKey: '',
        isInNav: false,
        getComponent: () => Server,
        roles: [],
      },
    ],
    roles: [UserRolesEnum.accountUser],
  },
  // {
  //   to: 'software',
  //   labelKey: 'Software',
  //   isInNav: true,
  //   shouldCollapse: true,
  //   subRoutes: [
  //     {
  //       to: '/system-and-modules-install-dates',
  //       exact: true,
  //       labelKey: 'Products/modules installation date',
  //       isInNav: true,
  //       getComponent: () => SystemAndModulesInstallDates,
  //       roles: [],
  //     },
  //     {
  //       to: '/system-and-modules-expire-dates',
  //       exact: true,
  //       labelKey: 'Products/modules expiration date',
  //       isInNav: true,
  //       getComponent: () => SystemAndModulesExpireDates,
  //       roles: [],
  //     },
  //     {
  //       to: '/system-and-modules',
  //       exact: true,
  //       labelKey: 'Products and modules',
  //       isInNav: true,
  //       getComponent: () => SystemAndModules,
  //       roles: [],
  //     },
  //   ],
  //   roles: ['viewUser'],
  // },
  {
    to: 'customers-and-users',
    labelKey: 'Accounts and users',
    isInNav: true,
    shouldCollapse: true,
    subRoutes: [
      {
        to: '/system-users',
        exact: true,
        labelKey: 'System Users',
        isInNav: true,
        getComponent: () => SystemUsers,
        roles: [
          {
            role: UserRolesEnum.accountUser,
            permissions: [],
            isSubscriptionExpiredPages: true,
            isViewUserWriteAccess: false,
          },
        ],
      },
      {
        to: '/system-users/user',
        exact: true,
        labelKey: '',
        isInNav: false,
        getComponent: () => SystemUsersAddEditUserWrapper,
        roles: [
          {
            role: UserRolesEnum.accountUser,
            permissions: [],
            isSubscriptionExpiredPages: true,
            isViewUserWriteAccess: false,
          },
        ],
      },
    ],
    roles: [UserRolesEnum.accountUser],
  },
];

//////////////////////////////
// NOT PRESENT IN SIDE MENU //
//////////////////////////////

export const routesHeap: RouteObj[] = [
  ...rootAdminRoutes,
  ...globalUserRoutes,
  ...organizationRoutes,
  ...organizationUserRoutes,
  ...monitoringUserRoutes,
  ...accountRoutes,
  ...accountUserRoutes,
  {
    to: '/table',
    exact: true,
    labelKey: 'Table View',
    getComponent: () => CustomerTableView,
    isInNav: false,
    roles: [UserRolesEnum.admin, UserRolesEnum.globalUser, UserRolesEnum.organization, UserRolesEnum.organizationUser],
  },
  {
    to: '/table-history-view',
    exact: true,
    labelKey: 'Table History View',
    getComponent: () => CustomerHistoryTableView,
    isInNav: false,
    roles: [
      UserRolesEnum.admin,
      UserRolesEnum.globalUser,
      UserRolesEnum.organization,
      UserRolesEnum.account,
      UserRolesEnum.accountUser,
    ],
  },
  {
    to: '/view-users',
    exact: true,
    labelKey: 'Account Users',
    isInNav: false,
    getComponent: () => AccountUsers,
    roles: [UserRolesEnum.globalUser, UserRolesEnum.admin, UserRolesEnum.organization],
  },
  {
    to: '/purchase-history',
    exact: true,
    labelKey: 'Purchase History',
    isInNav: false,
    getComponent: () => PurchaseHistory,
    roles: [
      UserRolesEnum.globalUser,
      UserRolesEnum.account,
      UserRolesEnum.accountUser,
      UserRolesEnum.organization,
      UserRolesEnum.admin,
    ],
  },
  {
    to: routeNames.serverMonitoring,
    exact: false,
    labelKey: 'Server Monitoring',
    isInNav: false,
    getComponent: () => ServerMonitoring,
    roles: [
      UserRolesEnum.globalUser,
      UserRolesEnum.organization,
      UserRolesEnum.admin,
      UserRolesEnum.monitoringUser,
      UserRolesEnum.organizationUser,
    ],
  },
  {
    to: '/workflow-alert',
    exact: false,
    labelKey: 'Workflow Alert',
    isInNav: false,
    getComponent: () => WorkflowAlert,
    roles: [UserRolesEnum.organization, UserRolesEnum.admin, UserRolesEnum.globalUser],
  },
  {
    to: '/customer-account-parameters',
    exact: true,
    labelKey: 'Profile settings',
    isInNav: false,
    getComponent: () => CustomerAccountParameters,
    roles: [UserRolesEnum.admin, UserRolesEnum.globalUser, UserRolesEnum.organization, UserRolesEnum.organizationUser],
  },
  {
    to: '/customer-dashboard',
    exact: true,
    labelKey: 'Account Dashboard',
    isInNav: false,
    getComponent: () => CustomerDashboard,
    roles: [UserRolesEnum.admin, UserRolesEnum.globalUser, UserRolesEnum.organization, UserRolesEnum.organizationUser],
  },
  {
    to: routeNames.appMonitoring,
    exact: true,
    labelKey: 'Application monitoring',
    isInNav: false,
    getComponent: () => AppMonitoring,
    roles: [
      UserRolesEnum.admin,
      UserRolesEnum.globalUser,
      UserRolesEnum.monitoringUser,
      UserRolesEnum.organization,
      UserRolesEnum.organizationUser,
      UserRolesEnum.account,
      UserRolesEnum.accountUser,
    ],
  },
  {
    to: '/g-trans',
    exact: true,
    labelKey: 'Google Translate',
    isInNav: false,
    getComponent: () => GTrans,
    roles: [UserRolesEnum.admin],
  },
];

////////////////////////////////////////////////////

const isRole = (compared: RoleWithOptions, reference: RoleUnion): boolean => {
  if (typeof reference === 'string') {
    return reference === compared.role;
  }

  if (compared.role === reference.role) {
    if (
      reference.permissionsExpanded ||
      reference.productType ||
      reference.isSubscriptionExpiredPages ||
      reference.isInOrganization ||
      reference.showControl ||
      typeof reference.isStripeEnabled === 'boolean' ||
      typeof reference.isSelfAccountOrOrgFirstLog === 'boolean' ||
      reference.accountType ||
      typeof reference.isViewUserWriteAccess === 'boolean' ||
      typeof reference.isViewUserSelfWriteAccess === 'boolean' ||
      typeof reference.isManagedUserProductsWorkflowDisabled === 'boolean' ||
      typeof reference.disabledBothWorkflowProductTypes === 'boolean'
    ) {
      let allRulesList: boolean[] = [];

      if (
        typeof reference.enabledOnlyServersMonitoring === 'object' &&
        (!reference.enabledOnlyServersMonitoring.length ||
          !compared.accountType ||
          reference.enabledOnlyServersMonitoring.includes(compared.accountType)) &&
        compared.enabledOnlyServersMonitoring
      ) {
        allRulesList = [...allRulesList, false];
      }

      if (compared.accountType && reference.accountType && compared.accountType === reference.accountType) {
        allRulesList = [...allRulesList, false];
      }

      if (reference.isInOrganization && compared.isInOrganization) {
        allRulesList = [...allRulesList, false];
      }

      if (reference.disabledBothWorkflowProductTypes && compared.disabledBothWorkflowProductTypes) {
        allRulesList = [...allRulesList, false];
      }

      if (reference.isSelfAccountOrOrgFirstLog && compared.isSelfAccountOrOrgFirstLog) {
        allRulesList = [...allRulesList, false];
      }

      if (reference.isManagedUserProductsWorkflowDisabled && compared.isManagedUserProductsWorkflowDisabled) {
        allRulesList = [...allRulesList, false];
      }

      if (reference.isSubscriptionExpiredPages && compared.isSubscriptionExpiredPages) {
        allRulesList = [...allRulesList, false];
      }

      if (reference.isStripeEnabled === false && compared.isStripeEnabled === false) {
        allRulesList = [...allRulesList, false];
      }

      if (reference.isViewUserWriteAccess === false && compared.isViewUserWriteAccess === false) {
        allRulesList = [...allRulesList, false];
      }

      if (reference.isViewUserSelfWriteAccess === true && compared.isViewUserSelfWriteAccess === true) {
        allRulesList = [...allRulesList, false];
      }

      if (reference.permissionsExpanded?.length > 0) {
        allRulesList = [
          ...allRulesList,
          ...reference.permissionsExpanded?.map((permission: any) => {
            return !!compared.permissionsExpanded?.[permission].isEnabled;
          }),
        ];
      }

      if (reference.productType?.length > 0) {
        allRulesList = [
          ...allRulesList,
          ...reference.productType.map((product: any) => {
            return (
              !!compared.productType.serverProductType?.[product] &&
              !!compared.productType.companyProductType?.[product]
            );
          }),
        ];
      }

      if (reference?.showControl?.filter((item: any) => !compared?.showControl[item]).length > 0) {
        allRulesList = [...allRulesList, false];
      }

      return !allRulesList.filter((rule: boolean) => !rule).length;
    } else {
      return reference.permissions.every((permission) =>
        compared.permissions.some((comparedPermission) => comparedPermission === permission),
      );
    }
  }

  return false;
};

const getClonedRoutes = (): RouteObj[] =>
  [...routesHeap].map((route) => ({
    ...route,
    ...(route.subRoutes ? { subroutes: [...route.subRoutes] } : {}),
  }));

const getAccountSettingsClonedRoutes = (): RouteObj[] =>
  [
    ...accountUserAccountParametersRoutes,
    ...accountAccountParametersRoutes,
    ...adminAccountParametersRoutes,
    ...organizationAccountParametersRoutes,
    ...organizationUserAccountParametersRoutes,
  ].map((route) => ({
    ...route,
    ...(route.subRoutes ? { subroutes: [...route.subRoutes] } : {}),
  }));

export const getRoutes = (comparedRole: RoleWithOptions, options?: OptionsArg): RouteObj[] => {
  const omit = options?.omit || [];
  const pick = options?.pick || [];
  const isOmit = omit?.length;
  const isPick = pick?.length;
  const permissions = options?.permissions || {
    users: {
      isEnabled: true,
      permission: 'read',
    },
    accounts: {
      isEnabled: true,
      permission: 'read',
    },
    reports: {
      isEnabled: true,
      permission: 'read',
    },
    finance: {
      isEnabled: true,
      permission: 'read',
    },
  };
  const productType = options?.productType || {
    companyProductType: { workflowReports: false, serversMonitoring: false, workflowMonitoring: false },
    serverProductType: { workflowReports: false, serversMonitoring: false, workflowMonitoring: false },
  };

  const togglesByOrgUser = options?.togglesByOrgUser || {
    serversMonitoring: true,
    workflowMonitoring: true,
    workflowReports: true,
  };

  const disabledBothWorkflowProductTypes =
    (options?.user?.role === UserRolesEnum.organizationUser &&
      !togglesByOrgUser.workflowMonitoring &&
      !togglesByOrgUser.workflowReports) ||
    ((options?.user?.role === UserRolesEnum.admin ||
      options?.user?.role === UserRolesEnum.organization ||
      options?.user?.role === UserRolesEnum.monitoringUser) &&
      !productType.serverProductType.workflowReports &&
      !productType.serverProductType.workflowMonitoring);

  const accountType = options?.user?.accountType;

  const enabledOnlyServersMonitoring = Boolean(
    (accountType === AccountTypesEnum.selfServed &&
      options?.togglesByOrgUser &&
      !togglesByOrgUser.workflowMonitoring &&
      !togglesByOrgUser.workflowReports &&
      togglesByOrgUser.serversMonitoring) ||
      (((!productType.serverProductType.workflowReports && !productType.serverProductType.workflowMonitoring) ||
        (!productType.companyProductType.workflowReports && !productType.companyProductType.workflowMonitoring)) &&
        productType.serverProductType.serversMonitoring &&
        productType.companyProductType.serversMonitoring),
  );

  const isManagedUserProductsWorkflowDisabled =
    accountType === AccountTypesEnum.managed &&
    (options?.user?.role === UserRolesEnum.accountUser || options?.user?.role === UserRolesEnum.account) &&
    (!(productType?.companyProductType?.workflowMonitoring && productType?.serverProductType?.workflowMonitoring) ||
      !(productType?.companyProductType?.workflowReports && productType?.serverProductType?.workflowReports));

  const showControl = {
    isCustomerFinance: options?.user?.isCustomerFinance,
    isCustomerOperator: options?.user?.isCustomerOperator,
    isCustomerPurchase: options?.user?.isCustomerPurchase,
    isCustomerReport: options?.user?.isCustomerReport,
    isSendCompareVersions: false,
    isSendSystemErrorsMail: false,
    isSendHardwareErrorsMail: options?.user?.isSendHardwareErrorsMail,
    isUptimeAccess: options?.user?.isUptimeAccess,
  };

  const isInOrganization = options?.user?.isInOrganization;

  const isViewUserWriteAccess =
    (accountType === AccountTypesEnum.managed || accountType === AccountTypesEnum.selfServed) &&
    options?.user?.role === UserRolesEnum.accountUser &&
    options?.user?.writeAccess;

  const isWithoutOrganizationFirstLogin =
    !isInOrganization && !Object.values(options?.user?.paymentStatus ?? {}).length;
  const isWithOrganizationFirstLogin =
    options?.user?.role === UserRolesEnum.organization &&
    isInOrganization &&
    !Object.values(options?.user?.paymentStatus ?? {}).length;

  const isSelfAccountOrOrgFirstLog =
    accountType === AccountTypesEnum.selfServed &&
    (isWithoutOrganizationFirstLogin || isWithOrganizationFirstLogin) &&
    (options?.user?.role === UserRolesEnum.account || options?.user?.role === UserRolesEnum.organization);

  const isNotInOrgWithoutSubscription =
    !isInOrganization &&
    ((!options?.user?.paymentStatus?.status && options?.user?.role !== UserRolesEnum.accountUser) ||
      options?.user?.paymentStatus?.status === PaymentStatus.BLOCKED ||
      options?.user?.subscription?.status === 'empty' ||
      (options?.user?.role === UserRolesEnum.accountUser &&
        ((options?.user?.paymentStatusOrganization?.payment !== 'payed' &&
          options?.user?.paymentStatusOrganization?.subscription !== 'active' &&
          options?.user?.paymentStatusOrganization?.payment !== 'failPayment' &&
          options?.user?.paymentStatusOrganization?.subscription !== 'past_due') ||
          (options?.user?.paymentStatusOrganization?.payment === 'payed' &&
            options?.user?.paymentStatusOrganization?.subscription === 'empty') ||
          options?.user?.paymentStatusOrganization?.payment === PaymentStatus.BLOCKED)));

  const isInOrgWithoutSubscription =
    isInOrganization &&
    ((options?.user?.paymentStatusOrganization?.payment !== 'payed' &&
      options?.user?.paymentStatusOrganization?.subscription !== 'active' &&
      options?.user?.paymentStatusOrganization?.payment !== 'failPayment' &&
      options?.user?.paymentStatusOrganization?.subscription !== 'past_due') ||
      (options?.user?.paymentStatusOrganization?.payment === 'payed' &&
        options?.user?.paymentStatusOrganization?.subscription === 'empty') ||
      options?.user?.paymentStatusOrganization?.payment === PaymentStatus.BLOCKED);

  const isNotTrial =
    options?.user?.paymentStatus?.status !== PaymentStatus.TRIAL &&
    options?.user?.paymentStatus?.status !== PaymentStatus.TRIALENDED &&
    options?.user?.paymentStatus?.status !== PaymentStatus.TRIAL &&
    options?.user?.paymentStatus?.status !== PaymentStatus.TRIALENDED &&
    options?.user?.paymentStatusOrganization?.status !== PaymentStatus.TRIAL &&
    options?.user?.paymentStatusOrganization?.status !== PaymentStatus.TRIALENDED &&
    options?.user?.paymentStatusOrganization?.status !== PaymentStatus.TRIAL &&
    options?.user?.paymentStatusOrganization?.status !== PaymentStatus.TRIALENDED &&
    options?.user?.paymentStatusOrganization?.payment !== PaymentStatus.TRIAL &&
    options?.user?.paymentStatusOrganization?.payment !== PaymentStatus.TRIALENDED &&
    options?.user?.paymentStatusOrganization?.payment !== PaymentStatus.TRIAL &&
    options?.user?.paymentStatusOrganization?.payment !== PaymentStatus.TRIALENDED;

  const isSubscriptionExpiredPages =
    accountType === AccountTypesEnum.selfServed &&
    isNotTrial &&
    (isNotInOrgWithoutSubscription || isInOrgWithoutSubscription);

  const stripeKey: string | undefined = process.env.REACT_APP_STRIPE_PUBLIC_KEY;

  const isStripeEnabled = options?.user?.isStripeEnabled || (!!stripeKey && stripeKey.length > 0);

  const filterFunction = (route: RouteObj): boolean => {
    const shouldNotKeep =
      (options?.filterNav && !route.isInNav) ||
      (isOmit && omit.includes(route.to)) ||
      (isPick && !pick.includes(route.to));

    if (shouldNotKeep) {
      return false;
    }

    if (route.subRoutes) {
      route.subRoutes = route.subRoutes.filter(filterFunction);
      if (!route.subRoutes.length) {
        return false;
      }
    }

    return (
      !route.roles.length ||
      route.roles.some((role) =>
        isRole(
          {
            ...comparedRole,
            permissionsExpanded: permissions,
            productType,
            isSubscriptionExpiredPages,
            isInOrganization,
            accountType,
            showControl,
            isStripeEnabled,
            isViewUserWriteAccess,
            isManagedUserProductsWorkflowDisabled,
            isSelfAccountOrOrgFirstLog,
            disabledBothWorkflowProductTypes,
            enabledOnlyServersMonitoring,
          },
          role,
        ),
      )
    );
  };

  return getClonedRoutes()
    .filter(filterFunction)
    .map((item) => {
      if (item.shouldCollapse && item.subRoutes?.length === 1) {
        return item.subRoutes[0];
      }
      return item;
    });
};

export const getAccountSettingsRoutes = (comparedRole: RoleWithOptions, options?: OptionsArg): RouteObj[] => {
  const omit = options?.omit || [];
  const pick = options?.pick || [];
  const isOmit = omit?.length;
  const isPick = pick?.length;

  const permissions = options?.permissions || {
    users: {
      isEnabled: true,
      permission: 'read',
    },
    accounts: {
      isEnabled: true,
      permission: 'read',
    },
    reports: {
      isEnabled: true,
      permission: 'read',
    },
    finance: {
      isEnabled: true,
      permission: 'read',
    },
  };
  // const isSubscriptionExpiredPages =
  //   (!options?.user?.paymentStatus?.status || options?.user?.paymentStatus?.status === PaymentStatus.BLOCKED) &&
  //   !options?.user?.isInOrganization;

  const isInOrganization = options?.user?.isInOrganization;
  const accountType = options?.user?.accountType;
  const showControl = {
    isCustomerFinance: options?.user?.isCustomerFinance,
    isCustomerOperator: options?.user?.isCustomerOperator,
    isCustomerPurchase: options?.user?.isCustomerPurchase,
    isCustomerReport: options?.user?.isCustomerReport,
    isSendCompareVersions: false,
    isSendSystemErrorsMail: false,
    isSendHardwareErrorsMail: options?.user?.isSendHardwareErrorsMail,
    isUptimeAccess: options?.user?.isUptimeAccess,
  };

  const isViewUserWriteAccess =
    (accountType === AccountTypesEnum.managed || accountType === AccountTypesEnum.selfServed) &&
    options?.user?.role === UserRolesEnum.accountUser &&
    options?.user?.writeAccess;

  const isViewUserSelfWriteAccess =
    accountType === AccountTypesEnum.selfServed &&
    isInOrganization &&
    options?.user?.role === UserRolesEnum.accountUser &&
    options?.user?.writeAccess;

  const isNotInOrgWithoutSubscription =
    !isInOrganization &&
    (!options?.user?.paymentStatus?.status ||
      options?.user?.paymentStatus?.status === PaymentStatus.BLOCKED ||
      options?.user?.subscription?.status === 'empty');
  const isInOrgWithoutSubscription =
    isInOrganization &&
    ((options?.user?.paymentStatusOrganization?.payment !== 'payed' &&
      options?.user?.paymentStatusOrganization?.subscription !== 'active' &&
      options?.user?.paymentStatusOrganization?.payment !== 'failPayment' &&
      options?.user?.paymentStatusOrganization?.subscription !== 'past_due') ||
      (options?.user?.paymentStatusOrganization?.payment === 'payed' &&
        options?.user?.paymentStatusOrganization?.subscription === 'empty') ||
      options?.user?.paymentStatusOrganization?.payment === PaymentStatus.BLOCKED);

  const isNotTrial =
    options?.user?.paymentStatus?.status !== PaymentStatus.TRIAL &&
    options?.user?.paymentStatus?.status !== PaymentStatus.TRIALENDED &&
    options?.user?.paymentStatus?.status !== PaymentStatus.TRIAL &&
    options?.user?.paymentStatus?.status !== PaymentStatus.TRIALENDED;
  const isSubscriptionExpiredPages =
    accountType === AccountTypesEnum.selfServed &&
    isNotTrial &&
    (isNotInOrgWithoutSubscription || isInOrgWithoutSubscription);

  const stripeKey: string | undefined = process.env.REACT_APP_STRIPE_PUBLIC_KEY;
  const isStripeEnabled = options?.user?.isStripeEnabled || (!!stripeKey && stripeKey.length > 0);

  const filterFunction = (route: RouteObj): boolean => {
    const shouldNotKeep =
      (options?.filterNav && !route.isInNav) ||
      (isOmit && omit.includes(route.to)) ||
      (isPick && !pick.includes(route.to));

    if (shouldNotKeep) {
      return false;
    }
    if (route.subRoutes) {
      route.subRoutes = route.subRoutes.filter(filterFunction);
      if (!route.subRoutes.length) {
        return false;
      }
    }

    return (
      !route.roles.length ||
      route.roles.some((role) => {
        return isRole(
          {
            ...comparedRole,
            isSubscriptionExpiredPages,
            permissionsExpanded: permissions,
            isInOrganization,
            accountType,
            showControl,
            isStripeEnabled,
            isViewUserWriteAccess,
            isViewUserSelfWriteAccess,
          },
          role,
        );
      })
    );
  };

  return getAccountSettingsClonedRoutes()
    .filter(filterFunction)
    .map((item) => {
      if (item.shouldCollapse && item.subRoutes?.length === 1) {
        return item.subRoutes[0];
      }
      return item;
    });
};

export const routes = [...getClonedRoutes(), ...getAccountSettingsClonedRoutes()];
