import React, { FC, ReactNode } from 'react';
import Dialog from '@material-ui/core/Dialog';
import { useStyles } from './styles';
import { useTranslation } from 'react-i18next';
import { Button } from 'components/UI';
import { Icon } from 'components/UI/Icon';
import { IconButton } from '@material-ui/core';

interface Props {
  open: boolean;
  onClose: () => any;
  messageKey: string;
  children?: ReactNode;
}

const SuccessModal: FC<Props> = ({ onClose, open, messageKey }: Props) => {
  // Styling
  const styles = useStyles();
  // Translation
  const { t } = useTranslation();

  return (
    <Dialog open={open} onClose={onClose}>
      <div className={styles.infoModal}>
        <IconButton className={styles.closeButton} onClick={() => onClose()}>
          <Icon name="close" color="#8E909D" />
        </IconButton>
        <div className={styles.modalInfoMessage}>{t(messageKey)}</div>
        <div className={styles.buttonsWrapper}>
          <Button labelKey={t('OK')} onClick={onClose} type="button" fullWidth />
        </div>
      </div>
    </Dialog>
  );
};

export default SuccessModal;
