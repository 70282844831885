export interface ViewUsersState {
  viewUsers: ViewUser[];
  firstViewUsers: ViewUser[];

  pagination: Pagination;
  viewUsersCount: number;
  search: string;
  counter: number;
  viewUser: any;
}

export interface Pagination {
  page: number;
  count: number;
  startViewUser: number;
  endViewUser: number;
}

export interface ViewUser {
  id: string;
  email: string;
  name: string;
  timeFormat: string;
  isCustomerPurchase: boolean;
  isCustomerFinance: boolean;
  isCustomerOperator: boolean;
  isCustomerReport: boolean;
  isSendCompareVersions: boolean;
}

export interface ViewUserWithStatus extends ViewUser {
  status: boolean;
  customerId: string;
}

export enum ViewUsersActionTypes {
  VIEW_USERS_SET = 'VIEW_USERS_SET',
  VIEW_USERS_FIRST_SET = 'VIEW_USERS_FIRST_SET',
  VIEW_USERS_SET_COUNT = 'VIEW_USERS_SET_COUNT',
  VIEW_USERS_SET_COUNT_FIRST = 'VIEW_USERS_SET_COUNT_FIRST',
  VIEW_USERS_SET_PAGINATION_COUNT = 'VIEW_USERS_SET_PAGINATION_COUNT',
  VIEW_USERS_SET_PAGE = 'VIEW_USERS_SET_PAGE',
  VIEW_USERS_SET_SHOWN = 'VIEW_USERS_SET_SHOWN,',
  VIEW_USERS_SET_SEARCH = 'VIEW_USERS_SET_SEARCH,',
  VIEW_USERS_RESET = 'VIEW_USERS_RESET',
}

export enum ViewUserActionTypes {
  VIEW_USER_SET = 'VIEW_USER_SET',
}

export interface ViewUsersSet {
  type: ViewUsersActionTypes.VIEW_USERS_SET;
  payload: ViewUser[];
}

export interface ViewUserSet {
  type: ViewUserActionTypes.VIEW_USER_SET;
  payload: ViewUser;
}

export interface ViewUsersFirstSet {
  type: ViewUsersActionTypes.VIEW_USERS_FIRST_SET;
  payload: ViewUser[];
}

export interface ViewUsersSetCount {
  type: ViewUsersActionTypes.VIEW_USERS_SET_COUNT;
  payload: number;
}

export interface ViewUsersSetCountFirst {
  type: ViewUsersActionTypes.VIEW_USERS_SET_COUNT_FIRST;
  payload: number;
}

export interface ViewUsersSetPaginationCount {
  type: ViewUsersActionTypes.VIEW_USERS_SET_PAGINATION_COUNT;
  payload: number;
}

export interface ViewUsersSetPage {
  type: ViewUsersActionTypes.VIEW_USERS_SET_PAGE;
  payload: number;
}

export interface ViewUsersSetShown {
  type: ViewUsersActionTypes.VIEW_USERS_SET_SHOWN;
  payload: { startViewUser: number; endViewUser: number };
}

export interface ViewUsersSetSearch {
  type: ViewUsersActionTypes.VIEW_USERS_SET_SEARCH;
  payload: string;
}

export interface ViewUsersReset {
  type: ViewUsersActionTypes.VIEW_USERS_RESET;
}

export type ViewUsersActions =
  | ViewUsersSet
  | ViewUsersSetPaginationCount
  | ViewUsersSetCount
  | ViewUsersSetCountFirst
  | ViewUsersSetPage
  | ViewUsersFirstSet
  | ViewUsersSetShown
  | ViewUsersSetSearch
  | ViewUserSet
  | ViewUsersReset;
