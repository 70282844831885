import React, { FC, useEffect } from 'react';
import PaginationWithDateRangeAndShow, {
  PaginationWithDateRangeAndShowProps,
} from 'components/Paginations/PaginationWithDateRangeAndShow';
import PaginationContainer from 'components/Paginations/PaginationContainer';
import { DEFAULT_PAGINATION_VALUE, PaginationLabels, Value as PaginationValue } from 'components/Paginations';
import { DEFAULT_DATE_RANGE_PICKER_VALUE, DateRange } from 'components/DateRangePicker';
import { mergeTwoObjects } from 'utils';
import BasicTable, { BasicTableProps, FilterOptions, RequestFilters, SelectedFind, SortValue } from './BasicTable';
import TableWrapper, { TableWrapperProps } from './TableWrapper';

export type TableWithDateRangePaginationChanges = RequestFilters & {
  pagination?: PaginationValue;
  dates?: DateRange;
  show?: string;
};

export type TableWithDateRangePaginationProps = Pick<
  BasicTableProps,
  'cellRenderer' | 'data' | 'columns' | 'tableLayout' | 'stickyHeader' | 'stickyHeaderOffsets' | 'hasNoFilters'
> & {
  tableWrapperProps?: TableWrapperProps;
  paginationLabels?: PaginationLabels;
  filtersData?: {
    status?: any;
    find?: FilterOptions;
    count: number;
  };
  filters?: {
    order?: SortValue;
    find?: SelectedFind;
    dates?: DateRange;
    pagination?: PaginationValue;
    show?: string;
  };
  defaultFilters?: {
    order?: SortValue;
    find?: SelectedFind;
    dates?: DateRange;
    pagination?: PaginationValue;
    show?: string;
  };
  shouldApplyChangesOnMount?: boolean;
  shouldUseDefaultDatesOnMount?: boolean;
  isNotEndDateLimited?: boolean;
  applyChanges: (value: TableWithDateRangePaginationChanges) => void;
  hasFixedPagination?: boolean;
  dateFormat?: string;
  hideShow?: boolean;
};

const TableWithDateRangeAndShowPagination: FC<TableWithDateRangePaginationProps> = ({
  data,
  columns,
  cellRenderer,
  tableWrapperProps,
  paginationLabels,
  filtersData,
  filters,
  defaultFilters,
  shouldApplyChangesOnMount,
  shouldUseDefaultDatesOnMount = true,
  applyChanges,
  isNotEndDateLimited,
  hasFixedPagination = true,
  dateFormat = 'DMY',
  tableLayout,
  stickyHeader,
  stickyHeaderOffsets,
  hasNoFilters,
  hideShow,
}) => {
  const dateRangePickerProps = mergeTwoObjects(
    {
      value: filters?.dates,
      defaultValue: defaultFilters?.dates || DEFAULT_DATE_RANGE_PICKER_VALUE,
      isNotEndDateLimited,
      onChange: (value: DateRange) => applyChanges({ dates: value }),
      dateFormat,
    },
    {},
  );

  const showTypeSelectProps = mergeTwoObjects(
    {
      value: filters?.show,
      onChange: (value: string) => applyChanges({ show: value }),
    },
    {},
  );

  const paginationProps = mergeTwoObjects(
    {
      showTypeSelectProps,
      dateRangePickerProps,
      count: filtersData?.count,
      status: filtersData?.status,
      value: filters?.pagination,
      defaultValue: defaultFilters?.pagination || DEFAULT_PAGINATION_VALUE,
      paginationLabels: paginationLabels,
      onChange: (value: PaginationValue) => applyChanges({ pagination: value }),
      hideShow,
    },
    {},
  ) as PaginationWithDateRangeAndShowProps;

  const tableProps = mergeTwoObjects(
    {
      data,
      columns,
      cellRenderer,
      filtersData,
      filters,
      defaultFilters,
      applyChanges: (value: RequestFilters) => applyChanges(value),
      tableLayout,
      stickyHeader,
      stickyHeaderOffsets,
      hasNoFilters,
    },
    {},
  ) as BasicTableProps;

  useEffect(() => {
    const shouldApply = typeof shouldApplyChangesOnMount === 'boolean' ? shouldApplyChangesOnMount : true;
    if (shouldApply) {
      applyChanges(
        mergeTwoObjects(filters || {}, {
          ...(shouldUseDefaultDatesOnMount ? { dates: dateRangePickerProps.defaultValue } : {}),
          pagination: paginationProps.defaultValue,
          ...(defaultFilters || {}),
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <TableWrapper {...(tableWrapperProps || {})}>
      <PaginationContainer top={0} isFixed={hasFixedPagination}>
        <PaginationWithDateRangeAndShow {...paginationProps} />
      </PaginationContainer>
      <BasicTable {...tableProps} stickyHeader={hasFixedPagination || stickyHeader} shouldApplyChangesOnMount={false} />
    </TableWrapper>
  );
};

export default TableWithDateRangeAndShowPagination;
