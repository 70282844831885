import React, { ReactElement } from 'react';
import { Route, Switch } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { useStyles } from './styles';
import SignIn from './SignIn';
import SignUp from './SignUp';
import ForgotPassword from './ForgotPassword';
import SetPassword from './SetPassword';
import PasswordHasBeenSet from './PasswordHasBeenSet';
import EmailHasBeenSent from './EmailHasBeenSent';
import { AppState } from 'store';

const AuthorizationForms = (): ReactElement => {
  const styles = useStyles();

  const brand = useSelector((state: AppState) => state.ui.themeBrandColor.brand);
  const logo = useSelector((state: AppState) => state.ui.themeBrandColor.logoUrl);

  return (
    <div className={styles.authorizationFormsContainer}>
      <div className={styles.overflowContainer}>
        <div className={styles.cardHeader}>
          <img className={styles.logo} src={`${logo}`} alt="logo" />
          <h2 className={styles.headerTitle}>{brand}</h2>
        </div>
        <Switch>
          <Route exact={true} path="/">
            <SignIn />
          </Route>
          <Route exact={true} path="/signin">
            <SignIn />
          </Route>
          <Route exact={true} path="/sign-up">
            <SignUp />
          </Route>
          <Route exact={true} path="/set-password">
            <SetPassword />
          </Route>
          <Route exact={true} path="/forgot-password">
            <ForgotPassword />
          </Route>
          <Route exact={true} path="/set">
            <PasswordHasBeenSet />
          </Route>
          <Route exact={true} path="/email">
            <EmailHasBeenSent />
          </Route>
        </Switch>
      </div>
    </div>
  );
};

export default AuthorizationForms;
