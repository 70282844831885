import React, { FC, useMemo } from 'react';
import { Icon } from 'components/UI/Icon';
import { Select } from 'components/UI';
import i18n from 'utils/i18n';
import { storage } from 'utils';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from 'store';
import { setLanguage } from 'store/session/actions';
import { useTranslation } from 'react-i18next';

import { useStyles } from './styles';

const LanguageSelector: FC = () => {
  // Redux
  const dispatch = useDispatch();
  const language = useSelector((state: AppState) => state.session.interfaceLanguage);
  const languages = useSelector((state: AppState) => state.ui.languages);
  const { t } = useTranslation();

  const nativeLanguages = useMemo(() => {
    return languages.map(({ value, label }) => {
      return { value, label: `${label}` };
    });
  }, [languages]);

  const styles = useStyles();
  const changeLanguage = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const lng = event.target.value;
    i18n.changeLanguage(lng);
    dispatch(setLanguage(lng));
    storage.saveLanguage(lng);
  };

  return (
    <div className={styles.languageSelectorContainer}>
      <Icon name="globe" color="#BFC0CD" />
      <Select
        hintOptionKey={t('Select Language')}
        className={styles.select}
        options={nativeLanguages}
        value={localStorage.getItem('language') || language || 'en'}
        onChange={changeLanguage}
        bordered={false}
      />
    </div>
  );
};

export default LanguageSelector;
