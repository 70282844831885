import ApiAddresses from './apiAddresses';
import { axiosInstance } from './axios';
import { AxiosPromise } from 'axios';

import { Credentials } from './models';
import { SessionTokens } from 'store/session/types';
import { logout, setTokens } from 'store/session/actions';
import { saveSession } from 'utils/storage';
import { CustomerParameters } from 'store/customerParameters/types';
import { ViewUser } from 'store/accountUsers/types';
import { GlobalUser } from 'store/globalUsers/types';

import { AdminData, BillingSettings } from 'store/adminParameters/types';
import { TableViewRequestParameters } from 'store/customerTableView/types';
import { AppearanceSetting, Lang, TableViewSetting, WorkflowStepType } from 'store/subui/types';
import { OrganizationCreate } from 'store/organizations/types';
import { uILoadingFinish, uILoadingStart } from 'store/ui/actions';
import {
  GetDashboardTableProps,
  GlobalModulesVersionListResponse,
  GlobalModulesVersionPost,
  SlaListPost,
  TypicalPostGetParameters,
} from './types';
import { ResourcesUsageReportBasic, ResourcesUsageReportParams } from '../store/resourcesUsageReport/types';
import { ServersChartList, ServersDashboardData } from '../store/serversDashboard/types';
import { IBillingHistory } from '../store/billingHistory/types';
import apiAddresses from './apiAddresses';
import { UpdateMonitoredAppsProps } from 'pages/AppMonitoring/interfaces';
import { UserRolesEnum } from 'utils/types';
import { store } from 'components/Root';

export const getTokens = (): SessionTokens => store.getState().session.tokens;

// Get Tree JSON actual API
export const createTreeApis = (apis: any) => {
  const copyApi = JSON.parse(JSON.stringify(apis));

  for (const api in copyApi) {
    copyApi[api] = copyApi[api].toString();
  }

  const valuesApi = Object.values(copyApi);

  return JSON.stringify(
    valuesApi.reduce((accum: any, item: any) => {
      const path = item.split('/');

      if (item.split('/')[0] === 'https:') {
        return accum;
      }

      let current = accum;

      for (const folder of path) {
        if (!current[folder]) {
          current[folder] = {};
        }

        current = current[folder];
      }

      return accum;
    }, {}),
  );

  return '';
};

const callApiWithToken = (token: string, url: string, options: any = {}): AxiosPromise => {
  return axiosInstance({
    ...options,
    url,
    headers: {
      Authorization: `Bearer ${token}`,
      ...options.headers,
    },
  });
};

export const refreshTokens = async (refreshToken: string, url: string, options: any = {}): Promise<any> => {
  try {
    const newTokens: any = await axiosInstance.post(ApiAddresses.AUTH_REFRESH, {
      refreshToken,
    });
    if (newTokens && newTokens.data) {
      // Save refreshed tokens in store and make an additional request using new access token
      store.dispatch(setTokens(newTokens.data));
      // Update tokens in localStorage
      saveSession(newTokens.data);
      return await callApiWithToken(newTokens.data.accessToken, url, options);
    }
    // Give up
    return await Promise.reject('Unauthorized');
  } catch (e) {
    // If refresh token is invalid -- log the user out
    store.dispatch(logout() as any);
  }
};
export const refreshTokens1 = async (refreshToken: string): Promise<any> => {
  try {
    const newTokens: any = await axiosInstance.post(ApiAddresses.AUTH_REFRESH, {
      refreshToken,
    });
    if (newTokens && newTokens.data) {
      // Save refreshed tokens in store and make an additional request using new access token
      store.dispatch(setTokens(newTokens.data));
      // Update tokens in localStorage
      saveSession(newTokens.data);
    }
  } catch (e) {
    store.dispatch(logout() as any);
  }
};

export const initRefreshTokens = async (refreshToken: string): Promise<any> => {
  try {
    const newTokens: any = await axiosInstance.post(ApiAddresses.AUTH_REFRESH, {
      refreshToken,
    });
    if (newTokens && newTokens.data) {
      if (newTokens.data.refresh) {
        store.dispatch(setTokens(newTokens.data));
        saveSession(newTokens.data);
      } else {
        store.dispatch(setTokens(newTokens.data.tokens));
        saveSession(newTokens.data.tokens);
      }
    }
  } catch (e) {
    store.dispatch(logout() as any);
  }
};

const authorizedRequest = async (url: string, options: any = {}): Promise<any> => {
  // Get tokens from store
  const storedTokens = getTokens();
  // If tokens are available in store
  if (storedTokens && storedTokens.access) {
    try {
      // Try to make an authorized request with existing token
      return await callApiWithToken(storedTokens.access, url, options);
    } catch (e) {
      // If token isn't valid try to update it using refresh token
      if ((e as any)?.response?.status === 401) {
        return await refreshTokens(storedTokens.refresh, url, options);
      }
      return await Promise.reject(e);
    }
  }

  return Promise.reject('Unauthorized');
};

const authorizedRequestWithLoading = <T>(url: string, options: any = {}): AxiosPromise<T> => {
  store.dispatch(uILoadingStart());
  return authorizedRequest(url, options).finally(() => store.dispatch(uILoadingFinish()));
};

//=================
// Authorization
//=================

export const apiSignIn = (credentials: Credentials): AxiosPromise<SessionTokens> => {
  const data: Credentials = {
    login: credentials.login,
    password: credentials.password,
  };

  return axiosInstance.post(ApiAddresses.USERS_SIGNIN, data);
};

export const apiSignUpAccount = (data: Record<string, any>): AxiosPromise<any> => {
  return axiosInstance.post(ApiAddresses.ACCOUNT_SIGN_UP, data);
};

export const apiSignUpOrganization = (data: Record<string, any>): AxiosPromise<any> => {
  return axiosInstance.post(ApiAddresses.ORGANIZATION_SIGN_UP, data);
};

export const apiVerifyAccount = (data: { tempCode: number; email: string }): AxiosPromise<any> => {
  return axiosInstance.post(ApiAddresses.ACTIVATE_ACCOUNT, data);
};

export const apiResendActivation = (email: string): AxiosPromise<any> => {
  const data: { email: string } = {
    email,
  };

  return axiosInstance.post(ApiAddresses.RESEND_ACTIVATION_EMAIL, data);
};

export const apiForgotPasswordRequestEmail = (email: string): AxiosPromise => {
  return axiosInstance.post(ApiAddresses.FORGOT_PASSWORD_REQUEST_EMAIL, {
    email,
  });
};

export const apiForgotPasswordSetNewPassword = (password: string, hash: string): AxiosPromise => {
  return axiosInstance.post(ApiAddresses.FORGOT_PASSWORD_SET_NEW, {
    password,
    tokenForReset: hash,
  });
};

export const apiGetUser = (): AxiosPromise => authorizedRequest(ApiAddresses.GET_USER, { method: 'GET' });

export const apiGetViewUser = (isOrganization: boolean): AxiosPromise =>
  isOrganization
    ? authorizedRequest(ApiAddresses.ORGANIZATION_VIEW_USER_DETAILS, { method: 'GET' })
    : authorizedRequest(ApiAddresses.VIEW_USER_DETAILS, { method: 'GET' });

export const apiGetViewUserParent = (): Promise<any> => {
  return authorizedRequest(ApiAddresses.VIEW_USER_PARENT, {
    method: 'GET',
  });
};

//==================
// User's requests
//==================
export const apiUserChangePassword = (oldPassword: string, newPassword: string): AxiosPromise => {
  return authorizedRequest(ApiAddresses.USER_PASSWORD_CHANGE, {
    method: 'POST',
    data: {
      oldPassword,
      newPassword,
    },
  });
};

export const apiGetUserParameters = (id: string): AxiosPromise => {
  return authorizedRequest(`${ApiAddresses.USER_PARAMETERS}?id=${id}`, { method: 'GET' });
};

export const apiGetUserStepsNames = (id: string | null, isOrganization: boolean): AxiosPromise => {
  return isOrganization
    ? authorizedRequest(`${ApiAddresses.ORGANIZATION_VIEW_USER_STEPS_NAMES}?id=${id}`, { method: 'GET' })
    : authorizedRequest(ApiAddresses.USER_STEPS_NAMES, { method: 'GET' });
};

export const apiGetViewUserStepsNames = (): AxiosPromise => {
  return authorizedRequest(ApiAddresses.VIEW_USER_STEPS_NAMES, { method: 'GET' });
};

export const apiGetUserStepsFilters = (): AxiosPromise => {
  return authorizedRequest(ApiAddresses.STEPS_DETAILS_FILTERS, { method: 'GET' });
};

export const apiGetUserDashboard = (stepName: string): AxiosPromise => {
  return authorizedRequest(`${ApiAddresses.GET_USER_DASHBOARD}?stepName=${stepName}`, { method: 'GET' });
};
export const apiGetOrganizationCustomersDashboard = (stepName: string, id: string): AxiosPromise => {
  return authorizedRequest(`${ApiAddresses.GET_ORGANIZATION_CUSTOMER_DASHBOARD}?stepName=${stepName}&userId=${id}`, {
    method: 'GET',
  });
};
export const apiGetViewUserDashboard = (stepName: string): AxiosPromise => {
  return authorizedRequest(`${ApiAddresses.GET_VIEW_USER_DASHBOARD}?stepName=${stepName}`, { method: 'GET' });
};

export const apiGetUserTableData = (
  requestParameters: TableViewRequestParameters,
  isOrganization?: boolean,
  id?: string,
): AxiosPromise => {
  return isOrganization && id
    ? authorizedRequest(`${ApiAddresses.ORGANIZATION_TABLE_DATA}?id=${id}`, {
        method: 'POST',
        data: { ...requestParameters },
      })
    : authorizedRequest(ApiAddresses.USER_TABLE_DATA, {
        method: 'POST',
        data: { ...requestParameters },
      });
};

export const apiGetViewUserTableData = (requestParameters: TableViewRequestParameters): AxiosPromise => {
  return authorizedRequest(ApiAddresses.VIEW_USER_TABLE_DATA, {
    method: 'POST',
    data: { ...requestParameters },
  });
};

//==================
// Admin's requests
//==================
export const apiAdminChangePassword = (oldPassword: string, newPassword: string): AxiosPromise => {
  return authorizedRequest(ApiAddresses.ADMIN_PASSWORD_CHANGE, {
    method: 'POST',
    data: {
      oldPassword,
      newPassword,
    },
  });
};
export const apiPostAdminBillingSettings = (data: BillingSettings): AxiosPromise => {
  return authorizedRequest(ApiAddresses.ADMIN_BILLING_SETTINGS, {
    method: 'POST',
    data,
  });
};
export const apiGetAdminBillingSettings = (): AxiosPromise => {
  return authorizedRequest(ApiAddresses.ADMIN_BILLING_SETTINGS, {
    method: 'GET',
  });
};

export const apiGetADAvailableDates = (): AxiosPromise => {
  return authorizedRequest(ApiAddresses.ADMIN_GET_AVAILABLE_DATES, { method: 'GET' });
};

export const apiGetEAAvailableDates = (): AxiosPromise => {
  return authorizedRequest(ApiAddresses.ORGANIZATION_GET_AVAILABLE_DATES, { method: 'GET' });
};

export const apiGetAdminDashboard = ({
  page,
  count,
  from,
  to,
  sortBy,
  accountType,
  hideAccounts,
}: GetDashboardTableProps): AxiosPromise => {
  return authorizedRequest(
    `${ApiAddresses.ADMIN_CUSTOMERS_DASHBOARD}?page=${page}&count=${count}&from=${from}&to=${to}&sort=${sortBy.order}&accountType=${accountType}&hideAccounts=${hideAccounts}`,
    {
      method: 'GET',
    },
  );
};

export const apiAdminDashboardSearch = ({
  search,
  page,
  count,
  from,
  to,
  sortBy,
  customerId,
  accountType,
  hideAccounts,
}: GetDashboardTableProps): AxiosPromise => {
  return authorizedRequest(
    `${ApiAddresses.ADMIN_DASHBOARD_SEARCH}?name=${encodeURIComponent(
      search || '',
    )}&page=${page}&count=${count}&customerId=${customerId}&from=${from}&to=${to}&sort=${
      sortBy.order
    }&accountType=${accountType}&hideAccounts=${hideAccounts}`,
    {
      method: 'GET',
    },
  );
};

export const apiGetOrganizationDashboard = ({
  page,
  count,
  from,
  to,
  sortBy,
  accountType,
  hideAccounts,
}: GetDashboardTableProps): AxiosPromise => {
  return authorizedRequest(
    `${ApiAddresses.ORGANIZATION_CUSTOMERS_DASHBOARD}?page=${page}&count=${count}&from=${from}&to=${to}&sort=${sortBy.order}&accountType=${accountType}&hideAccounts=${hideAccounts}`,
    {
      method: 'GET',
    },
  );
};

export const apiOrganizationDashboardSearch = ({
  search,
  page,
  count,
  from,
  to,
  sortBy,
  customerId,
  accountType,
  hideAccounts,
}: GetDashboardTableProps): AxiosPromise => {
  return authorizedRequest(
    `${ApiAddresses.ORGANIZATION_DASHBOARD_SEARCH}?name=${encodeURIComponent(
      search || '',
    )}&page=${page}&count=${count}&customerId=${customerId}&from=${from}&to=${to}&sort=${
      sortBy.order
    }&accountType=${accountType}&hideAccounts=${hideAccounts}`,
    {
      method: 'GET',
    },
  );
};

export const apiXLS = (url: string): AxiosPromise => {
  return authorizedRequest(url, { method: 'GET' });
};

export const apiGetXlsData = (
  id: string,
  user: string,
  requestParameters: TableViewRequestParameters,
): AxiosPromise => {
  switch (user) {
    case UserRolesEnum.accountUser:
      return authorizedRequest(`${ApiAddresses.VIEW_USER_TABLE_XLS}?id=${id}`, {
        method: 'POST',
        data: { ...requestParameters },
        responseType: 'blob',
      });
    case UserRolesEnum.organization:
      return authorizedRequest(`${ApiAddresses.ORGANIZATION_TABLE_XLS}?id=${id}`, {
        method: 'POST',
        data: { ...requestParameters },
        responseType: 'blob',
      });
    case UserRolesEnum.account:
      return authorizedRequest(`${ApiAddresses.USERS_TABLE_XLS}?id=${id}`, {
        method: 'POST',
        responseType: 'blob',
        data: { ...requestParameters },
      });
    case UserRolesEnum.globalUser:
      return authorizedRequest(`${ApiAddresses.GLOBAL_USER_TABLE_XLS}?id=${id}`, {
        method: 'POST',
        responseType: 'blob',
        data: { ...requestParameters },
      });
    default:
      return authorizedRequest(`${ApiAddresses.ADMIN_TABLE_XLS}?id=${id}`, {
        method: 'POST',
        data: { ...requestParameters },
        responseType: 'blob',
      });
  }
};

export const apiGetAdminCustomersList = (page: number, count: number, accountType: string): AxiosPromise => {
  return authorizedRequest(
    `${ApiAddresses.ADMIN_CUSTOMERS_LIST}?page=${page}&count=${count}&accountType=${accountType}`,
    { method: 'GET' },
  );
};

export const apiGetOrganizationCustomersList = (page: number, count: number, accountType: string): AxiosPromise => {
  return authorizedRequest(
    `${ApiAddresses.ORGANIZATION_CUSTOMERS_LIST}?page=${page}&count=${count}&accountType=${accountType}`,
    { method: 'GET' },
  );
};

export const apiCustomerSearch = (
  searchString: string,
  page: number,
  count: number,
  accountType?: string,
): AxiosPromise => {
  return authorizedRequest(
    `${ApiAddresses.ADMIN_CUSTOMERS_SEARCH}?name=${encodeURIComponent(
      searchString,
    )}&page=${page}&count=${count}&accountType=${accountType}`,
    {
      method: 'GET',
    },
  );
};

export const apiEnterpriseCustomerSearch = (
  searchString: string,
  page: number,
  count: number,
  accountType: string,
): AxiosPromise => {
  return authorizedRequest(
    `${ApiAddresses.ORGANIZATION_CUSTOMERS_SEARCH_ALL}?name=${encodeURIComponent(
      searchString,
    )}&page=${page}&count=${count}&accountType=${accountType}`,
    {
      method: 'GET',
    },
  );
};

export const apiGetCustomerParameters = (id: string): AxiosPromise => {
  return authorizedRequest(`${ApiAddresses.CUSTOMER_PARAMETERS}?id=${id}`, { method: 'GET' });
};

export const apiGetSelfCustomerParameters = (id: string): AxiosPromise => {
  return authorizedRequest(`${ApiAddresses.SELF_CUSTOMER_PARAMETERS}?id=${id}`, { method: 'GET' });
};

export const apiDeleteCustomer = (id: string): AxiosPromise => {
  return authorizedRequest(ApiAddresses.ADMIN_CUSTOMERS_DELETE, { method: 'DELETE', data: { id } });
};

export const apiAdminUpdateCustomer = (id: string, customerParameters: CustomerParameters): AxiosPromise => {
  return authorizedRequest(`${ApiAddresses.ADMIN_CUSTOMERS_UPDATE}?id=${id}`, {
    method: 'PUT',
    data: { ...customerParameters },
  });
};
export const apiUpdateUserAccountParameters = (
  id: string,
  customerParameters: CustomerParameters,
  isOrganization: boolean,
): AxiosPromise => {
  return isOrganization
    ? authorizedRequest(`${ApiAddresses.ORGANIZATION_USER_PARAMETERS_UPDATE}?id=${id}`, {
        method: 'PUT',
        data: { ...customerParameters },
      })
    : authorizedRequest(`${ApiAddresses.USER_PARAMETERS_UPDATE}?id=${id}`, {
        method: 'PUT',
        data: { ...customerParameters },
      });
};

export const apiGetAdminAccountParameters = (): AxiosPromise =>
  authorizedRequest(ApiAddresses.ADMIN_GET_ACCOUNT_PARAMETERS, { method: 'GET' });

export const apiGetGlobalUserAccountParameters = (): AxiosPromise =>
  authorizedRequest(ApiAddresses.GLOBAL_USER_GET_ACCOUNT_PARAMETERS, { method: 'GET' });

export const apiUpdateAdminAccountParameters = (adminData: AdminData): AxiosPromise => {
  return authorizedRequest(ApiAddresses.ADMIN_UPDATE_ACCOUNT_PARAMETERS, {
    method: 'PUT',
    data: { ...adminData },
  });
};

export const apiTestSMTP = (): AxiosPromise => {
  return authorizedRequest(ApiAddresses.ADMIN_TEST_SMTP, { method: 'GET' });
};

export const apiAdminGetUserStepsNames = (id: string): AxiosPromise => {
  return authorizedRequest(`${ApiAddresses.ADMIN_STEPS_NAMES}?id=${id}`, { method: 'GET' });
};

export const apiAdminGetUserStepsFilters = (id: string, isOrganization: boolean): AxiosPromise => {
  return isOrganization
    ? authorizedRequest(`${ApiAddresses.ORGANIZATION_STEPS_DETAILS_FILTERS}?id=${id}`, { method: 'GET' })
    : authorizedRequest(`${ApiAddresses.ADMIN_STEPS_DETAILS_FILTERS}?id=${id}`, { method: 'GET' });
};

export const apiTableViewHistoryData = (id: string, requestParameters: TableViewRequestParameters): AxiosPromise => {
  return authorizedRequest(`${ApiAddresses.GET_TABLE_HISTORY_VIEW}?id=${id}`, {
    method: 'POST',
    data: { ...requestParameters },
  });
};

export const apiAdminGetUserTableData = (id: string, requestParameters: TableViewRequestParameters): AxiosPromise => {
  return authorizedRequest(`${ApiAddresses.ADMIN_TABLE_DATA}?id=${id}`, {
    method: 'POST',
    data: { ...requestParameters },
  });
};

export const apiGetGlobalUserTableData = (id: string, requestParameters: TableViewRequestParameters): AxiosPromise => {
  return authorizedRequest(`${ApiAddresses.GLOBAL_USER_TABLE_DATA}?id=${id}`, {
    method: 'POST',
    data: { ...requestParameters },
  });
};

export const apiAdminGetUserDashboard = (stepName: string, id: string): AxiosPromise => {
  return authorizedRequest(`${ApiAddresses.ADMIN_GET_USER_DASHBOARD}?stepName=${stepName}&userId=${id}`, {
    method: 'GET',
  });
};

/*
  Requests for ViewUser
*/
export const apiGetViewUsersList = (
  page: number,
  count: number,
  isOrganization: boolean,
  customerId: string,
): AxiosPromise => {
  return isOrganization
    ? authorizedRequest(
        `${ApiAddresses.ORGANIZATION_VIEW_USERS_LIST}?page=${page}&count=${count}&userId=${customerId}`,
        { method: 'GET' },
      )
    : authorizedRequest(`${ApiAddresses.VIEW_USERS_LIST}?page=${page}&count=${count}`, { method: 'GET' });
};

export const apiAdminGetViewUsersList = (page: number, count: number, userId: string): AxiosPromise => {
  return authorizedRequest(`${ApiAddresses.ADMIN_VIEW_USERS_LIST}?page=${page}&count=${count}&userId=${userId}`, {
    method: 'GET',
  });
};
export const apiViewUsersSearch = (
  searchString: string,
  page: number,
  count: number,
  isOrganization: boolean,
  customerId: string,
): AxiosPromise => {
  return isOrganization
    ? authorizedRequest(
        `${ApiAddresses.ORGANIZATION_VIEW_USERS_SEARCH}?name=${encodeURIComponent(
          searchString,
        )}&page=${page}&count=${count}&customerId=${customerId}`,
        {
          method: 'GET',
        },
      )
    : authorizedRequest(
        `${ApiAddresses.VIEW_USERS_SEARCH}?name=${encodeURIComponent(searchString)}&page=${page}&count=${count}`,
        { method: 'GET' },
      );
};

export const apiAdminViewUsersSearch = (
  searchString: string,
  page: number,
  count: number,
  customerId: string,
): AxiosPromise => {
  return authorizedRequest(
    `${ApiAddresses.ADMIN_VIEW_USERS_SEARCH}?name=${encodeURIComponent(
      searchString,
    )}&page=${page}&count=${count}&customerId=${customerId}`,
    {
      method: 'GET',
    },
  );
};

export const apiDeleteViewUser = (id: string, isOrganization: boolean): AxiosPromise => {
  return isOrganization
    ? authorizedRequest(ApiAddresses.ORGANIZATION_VIEW_USER_DELETE, { method: 'DELETE', data: { id } })
    : authorizedRequest(ApiAddresses.VIEW_USER_DELETE, { method: 'DELETE', data: { id } });
};

export const apiAdminDeleteViewUser = (id: string): AxiosPromise => {
  return authorizedRequest(ApiAddresses.ADMIN_VIEW_USER_DELETE, { method: 'DELETE', data: { id } });
};

export const apiAdminViewUsersCreate = (viewUsersParameters: ViewUser, customerId: string): AxiosPromise => {
  return authorizedRequest(ApiAddresses.ADMIN_VIEW_USERS_CREATE, {
    method: 'POST',
    data: { customers: viewUsersParameters, customerId },
  });
};

export const apiAccountCreate = (accountParameters: any): AxiosPromise => {
  return authorizedRequest(ApiAddresses.CREATE_ACCOUNT, {
    method: 'POST',
    data: accountParameters,
  });
};

export const apiAccountUpdate = (accountParameters: any, id: string): AxiosPromise => {
  return authorizedRequest(`${ApiAddresses.UPDATE_ACCOUNT}?id=${id}`, {
    method: 'PUT',
    data: accountParameters,
  });
};

export const apiAccountGetOne = (id: string): AxiosPromise => {
  return authorizedRequest(`${ApiAddresses.GET_ACCOUNT_ONE}?id=${id}`, {
    method: 'GET',
  });
};

// Monitoring User
export const apiMonitoringUserUpdate = (parameters: any, id: string): AxiosPromise => {
  return authorizedRequest(`${ApiAddresses.UPDATE_MONITORING_USER}?id=${id}`, {
    method: 'PUT',
    data: parameters,
  });
};

export const apiMonitoringUserCreate = (parameters: any): AxiosPromise => {
  return authorizedRequest(ApiAddresses.CREATE_MONITORING_USER, {
    method: 'POST',
    data: parameters,
  });
};

export const apiMonitoringUserDelete = (id: string): AxiosPromise => {
  return authorizedRequest(`${ApiAddresses.DELETE_MONITORING_USER}?monitoringUserId=${id}`, {
    method: 'DELETE',
  });
};

export const apiMonitoringUserListAll = (id: string): AxiosPromise => {
  return authorizedRequest(`${ApiAddresses.GET_MONITORING_USER_LIST_ALL}?id=${id}`, {
    method: 'GET',
  });
};

export const apiMonitoringUserOne = (id: string): AxiosPromise => {
  return authorizedRequest(`${ApiAddresses.GET_MONITORING_USER}?id=${id}`, {
    method: 'GET',
  });
};

export const apiMonitoringUserList = (id: string): AxiosPromise => {
  return authorizedRequest(`${ApiAddresses.GET_MONITORING_USER_LIST}?monitoringUserId=${id}`, {
    method: 'GET',
  });
};

export const apiViewUserCreate = (viewUserParameters: ViewUser, customerId: string): AxiosPromise => {
  return authorizedRequest(ApiAddresses.VIEW_USER_CREATE, {
    method: 'POST',
    data: {
      customerId: customerId,
      customer: {
        ...viewUserParameters,
      },
    },
  });
};

export const apiViewUserGetOne = (id: string): AxiosPromise => {
  return authorizedRequest(`${ApiAddresses.VIEW_USER_GET_ONE}?id=${id}`, {
    method: 'GET',
  });
};

export const apiViewUserUpdate = (viewUserParameters: ViewUser): AxiosPromise => {
  return authorizedRequest(ApiAddresses.VIEW_USER_UPDATE, {
    method: 'PUT',
    data: { customer: viewUserParameters },
  });
};

export const apiViewUsersCreate = (
  viewUsersParameters: ViewUser,
  isOrganization: boolean,
  customerId?: string,
): AxiosPromise => {
  return isOrganization && customerId
    ? authorizedRequest(ApiAddresses.ORGANIZATION_VIEW_USERS_CREATE, {
        method: 'POST',
        data: { customers: viewUsersParameters, customerId },
      })
    : authorizedRequest(ApiAddresses.VIEW_USERS_CREATE, {
        method: 'POST',
        data: { customers: viewUsersParameters },
      });
};

export const apiViewUsersUpdate = (viewUsersParameters: ViewUser, isOrganization: boolean): AxiosPromise => {
  return isOrganization
    ? authorizedRequest(ApiAddresses.ORGANIZATION_VIEW_USERS_UPDATE, {
        method: 'PUT',
        data: {
          customers: viewUsersParameters,
        },
      })
    : authorizedRequest(ApiAddresses.VIEW_USERS_UPDATE, {
        method: 'PUT',
        data: {
          customers: viewUsersParameters,
        },
      });
};

export const apiAdminViewUsersUpdate = (viewUsersParameters: ViewUser, companyId: string): AxiosPromise => {
  return authorizedRequest(ApiAddresses.ADMIN_VIEW_USERS_UPDATE, {
    method: 'PUT',
    data: {
      customers: viewUsersParameters,
      companyId,
    },
  });
};

export const apiDeleteGlobalUser = (id: string): AxiosPromise => {
  return authorizedRequest(ApiAddresses.GLOBAL_USER_DELETE, { method: 'DELETE', data: { id } });
};

export const apiGlobalUsersCreate = (globalUsersParameters: GlobalUser): AxiosPromise => {
  return authorizedRequest(ApiAddresses.GLOBAL_USERS_CREATE, {
    method: 'POST',
    data: { globalUsers: globalUsersParameters },
  });
};

export const apiGlobalUserCreate = (globalUserParameters: GlobalUser): AxiosPromise => {
  return authorizedRequest(ApiAddresses.GLOBAL_USER_CREATE, {
    method: 'POST',
    data: { globalUser: globalUserParameters },
  });
};

export const apiGlobalUsersUpdate = (globalUsersParameters: GlobalUser): AxiosPromise => {
  return authorizedRequest(ApiAddresses.GLOBAL_USERS_UPDATE, {
    method: 'PUT',
    data: {
      globalUsers: globalUsersParameters,
    },
  });
};
export const apiGlobalUsersUpdateOne = (id: string, globalUsersParameters: Record<string, any>): AxiosPromise => {
  return authorizedRequest(`${ApiAddresses.GLOBAL_USERS_UPDATE_ONE}?id=${id}`, {
    method: 'PUT',
    data: {
      globalUser: { ...globalUsersParameters },
    },
  });
};

export const apiGlobalUsersGetOne = (id: string): AxiosPromise => {
  return authorizedRequest(`${ApiAddresses.GLOBAL_USERS_GET_ONE}?id=${id}`, {
    method: 'GET',
  });
};

export const apiGetGlobalUsersList = (page: number, count: number): AxiosPromise => {
  return authorizedRequest(`${ApiAddresses.GLOBAL_USERS_LIST}?page=${page}&count=${count}`, { method: 'GET' });
};

export const apiGlobalUsersSearch = (searchString: string, page: number, count: number): AxiosPromise => {
  return authorizedRequest(
    `${ApiAddresses.GLOBAL_USERS_SEARCH}?name=${encodeURIComponent(searchString)}&page=${page}&count=${count}`,
    { method: 'GET' },
  );
};

/* UI NEW requests */

export const apiUpdateAppearanceSettings = (data: AppearanceSetting): AxiosPromise => {
  return authorizedRequest(ApiAddresses.SETTINGS_APPEARANCE, {
    method: 'PUT',
    data: { backgroundUrl: data.backgroundUrl, logoUrl: data.logoUrl, brand: data.brand },
  });
};

export const apiGetAppearanceSettingsAdditional = (): AxiosPromise => {
  return authorizedRequest(ApiAddresses.SETTINGS_ADDITIONAL_COLOR, { method: 'GET' });
};

export const apiDeleteAdditLanguage = (id: string): AxiosPromise => {
  return authorizedRequest(`${ApiAddresses.SETTINGS_ADDITIONAL_LANGUAGE_DELETE}/${id}`, { method: 'DELETE' });
};

export const apiPostApearanceSettingsAdditional = (data: any): AxiosPromise => {
  return authorizedRequest(ApiAddresses.SETTINGS_ADDITIONAL_COLOR, {
    method: 'POST',
    data: { ...data },
  });
};

export const apiPostImage = (data: any): AxiosPromise => {
  const formData = new FormData();
  formData.append('image', data);

  return authorizedRequest(ApiAddresses.SETTINGS_ADDITIONAL_IMAGE, {
    method: 'POST',
    headers: { 'Content-Type': 'multipart/form-data' },
    data: formData,
  });
};

export const apiUpdateApearanceSettingsAdditional = (data: any): AxiosPromise => {
  return authorizedRequest(ApiAddresses.SETTINGS_ADDITIONAL_COLOR, {
    method: 'PUT',
    data: { ...data },
  });
};

export const apiGetLanguageSettingsAdditional = (): AxiosPromise => {
  return authorizedRequest(ApiAddresses.SETTINGS_ADDITIONAL_LANGUAGE_LIST, { method: 'GET' });
};

export const apiGetStepsSettingsAdditional = (): AxiosPromise => {
  return authorizedRequest(ApiAddresses.SETTINGS_ADDITIONAL_STEPS, { method: 'GET' });
};

export const apiUpdateStepsSettingsAdditional = (data: WorkflowStepType[]): AxiosPromise => {
  return authorizedRequest(ApiAddresses.SETTINGS_ADDITIONAL_STEPS, {
    method: 'PUT',
    data: data,
  });
};

export const apiGetTableViewSettingsAdditional = (): AxiosPromise => {
  return authorizedRequest(ApiAddresses.SETTINGS_ADDITIONAL_TABLE, { method: 'GET' });
};

export const apiUpdateTableViewSettingsAdditional = (data: TableViewSetting[]): AxiosPromise => {
  return authorizedRequest(ApiAddresses.SETTINGS_ADDITIONAL_TABLE, {
    method: 'PUT',
    data: data,
  });
};

export const apiGetDownloadAgent = (): AxiosPromise => {
  return axiosInstance({
    url: ApiAddresses.SETTINGS_ADDITIONAL_DOWNLOAD_AGENT,
    method: 'GET',
    responseType: 'blob',
  });
};

/*UI requests */
export const apiGetLanguages = (): AxiosPromise => {
  return axiosInstance.get(ApiAddresses.LANGUAGES);
};

export const apiGetWorkflowStepTypes = (isOrganization: boolean): AxiosPromise => {
  return isOrganization
    ? authorizedRequest(ApiAddresses.ORGANIZATION_STEPS_TYPES, { method: 'GET' })
    : axiosInstance.get(ApiAddresses.STEPS_TYPES);
};

export const apiGetTheme = (): AxiosPromise => {
  return axiosInstance.get(ApiAddresses.THEME);
};

export const apiGetLiveResponse = (): AxiosPromise => {
  return axiosInstance.get(ApiAddresses.LIVE);
};

/* Pre-paid */

export const apiPostPurchasePayment = (data: Record<string, any>): AxiosPromise => {
  return authorizedRequest(ApiAddresses.PAYMENT_PURCHASE, {
    method: 'POST',
    data,
  });
};

export const apiGetPurchaseHistory = (data: Record<string, any>, isOrganization: boolean): AxiosPromise => {
  return isOrganization
    ? authorizedRequest(ApiAddresses.ORGANIZATION_PAYMENT_HISTORY, {
        method: 'POST',
        data,
      })
    : authorizedRequest(ApiAddresses.PAYMENT_HISTORY, {
        method: 'POST',
        data,
      });
};

export const apiSendInvoice = (params: Record<string, any>, formData: FormData): AxiosPromise => {
  return authorizedRequest(ApiAddresses.PAYMENT_INVOICE, {
    method: 'POST',
    headers: { 'Content-Type': 'multipart/form-data' },
    params,
    data: formData,
  });
};

export const apiOrganizationsSearch = (
  name: string,
  page: number,
  count: number,
  enterpriseName: string,
): AxiosPromise => {
  return authorizedRequest(ApiAddresses.ORGANIZATION_SEARCH, {
    method: 'POST',
    data: {
      name,
      page,
      count,
      enterpriseName,
    },
  });
};

export const apiDeleteOrganization = (id: string): AxiosPromise => {
  return authorizedRequest(ApiAddresses.ORGANIZATION_DELETE, { method: 'DELETE', data: { id } });
};

export const apiOrganizationsCreate = (enterpriseAdministratorsParameters: OrganizationCreate[]): AxiosPromise => {
  return authorizedRequest(ApiAddresses.ORGANIZATION_CREATE_MANY, {
    method: 'POST',
    data: { enterprises: enterpriseAdministratorsParameters },
  });
};

export const apiOrganizationsUpdate = (enterpriseAdministratorsParameters: OrganizationCreate[]): AxiosPromise => {
  return authorizedRequest(ApiAddresses.ORGANIZATION_UPDATE_MANY, {
    method: 'PUT',
    data: {
      enterprises: enterpriseAdministratorsParameters,
    },
  });
};

export const apiOrganizationUserCreate = (organizationUpdate: Record<string, any>): AxiosPromise => {
  return authorizedRequest(`${ApiAddresses.CREATE_ORGANIZATION_USER}`, {
    method: 'POST',
    data: { ...organizationUpdate },
  });
};

export const apiOrganizationUserUpdate = (organizationUpdate: Record<string, any>, id: string): AxiosPromise => {
  return authorizedRequest(`${ApiAddresses.UPDATE_ORGANIZATION_USER}?id=${id}`, {
    method: 'PUT',
    data: { ...organizationUpdate },
  });
};

export const apiOrganizationUserByOrgUserGet = (id: string): AxiosPromise => {
  return authorizedRequest(`${ApiAddresses.GET_ORGANIZATION_USER_BY_ORG_USER_ID}?organizationUserId=${id}`, {
    method: 'GET',
  });
};

export const apiOrganizationUpdate = (organizationUpdate: Record<string, any>, id: string): AxiosPromise => {
  return authorizedRequest(`${ApiAddresses.PUT_ORGANIZATION_UPDATE}${id}`, {
    method: 'PUT',
    data: { ...organizationUpdate },
  });
};

// export const apiOrganizationUserUpdate = (organizationUpdate: Record<string, any>, id: string): AxiosPromise => {
//   return authorizedRequest(`${ApiAddresses.PUT_ORGANIZATION_USER_UPDATE}${id}`, {
//     method: 'PUT',
//     data: { ...organizationUpdate },
//   });
// };

export const apiOrganizationCreate = (organizationCreate: Record<string, any>): AxiosPromise => {
  return authorizedRequest(ApiAddresses.PUT_ORGANIZATION_CREATE, {
    method: 'POST',
    data: { ...organizationCreate },
  });
};

export const apiOrganizationOne = (id: string): AxiosPromise => {
  return authorizedRequest(`${ApiAddresses.GET_ORGANIZATION_ONE}${id}`, {
    method: 'GET',
    data: { id },
  });
};

export const apiOrganizationFreeUsersList = (): AxiosPromise => {
  return authorizedRequest(ApiAddresses.GET_ORGANIZATION_FREE_USERS_LIST, {
    method: 'GET',
  });
};

export const apiOrganizationCustomersSearch = (id: string | null): AxiosPromise => {
  return authorizedRequest(ApiAddresses.ORGANIZATION_CUSTOMERS_SEARCH, {
    method: 'POST',
    data: {
      id,
    },
  });
};

// System users
export const apiGetSystemUsers = (data: Record<string, any>, isOrganization: boolean): AxiosPromise => {
  return isOrganization
    ? authorizedRequest(ApiAddresses.COMMON_SYSTEM_USERS, {
        method: 'POST',
        data,
      })
    : authorizedRequest(ApiAddresses.COMMON_SYSTEM_USERS, {
        method: 'POST',
        data,
      });
};

export const apiDeleteSystemUser = (id: string): AxiosPromise => {
  return authorizedRequest(`${ApiAddresses.DELETE_SYSTEM_USER}?id=${id}`, {
    method: 'DELETE',
  });
};

export const apiGetSystemUsersCustomersList = (query: string): AxiosPromise => {
  return authorizedRequest(`${ApiAddresses.GET_CUSTOMERS_LIST}?accountType=${query}`, {
    method: 'GET',
  });
};

// Server monitoring
export const apiGetServersAlertHistory = (data: Record<string, any>): AxiosPromise => {
  return authorizedRequest(ApiAddresses.SERVERS_ALERT, {
    method: 'POST',
    data,
  });
};

export const apiGetServersAlertHistoryXLS = (data: Record<string, any>): AxiosPromise => {
  return authorizedRequest(ApiAddresses.SERVERS_ALERT_XLS, {
    method: 'POST',
    data,
    responseType: 'blob',
  });
};

export const apiGetWorkflowAlertHistory = (data: Record<string, any>): AxiosPromise => {
  return authorizedRequest(ApiAddresses.WORKFLOW_ALERT, {
    method: 'POST',
    data,
  });
};

export const apiGetWorkflowAlertHistoryXLS = (data: Record<string, any>): AxiosPromise => {
  return authorizedRequest(ApiAddresses.WORKFLOW_ALERT_XLS, {
    method: 'POST',
    data,
    responseType: 'blob',
  });
};

export const apiGetServersAlertCharts = (data: Record<string, any>): AxiosPromise => {
  return authorizedRequest(ApiAddresses.SERVERS_CHART, {
    method: 'POST',
    data,
  });
};

export const apiServersSearch = (companyId: string): AxiosPromise => {
  return authorizedRequest(`${ApiAddresses.GET_AGENT_MONITORS}?companyId=${companyId}`, { method: 'GET' });
};

export const apiRegisterAgent = (data: Record<string, any>): AxiosPromise => {
  return authorizedRequest(ApiAddresses.REGISTER_MONITOR, { method: 'POST', data });
};

export const apiUpdateAgent = (data: Record<string, any>): AxiosPromise => {
  return authorizedRequest(ApiAddresses.UPDATE_MONITOR, { method: 'PUT', data });
};

export const apiDeleteAgent = (monitorId: string): AxiosPromise => {
  return authorizedRequest(`${ApiAddresses.DELETE_MONITOR}?monitorId=${monitorId}`, { method: 'DELETE' });
};

export const apiResetAgent = (data: { monitorId: string }): AxiosPromise => {
  return authorizedRequest(ApiAddresses.RESET_MONITOR, { method: 'POST', data });
};

export const apiGetHardwareAlerts = (data: Record<string, any>): AxiosPromise => {
  return authorizedRequest(ApiAddresses.GET_HARDWARE_ALERTS, {
    method: 'POST',
    data,
  });
};

export const apiGetOverageReport = (url: string): AxiosPromise => {
  return authorizedRequest(url, {
    method: 'GET',
  });
};

export const apiGetGlobalModulesVersions = (
  data: Record<string, any>,
): AxiosPromise<GlobalModulesVersionListResponse> => {
  return authorizedRequestWithLoading<GlobalModulesVersionListResponse>(ApiAddresses.GLOBAL_MODULE_VERSIONS_LIST, {
    method: 'POST',
    data,
  });
};

export const apiAddGlobalModuleVersions = (data: GlobalModulesVersionPost): AxiosPromise => {
  return authorizedRequestWithLoading(ApiAddresses.GLOBAL_MODULE_VERSIONS_EDIT, {
    method: 'POST',
    data,
  });
};

export const apiModifyGlobalModuleVersions = (data: GlobalModulesVersionPost, id: string): AxiosPromise => {
  return authorizedRequestWithLoading(`${ApiAddresses.GLOBAL_MODULE_VERSIONS_EDIT}/${id}`, {
    method: 'PUT',
    data,
  });
};

export const apiDeleteGlobalModuleVersions = (id: string): AxiosPromise => {
  return authorizedRequestWithLoading(`${ApiAddresses.GLOBAL_MODULE_VERSIONS_EDIT}/${id}`, {
    method: 'DELETE',
  });
};

export const apiGetCustomerModulesVersions = (data: Record<string, any>): AxiosPromise => {
  return authorizedRequestWithLoading(ApiAddresses.CUSTOMER_MODULE_VERSIONS_LIST, {
    method: 'POST',
    data,
  });
};

export const apiModifyCustomerModuleVersionModule = (data: { isModuleActive: boolean }, id: string): AxiosPromise => {
  return authorizedRequestWithLoading(`${ApiAddresses.CUSTOMER_MODULE_VERSIONS_MODULE_EDIT}/${id}`, {
    method: 'PUT',
    data,
  });
};

export const apiModifyCustomerModuleVersionSystem = (data: { isSystemActive: boolean }, id: string): AxiosPromise => {
  return authorizedRequestWithLoading(`${ApiAddresses.CUSTOMER_MODULE_VERSIONS_SYSTEM_EDIT}/${id}`, {
    method: 'PUT',
    data,
  });
};

export const apiGetSlaListParams = (accountType: string): AxiosPromise => {
  return authorizedRequestWithLoading(`${ApiAddresses.SLA_LIST_PARAMS}?accountType=${accountType}`, {
    method: 'GET',
  });
};

export const apiGetSlaList = (data: SlaListPost = {}): AxiosPromise => {
  return authorizedRequestWithLoading(ApiAddresses.SLA_LIST, {
    method: 'POST',
    data,
  });
};

export const apiGetWorkflowNotifications = (data: Record<string, any>): AxiosPromise => {
  return authorizedRequestWithLoading(ApiAddresses.WORKFLOW_NOTIFICATIONS, {
    method: 'POST',
    data,
  });
};

export const apiGetSoftwareNotifications = (data: Record<string, any>): AxiosPromise => {
  return authorizedRequestWithLoading(ApiAddresses.SOFTWARE_NOTIFICATIONS, {
    method: 'POST',
    data,
  });
};

export const apiResolveSystemError = (data: { id: string; type: string }): AxiosPromise => {
  return authorizedRequestWithLoading(ApiAddresses.SYSTEM_ERROR_RESOLVE, {
    method: 'POST',
    data,
  });
};

export const apiResolveWorkflowError = (data: { id: string; type: string }): AxiosPromise => {
  return authorizedRequestWithLoading(ApiAddresses.WORKFLOW_ERROR_RESOLVE, {
    method: 'POST',
    data,
  });
};

export const apiGetPaymentHistoryWithCustomer = (data: TypicalPostGetParameters): AxiosPromise => {
  return authorizedRequestWithLoading(ApiAddresses.PAYMENT_HISTORY_WITH_CUSTOMER, {
    method: 'POST',
    data,
  });
};

export const apiGetContractNotifications = (data: TypicalPostGetParameters): AxiosPromise => {
  return authorizedRequestWithLoading(ApiAddresses.CONTRACT_NOTIFICATIONS, {
    method: 'POST',
    data,
  });
};

export const apiRevertPayment = (id: string): AxiosPromise => {
  return authorizedRequestWithLoading(ApiAddresses.REVERT_PAYMENT + id, {
    method: 'DELETE',
  });
};

export const apiGetReducedSLAFilters = (data: SlaListPost): AxiosPromise => {
  return authorizedRequestWithLoading(ApiAddresses.SLA_LIST_FILTERS, {
    method: 'POST',
    data,
  });
};

export const apiAddAdditionalLanguage = (data: Lang, formData: FormData): AxiosPromise => {
  return authorizedRequest(
    `${ApiAddresses.SETTINGS_ADDITIONAL_LANGUAGE_POST}?language=${data.name}&abbreviation=${data.abbreviation}`,
    {
      method: 'POST',
      headers: { 'Content-Type': 'multipart/form-data' },

      data: formData,
    },
  );
};

export const apiUploadZIPHelper = (formData: FormData): AxiosPromise => {
  return authorizedRequest(`${ApiAddresses.UPLOAD_HELPER}`, {
    method: 'POST',
    headers: { 'Content-Type': 'multipart/form-data' },
    data: formData,
  });
};

export const apiGetWorkflowStepKeys = (): AxiosPromise<string[]> => {
  return authorizedRequestWithLoading(ApiAddresses.SETTINGS_WORKFLOW_STEP_KEYS, {
    method: 'GET',
  });
};

export const apiUpdateWorkflowStepKeys = (data: string[]): AxiosPromise<string[]> => {
  return authorizedRequestWithLoading(ApiAddresses.SETTINGS_WORKFLOW_STEP_KEYS, {
    method: 'POST',
    data,
  });
};

export const apiGetSoftwareInstallCodes = (companyId: string): AxiosPromise => {
  return authorizedRequestWithLoading(`${ApiAddresses.SOFTWARE_INSTALL_CODES}?companyId=${companyId}`, {
    method: 'GET',
  });
};

export const apiSendSoftwareInstallCode = (data: { code: string; email: string }, companyId: string): AxiosPromise => {
  return authorizedRequestWithLoading(`${ApiAddresses.SOFTWARE_INSTALL_CODES}?companyId=${companyId}`, {
    method: 'POST',
    data,
  });
};

export const apiGenerateSoftwareInstallCode = (companyId: string): AxiosPromise => {
  return authorizedRequestWithLoading(`${ApiAddresses.GENERATE_SOFTWARE_INSTALL_CODE}?companyId=${companyId}`, {
    method: 'GET',
  });
};

export const apiActivateNewAccount = (hash: string): AxiosPromise<{ id: 'string'; email: 'string' }> => {
  const data: { tokenForReset: string } = {
    tokenForReset: hash,
  };

  return axiosInstance.post(ApiAddresses.ACCOUNT_ACTIVATE, data);
};

export const retryPayment = (): AxiosPromise => {
  return authorizedRequest(`${ApiAddresses.RETRY_PAYMENT}`, {
    method: 'POST',
  });
};

export const getStripeSetupIntent = (): AxiosPromise => {
  return authorizedRequest(`${ApiAddresses.STRIPE_SETUP_INTENT}`, {
    method: 'POST',
  });
};

export const getAccountWithCards = (): AxiosPromise => {
  return authorizedRequest(`${ApiAddresses.ACCOUNT_WITH_CARDS}`, {
    method: 'GET',
  });
};

export const deletePaymentMethod = (paymentMethodId: string): AxiosPromise => {
  return authorizedRequest(`${ApiAddresses.DELETE_PAYMENT_METHOD}`, {
    method: 'POST',
    data: { paymentMethodId },
  });
};

export const getSubscription = (): AxiosPromise => {
  return authorizedRequest(`${ApiAddresses.GET_SUBSCRIPTION}`, {
    method: 'GET',
  });
};

export const createSubscription = (body: any): AxiosPromise => {
  return authorizedRequest(`${ApiAddresses.CREATE_SUBSCRIPTION}`, {
    method: 'POST',
    data: body,
  });
};

export const updateSubscription = (body: any): AxiosPromise => {
  return authorizedRequest(`${ApiAddresses.UPDATE_SUBSCRIPTION}`, {
    method: 'POST',
    data: body,
  });
};

export const cancelSubscription = (): AxiosPromise => {
  return authorizedRequest(`${ApiAddresses.CANCEL_SUBSCRIPTION}`, {
    method: 'POST',
  });
};

export const renewSubscription = (): AxiosPromise => {
  return authorizedRequest(`${ApiAddresses.RENEW_SUBSCRIPTION}`, {
    method: 'POST',
  });
};

export const startTrial = (data: any): AxiosPromise => {
  return authorizedRequest(`${ApiAddresses.START_TRIAL}`, {
    method: 'POST',
    body: data,
  });
};

// Resources Usage Report
export const getResourcesUsageReportParams = (data: ResourcesUsageReportParams): AxiosPromise => {
  return authorizedRequest(`${ApiAddresses.RESOURCES_USAGE_REPORT_PARAMS}`, {
    method: 'POST',
    data: data,
  });
};

export const getResourcesUsageReport = (data: ResourcesUsageReportBasic): AxiosPromise => {
  return authorizedRequest(`${ApiAddresses.RESOURCES_USAGE_REPORT}`, {
    method: 'POST',
    data: data,
  });
};

export const apiGetServersDashboard = (accountType: string, data?: ServersDashboardData): AxiosPromise => {
  if (data) {
    return authorizedRequest(`${ApiAddresses.GET_SERVERS_DASHBOARD}?accountType=${accountType}`, {
      method: 'POST',
      data: data,
    });
  } else {
    return authorizedRequest(`${ApiAddresses.GET_SERVERS_DASHBOARD}?accountType=${accountType}`, {
      method: 'POST',
    });
  }
};

export const agiGetServersDashboardChartList = (data: ServersChartList): AxiosPromise => {
  return authorizedRequest(`${ApiAddresses.GET_SERVERS_DASHBOARD_CHART_LIST}`, {
    method: 'POST',
    data: data,
  });
};

// Billing History
export const apiGetBillingHistory = (data: IBillingHistory): AxiosPromise => {
  return authorizedRequest(`${ApiAddresses.GET_BILLING_HISTORY}`, {
    method: 'POST',
    data: data,
  });
};

export const apiGetOrganizationUsersList = (): AxiosPromise => {
  return authorizedRequest(`${ApiAddresses.GET_ORGANIZATION_USERS_LIST}`, {
    method: 'GET',
  });
};

export const apiGetBillingHistoryInvoice = (invoiceNumber: string): AxiosPromise => {
  return authorizedRequest(`${ApiAddresses.GET_BILLING_HISTORY_INVOICE}?InvoiceNumber=${invoiceNumber}`, {
    method: 'GET',
    responseType: 'blob',
  });
};

// JSON language

export const apiGetJSONLanguage = (file: string): AxiosPromise => {
  return authorizedRequest(`${apiAddresses.API_URL}/settings/languages/files/${file}`, {
    method: 'GET',
    responseType: 'blob',
  });
};

// WORKFLOW FILES MONITORING
export const apiGetWorkflowFilesMonitoringUsers = (): AxiosPromise => {
  return authorizedRequest(`${ApiAddresses.GET_WORKFLOW_FILES_USERS_LIST}`, {
    method: 'GET',
  });
};

export const apiGetWorkflowFilesMonitoringCharts = (body: {
  interval: number;
  step: number;
  units: string;
  date: any;
  tz: string;
  filters: {
    accountType: string;
    serverIds: string[];
    companyIds: string[];
    organizationIds: string[];
    companyOrOrganization: string;
  };
}): AxiosPromise => {
  return authorizedRequest(`${ApiAddresses.GET_WORKFLOW_FILES_CHARTS}`, {
    method: 'POST',
    data: body,
  });
};

// DISC QUOTA
export const apiGetDiscQuotaUsers = (): AxiosPromise => {
  return authorizedRequest(`${ApiAddresses.GET_DISC_QUOTA_USERS_LIST}`, {
    method: 'GET',
  });
};

export const apiGetDiscQuotaCharts = (body: {
  interval: number;
  step: number;
  units: string;
  agentId: string;
  date: any;
  tz: string;
  filters: {
    accountType: string;
    companyIds: string[];
    organizationIds: string[];
    companyOrOrganization: string;
  };
}): AxiosPromise => {
  return authorizedRequest(`${ApiAddresses.GET_DISC_QUOTA_CHARTS}`, {
    method: 'POST',
    data: body,
  });
};

// APP MONITORING
export const apiGetAllApps = (serverId: string): any =>
  authorizedRequest(`${ApiAddresses.GET_ALL_APPS}?serverId=${serverId}`, {
    method: 'GET',
  });

export const apiGetMonitoredApps = (serverId: string): any =>
  authorizedRequest(`${ApiAddresses.GET_MONITORED_APPS}?serverId=${serverId}`, {
    method: 'GET',
  });

export const apiUpdateMonitoredApps = (serverId: string, data: UpdateMonitoredAppsProps): any =>
  authorizedRequest(`${ApiAddresses.UPDATE_MONITORED_APPS}?serverId=${serverId}`, {
    method: 'PUT',
    data,
  });
