export const findLast = (array: any[], validate: (value: any) => boolean): [any, number] => {
  for (let i = array.length - 1; i >= 0; --i) {
    const x = array[i];
    if (validate(x)) {
      return [x, i];
    }
  }
  return [undefined, -1];
};

export const find = (array: any[], validate: (value: any) => boolean): [any, number] => {
  for (let i = 0; i < array.length; i++) {
    const x = array[i];
    if (validate(x)) {
      return [x, i];
    }
  }
  return [undefined, -1];
};

export const throttle = (callback: (...args: any[]) => any, delay: number): ((...args: any[]) => any) => {
  let timeoutId: number;
  let lastArgs: any[];

  return (...args: any[]): void => {
    lastArgs = args;

    if (timeoutId) return;

    timeoutId = window.setTimeout(() => callback(...lastArgs), delay);
  };
};

export const debounce = (callback: (...args: any[]) => any, delay: number): ((...args: any[]) => any) => {
  let timeoutId: number | null;

  return (...args: any[]): void => {
    if (timeoutId) {
      window.clearTimeout(timeoutId);
    }

    timeoutId = window.setTimeout(() => {
      callback(...args);
      timeoutId = null;
    }, delay);
  };
};

export const createDebounceClosure = (delay: number): ((callback: (...args: any[]) => any) => any) => {
  let timeoutId: number | null;

  return (callback: (...args: any[]) => any, ...args: unknown[]): void => {
    if (timeoutId) {
      window.clearTimeout(timeoutId);
    }

    timeoutId = window.setTimeout(() => {
      callback(...args);
      timeoutId = null;
    }, delay);
  };
};

export const getUTCDate = (date: string) => {
  const initialDate = new Date(date);
  return new Date(Date.UTC(initialDate.getUTCFullYear(), initialDate.getUTCMonth(), initialDate.getUTCDate()));
};
