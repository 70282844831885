import React, { FC } from 'react';
import Dialog from '@material-ui/core/Dialog';
import { useStyles } from './styles';
import { useTranslation } from 'react-i18next';
import { Icon } from 'components/UI/Icon';
import cn from 'classnames';

interface Props {
  open: boolean;
  onClose: (confirm: boolean) => any;
  captionKey?: string;
  messageKey?: string;
  haveErrors?: boolean;
}

const MessageModalWithCloseButton: FC<Props> = ({
  onClose,
  open,
  captionKey = 'Payment card is not connected',
  messageKey = 'Please add the card and try the purchasing once again',
}: Props) => {
  // Styling
  const styles = useStyles();
  // Translation
  const { t } = useTranslation();

  return (
    <Dialog open={open} onBackdropClick={(): void => onClose(false)}>
      <div className={cn(styles.DisableProtectionModal, styles['jc-fs'], styles['DisableProtectionModalMessage'])}>
        <div className={styles.modalCLose} onClick={(): void => onClose(false)}>
          <Icon name={'close'} />
        </div>
        <div className={styles.modalHeader}>{t(captionKey)}</div>
        <div className={cn(styles.modalMessage, styles['mt10'])}>{t(messageKey)}</div>
      </div>
    </Dialog>
  );
};

export default MessageModalWithCloseButton;
