import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  accountParameters: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100vh',
    backgroundColor: theme.palette.background.default,
    justifyContent: 'center',
    '& form': {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      maxWidth: 1400,
    },
  },
  formWrapper: {
    display: 'flex',
    flexDirection: 'column',
    background: theme.palette.background.paper,
    margin: '48px auto 0',
    overflow: 'auto',
    padding: '0 32px 32px',
    borderRadius: 16,
    width: 'calc(100vw - 40px)',
    maxHeight: 'calc(100vh - 205px)',
    maxWidth: 1400,
    '& .overflow-hidden-wrapper': {
      border: '1px solid #E9E9EE',
      borderRadius: 16,
      overflow: 'hidden',
    },
  },
  cardWithoutButtons: {
    maxHeight: 'calc(100vh - 165px)',
  },
  modalWrapper: {
    background: theme.palette.background.paper,
    padding: '30px 24px',
    borderRadius: 24,
  },
  tableContainer: {
    '& tr > th:first-child': {
      width: '80px',
    },
    '& tr > th:nth-child(2), & tr > th:last-child': {
      width: '200px',
    },
    '& tr:nth-child(even)': {
      background: theme.palette.background.paper,
    },
    '& tr:nth-child(odd)': {
      background: '#F8F8FB',
    },
    '& thead th': {
      paddingTop: 2,
      paddingBottom: 2,
    },
  },
  ['blocksWrapper--w1400']: {
    display: 'flex',
    flexDirection: 'column',
    minWidth: '54rem',
    maxWidth: '1400px',
    width: '100%',
    minHeight: 'min-content',
    justifyContent: 'flex-start',
    alignSelf: 'center',

    '&:last-of-type': {
      borderBottom: 'none',
    },
    '@media (max-width: 54em)': {
      alignSelf: 'end',
    },
  },
  blockContainer: {},
  blockHeader: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'space-between',
    fontWeight: 700,
    fontSize: 30,
    lineHeight: '40px',
    color: '#212121',
    marginBottom: 22,
    marginTop: 32,
  },
  alignEndBlock: {
    justifyContent: 'flex-end',
    display: 'flex',
    margin: '36px 0 1rem',
  },
  blockDiscount: {
    display: 'flex',
    height: '32px',
    backgroundColor: '#fff',
    border: '1px solid #D4D2D2',
  },
  discountText: {
    borderRight: '2px solid #D4D2D2',
  },
  discountValue: {
    fontWeight: 700,
  },
  blockDataSettings: {
    display: 'flex',
    '& .error': {
      top: 70,
      left: 0,
    },
  },
  dataSettingsContent: {
    marginRight: 16,
  },
  dataSettingsText: {
    margin: '8px 0 0',
    fontWeight: 400,
    fontSize: 13,
    lineHeight: '16px',
    color: '#666666',
  },
  button: {
    width: 140,
    marginLeft: 24,
  },
  blockRowGrid: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, minmax(10rem, 1fr))',
    columnGap: 22,
    rowGap: 22,
    alignItems: 'end',
  },
  'blockRowGrid--с3': {
    gridTemplateColumns: 'repeat(3, minmax(10rem, 1fr))',
  },
  fourColumnRow: {
    display: 'grid',
    gridTemplateColumns: 'repeat(4, 1fr)',
    columnGap: 16,
    rowGap: 22,
  },
  flex: {
    display: 'flex',
    gap: 20,
  },
  flex10: {
    display: 'flex',
    gap: 10,
  },
  flexToggleContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  accessContainer: {
    display: 'grid',
    gridTemplateColumns: 'repeat(4, minmax(10rem, 1fr))',
    gridGap: '0 32px',
  },
  accessItem: {
    '& > div:last-child': {
      marginLeft: '6px',
    },
  },
  inputTable: {
    '& input, & > div > div, & > div': {
      padding: 0,
      border: 'unset',
      backgroundColor: 'transparent',
      boxShadow: 'unset',
      margin: 0,
    },
    '& input + div': {
      height: 'fit-content',
      fontWeight: 'bold',
      lineHeight: '19px',
      top: '-0.3rem',
    },
  },
  arrow: {
    display: 'inline-flex',
    transform: 'translateY(2px)',
  },
  popupContainer: {
    position: 'fixed',
    zIndex: 1000,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100vw',
    height: '100vh',
    backgroundColor: '#21212133',
  },
  annualDiscount: {
    '& > div': {
      boxShadow: 'unset',
      borderLeft: 'unset',
      borderRight: 'unset',

      '& input': {
        fontWeight: 700,
      },
    },
  },
  OrganizationAccountParameters: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100vh',
    backgroundColor: theme.palette.background.default,
  },
  saveButton: {},
  saveButtonCenter: {
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  blockSubscription: {},
  blockSubscriptionTitle: {
    fontSize: 20,
    lineHeight: '24px',
    fontWeight: 700,
    color: '#212121',
    margin: '0 0 6px 0',
  },
  blockSubscriptionActive: {
    borderColor: '#80B236',
  },

  blockSubscriptionActiveText: {
    color: '#80B236',
  },
  blockSubscriptionActiveRenews: {
    whiteSpace: 'nowrap',
    fontWeight: 500,
  },
  fitContentButton: {
    width: 140,
    marginTop: 22,
  },
  blockSubscriptionContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    backgroundColor: 'transparent',
  },
  greyBlock: {
    background: theme.palette.background.default,
  },
  blockSubscriptionExpired: {
    borderColor: '#DD1B1B',
  },
  blockSubscriptionExpiredTrial: {
    flexDirection: 'column',
  },
  blockSubscriptionExpiredText: {
    color: '#DD1B1B',
  },
  blockSubscriptionExpiredWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    justifyContent: 'space-between',
    '& > span:nth-child(2)': {
      marginTop: '10px',
    },
  },
  blockSubscriptionExpiredRenew: {
    display: 'grid',
    alignItems: 'center',
    gridTemplateColumns: '18px 1fr',
    gridGap: '8px',
  },
  blockSubscriptionText: {
    margin: 0,
    color: '#8E909D',
    fontSize: 13,
    lineHeight: '140%',
    maxWidth: 260,
    fontWeight: 500,
  },
  bestValue: {
    position: 'absolute',
    color: '#fff',
    backgroundColor: '#50AC65',
    alignItems: 'center',
    display: 'flex',
    padding: '0 14px',
    height: 28,
    justifyContent: 'center',
    top: '-14px',
    right: 15,
    borderRadius: 8,
  },
  bestValueContainer: {
    position: 'relative',
  },
  isActiveSubscription: {},
  isActiveSubscriptionShadow: {},
  isBestPriceSubscription: {
    boxShadow: 'none',
  },
  transparentBorder: {
    borderWidth: '2px',
    borderColor: '#E9E9EE',
    borderStyle: 'solid',
    borderRadius: 16,
    color: '#212121',
    padding: 24,
    background: '#F8F8FA',
    minHeight: 120,
    display: 'flex',
    gap: 16,
    position: 'relative',
    cursor: 'pointer',
  },
  flexEnd: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  blockList: {
    display: 'flex',
    flexWrap: 'wrap',
    marginTop: '27px',
    gap: 10,
    justifyContent: 'space-between',
  },
  blockListItem: {
    display: 'flex',
    flexDirection: 'column',
    padding: '0 10px',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },
  blockListItemTitle: {
    display: 'inline-block',
    fontWeight: 400,
    fontSize: 13,
    lineHeight: '16px',
    margin: '0 0 4px 0',
    color: '#8E909D',
  },
  blockListItemText: {
    display: 'inline-block',
    fontWeight: 400,
    fontSize: 13,
    lineHeight: '16px',
    color: '#212121',
  },
  'blockRowGrid--с2': {
    gridTemplateColumns: '1fr 1fr',
  },
  'blockRowGrid--c2-r2': {
    gridTemplateColumns: '1.5fr 1fr',
    gridTemplateRows: '1fr 1fr',
    alignItems: 'center',
  },
  blockRowGridRight: {
    gridColumn: '2 / 2',
    gridRow: '2/ 2',
  },
  blockRowGridLeft: {
    gridColumn: '1 / 1',
    gridRow: '1 / 3',
    height: '100%',
  },
  buttonPayment: {
    position: 'relative',
    '& > *': {
      width: '100%',
      margin: 0,
    },
  },
  minWidth: {
    width: 'fit-content',
    gap: 24,
  },
  tooltipAddCard: {
    position: 'absolute',
    width: '207px',
    height: '32px',
    top: 0,
  },
  disabledButton: {
    backgroundColor: '#C4C4C4 !important',
    cursor: 'unset',
    pointerEvents: 'none',
  },
  nextCharge: {
    background: '#E9E9EE',
    borderRadius: 16,
    padding: '0 10px 0 7px',
    color: '#0F65E5',
    height: 27,
    display: 'flex',
    fontWeight: 400,
    alignItems: 'center',
    whiteSpace: 'break-spaces',
    marginBottom: '6px',
    '& svg': {
      width: 14,
      marginRight: 7,
    },
  },
  newPlan: {
    background: '#F5EEE0',
    borderRadius: 16,
    padding: '0 10px 0 7px',
    color: '#C8870A',
    height: 27,
    display: 'flex',
    alignItems: 'center',
    fontWeight: 400,
    alignSelf: 'flex-start',
    marginBottom: '6px',
    '& svg': {
      width: 14,
      marginRight: 7,
    },
  },
  expiresOn: {
    background: '#F6E1E5',
    borderRadius: 16,
    padding: '0 10px 0 7px',
    fontWeight: 400,
    color: '#CE3954',
    height: 27,
    display: 'flex',
    alignItems: 'center',
    whiteSpace: 'break-spaces',
    marginBottom: '6px',
    '& svg': {
      width: 10,
      marginRight: 7,
    },
  },
  alignCenterFlexBox: {
    display: 'flex',
    alignItems: 'center',
    gap: 8,
  },
  absoluteButtonBlock: {
    position: 'absolute',
    right: 0,
    bottom: 0,
  },
  relative: {
    position: 'relative',
    width: '100%',
  },
  // payment block
  blockPaymentCard: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    background: '#F8F8FA',
    border: '1px solid #E9E9EE',
    borderRadius: 16,
    padding: '12px 24px',
    marginBottom: 16,
  },
  blockRetry: {
    display: 'flex',
    alignItems: 'center',
  },
  addCard: {
    fontSize: '13px',
    color: '#666666',
  },
  cardInfo: {
    display: 'flex',
    alignItems: 'center',
  },
  card: {
    marginRight: '21px',
  },
  cardText: {
    fontWeight: 700,
    fontSize: 16,
    lineHeight: '20px',
    color: '#212121',
    marginRight: 16,
  },
  // Stripe
  stripe: {
    margin: '0 20px',
  },
  stripeContainer: {
    width: '100%',
  },
  cardElement: {
    color: '#8E909D',
    width: '100%',
    border: '2px solid #E9E9EE',
    height: '40px',
    padding: '10px 16px',
    margin: '0',
    fontSize: '14px',
    fontWeight: 400,
    backgroundColor: '#FFFFFF',
    borderRadius: 10,
  },
  cardElementWrapper: {
    padding: '0.2rem',
    '& label': {
      color: '#666',
      display: 'inline-block',
      padding: '0 0 6px 17px',
      fontSize: 13,
      fontWeight: 400,
      lineHeight: '16px',
    },
  },
  buttonPaymentLarge: {
    maxWidth: '100%',
    padding: '0.2rem',
  },
  test: {
    color: 'black',
    width: '100%,',
    border: '1px solid rgba(212, 210, 210, 1)',
    height: '2rem',
    outline: 'none',
    padding: '0.3rem 0.5rem',
    fontSize: '0.8125rem',
    fontWeight: 400,
    backgroundColor: '#FFFFFF',
  },
  isMaximumReached: {
    color: '#CE3954',
  },
  trialTextWithIcon: {
    display: 'flex',
    alignItems: 'center',
    gap: 6,
  },
  summary: {},
  fullWidthButton: {
    width: '100%',
    '& button': {
      width: '100%',
      maxWidth: 270,
      minWidth: 270,
    },
  },
  summaryContainer: {
    display: 'flex',
    alignItems: 'center',
    padding: '15px 15px 15px 25px',
    width: 'fit-content',
    gap: 10,
    borderRadius: 16,
    minWidth: 300,
    '& div button': {
      width: 'auto',
      minWidth: 'auto',
      alignSelf: 'flex-end',
    },
  },
  summaryContainerWithAdditionalCharge: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: 32,
    background: '#F8F8FA',
    color: '#212121',
    borderRadius: 16,
    maxWidth: 'fit-content',
    justifyContent: 'space-between',
    '& > div:nth-child(3)': {
      margin: '10px 0 0 0',
      width: '100%',
    },
    '& div button': {
      width: 270,
      minWidth: 270,
    },
  },
  summaryBlockItems: {
    '& > div:first-child': {
      marginRight: '32px',
    },
  },
  submitButton: {
    marginTop: 10,
    display: 'flex',
    flexDirection: 'column',
  },
  info: {
    '&:hover path': {
      fill: '#212121',
    },
  },
  tableWrapper: {
    background: 'rgba(33, 33, 33, 0.5)',
    zIndex: 1,
    top: 0,
    left: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'fixed',
    width: '100vw',
    height: '100vh',
  },
  tableClose: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '12px 11px',
    fontWeight: 700,
    fontSize: '30px',
    lineHeight: '40px',
    color: '#212121',
    marginBottom: 24,
  },
  subscriptionList: {
    margin: 0,
    padding: 0,
    listStyle: 'none',
    fontWeight: 500,
    fontSize: 14,
    lineHeight: '17px',
    color: '#666666',
    marginTop: 24,
    '& li': {
      marginBottom: 8,
    },
  },
  iconWrapper: {
    '& svg': {
      minWidth: 20,
    },
  },
  boldText: {
    fontWeight: 700,
    fontSize: 20,
    lineHeight: '20px',
    marginBottom: 8,
  },
  lightText: {
    fontWeight: 500,
    fontSize: 14,
    marginBottom: 10,
  },
  paymentDescription: {
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '17px',
    color: '#666666',
    display: 'flex',
    alignItems: 'center',
    marginBottom: 24,
    '& svg': {
      marginRight: 10,
    },
  },
  expirationBlock: {
    background: '#E9E9EE',
    border: '1px solid #BFC0CD',
    borderRadius: 8,
    padding: '6px 10px',
    fontWeight: 400,
    fontSize: 13,
    lineHeight: '16px',
    color: '#212121',
  },
  cardPaymentError: {
    marginBottom: 0,
    '& svg': {
      width: 13,
    },
  },
  alignTop: {
    alignItems: 'flex-start',
  },
  additionalChargeContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: 32,
    background: '#F8F8FA',
    color: '#212121',
    borderRadius: 16,
    maxWidth: 'fit-content',
    '& div button': {
      width: 'auto',
      minWidth: 'auto',
    },
    '& > div:nth-child(1)': {
      justifyContent: 'space-between',
      display: 'flex',
      width: '100%',
    },
    '& > div:nth-child(2)': {
      justifyContent: 'space-between',
      display: 'flex',
      width: '100%',
    },
    '& > div:nth-child(3)': {
      margin: '10px 0 0 0',
      '& > button': {
        width: '100%',
      },
    },
  },
  /////////////////
  // Atomic Css //
  ////////////////
  marginBottom: {
    marginBottom: '60px',
  },
  ['pb20']: {
    paddingBottom: '20px',
  },
  ['pb40']: {
    paddingBottom: '40px',
  },
  ['mt30']: {
    marginTop: '30px',
  },
  ['mt35']: {
    marginTop: '35px',
  },
  ['mt10']: {
    marginTop: '10px',
  },
  ['mr15']: {
    marginRight: '15px',
  },
  ['ml5']: {
    marginLeft: '5px',
  },
  ['mlr13']: {
    marginLeft: '13px',
    marginRight: '13px',
  },
  ['alignSelf--fs']: {
    alignSelf: 'flex-start',
  },
  ['alignSelf--fe']: {
    alignSelf: 'flex-end',
  },
  ['flex--jc-sb']: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  ['flex--ai-c']: {
    display: 'flex',
    alignItems: 'center',
    gap: 10,
    marginBottom: 5,
  },
  ['al-fe']: {
    alignItems: 'flex-end',
  },
  ['fw--700']: {
    fontWeight: 700,
  },
  ['fw--600']: {
    fontWeight: 600,
  },
  ['fs--14']: {
    fontSize: '14px !important',
  },
  ['fs--30']: {
    fontSize: '30px',
  },
  ['tt--up']: {
    textTransform: 'uppercase',
  },
  ['w100%']: {
    width: '100%',
  },
  ['m27-0']: {
    margin: '27px 0',
  },
  ['mt22']: {
    marginTop: 22,
  },
  ['mt2-svg']: {
    '& svg': {
      marginTop: 3,
    },
  },
  ['df--jc-sb']: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  ['ws-nw--all']: {
    '& *': {
      whiteSpace: 'nowrap',
    },
  },
  ['ws-nw']: {
    whiteSpace: 'nowrap',
  },
  cursorPointer: {
    cursor: 'pointer',
  },
  ['ml28']: {
    marginLeft: '28px',
  },
  ['mr25']: {
    marginRight: '25px',
  },
  ['mt39']: {
    marginTop: '39px',
  },
  ['pb49']: {
    paddingBottom: '49px',
  },
  ['width-247']: {
    width: '247px',
  },
  ['fc']: {
    display: 'flex',
  },
  ['span--ml-0']: {
    '& span': {
      marginLeft: 0,
    },
  },
  ['mw-489']: {
    maxWidth: '489px',
  },
  ['p-0-24']: {
    padding: '0 24px',
  },
  ['up-case']: {
    textTransform: 'uppercase',
  },
  ['flex-center']: {
    display: 'flex',
    alignItems: 'center',
  },
  ['w-200']: {
    width: '200px',
  },
  ['h24']: {
    height: 24,
  },
}));
