import { Language, ThemeBrandColor, UIActionTypes, UIActions, WorkflowStepType } from './types';
import { Dispatch } from 'redux';
import { apiGetLanguages, apiGetLiveResponse, apiGetTheme, apiGetWorkflowStepTypes } from 'api';
import { showErrorNotification } from 'utils/notifications';
import { customerParametersSetWorkflowStepTypes } from '../customerParameters/actions';
import { UserRolesEnum } from 'utils/types';

/**
 * Starts loading
 */
export function uILoadingStart(): UIActions {
  return {
    type: UIActionTypes.UI_LOADING_START,
  };
}

/**
 * Finishes loading
 */
export function uILoadingFinish(): UIActions {
  return {
    type: UIActionTypes.UI_LOADING_FINISH,
  };
}

/**
 * Toggles drawer or set it's state if received explicitly
 * @param {boolean} isDrawerOpened
 */
export function uIToggleDrawer(isDrawerOpened?: boolean): UIActions {
  return {
    type: UIActionTypes.UI_TOGGLE_DRAWER,
    payload: isDrawerOpened,
  };
}

/**
 * Sets languages array
 * @param {Language[]} languages
 */
export function uISetLanguages(languages: Language[]): UIActions {
  return {
    type: UIActionTypes.UI_SET_LANGUAGES,
    payload: languages,
  };
}

/**
 * Sets languages array
 * @param {WorkflowStepType[]} workflowStepTypes
 */
export function uISetWorkflowStepTypes(workflowStepTypes: WorkflowStepType[]): UIActions {
  return {
    type: UIActionTypes.UI_SET_WORKFLOW_STEP_TYPES,
    payload: workflowStepTypes,
  };
}

export function uISetTheme(themeBrandColor: ThemeBrandColor): UIActions {
  return {
    type: UIActionTypes.UI_SET_THEME,
    payload: themeBrandColor,
  };
}

export function uISetStripeEnabled(rule: boolean): UIActions {
  return {
    type: UIActionTypes.UI_SET_STRIPE_ENABLED,
    payload: rule,
  };
}

export const uIGetLiveResponse = () => {
  return async (dispatch: Dispatch): Promise<any> => {
    dispatch(uILoadingStart());
    try {
      await apiGetLiveResponse();
    } catch (e) {
      showErrorNotification(e as any);
    } finally {
      dispatch(uILoadingFinish());
    }
  };
};
/**
 * Fetches languages and dispatches uISetLanguages action
 */

export const uIGetLanguages = () => {
  return async (dispatch: Dispatch): Promise<any> => {
    dispatch(uILoadingStart());
    try {
      const response = await apiGetLanguages();
      if (response && response.data) {
        dispatch(uISetLanguages(response.data.languages));
      }
    } catch (e) {
      showErrorNotification(e as any);
    } finally {
      dispatch(uILoadingFinish());
    }
  };
};

/**
 * Fetches Step Types and dispatches uISetWorkflowStepTypes action
 */
export const uIGetWorkflowStepTypes = () => {
  return async (dispatch: (arg: any) => void, getState: () => Record<string, any>): Promise<any> => {
    dispatch(uILoadingStart());
    try {
      const isOrganization = getState().session.user && getState().session.user.role === UserRolesEnum.organization;
      const response = await apiGetWorkflowStepTypes(isOrganization);

      if (response && response.data) {
        const steps = isOrganization ? response.data.stepTypes : response.data.steps;

        dispatch(uISetWorkflowStepTypes(steps));

        dispatch(customerParametersSetWorkflowStepTypes(steps));
      }
    } catch (e) {
      showErrorNotification(e as any);
    } finally {
      dispatch(uILoadingFinish());
    }
  };
};

/**
 * Fetches Step Types and dispatches uISetWorkflowStepTypes action
 */
export const uIGetTheme = () => {
  return async (dispatch: (arg: any) => void): Promise<any> => {
    dispatch(uILoadingStart());
    try {
      const response = await apiGetTheme();
      if (response && response.data) {
        dispatch(uISetTheme(response.data.theme));
        dispatch(uISetStripeEnabled(response.data.isStripeEnabled));
      }
    } catch (e) {
      showErrorNotification(e as any);
    } finally {
      dispatch(uILoadingFinish());
    }
  };
};

export const uiResetState = (): { type: string } => ({ type: UIActionTypes.UI_RESET_STATE });
