import React, { FC } from 'react';
import { TableCell } from '@material-ui/core';

import { useStyles } from './styles';

const FilterCellTimeRange: FC = () => {
  // Styling
  const styles = useStyles();

  return (
    <TableCell className={[styles.filterCell].join(' ')}>
      <div className={styles.cellContent} />
    </TableCell>
  );
};

export default FilterCellTimeRange;
