import { timezones } from 'consts';

export const getCurrentTimeZone = (): string => {
  const iana = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const currentTimeZone = timezones.find((item) => {
    return item.utc.find((utc) => {
      return utc === iana;
    });
  });
  return (currentTimeZone && currentTimeZone.value) || '';
};
