import { Dispatch } from 'redux';
import * as storage from 'utils/storage';
import { SessionActionTypes, SessionActions, SessionTokens, User } from 'store/session/types';
import { Credentials } from 'api/models';
import {
  apiActivateNewAccount,
  apiAdminChangePassword,
  apiForgotPasswordRequestEmail,
  apiForgotPasswordSetNewPassword,
  apiGetUser,
  apiResendActivation,
  apiSignIn,
  apiSignUpAccount,
  apiSignUpOrganization,
  apiUserChangePassword,
  apiVerifyAccount,
  initRefreshTokens,
} from 'api';
import { AxiosResponse } from 'axios';
import { removeSession, saveSession, setSessionSettings } from 'utils/storage';
import {
  uIGetLanguages,
  uIGetLiveResponse,
  uIGetTheme,
  uIGetWorkflowStepTypes,
  uILoadingFinish,
  uILoadingStart,
} from 'store/ui/actions';
import { Dispatcher } from 'store';
import { NotificationTypes, showErrorNotification, showNotification } from 'utils/notifications';
import i18n from 'utils/i18n';
import { tvResetState } from 'store/customerTableView/actions';
import { uiResetState } from 'store/ui/actions';
import { adResetState } from 'store/adminDashboard/actions';
import { SignUpFormTypeEnum } from 'components/AuthorizationForms/SignUp/SignUp';
import { getCardsInfo } from '../customerParameters/actions';
import { AccountTypesEnum } from 'utils/types';
import { UserRolesEnum } from 'utils/types';

/**
 * Logs the user out and clears a stored data
 */
export function logout(shouldNotifyTabs = true) {
  return (dispatch: Dispatch): void => {
    if (shouldNotifyTabs) {
      storage.logout();
    }
    storage.removeSession();
    dispatch({ type: SessionActionTypes.REMOVE_SESSION });
    dispatch(tvResetState());
    dispatch(uiResetState());
    dispatch(adResetState());
  };
}

/**
 * Sets session tokens
 * @param tokens new session tokens
 */
export function setTokens(tokens: SessionTokens): SessionActions {
  return {
    type: SessionActionTypes.SET_TOKENS,
    payload: { tokens },
  };
}

/**
 * Sets interface language
 * @param lng
 */
export function setLanguage(lng: string): SessionActions {
  return {
    type: SessionActionTypes.SET_LANGUAGE,
    payload: lng,
  };
}

/**
 * Writes user data to redux store to
 * session.user
 * @param user
 */
export function getUserSuccess(user: User): SessionActions {
  return {
    type: SessionActionTypes.GET_USER_SUCCESS,
    payload: { user },
  };
}

/**
 * Makes a request to get the latest user's account data
 */
export function getUser(): any {
  return async (dispatch: any): Promise<void> => {
    dispatch({ type: SessionActionTypes.GET_USER_START });
    dispatch(uILoadingStart());

    try {
      const response: AxiosResponse<any> = await apiGetUser();
      if (
        response.data.details.role === UserRolesEnum.organizationUser ||
        (response.data.details.role === UserRolesEnum.accountUser &&
          response.data.details.accountType === AccountTypesEnum.selfServed)
      ) {
        dispatch(getCardsInfo());
      }

      if (response && response.data) {
        dispatch(
          getUserSuccess({
            ...response.data.details,
            productType: response.data.productType,
            isInOrganization: response.data.isInOrganization,
            organization: response.data.organization,
            organizationContractDates: response.data.organizationContractDates,
            itemsUsed: response.data.itemsUsed,
            paymentStatusOrganization: response.data.paymentStatus,
            isStripeEnabled: response.data.isStripeEnabled,
          }),
        );

        // const lng = localStorage.getItem('language') || response.data.details.language;TODO !!!!!
        const lng = localStorage.getItem('language') || response.data.details.language;
        i18n.changeLanguage(lng);
        dispatch(setLanguage(lng));
        storage.saveLanguage(lng);
      }
    } catch (e) {
      showErrorNotification(e as any);
      dispatch({ type: SessionActionTypes.GET_USER_FAIL });
    } finally {
      dispatch(uILoadingFinish());
    }
  };
}

/**
 * Makes a request with user's credentials to get authorization tokens
 * @param email entered email address
 * @param password entered password
 * @param keepLoggedIn save to local storage if checked
 */
export function signIn({
  email,
  password,
  keepLoggedIn,
}: {
  email: string;
  password: string;
  keepLoggedIn: boolean;
}): any {
  return async (dispatch: Dispatch): Promise<SessionTokens | null> => {
    dispatch(uILoadingStart());
    const login: string = email.trim();
    dispatch({ type: SessionActionTypes.SIGN_IN_START });

    const data: Credentials = {
      login,
      password,
    };
    try {
      const signInRes: AxiosResponse<any> = await apiSignIn(data);
      if (signInRes && signInRes.data) {
        if (!signInRes.data.needPasswordChange && !signInRes.data.isTempPassVerificationNeeded) {
          setSessionSettings(keepLoggedIn);
          saveSession(signInRes.data.tokens, signInRes.data.authorizationResult.userRole);
        } else {
          setSessionSettings(false);
          removeSession();
        }
        dispatch(setTokens(signInRes.data.tokens));
        return signInRes.data;
      }
    } catch (e) {
      const error = e as any;
      showErrorNotification(error);
      return (error.response && error.response.data) || null;
    } finally {
      dispatch(uILoadingFinish());
    }
    return null;
  };
}

/**
 * Makes a request with user's credentials to get authorization tokens
 * @param email entered email address
 * @param password entered password
 * @param docsAreAgreed documents are read
 * @param companyName entered company name
 * @param fullName entered full name name
 */
export function signUp({
  companyName,
  coordinates,
  phoneNumber,
  fullName,
  timeZone,
  formType,
  password,
  country,
  address,
  email,
}: {
  email: string;
  password: string;
  companyName: string;
  fullName: string;
  phoneNumber: string;
  country: string;
  address: string;
  coordinates: string;
  timeZone: string;
  formType: SignUpFormTypeEnum;
}): any {
  return async (dispatch: Dispatch): Promise<any> => {
    dispatch(uILoadingStart());

    const dataForAccount: Record<string, any> = {
      customer: {
        accountType: AccountTypesEnum.selfServed,
        phoneNumber,
        timeZone,
        country,
        email,
        name: fullName,
      },
      company: {
        coordinates,
        address,
        name: companyName,
      },
      password,
    };

    const dataForOrganization: Record<string, any> = {
      enterpriseAdmin: {
        email,
        name: fullName,
        phoneNumber,
        accountType: AccountTypesEnum.selfServed,
      },
      enterprise: {
        name: companyName,
      },
      password,
    };

    try {
      const response: any =
        formType === SignUpFormTypeEnum.ACCOUNT
          ? await apiSignUpAccount(dataForAccount)
          : await apiSignUpOrganization(dataForOrganization);
      if (response) {
        return response;
      }
    } catch (e) {
      const error = e as any;
      showErrorNotification(error);
      return (error.response && error.response.data) || null;
    } finally {
      dispatch(uILoadingFinish());
    }
    return null;
  };
}

/**
 * Makes a request with new password and hash
 * @param hash given hash identifier
 */
export function activateNewAccount(hash: string): any {
  return async (): Promise<any> => {
    try {
      const response: AxiosResponse<any> = await apiActivateNewAccount(hash);

      if (response && (response.status === 200 || response.status === 201)) {
        return response.data;
      }
    } catch (e) {
      const error = e as any;
      console.error(error);
      return (error.response && error.response.data) || null;
    } finally {
    }
    return null;
  };
}

/**
 * Makes a request for getting a confirmation email
 * @param email entered email address
 */
export function forgotPassword(email: string): any {
  return async (dispatch: Dispatch): Promise<any> => {
    dispatch(uILoadingStart());
    try {
      dispatch(uILoadingStart());
      const response: AxiosResponse<any> = await apiForgotPasswordRequestEmail(email);
      if (response && response.data) {
        return response.status;
      }
    } catch (e) {
      const error = e as any;
      showNotification(NotificationTypes.error, error?.response?.data?.message || 'Error');
      return (error.response && error.response.data) || null;
    } finally {
      dispatch(uILoadingFinish());
    }
  };
}

/**
 * Makes a request with new password and hash
 * @param password new entered password
 * @param hash given hash identifier
 */
export function forgotPasswordSetNewPassword(password: string, hash: string): Dispatcher<number> {
  return async (dispatch: Dispatch): Promise<number> => {
    try {
      dispatch(uILoadingStart());
      const response: AxiosResponse<any> = await apiForgotPasswordSetNewPassword(password, hash);

      if (response && (response.status === 200 || response.status === 201)) {
        dispatch({ type: SessionActionTypes.FORGOT_PASSWORD_STEP_SUCCESS });

        return response.status;
      }
    } catch (e) {
      const error = e as any;
      console.error(error);
      return (error.response && error.response.status) || 0;
    } finally {
      dispatch(uILoadingFinish());
    }
    return 0;
  };
}

/**
 * Makes a request with old password and new password
 * @param oldPassword
 * @param newPassword
 */
export function changePassword(
  oldPassword: string,
  newPassword: string,
  role: string,
): (dispatch: Dispatch) => Promise<any> {
  return async (dispatch: Dispatch): Promise<any> => {
    dispatch(uILoadingStart());
    try {
      const response: AxiosResponse<any> =
        role === UserRolesEnum.admin
          ? await apiAdminChangePassword(oldPassword, newPassword)
          : await apiUserChangePassword(oldPassword, newPassword);

      if (response && (response.status === 200 || response.status === 201)) {
        return response;
      }
    } catch (e) {
      const error = e as any;
      (error.response && error.response.status === 403) || showErrorNotification(error);
      return (error.response && error.response.status) || 0;
    } finally {
      dispatch(uILoadingFinish());
    }
    return 0;
  };
}

export function verifyAccount({
  tempCode,
  email,
}: {
  tempCode: number;
  email: string;
}): (dispatch: Dispatch) => Promise<any> {
  return async (dispatch: Dispatch): Promise<any> => {
    dispatch(uILoadingStart());

    try {
      const response: AxiosResponse<any> = await apiVerifyAccount({ tempCode, email });

      if (response && response.data) {
        return { code: 'OK' };
      }
    } catch (e) {
      const error = e as any;
      showErrorNotification(error);
      return (error.response && error.response.data) || null;
    } finally {
      dispatch(uILoadingFinish());
    }
    return null;
  };
}

export function resendActivation(email: string): (dispatch: Dispatch) => Promise<any> {
  return async (dispatch: Dispatch): Promise<any> => {
    dispatch(uILoadingStart());

    try {
      const response: AxiosResponse<any> = await apiResendActivation(email);

      if (response && response.data) {
        return response.data;
      }
    } catch (e) {
      const error = e as any;
      showErrorNotification(error);
      return (error.response && error.response.data) || null;
    } finally {
      dispatch(uILoadingFinish());
    }
    return null;
  };
}

/**
 * Sets an auth data if needed
 */
export function bootstrap(): any {
  return async (dispatch: any, getStore: any): Promise<void> => {
    dispatch(uIGetLiveResponse());
    dispatch(uIGetLanguages());
    dispatch(uIGetTheme());

    const data: string = storage.getTokens();
    if (data) {
      dispatch(uILoadingStart());
      dispatch({ type: SessionActionTypes.SESSION_LOADING_START });
      dispatch({ type: SessionActionTypes.BOOTSTRAP_START });
      try {
        const tokens: SessionTokens | null = storage.parseTokens(data);
        if (tokens !== null) {
          await dispatch(setTokens(tokens));
          await dispatch(getUser());
          await initRefreshTokens(tokens.refresh);
          const role = getStore().session.user && getStore().session.user.role;
          role && (await dispatch(uIGetWorkflowStepTypes()));
          await dispatch({ type: SessionActionTypes.BOOTSTRAP_SUCCESS });
        } else {
          dispatch({ type: SessionActionTypes.BOOTSTRAP_REJECT });
          await dispatch(logout());
        }
      } catch (error) {
        dispatch({ type: SessionActionTypes.BOOTSTRAP_FAIL });
        await dispatch(logout());
      } finally {
        dispatch(uILoadingFinish());
        dispatch({ type: SessionActionTypes.SESSION_LOADING_FINISH });
      }
    }
  };
}
