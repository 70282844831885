import React, { ReactElement } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, InputText } from 'components/UI';
import { Icon } from 'components/UI/Icon';
import { useStyles } from './styles';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { forgotPasswordSchema } from 'utils/validation';
import { useDispatch } from 'react-redux';
import { forgotPassword } from 'store/session/actions';
import { IconButton } from '@material-ui/core';

const ForgotPassword = (): ReactElement => {
  // Translation
  const { t } = useTranslation();

  // Routing
  const history = useHistory();

  // Redux
  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
    resolver: yupResolver(forgotPasswordSchema),
  });
  const styles = useStyles();
  const onSubmit = async (data: any): Promise<void> => {
    const response = await dispatch(forgotPassword(data.email));
    if (typeof response === 'number' && (response === 200 || response === 201)) {
      history.push('/email');
    } else if (response && response.code === 'ERROR') {
      if (response.message === 'User is not defined') {
        setError('email', { type: 'notMatch', message: 'User is not defined' }, { shouldFocus: true });
      }
    }
  };

  const navigateToLogin = () => {
    history.push('/');
  };

  return (
    <div className={styles.forgotPasswordContainer}>
      <Icon name="unlock" size={80} />
      <div className={styles.flexBoxTitle}>
        <IconButton className={styles.backButton} onClick={navigateToLogin}>
          <Icon name="arrowLeft" color="#666666" />
        </IconButton>
        <h5 className={styles.formHeader}>{t('Forgot your password?')}</h5>
      </div>
      <p>{t('Enter your email address to get a temporary password to log in')}</p>
      <form onSubmit={handleSubmit(onSubmit)}>
        <InputText
          {...register('email', { required: true })}
          placeholder={t('Email Address')}
          error={!!errors['email'] ? errors['email'].message : undefined}
          fullWidth
          className={styles.input}
        />
        <Button labelKey={t('Reset Password')} className={styles.submitButton} type="submit" fullWidth />
        <Button
          labelKey={t('Return to log in')}
          textStyle
          type="button"
          className={styles.textButton}
          onClick={() => history.push('/')}
        />
      </form>
    </div>
  );
};

export default ForgotPassword;
