import React, { FC, useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';

import UserPanel from 'components/UserPanel';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from 'store';
import { useStyles } from './styles';
import { Icon } from '../UI/Icon';
import { Pagination } from 'store/purchaseHistory/types';
import { handleGetServersAlertHistory } from 'store/customers/actions';
import { SASetPage, SASetRecordsCount, SASetShown, SASetTableData } from 'store/serversAlert/actions';
import { User } from 'store/session/types';
import cn from 'classnames';
import { routeNames } from 'routing/routes';
import { HeaderBreadcrumbsProps, HeaderProps } from './interfaces';
import { UserRolesEnum } from 'utils/types';

const Header: FC<HeaderProps> = ({ innerHeader, breadcrumbs }: HeaderProps) => {
  // Styling
  const styles = useStyles();
  // Routing
  const history = useHistory();
  const state: any = history.location.state;

  const logo = useSelector((state: AppState) => state.ui.themeBrandColor.logoUrl);
  const pagination: Pagination = useSelector((state: AppState) => state.serversAlert.pagination);
  const user: User | null = useSelector((state: AppState) => state.session.user);
  const startDate = useSelector((state: AppState) => state.serversAlert.startDate);
  const endDate = useSelector((state: AppState) => state.serversAlert.endDate);

  const isCustomer = useMemo(
    () => user?.role === UserRolesEnum.account || user?.role === UserRolesEnum.organization,
    [user],
  );

  useEffect(() => {
    const isChart =
      state?.isChart ||
      (localStorage.getItem('serverChartState') && JSON.parse(localStorage.getItem('serverChartState') || '')?.isChart);

    if (!history.location.pathname.includes('servers-alert') || !isChart) {
      localStorage.removeItem('serverChartPeriod');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history?.location, state]);

  // Dispatch
  const dispatch = useDispatch();

  const handleNavigateLinks = (state: HeaderBreadcrumbsProps) => {
    if (state.onClick) return state.onClick();
    if (!state.isDisable && state.href) {
      history.push(state.href);
    }
  };

  const handleNavigate = (state: any, index: number) => {
    if (isCustomer && index === 0) {
    } else {
      history.push(routeNames.serversAlert, state);
      const findProp = state?.serverName
        ? [
            {
              columnName: 'companyName',
              value: state?.companyName,
              direct: breadcrumbs && breadcrumbs.length > 1,
            },
            {
              columnName: 'serverName',
              value: state?.serverName,
              direct: breadcrumbs && breadcrumbs.length > 1,
            },
          ]
        : [
            {
              columnName: 'companyName',
              value: state?.companyName || '',
              direct: breadcrumbs && breadcrumbs.length > 1,
            },
          ];
      const enterpriseId = user?.role === UserRolesEnum.organization ? { enterpriseId: user?.enterpriseId } : {};
      const data = {
        page: 1,
        limit: pagination.count,
        find: findProp,
        ...enterpriseId,
        dates: { start: startDate, end: endDate },
      };
      new Promise((resolve) => resolve(dispatch(handleGetServersAlertHistory(data)))).then((result: any) => {
        const servers = result && result.data && result.data.data;
        if (servers) {
          dispatch(SASetTableData(servers));
          dispatch(SASetPage(1));
          dispatch(
            SASetShown(1 * pagination.count - (pagination.count - 1), (1 - 1) * pagination.count + servers.length),
          );
          dispatch(SASetRecordsCount(Number(result.data.count)));
        }
      });
    }
  };

  return (
    <>
      <header className={styles.container}>
        <UserPanel />
        {innerHeader ? (
          <div className={styles.innerHeader}>{innerHeader}</div>
        ) : (
          !breadcrumbs && <div className={styles.space} />
        )}

        <div className={styles.headersWrapper}>
          <img className={styles.logoImg} src={`${logo}`} alt="logo" />
        </div>
      </header>
      {breadcrumbs?.length ? (
        <div className={cn(styles.breadcrumbs, 'breadcrumbs')}>
          {breadcrumbs.map((link: HeaderBreadcrumbsProps, index: number) => {
            const lastTextClassName = index + 1 === breadcrumbs.length ? styles.disabledBreadcrumb : '';
            const lastWrapperClassName = index + 1 === breadcrumbs.length ? styles.lastBreadcrumb : '';
            const disabledTextClassName = link.isDisable ? styles.disabledText : '';
            return (
              <div key={index} className={cn(styles.breadcrumb, lastWrapperClassName)}>
                {index ? <Icon name={'arrowRight'} /> : null}
                <span
                  className={cn([styles.headerLink, lastTextClassName, disabledTextClassName])}
                  onClick={() =>
                    link.toServerAlert && !link.onClick ? handleNavigate(link.state, index) : handleNavigateLinks(link)
                  }
                >
                  <span className={cn(link.isDisable ? '' : styles.cp)}>{link.name}</span>
                </span>
              </div>
            );
          })}
        </div>
      ) : null}
    </>
  );
};

export default Header;
