import React, { FC, ReactElement, useLayoutEffect, useRef } from 'react';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Popover from '@material-ui/core/Popover';
import { TableCell } from '@material-ui/core';
import { Icon } from 'components/UI/Icon';
import { useDispatch } from 'react-redux';
import { useStyles } from './styles';

export interface FilterItem {
  value: string;
  label: string;
}

interface Props {
  columnName: string;
  filterOptions: FilterItem[];
  formatType?: string;
  setFilterMultiSelect: (columnName: string, newChecked: any) => void;
  setFilterMultiSelectShown: (columnName: string, newChecked: any) => void;
  getTableData?: any;
  setFilterMultiSelectPrev: (columnName: string, newChecked: any) => void;
  checked: any[];
  checkedPrev: any[];
  shownOptions: any[];
  style?: any;
  nonState?: boolean;
  noDispatch?: boolean;
  cellStyle?: Record<string, any>;
}

const FilterCellMultiSelect: FC<Props> = ({
  filterOptions,
  columnName,
  setFilterMultiSelect,
  setFilterMultiSelectShown,
  getTableData,
  setFilterMultiSelectPrev,
  checked,
  checkedPrev,
  shownOptions,
  style,
  nonState,
  noDispatch,
  cellStyle,
}: Props) => {
  // Styling
  const styles = useStyles();

  // Ref
  const cellElement = useRef<HTMLTableCellElement>(null);
  const spanElement = useRef<HTMLSpanElement>(null);

  // Redux
  const dispatch = useDispatch();

  const isOptions = !!filterOptions.length;

  /**
   * Calculate filter cell available width once after creation
   */
  const cellWidth = useRef<number>(0);
  useLayoutEffect(() => {
    if (cellElement.current) {
      cellWidth.current = cellElement.current.getBoundingClientRect().width;
    }
  }, []);

  /**
   * Calculate filter label width at changing number of shown filter options
   */
  const spanWidth = useRef<number>(0);
  useLayoutEffect(() => {
    if (spanElement.current) {
      spanWidth.current = spanElement.current.getBoundingClientRect().width;
    }
  }, [shownOptions]);

  /**
   * Returns filters labels string with number of hidden filters
   * according to checked snd shown filter options
   */
  const makeLabelString = (): string => {
    let str = '';
    const filteredOptions = shownOptions.filter((_: any, index: any) => index <= 1);

    filteredOptions.forEach((option: string) => {
      const filterOption = filterOptions.find((item: FilterItem) => item.value === option);

      if (filterOption) {
        if (str.length > 0) {
          str = str + `, ${filterOption?.label}`;
        } else {
          str = str + `${filterOption?.label}`;
        }
      }
    });

    if (checked.length > shownOptions.length) {
      str = str + ` +${checked.length - filteredOptions.length}`;
    }

    return str;
  };

  /**
   * Set checked filter options
   * and initial state for shown options
   * (to calculate all-options-label width an determine how many options should be hidden)
   */
  const handleToggle = (value: string) => (): void => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];
    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    nonState ? setFilterMultiSelect(columnName, newChecked) : dispatch(setFilterMultiSelect(columnName, newChecked));
    !nonState && dispatch(setFilterMultiSelectShown(columnName, newChecked));
  };

  // Popover
  const [anchorEl, setAnchorEl] = React.useState<HTMLTableCellElement | null>(null);
  const handleFiltersClick = (event: React.MouseEvent<HTMLTableCellElement>): void => {
    if (!isOptions) {
      return;
    }
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (): void => {
    setAnchorEl(null);
    if (JSON.stringify(checkedPrev) !== JSON.stringify(checked)) {
      nonState
        ? setFilterMultiSelectPrev(columnName, checked)
        : dispatch(setFilterMultiSelectPrev(columnName, checked));
      if (getTableData) {
        nonState || noDispatch ? getTableData(1) : dispatch(getTableData());
      }
    }
  };
  const open = Boolean(anchorEl);
  const id = open ? 'multi-select-popover' : undefined;

  const renderStepsList = (): ReactElement => {
    return (
      <List>
        {/* eslint-disable-next-line react/prop-types */}
        {filterOptions.map((option: FilterItem, index: number) => {
          return (
            <ListItem className={styles.listItem} key={index} dense button onClick={handleToggle(option.value)}>
              <input className={styles.checkbox} type="checkbox" checked={checked.indexOf(option.value) !== -1} />
              {option.label}
            </ListItem>
          );
        })}
      </List>
    );
  };

  useLayoutEffect(() => {
    if (spanWidth.current > cellWidth.current) {
      const newArr = shownOptions.slice(0, shownOptions.length - 1);
      !nonState && dispatch(setFilterMultiSelectShown(columnName, newArr));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [columnName, dispatch, filterOptions]);

  return (
    <>
      <TableCell
        className={[styles.filterCell, styles.FilterCellMultiSelect, isOptions ? styles.pointer : ''].join(' ')}
        onClick={handleFiltersClick}
        style={cellStyle || {}}
      >
        <div ref={cellElement} className={styles.cellContent} style={style ? style : {}}>
          {isOptions ? (
            !!shownOptions.length ? (
              <span ref={spanElement}>{makeLabelString()}</span>
            ) : (
              <Icon name="filter" size={12} />
            )
          ) : (
            ''
          )}
        </div>
      </TableCell>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        {renderStepsList()}
      </Popover>
    </>
  );
};

export default FilterCellMultiSelect;
