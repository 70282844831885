import React, { ChangeEvent, FC, ReactElement, useCallback, useMemo, useState } from 'react';
import { IconButton, TableCell } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import Popover from '@material-ui/core/Popover';
import { Icon } from 'components/UI/Icon';

import { useStyles } from './styles';

interface Props {
  columnName: string;
  searchString: string;
  searchStringPrev: string;
  setFilterSearch: (columnName: string, value: string | undefined) => void;
  setFilterSearchPrev: (columnName: string, value: string | undefined) => void;
  getTableData?: any;
  nonState?: boolean;
  style?: any;
  cellStyle?: any;
  columnsFilters?: any;
  noDispatch?: boolean;
}

const FilterCellSearch: FC<Props> = ({
  columnName,
  searchString,
  searchStringPrev,
  setFilterSearch,
  setFilterSearchPrev,
  getTableData,
  style,
  nonState,
  columnsFilters,
  noDispatch,
  cellStyle,
}: Props) => {
  // Styling

  // State
  const [focused, setFocused] = useState(false);

  // Redux
  const dispatch = useDispatch();

  const styles = useStyles();

  const handleFocus = (): void => {
    setFocused(true);
  };
  const handleBlur = (): void => {
    setFocused(false);
  };

  //Popover
  const [anchorEl, setAnchorEl] = React.useState<HTMLTableCellElement | null>(null);
  const handleFiltersClick = (event: React.MouseEvent<HTMLTableCellElement>): void => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = useCallback((): void => {
    setAnchorEl(null);
    if (searchString === undefined || searchString === '') {
      nonState ? setFilterSearch(columnName, undefined) : dispatch(setFilterSearch(columnName, undefined));
      nonState ? setFilterSearchPrev(columnName, undefined) : dispatch(setFilterSearchPrev(columnName, undefined));
      if (getTableData) {
        nonState || noDispatch
          ? getTableData(
              1,
              undefined,
              undefined,
              columnsFilters.filter((item: any) => item.columnName !== columnName),
            )
          : dispatch(getTableData());
      }
    } else if (searchStringPrev !== searchString) {
      nonState
        ? setFilterSearchPrev(columnName, searchString)
        : dispatch(setFilterSearchPrev(columnName, searchString));
      if (getTableData) {
        nonState || noDispatch ? getTableData(1) : dispatch(getTableData());
      }
    }
  }, [
    searchString,
    searchStringPrev,
    nonState,
    setFilterSearch,
    columnName,
    dispatch,
    setFilterSearchPrev,
    getTableData,
    noDispatch,
    columnsFilters,
  ]);

  const open = Boolean(anchorEl);
  const id = open ? 'multi-select-popover' : undefined;

  const renderSearchInput = useMemo(() => {
    const handleInputChange = (e: ChangeEvent<HTMLInputElement>): void => {
      nonState ? setFilterSearch(columnName, e.target.value) : dispatch(setFilterSearch(columnName, e.target.value));
    };

    const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>): void => {
      if (event.which === 13 || event.keyCode === 13) {
        handleClose();
      }
    };

    const searchInput = (): ReactElement => {
      return (
        <input
          className={[styles.searchInput, focused ? styles.searchInputFocused : ''].join(' ')}
          type="text"
          onFocus={handleFocus}
          onBlur={handleBlur}
          onChange={handleInputChange}
          onKeyPress={handleKeyPress}
          value={searchString}
          autoFocus
        />
      );
    };
    return searchInput();
  }, [
    columnName,
    dispatch,
    focused,
    handleClose,
    nonState,
    searchString,
    setFilterSearch,
    styles.searchInput,
    styles.searchInputFocused,
  ]);

  const handleSearchClearButton = (event: React.MouseEvent): void => {
    event.stopPropagation();
    nonState ? setFilterSearch(columnName, undefined) : dispatch(setFilterSearch(columnName, undefined));
    nonState ? setFilterSearchPrev(columnName, undefined) : dispatch(setFilterSearchPrev(columnName, undefined));
    if (getTableData) {
      nonState || noDispatch
        ? getTableData(
            1,
            undefined,
            undefined,
            columnsFilters.filter((item: any) => item.columnName !== columnName),
          )
        : dispatch(getTableData());
    }
  };

  return (
    <>
      <TableCell
        className={[styles.filterCell, styles.FilterCellSearch].join(' ')}
        onClick={handleFiltersClick}
        style={cellStyle || {}}
      >
        <div className={styles.cellContent} style={style ? style : {}}>
          {!!searchString ? (
            <>
              <input className={styles.searchLabel} value={searchString} />
              <IconButton onClick={handleSearchClearButton}>
                <Icon name="close" size={8} />
              </IconButton>
            </>
          ) : (
            <Icon name="search" size={12} />
          )}
        </div>
      </TableCell>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        {renderSearchInput}
      </Popover>
    </>
  );
};

export default FilterCellSearch;
