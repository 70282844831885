import React, { FC, useCallback, useMemo, useState } from 'react';
import { IconButton } from '@material-ui/core';
import { Icon } from 'components/UI/Icon';
import { useStyles } from './styles';
import { ChangeListener, Value } from './types';
import { DEFAULT_DISABLED_COLOR, DEFAULT_PAGINATION_VALUE } from './constants';
import { getEndRecord, getNextPageDisabled, getPaginationSetter, getPrevPageDisabled, getStartRecord } from './utils';
import { useTranslation } from 'react-i18next';

export type BasicPaginationProps = {
  value?: Value;
  defaultValue?: Value;
  count: number;
  label?: string;
  onChange: ChangeListener;
};

const BasicPagination: FC<BasicPaginationProps> = ({ value, count = 0, defaultValue, onChange }) => {
  const styles = useStyles();
  const [innerValue, setInnerValue] = useState(null as unknown as Value);
  const { t } = useTranslation();

  const selectedValue = useMemo(
    () => value || innerValue || defaultValue || DEFAULT_PAGINATION_VALUE,
    [defaultValue, innerValue, value],
  );

  const applyChanges: ChangeListener = useCallback(
    (value: Value) => {
      setInnerValue(value);
      onChange(value);
    },
    [onChange],
  );

  const prevPageDisabled = useMemo(() => getPrevPageDisabled(selectedValue), [selectedValue]);
  const endRecord = useMemo(() => getEndRecord(selectedValue, count), [count, selectedValue]);
  const startRecord = useMemo(() => getStartRecord(selectedValue, count), [count, selectedValue]);
  const nextPageDisabled = useMemo(() => getNextPageDisabled(selectedValue, count), [count, selectedValue]);
  const setPagination = useMemo(() => getPaginationSetter(applyChanges, selectedValue), [applyChanges, selectedValue]);

  const prevPageColor = prevPageDisabled ? DEFAULT_DISABLED_COLOR : '';
  const nextPageColor = nextPageDisabled ? DEFAULT_DISABLED_COLOR : '';

  return (
    <div className={styles.flexboxContainer}>
      <IconButton
        className={styles.paginationButton}
        disabled={prevPageDisabled}
        onClick={(): void => setPagination({ page: 1 })}
      >
        <Icon name="firstPage" color={prevPageColor} />
      </IconButton>
      <IconButton
        className={styles.paginationButton}
        disabled={prevPageDisabled}
        onClick={(): void => setPagination({ page: selectedValue.page - 1 })}
      >
        <Icon name="previousPage" color={prevPageColor} />
      </IconButton>
      <div className={styles.shownItems}>
        <span>{`${startRecord}-${endRecord}`}</span>
        <span className={styles.recordsCount}>{`${t('out of')} ${count || 0}`}</span>
      </div>
      <IconButton
        className={styles.paginationButton}
        disabled={nextPageDisabled}
        onClick={(): void => setPagination({ page: selectedValue.page + 1 })}
      >
        <Icon name="nextPage" color={nextPageColor} />
      </IconButton>
      <IconButton
        className={styles.paginationButton}
        disabled={nextPageDisabled}
        onClick={(): void => setPagination({ page: Math.ceil(count / selectedValue.limit) })}
      >
        <Icon name="lastPage" color={nextPageColor} />
      </IconButton>
    </div>
  );
};

export default BasicPagination;
