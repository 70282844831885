import { Customer } from 'store/adminDashboard/types';

export interface OrganizationsState {
  enterprise: any;
  enterpriseAdministrators: any[];
  firstOrganizations: Organization[];
  pagination: Pagination;
  enterpriseAdministratorsCount: number;
  search: string;
  counter: number;
  enterpriseNameSearch: string;
  freeCustomers: { customers: Customer[]; customersCount: number };
  eatags: string[];
  // TODO remove any and remove from this state type
  one: any;
  enterpriseCustomers: any;
  freeUsersList: any;
  belongUsersList: any;
  createdUser: any;
}

export interface FreeCustomer {
  customersCount: number;
  customers: Customer[];
}

export interface Pagination {
  page: number;
  count: number;
  startOrganization: number;
  endOrganization: number;
}

export interface Organization {
  id: string;
  email: string;
  name: string;
  writeAccess: boolean;

  isCustomerPurchase: boolean;
  isCustomerFinance: boolean;
  isCustomerOperator: boolean;
  isCustomerReport: boolean;

  timeFormat: string;
  enterpriseName: string;
  enterpriseCustomers: Customer[];
  startCustomersIds?: string[];
}

export interface DefaultOrganization {
  email: string;
  name: string;
  writeAccess: boolean;
  isCustomerPurchase: boolean;
  isCustomerFinance: boolean;
  isCustomerOperator: boolean;
  isCustomerReport: boolean;
  timeFormat: string;
  enterpriseName: string;
  enterpriseCustomers?: Customer[];
}

export interface OrganizationCreate {
  enterpriseAdmin: Organization;
  enterprise: {
    name: string;
  };
  enterpriseCustomers?: {
    toAddIds?: string[];
    toRemoveIds?: string[];
  };
}

export interface OrganizationCreateMany {
  enterprises: OrganizationCreate[];
}

export enum OrganizationsActionTypes {
  ORGANIZATIONS_SET = 'ORGANIZATIONS_SET',
  ORGANIZATIONS_FIRST_SET = 'ORGANIZATIONS_FIRST_SET',
  ORGANIZATIONS_SET_COUNT = 'ORGANIZATIONS_SET_COUNT',
  ORGANIZATIONS_SET_COUNT_FIRST = 'ORGANIZATIONS_SET_COUNT_FIRST',
  ORGANIZATIONS_SET_PAGINATION_COUNT = 'ORGANIZATIONS_SET_PAGINATION_COUNT',
  ORGANIZATIONS_SET_PAGE = 'ORGANIZATIONS_SET_PAGE',
  ORGANIZATIONS_SET_SHOWN = 'ORGANIZATIONS_SET_SHOWN',
  ORGANIZATIONS_SET_SEARCH = 'ORGANIZATIONS_SET_SEARCH',
  ORGANIZATIONS_SET_FREE_CUSTOMERS = 'ORGANIZATIONS_SET_FREE_CUSTOMERS',
  ORGANIZATIONS_SET_CUSTOMERS = 'ORGANIZATIONS_SET_CUSTOMERS',
  ORGANIZATIONS_ADD_FREE_CUSTOMERS = 'ORGANIZATIONS_ADD_FREE_CUSTOMERS',
  ORGANIZATIONS_SET_ENTERPRISE_NAME_SEARCH = 'ORGANIZATIONS_SET_ENTERPRISE_NAME_SEARCH',
  ORGANIZATIONS_SET_EATAGS = 'ORGANIZATIONS_SET_EATAGS',
  ORGANIZATIONS_SET_ENTERPRISE = 'ORGANIZATIONS_SET_ENTERPRISE',
}

export enum OrganizationOneActionTypes {
  ORGANIZATION_ONE = 'ORGANIZATION_ONE',
  ORGANIZATION_FREE_USERS_LIST = 'ORGANIZATION_FREE_USERS_LIST',
  ORGANIZATION_BELONG_USERS_LIST = 'ORGANIZATION_BELONG_USERS_LIST',
  ORGANIZATION_CREATED_USER = 'ORGANIZATION_CREATED_USER',
}

export interface OrganizationFreeUsersListActionSet {
  type: OrganizationOneActionTypes.ORGANIZATION_FREE_USERS_LIST;
  payload: Organization[];
}

export interface OrganizationBelongUsersListActionSet {
  type: OrganizationOneActionTypes.ORGANIZATION_BELONG_USERS_LIST;
  payload: Organization[];
}

export interface OrganizationOneActionSet {
  type: OrganizationOneActionTypes.ORGANIZATION_ONE;
  payload: Organization[];
}

export interface OrganizationCreatedUserActionSet {
  type: OrganizationOneActionTypes.ORGANIZATION_CREATED_USER;
  payload: Organization[];
}

export interface OrganizationsSet {
  type: OrganizationsActionTypes.ORGANIZATIONS_SET;
  payload: Organization[];
}

export interface OrganizationsFirstSet {
  type: OrganizationsActionTypes.ORGANIZATIONS_FIRST_SET;
  payload: Organization[];
}

export interface OrganizationsSetCount {
  type: OrganizationsActionTypes.ORGANIZATIONS_SET_COUNT;
  payload: number;
}

export interface OrganizationsSetCountFirst {
  type: OrganizationsActionTypes.ORGANIZATIONS_SET_COUNT_FIRST;
  payload: number;
}

export interface OrganizationsSetPaginationCount {
  type: OrganizationsActionTypes.ORGANIZATIONS_SET_PAGINATION_COUNT;
  payload: number;
}

export interface OrganizationsSetPage {
  type: OrganizationsActionTypes.ORGANIZATIONS_SET_PAGE;
  payload: number;
}

export interface OrganizationsSetShown {
  type: OrganizationsActionTypes.ORGANIZATIONS_SET_SHOWN;
  payload: { startOrganization: number; endOrganization: number };
}

export interface OrganizationsSetSearch {
  type: OrganizationsActionTypes.ORGANIZATIONS_SET_SEARCH;
  payload: string;
}

export interface OrganizationsSetEnterpriseNameSearch {
  type: OrganizationsActionTypes.ORGANIZATIONS_SET_ENTERPRISE_NAME_SEARCH;
  payload: string;
}

export interface OrganizationsSetFreeCustomers {
  type: OrganizationsActionTypes.ORGANIZATIONS_SET_FREE_CUSTOMERS;
  payload: FreeCustomer;
}

export interface OrganizationsAddFreeCustomers {
  type: OrganizationsActionTypes.ORGANIZATIONS_ADD_FREE_CUSTOMERS;
  payload: Customer[];
}

export interface OrganizationSetCustomers {
  type: OrganizationsActionTypes.ORGANIZATIONS_SET_CUSTOMERS;
  payload: { id: string; enterpriseCustomers: Customer[] };
}

export interface OrganizationsSetTags {
  type: OrganizationsActionTypes.ORGANIZATIONS_SET_EATAGS;
  payload: string[];
}

export interface OrganizationsSetEnterprise {
  type: OrganizationsActionTypes.ORGANIZATIONS_SET_ENTERPRISE;
  payload: string[];
}

export interface OrganizationUserCreate {
  enterpriseAdmin?: {
    name: string;
    email: string;
    phoneNumber: string;
    timeFormat: string;
    isCustomerPurchase: boolean;
    isCustomerFinance: boolean;
    isCustomerOperator: boolean;
    isCustomerReport: boolean;
    isSendCompareVersions: boolean;
  };
  enterprise?: {
    name: string;
  };
  enterpriseCustomers?: {
    toAddIds?: string[];
    toRemoveIds?: string[];
  };
}

export interface OrganizationUserUpdate {
  enterpriseAdmin?: {
    id: string;
    name: string;
    email: string;
    phoneNumber: string;
    timeFormat: string;
    isCustomerPurchase: boolean;
    isCustomerFinance: boolean;
    isCustomerOperator: boolean;
    isCustomerReport: boolean;
    isSendCompareVersions: boolean;
  };
  enterprise?: {
    name: string;
  };
  enterpriseCustomers?: {
    toAddIds?: string[];
    toRemoveIds?: string[];
  };
}

export type OrganizationsActions =
  | OrganizationsSet
  | OrganizationsFirstSet
  | OrganizationsSetPaginationCount
  | OrganizationsSetCount
  | OrganizationsSetCountFirst
  | OrganizationsSetPage
  | OrganizationsSetShown
  | OrganizationsSetSearch
  | OrganizationsSetFreeCustomers
  | OrganizationSetCustomers
  | OrganizationsAddFreeCustomers
  | OrganizationsSetEnterpriseNameSearch
  | OrganizationsSetTags
  | OrganizationsSetEnterprise
  | OrganizationOneActionSet
  | OrganizationCreatedUserActionSet
  | OrganizationFreeUsersListActionSet
  | OrganizationBelongUsersListActionSet;
