import { Theme, createStyles, makeStyles, withStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';

export const useStyles = makeStyles((theme: Theme) => ({
  HeaderCell: {
    backgroundColor: theme.palette.background.paper,
  },
  cellContent: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    height: '100%',
    alignItems: 'center',
    '& span': {
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      fontWeight: 500,
      width: '100%',
    },
  },
  filterCell: {
    minHeight: '36px',
    height: '36px',
    padding: '0.2rem 12px',
    fontSize: '0.75rem',
    fontStyle: 'italic',
    borderBottom: '1px solid #E9E9EE',
    borderRight: '1px solid #E9E9EE',
    borderColor: '#E9E9EE',
    boxSizing: 'border-box',
    maxWidth: 'min-content',
    whiteSpace: 'nowrap',
    backgroundColor: theme.palette.background.default,
    '& svg': {
      cursor: 'pointer',
    },
  },
  FilterCellMultiSelect: {
    overflow: 'hidden',
    maxWidth: 'min-content',
    whiteSpace: 'nowrap',
  },
  pointer: {
    cursor: 'pointer',
  },
  checkbox: {
    marginRight: '0.5rem',
    marginLeft: 6,
    width: 18,
    height: 18,
    cursor: 'pointer',
  },
  listItem: {
    paddingLeft: '0.5rem',
    fontSize: '13px',
    lineHeight: '18px',
    fontWeight: 500,
    color: '#666666',
    textTransform: 'capitalize',
  },
  FilterCellSearch: {},
  FilterCellDateRange: {},
  searchInput: {
    width: '16rem',
    height: '100%',
    margin: '0.2rem',
    padding: '0.2rem',
    fontSize: '0.75rem',
    borderRadius: 8,
  },
  searchInputFocused: {
    // Change width to broaden field
    // width: '100%',
    fontSize: '0.8125rem',
    boxShadow: `0px 0px 2px 0px #F8F8F8`,
    zIndex: 999,
  },
  searchLabel: {
    appearance: 'none',
    width: 'calc(100% - 16px)',
    border: 'none',
    pointerEvents: 'none',
    backgroundColor: 'transparent',
  },
  FilterCellInk: {
    '& > div': {
      justifyContent: 'space-around',
    },
  },
  inkButton: {
    margin: '0.1rem',
    padding: '4px',
    borderRadius: '2px',

    '& svg': {
      opacity: 0.4,
    },
  },
  inkSelected: {
    '& svg': {
      opacity: 1,
    },
  },
  FilterCellState: {
    '& > div': {
      justifyContent: 'space-around',
    },
  },
  popoverRangeWrapper: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    height: '20rem',
    padding: '0.5rem',
    alignItems: 'center',
    justifyContent: 'center',
  },
  rangeHeader: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    height: '2rem',
    alignItems: 'center',
    justifyContent: 'center',
  },
  rangePickerWrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },
  rangeClearButton: {
    marginLeft: '0.5rem',
  },
}));

export const StyledHeaderCell = withStyles((theme: Theme) =>
  createStyles({
    head: {
      fontSize: '13px',
      fontWeight: 700,
      whiteSpace: 'nowrap',
      backgroundColor: theme.palette.background.paper,
      color: theme.palette.grey['700'],
      borderRight: '1px solid #E9E9EE',
      boxSizing: 'border-box',
      textAlign: 'center',
      lineHeight: '17px',
      padding: '10px 12px',
    },
  }),
)(TableCell);
