export interface PurchaseHistoryState {
  records: PHRecord[];
  pagination: Pagination;
  reference: string;
  sortBy: SortBy;
  recordsCount: number;
}

export interface SortBy {
  columnName?: string;
  order?: string;
}

export interface Pagination {
  page: number;
  count: number;
  startRecord: number;
  endRecord: number;
}

export interface PHRecord {
  amount: number;
  createdAt: string;
  id: string;
  invoice: string;
  reference: string;
  stepId: string;
  stepName: string;
  timeStamp: string;
  updatedAt: string;
  updatedBy: string;
  updatedByName: string;
  userId: string;
  finalBalance: number;
}

export enum PurchaseHistoryActionTypes {
  PH_SET_RECORDS = 'PH_SET_RECORDS',
  PH_SET_RECORDS_COUNT = 'PH_SET_RECORDS_COUNT',
  PH_SET_COUNT = 'PH_SET_COUNT',
  PH_SET_PAGE = 'PH_SET_PAGE',
  PH_SET_SHOWN = 'PH_SET_SHOWN,',
  PH_SET_SEARCH = 'PH_SET_SEARCH,',
  PH_TOGGLE_SORT_BY = 'PH_TOGGLE_SORT_BY',
  PH_SET_SORT_BY = 'PH_SET_SORT_BY',
}

export interface PHSetRecords {
  type: PurchaseHistoryActionTypes.PH_SET_RECORDS;
  payload: PHRecord[];
}

export interface PHSetRecordsCount {
  type: PurchaseHistoryActionTypes.PH_SET_RECORDS_COUNT;
  payload: number;
}

export interface PHSetCount {
  type: PurchaseHistoryActionTypes.PH_SET_COUNT;
  payload: number;
}

export interface PHSetPage {
  type: PurchaseHistoryActionTypes.PH_SET_PAGE;
  payload: number;
}

export interface PHSetShown {
  type: PurchaseHistoryActionTypes.PH_SET_SHOWN;
  payload: { startRecord: number; endRecord: number };
}

export interface PHSetSearch {
  type: PurchaseHistoryActionTypes.PH_SET_SEARCH;
  payload: string;
}

export interface PHToggleSortBy {
  type: PurchaseHistoryActionTypes.PH_TOGGLE_SORT_BY;
  payload: string;
}

export interface PHSetSortBy {
  type: PurchaseHistoryActionTypes.PH_SET_SORT_BY;
  payload: SortBy;
}

export type PurchaseHistoryActions =
  | PHSetRecords
  | PHSetCount
  | PHSetPage
  | PHSetRecordsCount
  | PHSetShown
  | PHSetSearch
  | PHToggleSortBy
  | PHSetSortBy;
