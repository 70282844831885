import React, {
  FocusEvent,
  ForwardRefRenderFunction,
  ReactElement,
  SyntheticEvent,
  forwardRef,
  memo,
  useCallback,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useStyles } from './styles';
import RDatePicker, { registerLocale } from 'react-datepicker';
import cn from 'classnames';

import 'react-datepicker/dist/react-datepicker.css';
import en from 'date-fns/locale/en-US';
import es from 'date-fns/locale/es';
import he from 'date-fns/locale/he';
import ar from 'date-fns/locale/ar-SA';
import { Icon } from 'components/UI/Icon';
import { useTranslation } from 'react-i18next';

registerLocale('en', en);
registerLocale('es', es);
registerLocale('he', he);
registerLocale('ar', ar);

export interface DatePickerProps {
  disabled?: boolean;
  labelKey?: string;
  name?: string;
  error?: any;
  fullWidth?: boolean;
  positionBlur?: boolean;
  className?: string;
  onChange?: (date: Date, event?: SyntheticEvent) => void;
  onBlur?: (event: FocusEvent) => unknown;
  selected?: Date;
  placeholder?: string;
  inline?: boolean;
  readOnly?: boolean;
  minDate?: any;
  maxDate?: any;
  blur?: any;
  formatDate: string;
  classes?: any;
  required?: boolean;
}

export type DatePickerImperativeHandle = {
  name?: string;
  value?: Date;
};

const DatePicker: ForwardRefRenderFunction<DatePickerImperativeHandle, DatePickerProps> = (
  {
    labelKey,
    error,
    name,
    fullWidth,
    className,
    onChange,
    onBlur,
    selected,
    placeholder,
    inline,
    readOnly,
    minDate,
    maxDate,
    formatDate,
    blur,
    required,
    disabled,
  },
  ref,
): ReactElement => {
  const styles = useStyles();
  const [innerValue, setInnerValue] = useState(selected);
  const [wrapperClasses, setWrapperClasses] = useState(styles.inputWrapper);
  const [labelClasses, setLabelClasses] = useState(styles.label);
  const iconButtonRef = useRef<HTMLDivElement | null>(null);

  const containerClasses = useMemo(
    () => [styles.container, fullWidth ? styles.fullWidth : '', className].join(' '),
    [className, fullWidth, styles.container, styles.fullWidth],
  );

  // Translation
  const { t } = useTranslation();

  // Focusing
  const handleFocus = (): void => {
    if (!readOnly) {
      setWrapperClasses([styles.inputWrapper, styles.focused].join(' '));
      setLabelClasses([styles.label, styles.focusedLabel].join(' '));
    }
  };

  const handleBlur = (event: FocusEvent): void => {
    if (!readOnly) {
      setWrapperClasses(styles.inputWrapper);
      setLabelClasses(styles.label);
    }

    onBlur?.(event);

    if (error && blur) {
      blur();
    }
  };

  const handleIconClick = useCallback((event) => {
    event.preventDefault();
    event.stopPropagation();
    const parentNode = event.currentTarget.parentElement;
    const input = parentNode?.querySelector('input');
    input?.focus();
  }, []);

  const handleChange = useCallback(
    (date: Date, event: SyntheticEvent) => {
      setInnerValue(date);
      onChange?.(date, event);
    },
    [onChange],
  );

  useImperativeHandle(ref, () => ({
    value: innerValue,
    focus: () => iconButtonRef.current?.click(),
    name,
  }));

  const renderDataPicker = (): ReactElement => {
    return (
      <div className={cn(wrapperClasses, 'inputWrapper')}>
        <RDatePicker
          disabled={disabled}
          required={required}
          readOnly={readOnly}
          name={name}
          placeholderText={placeholder}
          selected={selected}
          onChange={handleChange}
          onFocus={handleFocus}
          onBlur={handleBlur}
          showPopperArrow={false}
          shouldCloseOnSelect
          inline={inline}
          dateFormat={formatDate}
          minDate={minDate}
          maxDate={maxDate}
          locale={localStorage.getItem('language') || 'en'}
          wrapperClassName={styles.rDatePicker}
          calendarClassName={styles.rDatePickerCalendar}
          className={styles.input}
          popperClassName={styles.rDatePickerPopper}
          popperPlacement="bottom-end"
          popperModifiers={{
            offset: {
              enabled: true,
              offset: '36px, 10px',
            },
          }}
          fixedHeight
        />
        <div className={styles.adornmentButton} onClick={handleIconClick} ref={iconButtonRef} tabIndex={-1}>
          <Icon name="calendar" />
        </div>
        {!!error && <div className={styles.error}>{t(error)}</div>}
      </div>
    );
  };
  return (
    <div className={containerClasses}>
      {labelKey ? (
        <label className={labelClasses}>
          <p className={styles.labelTitle}>{t(labelKey)}</p>
          {renderDataPicker()}
        </label>
      ) : (
        renderDataPicker()
      )}
    </div>
  );
};

export default memo(forwardRef(DatePicker));
