import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import React from 'react';

import StripeForm from './stripeAddCard';

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.

type StripeFormRunner = {
  onCloseModal: (confirm: boolean) => any;
};

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY as string);

export default function StripeFormRunner({ onCloseModal }: StripeFormRunner) {
  return (
    <Elements stripe={stripePromise}>
      <StripeForm onCloseModal={onCloseModal} />
    </Elements>
  );
}
