import { t } from 'i18next';
import * as yup from 'yup';

const startDateValidation = {
  startDate: yup
    .date()
    .nullable()
    .notRequired()
    .typeError('')
    .when('endDate', (endDate: any, schema: any) => {
      return endDate
        ? schema.max(new Date(endDate.getTime() - 60 * 60 * 24 * 1000), t('Must be earlier than Expiration Day'))
        : schema.nullable();
    }),
};

yup.setLocale({
  // use constant translation keys for messages without values
  mixed: {
    default: 'Field invalid',
    required: 'Field required',
  },
  // use functions to generate an error object that includes the value from the schema
  string: {
    email: 'Must be a valid email',
  },
});

const PATTERNS_DIGITS_ONLY = new RegExp('^\\$[1-9]\\d*$', 'g');
const PATTERNS_DIGITS_ONLY_TRIAL = new RegExp('^[0-9]\\d*$', 'g');
const PATTERNS_DIGITS_ONLY_DISCOUNT = new RegExp('^[0-9]\\d*%?$', 'g');
const pricingSettingsText = 'Type more than {0}';
const pricingSettingsTextDigitalOnly = 'Digital only';
const pricingSettingsTextNumericOnly = 'Only numeric symbols are allowed';

export const signInSchema = yup.object().shape({
  email: yup.string().email().required().label('Email'),
  password: yup
    .string()
    // Minimum eight characters, at least one uppercase letter, one lowercase letter and one number
    // .test('pass-validation', 'Invalid format', (value) =>
    //   // eslint-disable-next-line
    //   value.match(/^(?=.*?[A-Z])(?=(.*[a-z])+)(?=(.*\d)+)(?=(.*[!#$%&()*+@^\-])*)(?!.*\s).{8,}$/g),
    // )
    .required()
    .label('Password'),
});

export const forgotPasswordSchema = yup.object().shape({
  email: yup.string().email().required().label('Email'),
});

export const codeVerifyingSchema = (t: any) => {
  return yup.object().shape({
    code: yup
      .string()
      .min(4, `${t(`Must be`)} 4`)
      .required()
      .label('Code'),
  });
};

export const setPasswordSchema = (t: any) => {
  return yup.object().shape({
    newPassword: yup
      .string()
      // Minimum eight characters, at least one uppercase letter, one lowercase letter and one number
      // .test('pass-validation', 'Invalid format', (value) =>
      //   // eslint-disable-next-line
      //   value.match(/^(?=.*?[A-Z])(?=(.*[a-z])+)(?=(.*\d)+)(?=(.*[!#$%&()*+@^\-])*)(?!.*\s).{8,}$/g),
      // )
      .required()
      .label('Password'),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref('newPassword'), null], t('Passwords must match'))
      .required()
      .label('Confirm Password'),
  });
};

const companyName = (t: any) => {
  return (
    yup
      .string()
      // .test('name-validation', 'Invalid format', value => value.match(/^[\dA-Za-z]+(([ ',-.][\d A-Za-z])?[\dA-Za-z]*)*$/g))
      .test('name-validation', `${t('Invalid symbol')} &`, (value) => !value.match(/&/gi))
      .max(59, `${t('Must be less than')} 60`)
      .required()
      .label('Company Name')
  );
};
const userName = (t: any) => {
  return (
    yup
      .string()
      // .test('name-validation', 'Invalid format', value => value.match(/^[A-Za-z]+(([ ',-.][ A-Za-z])?[ A-Za-z]*)*$/g))
      .max(25, `${t('Must be less than')} 25`)
      .required()
      .label('Contact Name')
  );
};
const viewGlobalName = (t: any) => {
  return (
    yup
      .string()
      // .test('name-validation', 'Invalid format', value => value.match(/^[\dA-Za-z]+(([ ',-.][\d A-Za-z])?[ A-Za-z]*)*$/g))
      .max(25, `${t('Must be less than')} 25`)
      .min(3, `${t('Must be longer than')} 3`)
      .required()
      .label('Contact Name')
  );
};

const agentName = (t: any) => {
  return yup
    .string()
    .test('name-validation', 'Invalid symbols', (value) => !value?.match(/\/|\\|\(|\)|^\s+$/gi))
    .max(20, `${t('Must be less than')} 20`)
    .min(3, `${t('Must be longer than')} 3`)
    .required()
    .label('Server Name');
};

const enterpriseName = (t: any) => {
  return yup
    .string()
    .max(25, `${t('Must be less than')} 25`)
    .min(3, `${t('Must be longer than')} 3`)
    .required()
    .label('Organization Name');
};

const country = yup.string().required().label('Country');
const email = yup.string().email().required().label('Email');
const emailNotRequired = yup.string().email().label('Email');
const language = yup.string().required().label('Language');
const phone = (t: any) => {
  return (
    yup
      .string()
      // Minimum eight characters, at least one uppercase letter, one lowercase letter and one number
      .required()
      .test('phone-validation', t('Invalid format'), (value) => value.match(/^\+(?:\d ?){6,14}\d$/g))
      .label('Telephone')
  );
};
const phoneNotRequired = (t: any) => {
  return (
    yup
      .string()
      // Minimum eight characters, at least one uppercase letter, one lowercase letter and one number
      .test('phone-validation', t('Invalid format'), (value) => (!!value ? value.match(/^\+(?:\d ?){6,14}\d$/g) : true))
      .label('Telephone')
  );
};
const timeZone = yup.string().required().label('Time Zone');

export const addNewCustomerSchema = (t: any) => {
  return yup.object().shape({
    company: companyName(t),
    name: userName(t),
    agentsLimit: yup
      .string()
      .test('', pricingSettingsTextNumericOnly, (value) =>
        !!value ? value.match(PATTERNS_DIGITS_ONLY_DISCOUNT) : true,
      )
      .required()
      .label('Agents Limit'),
    email: email,
    timeFormat: yup.string().required().label('Date Format'),
    language: language,
    dailyReportEmail: emailNotRequired,
    errorReportEmail: emailNotRequired,
    phoneNumber: phoneNotRequired(t),
    timeZone: timeZone,
    coordinates: yup.array().required(),
    address: yup
      .string()
      .when('coordinates', {
        is: (b) => {
          return b === undefined;
        },
        then: yup.string().max(0, t('Could not find this address')),
      })
      .required()
      .label('Address'),
    customerId: yup.string().matches(/^\d+$|^$/, pricingSettingsTextNumericOnly),
    ...startDateValidation,
  });
};

export const addNewCustomerSchemaAdmins = (t: any) => {
  return yup.object().shape({
    company: companyName(t),
    name: userName(t),
    agentsLimit: yup
      .string()
      .test('', pricingSettingsTextNumericOnly, (value) =>
        !!value ? value.match(PATTERNS_DIGITS_ONLY_DISCOUNT) : true,
      )
      .required()
      .label('Agents Limit'),
    email: email,
    timeFormat: yup.string().required().label('Date Format'),
    language: language,
    dailyReportEmail: emailNotRequired,
    errorReportEmail: emailNotRequired,
    phoneNumber: phoneNotRequired(t),
    timeZone: timeZone,
    coordinates: yup.array().required(),
    address: yup
      .string()
      .when('coordinates', {
        is: (b) => {
          return b === undefined;
        },
        then: yup.string().max(0, t('Could not find this address')),
      })
      .required()
      .label('Address'),
    customerId: yup
      .string()
      .required(t('Can not be empty'))
      .max(11, `${t('Must be less than')} 11`)
      .matches(/^\d+$|^$/, pricingSettingsTextNumericOnly)
      .test('', `${t('Can not start with')} 90`, (value) => {
        const firstSymbol = value?.replace(/ /g, '')[0];
        const secondSymbol = value?.replace(/ /g, '')[1];

        if (firstSymbol === '9' && secondSymbol === '0') {
          return false;
        }

        return true;
      }),
    ...startDateValidation,
  });
};

export const addSelfAccountContactSchema = (t: any) => {
  return yup.object().shape({
    company: companyName(t),
    name: userName(t),
    email: email,
    timeFormat: yup.string().required().label('Date Format'),
    language: language,
    phoneNumber: yup
      .string()
      .test('phone-validation', t('Invalid format'), (value) => (!!value ? value.match(/^\+(?:\d ?){6,14}\d$/g) : true))
      .label('Telephone'),
    timeZone: timeZone,
    coordinates: yup.array().required(),
    address: yup
      .string()
      .when('coordinates', {
        is: (b) => {
          return b === undefined;
        },
        then: yup.string().max(0, t('Could not find this address')),
      })
      .required()
      .label('Address'),
    customerId: yup.string().matches(/^\d+$|^$/, pricingSettingsTextNumericOnly),
    country: yup.string().required().label('Country'),
    endDate: yup.date().nullable(),
    startDate: yup
      .date()
      .nullable()
      .when('endDate', (endDate: any, schema: any) => {
        return endDate
          ? schema.max(new Date(endDate.getTime() - 60 * 60 * 24 * 1000), t('Must be earlier than Expiration Day'))
          : schema.nullable();
      }),
  });
};

export const addSelfAccountContractSchema = (t: any) => {
  return yup.object().shape({
    endDate: yup.date().nullable().notRequired(),
    startDate: yup
      .date()
      .nullable()
      .notRequired()
      .when('endDate', (endDate: any, schema: any) => {
        return endDate
          ? schema.max(new Date(endDate.getTime() - 60 * 60 * 24 * 1000), t('Must be earlier than Expiration Day'))
          : schema.nullable();
      }),
  });
};

export const addNewViewUserSchema = (t: any) => {
  return yup.object().shape({
    viewUsers: yup.array().of(
      yup.object().shape({
        name: viewGlobalName(t),
        email: email,
        timeFormat: yup.string().required().label('Date Format'),
      }),
    ),
    addingUsers: yup.array().of(
      yup.object().shape({
        name: viewGlobalName(t),
        email: email,
        timeFormat: yup.string().required().label('Date Format'),
      }),
    ),
  });
};

export const addNewGlobalUserSchema = (t: any) => {
  return yup.object().shape({
    globalUsers: yup.array().of(
      yup.object().shape({
        name: viewGlobalName(t),
        email: email,
        timeFormat: yup.string().required().label('Date Format'),
      }),
    ),
    addingUsers: yup.array().of(
      yup.object().shape({
        name: viewGlobalName(t),
        email: email,
        timeFormat: yup.string().required().label('Date Format'),
      }),
    ),
  });
};

export const addNewOrganizationSchema = (t: any) => {
  return yup.object().shape({
    enterpriseAdministrators: yup.array().of(
      yup.object().shape({
        name: viewGlobalName(t),
        email: email,
        timeFormat: yup.string().required().label('Date Format'),
        enterpriseName: enterpriseName(t),
      }),
    ),
    addingUsers: yup.array().of(
      yup.object().shape({
        name: viewGlobalName(t),
        email: email,
        timeFormat: yup.string().required().label('Date Format'),
        enterpriseName: enterpriseName(t),
      }),
    ),
  });
};

export const addNewAgentSchema = (t: any) => {
  return yup.object().shape({
    servers: yup.array().of(
      yup.object().shape({
        name: agentName(t),
        cpuAlert: yup
          .string()
          .matches(/^[1-9]\d?$|^100$/, t('{0} till {1} allowed').replace('{0}', '1').replace('{1}', '100')),
        ramAlert: yup
          .string()
          .matches(/^[1-9]\d?$|^100$/, t('{0} till {1} allowed').replace('{0}', '1').replace('{1}', '100')),
        discCUsage: yup
          .string()
          .matches(/^[1-9]\d?$|^100$|^$/, t('{0} till {1} allowed').replace('{0}', '1').replace('{1}', '100')),
        discDUsage: yup
          .string()
          .matches(/^[1-9]\d?$|^100$|^$/, t('{0} till {1} allowed').replace('{0}', '1').replace('{1}', '100')),
        alertWindow: yup
          .string()
          .matches(/^[1-9]\d{0,2}$/, t('{0} till {1} allowed').replace('{0}', '1').replace('{1}', '999')),
        // okStatusTime: yup.string().matches(/^[1-9][0-9]?[0-9]?$/, '1 till 999 allowed'),
        // keepAliveTime: yup
        //   .number()
        //   .typeError('1 till 999 allowed')
        //   .min(1, '1 till 999 allowed')
        //   .max(999, '1 till 999 allowed'),
        // timeToNoKeepAlive: yup
        //   .number()
        //   .test('len', '1 till 999 allowed', val => !val.toString().match(/^0.+/i))
        //   .typeError('1 till 999 allowed')
        //   .min(1, '1 till 999 allowed')
        //   .max(999, '1 till 999 allowed')
        //   .moreThan(yup.ref('keepAliveTime'), '< or = interval'),
        // resourcesTime: yup.string().matches(/^[1-9][0-9]?[0-9]?$/, '1 till 999 allowed'),
        folder1Files: yup.string().matches(/^\d+$|^$/, t('Invalid value')),
        folder2Files: yup.string().matches(/^\d+$|^$/, t('Invalid value')),
        folder3Files: yup.string().matches(/^\d+$|^$/, t('Invalid value')),
        folder4Files: yup.string().matches(/^\d+$|^$/, t('Invalid value')),
        folder5Files: yup.string().matches(/^\d+$|^$/, t('Invalid value')),
      }),
    ),
    addingServers: yup.array().of(
      yup.object().shape({
        name: agentName(t),
        cpuAlert: yup
          .string()
          .matches(/^[1-9]\d?$|^100$/, t('{0} till {1} allowed').replace('{0}', '1').replace('{1}', '100')),
        ramAlert: yup
          .string()
          .matches(/^[1-9]\d?$|^100$/, t('{0} till {1} allowed').replace('{0}', '1').replace('{1}', '100')),
        discCUsage: yup
          .string()
          .matches(/^[1-9]\d?$|^100$|^$/, t('{0} till {1} allowed').replace('{0}', '1').replace('{1}', '100')),
        discDUsage: yup
          .string()
          .matches(/^[1-9]\d?$|^100$|^$/, t('{0} till {1} allowed').replace('{0}', '1').replace('{1}', '100')),
        alertWindow: yup
          .string()
          .matches(/^[1-9]\d{0,2}$/, t('{0} till {1} allowed').replace('{0}', '1').replace('{1}', '999')),
        // okStatusTime: yup.string().matches(/^[1-9][0-9]?[0-9]?$/, '1 till 999 allowed'),
        // keepAliveTime: yup
        //   .number()
        //   .typeError('1 till 999 allowed')
        //   .min(1, '1 till 999 allowed')
        //   .max(999, '1 till 999 allowed'),
        // timeToNoKeepAlive: yup
        //   .number()
        //   .test('len', '1 till 999 allowed', val => !val.toString().match(/^0.+/i))
        //   .typeError('1 till 999 allowed')
        //   .min(1, '1 till 999 allowed')
        //   .max(999, '1 till 999 allowed')
        //   .moreThan(yup.ref('keepAliveTime'), '< or = interval'),
        // resourcesTime: yup.string().matches(/^[1-9][0-9]?[0-9]?$/, '1 till 999 allowed'),
        folder1Files: yup.string().matches(/^\d+$|^$/, t('Invalid value')),
        folder2Files: yup.string().matches(/^\d+$|^$/, t('Invalid value')),
        folder3Files: yup.string().matches(/^\d+$|^$/, t('Invalid value')),
        folder4Files: yup.string().matches(/^\d+$|^$/, t('Invalid value')),
        folder5Files: yup.string().matches(/^\d+$|^$/, t('Invalid value')),
      }),
    ),
  });
};

export const addNewWorkflowStep = yup.object().shape({
  workflowSteps: yup.array().of(
    yup.object().shape({
      label: yup.string().required(),
      value: yup.string().required(),
    }),
  ),
});

const tableViewSchema = yup.array().of(
  yup.object().shape({
    columnName: yup.string().required(),
    columnLabel: yup.string().required(),
    nameInXML: yup.string().required(),
    filterType: yup.string().required(),
  }),
);
export const addNewTableView = yup.object().shape({
  addingFilters: tableViewSchema,
  tableView: tableViewSchema,
});

export const searchCustomerSchema = (t: any) => {
  return yup.object().shape({
    customerSearch: yup.string().test('name-validation', t('Invalid format'), (value) => !value.match(/&/gi)),
  });
};

export const referenceSchema = yup.object().shape({
  customerSearch: yup.string(),
});

export const addNewFileLAnguages = yup.object().shape({
  abbreviation: yup.string().required(),
  fileName: yup.string().required(),
});

export const userAccountParametersSchema = (t: any) => {
  return yup.object().shape({
    company: companyName(t),
    name: userName(t),
    endDate: yup.date(),
    startDate: yup
      .date()
      .when(
        'endDate',
        (endDate: any, schema: any) =>
          endDate &&
          schema.max(new Date(endDate.getTime() - 60 * 60 * 24 * 1000), t('Must be earlier than Expiration Day')),
      ),
    timeFormat: yup.string().required().label('Date Format'),
    // country: country,
    email: email,
    language: language,
    // dailyReportEmail: email,
    phoneNumber: phone(t),
    timeZone: timeZone,
    coordinates: yup.array().required(),
    address: yup
      .string()
      .when('coordinates', {
        is: (b) => {
          return b === undefined;
        },
        then: yup.string().max(0, t('Could not find this address')),
      })
      .required()
      .label('Address'),
  });
};

export const addNewAppearanceSettings = (t: any) => {
  return yup.object().shape({
    brand: companyName(t),
    backgroundUrl: yup.string().required(),
    logoUrl: yup.string().required(),
    supportEmail: email,
  });
};

export const adminParametersSchema = (t: any) => {
  return yup.object().shape({
    name: userName(t),
    phoneNumber: phone(t),
    email: email,
    dailyReportEmail: email,
    timeFormat: yup.string().required().label('Date Format'),
    hotFolderPath: yup
      .string()
      // .test('hotFolderPath-validation', 'Invalid format', value => value.match(/\\\\[\w-.]+(\\[\w-]+)+\${0,1}/g))
      .required(),
    hotFolderUserLogin: yup.string(),
    hotFolderUserPassword: yup.string(),
    smtpAddress: yup
      .string()
      .test('smtpAddress-validation', t('Invalid format'), (value) =>
        value.match(
          /^(([\dA-Za-z]|[\dA-Za-z][\d-AZa-z-]*[\dA-Za-z])\.)*([\dA-Za-z]|[\dA-Za-z][\d-AZa-z-]*[\dA-Za-z])$/g,
        ),
      )
      .required(),
    smtpUserName: yup.string().required(),
    smtpUserPassword: yup.string().required(),
    // timeToNoKeepAliveXML: yup.string().required(),
    keepAliveTime: yup.number().test('max', t('Set less than H/W no KeepAlive'), function (value) {
      const { timeToNoKeepAlive } = this.parent;
      return value < timeToNoKeepAlive;
    }),
    resourcesTime: yup
      .number()
      .typeError('Invalid number')
      .required()
      .min(1, `${t('Set more than or equal to')} 1`)
      .max(5, `${t('Set more than or equal to')} 5`),
    okStatusTime: yup
      .number()
      .typeError('Invalid number')
      .required()
      .min(1, `${t('Set more than or equal to')} 1`)
      .max(100, `${t('Set more than or equal to')} 100`),
  });
};

export const globalParametersSchema = yup.object().shape({
  name: yup.string().max(25).required(),
  email: yup.string().email().required().label('Email'),
  timeFormat: yup.string().required().label('Date Format'),
});

export const viewUserParametersSchema = (t: any) => {
  return yup.object().shape({
    name: yup.string().required().max(25, t('Name must not be longer than {0} symbols').replace('{0}', '25')),
    email: yup.string().email().required().label('Email'),
    customerId: yup.string().required(),
    timeFormat: yup.string().required().label('Date Format'),
  });
};

export const viewUserContactSchema = (t: any) => {
  return yup.object().shape({
    name: yup.string().required().max(25, t('Name must not be longer than {0} symbols').replace('{0}', '25')),
    email: yup.string().email().required().label('Email'),
    timeFormat: yup.string().required().label('Date Format'),
  });
};

export const viewUserContactWithoutValidationSchema = yup.object().shape({
  name: yup.string(),
  email: yup.string(),
  customerId: yup.string(),
  timeFormat: yup.string(),
});

export const viewUserWithoutCustomerIdParametersSchema = (t: any) => {
  return yup.object().shape({
    name: yup.string().required().max(25, t('Name must not be longer than {0} symbols').replace('{0}', '25')),
    email: yup.string().email().required().label('Email'),
    timeFormat: yup.string().required().label('Date Format'),
  });
};

export const organizationUpdateSchema = (t: any) => {
  return yup.object().shape({
    name: yup
      .string()
      .max(24, `${t('Must be less than')} 25`)
      .required()
      .label('Name'),
    orgCustomerId: yup
      .string()
      .max(11, `${t('Must be less than')} 11`)
      .test('', t(pricingSettingsTextDigitalOnly), (value) =>
        !!value ? value.match(PATTERNS_DIGITS_ONLY_DISCOUNT) : true,
      ),
    eatag: yup.string().required().label('Company Name'),
    email: yup.string().email().required().label('Email'),
    timeFormat: yup.string().required().label('Date Format'),
    // orgCustomerId: yup.string().label('Account ID'),
    phoneNumber: yup
      .string()
      // Minimum eight characters, at least one uppercase letter, one lowercase letter and one number
      .test('phone-validation', t('Invalid format'), (value) => (!!value ? value.match(/^\+(?:\d ?){6,14}\d$/g) : true))
      .label('Telephone'),
    ...startDateValidation,
  });
};

export const organizationUpdateSchemaAdmins = (t: any) => {
  return yup.object().shape({
    name: yup
      .string()
      .max(24, `${t('Must be less than')} 25`)
      .required()
      .label('Name'),
    orgCustomerId: yup
      .string()
      .required(t('Can not be empty'))
      .max(11, `${t('Must be less than')} 11`)
      .test('', t(pricingSettingsTextDigitalOnly), (value) =>
        !!value ? value.match(PATTERNS_DIGITS_ONLY_DISCOUNT) : true,
      )
      .test('', `${t('Can not start with')} 90`, (value) => {
        const firstSymbol = value?.replace(/ /g, '')[0];
        const secondSymbol = value?.replace(/ /g, '')[1];

        if (firstSymbol === '9' && secondSymbol === '0') {
          return false;
        }

        return true;
      }),

    eatag: yup.string().required().label('Company Name'),
    email: yup.string().email().required().label('Email'),
    timeFormat: yup.string().required().label('Date Format'),
    phoneNumber: yup
      .string()
      // Minimum eight characters, at least one uppercase letter, one lowercase letter and one number
      .test('phone-validation', t('Invalid format'), (value) => (!!value ? value.match(/^\+(?:\d ?){6,14}\d$/g) : true))
      .label('Telephone'),
    ...startDateValidation,
  });
};

export const organizationUserSchema = (t: any) => {
  return yup.object().shape({
    name: yup
      .string()
      .max(24, `${t(`Must be less than`)} 25`)
      .required()
      .label('Name'),
    email: yup.string().email().required().label('Email'),
    timeFormat: yup.string().required().label('Date Format'),
    enterpriseId: yup.string().required(),
    // orgCustomerId: yup.string().label('Account ID'),
    phoneNumber: yup
      .string()
      // Minimum eight characters, at least one uppercase letter, one lowercase letter and one number
      .test('phone-validation', t('Invalid format'), (value) => (!!value ? value.match(/^\+(?:\d ?){6,14}\d$/g) : true))
      .label('Telephone'),
  });
};

export const organizationUserSchemaWithoutEnterpriseId = (t: any) => {
  return yup.object().shape({
    name: yup
      .string()
      .max(24, `${t(`Must be less than`)} 25`)
      .required()
      .label('Name'),
    email: yup.string().email().required().label('Email'),
    timeFormat: yup.string().required().label('Date Format'),
    phoneNumber: yup
      .string()
      .test('phone-validation', t('Invalid format'), (value) => (!!value ? value.match(/^\+(?:\d ?){6,14}\d$/g) : true))
      .label('Telephone'),
  });
};

export const paymentShortSchema = (t: any) => {
  return yup.object().shape({
    name: yup
      .string()
      .max(22, `${t('Must be less than')} 22`)
      .required()
      .label('Name on card'),
    email: yup.string().email().required().label('Email'),
  });
};

export const subscriptionOrgUserSchema = (t: any) => {
  return yup.object().shape({
    numberOfUsers: yup
      .string()
      .test('', t(pricingSettingsTextDigitalOnly), (value) =>
        !!value ? value.match(PATTERNS_DIGITS_ONLY_DISCOUNT) : true,
      )
      .required(),
    numberOfAccounts: yup
      .string()
      .test('', t(pricingSettingsTextDigitalOnly), (value) =>
        !!value ? value.match(PATTERNS_DIGITS_ONLY_DISCOUNT) : true,
      )
      .required(),
    numberOfServers: yup
      .string()
      .test('', t(pricingSettingsTextDigitalOnly), (value) =>
        !!value ? value.match(PATTERNS_DIGITS_ONLY_DISCOUNT) : true,
      )
      .required(),
    numberOfWorkflowSteps: yup
      .string()
      .test('', t(pricingSettingsTextDigitalOnly), (value) =>
        !!value ? value.match(PATTERNS_DIGITS_ONLY_DISCOUNT) : true,
      )
      .required(),
    numberOfProducts: yup
      .string()
      .test('', t(pricingSettingsTextDigitalOnly), (value) =>
        !!value ? value.match(PATTERNS_DIGITS_ONLY_DISCOUNT) : true,
      )
      .required(),
    workflowMonitoring: yup.string().required(),
    serversMonitoring: yup.string().required(),
    workflowReporting: yup.string().required(),
  });
};

export const subscriptionAccountSchema = (t: any) => {
  return yup.object().shape({
    numberOfUsers: yup
      .string()
      .test('', t(pricingSettingsTextDigitalOnly), (value) =>
        !!value ? value.match(PATTERNS_DIGITS_ONLY_DISCOUNT) : true,
      )
      .required(),
    numberOfServers: yup
      .string()
      .test('', t(pricingSettingsTextDigitalOnly), (value) =>
        !!value ? value.match(PATTERNS_DIGITS_ONLY_DISCOUNT) : true,
      )
      .required(),
    numberOfWorkflowSteps: yup
      .string()
      .test('', t(pricingSettingsTextDigitalOnly), (value) =>
        !!value ? value.match(PATTERNS_DIGITS_ONLY_DISCOUNT) : true,
      )
      .required(),
    numberOfProducts: yup
      .string()
      .test('', t(pricingSettingsTextDigitalOnly), (value) =>
        !!value ? value.match(PATTERNS_DIGITS_ONLY_DISCOUNT) : true,
      )
      .required(),
    workflowMonitoring: yup.string().required(),
    serversMonitoring: yup.string().required(),
    workflowReporting: yup.string().required(),
  });
};

export const prisingSettingsSchema = (t: any) => {
  return yup.object().shape({
    annualDiscount: yup
      .string()
      .test('', t(pricingSettingsTextDigitalOnly), (value) =>
        !!value ? value.match(PATTERNS_DIGITS_ONLY_DISCOUNT) : true,
      )
      .label('Annual discount'),
    dataAccess: yup
      .string()
      .min(1)
      .test('', t(pricingSettingsTextDigitalOnly), (value) =>
        !!value ? value.match(PATTERNS_DIGITS_ONLY_TRIAL) : true,
      )
      .test('', `${t('More than')} '0'`, (value) => (typeof Number(value) === 'number' ? Number(value) >= 1 : true))
      .label('Data access'),
    removal: yup
      .string()
      .test('', t(pricingSettingsTextDigitalOnly), (value) =>
        !!value ? value.match(PATTERNS_DIGITS_ONLY_TRIAL) : true,
      )
      .label('Removal'),
    trial_servers: yup
      .string()
      .test('', t(pricingSettingsTextDigitalOnly), (value) =>
        !!value ? value.match(PATTERNS_DIGITS_ONLY_TRIAL) : true,
      )
      .label('Servers monitoring'),
    trial_workflow: yup
      .string()
      .test('', t(pricingSettingsTextDigitalOnly), (value) =>
        !!value ? value.match(PATTERNS_DIGITS_ONLY_TRIAL) : true,
      )
      .label('Workflow steps monitoring'),
    trial_licences: yup
      .string()
      .test('', t(pricingSettingsTextDigitalOnly), (value) =>
        !!value ? value.match(PATTERNS_DIGITS_ONLY_TRIAL) : true,
      )
      .label('Licences'),
    trial_accounts: yup
      .string()
      .test('', t(pricingSettingsTextDigitalOnly), (value) =>
        !!value ? value.match(PATTERNS_DIGITS_ONLY_TRIAL) : true,
      )
      .label('Accounts'),
    trial_users: yup
      .string()
      .test('', t(pricingSettingsTextDigitalOnly), (value) =>
        !!value ? value.match(PATTERNS_DIGITS_ONLY_TRIAL) : true,
      )
      .label('Users'),
    accounts_prices_additional: yup
      .string()
      .test('', t(pricingSettingsText).replace('{0}', '0'), (value) =>
        !!value ? value.match(PATTERNS_DIGITS_ONLY) : true,
      ),
    accounts_prices_10: yup
      .string()
      .test('', t(pricingSettingsText).replace('{0}', '0'), (value) =>
        !!value ? value.match(PATTERNS_DIGITS_ONLY) : true,
      ),
    accounts_prices_20: yup
      .string()
      .test('', t(pricingSettingsText).replace('{0}', '0'), (value) =>
        !!value ? value.match(PATTERNS_DIGITS_ONLY) : true,
      ),
    accounts_prices_50: yup
      .string()
      .test('', t(pricingSettingsText).replace('{0}', '0'), (value) =>
        !!value ? value.match(PATTERNS_DIGITS_ONLY) : true,
      ),
    accounts_prices_100: yup
      .string()
      .test('', t(pricingSettingsText).replace('{0}', '0'), (value) =>
        !!value ? value.match(PATTERNS_DIGITS_ONLY) : true,
      ),
    'accounts_prices_100+': yup
      .string()
      .test('', t(pricingSettingsText).replace('{0}', '0'), (value) =>
        !!value ? value.match(PATTERNS_DIGITS_ONLY) : true,
      ),
    users_prices_additional: yup
      .string()
      .test('', t(pricingSettingsText).replace('{0}', '0'), (value) =>
        !!value ? value.match(PATTERNS_DIGITS_ONLY) : true,
      ),
    users_prices_10: yup
      .string()
      .test('', t(pricingSettingsText).replace('{0}', '0'), (value) =>
        !!value ? value.match(PATTERNS_DIGITS_ONLY) : true,
      ),
    users_prices_20: yup
      .string()
      .test('', t(pricingSettingsText).replace('{0}', '0'), (value) =>
        !!value ? value.match(PATTERNS_DIGITS_ONLY) : true,
      ),
    users_prices_50: yup
      .string()
      .test('', t(pricingSettingsText).replace('{0}', '0'), (value) =>
        !!value ? value.match(PATTERNS_DIGITS_ONLY) : true,
      ),
    users_prices_100: yup
      .string()
      .test('', t(pricingSettingsText).replace('{0}', '0'), (value) =>
        !!value ? value.match(PATTERNS_DIGITS_ONLY) : true,
      ),
    'users_prices_100+': yup
      .string()
      .test('', t(pricingSettingsText).replace('{0}', '0'), (value) =>
        !!value ? value.match(PATTERNS_DIGITS_ONLY) : true,
      ),
    server_prices_additional: yup
      .string()
      .test('', t(pricingSettingsText).replace('{0}', '0'), (value) =>
        !!value ? value.match(PATTERNS_DIGITS_ONLY) : true,
      ),
    server_prices_10: yup
      .string()
      .test('', t(pricingSettingsText).replace('{0}', '0'), (value) =>
        !!value ? value.match(PATTERNS_DIGITS_ONLY) : true,
      ),
    server_prices_20: yup
      .string()
      .test('', t(pricingSettingsText).replace('{0}', '0'), (value) =>
        !!value ? value.match(PATTERNS_DIGITS_ONLY) : true,
      ),
    server_prices_50: yup
      .string()
      .test('', t(pricingSettingsText).replace('{0}', '0'), (value) =>
        !!value ? value.match(PATTERNS_DIGITS_ONLY) : true,
      ),
    server_prices_100: yup
      .string()
      .test('', t(pricingSettingsText).replace('{0}', '0'), (value) =>
        !!value ? value.match(PATTERNS_DIGITS_ONLY) : true,
      ),
    'server_prices_100+': yup
      .string()
      .test('', t(pricingSettingsText).replace('{0}', '0'), (value) =>
        !!value ? value.match(PATTERNS_DIGITS_ONLY) : true,
      ),
    workflow_prices_additional: yup
      .string()
      .test('', t(pricingSettingsText).replace('{0}', '0'), (value) =>
        !!value ? value.match(PATTERNS_DIGITS_ONLY) : true,
      ),
    workflow_prices_10: yup
      .string()
      .test('', t(pricingSettingsText).replace('{0}', '0'), (value) =>
        !!value ? value.match(PATTERNS_DIGITS_ONLY) : true,
      ),
    workflow_prices_20: yup
      .string()
      .test('', t(pricingSettingsText).replace('{0}', '0'), (value) =>
        !!value ? value.match(PATTERNS_DIGITS_ONLY) : true,
      ),
    workflow_prices_50: yup
      .string()
      .test('', t(pricingSettingsText).replace('{0}', '0'), (value) =>
        !!value ? value.match(PATTERNS_DIGITS_ONLY) : true,
      ),
    workflow_prices_100: yup
      .string()
      .test('', t(pricingSettingsText).replace('{0}', '0'), (value) =>
        !!value ? value.match(PATTERNS_DIGITS_ONLY) : true,
      ),
    'workflow_prices_100+': yup
      .string()
      .test('', t(pricingSettingsText).replace('{0}', '0'), (value) =>
        !!value ? value.match(PATTERNS_DIGITS_ONLY) : true,
      ),
  });
};

export const signUpSchema = (isAccount: boolean, t: any) => {
  return yup.object().shape({
    companyName: yup
      .string()
      .test('name-validation', `${t('Invalid symbol')} &`, (value) => !value.match(/&/gi))
      .min(3, `${t('Must be longer than')} 3`)
      .max(59, `${t('Must be less than')} 60`)
      .required()
      .label('Company Name'),
    fullName: userName(t),
    email: yup.string().email().required().label('Email'),
    phoneNumber: yup
      .string()
      // Minimum eight characters, at least one uppercase letter, one lowercase letter and one number
      .test('phone-validation', t('Invalid format'), (value) => (!!value ? value.match(/^\+(?:\d ?){6,14}\d$/g) : true))
      .label('Telephone'),
    password: yup
      .string()
      // Minimum eight characters, at least one uppercase letter, one lowercase letter and one number
      .test('pass-validation', t('Invalid format'), (value) =>
        // eslint-disable-next-line
        value.match(/^(?=.*?[A-Z])(?=(.*[a-z])+)(?=(.*\d)+)(?=(.*[!#$%&()*+@^\-])*)(?!.*\s).{8,}$/g),
      )
      .required()
      .label('Password'),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref('password'), null], t('Passwords must match'))
      .required()
      .label('Confirm Password'),
    ...(isAccount && {
      timeZone: timeZone,
      coordinates: yup.array().required(),
      address: yup
        .string()
        .when('coordinates', {
          is: (b) => {
            return b === undefined;
          },
          then: yup.string().max(0, t('Could not find this address')),
        })
        .required()
        .label('Address'),
      country,
    }),
  });
};
