import React, { ForwardRefRenderFunction } from 'react';
import { useTranslation } from 'react-i18next';
import { Icon } from '../Icon';

import { useStyles } from './styles';

interface Props {
  labelKey: string;
  disabled?: boolean;
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  type: 'button' | 'submit' | 'reset' | undefined;
  className?: string;
  fullWidth?: boolean;
  textStyle?: boolean;
  attentionStyle?: boolean;
  secondaryStyle?: boolean;
  successStyle?: boolean;
  id?: string;
  ref?: any;
  icon?: string;
  iconColor?: string;
  emptyStyle?: boolean;
  xs?: boolean;
}

const Button: ForwardRefRenderFunction<HTMLButtonElement, Props> = (
  {
    labelKey,
    type,
    onClick,
    disabled,
    className,
    secondaryStyle,
    fullWidth,
    textStyle,
    attentionStyle,
    successStyle,
    id,
    icon,
    iconColor,
    emptyStyle,
    xs,
  }: Props,
  ref: any,
) => {
  // Translation
  const { t } = useTranslation();

  const styles = useStyles();
  const buttonStyles = [
    styles.button,
    className ? className : '',
    fullWidth ? styles.fullWidth : '',
    textStyle ? styles.textStyle : styles.buttonStyle,
    attentionStyle ? styles.attentionStyle : '',
    secondaryStyle ? styles.secondaryStyle : '',
    successStyle ? styles.successStyle : '',
    emptyStyle ? styles.emptyStyle : '',
    xs ? styles.xs : '',
  ].join(' ');

  const handleClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
    if (disabled) {
      return;
    }
    if (onClick) {
      onClick(event);
    }
  };

  const idProp = id ? { id } : {};
  const refProp = ref ? { ref } : {};

  return (
    <button className={buttonStyles} type={type} onClick={handleClick} disabled={disabled} {...idProp} {...refProp}>
      {icon ? <Icon name={icon} color={iconColor} /> : <></>}
      {t(labelKey)}
    </button>
  );
};

export default React.forwardRef(Button);
