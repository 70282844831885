// Browser storage data manipulation utils
// ===============================
import { SessionActions, SessionTokens } from 'store/session/types';
import { UserRolesEnum } from './types';
//
//
type InitSessionSyncOptions = {
  setTokens: (arg: SessionTokens) => SessionActions;
  logout: (arg: boolean) => any;
};
/**
 * Checks if the previous session was saved
 */
export function isPreviousSessionSaved(): boolean {
  const rememberMe: string | null = localStorage.getItem('rememberMe');
  return !!(rememberMe && rememberMe === 'true');
}

/**
 * Removes language from local storage
 */
export function removeLanguage(): void {
  localStorage.removeItem('language');
}

/**
 * Saves language to local storage
 * @param lng
 */
export function saveLanguage(lng: string): void {
  if (lng) {
    localStorage.setItem('language', lng);
  }
}

/**
 * Saves `rememberMe` setting into localStorage
 * @param value current `rememberMe` value
 */
export function setSessionSettings(value: boolean): void {
  localStorage.setItem('rememberMe', value.toString());
}

/**
 * Removes all session data from both browser storages
 */
export function removeSession(): void {
  localStorage.removeItem('session');
  sessionStorage.removeItem('session');
  localStorage.removeItem('5180ee71-8fda-425f-a9ec-1c6c4a75ed70');
  sessionStorage.removeItem('5180ee71-8fda-425f-a9ec-1c6c4a75ed70');
  setSessionSettings(false);

  // remove local storage table data
  sessionStorage.clear();
  // removeLanguage();
}

/**
 * Returns session data from browser storage
 */
export function getTokens(): string {
  const shouldSaveSession: boolean = isPreviousSessionSaved();
  if (shouldSaveSession) {
    return localStorage.getItem('session') || '';
  }
  return sessionStorage.getItem('session') || '';
}

/**
 * Returns user role data from browser storage
 */
export function getRole(): string {
  const shouldSaveSession: boolean = isPreviousSessionSaved();
  if (shouldSaveSession) {
    return localStorage.getItem('5180ee71-8fda-425f-a9ec-1c6c4a75ed70') || '';
  }
  return sessionStorage.getItem('5180ee71-8fda-425f-a9ec-1c6c4a75ed70') || '';
}

export function shareSessionStorage(): void {
  const tokens = getTokens();
  const role = getRole();
  if (tokens || role) {
    localStorage.setItem('sharedSessionStorage', JSON.stringify({ role, tokens }));
    localStorage.setItem('sharedSessionStorage', JSON.stringify(null));
  }
}

export function saveSessionToSessionStorage(session: string, role: string): void {
  sessionStorage.setItem('5180ee71-8fda-425f-a9ec-1c6c4a75ed70', role);
  sessionStorage.setItem('session', session);
}

/**
 * Saves session data into browser storage depending on `rememberMe` setting
 * @param data tokens object
 * @param userRole
 */
export function saveSession(data: SessionTokens, userRole?: string): void {
  const shouldSaveSession: boolean = isPreviousSessionSaved();

  const { access, refresh }: SessionTokens = data;
  const session = JSON.stringify({
    access,
    refresh,
  });
  const role = (): string => {
    switch (userRole) {
      case UserRolesEnum.admin:
        return 'e69ee3b1-0495-44ff-987b-4f27b01535cc'; // Constant UUID for admin role
      case UserRolesEnum.account:
        return '364b431c-71b4-463f-bb52-213f2754db82'; // Constant UUID for customer role
      case UserRolesEnum.globalUser:
        return '7b71386f-73b1-4789-89b9-ee5e09610a00'; // Constant UUID for globalUser role
      case UserRolesEnum.accountUser:
        return 'a6b71146-7a2f-11ea-bc55-0242ac130003'; // Constant UUID for viewUser role
      default:
        return localStorage.getItem('5180ee71-8fda-425f-a9ec-1c6c4a75ed70') || '';
    }
  };
  if (shouldSaveSession) {
    localStorage.setItem('session', session);
    localStorage.setItem('5180ee71-8fda-425f-a9ec-1c6c4a75ed70', role());
  } else {
    saveSessionToSessionStorage(session, role());
    shareSessionStorage();
  }
}

/**
 * Parses token string
 * @param data token data string
 */
export function parseTokens(data: string): SessionTokens | null {
  const parsedData = JSON.parse(data);

  if (!parsedData || !parsedData.access) {
    return null;
  }
  return { access: parsedData.access, refresh: parsedData.refresh };
}

export function logout(): void {
  localStorage.setItem('logOut', Date.now().toString());
}

/**
 * There is one more listener at public/session.js script,
 * which saves the 'sharedSessionStorage' value into session storage.
 * It is made so to achieve proper sharing, because session storage is async.
 * Session data needs to be requested and recorded before react app script loads and executes.
 */
export function initSessionSync(store: Record<string, any>, { setTokens, logout }: InitSessionSyncOptions): void {
  window.addEventListener('storage', (event: StorageEvent) => {
    const newValue = JSON.parse(event.newValue || 'null');
    switch (event.key) {
      case 'requestSessionStorage':
        shareSessionStorage();
        break;
      case 'sharedSessionStorage':
        if (newValue) {
          store.dispatch(setTokens(JSON.parse(newValue.tokens)));
        }
        break;
      case 'logOut':
        store.dispatch(logout(false));
        break;
      case 'session':
        if (newValue) {
          store.dispatch(setTokens(newValue));
        }
      default:
        break;
    }
  });
}
