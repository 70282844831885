import React, { FC, useCallback, useState } from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import { useStyles } from './styles';

export type EllipsisTextProps = {
  text: string;
  className?: string;
  classes?: Record<string, any>;
  isInline?: boolean;
  tooltipPlacement?: any;
  alwaysShowTooltip?: boolean;
  tooltipText?: string;
};

const EllipsisText: FC<EllipsisTextProps> = ({
  text,
  className,
  classes,
  isInline,
  tooltipPlacement,
  alwaysShowTooltip,
  tooltipText,
}) => {
  const classnames = useStyles({ classes, isInline });
  const [showTooltip, setShowTooltip] = useState(false);

  const handleEllipsis = useCallback(
    ({ currentTarget, type }) => {
      const shouldShow =
        type === 'pointerover' &&
        (alwaysShowTooltip || (!showTooltip && currentTarget.clientWidth < currentTarget.scrollWidth));

      const shouldHide = !shouldShow && showTooltip && type === 'pointerout';

      if (shouldShow) {
        setShowTooltip(true);
      } else if (shouldHide) {
        setShowTooltip(false);
      }
    },
    [showTooltip, alwaysShowTooltip],
  );

  return (
    <Tooltip
      open={showTooltip}
      disableFocusListener
      disableHoverListener
      disableTouchListener
      title={tooltipText || text}
      placement={tooltipPlacement || 'top'}
    >
      <div
        className={`${classnames.root} ${className || ''}`}
        onPointerOver={handleEllipsis}
        onPointerOut={handleEllipsis}
      >
        {text}
      </div>
    </Tooltip>
  );
};

export default EllipsisText;
