import axios, { AxiosRequestConfig } from 'axios';
import apiAddresses from 'api/apiAddresses';

const { API_URL } = apiAddresses;

const requestHandler = (request: AxiosRequestConfig): AxiosRequestConfig => {
  return request;
};

const axiosInstance = axios.create({
  baseURL: API_URL,
});

axiosInstance.interceptors.request.use((request) => requestHandler(request));

export { axiosInstance };
