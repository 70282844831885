import React, { ChangeEvent, FC, FocusEvent, MouseEvent, useCallback, useMemo, useState } from 'react';
import { IconButton, TableCell } from '@material-ui/core';
import Popover from '@material-ui/core/Popover';
import { Icon } from 'components/UI/Icon';

import { useStyles } from './styles';

export type SearchFilterProps = {
  defaultValue?: string;
  value?: string;
  onChange?: (value: string) => unknown;
  onFocus?: (event: FocusEvent<HTMLInputElement>) => unknown;
  onBlur?: (event: FocusEvent<HTMLInputElement>) => unknown;
  onOpen?: () => unknown;
  onClose?: () => unknown;
  style?: any;
  cellStyle?: any;
  searchableCount?: number;
  maxLength?: number;
};

const SearchFilter: FC<SearchFilterProps> = ({
  defaultValue,
  value,
  onChange,
  onFocus,
  onBlur,
  onOpen,
  onClose,
  style,
  cellStyle,
  maxLength,
  searchableCount = 1,
}) => {
  const styles = useStyles();
  const [isFocused, setIsFocused] = useState(false);
  const [isDeletePress, setIsDeletePress] = useState(false);
  const [innerValue, setInnerValue] = useState(defaultValue || value || '');
  const [anchorEl, setAnchorEl] = useState<HTMLTableCellElement | null>(null);

  const handleFocus = useCallback(
    (event: FocusEvent<HTMLInputElement>): void => {
      setIsFocused(true);
      if (onFocus) {
        onFocus(event);
      }
    },
    [onFocus],
  );

  const handleBlur = useCallback(
    (event: FocusEvent<HTMLInputElement>): void => {
      setIsFocused(false);
      if (onBlur) {
        onBlur(event);
      }
    },
    [onBlur],
  );

  const handleClose = useCallback((): void => {
    setAnchorEl(null);
    if (onClose) {
      onClose();
    }
  }, [onClose]);

  const handleFiltersClick = useCallback(
    (event: React.MouseEvent<HTMLTableCellElement>): void => {
      setAnchorEl(event.currentTarget);
      if (onOpen) {
        onOpen();
      }
    },
    [onOpen],
  );

  const handleInputChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target;
      setInnerValue((prevState: string) => {
        if (onChange) {
          if (value.length > searchableCount) {
            onChange(value);
          } else if (prevState.length > searchableCount && isDeletePress) {
            onChange('');
            setIsDeletePress(false);
          }
        }

        return value;
      });
    },
    [onChange, isDeletePress],
  );

  const handleKeyPress = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>): void => {
      if (event.key === 'Enter' || event.key === 'Escape') {
        handleClose();
      }

      if (event.key === 'Delete' || event.key === 'Backspace') {
        setIsDeletePress(true);
      }
    },
    [handleClose],
  );

  const searchInput = useMemo(() => {
    return (
      <input
        className={[styles.searchInput, isFocused ? styles.searchInputFocused : ''].join(' ')}
        type="text"
        onFocus={handleFocus}
        onBlur={handleBlur}
        onChange={handleInputChange}
        onKeyDown={handleKeyPress}
        value={innerValue}
        autoFocus
        maxLength={maxLength}
      />
    );
  }, [
    handleBlur,
    handleFocus,
    handleInputChange,
    handleKeyPress,
    innerValue,
    isFocused,
    styles.searchInput,
    styles.searchInputFocused,
  ]);

  const handleSearchClearButton = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation();
      setInnerValue('');
      if (onChange) {
        onChange('');
      }
    },
    [onChange],
  );

  return (
    <>
      <TableCell className={styles.filterCell} onClick={handleFiltersClick} style={cellStyle || {}}>
        <div className={styles.cellContent} style={style ? style : {}}>
          {!!innerValue ? (
            <>
              <input className={styles.searchLabel} value={innerValue} />
              <IconButton onClick={handleSearchClearButton}>
                <Icon name="close" size={9} color="#666666" />
              </IconButton>
            </>
          ) : (
            <Icon name="search" size={12} />
          )}
        </div>
      </TableCell>
      <Popover
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        {searchInput}
      </Popover>
    </>
  );
};

export default SearchFilter;
