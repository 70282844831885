import React, { ReactNode, useEffect } from 'react';
import { bootstrap } from 'store/session/actions';
import { useDispatch, useSelector } from 'react-redux';
import Loader from 'components/UI/Loader';
import { AppState } from 'store';

type Props = {
  children?: ReactNode;
};

const App: React.FunctionComponent = (props: Props) => {
  const isLoading = useSelector((state: AppState) => state.ui.isLoading);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(bootstrap());
  }, [dispatch]);

  const ui = useSelector((state: AppState) => state.ui);

  // change title
  useEffect(() => {
    const savedBrand = localStorage.getItem('brandName');
    const title = document.querySelector('title');

    if (savedBrand && savedBrand.length > 0 && savedBrand.includes(ui.themeBrandColor.brand)) {
      if (title) {
        title.innerHTML = savedBrand;
      }
    } else if (ui.themeBrandColor.brand.length > 0) {
      if (title) {
        title.innerHTML = ui.themeBrandColor.brand;
        localStorage.setItem('brandName', ui.themeBrandColor.brand);
      }
    }
  }, [ui.themeBrandColor.brand]);

  return (
    <>
      {isLoading && <Loader />}
      {props.children}
    </>
  );
};

export default App;
