import React, { FC, useCallback, useEffect, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, InputCode } from 'components/UI';
import { useStyles } from './styles';
import { useTranslation } from 'react-i18next';
import { codeVerifyingSchema } from 'utils/validation';
import { useDispatch } from 'react-redux';
import { resendActivation, verifyAccount } from 'store/session/actions';
import cn from 'classnames';
import { useHistory } from 'react-router-dom';

interface IProps {
  onVerifySuccess: () => void;
  registrationEmail: string;
}

const CodeVerifying: FC<IProps> = ({ registrationEmail, onVerifySuccess }) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const history = useHistory();

  const state: any = history.location.state;
  const isNeedSendCodeImmediately: boolean = state?.isNeedVerification;

  const formElement = useRef<HTMLFormElement>(null);
  const submitButtonElement = useRef<HTMLButtonElement>(null);

  const {
    handleSubmit,
    formState: { errors },
    setError,
    register,
    getValues,
    setValue,
    resetField,
  } = useForm({
    defaultValues: {
      code: '',
    },
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
    resolver: yupResolver(codeVerifyingSchema(t)),
  });

  const styles = useStyles();

  const values = getValues();

  const onCodeSubmit = async (data: any): Promise<void> => {
    const response: any = await dispatch(verifyAccount({ email: registrationEmail, tempCode: +data.code }));

    if (response && response.code === 'OK') {
      onVerifySuccess();
    } else if (response && response.statusCode === 403) {
      if (response.message === 'Verification code not valid!') {
        setError(
          'code',
          { type: 'notMatch', message: 'Verification code not valid! Please click resend.' },
          { shouldFocus: true },
        );
      }
    }
  };

  const handleCodeChange = (value: string) => {
    setValue('code', value);
  };

  const onResendCode = async (): Promise<void> => {
    const response: any = await dispatch(resendActivation(registrationEmail));

    if (response && response.code === 'OK') {
      resetField('code', { keepError: false, defaultValue: '' });
    }
  };

  const handleKeyPress = useCallback((event: any): void => {
    if (event.key === 'Enter' && document.activeElement?.closest('#code-verifying-form')) {
      event.preventDefault();
      if (submitButtonElement.current) {
        submitButtonElement.current.click();
      }
      if (event.target) {
        event.target.blur();
      }
    }
  }, []);

  useEffect(() => {
    register('code', { required: true });
  }, [register]);

  useEffect(() => {
    document.body.addEventListener('keypress', handleKeyPress);
    if (formElement.current) {
      formElement.current.focus();
    }
    return (): void => {
      document.body.removeEventListener('keypress', handleKeyPress);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formElement]);

  useEffect(() => {
    if (isNeedSendCodeImmediately) {
      onResendCode();
    }
  }, [isNeedSendCodeImmediately]);

  return (
    <>
      <h5 className={styles.formHeader}>{t('Complete registration')}</h5>

      <p className={styles.successTitle}>
        {t(
          'Account verification code was sent to your email. Please enter the code in order to complete registration.',
        )}
      </p>

      <form
        onSubmit={handleSubmit(onCodeSubmit)}
        tabIndex={-1}
        ref={formElement}
        id="code-verifying-form"
        className={styles.verifyingForm}
      >
        <InputCode
          onChange={handleCodeChange}
          value={values.code || ''}
          id="verifying-code"
          error={!!errors['code'] ? errors['code'].message : undefined}
          alignCenter
        />

        <div className={styles.resendButtonWrapper}>
          <Button
            className={styles.resendButton}
            labelKey={t('Resend code')}
            textStyle
            type="button"
            onClick={onResendCode}
          />
        </div>

        <Button
          labelKey={t('Verify')}
          className={cn(styles.submitButton)}
          type="submit"
          fullWidth
          ref={submitButtonElement}
        />
      </form>
    </>
  );
};

export default CodeVerifying;
