import { Reducer } from 'redux';
import { produce } from 'immer';

import { ServersAlertActionTypes, ServersAlertActions, ServersAlertState } from './types';
import { isNotNull } from '../../pages/BillingHistory';

const initialState: ServersAlertState = {
  records: [],
  recordsCount: 0,
  pagination: {
    page: isNotNull(sessionStorage.getItem('system-alerts__page'))
      ? Number(sessionStorage.getItem('system-alerts__page'))
      : 1,
    count: isNotNull(sessionStorage.getItem('system-alerts__count'))
      ? Number(sessionStorage.getItem('system-alerts__count'))
      : 25,
    endRecord: 0,
    startRecord: 0,
  },
  find: [
    {
      columnName: 'companyName',
      value: '',
    },
  ],
  columnsFilters: {},
  sortBy: { columnName: 'createdAt', order: 'desc' },
  startDate:
    isNotNull(sessionStorage.getItem('system-alerts__dates-start')) &&
    isNotNull(new Date(Number(sessionStorage.getItem('system-alerts__dates-start'))))
      ? new Date(Number(sessionStorage.getItem('system-alerts__dates-start')))
      : new Date(new Date().setDate(new Date().getDate() - 1)),
  endDate:
    isNotNull(sessionStorage.getItem('system-alerts__dates-end')) &&
    isNotNull(new Date(Number(sessionStorage.getItem('system-alerts__dates-end'))))
      ? new Date(Number(sessionStorage.getItem('system-alerts__dates-end')))
      : new Date(),
  accountType: 'all',
};

export const serversAlertReducer: Reducer<ServersAlertState, ServersAlertActions> = (
  state = initialState,
  action,
): ServersAlertState => {
  return produce(state, (draft: ServersAlertState) => {
    switch (action.type) {
      case ServersAlertActionTypes.SA_RESET_STATE:
        return initialState;

      case ServersAlertActionTypes.SA_SET_TABLE_DATA:
        draft.records = action.payload;
        return;

      case ServersAlertActionTypes.SA_SET_RECORDS_COUNT:
        draft.recordsCount = Number(action.payload);
        return;

      case ServersAlertActionTypes.SA_SET_ACCOUNT_TYPE:
        draft.accountType = action.payload;
        return;

      case ServersAlertActionTypes.SA_SET_COUNT:
        draft.pagination.count = Number(action.payload);
        draft.pagination.page = 1;
        return;

      case ServersAlertActionTypes.SA_SET_PAGE:
        draft.pagination.page = action.payload;
        return;

      case ServersAlertActionTypes.SA_SET_SHOWN:
        draft.pagination.startRecord = action.payload.startRecord;
        draft.pagination.endRecord = action.payload.endRecord;
        return;

      case ServersAlertActionTypes.SA_SET_SEARCH:
        draft.find = [action.payload];
        return;

      case ServersAlertActionTypes.SA_SET_FILTER_MULTI_SELECT:
        draft.columnsFilters = {
          ...state.columnsFilters,
          [action.payload.columnName]: {
            ...state.columnsFilters[action.payload.columnName],
            filterType: 'multiSelect',
            selectedOptions: action.payload.selectedOptions,
          },
        };
        draft.pagination.page = 1;
        return;

      case ServersAlertActionTypes.SA_SET_FILTER_MULTI_SELECT_PREV:
        draft.columnsFilters[action.payload.columnName] = {
          ...state.columnsFilters[action.payload.columnName],
          selectedOptionsPrev: action.payload.selectedOptionsPrev,
        };
        return;

      case ServersAlertActionTypes.SA_SET_FILTER_MULTI_SELECT_SHOWN:
        draft.columnsFilters[action.payload.columnName] = {
          ...state.columnsFilters[action.payload.columnName],
          selectedOptionsShown: action.payload.selectedOptionsShown,
        };
        return;

      case ServersAlertActionTypes.SA_SET_FILTER_STATE:
        draft.pagination.page = 0;
        return;

      case ServersAlertActionTypes.SA_SET_FILTER_SEARCH:
        if (!!action.payload.searchString) {
          draft.columnsFilters = {
            ...state.columnsFilters,
            [action.payload.columnName]: { filterType: 'search', searchString: action.payload.searchString },
          };
        } else {
          delete draft.columnsFilters[action.payload.columnName];
        }
        return;

      case ServersAlertActionTypes.SA_SET_FILTER_SEARCH_PREV:
        draft.columnsFilters[action.payload.columnName] = {
          ...state.columnsFilters[action.payload.columnName],
          searchStringPrev: action.payload.searchStringPrev,
        };
        return;

      case ServersAlertActionTypes.SA_TOGGLE_SORT_BY:
        if (state.sortBy.columnName === action.payload) {
          if (state.sortBy.order === 'asc') {
            draft.sortBy.order = 'desc';
          } else {
            draft.sortBy.order = 'asc';
          }
        } else {
          draft.sortBy.columnName = action.payload;
          draft.sortBy.order = 'asc';
        }
        return;

      case ServersAlertActionTypes.SA_SET_SORT_BY:
        draft.sortBy = action.payload;
        return;
      case ServersAlertActionTypes.SA_GET_TABLE_DATA:
        return;
      case ServersAlertActionTypes.SA_RESET_FILTERS:
        draft.columnsFilters = {};
        return;

      case ServersAlertActionTypes.SA_SET_START_DATE:
        draft.startDate = action.payload;
        return;
      case ServersAlertActionTypes.SA_SET_END_DATE:
        draft.endDate = action.payload;
        return;
      default:
        return state;
    }
  });
};
