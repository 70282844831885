import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core';

import i18n from 'utils/i18n';

export const useStyles = makeStyles((theme: Theme) => ({
  setPasswordContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '80px 0 0',
    minHeight: '13rem',

    '& form': {
      width: '100%',
      gap: 30,
      display: 'flex',
      flexDirection: 'column',
    },

    '& p': {
      margin: '0 0 0.5rem 0',
      fontSize: '0.875rem',
      textAlign: 'center',
    },
  },
  iconContainer: {
    display: 'flex',
    width: '100%',
    height: '8.5rem',
    alignItems: 'center',
    justifyContent: 'center',
  },
  imageWrapper: {
    height: '40px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  formHeader: {
    ...theme.typography.h5,
    margin: '0 0 45px',
    fontStretch: i18n.language === 'es' ? 'semi-condensed' : 'normal',
    textAlign: 'center',
    color: '#212121',
    fontWeight: 700,
  },
  activatedHeader: {
    ...theme.typography.h5,
    margin: '1.5rem 0 1.5rem 0',
    fontStretch: i18n.language === 'es' ? 'semi-condensed' : 'normal',
    textAlign: 'center',
    color: '#333333',
    fontWeight: 'bold',
  },
  secondLine: {
    fontSize: '0.8125rem',
    margin: '-20px 0 20px',
  },
  secondLineActive: {
    fontSize: '0.8125rem',
    textAlign: 'center',
    lineHeight: '19px',
    height: '38px',
  },
  input: {
    '& label': {
      fontWeight: 400,
    },
  },
  submitButton: {
    marginTop: '1rem',
  },
  textButton: {
    margin: '0.5rem auto 0',
  },
  backButton: {
    width: 40,
    height: 40,
    border: '2px solid #BFC0CD',
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& svg': {
      height: 16,
      marginRight: 2,
    },
  },
  flexBoxTitle: {
    display: 'flex',
    alignSelf: 'flex-start',
    gap: 16,
  },
}));
