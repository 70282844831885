export interface SArecord {
  customerName: string;
  severity: string;
  module: string[] | string;
  serverName: string;
  resolveTime: string;
  createdAt: string;
  companyName: string;
  systemName: string;
  id: string;
  type: string;
  resolveTimeCustomer: string;
  createdAtCustomer: string;
  timeZone: string;
  errorId: string;
  data: {
    appName: string;
    ram: {
      message: string;
    };
    cpu: {
      message: string;
    };
    drives: {
      drives: Array<{ message: string }>;
    };
    folders: {
      folders: Array<{ message: string; name: string; folderName: string }>;
    };
  };
}
export interface WArecord {
  customerName: string;
  stepType: string;
  severity: string;
  stepName: string;
  createdAt: string;
  resolveTime: string;
  resolveTimeCustomer: string;
  createdAtCustomer: string;
  id: string;
  type: string;
  customerId: string;
  timeZone: string;
}

export interface FindProp {
  columnName: string;
  value?: string;
  values?: string[];
}

export interface ServersAlertState {
  recordsCount: number;
  pagination: Pagination;
  find: FindProp[];
  columnsFilters: ColumnsFilters;
  records: SArecord[];
  sortBy: SortBy;
  startDate: Date;
  endDate: Date;
  accountType: string;
}

export interface SortBy {
  columnName: string;
  order: string;
}

export type FilterType = 'search' | 'multiSelect';

export interface ColumnsFilters {
  [columnName: string]: Filter;
}

export interface Pagination {
  page: number;
  count: number;
  startRecord: number;
  endRecord: number;
}

export interface Filter {
  filterType: FilterType;
  selectedOptions?: string[];
  selectedOptionsPrev?: string[];
  selectedOptionsShown?: string[];
  rangeStart?: string | Date;
  rangeEnd?: string | Date;
  searchString?: string;
  searchStringPrev?: string;
}

export interface CreditInfo {
  creditMonth: string;
  credit: number;
  creditUsed: number;
  creditForLastMonth: number;
  creditUsedLastMonth: number;
  paymentType: string;
  balance: number;
}

export interface TableViewDates {
  startDate: Date;
  endDate: Date;
}

export interface SARequestParameters {
  filters: {
    count?: string;
    page?: string;
    stepName: string;
    columnsFilters: Record<string, any>;
    dates: {
      start: string;
      end: string;
    };
    sortBy: SortBy;
  };
}
export enum ServersAlertActionTypes {
  SA_RESET_STATE = 'SA_RESET_STATE',
  SA_SET_CUSTOMER_ID = 'SA_SET_CUSTOMER_ID',
  SA_SET_CUSTOMER_NAME = 'SA_SET_CUSTOMER_NAME',
  SA_SET_TABLE_DATA = 'SA_SET_TABLE_DATA',
  SA_SET_COUNT = 'SA_SET_COUNT',
  SA_SET_PAGE = 'SA_SET_PAGE',
  SA_SET_SHOWN = 'SA_SET_SHOWN',
  SA_SET_SEARCH = 'SA_SET_SEARCH',
  SA_SET_RECORDS_COUNT = 'SA_SET_RECORDS_COUNT',
  SA_SET_FILTER_MULTI_SELECT = 'SA_SET_FILTER_MULTI_SELECT',
  SA_SET_FILTER_MULTI_SELECT_PREV = 'SA_SET_FILTER_MULTI_SELECT_PREV',
  SA_SET_FILTER_MULTI_SELECT_SHOWN = 'SA_SET_FILTER_MULTI_SELECT_SHOWN',
  SA_SET_FILTER_STATE = 'SA_SET_FILTER_STATE',
  SA_SET_FILTER_SEARCH = 'SA_SET_FILTER_SEARCH',
  SA_SET_FILTER_SEARCH_PREV = 'SA_SET_FILTER_SEARCH_PREV',
  SA_TOGGLE_SORT_BY = 'SA_TOGGLE_SORT_BY',
  SA_SET_SORT_BY = 'SA_SET_SORT_BY',
  SA_RESET_FILTERS = 'SA_RESET_FILTERS',
  SA_GET_TABLE_DATA = 'SA_GET_TABLE_DATA',
  SA_SET_SERVERS = 'SA_SET_SERVERS',
  SA_SET_START_DATE = 'SA_SET_START_DATE',
  SA_SET_END_DATE = 'SA_SET_END_DATE',
  SA_SET_ACCOUNT_TYPE = 'SA_SET_ACCOUNT_TYPE',
}

export interface SAResetState {
  type: ServersAlertActionTypes.SA_RESET_STATE;
}

export interface SASetTableViewData {
  type: ServersAlertActionTypes.SA_SET_TABLE_DATA;
  payload: SArecord[];
}

export interface SASetRecordsCount {
  type: ServersAlertActionTypes.SA_SET_RECORDS_COUNT;
  payload: number;
}

export interface SASetAccountType {
  type: ServersAlertActionTypes.SA_SET_ACCOUNT_TYPE;
  payload: string;
}

export interface SASetCount {
  type: ServersAlertActionTypes.SA_SET_COUNT;
  payload: number;
}

export interface SASetPage {
  type: ServersAlertActionTypes.SA_SET_PAGE;
  payload: number;
}

export interface SASetShown {
  type: ServersAlertActionTypes.SA_SET_SHOWN;
  payload: { startRecord: number; endRecord: number };
}

export interface SASetSearch {
  type: ServersAlertActionTypes.SA_SET_SEARCH;
  payload: {
    columnName: string;
    value: string;
  };
}

export interface SASetFilterMultiSelect {
  type: ServersAlertActionTypes.SA_SET_FILTER_MULTI_SELECT;
  payload: {
    columnName: string;
    selectedOptions: string[];
  };
}

export interface SASetFilterMultiSelectPrev {
  type: ServersAlertActionTypes.SA_SET_FILTER_MULTI_SELECT_PREV;
  payload: {
    columnName: string;
    selectedOptionsPrev: string[];
  };
}

export interface SASetFilterMultiSelectShown {
  type: ServersAlertActionTypes.SA_SET_FILTER_MULTI_SELECT_SHOWN;
  payload: {
    columnName: string;
    selectedOptionsShown: string[];
  };
}

export interface SASetFilterState {
  type: ServersAlertActionTypes.SA_SET_FILTER_STATE;
  payload: { columnName: string; selectedOptions: string[] };
}

export interface SASetFilterSearch {
  type: ServersAlertActionTypes.SA_SET_FILTER_SEARCH;
  payload: { columnName: string; searchString: string | undefined };
}

export interface SASetFilterSearchPrev {
  type: ServersAlertActionTypes.SA_SET_FILTER_SEARCH_PREV;
  payload: { columnName: string; searchStringPrev: string | undefined };
}

export interface SAToggleSortBy {
  type: ServersAlertActionTypes.SA_TOGGLE_SORT_BY;
  payload: string;
}

export interface SASetSortBy {
  type: ServersAlertActionTypes.SA_SET_SORT_BY;
  payload: SortBy;
}

export interface SAGetTableData {
  type: ServersAlertActionTypes.SA_GET_TABLE_DATA;
}

export interface SAResetFilters {
  type: ServersAlertActionTypes.SA_RESET_FILTERS;
}
export interface SASetStartDate {
  type: ServersAlertActionTypes.SA_SET_START_DATE;
  payload: Date;
}
export interface SASetEndDate {
  type: ServersAlertActionTypes.SA_SET_END_DATE;
  payload: Date;
}
export type ServersAlertActions =
  | SAResetState
  | SAResetFilters
  | SASetTableViewData
  | SASetCount
  | SASetPage
  | SASetShown
  | SASetRecordsCount
  | SASetSearch
  | SASetFilterMultiSelect
  | SASetFilterMultiSelectPrev
  | SASetFilterMultiSelectShown
  | SASetFilterState
  | SASetFilterSearch
  | SASetFilterSearchPrev
  | SAToggleSortBy
  | SASetSortBy
  | SAGetTableData
  | SASetStartDate
  | SASetEndDate
  | SASetAccountType;
