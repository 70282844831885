import React, { ChangeEvent, FC, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Select } from 'components/UI';
import { useStyles } from '../styles';

export type IDataSelect = {
  options: { value: string | number; label: string }[];
  label: string;
  value?: string;
  defaultValue?: number;
  onChange: (value: string) => any;
};

const DataSelect: FC<IDataSelect> = ({ value, defaultValue, label, options, onChange }) => {
  const styles = useStyles();
  const { t } = useTranslation();

  const limitSelectLabel = useMemo(() => t(label || 'Account type'), [label, t]);

  const handleChange = useCallback((event: ChangeEvent<HTMLSelectElement>) => onChange(event.target.value), [onChange]);

  return (
    <Select
      labelKey={limitSelectLabel}
      className={styles.accountTypeSelect}
      options={options ?? []}
      defaultValue={defaultValue || options ? options[0]?.value : ''}
      onChange={handleChange}
      {...(value ? { value } : {})}
      bordered
    />
  );
};

export default DataSelect;
