import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  a: {
    fill: '#0a759d',
  },
  '.b,.c': {
    fill: '#231f20',
  },
  b: {
    fillRule: 'evenodd',
  },
  d: {
    fill: '#006fac',
  },
  primaryColor: {
    fill: theme.palette.primary.main,
  },
  // agfaSt0: { fillRule: 'evenodd', clipRule: 'evenodd', fill: '#1D1D1B' },
  agfaSt1: { fillRule: 'evenodd', clipRule: 'evenodd', fill: '#E85023' },
  agfaSt2: { fillRule: 'evenodd', clipRule: 'evenodd', fill: '#FFFFFF' },
}));
