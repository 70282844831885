import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  setPasswordContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '80px 0 0',

    '& p': {
      margin: '0',
      fontSize: '14px',
      textAlign: 'center',
      color: '#666666',
    },
  },
  iconContainer: {
    display: 'flex',
    width: '100%',
    height: '8.5rem',
    alignItems: 'center',
    justifyContent: 'center',
  },
  formHeader: {
    ...theme.typography.h5,
    margin: '40px 0 24px',
    textAlign: 'center',
    color: '#212121',
    fontWeight: 700,
    fontSize: 30,
  },
  submitButton: {
    marginTop: '40px',
  },
  textButton: {
    margin: '0.5rem auto',
  },
}));
