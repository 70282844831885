import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import XHR from 'i18next-xhr-backend';
import apiAddresses from 'api/apiAddresses';

const { API_URL: baseURL } = apiAddresses;

i18n
  .use(XHR)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    debug: false,
    // resources,
    nsSeparator: '__',
    lng: localStorage.getItem('language') || 'en',
    fallbackLng: 'en', // use en if detected lng is not available

    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    load: 'currentOnly',
    // saveMissing: true,
    // saveMissingTo: 'current',

    backend: {
      loadPath: `${baseURL}/shared/translation/{{lng}}/{{ns}}.json`,
    },
  });

export default i18n;
