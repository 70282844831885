import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
  entryContainer: {
    display: 'flex',
    width: '100%',
    height: '100vh',
    backgroundColor: '#f5f5f5',
  },
  formsContainer: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
  },
  headerContainer: {
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: '2rem',
    justifyContent: 'center',
    color: 'white',
    whiteSpace: 'pre-line',
    fontWeight: 700,
    lineHeight: 'normal',

    '& h1': {
      margin: 0,
      fontSize: '3.5rem',
    },

    '& h2': {
      margin: 0,
      fontSize: '1.45rem',
    },
  },
}));
