import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
  SearchBar: {},
  searchInput: {
    '& label': {
      display: 'flex',
      flexDirection: 'column',
      margin: 0,
      fontWeight: 700,
    },
  },
  eaSearchInput: {},
  xsSearch: {
    width: 140,
    margin: '0 32px 0 0',
  },
  xlSearch: {
    width: 280,
  },
  ml24: {
    marginLeft: 24,
  },
}));
