import React, { FC, ReactNode, useRef } from 'react';
import { useStyles } from './styles';
import Drawer from '@material-ui/core/Drawer';
import DrawerMenu from 'components/DrawerMenu';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from 'store';
import { uIToggleDrawer } from 'store/ui/actions';
import { useTranslation } from 'react-i18next';

interface Props {
  children?: ReactNode;
  innerHeader?: string;
  style?: any;
  pageStyle?: any;
}

const PageWithDrawer: FC<Props> = ({ children, innerHeader, style, pageStyle }: Props) => {
  // Styling
  const styles = useStyles({ hasInnerHeader: !!innerHeader });
  const { t } = useTranslation();

  // Redux
  const isDrawerOpened = useSelector((state: AppState) => state.ui.isDrawerOpened);

  const dispatch = useDispatch();

  const drawerContainer = useRef(null);

  const handleClose = (): void => {
    dispatch(uIToggleDrawer(false));
  };
  return (
    <div ref={drawerContainer} className={styles.PageWithDrawer} style={pageStyle || {}}>
      <Drawer
        open={isDrawerOpened}
        onClose={handleClose}
        variant="temporary"
        anchor="left"
        PaperProps={{ style: { position: 'absolute' } }}
        BackdropProps={{ style: { position: 'absolute' } }}
        ModalProps={
          drawerContainer.current ? { container: drawerContainer.current, style: { position: 'absolute' } } : {}
        }
      >
        <DrawerMenu />
      </Drawer>
      {innerHeader && (
        <div className={styles.innerHeader} style={style ? style : {}}>
          {t(innerHeader)}
        </div>
      )}
      {children}
    </div>
  );
};

export default PageWithDrawer;
