import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core';
import arrow from 'assets/images/arrow.svg';
import arrowRight from 'assets/images/arrow-right.svg';
import calendar from 'assets/images/calendar.svg';

export const useStyles = makeStyles((theme: Theme) => {
  const { palette, shape } = theme;

  return {
    DatePicker: {
      backgroundColor: palette.background.paper,
    },
    container: {},
    monthPickerWrapper: {
      '& input': {
        border: 'none',
        outline: 'none',
        width: 108,
        fontWeight: 500,
        fontSize: 13,
        padding: 0,
        lineHeight: '140%',
        color: '#666666',
        pointerEvents: 'none',
      },
      '& .react-datepicker__input-container': {
        position: 'relative',
        '&::after': {
          content: '""',
          position: 'absolute',
          top: '50%',
          transform: 'translateY(-50%)',
          right: 0,
          width: 18,
          height: 19,
          backgroundImage: `url(${calendar})`,
          backgroundRepeat: 'no-repeat',
        },
      },
      '& .inputWrapper': {
        cursor: 'pointer',
        display: 'flex',
        width: 140,
        border: '2px solid #E9E9EE',
        borderRadius: 10,
        height: 43,
        overflow: 'hidden',
        alignItems: 'center',
        padding: '0 16px',
      },
      '& .react-datepicker__header': {
        fontWeight: 700,
        fontSize: 16,
        color: '#030614',
      },
    },
    errorWrapper: {
      '& .inputWrapper': {
        borderColor: theme.palette.error.main,
      },
    },
    monthCalendar: {
      marginTop: 5,
      width: 272,
      '& .react-datepicker__month-container': {
        width: '100%',
        height: 244,
      },
      '& .react-datepicker__month': {
        margin: '10px 0 !important',
      },
      '& .react-datepicker__month .react-datepicker__month-text, .react-datepicker__month .react-datepicker__quarter-text':
        {
          width: 70,
          height: 35,
          lineHeight: '35px',
          fontWeight: 500,
          fontSize: 13,
          color: '#212121',
          borderRadius: 8,
        },
      '& .react-datepicker__month--selected': {
        background: '#0F65E6 !important',
        color: `${theme.palette.background.paper} !important`,
      },
      '& .react-datepicker__month-text--keyboard-selected': {
        background: theme.palette.background.paper,
      },
      '& .react-datepicker__month--disabled': {
        opacity: 0.25,
        cursor: 'not-allowed',
      },
    },
    label: {
      fontSize: '0.8125rem',
      fontWeight: 700,
      color: palette.grey['700'],
      marginBottom: '0.8125rem',
    },
    labelTitle: {
      fontWeight: 400,
      lineHeight: '16px',
      fontSize: '13px',
      color: palette.grey['700'],
      padding: '0 0 6px 17px',
      margin: 0,
    },
    inputWrapper: {
      height: '43px',
      position: 'relative',
      display: 'flex',
      flexDirection: 'row',
      padding: '0 13px 0 0',
      alignItems: 'center',
      backgroundColor: palette.background.paper,
      border: '2px solid #E9E9EE',
      width: 140,
      borderRadius: 10,
      '& input:read-only': { background: '#E9E9EE' },
    },
    input: {
      width: '100%',
      fontSize: '14px',
      padding: '0 16px',
      color: '#666666',
      border: 'none',
      outline: 'none',
      fontWeight: 500,
    },
    adornmentButton: {
      padding: '0',
      background: 'none',
      border: 'none',
      display: 'flex',
      width: '2.6rem',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: palette.background.paper,
      cursor: 'pointer',
    },
    error: {
      top: '-0.6rem',
      left: '0.25rem',
      borderRadius: shape.borderRadius,
      backgroundColor: palette.background.paper,
      padding: '0 0.25rem',
      border: '1px solid lightgrey',
      fontSize: '13px',
      lineHeight: '16px',
      color: palette.error.main,
      position: 'absolute',
      fontWeight: 400,
    },
    errorBlur: {
      fontSize: '0.75rem',
      color: palette.error.main,
      position: 'absolute',
      top: '0',
      left: '-5.4rem',
      width: '14rem',
      borderRadius: shape.borderRadius,
      backgroundColor: palette.background.paper,
      padding: '0 0.25rem',
      border: '1px solid lightgrey',
    },
    fullWidth: {
      width: '100%',
    },
    focused: {
      borderColor: '#0F65E5',
    },
    focusedLabel: {
      color: palette.primary.main,
    },

    datePicker: {
      width: '140px',
    },
    rDatePicker: {
      width: '16rem',
    },
    rDatePickerInput: { width: '100%' },
    rDatePickerPopper: {
      margin: '0 !important',
      zIndex: 10,
    },

    rDatePickerCalendar: {
      border: '1px solid #E9E9EE',
      boxShadow: '5px 5px 50px rgba(0, 0, 0, 0.05)',
      borderRadius: 16,
      overflow: 'hidden',

      '& .react-datepicker__header': {
        padding: '20px 23px 12px',
        backgroundColor: theme.palette.background.paper,
        border: 'none',
      },
      '& .react-datepicker__month': {
        margin: '0 0 20px',
      },
      '& .react-datepicker__day-names': {
        backgroundColor: theme.palette.background.paper,
        fontWeight: 500,
        fontSize: '12px',
        lineHeight: '25px',
        color: '#BFC0CD',
      },
      '& .react-datepicker__day--disabled': {
        opacity: 0.4,
        cursor: 'not-allowed',
      },
      '& .react-datepicker__day-name': {
        backgroundColor: theme.palette.background.paper,
        fontWeight: 500,
        fontSize: '12px',
        lineHeight: '15px',
        color: '#BFC0CD',
      },
      '& .react-datepicker__navigation': {
        background: 'none',
        width: 14,

        '&--next': {
          borderLeftColor: 'transparent',
          top: 23,
          height: 15,
          right: '23px',
          '&:before': {
            content: '""',
            display: 'block',
            height: 15,
            width: 8,
            backgroundImage: `url("${arrowRight}")`,
            backgroundRepeat: 'no-repeat',
          },

          '&:hover': {
            borderLeftColor: 'transparent',
          },
        },
        '&--previous': {
          borderRightColor: 'transparent',
          top: 23,
          height: 15,
          left: '23px',
          '&:before': {
            content: '""',
            display: 'block',
            height: 15,
            width: 8,
            backgroundImage: `url("${arrow}")`,
            backgroundRepeat: 'no-repeat',
          },
          '&:hover': {
            borderRightColor: 'transparent',
          },
        },
        '& > *': {
          display: 'none',
        },
      },

      '& .react-datepicker__current-month': {
        fontSize: 16,
        lineHeight: '20px',
        color: '#030614',
        marginBottom: 12,
        fontWeight: 700,
      },
      '& .react-datepicker__day': {
        backgroundColor: theme.palette.background.paper,
        borderRadius: '50%',
        fontSize: 14,
        color: '#212121',
        fontWeight: 500,

        '&--selected': {
          backgroundColor: '#0F65E5',
          color: theme.palette.background.paper,
        },
        '&--keyboard-selected': {
          backgroundColor: '#0F65E5',
          color: theme.palette.background.paper,
        },
      },
      '& .react-datepicker__day--outside-month': {
        color: '#9698A0',
        opacity: 1,
      },
    },
    monthlyError: {
      borderRadius: shape.borderRadius,
      backgroundColor: palette.background.paper,
      padding: '4px 0',
      fontSize: '13px',
      lineHeight: '16px',
      color: palette.error.main,
      position: 'absolute',
      fontWeight: 400,
    },
  };
});
