import React, { FC, useEffect, useMemo } from 'react';
import { Value as PaginationValue } from 'components/Paginations';
import { mergeTwoObjects } from 'utils';
import BasicTable, { BasicTableProps, FilterOptions, RequestFilters, SelectedFind, SortValue } from './BasicTable';
import TableWrapper, { TableWrapperProps } from './TableWrapper';
import { useStyles } from './styles';
import { useTranslation } from 'react-i18next';

export type TableWithPaginationChanges = RequestFilters & { pagination?: PaginationValue; accountType?: string };

export type TableWithLimitSelectPaginationProps = Pick<
  BasicTableProps,
  'cellRenderer' | 'data' | 'columns' | 'tableLayout' | 'stickyHeader' | 'stickyHeaderOffsets' | 'hasNoFilters'
> & {
  tableWrapperProps?: TableWrapperProps;
  innerTableWrapperProps?: {
    className?: string;
    style?: React.CSSProperties;
  };
  filtersData?: {
    find?: FilterOptions;
    count: number;
  };
  filters?: {
    order?: SortValue;
    find?: SelectedFind;
    pagination?: PaginationValue;
    accountType?: string;
  };
  defaultFilters?: {
    order?: SortValue;
    find?: SelectedFind;
    pagination?: PaginationValue;
    accountType?: string;
  };
  shouldApplyChangesOnMount?: boolean;
  applyChanges: (value: TableWithPaginationChanges) => void;
  isReverseSort?: boolean;
  pagination?: PaginationValue;
};

const TableWithLimitSelectPagination: FC<TableWithLimitSelectPaginationProps> = ({
  data,
  columns,
  cellRenderer,
  tableWrapperProps,
  filtersData,
  filters,
  defaultFilters,
  shouldApplyChangesOnMount,
  applyChanges,
  tableLayout,
  stickyHeader = true,
  stickyHeaderOffsets,
  hasNoFilters,
  innerTableWrapperProps,
  isReverseSort,
  pagination,
}) => {
  const { t } = useTranslation();

  const styles = useStyles({});

  const tableProps = mergeTwoObjects(
    {
      data,
      columns: columns.map((item) => ({ ...item, label: t(item.label) })),
      cellRenderer,
      filtersData,
      filters,
      defaultFilters,
      applyChanges: (value: RequestFilters) => applyChanges(value),
      tableLayout,
      stickyHeader,
      stickyHeaderOffsets,
      hasNoFilters,
      isReverseSort,
    },
    {},
  ) as BasicTableProps;

  useEffect(() => {
    const shouldApply = typeof shouldApplyChangesOnMount === 'boolean' ? shouldApplyChangesOnMount : true;

    if (shouldApply) {
      applyChanges(mergeTwoObjects(filters || {}, { pagination, ...(defaultFilters || {}) }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const definedTableWrapperProps = useMemo(() => tableWrapperProps || {}, [tableWrapperProps]);

  const { className, style } = innerTableWrapperProps || {};

  return (
    <TableWrapper {...definedTableWrapperProps}>
      <div className={`${styles.innerTableWrapper} ${className || ''}`} style={style || {}}>
        <BasicTable {...tableProps} shouldApplyChangesOnMount={false} />
      </div>
    </TableWrapper>
  );
};

export default TableWithLimitSelectPagination;
