export interface HArecord {
  customerName: string;
  severity: string;
  module: string;
  serverName: string;
  resolveTime: string;
  createdAt: string;
  companyName: string;
  systemName: string;
  id: string;
}

export interface HardwareAlertState {
  columnsFilters: ColumnsFilters;
  sortBy: SortBy;
  modulesAndSeverities: { modules: string[]; severities: string[] };
}

export interface SortBy {
  columnName: string;
  order: string;
}

export type FilterType = 'search' | 'multiSelect';

export interface ColumnsFilters {
  [columnName: string]: Filter;
}

export interface Pagination {
  page: number;
  count: number;
  startRecord: number;
  endRecord: number;
}

export interface Filter {
  filterType: FilterType;
  selectedOptions?: string[];
  selectedOptionsPrev?: string[];
  selectedOptionsShown?: string[];
  rangeStart?: string | Date;
  rangeEnd?: string | Date;
  searchString?: string;
  searchStringPrev?: string;
}

export interface CreditInfo {
  creditMonth: string;
  credit: number;
  creditUsed: number;
  creditForLastMonth: number;
  creditUsedLastMonth: number;
  paymentType: string;
  balance: number;
}

export interface TableViewDates {
  startDate: Date;
  endDate: Date;
}

export interface HARequestParameters {
  filters: {
    count?: string;
    page?: string;
    stepName: string;
    columnsFilters: Record<string, any>;
    dates: {
      start: string;
      end: string;
    };
    sortBy: SortBy;
  };
}
export enum HardwareAlertActionTypes {
  HA_RESET_STATE = 'HA_RESET_STATE',
  HA_SET_TABLE_DATA = 'HA_SET_TABLE_DATA',
  HA_SET_SEARCH = 'HA_SET_SEARCH,',
  HA_SET_FILTER_MULTI_SELECT = 'HA_SET_FILTER_MULTI_SELECT',
  HA_SET_FILTER_MULTI_SELECT_PREV = 'HA_SET_FILTER_MULTI_SELECT_PREV',
  HA_SET_FILTER_MULTI_SELECT_SHOWN = 'HA_SET_FILTER_MULTI_SELECT_SHOWN',
  HA_SET_FILTER_STATE = 'HA_SET_FILTER_STATE',
  HA_SET_FILTER_SEARCH = 'HA_SET_FILTER_SEARCH',
  HA_SET_FILTER_SEARCH_PREV = 'HA_SET_FILTER_SEARCH_PREV',
  HA_SET_SORT_BY = 'HA_SET_SORT_BY',
  HA_RESET_FILTERS = 'HA_RESET_FILTERS',
  HA_SET_TABLE_COLUMNS = 'HA_SET_TABLE_COLUMNS',
}

export interface HAResetState {
  type: HardwareAlertActionTypes.HA_RESET_STATE;
}

export interface HASetTableViewData {
  type: HardwareAlertActionTypes.HA_SET_TABLE_DATA;
  payload: HArecord[];
}

export interface HASetSearch {
  type: HardwareAlertActionTypes.HA_SET_SEARCH;
  payload: {
    columnName: string;
    value: string;
  };
}

export interface HASetFilterMultiSelect {
  type: HardwareAlertActionTypes.HA_SET_FILTER_MULTI_SELECT;
  payload: {
    columnName: string;
    selectedOptions: string[];
  };
}

export interface HASetFilterMultiSelectPrev {
  type: HardwareAlertActionTypes.HA_SET_FILTER_MULTI_SELECT_PREV;
  payload: {
    columnName: string;
    selectedOptionsPrev: string[];
  };
}

export interface HASetFilterMultiSelectShown {
  type: HardwareAlertActionTypes.HA_SET_FILTER_MULTI_SELECT_SHOWN;
  payload: {
    columnName: string;
    selectedOptionsShown: string[];
  };
}

export interface HASetFilterState {
  type: HardwareAlertActionTypes.HA_SET_FILTER_STATE;
  payload: { columnName: string; selectedOptions: string[] };
}

export interface HASetFilterSearch {
  type: HardwareAlertActionTypes.HA_SET_FILTER_SEARCH;
  payload: { columnName: string; searchString: string | undefined };
}

export interface HASetFilterSearchPrev {
  type: HardwareAlertActionTypes.HA_SET_FILTER_SEARCH_PREV;
  payload: { columnName: string; searchStringPrev: string | undefined };
}

export interface HASetSortBy {
  type: HardwareAlertActionTypes.HA_SET_SORT_BY;
  payload: SortBy;
}

export interface HAResetFilters {
  type: HardwareAlertActionTypes.HA_RESET_FILTERS;
}

export interface HASetTableColumns {
  type: HardwareAlertActionTypes.HA_SET_TABLE_COLUMNS;
  payload: any;
}

export type HardwareAlertActions =
  | HAResetState
  | HAResetFilters
  | HASetTableViewData
  | HASetSearch
  | HASetFilterMultiSelect
  | HASetFilterMultiSelectPrev
  | HASetFilterMultiSelectShown
  | HASetFilterState
  | HASetFilterSearch
  | HASetFilterSearchPrev
  | HASetSortBy
  | HASetTableColumns;
