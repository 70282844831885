import { Reducer } from 'redux';
import { produce } from 'immer';
import { ServersMonitoringActionTypes, ServersMonitoringActions, ServersMonitoringState } from './types';

const initialState: ServersMonitoringState = {
  servers: [],
  find: [
    {
      columnName: 'name',
      value: '',
    },
  ],
  columnsFilters: {},
  sortBy: { columnName: 'name', order: 'desc' },
};

const serversMonitoringReducer: Reducer<ServersMonitoringState, ServersMonitoringActions> = (
  state = initialState,
  action,
): ServersMonitoringState => {
  return produce(state, (draft: ServersMonitoringState) => {
    switch (action.type) {
      case ServersMonitoringActionTypes.SERVERS_MONITORING_SET:
        draft.servers = action.payload;
        return;

      case ServersMonitoringActionTypes.SERVERS_MONITORING_SET_FIELD:
        draft.servers = draft.servers.map((server) => {
          if (action.payload.id === server.agentMonitorId || action.payload.id === server.id) {
            return { ...server, ...action.payload.data };
          } else {
            return server;
          }
        });
        return;

      case ServersMonitoringActionTypes.SERVERS_MONITORING_SET_FILTER_MULTI_SELECT:
        draft.columnsFilters = {
          ...state.columnsFilters,
          [action.payload.columnName]: {
            ...state.columnsFilters[action.payload.columnName],
            filterType: 'multiSelect',
            selectedOptions: action.payload.selectedOptions,
          },
        };
        return;

      case ServersMonitoringActionTypes.SERVERS_MONITORING_SET_FILTER_MULTI_SELECT_PREV:
        draft.columnsFilters[action.payload.columnName] = {
          ...state.columnsFilters[action.payload.columnName],
          selectedOptionsPrev: action.payload.selectedOptionsPrev,
        };
        return;

      case ServersMonitoringActionTypes.SERVERS_MONITORING_SET_FILTER_MULTI_SELECT_SHOWN:
        draft.columnsFilters[action.payload.columnName] = {
          ...state.columnsFilters[action.payload.columnName],
          selectedOptionsShown: action.payload.selectedOptionsShown,
        };
        return;

      case ServersMonitoringActionTypes.SERVERS_MONITORING_SET_FILTER_SEARCH:
        if (!!action.payload.searchString) {
          draft.columnsFilters = {
            ...state.columnsFilters,
            [action.payload.columnName]: { filterType: 'search', searchString: action.payload.searchString },
          };
        } else {
          delete draft.columnsFilters[action.payload.columnName];
        }
        return;

      case ServersMonitoringActionTypes.SERVERS_MONITORING_SET_FILTER_SEARCH_PREV:
        draft.columnsFilters[action.payload.columnName] = {
          ...state.columnsFilters[action.payload.columnName],
          searchStringPrev: action.payload.searchStringPrev,
        };
        return;

      case ServersMonitoringActionTypes.SERVERS_MONITORING_TOGGLE_SORT_BY:
        if (state.sortBy.columnName === action.payload) {
          if (state.sortBy.order === 'asc') {
            draft.sortBy.order = 'desc';
          } else {
            draft.sortBy.order = 'asc';
          }
        } else {
          draft.sortBy.columnName = action.payload;
          draft.sortBy.order = 'asc';
        }
        return;

      case ServersMonitoringActionTypes.SERVERS_MONITORING_SET_SORT_BY:
        draft.sortBy = action.payload;
        return;
      case ServersMonitoringActionTypes.SERVERS_MONITORING_GET_TABLE_DATA:
        return;
      default:
        return state;
    }
  });
};

export default serversMonitoringReducer;
