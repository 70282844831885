export enum PermissionsEnum {
  write = 'write',
  read = 'read',
}

export enum AccountTypesEnum {
  managed = 'managed',
  selfServed = 'self',
}

export enum UserRolesEnum {
  admin = 'admin',
  globalUser = 'globalUser',
  monitoringUser = 'monitoringUser',
  organization = 'enterpriseAdmin',
  organizationUser = 'organizationUser',
  account = 'customer',
  accountUser = 'viewUser',
}
