import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(({ palette }) => ({
  closeIcon: {
    fontSize: 24,
  },
  closeIconButton: {
    color: palette.text.secondary,
    position: 'absolute',
    top: 4,
    right: 4,
  },
}));
