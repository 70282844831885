import React, { ChangeEvent, FocusEvent, ForwardRefRenderFunction, ReactNode, forwardRef, useCallback } from 'react';
import { useStyles } from './styles';

// onChange: ChangeHandler;
// onBlur: ChangeHandler;
// ref: RefCallBack;
// name: InternalFieldName;
// min?: string | number;
// max?: string | number;
// maxLength?: number;
// minLength?: number;
// pattern?: string;
// required?: boolean;
// disabled?: boolean;

interface Props {
  labelKey?: string | ReactNode;
  disabled?: boolean;
  className?: string;
  name: string;
  checked?: any;
  switchState?: (event: ChangeEvent<HTMLInputElement>) => unknown;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => unknown;
  onBlur?: (event: FocusEvent) => unknown;
}

const Checkbox: ForwardRefRenderFunction<HTMLInputElement, Props> = (
  { labelKey, name, disabled, className, checked, switchState, onChange, onBlur },
  ref,
) => {
  // Translation
  // const { t } = useTranslation();
  const styles = useStyles();
  const checkboxStyles = [styles.label, className ? className : ''].join(' ');

  const changeHandler = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      onChange?.(event);
      switchState?.(event);
    },
    [onChange, switchState],
  );

  return (
    <label className={checkboxStyles}>
      <input
        name={name}
        onBlur={onBlur}
        onChange={changeHandler}
        ref={ref}
        disabled={disabled}
        className={styles.input}
        type="checkbox"
        checked={checked}
      />
      {!!labelKey && <p className={styles.labelKey}>{labelKey}</p>}
    </label>
  );
};

export default forwardRef(Checkbox);
