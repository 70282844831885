import { Server } from 'http';
import { AccountTypesEnum } from 'utils/types';

export interface CustomersState {
  customers: Customer[];
  pagination: Pagination;
  customersCount: number;
  search: string;
  accountType: string;
}

export interface Pagination {
  page: number;
  count: number;
  startRecord: number;
  endRecord: number;
}

export interface Customer {
  id: string;
  email: string;
  password: string;
  companyId: string;
  name: string;
  companyName: string;
  country: string;
  language: string;
  timeZone: string;
  phoneNumber: string;
  role: 'customer' | 'Admin';
  needPasswordChange: boolean;
  createdAt: string;
  updatedAt: string;
  servers?: Server[];
  autoLoginToken?: string;
  accountType: AccountTypesEnum;
  url?: string;
}
export enum CustomersActionTypes {
  CUSTOMERS_SET_CUSTOMERS = 'CUSTOMERS_SET_CUSTOMERS',
  CUSTOMERS_SET_CUSTOMERS_COUNT = 'CUSTOMERS_SET_CUSTOMERS_COUNT',
  CUSTOMERS_SET_COUNT = 'CUSTOMERS_SET_COUNT',
  CUSTOMERS_SET_PAGE = 'CUSTOMERS_SET_PAGE',
  CUSTOMERS_SET_SHOWN = 'CUSTOMERS_SET_SHOWN,',
  CUSTOMERS_SET_SEARCH = 'CUSTOMERS_SET_SEARCH,',
  SET_ACCOUNT_TYPE = 'SET_ACCOUNT_TYPE',
}

export interface CustomersSetCustomers {
  type: CustomersActionTypes.CUSTOMERS_SET_CUSTOMERS;
  payload: Customer[];
}

export interface SetAccountType {
  type: CustomersActionTypes.SET_ACCOUNT_TYPE;
  payload: string;
}

export interface CustomersSetCustomersCount {
  type: CustomersActionTypes.CUSTOMERS_SET_CUSTOMERS_COUNT;
  payload: number;
}

export interface CustomersSetCount {
  type: CustomersActionTypes.CUSTOMERS_SET_COUNT;
  payload: number;
}

export interface CustomersSetPage {
  type: CustomersActionTypes.CUSTOMERS_SET_PAGE;
  payload: number;
}

export interface CustomersSetShown {
  type: CustomersActionTypes.CUSTOMERS_SET_SHOWN;
  payload: { startRecord: number; endRecord: number };
}

export interface CustomersSetSearch {
  type: CustomersActionTypes.CUSTOMERS_SET_SEARCH;
  payload: string;
}

export type CustomersActions =
  | CustomersSetCustomers
  | CustomersSetCustomers
  | CustomersSetCustomersCount
  | CustomersSetCount
  | CustomersSetPage
  | CustomersSetShown
  | CustomersSetSearch
  | SetAccountType;
