import React, { ChangeEvent, FC, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Select } from 'components/UI';
import { useStyles } from '../styles';
import { showTypeOptions } from 'consts';

export type ShowTypeSelectProps = {
  value?: string;
  defaultValue?: number;
  label?: string;
  onChange: (value: string) => any;
};

const ShowTypeSelect: FC<ShowTypeSelectProps> = ({ value, defaultValue, onChange, label }) => {
  const styles = useStyles();
  const { t } = useTranslation();

  const limitSelectLabel = useMemo(() => t(label || 'Show'), [label, t]);

  const handleChange = useCallback((event: ChangeEvent<HTMLSelectElement>) => onChange(event.target.value), [onChange]);

  return (
    <Select
      labelKey={limitSelectLabel}
      className={styles.accountTypeSelect}
      options={showTypeOptions.map((item) => ({ ...item, label: t(item.label) }))}
      defaultValue={defaultValue || 'all'}
      onChange={handleChange}
      {...(value ? { value } : {})}
      bordered
    />
  );
};

export default ShowTypeSelect;
