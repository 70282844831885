import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  loader: {
    position: 'absolute',
    top: 0,
    left: 0,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100vw',
    height: '100vh',
    zIndex: theme.zIndex.tooltip,
    background: 'rgba(33, 33, 33, 0.5)',
  },
  icon: {
    width: 100,
    height: 45,
  },
  text: {
    fontWeight: 700,
    fontSize: '20px',
    lineHeight: '24px',
    color: '#212121',
    margin: '8px 0 0',
  },
  whiteCircle: {
    width: 200,
    height: 200,
    background: '#fff',
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
}));
