import { AnyAction, Dispatch, combineReducers } from 'redux';
import uIReducer from './ui/reducer';
import subuIReducer from './subui/reducer';
import sessionReducer from './session/reducer';
import adminDashboardReducer from './adminDashboard/reducer';
import customersReducer from './customers/reducer';
import viewUsersReducer from './accountUsers/reducer';
import globalUsersReducer from './globalUsers/reducer';
import organizationsReducer from './organizations/reducer';
import systemUsersReducer from './systemUsers/reducer';

import customerParametersReducer from './customerParameters/reducer';
import { adminParametersReducer } from './adminParameters/reducer';
import { customerTableViewReducer } from './customerTableView/reducer';
import { customerDashboardReducer } from './customerDashboard/reducer';
import { mapDashboardReducer } from './mapDashboard/reducer';
import purchaseHistoryReducer from './purchaseHistory/reducer';
import { serversAlertReducer } from './serversAlert/reducer';
import serversMonitoringReducer from './serversMonitoring/reducer';
import { hardwareAlertsReducer } from './hardwareAlert/reducer';

import { resourcesUsageReport } from './resourcesUsageReport/reducer';
import { billingHistory } from './billingHistory/reducer';
import serversDashboardReducer from './serversDashboard/reducer';

const rootReducer = combineReducers({
  ui: uIReducer,
  subui: subuIReducer,
  session: sessionReducer,
  adminDashboard: adminDashboardReducer,
  customers: customersReducer,
  viewUsers: viewUsersReducer,
  globalUsers: globalUsersReducer,
  organizations: organizationsReducer,
  systemUsers: systemUsersReducer,

  customerParameters: customerParametersReducer,
  adminParameters: adminParametersReducer,
  customerTableView: customerTableViewReducer,
  customerDashboard: customerDashboardReducer,
  mapDashboard: mapDashboardReducer,
  purchaseHistory: purchaseHistoryReducer,
  serversAlert: serversAlertReducer,
  serversMonitoring: serversMonitoringReducer,
  hardwareAlerts: hardwareAlertsReducer,

  resourcesUsageReport,
  serversDashboard: serversDashboardReducer,
  billingHistory: billingHistory,
});

export type AppState = ReturnType<typeof rootReducer>;
export type Dispatcher<T> = (dispatch: Dispatch<AnyAction>) => Promise<T>;

export default rootReducer;
