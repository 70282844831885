import countries from 'consts/country-by-abbreviation.json';
import timezones from 'consts/timezones.json';
import languages from 'consts/languages.json';

// import workflowStepTypes from 'consts/workflow-step-types.json';
import tableViewKeys from 'consts/tableViewKeys.json';
import { AccountTypesEnum } from 'utils/types';

const monthsConst = [
  { label: 'January', value: 1 },
  { label: 'February', value: 2 },
  { label: 'March', value: 3 },
  { label: 'April', value: 4 },
  { label: 'May', value: 5 },
  { label: 'June', value: 6 },
  { label: 'July', value: 7 },
  { label: 'August', value: 8 },
  { label: 'September', value: 9 },
  { label: 'October', value: 10 },
  { label: 'November', value: 11 },
  { label: 'December', value: 12 },
];

const accountTypeOptions = [
  {
    label: 'All',
    value: 'all',
  },
  {
    label: 'Self Served',
    value: AccountTypesEnum.selfServed,
  },
  {
    label: 'Managed',
    value: AccountTypesEnum.managed,
  },
];

const accountAndOrganizationOptions = [
  {
    label: 'All',
    value: 'all',
  },
  {
    label: 'Account',
    value: 'company',
  },
  {
    label: 'Organization',
    value: 'organization',
  },
];

const showTypeOptions = [
  {
    label: 'All',
    value: 'all',
  },
  {
    label: 'Organization',
    value: 'organization',
  },
  {
    label: 'Account',
    value: 'account',
  },
];

const countConst = [
  { label: '10', value: 10 },
  { label: '25', value: 25 },
  { label: '50', value: 50 },
  { label: '100', value: 100 },
];

const hours = [
  { value: '00', label: '00' },
  { value: '01', label: '01' },
  { value: '02', label: '02' },
  { value: '03', label: '03' },
  { value: '04', label: '04' },
  { value: '05', label: '05' },
  { value: '06', label: '06' },
  { value: '07', label: '07' },
  { value: '08', label: '08' },
  { value: '09', label: '09' },
  { value: '10', label: '10' },
  { value: '11', label: '11' },
  { value: '12', label: '12' },
  { value: '13', label: '13' },
  { value: '14', label: '14' },
  { value: '15', label: '15' },
  { value: '16', label: '16' },
  { value: '17', label: '17' },
  { value: '18', label: '18' },
  { value: '19', label: '19' },
  { value: '20', label: '20' },
  { value: '21', label: '21' },
  { value: '22', label: '22' },
  { value: '23', label: '23' },
];

const fiveMinutes = [
  { value: '00', label: '00' },
  { value: '05', label: '05' },
  { value: '10', label: '10' },
  { value: '15', label: '15' },
  { value: '20', label: '20' },
  { value: '25', label: '25' },
  { value: '30', label: '30' },
  { value: '35', label: '35' },
  { value: '40', label: '40' },
  { value: '45', label: '45' },
  { value: '50', label: '50' },
  { value: '55', label: '55' },
];
const writeAccess = [
  { value: 'false', label: 'Read only' },
  { value: 'true', label: 'Read/write' },
];
const filterTypes = [
  { value: 'timeRange', label: 'Time Range' },
  { value: 'multiSelect', label: 'Multi Select' },
  { value: 'search', label: 'Search' },
  { value: 'ink', label: 'Color' },
  { value: 'state', label: 'State' },
];
const formatTypes = [
  { value: 'String', label: 'String' },
  { value: 'Number', label: 'Number' },
  { value: 'Date', label: 'Date' },
  { value: 'Time', label: 'Time' },
  { value: 'DateTime', label: 'DateTime' },
  { value: 'Size', label: 'Size' },
];
const dateOptions = [
  { value: 'MDY', label: 'MM/DD/YYYY' },
  { value: 'DMY', label: 'DD/MM/YYYY' },
];

const paymentTypes = {
  contract: 'contract',
  prepaid: 'prepaid',
};

const roles: any = {
  enterpriseAdmin: 'Organization admin',
  organizationUser: 'Organization user',
  admin: 'Admin',
  monitoringUser: 'Monitoring User',
  customer: 'Account', // TODO Check changing Customer to account
  globalUser: 'Global User',
  viewUser: 'Account User',
};

const rolesOrganization: any = {
  enterpriseAdmin: 'Organization admin',
  organizationUser: 'Organization user',
  customer: 'Account', // TODO Check changing Customer to account
  viewUser: 'Account User',
};

const rolesOrganizationUser: any = {
  organizationUser: 'Organization user',
  customer: 'Account', // TODO Check changing Customer to account
  viewUser: 'Account User',
};

const rolesAccount: any = {
  customer: 'Account', // TODO Check changing Customer to account
  viewUser: 'Account User',
};

const rolesAccountUser: any = {
  customer: 'Account', // TODO Check changing Customer to account
  viewUser: 'Account User',
};

const serverPeriods = [
  { value: '1', label: '1 hour' },
  { value: '6', label: '6 hours' },
  { value: '12', label: '12 hours' },
  { value: '24', label: '24 hours' },
  { value: '48', label: '48 hours' },
  { value: '168', label: '1 week' },
];

export {
  dateOptions,
  countConst,
  monthsConst,
  writeAccess,
  languages,
  countries,
  hours,
  formatTypes,
  filterTypes,
  fiveMinutes,
  showTypeOptions,
  // workflowStepTypes,
  timezones,
  tableViewKeys,
  paymentTypes,
  roles,
  rolesOrganization,
  rolesOrganizationUser,
  rolesAccount,
  rolesAccountUser,
  serverPeriods,
  accountTypeOptions,
  accountAndOrganizationOptions,
};
