import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => {
  const { palette } = theme;

  return {
    container: {},
    label: {
      whiteSpace: 'nowrap',
    },
    selectTitle: {
      fontWeight: 400,
      lineHeight: '16px',
      fontSize: '13px',
      color: palette.grey['700'],
      padding: '0 0 6px 17px',
      margin: 0,
    },
    inputWrapper: {
      position: 'relative',
      display: 'flex',
      flexDirection: 'row',
      '&:before': {
        top: '27px',
        pointerEvents: 'none',
        cursor: 'pointer',
        right: '7px',
        width: 0,
        height: '8px',
        content: '""',
        display: 'block',
        zIndex: 1,
        position: 'absolute',
        transform: 'rotate(229deg)',
        marginTop: '-8px',
        borderRight: '2px solid #BFC0CD',
      },

      '&:after': {
        top: '20px',
        pointerEvents: 'none',
        cursor: 'pointer',
        right: '12px',
        width: 0,
        height: '8px',
        content: '""',
        display: 'block',
        zIndex: 1,
        position: 'absolute',
        transform: 'rotate(-50deg)',
        marginTop: '-1px',
        borderRight: '2px solid #BFC0CD',
      },
      '& select:disabled': {
        background: '#E9E9EE',
        cursor: 'not-allowed',
      },
    },
    borderedSelectWrapper: {
      '&:before': {
        right: '16px',
      },
      '&:after': {
        right: '21px',
      },
    },
    input: {
      width: '100%',
      padding: '11px 16px',
      height: 43,
      fontSize: '14px',
      fontWeight: 400,
      color: '#666666',
      border: 'none',
      outline: 'none',
      cursor: 'pointer',
      borderRadius: 10,
      backgroundColor: palette.background.paper,
    },
    error: {
      fontSize: '13px',
      lineHeight: '13px',
      fontWeight: 400,
      color: palette.error.main,
      position: 'absolute',
      top: '46px',
      left: '16px',
      backgroundColor: 'transparent',
      padding: 0,
    },
    fullWidth: {
      width: '100%',
    },
    focused: {},
    focusedLabel: {
      color: palette.primary.main,
    },
    bordered: {
      border: '2px solid #E9E9EE',
      paddingRight: 32,
    },
    hasError: {
      border: `2px solid ${palette.error.main}`,
    },
  };
});
