import React, { FC } from 'react';
import { TableCell } from '@material-ui/core';

import { useStyles } from './styles';

const FilterCellDefault: FC<{ cellStyle?: Record<string, any> }> = ({ cellStyle }) => {
  // Styling
  const styles = useStyles();

  return (
    <TableCell className={[styles.filterCell].join(' ')} style={cellStyle || {}}>
      <div className={styles.cellContent} />
    </TableCell>
  );
};

export default FilterCellDefault;
