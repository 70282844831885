import React, { FC, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Tooltip } from '@material-ui/core';
import { DatePicker } from 'components/UI';
import { getDateFormat } from 'utils/formating';
import { useStyles } from './styles';

export type DateRange = {
  start: Date;
  end: Date;
};

type Errors = {
  start?: string | boolean;
  end?: string | boolean;
};

export type DateRangePickerProps = {
  value?: DateRange;
  defaultValue?: DateRange;
  onChange: (range: DateRange) => any;
  isNotEndDateLimited?: boolean;
  classes?: any;
  dateFormat?: string;
};

export const DEFAULT_DATE_RANGE_PICKER_VALUE: DateRange = { start: new Date(), end: new Date() };

const DateRangePicker: FC<DateRangePickerProps> = ({
  value,
  defaultValue,
  onChange,
  classes,
  isNotEndDateLimited,
  dateFormat,
}) => {
  const styles = useStyles(classes ? { classes } : {});
  const { t } = useTranslation();

  const [errors, setErrorsState] = useState({ start: false, end: false } as Errors);
  const [innerValue, setInnerValue] = useState(null as unknown as DateRange);

  const range = useMemo(
    () => value || innerValue || defaultValue || DEFAULT_DATE_RANGE_PICKER_VALUE,
    [defaultValue, innerValue, value],
  );

  const text = useMemo(
    () => ({
      tooltipTitle: t('The time for this filter is selected by UTC0') || '',
      startDateErrorText: t('Start date should be earlier than End day'),
      endDateErrorText: t('End date must be later than Start Day'),
    }),
    [t],
  );

  const pickerDateFormat = useMemo(() => getDateFormat(dateFormat || 'DMY'), [dateFormat]);

  const setErrors = (payload: Errors): void => setErrorsState((errors) => ({ ...errors, ...payload }));

  const handleStartDateChange = (date: Date): void => {
    if (date <= range.end) {
      setErrors({ start: false });
      const dateRangeUpdate = { start: date, end: range.end };
      setInnerValue(dateRangeUpdate);
      onChange(dateRangeUpdate);
    } else {
      setErrors({ start: text.startDateErrorText });
    }
    if (errors.end) {
      setErrors({ end: false });
    }
  };

  const handleEndDateChange = (date: Date): void => {
    const isLargerThanStart = date >= range.start;
    const isOkForLimited =
      !isNotEndDateLimited && date <= new Date(new Date().setHours(23, 59, 59, 0)) && isLargerThanStart;
    const isOkForUnlimited = isNotEndDateLimited && isLargerThanStart;
    if (isOkForLimited || isOkForUnlimited) {
      setErrors({ end: false });
      const dateRangeUpdate = { start: range.start, end: date };
      setInnerValue(dateRangeUpdate);
      onChange(dateRangeUpdate);
    } else {
      setErrors({ end: text.endDateErrorText });
    }

    if (errors.start) {
      setErrors({ start: false });
    }
  };

  return (
    <>
      <Tooltip title={text.tooltipTitle} placement="top">
        <div className={styles.root}>
          <DatePicker
            name="startDate"
            labelKey={t('Start date')}
            placeholder={pickerDateFormat}
            formatDate={pickerDateFormat}
            selected={range.start}
            onChange={handleStartDateChange}
            maxDate={range.end}
            className={styles.datePicker}
            error={errors.start}
            blur={(): void => setErrors({ start: false })}
            positionBlur={true}
          />
          <DatePicker
            name="endDate"
            labelKey={t('End date')}
            placeholder={pickerDateFormat}
            formatDate={pickerDateFormat}
            selected={range.end}
            onChange={handleEndDateChange}
            className={styles.datePicker}
            error={errors.end}
            blur={(): void => setErrors({ end: false })}
            positionBlur={true}
            {...(!isNotEndDateLimited ? { maxDate: new Date().setHours(23, 59, 59, 0) } : {})}
          />
        </div>
      </Tooltip>
    </>
  );
};

export default DateRangePicker;
