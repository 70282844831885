import React, { FC, ReactNode } from 'react';
import Dialog from '@material-ui/core/Dialog';
import { useStyles } from './styles';
import { useTranslation } from 'react-i18next';
import { Icon } from 'components/UI/Icon';
import cn from 'classnames';

interface Props {
  open: boolean;
  onClose: (confirm: boolean) => any;
  children?: ReactNode;
  captionKey?: string;
  messageKey?: string;
  haveErrors?: boolean;
}

const PaymentModal: FC<Props> = ({
  onClose,
  open,
  children,
  captionKey = 'Add Card',
  messageKey = 'Costs will be charged on next subscription period',
}: Props) => {
  // Styling
  const styles = useStyles();
  // Translation
  const { t } = useTranslation();

  return (
    <Dialog open={open} onBackdropClick={(): void => onClose(false)}>
      <div className={cn(styles.DisableProtectionModal, styles['jc-fs'], styles['DisableProtectionModalLarge'])}>
        <div className={styles.modalCLose} onClick={(): void => onClose(false)}>
          <Icon name={'close'} />
        </div>
        <div className={styles.modalHeader}>{t(captionKey)}</div>
        <div className={cn(styles.modalMessage)}>{t(messageKey)}</div>
        {children}
      </div>
    </Dialog>
  );
};

export default PaymentModal;
