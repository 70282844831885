import {
  AvailableSteps,
  ColumnsFilters,
  CustomerTableViewActionTypes,
  CustomerTableViewActions,
  SortBy,
  TableColumn,
  TableViewFormattedRecord,
  TableViewRecord,
  TableViewRequestParameters,
} from './types';
import { Dispatch } from 'redux';
import { uILoadingFinish, uILoadingStart } from 'store/ui/actions';
import { NotificationTypes, showErrorNotification, showNotification } from 'utils/notifications';
import {
  apiAdminGetUserStepsFilters,
  apiAdminGetUserStepsNames,
  apiAdminGetUserTableData,
  apiGetGlobalUserTableData,
  apiGetUserStepsFilters,
  apiGetUserStepsNames,
  apiGetUserTableData,
  apiGetViewUserStepsNames,
  apiGetViewUserTableData,
  apiGetXlsData,
  apiTableViewHistoryData,
  apiXLS,
} from 'api';
import { AppState } from 'store';
import format from 'date-fns/format';
import { getAdminCustomersList } from 'store/customers/actions';
import { UserRolesEnum } from 'utils/types';
import { endOfDay, subYears } from 'date-fns';

export const tvResetState = (): CustomerTableViewActions => ({ type: CustomerTableViewActionTypes.TV_RESET_STATE });

export const tVSetCustomerId = (id: string | null): CustomerTableViewActions => {
  return {
    type: CustomerTableViewActionTypes.TV_SET_CUSTOMER_ID,
    payload: id,
  };
};

export const tVSetCompanyId = (companyId: string): CustomerTableViewActions => {
  return {
    type: CustomerTableViewActionTypes.TV_SET_COMPANY_ID,
    payload: companyId,
  };
};

export const tVSetCustomerName = (name: string | null): CustomerTableViewActions => {
  return {
    type: CustomerTableViewActionTypes.TV_SET_CUSTOMER_NAME,
    payload: name,
  };
};

export const tVSetCompanyName = (name: string): CustomerTableViewActions => {
  return {
    type: CustomerTableViewActionTypes.TV_SET_COMPANY_NAME,
    payload: name,
  };
};

export const tVSetTableViewData = (tableViewData: TableViewRecord[]): CustomerTableViewActions => {
  return {
    type: CustomerTableViewActionTypes.TV_SET_TABLE_VIEW_DATA,
    payload: tableViewData,
  };
};

export const tVHSetTableHistoryViewData = (tableViewData: TableViewRecord[]): CustomerTableViewActions => {
  return {
    type: CustomerTableViewActionTypes.TV_SET_TABLE_HISTORY_VIEW_DATA,
    payload: tableViewData,
  };
};

export const tVSetRecordsCount = (customersCount: number): CustomerTableViewActions => {
  return {
    type: CustomerTableViewActionTypes.TV_SET_RECORDS_COUNT,
    payload: customersCount,
  };
};

export const tVHSetRecordsCount = (customersCount: number): CustomerTableViewActions => {
  return {
    type: CustomerTableViewActionTypes.TVH_SET_RECORDS_COUNT,
    payload: customersCount,
  };
};

export const tVSetCount = (count: number): CustomerTableViewActions => {
  return {
    type: CustomerTableViewActionTypes.TV_SET_COUNT,
    payload: count,
  };
};

export const tVHSetCount = (count: number): CustomerTableViewActions => {
  return {
    type: CustomerTableViewActionTypes.TVH_SET_COUNT,
    payload: count,
  };
};

export const tVSetPage = (page: number): CustomerTableViewActions => {
  return {
    type: CustomerTableViewActionTypes.TV_SET_PAGE,
    payload: page,
  };
};

export const tVHSetPage = (page: number): CustomerTableViewActions => {
  return {
    type: CustomerTableViewActionTypes.TVH_SET_PAGE,
    payload: page,
  };
};

export const tVSetShown = (startRecord: number, endRecord: number): CustomerTableViewActions => {
  return {
    type: CustomerTableViewActionTypes.TV_SET_SHOWN,
    payload: { startRecord, endRecord },
  };
};

export const tVHSetShown = (startRecord: number, endRecord: number): CustomerTableViewActions => {
  return {
    type: CustomerTableViewActionTypes.TVH_SET_SHOWN,
    payload: { startRecord, endRecord },
  };
};

// export const tVSetSearch = (search: string): CustomerTableViewActions => {
//   return {
//     type: CustomerTableViewActionTypes.TV_SET_SEARCH,
//     payload: search,
//   };
// };

export const tVSetStartDate = (date: Date): CustomerTableViewActions => {
  return {
    type: CustomerTableViewActionTypes.TV_SET_START_DATE,
    payload: date,
  };
};

export const tVSetEndDate = (date: Date): CustomerTableViewActions => {
  return {
    type: CustomerTableViewActionTypes.TV_SET_END_DATE,
    payload: date,
  };
};

export const tVResetDates = (): CustomerTableViewActions => {
  return {
    type: CustomerTableViewActionTypes.TV_RESET_DATES,
  };
};

export const tVResetFilters = (): CustomerTableViewActions => {
  return {
    type: CustomerTableViewActionTypes.TV_RESET_FILTERS,
  };
};

export const tVSetCreditInfo = (
  creditMonthCount: number,
  startDate: Date,
  endDate: Date,
  credit: number,
  creditUsed: number,
  creditForLastMonth: number,
  creditUsedLastMonth: number,
  paymentType: string,
  balance: number,
): CustomerTableViewActions => {
  let creditMonth: string;
  if (creditMonthCount === 0) {
    creditMonth = '';
  } else if (creditMonthCount === 1) {
    creditMonth = `${format(new Date(startDate), 'MMMM yyyy')}`;
  } else {
    // creditMonth = `${format(new Date(startDate), 'MMM yyyy')} +${creditMonthCount - 1}`;
    creditMonth = `${format(new Date(startDate), 'MMMM yyyy')} -\n${format(new Date(endDate), 'MMMM yyyy')}`;
  }

  return {
    type: CustomerTableViewActionTypes.TV_SET_CREDIT_INFO,
    payload: { creditMonth, credit, creditUsed, creditForLastMonth, creditUsedLastMonth, paymentType, balance },
  };
};

export const tVSetAvailableSteps = (steps: AvailableSteps): CustomerTableViewActions => {
  return {
    type: CustomerTableViewActionTypes.TV_SET_AVAILABLE_STEPS,
    payload: steps,
  };
};

export const tVSetAvailableContractDates = (steps: { startDate: Date; endDate: Date }): CustomerTableViewActions => {
  return {
    type: CustomerTableViewActionTypes.TV_SET_AVAILABLE_CONTRACT_DATES,
    payload: steps,
  };
};

export const tVSetSelectedStep = (step: string): CustomerTableViewActions => {
  return {
    type: CustomerTableViewActionTypes.TV_SET_SELECTED_STEP,
    payload: step,
  };
};

export const tVSetTableColumns = (tableColumns: TableColumn[]): CustomerTableViewActions => {
  return {
    type: CustomerTableViewActionTypes.TV_SET_TABLE_COLUMNS,
    payload: tableColumns,
  };
};

export const tVHSetTableHistoryColumns = (tableColumns: TableColumn[]): CustomerTableViewActions => {
  return {
    type: CustomerTableViewActionTypes.TV_SET_TABLE_HISTORY_COLUMNS,
    payload: tableColumns,
  };
};

export const tVSetFilterInk = (columnName: string, selectedOptions: string[]): CustomerTableViewActions => {
  return {
    type: CustomerTableViewActionTypes.TV_SET_FILTER_INK,
    payload: { columnName, selectedOptions },
  };
};

export const tVSetFilterState = (columnName: string, selectedOptions: string[]): CustomerTableViewActions => {
  return {
    type: CustomerTableViewActionTypes.TV_SET_FILTER_STATE,
    payload: { columnName, selectedOptions },
  };
};

export const tVSetFilterMultiSelect = (columnName: string, selectedOptions: string[]): CustomerTableViewActions => {
  return {
    type: CustomerTableViewActionTypes.TV_SET_FILTER_MULTI_SELECT,
    payload: { columnName, selectedOptions },
  };
};

export const tVSetFilterMultiSelectPrev = (
  columnName: string,
  selectedOptionsPrev: string[],
): CustomerTableViewActions => {
  return {
    type: CustomerTableViewActionTypes.TV_SET_FILTER_MULTI_SELECT_PREV,
    payload: { columnName, selectedOptionsPrev },
  };
};

export const tVSetFilterMultiSelectShown = (
  columnName: string,
  selectedOptionsShown: string[],
): CustomerTableViewActions => {
  return {
    type: CustomerTableViewActionTypes.TV_SET_FILTER_MULTI_SELECT_SHOWN,
    payload: { columnName, selectedOptionsShown },
  };
};

export const tVSetFilterSearch = (columnName: string, searchString: string | undefined): CustomerTableViewActions => {
  return {
    type: CustomerTableViewActionTypes.TV_SET_FILTER_SEARCH,
    payload: { columnName, searchString },
  };
};
export const tVSetFilterSearchPrev = (
  columnName: string,
  searchStringPrev: string | undefined,
): CustomerTableViewActions => {
  return {
    type: CustomerTableViewActionTypes.TV_SET_FILTER_SEARCH_PREV,
    payload: { columnName, searchStringPrev },
  };
};

export const tVSetFilterDateRange = (
  columnName: string,
  rangeStart: Date | undefined,
  rangeEnd: Date | undefined,
): CustomerTableViewActions => {
  return {
    type: CustomerTableViewActionTypes.TV_SET_FILTER_DATE_RANGE,
    payload: { columnName, rangeStart, rangeEnd },
  };
};

export const tVToggleSortBy = (columnName: string): CustomerTableViewActions => {
  return {
    type: CustomerTableViewActionTypes.TV_TOGGLE_SORT_BY,
    payload: columnName,
  };
};

export const tVSetSortBy = (sortBy: SortBy): CustomerTableViewActions => {
  return {
    type: CustomerTableViewActionTypes.TV_SET_SORT_BY,
    payload: sortBy,
  };
};

export const tVGetTableData = (newId?: string): any => {
  return async (dispatch: any, getState: () => AppState): Promise<void> => {
    dispatch(uILoadingStart());
    const id = getState().customerTableView.customerId;

    const viewUser = getState().session?.user?.role === UserRolesEnum.accountUser;
    const isAdmin = getState().session?.user?.role === UserRolesEnum.admin;
    const isGlobalUser = getState().session?.user?.role === UserRolesEnum.globalUser;
    const isOrganization =
      getState().session?.user?.role === UserRolesEnum.organization ||
      getState().session?.user?.role === UserRolesEnum.organizationUser;

    const columnsFilters: ColumnsFilters = {};
    const columnsFiltersState = getState().customerTableView.columnsFilters;

    Object.keys(columnsFiltersState).forEach((columnName: string) => {
      if (columnsFiltersState[columnName].filterType === 'multiSelect') {
        if (columnsFiltersState[columnName].selectedOptions?.length) {
          columnsFilters[columnName] = {
            filterType: columnsFiltersState[columnName].filterType,
            selectedOptions: columnsFiltersState[columnName].selectedOptions,
          };
        }
      } else {
        columnsFilters[columnName] = columnsFiltersState[columnName];
      }
    });

    const { page, count } = getState().customerTableView.pagination;
    const stepName =
      getState().customerTableView.selectedStep || Object.keys(getState().customerTableView.availableSteps)[0];

    const startDate = getState().customerTableView.tableViewDates.startDate || new Date();
    const endDate = getState().customerTableView.tableViewDates.endDate || new Date();
    const sortBy = getState().customerTableView.sortBy;
    const start = new Date(new Date(startDate).setUTCHours(0, 0, 0, 0));
    const end = new Date(new Date(endDate).setUTCHours(23, 59, 59, 999));
    const startISO = start.toISOString();
    const endISO = end.toISOString();

    const dates = {
      start: startISO.substring(0, startISO.length - 5),
      end: `${endISO.substring(0, endISO.length - 5)}.999`,
    };

    const requestParameters: TableViewRequestParameters = {
      filters: {
        count: `${count}`,
        page: `${page}`,
        stepName,
        columnsFilters,
        dates,
        sortBy,
      },
    };

    try {
      let response;
      if (!!id && isAdmin) {
        response = await apiAdminGetUserTableData(id, requestParameters);
      } else if (!!id && isGlobalUser) {
        response = await apiGetGlobalUserTableData(id, requestParameters);
      } else if (viewUser) {
        response = await apiGetViewUserTableData(requestParameters);
      } else if ((!!id || newId) && isOrganization) {
        response = await apiGetUserTableData(requestParameters, isOrganization, id || newId);
      } else {
        response = await apiGetUserTableData(requestParameters);
      }

      if (response && response.data && response.data.dataForTable) {
        const dataForTable = response.data.dataForTable;
        const startRecord = !!dataForTable.recordsCount ? page * count + 1 : 0;
        const endAvailableRecord = (page + 1) * count;
        let endRecord = endAvailableRecord;
        if (dataForTable.recordsCount < endAvailableRecord) {
          endRecord = dataForTable.recordsCount;
        }
        await dispatch(tVSetRecordsCount(dataForTable.recordsCount));
        await dispatch(tVSetShown(startRecord, endRecord));
        dispatch(tVSetTableColumns(dataForTable.filtersAndColumns));
        dispatch(tVSetTableViewData(dataForTable.tableData));
        !!dataForTable.sortBy && dispatch(tVSetSortBy(dataForTable.sortBy));
        dispatch(
          tVSetCreditInfo(
            dataForTable.creditInfo.creditMonth,
            start,
            end,
            dataForTable.creditInfo.credit,
            dataForTable.creditInfo.creditUsed,
            dataForTable.creditInfo.creditForLastMonth,
            dataForTable.creditInfo.creditUsedLastMonth,
            dataForTable.creditInfo.paymentType,
            dataForTable.creditInfo.balance,
          ),
        );
      }
    } catch (e) {
      showErrorNotification(e as any);
    } finally {
      dispatch(uILoadingFinish());
    }
  };
};

export const tVHGetTableData = (query: any = {}): any => {
  return async (dispatch: any, getState: () => AppState): Promise<void> => {
    dispatch(uILoadingStart());

    const filterColumns = (columns: any) => {
      return columns.filter(({ columnName }: TableViewFormattedRecord) =>
        ['stepName', 'version', 'date', 'state', 'time', 'name', 'objectType', 'section', 'book'].includes(columnName),
      );
    };

    const filterTable = (columns: any) => {
      return columns.map((column: any) => {
        return {
          tableRecord: column.tableRecord.filter(({ columnName }: TableViewFormattedRecord) =>
            ['stepName', 'version', 'date', 'state', 'time', 'name', 'objectType', 'section', 'book'].includes(
              columnName,
            ),
          ),
        };
      });
    };

    const { customerId, name, publicationDate, publication, edition, zone, objectType, section, book } =
      Object.fromEntries(query.entries());

    const id = getState().customerTableView.customerId || customerId;

    const columnsFilters: ColumnsFilters = {};

    let typeColumn = {};

    if (objectType?.split(',')[0] === 'page' && section.split(',')[0] !== 'undefined') {
      typeColumn = { section: { filterType: 'multiSelect', selectedOptions: [section.split(',')[0]] } };
    } else if (
      (objectType?.split(',')[0] === 'form' || objectType?.split(',')[0] === 'plate') &&
      book.split(',')[0] !== 'undefined'
    ) {
      typeColumn = { book: { filterType: 'multiSelect', selectedOptions: [book.split(',')[0]] } };
    }

    const columnsFiltersState: any = {
      name: { filterType: 'multiSelect', selectedOptions: [name.split(',')[0]] },
      publicationDate: {
        filterType: publicationDate.split(',')[1],
        selectedOptions: [publicationDate.split(',')[0]],
      },
      publication: { filterType: publication.split(',')[1], selectedOptions: [publication.split(',')[0]] },
      edition: { filterType: edition.split(',')[1], selectedOptions: [edition.split(',')[0]] },
      zone: { filterType: zone.split(',')[1], selectedOptions: [zone.split(',')[0]] },
      ...typeColumn,
    };

    Object.keys(columnsFiltersState).forEach((columnName: string) => {
      if (columnsFiltersState[columnName].filterType === 'multiSelect') {
        if (columnsFiltersState[columnName].selectedOptions?.length) {
          columnsFilters[columnName] = {
            filterType: columnsFiltersState[columnName].filterType,
            selectedOptions: columnsFiltersState[columnName].selectedOptions,
          };
        }
      } else {
        columnsFilters[columnName] = columnsFiltersState[columnName];
      }
    });

    const { pageHistory, countHistory } = getState().customerTableView.pagination;
    const page = pageHistory ?? 0;
    const count = countHistory ?? 25;

    const sortBy = getState().customerTableView.sortBy;
    const date = new Date();
    const requestParameters: any = {
      filters: {
        count: `${count}`,
        page: `${page}`,
        columnsFilters,
        dates: {
          end: endOfDay(date).toISOString(),
          start: subYears(date, 3).toISOString(),
        },
        sortBy,
      },
    };

    try {
      let response;

      if (!!id) {
        response = await apiTableViewHistoryData(id, requestParameters);
      }

      if (response && response.data && response.data.dataForTable) {
        const dataForTable = response.data.dataForTable;
        const startRecord = !!dataForTable.recordsCount ? page * count + 1 : 0;
        const endAvailableRecord = (page + 1) * count;
        let endRecord = endAvailableRecord;

        if (dataForTable.recordsCount < endAvailableRecord) {
          endRecord = dataForTable.recordsCount;
        }

        await dispatch(tVHSetRecordsCount(dataForTable.recordsCount));
        await dispatch(tVHSetShown(startRecord, endRecord));

        dispatch(tVHSetTableHistoryColumns(filterColumns(dataForTable.filtersAndColumns)));

        dispatch(tVHSetTableHistoryViewData(filterTable(dataForTable.tableData)));
      }
    } catch (e) {
      showErrorNotification(e as any);
    } finally {
      dispatch(uILoadingFinish());
    }
  };
};

export const tVGetStepsFilters = (newId: string) => {
  return async (dispatch: Dispatch, getState: () => AppState): Promise<any> => {
    dispatch(uILoadingStart());
    const id = getState().customerTableView.customerId;
    const isOrganization = getState().session?.user?.role === UserRolesEnum.organization;

    try {
      const response =
        !!id || newId ? await apiAdminGetUserStepsFilters(id || newId, isOrganization) : await apiGetUserStepsFilters();
      if (response && response.data && response.data) {
        // Check where filtersAndColumns are
        // Due to api could return filters in different shapes
        if (response.data.filtersAndColumns) {
          dispatch(tVSetTableColumns(response.data.filtersAndColumns));
        } else {
          dispatch(tVSetTableColumns(response.data));
        }
      }
    } catch (e) {
      showErrorNotification(e as any);
    } finally {
      dispatch(uILoadingFinish());
    }
  };
};

export const tvSetSelectedStepData = (stepName: string) => {
  return (dispatch: Dispatch): void => {
    localStorage.setItem('step', stepName);
    dispatch(tVSetSelectedStep(stepName));
  };
};

export const tVGetDataFromHistory = ():
  | ((dispatch: any) => Promise<any>)
  | ((dispatch: any, getState: () => AppState) => Promise<any>) => {
  const query = new URLSearchParams(window.location.search);
  const queryName = query.get('customerName');
  const queryUserId = query.get('customerId');
  if (queryName && queryUserId) {
    return async (dispatch: any): Promise<any> => {
      await dispatch(tVSetCustomerName(queryName));
      await dispatch(tVSetCustomerId(queryUserId));
      const startDate = query.get('startDate');
      const endDate = query.get('endDate');
      const step = query.get('selectedStep');
      const status = query.get('status');
      if (startDate) {
        await dispatch(tVSetStartDate(new Date(Number.isNaN(+startDate) ? startDate : +startDate)));
      }
      if (endDate) {
        await dispatch(tVSetEndDate(new Date(Number.isNaN(+endDate) ? endDate : +endDate)));
      }
      if (step) {
        await dispatch(tVSetSelectedStep(step));
      }
      if (status === 'error') {
        await dispatch(tVSetFilterState('state', ['error']));
      }
    };
  } else if (localStorage.getItem('step') === null) {
    return async (dispatch: any, getState: () => AppState): Promise<any> => {
      !getState().customerTableView.customerId &&
        !!window.history.state?.state?.customerId &&
        (await dispatch(tVSetCustomerId(window.history.state.state.customerId)));

      !getState().customerTableView.selectedStep &&
        !!window.history.state?.state?.selectedStep &&
        (await dispatch(tVSetSelectedStep(window.history.state.state.selectedStep)));

      !getState().customerTableView.customerName &&
        !!window.history.state?.state?.customerName &&
        (await dispatch(tVSetCustomerName(window.history.state.state.customerName)));
    };
  } else {
    return async (dispatch: any, getState: () => AppState): Promise<any> => {
      !getState().customerTableView.customerId &&
        !!window.history.state?.state?.customerId &&
        (await dispatch(tVSetCustomerId(window.history.state.state.customerId)));

      const step: any = localStorage.getItem('step');
      await dispatch(tVSetSelectedStep(step));

      !getState().customerTableView.customerName &&
        !!window.history.state?.state?.customerName &&
        (await dispatch(tVSetCustomerName(window.history.state.state.customerName)));
    };
  }
};

export const tVGetAvailableSteps = (companyName?: string) => {
  return async (dispatch: (arg: any) => void, getState: () => AppState): Promise<any> => {
    dispatch(uILoadingStart());
    await dispatch(tVResetFilters());

    await dispatch(tVGetDataFromHistory());
    const isAdmin = getState().session?.user?.role === UserRolesEnum.admin;
    const viewUser = getState().session?.user?.role === UserRolesEnum.accountUser;
    const isOrganization =
      getState().session?.user?.role === UserRolesEnum.organization ||
      getState().session?.user?.role === UserRolesEnum.organizationUser;

    const id = getState().customerTableView.customerId;
    const selectedStep = getState().customerTableView.selectedStep;
    if (isAdmin && !id) {
      window.location.href = '/';
    }
    let newId = '';
    if (isOrganization && !id) {
      const enterpriseCompany = () => getState().customers?.customers?.find((company) => company.name === companyName);
      const chosenCompany = enterpriseCompany();
      if (chosenCompany) {
        newId = chosenCompany?.id || '';
      } else {
        await dispatch(getAdminCustomersList());
        const companyAfterUpdate = enterpriseCompany();
        newId = companyAfterUpdate?.id || '';
        if (!companyAfterUpdate) {
          window.location.href = '/';
        }
      }
    }
    try {
      const response =
        !!id && !isOrganization
          ? await apiAdminGetUserStepsNames(id)
          : viewUser
          ? await apiGetViewUserStepsNames()
          : await apiGetUserStepsNames(id || newId, isOrganization);
      if (response && response.data && response.data.stepsNames) {
        const { stepsNames } = response.data;
        const newStep = !!stepsNames[selectedStep] ? selectedStep : '';
        if (!!Object.keys(stepsNames).length) {
          await dispatch(tVSetAvailableSteps(stepsNames));
        } else {
          await dispatch(tvResetState());
          showNotification(NotificationTypes.info, !!id ? "User doesn't have flow steps" : "You don't have flow steps");
          return;
        }
        await dispatch(tvSetSelectedStepData(newStep));
        await dispatch(tVGetStepsFilters(newId)); //!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
        await dispatch(tVGetTableData(newId));
        if (localStorage.getItem('shouldSaveSession') === 'false') {
          localStorage.setItem('rememberMe', 'false');
          localStorage.removeItem('shouldSaveSession');
          localStorage.removeItem('180ee71-8fda-425f-a9ec-1c6c4a75ed70');
        }
      }
    } catch (e) {
      showErrorNotification(e as any);
    } finally {
      dispatch(uILoadingFinish());
    }
  };
};

export const getXlsFile = () => {
  return async (dispatch: Dispatch, getState: () => AppState): Promise<any> => {
    dispatch(uILoadingStart());
    const id: any = getState().customerTableView.customerId || getState().session.user?.id;
    const role: any = getState().session?.user?.role;

    const columnsFilters: ColumnsFilters = {};
    const columnsFiltersState = getState().customerTableView.columnsFilters;
    Object.keys(columnsFiltersState).forEach((columnName: string) => {
      if (columnsFiltersState[columnName].filterType === 'multiSelect') {
        if (columnsFiltersState[columnName].selectedOptions?.length) {
          columnsFilters[columnName] = {
            filterType: columnsFiltersState[columnName].filterType,
            selectedOptions: columnsFiltersState[columnName].selectedOptions,
          };
        }
      } else {
        columnsFilters[columnName] = columnsFiltersState[columnName];
      }
    });

    const stepName =
      getState().customerTableView.selectedStep || Object.keys(getState().customerTableView.availableSteps)[0];

    const sortBy = getState().customerTableView.sortBy;
    const startDate = getState().customerTableView.tableViewDates.startDate || new Date();
    const endDate = getState().customerTableView.tableViewDates.endDate || new Date();
    const start = new Date(new Date(startDate).setUTCHours(0, 0, 0, 0));
    const end = new Date(new Date(endDate).setUTCHours(23, 59, 59, 999));
    const startISO = start.toISOString();
    const endISO = end.toISOString();

    const dates = {
      start: startISO.substring(0, startISO.length - 5),
      end: `${endISO.substring(0, endISO.length - 5)}.999`,
    };

    const requestParameters: TableViewRequestParameters = {
      filters: {
        stepName,
        columnsFilters,
        dates,
        sortBy,
      },
    };
    try {
      const response: any = await apiGetXlsData(id, role, requestParameters);
      const title = response.headers['content-disposition'].replace('attachment; filename="', '').replace('"', '');
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', title);
      document.body.appendChild(link);
      link.click();
    } catch (e) {
      const error = e as any;
      if (error.message === 'Request failed with status code 500') {
        showNotification(
          NotificationTypes.info,
          role === UserRolesEnum.globalUser || role === UserRolesEnum.admin
            ? "User doesn't have flow steps"
            : "You don't have flow steps",
        );
      } else {
        showErrorNotification(error);
      }
    } finally {
      dispatch(uILoadingFinish());
    }
  };
};

export const getXlsFile2 = (url: string) => {
  return async (dispatch: Dispatch): Promise<any> => {
    dispatch(uILoadingStart());

    try {
      const saveData = (blob: any, fileName: any) => {
        const a: any = document.createElement('a');
        document.body.appendChild(a);
        a.style = 'display: none';

        const url = window.URL.createObjectURL(blob);
        a.href = url;

        a.download = fileName.replace('-' + fileName.replace(/\D+/g, ''), '');
        a.click();
        window.URL.revokeObjectURL(url);
      };
      const response: any = await apiXLS(url);
      const blob = new Blob([response.data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });

      saveData(blob, 'file');
    } catch (e) {
      showErrorNotification(e as any);
    } finally {
      dispatch(uILoadingFinish());
    }
  };
};
