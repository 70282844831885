export interface AdminDashboardState {
  filters: Filters;
  customers: Customer[];
  pagination: Pagination;
  customersCount: number;
  search: string;
  sortBy: SortBy;
  customerId: string;
  accountType: string;
  monitoringAccounts: boolean;
}

export interface SortBy {
  columnName: string;
  order: 'asc' | 'desc';
}

export interface Filters {
  from: Date;
  to: Date;
  availableDates: AvailableDates;
}

export interface AvailableDates {
  [year: number]: number[];
}

export interface Pagination {
  page: number;
  count: number;
  startRecord: number;
  endRecord: number;
}

export interface Customer {
  customer: string;
  id: string;
  contractStartDate: MonthYearDate;
  contractEndDate: MonthYearDate;
  steps: Step[];
}

export interface MonthYearDate {
  month: number;
  year: number;
  date: string;
}

export interface Step {
  credit: number;
  monthlyUsed: number;
  yearlyUsed: number;
  overUse: number;
  errorFiles: number;
  name: string;
  balance: number;
  stepBalance: number;
  paymentType: string;
  creditBalance: number;
}

export enum AdminDashboardActionTypes {
  AD_SET_AVAILABLE_DATES = 'AD_SET_AVAILABLE_DATES',
  AD_SET_FILTER_FROM = 'AD_SET_FILTER_FROM',
  AD_SET_FILTER_TO = 'AD_SET_FILTER_TO',
  AD_SET_CUSTOMERS = 'AD_SET_CUSTOMERS',
  AD_SET_CUSTOMERS_COUNT = 'AD_SET_CUSTOMERS_COUNT',
  AD_SET_COUNT = 'AD_SET_COUNT',
  AD_SET_PAGE = 'AD_SET_PAGE',
  AD_SET_SHOWN = 'AD_SET_SHOWN,',
  AD_SET_SEARCH = 'AD_SET_SEARCH,',
  AD_TOGGLE_SORT_BY = 'AD_TOGGLE_SORT_BY',
  AD_SET_SORT_BY = 'AD_SET_SORT_BY',
  AD_SET_CUSTOMER_ID = 'AD_SET_CUSTOMER_ID',
  AD_RESET_STATE = 'AD_RESET_STATE',
  AD_SET_ACCOUNT_TYPE = 'AD_SET_ACCOUNT_TYPE',
  AD_SET_MONITORING_ACCOUNTS = 'AD_SET_MONITORING_ACCOUNTS',
}

export interface ADSetAvailableDates {
  type: AdminDashboardActionTypes.AD_SET_AVAILABLE_DATES;
  payload: AvailableDates;
}

export interface ADSetFilterFrom {
  type: AdminDashboardActionTypes.AD_SET_FILTER_FROM;
  payload: Date;
}

export interface ADSetFilterTo {
  type: AdminDashboardActionTypes.AD_SET_FILTER_TO;
  payload: Date;
}

export interface ADSetCustomers {
  type: AdminDashboardActionTypes.AD_SET_CUSTOMERS;
  payload: Customer[];
}

export interface ADSetCustomersCount {
  type: AdminDashboardActionTypes.AD_SET_CUSTOMERS_COUNT;
  payload: number;
}

export interface ADSetCount {
  type: AdminDashboardActionTypes.AD_SET_COUNT;
  payload: number;
}

export interface ADSetPage {
  type: AdminDashboardActionTypes.AD_SET_PAGE;
  payload: number;
}

export interface ADSetShown {
  type: AdminDashboardActionTypes.AD_SET_SHOWN;
  payload: { startRecord: number; endRecord: number };
}

export interface ADSetSearch {
  type: AdminDashboardActionTypes.AD_SET_SEARCH;
  payload: string;
}

export interface ADSetCustomerId {
  type: AdminDashboardActionTypes.AD_SET_CUSTOMER_ID;
  payload: string;
}

export interface ADToggleSortBy {
  type: AdminDashboardActionTypes.AD_TOGGLE_SORT_BY;
  payload: string;
}

export interface ADSetAccountType {
  type: AdminDashboardActionTypes.AD_SET_ACCOUNT_TYPE;
  payload: string;
}

export interface ADSetSortBy {
  type: AdminDashboardActionTypes.AD_SET_SORT_BY;
  payload: SortBy;
}

export interface ADSetMonitoringAccounts {
  type: AdminDashboardActionTypes.AD_SET_MONITORING_ACCOUNTS;
  payload: boolean;
}

export interface ADResetState {
  type: AdminDashboardActionTypes.AD_RESET_STATE;
}

export type AdminDashboardActions =
  | ADSetAvailableDates
  | ADSetFilterFrom
  | ADSetFilterTo
  | ADSetCustomers
  | ADSetCount
  | ADSetPage
  | ADSetCustomersCount
  | ADSetShown
  | ADSetSearch
  | ADToggleSortBy
  | ADSetSortBy
  | ADSetCustomerId
  | ADResetState
  | ADSetAccountType
  | ADSetMonitoringAccounts;
