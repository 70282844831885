import React, { ReactElement } from 'react';
import { Button } from 'components/UI';
import { Icon } from 'components/UI/Icon';
import { useStyles } from './styles';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { IconButton } from '@material-ui/core';

const EmailHasBeenSent = (): ReactElement => {
  // Translation
  const { t } = useTranslation();

  // Routing
  const history = useHistory();

  const styles = useStyles();

  const navigateToForgotPassword = () => {
    history.push('/forgot-password');
  };

  return (
    <div className={styles.setPasswordContainer}>
      <div className={styles.iconContainer}>
        <Icon name="auth-success" />
      </div>
      <div className={styles.flexBoxTitle}>
        <IconButton className={styles.backButton} onClick={navigateToForgotPassword}>
          <Icon name="arrowLeft" color="#666666" />
        </IconButton>
        <h5 className={styles.formHeader}>{t('Reset Password Sent')}</h5>
      </div>
      <p>{t('Return to Log in to input your temporary password')}</p>
      <Button
        labelKey={t('Return to log in')}
        type="button"
        fullWidth
        className={styles.textButton}
        onClick={() => history.push('/')}
      />
    </div>
  );
};

export default EmailHasBeenSent;
