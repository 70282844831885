import { Reducer } from 'redux';
import { produce } from 'immer';
import { CustomerDashboardActionTypes, CustomerDashboardActions, CustomerDashboardState } from './types';
import { paymentTypes } from 'consts';

const initialState: CustomerDashboardState = {
  creditBalance: {
    monthlyCredits: 0,
    creditsUsed: 0,
    balance: 0,
  },
  currentMonthUsage: {
    currentMonth: '',
    dailyCreditUsageArray: [],
    averageMonthlyUsage: 0,
  },
  yearlyUsage: {
    yearlyCreditUsageArray: [],
    limitExceeded: 0,
  },
  paymentType: paymentTypes.contract,
  summaryCreditsUsage: {
    averageMonthlyUsage: 0,
    contractDates: {
      start: new Date(new Date().setDate(new Date().getDate() - 1)),
      end: new Date(),
    },
    yearlyCreditDeviation: 0,
    yearlyCredits: 0,
    yearlyUsage: 0,
  },
};

export const customerDashboardReducer: Reducer<CustomerDashboardState, CustomerDashboardActions> = (
  state = initialState,
  action,
): CustomerDashboardState => {
  return produce(state, (draft: CustomerDashboardState) => {
    switch (action.type) {
      case CustomerDashboardActionTypes.SET_CUSTOMER_DASHBOARD_STEP:
        draft.creditBalance = state.creditBalance;
        return;

      case CustomerDashboardActionTypes.SET_CUSTOMER_DASHBOARD_DATA:
        draft.creditBalance = action.payload.creditBalance;
        draft.currentMonthUsage = action.payload.currentMonthUsage;
        draft.yearlyUsage = action.payload.yearlyUsage;
        draft.summaryCreditsUsage = action.payload.summaryCreditsUsage;
        draft.paymentType = action.payload.paymentType;
        return;
      case CustomerDashboardActionTypes.SET_CUSTOMER_INITIAL_STATE:
        return initialState;
      default:
        return state;
    }
  });
};
