export interface SystemUser {
  name: string;
  email: string;
  enterpriseName: string;
  enterpriseNames?: string[];
  role: string;
  companyName: string;
  permission: string;
  lastLoginDate: string;
  lastLoginTime: string;
  accountType: string;
  id: string;
  isEditable: boolean;
  isDeleteOrganization: boolean;
}

export interface SystemUsersState {
  records: SystemUser[];
  pagination: Pagination;
  name: string;
  sortBy: SortBy;
  recordsCount: number;
  accountType: string;
  customers: any;
  account: any;
  monitoringUser: any;
}

export interface MonitoringUser {
  id: string;
  email: string;
  name: string;
  writeAccess: boolean;
  timeFormat: string;
  isCustomerOperator: boolean;
  isCustomerReport: boolean;
}

export interface MonitoringUserWithStatus extends MonitoringUser {
  status: boolean;
}

export interface SortBy {
  columnName?: string | undefined;
  order?: string;
}

export interface Pagination {
  page: number;
  count: number;
  startRecord: number;
  endRecord: number;
}

export enum SystemUsersActionTypes {
  SU_SET_RECORDS = 'SU_SET_RECORDS',
  SU_SET_RECORDS_COUNT = 'SU_SET_RECORDS_COUNT',
  SU_SET_COUNT = 'SU_SET_COUNT',
  SU_SET_PAGE = 'SU_SET_PAGE',
  SU_SET_SHOWN = 'SU_SET_SHOWN,',
  SU_SET_SEARCH = 'SU_SET_SEARCH,',
  SU_TOGGLE_SORT_BY = 'SU_TOGGLE_SORT_BY',
  SU_SET_SORT_BY = 'SU_SET_SORT_BY',
  SU_SET_ACCOUNT_TYPE = 'SU_SET_ACCOUNT_TYPE',
}

export enum SystemUsersCustomersActionTypes {
  SU_SET_CUSTOMERS_LIST = 'SU_SET_CUSTOMERS_LIST',
  SU_SET_ACCOUNT = 'SU_SET_ACCOUNT',
  SU_SET_CUSTOMERS_RESET_LIST = 'SU_SET_CUSTOMERS_RESET_LIST',
}

export enum MonitoringUsersActionTypes {
  SET_MONITORING_USERS = 'SET_MONITORING_USERS',
  SET_MONITORING_USERS_LIST = 'SET_MONITORING_USERS_LIST',
  SET_MONITORING_USERS_LIST_ALL = 'SET_MONITORING_USERS_LIST_ALL',
}

export interface SUSetRecords {
  type: SystemUsersActionTypes.SU_SET_RECORDS;
  payload: SystemUser[];
}

export interface SUSetCustomersList {
  type: SystemUsersCustomersActionTypes.SU_SET_CUSTOMERS_LIST;
  payload: any;
}

export interface SUSetCustomersListReset {
  type: SystemUsersCustomersActionTypes.SU_SET_CUSTOMERS_RESET_LIST;
  payload: any;
}

export interface SUSetRecordsCount {
  type: SystemUsersActionTypes.SU_SET_RECORDS_COUNT;
  payload: number;
}

export interface SUSetCount {
  type: SystemUsersActionTypes.SU_SET_COUNT;
  payload: number;
}

export interface SUAccountType {
  type: SystemUsersActionTypes.SU_SET_ACCOUNT_TYPE;
  payload: string;
}

export interface SUSetPage {
  type: SystemUsersActionTypes.SU_SET_PAGE;
  payload: number;
}

export interface SUSetShown {
  type: SystemUsersActionTypes.SU_SET_SHOWN;
  payload: { startRecord: number; endRecord: number };
}

export interface SUSetSearch {
  type: SystemUsersActionTypes.SU_SET_SEARCH;
  payload: string;
}

export interface SUToggleSortBy {
  type: SystemUsersActionTypes.SU_TOGGLE_SORT_BY;
  payload: string;
}

export interface SUSetSortBy {
  type: SystemUsersActionTypes.SU_SET_SORT_BY;
  payload: SortBy;
}

export interface SUSetAccount {
  type: SystemUsersCustomersActionTypes.SU_SET_ACCOUNT;
  payload: any;
}

export interface MonitoringUserSet {
  type: MonitoringUsersActionTypes.SET_MONITORING_USERS;
  payload: any;
}

export interface MonitoringUserListSet {
  type: MonitoringUsersActionTypes.SET_MONITORING_USERS_LIST;
  payload: any;
}

export interface MonitoringUserListAllSet {
  type: MonitoringUsersActionTypes.SET_MONITORING_USERS_LIST_ALL;
  payload: any;
}

export type SystemUsersActions =
  | SUSetRecords
  | SUSetCount
  | SUSetPage
  | SUSetRecordsCount
  | SUSetShown
  | SUSetSearch
  | SUToggleSortBy
  | SUAccountType
  | SUSetCustomersList
  | SUSetCustomersListReset
  | SUSetAccount
  | MonitoringUserSet
  | MonitoringUserListSet
  | MonitoringUserListAllSet
  | SUSetSortBy;
