import React, { FC, ReactElement, useMemo, useState } from 'react';
import { IconButton, TableCell } from '@material-ui/core';
import Popover from '@material-ui/core/Popover';
import { InlineDatePicker } from 'components/UI';
import { tVGetTableData, tVSetFilterDateRange } from 'store/customerTableView/actions';
import { useDispatch, useSelector } from 'react-redux';
import format from 'date-fns/format';
import { AppState } from 'store';
import { Icon } from 'components/UI/Icon';
import { useTranslation } from 'react-i18next';

import { useStyles } from './styles';

interface Props {
  columnName: string;
}

const FilterCellDateRange: FC<Props> = ({ columnName }: Props) => {
  // Styling
  const styles = useStyles();
  const { t } = useTranslation();

  // State
  const [rangeStart, setRangeStart] = useState(new Date(new Date().setDate(new Date().getDate() - 1)));
  const [rangeEnd, setRangeEnd] = useState(new Date());
  const [rangeStartPrev, setRangeStartPrev] = useState(rangeStart);
  const [rangeEndPrev, setRangeEndPrev] = useState(rangeEnd);

  // Redux
  const dispatch = useDispatch();
  const filterRangeStart = useSelector(
    (state: AppState) =>
      state.customerTableView.columnsFilters[columnName] &&
      (state.customerTableView.columnsFilters[columnName].rangeStart as Date),
  );
  const filterRangeEnd = useSelector(
    (state: AppState) =>
      state.customerTableView.columnsFilters[columnName] &&
      (state.customerTableView.columnsFilters[columnName].rangeEnd as Date),
  );

  const renderRangeSelector = useMemo(() => {
    const handleRangeStartChange = (date: Date, event: React.SyntheticEvent<any>): void => {
      event.stopPropagation();
      event.preventDefault();
      setRangeStart(date);
      dispatch(tVSetFilterDateRange(columnName, date, rangeEnd));
    };
    const handleRangeEndChange = (date: Date, event: React.SyntheticEvent<any>): void => {
      event.stopPropagation();
      event.preventDefault();
      setRangeEnd(date);
      dispatch(tVSetFilterDateRange(columnName, rangeStart, date));
    };
    const rangeSelector = (): ReactElement => {
      return (
        <div className={styles.popoverRangeWrapper}>
          <InlineDatePicker selected={rangeStart} labelKey={t('Range Start')} onChange={handleRangeStartChange} />
          <InlineDatePicker selected={rangeEnd} labelKey={t('Range End')} onChange={handleRangeEndChange} />
        </div>
      );
    };
    return rangeSelector();
  }, [columnName, dispatch, rangeEnd, rangeStart, styles.popoverRangeWrapper, t]);

  //Popover
  const [anchorEl, setAnchorEl] = React.useState<HTMLTableCellElement | null>(null);
  const handleFiltersClick = (event: React.MouseEvent<HTMLTableCellElement>): void => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (): void => {
    setAnchorEl(null);
    if (rangeStartPrev !== rangeStart || rangeEndPrev !== rangeEnd) {
      dispatch(tVGetTableData());
      setRangeStartPrev(rangeStart);
      setRangeEndPrev(rangeEnd);
    }
  };
  const open = Boolean(anchorEl);
  const id = open ? 'multi-select-popover' : undefined;

  const handleRangeClearButton = (event: any): void => {
    event.stopPropagation();
    dispatch(tVSetFilterDateRange(columnName, undefined, undefined));
    dispatch(tVGetTableData());
  };

  return (
    <>
      <TableCell className={[styles.filterCell, styles.FilterCellDateRange].join(' ')} onClick={handleFiltersClick}>
        <div className={styles.cellContent}>
          {!!filterRangeStart && !!filterRangeEnd ? (
            <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
              {`${format(filterRangeStart, 'dd/MM/yy')} - ${format(filterRangeEnd, 'dd/MM/yy')}`}
              <IconButton className={styles.rangeClearButton} onClick={handleRangeClearButton}>
                <Icon name="close" size={9} />
              </IconButton>
            </div>
          ) : (
            <Icon name="filter" size={12} />
          )}
        </div>
      </TableCell>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <div className={styles.rangeHeader}>{t('Select Date Range')}</div>
        {renderRangeSelector}
      </Popover>
    </>
  );
};

export default FilterCellDateRange;
