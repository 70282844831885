import React, { FC, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import Header from '../../components/Header';
import PageWithDrawer from '../../components/PageWithDrawer';
import { Button, InputText, ToggleSwitch } from 'components/UI';

import { useTranslation } from 'react-i18next';

import { useDispatch, useSelector } from 'react-redux';
import { AppState } from 'store';
import { subscriptionAccountSchema, subscriptionOrgUserSchema } from 'utils/validation';
import { useStyles } from '../AdminPricingSettings/styles';
import cn from 'classnames';
import { Icon } from '../../components/UI/Icon';
import ConfirmDeleteChanel from '../../components/Modals/ConfirmCommonModal';

// Stripe
import StripeForm from './stripe';
import PaymentModal from '../../components/Modals/PaymentModal';
import MessageModalWithCloseButton from '../../components/Modals/MessageModalWithCloseButton';
import {
  cancelSubscriptionDetails,
  createSubscriptionDetails,
  customerIsNotConnectedPaymentCardSet,
  getCardsInfo,
  getSubscriptionDetails,
  renewSubscriptionDetails,
  retryPaymentSubscription,
  setSubscriptionFirstCardReject,
  setSubscriptionUpdatedReject,
  setSubscriptionUpdatedSuccessful,
  startTrialAction,
  updateSubscriptionDetails,
} from '../../store/customerParameters/actions';
import {
  CardCustomerIntents,
  CardError,
  IsNotConnectedPaymentCard,
  PaymentStatus,
  SubscriptionStatus,
} from '../../store/customerParameters/types';
import { User } from '../../store/session/types';
import { deletePaymentMethod } from '../../api';
import { uILoadingStart } from '../../store/ui/actions';
import Tooltip from '@material-ui/core/Tooltip';
import SuccessModal from '../../components/Modals/SuccessModal';
import { getAdminBillingSettings } from '../../store/adminParameters/actions';
import { TransparentBorder } from './components/TransparentBorder';
import { BasicTable, BasicTableProps } from '../../components/Tables';
import TableWrapper from '../../components/Tables/TableWrapper';
import { mergeTwoObjects } from '../../utils';
import { defaultBillingSettings } from '../../store/adminParameters/reducer';
import { IconButton } from '@material-ui/core';
import { toFixed_2 } from '../BillingHistory';
import InfoModal from '../../components/Modals/InfoModal';
import { UserRolesEnum } from 'utils/types';
import { differenceInMinutes, format } from 'date-fns';
import {
  CardDetails,
  FormValues,
  IPrice,
  IProducts,
  SubscriptionPlan,
  SubscriptionPlansRender,
  Summary,
  TrialExpiredLimit,
  TrialExpiredLimitLabel,
} from './types';
import { TrialLimit } from './components/TrialLimit';
import endOfMonth from 'date-fns/esm/fp/endOfMonth/index.js';

const MAX_LENGTH = 5;
const convertToNumber = (value: string | number) => (isNaN(Number(value)) ? 0 : Number(value));
const convertToBoolean = (value: string) => value === 'true' || (typeof value === 'boolean' && value === true);
export const convertCentsToDollar = (value: string | number) => parseFloat(String(value)) / 100;
export const toFixed = (n: number) => {
  return parseInt(String(n * 100)) / 100;
};
const convertToString = (value: string | number) => String(value);

const defaultProducts = {
  Accounts: { overUsed: 0, quantity: 0 },
  Licences: { overUsed: 0, quantity: 0 },
  Servers: { overUsed: 0, quantity: 0 },
  Users: { overUsed: 0, quantity: 0 },
  'Workflow Steps': { overUsed: 0, quantity: 0 },
};

let intervalID: any = null;

const ProductSettings: FC = () => {
  // Styling
  const styles = useStyles();
  // Translation
  const { t } = useTranslation();
  // Redux
  const dispatch = useDispatch();

  const isOrganization: boolean = useSelector(
    (state: AppState) => !!state.session.user && state.session.user.role === UserRolesEnum.organization,
  );
  const isOrganizationUser: boolean = useSelector(
    (state: AppState) => !!state.session.user && state.session.user.role === UserRolesEnum.organizationUser,
  );

  const user: User | null = useSelector((state: AppState) => state.session.user);
  const productType: any = useSelector((state: AppState) => state.session.user?.productType);

  const permissions: any = useSelector((state: AppState) => state.session.user?.permissions);
  const itemsPrices: any = useSelector((state: AppState) => state.adminParameters?.billingSettings?.itemsPrices);

  const customerIntents: CardCustomerIntents | undefined = useSelector(
    (state: AppState) => state.customerParameters?.cardsInfo?.account?.customerIntents,
  );
  const subscription: any = useSelector((state: AppState) => state.customerParameters?.subscription);
  const subscriptionStatus: any = useSelector((state: AppState) => state.customerParameters?.subscription?.status);
  const newPlan: string | undefined = useSelector(
    (state: AppState) => state.customerParameters?.cardsInfo?.switchToSubscription?.newPlan,
  );
  const subscriptionProducts: any = useSelector((state: AppState) => state.customerParameters?.subscription?.products);
  const paymentStatusFromState: PaymentStatus | undefined = useSelector(
    (state: AppState) => state.customerParameters?.cardsInfo?.account?.paymentStatus?.status,
  );

  const overuseStatus: any | undefined = useSelector(
    (state: AppState) => state.customerParameters?.cardsInfo?.account?.paymentStatus?.overuse?.status,
  );
  const regularStatus: any | undefined = useSelector(
    (state: AppState) => state.customerParameters?.cardsInfo?.account?.paymentStatus?.regular?.status,
  );

  const failInvoiceId: string | undefined = useSelector(
    (state: AppState) => state.customerParameters?.cardsInfo?.account?.paymentStatus?.failInvoiceId,
  );
  const subscriptionPlan: any = useSelector((state: AppState) => state.customerParameters?.subscription?.plan);

  // TODO temporary payment status/ delete after status from server will come correctly
  const paymentStatus = subscriptionStatus === 'empty' ? 'blocked' : paymentStatusFromState;
  const isCanceled: boolean | undefined = useSelector(
    (state: AppState) => state.customerParameters?.subscription?.isCanceled,
  );

  // Subscription ABSOLUTE CANCEL(BLOCKED)
  const isAbsoluteCancel = paymentStatus === PaymentStatus.BLOCKED && regularStatus !== PaymentStatus.FAIL_PAYMENT;

  // Subscription TRIAL
  const isTrialExpired = paymentStatus === PaymentStatus.TRIALENDED;
  const isTrial = paymentStatus === PaymentStatus.TRIAL;

  // Subscription BLOCKED but in Past due
  const isBlockedInPastDue = useMemo(
    () =>
      paymentStatus === PaymentStatus.BLOCKED &&
      (regularStatus === PaymentStatus.FAIL_PAYMENT || regularStatus === PaymentStatus.PAYED),
    [paymentStatus, regularStatus],
  );
  const isBlockedInPastDueOveruse = useMemo(
    () =>
      paymentStatus === PaymentStatus.BLOCKED &&
      (overuseStatus === PaymentStatus.FAIL_PAYMENT || overuseStatus === PaymentStatus.PAYED),
    [paymentStatus, overuseStatus],
  );
  // Subscription CANCEL
  const isMonthlySubscriptionCancel = subscriptionPlan === SubscriptionStatus.MONTHLY && isCanceled;
  const isAnnualSubscriptionCancel = subscriptionPlan === SubscriptionStatus.ANNUALLY && isCanceled;

  // Subscription PAYED
  const isMonthlySubscription =
    paymentStatus === PaymentStatus.PAYED && subscriptionPlan === SubscriptionStatus.MONTHLY && !isCanceled;
  const isAnnualSubscription =
    paymentStatus === PaymentStatus.PAYED && subscriptionPlan === SubscriptionStatus.ANNUALLY && !isCanceled;

  // Subscription FAIL PAYMENT
  const isMonthlySubscriptionPaymentFail =
    (paymentStatus === PaymentStatus.FAIL_PAYMENT ||
      (paymentStatus === PaymentStatus.BLOCKED && subscriptionStatus === 'incomplete')) &&
    subscriptionPlan === SubscriptionStatus.MONTHLY;
  const isAnnualSubscriptionPaymentFail =
    (paymentStatus === PaymentStatus.FAIL_PAYMENT ||
      (paymentStatus === PaymentStatus.BLOCKED && subscriptionStatus === 'incomplete')) &&
    subscriptionPlan === SubscriptionStatus.ANNUALLY;

  // product subscription toggles
  const toggleSubscriptionWorkflowMonitoring = subscription?.toggles?.workflowMonitoring;
  const toggleSubscriptionWorkflowReports = subscription?.toggles?.workflowReports;
  const toggleSubscriptionServersMonitoring = subscription?.toggles?.serversMonitoring;

  const subscriptionProductsPrepared = isAbsoluteCancel ? defaultProducts : subscriptionProducts;

  // products subscription field?
  const productQuantitySubscriptionUsers = subscriptionProductsPrepared?.Users?.quantity;
  const productQuantitySubscriptionAccounts = subscriptionProductsPrepared?.Accounts?.quantity;
  const productQuantitySubscriptionLicences = subscriptionProductsPrepared?.Licences?.quantity;
  const productQuantitySubscriptionServers = subscriptionProductsPrepared?.Servers?.quantity;
  const productQuantitySubscriptionWorkflowSteps = subscriptionProductsPrepared?.['Workflow Steps']?.quantity;

  const itemsUsed: any = useSelector((state: AppState) => {
    if (state.customerParameters?.cardsInfo?.itemsUsed?.Accounts) {
      return Object.keys(state.customerParameters?.cardsInfo?.itemsUsed).length > 0
        ? state.customerParameters?.cardsInfo?.itemsUsed
        : {
            Accounts: [0, 0],
            Licences: [0, 0],
            Servers: [0, 0],
            Users: [0, 0],
            'Workflow Steps': [0, 0],
          };
    }
  });
  const annualDiscount: number | undefined = useSelector(
    (state: AppState) => state.customerParameters?.cardsInfo?.annualDiscount,
  );
  const subscriptionUpdatedSuccessful: boolean = useSelector(
    (state: AppState) => state.customerParameters?.subscriptionUpdatedSuccessful,
  );
  const subscriptionUpdatedReject: boolean = useSelector(
    (state: AppState) => state.customerParameters?.subscriptionUpdatedReject,
  );
  const subscriptionFirstCardReject: boolean = useSelector(
    (state: AppState) => state.customerParameters?.subscriptionFirstCardReject,
  );
  const isNotConnectedPaymentCard: IsNotConnectedPaymentCard | undefined = useSelector(
    (state: AppState) => state.customerParameters?.isNotConnectedPaymentCard,
  );
  const errorMessage: string | undefined = useSelector((state: AppState) => state.customerParameters?.errorMessage);
  const expiresSubscription: string | undefined = useSelector(
    (state: AppState) => state.customerParameters?.subscription?.timings?.current_period_end,
  );
  const startSubscription: string | undefined = useSelector(
    (state: AppState) => state.customerParameters?.subscription?.timings?.current_period_start,
  );

  const isOncePayed: boolean | undefined = useSelector(
    (state: AppState) =>
      state.customerParameters?.cardsInfo?.account?.paymentStatus?.isOncePayed ||
      state.customerParameters?.cardsInfo?.account?.paymentStatus?.waitForAction === 'trialEndedToBlocked',
  );

  const [openModal, setOpenModal] = useState(false);
  const [openModalSubmitZeroValues, setOpenModalSubmitZeroValues] = useState(false);
  const [openModalFailPayment, setOpenModalFailPayment] = useState(false);

  const [openModalCancelSubscription, setOpenModalCancelSubscription] = useState(false);
  const [openModalModifyAttention, setOpenModalModifyAttention] = useState(false);
  const [openModalModifyAttentionCard, setOpenModalModifyAttentionCard] = useState({
    isOpen: false,
    last4: '',
    id: '',
  });

  const preparedSubscriptionPlan: SubscriptionStatus =
    paymentStatus === PaymentStatus.TRIAL || paymentStatus === PaymentStatus.TRIALENDED
      ? paymentStatus
      : subscriptionPlan;

  // TODO temporary delete
  const [activeSubscriptionValue, setActiveSubscription] = useState(preparedSubscriptionPlan);

  // state for saved filed value if toggles off/on
  const [productField, setProductField] = useState(0);
  const [workflowReportField, setWorkflowReportField] = useState(0);
  const [serversField, setServersField] = useState(0);

  const isUpdateSubscription = !!subscription && !!subscription.id && !isAbsoluteCancel;

  const isPermissionFinanceOrganizationReadOnly =
    (isOrganization || isOrganizationUser) && permissions.finance?.permission === 'read';

  const labels = useMemo(
    () => ({
      name: t('Name'),
      CVV2: t('CVV2'),
      nameOnCard: t('Name on card'),
      cardNumber: t('Card Number'),
      expiringDate: t('Expiring Date'),
      numberOfUsers: t('Number of Users'),
      workflowMonitoring: t('Workflow Monitoring'),
      serversMonitoring: t('Servers Monitoring'),
      workflowReporting: t('Workflow Reporting'),
      numberOfAccounts: t('Number of Accounts'),
      numberOfServers: t('Number of Servers'),
      numberOfWorkflowSteps: t('Number of Workflow Steps'),
    }),
    [t],
  );

  // Form
  const {
    register,
    formState: { errors },
    setValue,
    getValues,
    handleSubmit,
    watch,
    reset,
  } = useForm<FormValues>({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    criteriaMode: 'firstError',
    resolver: yupResolver(
      isOrganization || isOrganizationUser ? subscriptionOrgUserSchema(t) : subscriptionAccountSchema(t),
      {
        abortEarly: false,
      },
    ),
  });

  const watchWorkflowMonitoring = watch('workflowMonitoring');
  const watchWorkflowReporting = watch('workflowReporting');
  const watchServersMonitoring = watch('serversMonitoring');

  const setValuesDefault = () => {
    setValue('numberOfUsers', 0);
    setValue('numberOfServers', 0);
    setValue('numberOfProducts', 0);
    setValue('numberOfWorkflowSteps', 0);
    setValue('numberOfAccounts', 0);
    setValue('workflowMonitoring', productType.serverProductType.workflowMonitoring);
    setValue('workflowReporting', productType.serverProductType.workflowReports);
    setValue('serversMonitoring', productType.serverProductType.serversMonitoring);
  };

  const handleActiveSubscription = (value: SubscriptionStatus) => {
    setActiveSubscription((prevState: SubscriptionStatus) => {
      handleSumSubscription();

      if (Object.keys(subscription).length === 0 || subscription.status === 'empty') {
        if (value === SubscriptionStatus.TRIAL || value === SubscriptionStatus.TRIALENDED) {
          setValue('numberOfUsers', itemsUsed && itemsUsed?.Users ? itemsUsed.Users[1] : 0);
          setValue('numberOfServers', itemsUsed && itemsUsed?.Servers ? itemsUsed.Servers[1] : 0);
          setValue(
            'numberOfWorkflowSteps',
            itemsUsed && itemsUsed?.['Workflow Steps'] ? itemsUsed['Workflow Steps'][1] : 0,
          );

          if (isOrganization || isOrganizationUser) {
            setValue('numberOfAccounts', itemsUsed && itemsUsed?.Accounts ? itemsUsed.Accounts[1] : 0);
          }
        } else if (prevState === SubscriptionStatus.TRIAL || prevState === SubscriptionStatus.TRIALENDED) {
          setValue('numberOfUsers', 0);
          setValue('numberOfServers', 0);
          setValue('numberOfProducts', 0);
          setValue('numberOfWorkflowSteps', 0);

          if (isOrganization || isOrganizationUser) {
            setValue('numberOfAccounts', 0);
          }
        }
      }

      return value;
    });
  };

  // Summary blocks
  const renderMoreInfoButton = () => {
    return (
      <Button
        id={'more_info'}
        type={'button'}
        labelKey={t('More Info')}
        onClick={() => {
          handleActiveSubscription(SubscriptionStatus.ANNUALLY);
        }}
        className={cn(styles.saveButton, styles.buttonPayment)}
      />
    );
  };
  const renderButton = () => {
    return (
      <Button
        id={isUpdateSubscription ? 'modify' : 'subscribe'}
        type={
          (isUpdateSubscription || isValidationButtonModify) && activeSubscriptionValue !== SubscriptionStatus.TRIAL
            ? 'button'
            : 'submit'
        }
        disabled={isDisableButtonModify}
        labelKey={t(isUpdateSubscription ? 'Modify' : 'Subscribe')}
        onClick={() => {
          if (isValidationButtonModify && activeSubscriptionValue !== SubscriptionStatus.TRIAL) {
            setOpenModalSubmitZeroValues(true);
          } else if (isUpdateSubscription) {
            setOpenModalModifyAttention(true);
          } else {
            handleSubmit(onSubmit);
          }
        }}
        className={cn(styles.saveButton, styles.buttonPayment, isDisableButtonModify && styles.disabledButton)}
        emptyStyle={isUpdateSubscription}
        xs={isUpdateSubscription}
      />
    );
  };

  const getPrice = ({
    count,
    type,
    isModify,
  }: {
    count: number;
    type: 'Users' | 'Accounts' | 'Servers' | 'Licences' | 'Workflow Steps';
    isModify?: boolean;
  }) => {
    if (itemsPrices[type] && count) {
      const getModifiedPriceInDollar = (price: number) => {
        const now = new Date();
        const start = startSubscription ? new Date(startSubscription) : now;
        const startYear = start?.getFullYear();
        const end = expiresSubscription ? new Date(expiresSubscription) : now;
        const currentYear = now.getFullYear();

        if (currentYear < startYear) {
          return toFixed(convertCentsToDollar(price));
        }
        const subscriptionDurationInSeconds = differenceInMinutes(end, start);
        const timePassedFromStartSubscriptionInSeconds = differenceInMinutes(now, start);

        const timeLeftInSeconds = subscriptionDurationInSeconds - timePassedFromStartSubscriptionInSeconds;
        const priceInCentForOneSecond = price / subscriptionDurationInSeconds;
        const priceForOneSubscriptionItemTimeLeft = convertCentsToDollar(timeLeftInSeconds * priceInCentForOneSecond);

        return priceForOneSubscriptionItemTimeLeft;
      };

      if (isModify) {
        if (count <= 10) {
          return getModifiedPriceInDollar(itemsPrices[type].upTo10);
        }

        if (count <= 20) {
          return getModifiedPriceInDollar(itemsPrices[type].upTo20);
        }

        if (count <= 50) {
          return getModifiedPriceInDollar(itemsPrices[type].upTo50);
        }

        if (count <= 100) {
          return getModifiedPriceInDollar(itemsPrices[type].upTo100);
        }

        if (count > 100) {
          return getModifiedPriceInDollar(itemsPrices[type].moreThan100);
        }
      }

      if (count <= 10) {
        return convertCentsToDollar(itemsPrices[type].upTo10);
      }

      if (count <= 20) {
        return convertCentsToDollar(itemsPrices[type].upTo20);
      }

      if (count <= 50) {
        return convertCentsToDollar(itemsPrices[type].upTo50);
      }

      if (count <= 100) {
        return convertCentsToDollar(itemsPrices[type].upTo100);
      }

      if (count > 100) {
        return convertCentsToDollar(itemsPrices[type].moreThan100);
      }
    }

    return 0;
  };

  const calculateActualUnitsWithTime = ({ boughtUnits, mixedUnits, product }: IPrice) => {
    if (mixedUnits - boughtUnits === 0 || mixedUnits - boughtUnits < 0) {
      return 0;
    }
    // AD = ((X - Y) * (P / X) * M) - ((X - Y) * (P / X) * B)
    const priceForMixedUnit = getPrice({ count: mixedUnits, type: product, isModify: true }) * mixedUnits;
    const priceForBoughtUnit = getPrice({ count: boughtUnits, type: product, isModify: true }) * boughtUnits;

    return priceForMixedUnit - priceForBoughtUnit;
  };

  const calculateSumForBoughtAndActualUnits = ({ boughtUnits, mixedUnits, product }: IPrice): number => {
    if (mixedUnits < boughtUnits) {
      const actualUnits = mixedUnits;
      const priceForActualUnit = getPrice({ count: actualUnits, type: product }) * actualUnits;

      return priceForActualUnit;
    } else {
      const priceForActualUnit = getPrice({ count: mixedUnits, type: product }) * mixedUnits;

      return priceForActualUnit;
    }
  };

  const convertToPricesFieldsValues = () => {
    if (!itemsPrices && Object.keys(itemsPrices).length === 0) {
      return 0;
    }

    const setCalculateSumUnits = (props: IPrice): void => {
      sum[props.product] = calculateSumForBoughtAndActualUnits(props);
    };

    const sum: IProducts = {
      Accounts: 0,
      Licences: 0,
      Servers: 0,
      Users: 0,
      'Workflow Steps': 0,
    };
    const numberOfUsersProductQuantity = isUpdateSubscription ? productQuantitySubscriptionUsers : 0;
    const numberOfUsers = convertToNumber(getValues('numberOfUsers'));

    const numberOfAccountsProductQuantity = isUpdateSubscription ? productQuantitySubscriptionAccounts : 0;
    const numberOfAccounts = convertToNumber(getValues('numberOfAccounts'));

    const numberOfServersProductQuantity = isUpdateSubscription ? productQuantitySubscriptionServers : 0;
    const numberOfServers = convertToNumber(getValues('numberOfServers'));

    const numberOfProductsProductQuantity = isUpdateSubscription ? productQuantitySubscriptionLicences : 0;
    const numberOfProducts = convertToNumber(getValues('numberOfProducts'));

    const numberOfWorkflowStepsProductQuantity = isUpdateSubscription ? productQuantitySubscriptionWorkflowSteps : 0;
    const numberOfWorkflowSteps = convertToNumber(getValues('numberOfWorkflowSteps'));

    if (numberOfUsers > 0) {
      setCalculateSumUnits({
        boughtUnits: numberOfUsersProductQuantity,
        mixedUnits: numberOfUsers,
        product: 'Users',
      });
    }

    if (numberOfAccounts > 0) {
      setCalculateSumUnits({
        boughtUnits: numberOfAccountsProductQuantity,
        mixedUnits: numberOfAccounts,
        product: 'Accounts',
      });
    }

    if (numberOfServers > 0) {
      setCalculateSumUnits({
        boughtUnits: numberOfServersProductQuantity,
        mixedUnits: numberOfServers,
        product: 'Servers',
      });
    }

    if (numberOfProducts > 0) {
      setCalculateSumUnits({
        boughtUnits: numberOfProductsProductQuantity,
        mixedUnits: numberOfProducts,
        product: 'Licences',
      });
    }

    if (numberOfWorkflowSteps > 0) {
      setCalculateSumUnits({
        boughtUnits: numberOfWorkflowStepsProductQuantity,
        mixedUnits: numberOfWorkflowSteps,
        product: 'Workflow Steps',
      });
    }

    return Object.values(sum).reduce((accum, value) => accum + value, 0);
  };

  const [isShowPrice, setIsShowPrice] = useState(false);

  const [sumSubscription, setSumSubscription] = useState(convertToPricesFieldsValues());
  const handleSumSubscription = () => setSumSubscription(convertToPricesFieldsValues());

  const getPricesWithDiscount = (price: number) => {
    const preparedAnnualDiscount = annualDiscount ?? 0;

    return price - (price * preparedAnnualDiscount) / 100;
  };

  const COLUMNS__PRICES = [
    {
      label: t('Service/Number'),
      dataKey: 'service_number',
    },
    {
      label: t('Overage fee'),
      dataKey: 'additional_unit',
    },
    {
      label: t('Up to {0}').replace('{0}', String(10)),
      dataKey: 'up_to_10',
    },
    {
      label: t('Up to {0}').replace('{0}', String(20)),
      dataKey: 'up_to_20',
    },
    {
      label: t('Up to {0}').replace('{0}', String(50)),
      dataKey: 'up_to_50',
    },
    {
      label: t('Up to {0}').replace('{0}', String(100)),
      dataKey: 'up_to_100',
    },
    {
      label: t('100+'),
      dataKey: '100+',
    },
  ];

  const adminBillingSettings: any = useSelector((state: AppState) => state.adminParameters.billingSettings);
  const preparedBillingSettings =
    adminBillingSettings && Object.keys(adminBillingSettings).length ? adminBillingSettings : defaultBillingSettings;
  const preparedValue = (value: string | number): string => convertToString(convertCentsToDollar(value));
  const tablePropsPrices = mergeTwoObjects(
    {
      data: [
        {
          id: 'Accounts',
          service_number: t('Accounts'),
          additional_unit: '$' + preparedValue(preparedBillingSettings?.itemsPrices?.Accounts?.additionalUnit),
          up_to_10: '$' + preparedValue(preparedBillingSettings?.itemsPrices?.Accounts?.upTo10),
          up_to_20: '$' + preparedValue(preparedBillingSettings?.itemsPrices?.Accounts?.upTo20),
          up_to_50: '$' + preparedValue(preparedBillingSettings?.itemsPrices?.Accounts?.upTo50),
          up_to_100: '$' + preparedValue(preparedBillingSettings?.itemsPrices?.Accounts?.upTo100),
          '100+': '$' + preparedValue(preparedBillingSettings?.itemsPrices?.Accounts?.moreThan100),
        },
        {
          id: 'Users',
          service_number: t('Users'),
          additional_unit: '$' + preparedValue(preparedBillingSettings?.itemsPrices?.Users?.additionalUnit),
          up_to_10: '$' + preparedValue(preparedBillingSettings?.itemsPrices?.Users?.upTo10),
          up_to_20: '$' + preparedValue(preparedBillingSettings?.itemsPrices?.Users?.upTo20),
          up_to_50: '$' + preparedValue(preparedBillingSettings?.itemsPrices?.Users?.upTo50),
          up_to_100: '$' + preparedValue(preparedBillingSettings?.itemsPrices?.Users?.upTo100),
          '100+': '$' + preparedValue(preparedBillingSettings?.itemsPrices?.Users?.moreThan100),
        },
        {
          id: 'Server',
          service_number: t('Servers'),
          additional_unit: '$' + preparedValue(preparedBillingSettings?.itemsPrices?.Servers?.additionalUnit),
          up_to_10: '$' + preparedValue(preparedBillingSettings?.itemsPrices?.Servers?.upTo10),
          up_to_20: '$' + preparedValue(preparedBillingSettings?.itemsPrices?.Servers?.upTo20),
          up_to_50: '$' + preparedValue(preparedBillingSettings?.itemsPrices?.Servers?.upTo50),
          up_to_100: '$' + preparedValue(preparedBillingSettings?.itemsPrices?.Servers?.upTo100),
          '100+': '$' + preparedValue(preparedBillingSettings?.itemsPrices?.Servers?.moreThan100),
        },
        {
          id: 'Workflow step',
          service_number: t('Workflow steps'),
          additional_unit: '$' + preparedValue(preparedBillingSettings?.itemsPrices['Workflow Steps'].additionalUnit),
          up_to_10: '$' + preparedValue(preparedBillingSettings?.itemsPrices['Workflow Steps'].upTo10),
          up_to_20: '$' + preparedValue(preparedBillingSettings?.itemsPrices['Workflow Steps'].upTo20),
          up_to_50: '$' + preparedValue(preparedBillingSettings?.itemsPrices['Workflow Steps'].upTo50),
          up_to_100: '$' + preparedValue(preparedBillingSettings?.itemsPrices['Workflow Steps'].upTo100),
          '100+': '$' + preparedValue(preparedBillingSettings?.itemsPrices['Workflow Steps'].moreThan100),
        },
        // {
        //   id: 'Licence',
        //   service_number: t('Products'),
        //   additional_unit: '$' + preparedValue(preparedBillingSettings?.itemsPrices?.Licences?.additionalUnit),
        //   up_to_10: '$' + preparedValue(preparedBillingSettings?.itemsPrices?.Licences?.upTo10),
        //   up_to_20: '$' + preparedValue(preparedBillingSettings?.itemsPrices?.Licences?.upTo20),
        //   up_to_50: '$' + preparedValue(preparedBillingSettings?.itemsPrices?.Licences?.upTo50),
        //   up_to_100: '$' + preparedValue(preparedBillingSettings?.itemsPrices?.Licences?.upTo100),
        //   '100+': '$' + preparedValue(preparedBillingSettings?.itemsPrices?.Licences?.moreThan100),
        // },
      ],
      columns: COLUMNS__PRICES,
      // dateFormat: user?.timeFormat,
      tableLayout: 'auto' as const,
      stickyHeader: true,
      stickyHeaderOffsets: true,
    },
    {},
  ) as BasicTableProps;

  const renderPriceTable = () => {
    return (
      <div className={styles.tableWrapper} onClick={() => setIsShowPrice(false)}>
        <div className={cn(styles.tableContainer, styles.modalWrapper)}>
          <div className={styles.flexEnd}>
            <Icon name={'close'} />
          </div>
          <div className={styles.tableClose} onClick={() => setIsShowPrice(false)}>
            {t('Subscription prices description')}
          </div>
          <TableWrapper
            {...{
              isFullWidth: true,
            }}
          >
            <BasicTable
              {...tablePropsPrices}
              hasNoFilters={true}
              data={tablePropsPrices.data.filter((column: any) => {
                if (
                  !productType.serverProductType.serversMonitoring &&
                  (column?.id === 'Licence' || column?.id === 'Server')
                ) {
                  return false;
                }

                if (
                  !productType.serverProductType.workflowMonitoring &&
                  !productType.serverProductType.workflowReports &&
                  column?.id === 'Workflow step'
                ) {
                  return false;
                }

                return true;
              })}
              // TODO remove any
              applyChanges={() => {}}
              shouldApplyChangesOnMount={false}
            />
            <ul className={styles.subscriptionList}>
              <li>{t('Each account incudes one free user')}</li>
              <li>{t('Users unit is for the additional account and organization users')}</li>
              <li>{t('Overage fee will be invoiced for each unit over the subscription limit')}</li>
            </ul>
          </TableWrapper>
        </div>
      </div>
    );
  };

  const renderSummaryPriceBlock = ({ text, value }: Summary) => {
    const isAdditionalCharge = additionalCharge.isAdditionalCharge && !isCanceled && !isAbsoluteCancel;
    return (
      <div className={cn(styles.flex10, styles.iconWrapper, styles['mt2-svg'])}>
        {isAdditionalCharge ? null : <Icon name="money" />}
        <div className={cn(isAdditionalCharge ? styles.flexToggleContainer : '', styles['w100%'])}>
          <div className={cn(styles['fw--600'], styles['flex10'], styles.boldText)}>
            {isAdditionalCharge ? t('Total') : text}{' '}
            <span
              className={cn(styles['ml5'], styles.info, styles['cursorPointer'])}
              onClick={() => setIsShowPrice(true)}
            >
              <Icon name={'info'} />
            </span>
            {isShowPrice && renderPriceTable()}
          </div>
          <div className={cn(styles.lightText, styles['ws-nw'])}>${value}</div>
        </div>
      </div>
    );
  };

  const renderSummaryBlock = (props: Summary) => {
    return (
      <>
        {renderSummaryPriceBlock(props)}

        <div className={cn(styles.submitButton, styles.fullWidthButton)}>{renderButton()}</div>
      </>
    );
  };

  const renderSummaryBestPriceBlock = (props: Summary) => {
    return (
      <>
        {renderSummaryPriceBlock(props)}

        <div className={cn(styles.submitButton, styles.fullWidthButton)}>{renderMoreInfoButton()}</div>
      </>
    );
  };

  const getAdditionalPriceFromSubscription = ({
    currentSub,
    activeSub,
  }: {
    currentSub: string | undefined;
    activeSub: string | undefined;
  }) => {
    let price = '';

    if (currentSub === SubscriptionStatus.ANNUALLY) {
      if (typeof currentSub === 'string' && typeof activeSub === 'string') {
        price = toFixed_2(getPricesWithDiscount(12 * Number(toFixed_2(additionalCharge.pricesAdditionalCharge))));
      }
    }

    if (currentSub === SubscriptionStatus.MONTHLY) {
      if (typeof currentSub === 'string' && typeof activeSub === 'string') {
        price = toFixed_2(additionalCharge.pricesAdditionalCharge);
      }
    }

    return price;
  };

  const renderSummary = (props: Summary) => {
    return (
      <div
        className={cn(
          styles.summaryContainer,
          styles.isActiveSubscription,
          styles.summary,
          styles.greyBlock,
          additionalCharge.isAdditionalCharge && !isCanceled && !isAbsoluteCancel && styles.additionalChargeContainer,
          (additionalCharge.isAdditionalCharge || !isUpdateSubscription) &&
            !isCanceled &&
            !isAbsoluteCancel &&
            styles.summaryContainerWithAdditionalCharge,
        )}
      >
        {additionalCharge.isAdditionalCharge && !isCanceled && !isAbsoluteCancel && (
          <div>
            <div className={styles['flex--ai-c']}>
              {t('Additional Charge')}
              <Tooltip
                title={
                  'Upon subscription modification you might need to make an additional payment for the extra services. This payment is calculated based on the time you will use additional services in the current billing period. In the next billing period price for the additional services will be added to your monthly/annual charges'
                }
              >
                <IconButton className={styles.cursorPointer}>
                  <Icon name={'info'} />
                </IconButton>
              </Tooltip>
            </div>
            <div className={styles['flex--ai-c']}>
              $
              {getAdditionalPriceFromSubscription({
                currentSub: preparedSubscriptionPlan,
                activeSub: activeSubscriptionValue,
              })}
            </div>
          </div>
        )}
        {renderSummaryBlock(props)}
      </div>
    );
  };

  const renderSummaryBestPrice = (props: Summary) => {
    return (
      <div
        className={cn(
          styles.summaryContainer,
          styles.greyBlock,
          styles.isBestPriceSubscription,
          additionalCharge.isAdditionalCharge && !isCanceled && !isAbsoluteCancel && styles.additionalChargeContainer,
          (additionalCharge.isAdditionalCharge || !isUpdateSubscription) &&
            !isCanceled &&
            !isAbsoluteCancel &&
            styles.summaryContainerWithAdditionalCharge,
        )}
      >
        {additionalCharge.isAdditionalCharge && !isCanceled && !isAbsoluteCancel && (
          <div>
            <div className={styles['flex--ai-c']}>
              {t('Additional Charge')}
              <Tooltip
                title={
                  'Upon subscription modification you might need to make an additional payment for the extra services. This payment is calculated based on the time you will use additional services in the current billing period. In the next billing period price for the additional services will be added to your monthly/annual charges'
                }
              >
                <IconButton className={styles.cursorPointer}>
                  <Icon name={'info'} />
                </IconButton>
              </Tooltip>
            </div>

            <div>
              $
              {getAdditionalPriceFromSubscription({
                currentSub: preparedSubscriptionPlan,
                activeSub: activeSubscriptionValue,
              })}
            </div>
          </div>
        )}
        {renderSummaryBestPriceBlock(props)}
      </div>
    );
  };

  const renderSubscriptionMonthlyBlock = () => {
    return (
      <div className={cn(styles['flex10'], styles.summaryBlockItems)}>
        <div>
          <h2 className={styles['fs--30']}>{t('Summary')}</h2>

          {renderSummary({
            text: t('Monthly'),
            value: `${toFixed_2(sumSubscription)} / ${t('month')}`,
          })}
        </div>

        {!isAnnualSubscription && !isMonthlySubscription && (
          <div>
            <h2 className={styles['fs--30']}>{t('Best value')}</h2>

            {renderSummaryBestPrice({
              isAnnual: true,
              text: t('Annual'),
              value: `${toFixed_2(12 * getPricesWithDiscount(sumSubscription))} ($${toFixed_2(
                getPricesWithDiscount(sumSubscription),
              )}/${t('month')})`,
            })}
          </div>
        )}
      </div>
    );
  };

  const renderSubscriptionAnnualBlock = () => {
    return (
      <div className={cn(styles['flex10'], styles.summaryBlockItems)}>
        <div>
          <h2 className={styles['fs--30']}>{t('Summary')}</h2>

          {renderSummary({
            isAnnual: true,
            text: t('Annual'),
            value: `${toFixed_2(12 * getPricesWithDiscount(sumSubscription))} / ${t('year')}`,
          })}
        </div>
      </div>
    );
  };

  const renderSubscriptionTrialBlock = () => {
    if (
      !isShowSubscriptionButton &&
      !(subscriptionStatus === 'empty' && paymentStatus === PaymentStatus.FAIL_PAYMENT)
    ) {
      return '';
    }

    return <div className={cn(styles['mt30'], styles.flexEnd)}>{renderButton()}</div>;
  };

  const renderSubscriptionButtonsBlock = () => {
    if (!isShowSubscriptionButton && !(paymentStatus === PaymentStatus.BLOCKED && subscriptionStatus === 'empty')) {
      return '';
    }

    let value = '';

    if (activeSubscriptionValue === SubscriptionStatus.MONTHLY) {
      value = 'monthly';
    }

    if (activeSubscriptionValue === SubscriptionStatus.ANNUALLY) {
      value = 'annual';
    }

    if (activeSubscriptionValue === SubscriptionStatus.TRIAL && preparedSubscriptionPlan !== SubscriptionStatus.TRIAL) {
      value = 'trial';
    }

    switch (value) {
      case 'monthly':
        return renderSubscriptionMonthlyBlock();
      case 'annual':
        return renderSubscriptionAnnualBlock();
      case 'trial':
        return renderSubscriptionTrialBlock();
      case '':
      default:
        return '';
    }
  };

  // Payment blocks
  const renderPaymentCardFailed = ({ last4, exp_month, exp_year, key }: CardDetails) => (
    <div key={key} className={cn(styles.blockPaymentCard, styles['flex--jc-sb'])}>
      <div className={styles.cardInfo}>
        <span className={cn(styles.cardText)}>**** **** **** {last4}</span>
        <span className={styles.expirationBlock}>
          {t('Expiration date')}: <b>{`${exp_month}/${exp_year}`}</b>
        </span>
        <span className={cn(styles.expiresOn, styles.cardPaymentError, styles['mlr13'])}>
          <Icon name={'payment-error'} />
          <b>
            {overuseStatus === PaymentStatus.FAIL_PAYMENT && regularStatus !== PaymentStatus.FAIL_PAYMENT
              ? t('Overuse payment failed')
              : t('Payment Failed')}
          </b>
        </span>
      </div>
      <div className={styles.blockRetry}>
        <Button
          type="button"
          icon="reset"
          onClick={() => {
            dispatch(retryPaymentSubscription(setOpenModalFailPayment));
          }}
          className={cn(styles.blockSubscriptionExpiredRenew, styles['cursorPointer'], styles['mr15'])}
          labelKey={t('Retry')}
          emptyStyle
          xs
        />
        <div
          className={styles.h24}
          onClick={() => {
            setOpenModalModifyAttentionCard({
              isOpen: true,
              id: key,
              last4,
            });
          }}
        >
          <Icon name={'delete'} />
        </div>

        <ConfirmDeleteChanel
          open={openModalModifyAttentionCard.isOpen}
          onClose={(value: boolean) => {
            setOpenModalModifyAttentionCard({
              isOpen: false,
              id: '',
              last4: '',
            });

            if (value) {
              const deleteCard = async () => {
                dispatch(uILoadingStart());
                await deletePaymentMethod(openModalModifyAttentionCard.id);
                await dispatch(getCardsInfo());
              };

              deleteCard();
            }
          }}
          messageKey={`${t('Are you sure you want to delete your card')} ${openModalModifyAttentionCard.last4} ?`}
        />
      </div>
    </div>
  );

  const renderPaymentCardDefault = ({ last4, exp_month, exp_year, key }: CardDetails) => (
    <div key={key} className={cn(styles.blockPaymentCard, styles['flex--jc-sb'])}>
      <div className={styles.cardInfo}>
        <span className={styles.cardText}>**** **** **** {last4}</span>
        <span className={styles.expirationBlock}>
          {t('Expiration date')}: <b>{`${exp_month}/${exp_year}`}</b>
        </span>
      </div>
      <div
        className={styles.blockRetry}
        onClick={() => {
          setOpenModalModifyAttentionCard({
            isOpen: true,
            id: key,
            last4,
          });
        }}
      >
        <Icon name={'delete'} />
      </div>

      <ConfirmDeleteChanel
        open={openModalModifyAttentionCard.isOpen}
        onClose={(value: boolean) => {
          setOpenModalModifyAttentionCard({
            isOpen: false,
            id: '',
            last4: '',
          });

          if (value) {
            const deleteCard = async () => {
              dispatch(uILoadingStart());
              await deletePaymentMethod(openModalModifyAttentionCard.id);
              await dispatch(getCardsInfo());
            };

            deleteCard();
          }
        }}
        messageKey={`${t('Are you sure you want to delete your card')} ${openModalModifyAttentionCard.last4} ?`}
      />
    </div>
  );

  // Plans blocks

  const renderSubscriptionPlanDefault = ({ title, content, value, icon }: SubscriptionPlan) => (
    <TransparentBorder
      color={'#0F65E6'}
      isActive={activeSubscriptionValue && activeSubscriptionValue === value}
      onClick={() => handleActiveSubscription(value)}
    >
      <Icon name={icon} />
      <div
        style={{ pointerEvents: isPermissionFinanceOrganizationReadOnly ? 'none' : 'initial' }}
        className={cn(styles.blockSubscription)}
      >
        <h3 className={styles.blockSubscriptionTitle}>{t(title)}</h3>
        <p className={styles.blockSubscriptionText}>{t(content)}</p>
      </div>
    </TransparentBorder>
  );

  const renderSubscriptionPlanBestPrice = ({ title, content, value, icon }: SubscriptionPlan) => {
    return (
      <TransparentBorder
        color={'#0F65E6'}
        isActive={activeSubscriptionValue && activeSubscriptionValue === value}
        isShowShadow={false}
        onClick={() => handleActiveSubscription(value)}
      >
        <Icon name={icon} />
        <div className={cn(styles.bestValue)}>{t('Best value')}</div>
        <div className={styles.bestValueContainer}>
          <div
            style={{ pointerEvents: isPermissionFinanceOrganizationReadOnly ? 'none' : 'initial' }}
            className={cn(styles.blockSubscription, styles.isBestPriceSubscription)}
          >
            <h3 className={styles.blockSubscriptionTitle}>{t(title)}</h3>
            <p className={styles.blockSubscriptionText}>{t(content)}</p>
          </div>
        </div>
      </TransparentBorder>
    );
  };

  const renderSubscriptionPlanActive = ({ title, content, value, icon }: SubscriptionPlan) => (
    <TransparentBorder
      color={'#0F65E6'}
      isActive={activeSubscriptionValue && activeSubscriptionValue === value}
      onClick={() => handleActiveSubscription(value)}
    >
      <Icon name={icon} />
      <div className={cn(styles.blockSubscription, styles.blockSubscriptionActive, styles.blockSubscriptionContainer)}>
        <div>
          <div className={styles.alignCenterFlexBox}>
            <h3 className={styles.blockSubscriptionTitle}>{t(title)}</h3>
            <span className={styles.nextCharge}>
              <Icon name="done" color="#0F65E5" />
              {t('Next charge on')}:
              <b> {expiresSubscription && format(new Date(expiresSubscription || 0), 'dd/MM/yyyy')}</b>
            </span>
          </div>
          <p className={styles.blockSubscriptionText}>{t(content)}</p>
        </div>
      </div>
    </TransparentBorder>
  );

  const renderSubscriptionPlanExpired = ({ title, content, value, icon }: SubscriptionPlan) => (
    <TransparentBorder
      color={'#0F65E6'}
      isActive={activeSubscriptionValue && activeSubscriptionValue === value}
      onClick={() => handleActiveSubscription(value)}
    >
      <Icon name={icon} />
      <div
        className={cn(
          styles.blockSubscription,
          styles.blockSubscriptionActive,
          styles.blockSubscriptionContainer,
          styles.relative,
        )}
      >
        <div>
          <div className={styles.alignCenterFlexBox}>
            <h3 className={cn(styles.blockSubscriptionTitle)}>{t(title)}</h3>
            <span
              className={cn(
                styles.blockSubscriptionActiveRenews,
                styles.blockSubscriptionExpiredText,
                newPlan ? styles.newPlan : styles.expiresOn,
              )}
            >
              <Icon name={newPlan ? 'reset' : 'close'} color={newPlan ? '#C8870A' : '#CE3954'} />
              {newPlan ? `${t('Transition to')} ${newPlan} ${t('on')}` : t('Expires on')}:{' '}
              <b>{expiresSubscription && format(new Date(expiresSubscription || 0), 'dd/MM/yyyy')}</b>
            </span>
          </div>
          <p className={styles.blockSubscriptionText}>{t(content)}</p>
        </div>
        {isUpdateSubscription && isCanceled && (
          <div className={styles.absoluteButtonBlock}>
            <Button
              type="button"
              labelKey={newPlan ? t('Undo') : t('Renew')}
              icon={newPlan ? 'arrowLeft' : 'reset'}
              onClick={() => {
                dispatch(renewSubscriptionDetails());
              }}
              emptyStyle
              xs
            />
          </div>
        )}
      </div>
    </TransparentBorder>
  );

  const renderSubscriptionPlanFaildPayment = ({ title, content, value, icon }: SubscriptionPlan) => (
    <TransparentBorder
      color={'#0F65E6'}
      isActive={activeSubscriptionValue && activeSubscriptionValue === value}
      onClick={() => handleActiveSubscription(value)}
    >
      <Icon name={icon} />
      <div
        className={cn(
          styles.blockSubscription,
          styles.blockSubscriptionActive,
          styles.blockSubscriptionContainer,
          styles.relative,
        )}
      >
        <div>
          <div className={styles.alignCenterFlexBox}>
            <h3 className={cn(styles.blockSubscriptionTitle)}>{t(title)}</h3>
            <span
              className={cn(
                styles.blockSubscriptionActiveRenews,
                styles.blockSubscriptionExpiredText,
                styles.expiresOn,
              )}
            >
              <Icon name="close" color="#CE3954" />
              {overuseStatus === PaymentStatus.FAIL_PAYMENT && regularStatus !== PaymentStatus.FAIL_PAYMENT
                ? t('Overuse payment failed')
                : t('Payment Failed')}
            </span>
          </div>
          <p className={styles.blockSubscriptionText}>{t(content)}</p>
        </div>
        {isUpdateSubscription && isCanceled && (
          <div className={styles.absoluteButtonBlock}>
            <Button
              type="button"
              labelKey={t('Renew')}
              icon="reset"
              onClick={() => {
                dispatch(renewSubscriptionDetails());
              }}
              emptyStyle
              xs
            />
          </div>
        )}
      </div>
    </TransparentBorder>
  );

  const TRIAL_EXPIRED_LIMIT: TrialExpiredLimit = {
    // licences: {
    //   key: 'licences',
    //   isMaximumReached: itemsUsed?.Licences?.[0] >= itemsUsed?.Licences?.[1],
    //   label: t('Licences'),
    //   value: itemsUsed?.Licences?.[0],
    // },
    workflowSteps: {
      key: 'workflowSteps',
      isMaximumReached: itemsUsed?.['Workflow Steps'][0] >= itemsUsed?.['Workflow Steps'][1],
      label: t(`Workflow Steps`),
      value: itemsUsed?.['Workflow Steps'][0],
    },
    servers: {
      key: 'servers',
      isMaximumReached: itemsUsed?.Servers?.[0] >= itemsUsed?.Servers?.[1],
      label: t(`Servers`),
      value: itemsUsed?.Servers?.[0],
    },
    users: {
      key: 'users',
      isMaximumReached: itemsUsed?.Users?.[0] >= itemsUsed?.Users?.[1],
      label: t(`Users`),
      value: itemsUsed?.Users?.[0],
    },
  };

  if (isOrganization || isOrganizationUser) {
    TRIAL_EXPIRED_LIMIT.accounts = {
      key: 'accounts',
      isMaximumReached: itemsUsed?.Accounts?.[0] >= itemsUsed?.Accounts?.[1],
      label: t(`Accounts`),
      value: itemsUsed?.Accounts?.[0],
    };
  }

  const renderSubscriptionPlanTrialExpired = ({ title, content, value, icon }: SubscriptionPlan) => (
    <div>
      <TransparentBorder
        color={'#0F65E6'}
        isActive={activeSubscriptionValue && activeSubscriptionValue === value}
        onClick={() => handleActiveSubscription(value)}
      >
        <Icon name={icon} />
        <div>
          <div className={styles.alignCenterFlexBox}>
            <h3 className={cn(styles.blockSubscriptionTitle, styles['df--jc-sb'])}>{t(title)}</h3>
            <span className={styles.expiresOn}>
              <Icon name="close" color="#CE3954" />
              {t('Trial ended')}
            </span>
          </div>
          <p className={styles.blockSubscriptionText}>{t(content)}</p>
        </div>
      </TransparentBorder>
      <div className={styles.blockList}>
        {Object.values(TRIAL_EXPIRED_LIMIT)
          .filter((item) => {
            if (
              item.key === 'workflowSteps' &&
              !productType.serverProductType.workflowMonitoring &&
              !productType.serverProductType.workflowReports &&
              (activeSubscriptionValue === SubscriptionStatus.TRIAL ||
                activeSubscriptionValue === SubscriptionStatus.TRIALENDED)
            ) {
              return false;
            }

            if (
              (item.key === 'servers' || item.key === 'licences') &&
              !productType.serverProductType.serversMonitoring &&
              (activeSubscriptionValue === SubscriptionStatus.TRIAL ||
                activeSubscriptionValue === SubscriptionStatus.TRIALENDED)
            ) {
              return false;
            }

            return true;
          })
          .map((props: TrialExpiredLimitLabel) => (
            <TrialLimit {...props} key={props.key} />
          ))}
      </div>
    </div>
  );

  const renderSubscriptionPlanTrialActive = ({ title, content, value, icon }: SubscriptionPlan) => (
    <div>
      <TransparentBorder
        color={'#0F65E6'}
        isActive={activeSubscriptionValue && activeSubscriptionValue === value}
        onClick={() => handleActiveSubscription(value)}
      >
        <Icon name={icon} />
        <div>
          <div className={styles.alignCenterFlexBox}>
            <h3 className={cn(styles.blockSubscriptionTitle)}>{t(title)}</h3>
            <span className={styles.expiresOn}>
              <Icon name="close" color="#CE3954" />
              {t('Expires on')}: <b>{format(endOfMonth(new Date()), 'dd/MM/yyyy')}</b>
            </span>
          </div>

          <p className={styles.blockSubscriptionText}>{t(content)}</p>
        </div>
      </TransparentBorder>

      <div className={styles.blockList}>
        {Object.values(TRIAL_EXPIRED_LIMIT)
          .filter((item) => {
            if (
              item.key === 'workflowSteps' &&
              !productType.serverProductType.workflowMonitoring &&
              !productType.serverProductType.workflowReports &&
              (activeSubscriptionValue === SubscriptionStatus.TRIAL ||
                activeSubscriptionValue === SubscriptionStatus.TRIALENDED)
            ) {
              return false;
            }

            if (
              (item.key === 'servers' || item.key === 'licences') &&
              !productType.serverProductType.serversMonitoring &&
              (activeSubscriptionValue === SubscriptionStatus.TRIAL ||
                activeSubscriptionValue === SubscriptionStatus.TRIALENDED)
            ) {
              return false;
            }

            return true;
          })
          .map((props: TrialExpiredLimitLabel) => (
            <TrialLimit {...props} key={props.key} />
          ))}
      </div>
    </div>
  );

  const contentTrial = 'Try our services for free for 30 days period with no charge.';
  const contentTrialActive = 'Trial subscription is activated, you can enjoy these units for 1 month:';
  const contentTrialExpired =
    'Trial subscription has expired, please choose monthly or yearly subscription to continue enjoying the service.';
  const contentMonthly = 'Pay for the selected services on a monthly basis.';
  const contentMonthlyActive = 'Pay for the selected services on a monthly basis.';
  const contentMonthlyExpired = 'Pay for the selected services on a monthly basis.';
  const contentAnnual = t(`Pay for the services annually and save {0}% on charges.`).replace(
    '{0}',
    String(annualDiscount),
  );

  const renderSubscriptionPlansDefault = () => (
    <div className={cn(styles.blockRowGrid, styles['blockRowGrid--с3'])}>
      {!isOncePayed &&
        !(user?.isInOrganization && user?.paymentStatusOrganization?.payment === 'trial') &&
        renderSubscriptionPlanDefault({
          title: t('{0} month Trial').replace('{0}', '1'),
          content: contentTrial,
          value: SubscriptionStatus.TRIAL,
          icon: 'trial',
        })}
      {renderSubscriptionPlanDefault({
        title: 'Monthly Subscription',
        content: contentMonthly,
        value: SubscriptionStatus.MONTHLY,
        icon: 'monthly-subscription',
      })}
      {renderSubscriptionPlanBestPrice({
        title: 'Annual Subscription',
        content: contentAnnual,
        value: SubscriptionStatus.ANNUALLY,
        icon: 'annual-subscription',
      })}
    </div>
  );

  const renderSubscriptionPlansMonthlyActive = () => (
    <div className={cn(styles.blockRowGrid, styles['blockRowGrid--с2'])}>
      {renderSubscriptionPlanActive({
        title: 'Monthly Subscription',
        content: contentMonthlyExpired,
        value: SubscriptionStatus.MONTHLY,
        icon: 'monthly-subscription',
      })}
      {renderSubscriptionPlanBestPrice({
        title: 'Annual Subscription',
        content: contentAnnual,
        value: SubscriptionStatus.ANNUALLY,
        icon: 'annual-subscription',
      })}
    </div>
  );

  const renderSubscriptionPlansAnnualActive = () => (
    <div className={cn(styles.blockRowGrid, styles['blockRowGrid--с2'])}>
      {renderSubscriptionPlanDefault({
        title: 'Monthly Subscription',
        content: contentMonthlyExpired,
        value: SubscriptionStatus.MONTHLY,
        icon: 'monthly-subscription',
      })}
      {renderSubscriptionPlanActive({
        title: 'Annual Subscription',
        content: contentAnnual,
        value: SubscriptionStatus.ANNUALLY,
        icon: 'annual-subscription',
      })}
    </div>
  );

  const renderSubscriptionPlansMonthlyFailPayment = () => (
    <div className={cn(styles.blockRowGrid, styles['blockRowGrid--с2'])}>
      {renderSubscriptionPlanFaildPayment({
        title: 'Monthly Subscription',
        content: contentMonthlyActive,
        value: SubscriptionStatus.MONTHLY,
        icon: 'monthly-subscription',
      })}
      {renderSubscriptionPlanBestPrice({
        title: 'Annual Subscription',
        content: contentAnnual,
        value: SubscriptionStatus.ANNUALLY,
        icon: 'annual-subscription',
      })}
    </div>
  );

  const renderSubscriptionPlansMonthlyCanceled = () => (
    <div className={cn(styles.blockRowGrid, styles['blockRowGrid--с2'])}>
      {renderSubscriptionPlanExpired({
        title: `${t('Monthly Subscription')}`,
        content: contentMonthlyActive,
        value: SubscriptionStatus.MONTHLY,
        icon: 'monthly-subscription',
      })}
      {renderSubscriptionPlanBestPrice({
        title: 'Annual Subscription',
        content: contentAnnual,
        value: SubscriptionStatus.ANNUALLY,
        icon: 'annual-subscription',
      })}
    </div>
  );

  const renderSubscriptionPlansAnnualFailPayment = () => (
    <div className={cn(styles.blockRowGrid, styles['blockRowGrid--с2'])}>
      {renderSubscriptionPlanDefault({
        title: 'Monthly Subscription',
        content: contentMonthlyActive,
        value: SubscriptionStatus.MONTHLY,
        icon: 'monthly-subscription',
      })}
      {renderSubscriptionPlanFaildPayment({
        title: 'Annual Subscription',
        content: contentAnnual,
        value: SubscriptionStatus.ANNUALLY,
        icon: 'annual-subscription',
      })}
    </div>
  );

  const renderSubscriptionPlansAnnualCanceled = () => (
    <div className={cn(styles.blockRowGrid, styles['blockRowGrid--с2'])}>
      {renderSubscriptionPlanDefault({
        title: 'Monthly Subscription',
        content: contentMonthlyActive,
        value: SubscriptionStatus.MONTHLY,
        icon: 'monthly-subscription',
      })}
      {renderSubscriptionPlanExpired({
        title: `${t('Annual Subscription')}`,
        content: contentAnnual,
        value: SubscriptionStatus.ANNUALLY,
        icon: 'annual-subscription',
      })}
    </div>
  );

  const renderSubscriptionPlansTrialExpired = () => (
    <div className={cn(styles.blockRowGrid, styles['blockRowGrid--с3'], styles.alignTop)}>
      {renderSubscriptionPlanTrialExpired({
        title: t('{0} month Trial').replace('{0}', '1'),
        content: contentTrialExpired,
        value: SubscriptionStatus.TRIALENDED,
        icon: 'trial',
      })}
      {renderSubscriptionPlanDefault({
        title: 'Monthly Subscription',
        content: contentMonthly,
        value: SubscriptionStatus.MONTHLY,
        icon: 'monthly-subscription',
      })}
      {renderSubscriptionPlanBestPrice({
        title: 'Annual Subscription',
        content: contentAnnual,
        value: SubscriptionStatus.ANNUALLY,
        icon: 'annual-subscription',
      })}
    </div>
  );

  const renderSubscriptionPlansTrialActive = () => (
    <div className={cn(styles.blockRowGrid, styles['blockRowGrid--с3'], styles.alignTop)}>
      {renderSubscriptionPlanTrialActive({
        title: t('{0} month Trial').replace('{0}', '1'),
        content: contentTrialActive,
        value: SubscriptionStatus.TRIAL,
        icon: 'trial',
      })}
      {renderSubscriptionPlanDefault({
        title: 'Monthly Subscription',
        content: contentMonthly,
        value: SubscriptionStatus.MONTHLY,
        icon: 'monthly-subscription',
      })}
      {renderSubscriptionPlanBestPrice({
        title: 'Annual Subscription',
        content: contentAnnual,
        value: SubscriptionStatus.ANNUALLY,
        icon: 'annual-subscription',
      })}
    </div>
  );

  const renderSubscriptionPlans = () => {
    const getActualSubscriptionStatus = () => {
      if (
        (isMonthlySubscriptionPaymentFail && subscriptionStatus !== 'empty') ||
        (subscriptionPlan === SubscriptionStatus.MONTHLY && isBlockedInPastDue) ||
        (subscriptionPlan === SubscriptionStatus.MONTHLY && isBlockedInPastDueOveruse)
      ) {
        return 'FailPaymentMonthly';
      }

      if (
        (isAnnualSubscriptionPaymentFail && subscriptionStatus !== 'empty') ||
        (subscriptionPlan === SubscriptionStatus.ANNUALLY && isBlockedInPastDue) ||
        (subscriptionPlan === SubscriptionStatus.ANNUALLY && isBlockedInPastDueOveruse)
      ) {
        return 'FailPaymentAnnual';
      }

      if (isMonthlySubscriptionCancel) {
        return 'CanceledMonthly';
      }

      if (isAnnualSubscriptionCancel) {
        return 'CanceledAnnual';
      }

      if (isAbsoluteCancel) {
        return 'BlockedSubscription';
      }

      if (isTrialExpired) {
        return 'TrialExpired';
      }

      if (isTrial) {
        return 'TrialActive';
      }

      if (isMonthlySubscription) {
        return 'ActiveMonthly';
      }

      if (isAnnualSubscription) {
        return 'ActiveAnnual';
      }

      return '';
    };
    const planStatus: SubscriptionPlansRender = getActualSubscriptionStatus();

    switch (planStatus) {
      case 'TrialExpired':
        return renderSubscriptionPlansTrialExpired();
      case 'TrialActive':
        return renderSubscriptionPlansTrialActive();
      case 'CanceledMonthly':
        return renderSubscriptionPlansMonthlyCanceled();
      case 'CanceledAnnual':
        return renderSubscriptionPlansAnnualCanceled();
      case 'ActiveMonthly':
        return renderSubscriptionPlansMonthlyActive();
      case 'ActiveAnnual':
        return renderSubscriptionPlansAnnualActive();
      case 'FailPaymentMonthly':
        return renderSubscriptionPlansMonthlyFailPayment();
      case 'FailPaymentAnnual':
        return renderSubscriptionPlansAnnualFailPayment();
      case '':
      case 'BlockedSubscription':
      default:
        return renderSubscriptionPlansDefault();
    }
  };

  const isDisableFields = useMemo(
    () =>
      (isAbsoluteCancel &&
        !isBlockedInPastDue &&
        !isBlockedInPastDueOveruse &&
        preparedSubscriptionPlan === activeSubscriptionValue) ||
      isPermissionFinanceOrganizationReadOnly ||
      activeSubscriptionValue === SubscriptionStatus.TRIAL ||
      activeSubscriptionValue === SubscriptionStatus.TRIALENDED ||
      isMonthlySubscriptionPaymentFail ||
      isAnnualSubscriptionPaymentFail ||
      (subscriptionPlan === SubscriptionStatus.MONTHLY && isBlockedInPastDue) ||
      (subscriptionPlan === SubscriptionStatus.MONTHLY && isBlockedInPastDueOveruse) ||
      (subscriptionPlan === SubscriptionStatus.ANNUALLY && isBlockedInPastDue) ||
      (subscriptionPlan === SubscriptionStatus.ANNUALLY && isBlockedInPastDueOveruse) ||
      (isCanceled && preparedSubscriptionPlan === activeSubscriptionValue),
    [
      isAbsoluteCancel,
      isPermissionFinanceOrganizationReadOnly,
      activeSubscriptionValue,
      isMonthlySubscriptionPaymentFail,
      isAnnualSubscriptionPaymentFail,
      isCanceled,
      preparedSubscriptionPlan,
      subscriptionPlan,
      isBlockedInPastDue,
      isBlockedInPastDueOveruse,
    ],
  );
  const isDisableNumberOfServersField =
    isPermissionFinanceOrganizationReadOnly || isDisableFields || !getValues('serversMonitoring');
  const isDisableNumberOfWorkflowStepsField =
    isPermissionFinanceOrganizationReadOnly ||
    isDisableFields ||
    (!getValues('workflowMonitoring') && !getValues('workflowReporting'));

  const isShowSubscriptionButton = useMemo(
    () =>
      (!isCanceled || preparedSubscriptionPlan !== activeSubscriptionValue || !isAbsoluteCancel) &&
      !(subscriptionPlan === SubscriptionStatus.MONTHLY && isBlockedInPastDue) &&
      !(subscriptionPlan === SubscriptionStatus.MONTHLY && isBlockedInPastDueOveruse) &&
      !(subscriptionPlan === SubscriptionStatus.ANNUALLY && isBlockedInPastDue) &&
      !(subscriptionPlan === SubscriptionStatus.ANNUALLY && isBlockedInPastDueOveruse) &&
      paymentStatusFromState !== PaymentStatus.FAIL_PAYMENT,
    [
      isCanceled,
      preparedSubscriptionPlan,
      activeSubscriptionValue,
      isAbsoluteCancel,
      paymentStatusFromState,
      subscriptionPlan,
      isBlockedInPastDue,
      isBlockedInPastDueOveruse,
    ],
  );

  const areAllValuesZero = () => {
    const numberOfUsers = getValues('numberOfUsers');
    const numberOfAccounts = getValues('numberOfAccounts');
    const numberOfServers = getValues('numberOfServers');
    const numberOfProducts = getValues('numberOfProducts');
    const numberOfWorkflowSteps = getValues('numberOfWorkflowSteps');
    let isAllFieldsZero = false;

    if (isOrganization || isOrganizationUser) {
      if (
        convertToNumber(numberOfUsers) == 0 &&
        convertToNumber(numberOfServers) == 0 &&
        convertToNumber(numberOfProducts) == 0 &&
        convertToNumber(numberOfAccounts) == 0 &&
        convertToNumber(numberOfWorkflowSteps) == 0
      ) {
        isAllFieldsZero = true;
      }
    } else if (
      convertToNumber(numberOfUsers) == 0 &&
      convertToNumber(numberOfServers) == 0 &&
      convertToNumber(numberOfProducts) == 0 &&
      convertToNumber(numberOfWorkflowSteps) == 0
    ) {
      isAllFieldsZero = true;
    }
    return isAllFieldsZero;
  };

  const getValuesFromFields = () => {
    const numberOfUsers = getValues('numberOfUsers');
    const numberOfAccounts = getValues('numberOfAccounts');
    const numberOfServers = getValues('numberOfServers');
    const numberOfProducts = getValues('numberOfProducts');
    const numberOfWorkflowSteps = getValues('numberOfWorkflowSteps');

    const isEmptyString = (value: string | number) => typeof value === 'string' && value.length === 0;

    return (
      areAllValuesZero() ||
      isEmptyString(numberOfUsers) ||
      isEmptyString(numberOfServers) ||
      isEmptyString(numberOfProducts) ||
      isEmptyString(numberOfAccounts) ||
      isEmptyString(numberOfWorkflowSteps) ||
      (numberOfUsers == convertToNumber(productQuantitySubscriptionUsers) &&
        numberOfServers == convertToNumber(productQuantitySubscriptionServers) &&
        numberOfProducts == convertToNumber(productQuantitySubscriptionLicences) &&
        numberOfWorkflowSteps == convertToNumber(productQuantitySubscriptionWorkflowSteps) &&
        (isOrganization || isOrganizationUser
          ? numberOfAccounts == convertToNumber(productQuantitySubscriptionAccounts)
          : true))
    );
  };
  const getValuesFromToggles = () => {
    if (
      toggleSubscriptionWorkflowMonitoring === undefined &&
      toggleSubscriptionWorkflowReports === undefined &&
      toggleSubscriptionServersMonitoring === undefined
    ) {
      return true;
    }

    return (
      getValues('workflowMonitoring') === convertToBoolean(toggleSubscriptionWorkflowMonitoring) &&
      getValues('workflowReporting') === convertToBoolean(toggleSubscriptionWorkflowReports) &&
      getValues('serversMonitoring') === convertToBoolean(toggleSubscriptionServersMonitoring)
    );
  };

  const getActualValuesForButtonModifyState = () => {
    if (
      (typeof preparedSubscriptionPlan === 'string' &&
        typeof activeSubscriptionValue === 'string' &&
        !isCanceled &&
        preparedSubscriptionPlan !== activeSubscriptionValue &&
        customerIntents &&
        Object.keys(customerIntents).length > 0 &&
        !areAllValuesZero()) ||
      isAbsoluteCancel
    ) {
      return false;
    }

    return (
      isAnnualSubscriptionPaymentFail ||
      isMonthlySubscriptionPaymentFail ||
      (subscriptionPlan === SubscriptionStatus.MONTHLY && isBlockedInPastDue) ||
      (subscriptionPlan === SubscriptionStatus.MONTHLY && isBlockedInPastDueOveruse) ||
      (subscriptionPlan === SubscriptionStatus.ANNUALLY && isBlockedInPastDue) ||
      (subscriptionPlan === SubscriptionStatus.ANNUALLY && isBlockedInPastDueOveruse) ||
      (!customerIntents && activeSubscriptionValue !== SubscriptionStatus.TRIAL) ||
      // (customerIntents &&
      //   Object.keys(customerIntents).length === 0 &&
      //   activeSubscriptionValue !== SubscriptionStatus.TRIAL) ||
      (preparedSubscriptionPlan === SubscriptionStatus.TRIALENDED &&
        activeSubscriptionValue === SubscriptionStatus.TRIALENDED) ||
      (preparedSubscriptionPlan === SubscriptionStatus.TRIAL && activeSubscriptionValue === SubscriptionStatus.TRIAL) ||
      !activeSubscriptionValue ||
      (getValuesFromFields() && getValuesFromToggles())
    );
  };
  const [isDisableButtonModify, setIsDisableButtonModify] = useState(getActualValuesForButtonModifyState());
  const handleIsDisableButtonModify = () => setIsDisableButtonModify(getActualValuesForButtonModifyState());

  const getActualValuesForAdditionalCharge = () => getValuesFromFields();
  const [additionalCharge, setIsAdditionalCharge] = useState({
    isAdditionalCharge: getActualValuesForAdditionalCharge(),
    pricesAdditionalCharge: 0,
  });

  const handleIsAdditionalCharge = () => {
    const numberOfUsers = convertToNumber(getValues('numberOfUsers'));
    const convertUsers = convertToNumber(productQuantitySubscriptionUsers);

    const numberOfServers = convertToNumber(getValues('numberOfServers'));
    const convertServers = convertToNumber(productQuantitySubscriptionServers);

    const numberOfProducts = convertToNumber(getValues('numberOfProducts'));
    const convertProducts = convertToNumber(productQuantitySubscriptionLicences);

    const numberOfWorkflowSteps = convertToNumber(getValues('numberOfWorkflowSteps'));
    const convertWorkflowSteps = convertToNumber(productQuantitySubscriptionWorkflowSteps);

    const numberOfAccounts = convertToNumber(getValues('numberOfAccounts'));
    const convertAccounts = convertToNumber(productQuantitySubscriptionAccounts);

    const pricesAdditionalCharge = {
      numberOfUsers: calculateActualUnitsWithTime({
        boughtUnits: convertUsers,
        mixedUnits: numberOfUsers,
        product: 'Users',
      }),
      numberOfProducts: calculateActualUnitsWithTime({
        boughtUnits: convertProducts,
        mixedUnits: numberOfProducts,
        product: 'Licences',
      }),
      numberOfWorkflowSteps: calculateActualUnitsWithTime({
        boughtUnits: convertWorkflowSteps,
        mixedUnits: numberOfWorkflowSteps,
        product: 'Workflow Steps',
      }),
      numberOfAccounts: calculateActualUnitsWithTime({
        boughtUnits: convertAccounts,
        mixedUnits: numberOfAccounts,
        product: 'Accounts',
      }),
      numberOfServers: calculateActualUnitsWithTime({
        boughtUnits: convertServers,
        mixedUnits: numberOfServers,
        product: 'Servers',
      }),
    };

    const additionalCharge = Object.values(pricesAdditionalCharge).reduce(
      (a, b) => (isNaN(a) || isNaN(b) ? a : a + b),
      0,
    );

    const isAdditionalCharge = !!additionalCharge && additionalCharge > 0;

    !isAdditionalCharge && clearTimeout(intervalID);

    setIsAdditionalCharge({
      isAdditionalCharge,
      pricesAdditionalCharge: additionalCharge,
    });
  };

  const getActualValidationForButtonModifyState = () => {
    return (
      convertToNumber(getValues('numberOfUsers')) === 0 &&
      convertToNumber(getValues('numberOfServers')) === 0 &&
      convertToNumber(getValues('numberOfProducts')) === 0 &&
      convertToNumber(getValues('numberOfWorkflowSteps')) === 0 &&
      convertToNumber(getValues('numberOfAccounts')) === 0
    );
  };

  const [isValidationButtonModify, setIsValidationButtonModify] = useState(getActualValidationForButtonModifyState());
  const handleIsValidationButtonModify = () => setIsValidationButtonModify(getActualValidationForButtonModifyState());

  const handleWorkflowStepsFieldFromToggles = () => {
    if (!getValues('workflowMonitoring') && !getValues('workflowReporting')) {
      setWorkflowReportField(getValues('numberOfWorkflowSteps'));
      setValue('numberOfWorkflowSteps', 0);

      handleIsDisableButtonModify();

      handleSumSubscription();
    } else {
      setValue('numberOfWorkflowSteps', workflowReportField);

      handleIsDisableButtonModify();

      handleSumSubscription();
    }
  };

  const handleServersFieldFromToggles = () => {
    if (!getValues('serversMonitoring')) {
      setProductField(getValues('numberOfProducts'));
      setServersField(getValues('numberOfServers'));
      setValue('numberOfProducts', 0);
      setValue('numberOfServers', 0);

      handleSumSubscription();
    } else {
      setValue('numberOfProducts', productField);
      setValue('numberOfServers', serversField);

      handleSumSubscription();
    }
  };

  useEffect(() => {
    if (isUpdateSubscription) {
      setValue(
        'workflowMonitoring',
        toggleSubscriptionWorkflowMonitoring ?? productType.serverProductType.workflowMonitoring,
      );
      setValue('workflowReporting', toggleSubscriptionWorkflowReports ?? productType.serverProductType.workflowReports);
      setValue(
        'serversMonitoring',
        toggleSubscriptionServersMonitoring ?? productType.serverProductType.serversMonitoring,
      );
      setValue('numberOfUsers', productQuantitySubscriptionUsers ?? 0);
      setValue('numberOfServers', productQuantitySubscriptionServers ?? 0);
      setValue('numberOfProducts', productQuantitySubscriptionLicences ?? 0);
      setValue('numberOfWorkflowSteps', productQuantitySubscriptionWorkflowSteps ?? 0);

      if (isOrganization || isOrganizationUser) {
        setValue('numberOfAccounts', productQuantitySubscriptionAccounts ?? 0);
      }

      setProductField(productQuantitySubscriptionLicences ?? 0);
      setServersField(productQuantitySubscriptionServers ?? 0);

      handleIsDisableButtonModify();
      handleIsValidationButtonModify();
      handleSumSubscription();
      handleIsAdditionalCharge();
    } else if (isTrial || isTrialExpired) {
      setValue(
        'workflowMonitoring',
        toggleSubscriptionWorkflowMonitoring ?? productType.serverProductType.workflowMonitoring,
      );
      setValue('workflowReporting', toggleSubscriptionWorkflowReports ?? productType.serverProductType.workflowReports);
      setValue(
        'serversMonitoring',
        toggleSubscriptionServersMonitoring ?? productType.serverProductType.serversMonitoring,
      );
      setValue('numberOfUsers', itemsUsed?.Users[1] ?? 0);
      setValue('numberOfServers', itemsUsed?.Servers[1] ?? 0);
      setValue('numberOfProducts', itemsUsed?.Licences[1] ?? 0);
      setValue('numberOfWorkflowSteps', itemsUsed?.['Workflow Steps'][1] ?? 0);

      if (isOrganization || isOrganizationUser) {
        setValue('numberOfAccounts', itemsUsed?.Accounts[1] ?? 0);
      }

      setProductField(itemsUsed?.Licences[1] ?? 0);
      setServersField(itemsUsed?.Servers[1] ?? 0);

      handleIsDisableButtonModify();
      handleIsAdditionalCharge();
    } else {
      setValuesDefault();
      handleIsValidationButtonModify();
    }
  }, [reset, setValue, user, subscription, isUpdateSubscription, isTrial, isTrialExpired]);

  useEffect(() => {
    handleIsDisableButtonModify();
  }, [activeSubscriptionValue, customerIntents]);

  useEffect(() => {
    handleActiveSubscription(preparedSubscriptionPlan);
  }, [preparedSubscriptionPlan]);

  useEffect(() => {
    dispatch(getSubscriptionDetails());
  }, []);

  useEffect(() => {
    if (errorMessage === 'Card not found') {
      dispatch(
        customerIsNotConnectedPaymentCardSet({
          error: CardError.DECLINED,
          toggle: true,
        }),
      );
    }
  }, [errorMessage]);

  // set default value for form payment
  useEffect(() => {
    const getCardsInfoFromApi = async () => {
      await dispatch(getCardsInfo());
    };

    getCardsInfoFromApi();
  }, []);

  useEffect(() => {
    dispatch(getAdminBillingSettings());
  }, []);

  useEffect(() => {
    handleSumSubscription();
  }, [itemsPrices]);

  // Form submit
  const onSubmit = async (data: any): Promise<any> => {
    const toggles = {
      serversMonitoring: convertToBoolean(data.serversMonitoring),
      workflowReports: convertToBoolean(data.workflowReporting),
      workflowMonitoring: convertToBoolean(data.workflowMonitoring),
    };
    const preparedData = {
      plan: activeSubscriptionValue,
      products: {
        Servers: convertToNumber(data.numberOfServers),
        ['Workflow Steps']: convertToNumber(data.numberOfWorkflowSteps),
        Licences: 0,
        Accounts: convertToNumber(data.numberOfAccounts),
        Users: convertToNumber(data.numberOfUsers),
      },
      toggles: {
        workflowMonitoring: toggles.workflowMonitoring,
        workflowReports: toggles.workflowReports,
        serversMonitoring: toggles.serversMonitoring,
      },
    };

    if (activeSubscriptionValue === SubscriptionStatus.TRIAL) {
      dispatch(
        startTrialAction({
          startData: new Date().toISOString(),
        }),
      );
    } else {
      isUpdateSubscription
        ? dispatch(updateSubscriptionDetails(preparedData, Object.keys(customerIntents || {}).length))
        : dispatch(createSubscriptionDetails(preparedData, setOpenModalFailPayment));
    }
  };

  useEffect(() => {
    return () => clearInterval(intervalID);
  }, []);

  useEffect(() => {
    handleSumSubscription();
  }, [
    watch('numberOfUsers'),
    watch('numberOfAccounts'),
    watch('numberOfServers'),
    watch('numberOfWorkflowSteps'),
    watch('numberOfProducts'),
  ]);

  const onSubscriptionFirstCardReject = () => {
    dispatch(uILoadingStart());
    dispatch(setSubscriptionFirstCardReject(false));
  };

  return (
    <div className={styles.OrganizationAccountParameters}>
      {!!user && (
        <>
          <Header innerHeader={t('Product Settings')} />
          <PageWithDrawer>
            <div className={cn(styles.formWrapper, styles.cardWithoutButtons)}>
              <div className={styles['blocksWrapper--w1400']}>
                <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                  <div className={cn(styles.blockContainer, styles['w100%'])}>
                    <div className={styles.blockHeader}>
                      <span>{t('Your Subscription Plan')}</span>
                      {isUpdateSubscription && !isCanceled && (!isTrialExpired || !isTrial) && (
                        <Button
                          type="button"
                          onClick={() => {
                            setOpenModalCancelSubscription(true);
                          }}
                          labelKey={t('Cancel Subscription')}
                          attentionStyle
                        />
                      )}

                      <ConfirmDeleteChanel
                        open={openModalCancelSubscription}
                        onClose={(value: boolean) => {
                          const asyncCancelSubscription = async () => {
                            if (value) {
                              await dispatch(cancelSubscriptionDetails());

                              // location.reload();
                            }

                            setOpenModalCancelSubscription(false);
                          };

                          asyncCancelSubscription();
                        }}
                        messageKey={'Subscription will be cancelled'}
                      />
                    </div>

                    {renderSubscriptionPlans()}
                  </div>

                  <div className={cn(styles.blockContainer, styles['w100%'])}>
                    <div className={styles.blockHeader}>
                      <span></span>
                    </div>
                    <div className={cn(styles.fourColumnRow, styles['blockRowGrid--с3'])}>
                      <InputText
                        {...register('numberOfUsers', {
                          onChange: () => {
                            handleIsDisableButtonModify();
                            handleIsValidationButtonModify();
                            handleSumSubscription();
                            handleIsAdditionalCharge();

                            clearInterval(intervalID);
                            intervalID = setInterval(handleIsAdditionalCharge, 10000);
                          },
                        })}
                        type="text"
                        tooltipHover={{
                          text: t('Additional account and organization users'),
                          isShow: true,
                        }}
                        maxLength={MAX_LENGTH}
                        labelKey={labels.numberOfUsers}
                        disabled={isDisableFields}
                        error={!!errors[`numberOfUsers`] ? errors[`numberOfUsers`].message : undefined}
                      />
                      {(isOrganization || isOrganizationUser) && (
                        <InputText
                          {...register('numberOfAccounts', {
                            onChange: () => {
                              handleIsDisableButtonModify();
                              handleIsValidationButtonModify();
                              handleSumSubscription();
                              handleIsAdditionalCharge();

                              clearInterval(intervalID);
                              intervalID = setInterval(handleIsAdditionalCharge, 1000);
                            },
                          })}
                          type="text"
                          tooltipHover={{
                            text: t('Each account incudes one free user'),
                            isShow: true,
                          }}
                          maxLength={MAX_LENGTH}
                          labelKey={labels.numberOfAccounts}
                          disabled={isDisableFields}
                          error={!!errors[`numberOfAccounts`] ? errors[`numberOfAccounts`].message : undefined}
                        />
                      )}
                      {/* {productType.serverProductType.serversMonitoring &&
                        !(
                          !productType.serverProductType.serversMonitoring &&
                          (activeSubscriptionValue === SubscriptionStatus.TRIAL ||
                            activeSubscriptionValue === SubscriptionStatus.TRIALENDED)
                        ) && (
                          <InputText
                            {...register('numberOfProducts', {
                              onChange: () => {
                                handleIsDisableButtonModify();
                                handleIsValidationButtonModify();
                                handleSumSubscription();
                                handleIsAdditionalCharge();

                                clearInterval(intervalID);
                                intervalID = setInterval(handleIsAdditionalCharge, 1000);
                              },
                            })}
                            type="text"
                            maxLength={MAX_LENGTH}
                            labelKey={labels.numberOfProducts}
                            disabled={isDisableNumberOfServersField}
                            error={!!errors[`numberOfProducts`] ? errors[`numberOfProducts`].message : undefined}
                          />
                        )} */}

                      {productType.serverProductType.workflowMonitoring &&
                        productType.serverProductType.workflowReports &&
                        !(
                          !productType.serverProductType.workflowMonitoring &&
                          !productType.serverProductType.workflowReports &&
                          (activeSubscriptionValue === SubscriptionStatus.TRIAL ||
                            activeSubscriptionValue === SubscriptionStatus.TRIALENDED)
                        ) && (
                          <InputText
                            {...register('numberOfWorkflowSteps', {
                              onChange: () => {
                                handleIsDisableButtonModify();
                                handleIsValidationButtonModify();
                                handleSumSubscription();
                                handleIsAdditionalCharge();

                                setWorkflowReportField(getValues('numberOfWorkflowSteps'));

                                clearInterval(intervalID);
                                intervalID = setInterval(handleIsAdditionalCharge, 1000);
                              },
                            })}
                            type="text"
                            maxLength={MAX_LENGTH}
                            labelKey={labels.numberOfWorkflowSteps}
                            disabled={isDisableNumberOfWorkflowStepsField}
                            error={
                              !!errors[`numberOfWorkflowSteps`] ? errors[`numberOfWorkflowSteps`].message : undefined
                            }
                          />
                        )}
                      {productType.serverProductType.serversMonitoring &&
                        !(
                          !productType.serverProductType.serversMonitoring &&
                          (activeSubscriptionValue === SubscriptionStatus.TRIAL ||
                            activeSubscriptionValue === SubscriptionStatus.TRIALENDED)
                        ) && (
                          <InputText
                            {...register('numberOfServers', {
                              onChange: () => {
                                handleIsDisableButtonModify();
                                handleIsValidationButtonModify();
                                handleSumSubscription();
                                handleIsAdditionalCharge();

                                clearInterval(intervalID);
                                intervalID = setInterval(handleIsAdditionalCharge, 1000);
                              },
                            })}
                            type="text"
                            maxLength={MAX_LENGTH}
                            labelKey={labels.numberOfServers}
                            disabled={isDisableNumberOfServersField}
                            error={!!errors[`numberOfServers`] ? errors[`numberOfServers`].message : undefined}
                          />
                        )}
                    </div>

                    <div className={cn(styles.blockRowGrid, styles.flex, styles['m27-0'])}>
                      {productType.serverProductType.workflowMonitoring &&
                        productType.companyProductType.workflowMonitoring && (
                          <ToggleSwitch
                            noMargin
                            labelKey={labels.workflowMonitoring}
                            checked={watchWorkflowMonitoring}
                            disabled={isDisableFields}
                            {...register('workflowMonitoring', {
                              onChange: () => {
                                handleIsDisableButtonModify();
                                handleWorkflowStepsFieldFromToggles();

                                clearInterval(intervalID);
                                intervalID = setInterval(handleIsAdditionalCharge, 1000);
                              },
                            })}
                          />
                        )}

                      {productType.serverProductType.workflowReports && productType.companyProductType.workflowReports && (
                        <ToggleSwitch
                          noMargin
                          labelKey={labels.workflowReporting}
                          checked={watchWorkflowReporting}
                          disabled={isDisableFields}
                          {...register('workflowReporting', {
                            onChange: () => {
                              handleIsDisableButtonModify();
                              handleWorkflowStepsFieldFromToggles();

                              clearInterval(intervalID);
                              intervalID = setInterval(handleIsAdditionalCharge, 1000);
                            },
                          })}
                        />
                      )}

                      {productType.serverProductType.serversMonitoring &&
                        productType.companyProductType.serversMonitoring && (
                          <ToggleSwitch
                            noMargin
                            labelKey={labels.serversMonitoring}
                            checked={watchServersMonitoring}
                            disabled={isDisableFields}
                            {...register('serversMonitoring', {
                              onChange: () => {
                                handleIsDisableButtonModify();
                                handleServersFieldFromToggles();

                                clearInterval(intervalID);
                                intervalID = setInterval(handleIsAdditionalCharge, 1000);
                              },
                            })}
                          />
                        )}
                    </div>

                    {renderSubscriptionButtonsBlock()}

                    <ConfirmDeleteChanel
                      open={openModalModifyAttention}
                      onClose={(value: boolean) => {
                        if (value) {
                          const button = document.getElementById('modify');

                          button?.setAttribute('type', 'submit');
                          button?.click();
                          button?.setAttribute('type', 'button');
                        }

                        setOpenModalModifyAttention(false);
                      }}
                      messageKey={
                        additionalCharge.isAdditionalCharge
                          ? `${t('Additional charge of {0} will be applied.')} ${t(
                              'Are you sure you want to modify your subscription?',
                            )}`.replace(
                              '{0}',
                              getAdditionalPriceFromSubscription({
                                currentSub: preparedSubscriptionPlan,
                                activeSub: activeSubscriptionValue,
                              }),
                            )
                          : t('Are you sure you want to modify your subscription?')
                      }
                    />

                    <SuccessModal
                      open={subscriptionUpdatedSuccessful}
                      onClose={() => {
                        dispatch(setSubscriptionUpdatedSuccessful(false));
                        location.reload();
                      }}
                      messageKey={'Subscription was successfully modified.'}
                    />
                    <SuccessModal
                      open={subscriptionUpdatedReject}
                      onClose={() => {
                        dispatch(setSubscriptionUpdatedReject(false));
                        location.reload();
                      }}
                      messageKey={
                        'Modification failed due to payment decline. Please check if your card is valid or has sufficient funds for the transactions'
                      }
                    />
                  </div>
                </form>

                <div className={cn(styles.blockContainer, styles['w100%'])}>
                  <div className={cn(styles.blockHeader, styles.minWidth)}>
                    <span>{t('Payment Option')}</span>

                    <div className={cn(styles['flex--jc-sb'], styles['al-fe'])}>
                      <div className={styles.buttonPayment}>
                        <Button
                          disabled={isPermissionFinanceOrganizationReadOnly}
                          type="button"
                          icon="simple-add"
                          labelKey={t('Add Card')}
                          className={cn(
                            (customerIntents && Object.keys(customerIntents).length === 2) ||
                              (isPermissionFinanceOrganizationReadOnly && styles.disabledButton),
                          )}
                          onClick={() => setOpenModal(true)}
                        />
                        {customerIntents && Object.keys(customerIntents).length === 2 && (
                          <Tooltip title={'To add a new card please remove existing one'}>
                            <div className={styles.tooltipAddCard} />
                          </Tooltip>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className={styles.paymentDescription}>
                    <Icon name="info" color="#0F65E5" />
                    {t('We charge payment from the first added card.')}
                  </div>
                  {customerIntents && Object.keys(customerIntents).length > 0 && (
                    <div>
                      {Object.entries(customerIntents).map(([key, value]: any, index: number) => {
                        if (
                          (paymentStatus === PaymentStatus.FAIL_PAYMENT ||
                            (subscriptionPlan === SubscriptionStatus.MONTHLY && isBlockedInPastDue) ||
                            (subscriptionPlan === SubscriptionStatus.MONTHLY && isBlockedInPastDueOveruse) ||
                            (subscriptionPlan === SubscriptionStatus.ANNUALLY && isBlockedInPastDue) ||
                            (subscriptionPlan === SubscriptionStatus.ANNUALLY && isBlockedInPastDueOveruse)) &&
                          (index === 0 || key === failInvoiceId)
                        ) {
                          return renderPaymentCardFailed({ ...value, key });
                        }
                        return renderPaymentCardDefault({ ...value, key });
                      })}
                    </div>
                  )}
                </div>

                <PaymentModal open={openModal} onClose={setOpenModal}>
                  <StripeForm onCloseModal={setOpenModal} />
                </PaymentModal>

                <MessageModalWithCloseButton
                  captionKey={
                    isNotConnectedPaymentCard?.error === CardError.INCOMLPLETE_NUMBER
                      ? 'The payment card is not added'
                      : undefined
                  }
                  messageKey={
                    isNotConnectedPaymentCard?.error === CardError.INCOMLPLETE_NUMBER
                      ? 'Please check your card details or try another card'
                      : undefined
                  }
                  open={!!isNotConnectedPaymentCard?.toggle}
                  onClose={() =>
                    dispatch(
                      customerIsNotConnectedPaymentCardSet({
                        error: CardError.EMPTY,
                        toggle: false,
                      }),
                    )
                  }
                />

                <MessageModalWithCloseButton
                  captionKey={'Validation error'}
                  messageKey={'At least one of the units field must contain a non-zero value'}
                  open={openModalSubmitZeroValues}
                  onClose={() => {
                    setOpenModalSubmitZeroValues(false);
                  }}
                />

                <InfoModal
                  open={openModalFailPayment}
                  onClose={() => {
                    setOpenModalFailPayment(false);
                    location.reload();
                  }}
                  messageKey={
                    'Payment failed. Please check if your card is valid or has sufficient funds for the transaction'
                  }
                />
                <InfoModal
                  open={subscriptionFirstCardReject}
                  onClose={onSubscriptionFirstCardReject}
                  messageKey={t('Payment from the main card has failed. Charge from the second card is in progress')}
                />
              </div>
            </div>
          </PageWithDrawer>
        </>
      )}
    </div>
  );
};

export default ProductSettings;
