import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  DisableProtectionModal: {
    display: 'flex',
    flexDirection: 'column',
    width: '508px',
    padding: '72px 24px 40px',
    alignItems: 'center',
    backgroundColor: theme.palette.background.paper,
    position: 'relative',
  },
  DisableProtectionModalLarge: {
    width: '32rem',
  },
  DisableProtectionModalMessage: {
    width: '32rem',
    padding: '3rem 3rem 1rem 3rem',
  },
  infoModal: {
    display: 'flex',
    flexDirection: 'column',
    width: '24rem',
    padding: '2rem 3rem 2rem 3rem',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: theme.palette.background.paper,
  },
  confirmPurchaseCard: {
    display: 'flex',
    flexDirection: 'column',
    width: '24rem',
    padding: '32px',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: theme.palette.background.paper,
  },
  modalHeader: {
    fontSize: 30,
    lineHeight: '40px',
    marginBottom: 16,
    color: '#212121',
    fontWeight: 700,
    textAlign: 'center',
  },
  modalMessage: {
    color: '#666666',
    textAlign: 'center',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '17px',
    whiteSpace: 'break-spaces',
    marginBottom: 32,
  },
  modalInfoMessage: {
    margin: '1rem 0',
    color: '#666666',
    textAlign: 'center',
    fontSize: '1.06rem',
    fontWeight: 400,
  },
  buttonsWrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    gap: 24,
    '& button': {
      width: 180,
    },
  },
  modalCLose: {
    position: 'absolute',
    right: 0,
    top: 0,
    padding: '15px',
    cursor: 'pointer',
  },
  disableButton: {
    background: theme.palette.grey[700],
    pointerEvents: 'none',

    '&:hover': {
      background: theme.palette.grey[700],
    },
  },
  beforeSubscriptionWrapper: {
    position: 'fixed',
    width: '100vw',
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: 'rgba(242, 242, 242, 0.6)',
    backdropFilter: 'blur(5px)',
    zIndex: 10,
    '& > div': {
      width: '30rem',
      transform: 'translateY(-80px)',
    },
  },
  modalHeaderCenter: {
    textAlign: 'center',
  },
  closeButton: {
    position: 'absolute',
    top: 30,
    right: 30,
  },
  // Atomic css
  ['jc-fs']: {
    justifyContent: 'flex-start',
  },
  ['mt10']: {
    marginTop: '10px',
  },
}));
