export enum SessionActionTypes {
  SESSION_LOADING_START = 'SESSION_LOADING_START',
  SESSION_LOADING_FINISH = 'SESSION_LOADING_FINISH',
  CHANGE_REMEMBER_ME_STATUS = 'CHANGE_REMEMBER_ME_STATUS',
  SET_TOKENS = 'SET_TOKENS',
  SET_LANGUAGE = 'SET_LANGUAGE',
  SET_NEW_ACCESS_TOKEN = 'SET_NEW_ACCESS_TOKEN',
  BOOTSTRAP_START = 'BOOTSTRAP_START',
  BOOTSTRAP_SUCCESS = 'BOOTSTRAP_SUCCESS',
  BOOTSTRAP_REJECT = 'BOOTSTRAP_REJECT',
  BOOTSTRAP_FAIL = 'BOOTSTRAP_FAIL',
  GET_ACCESS_TOKEN_START = 'GET_ACCESS_TOKEN_START',
  GET_ACCESS_TOKEN_SUCCESS = 'GET_ACCESS_TOKEN_SUCCESS',
  GET_ACCESS_TOKEN_FAIL = 'GET_ACCESS_TOKEN_FAIL',
  SIGN_UP_START = 'SIGN_UP_START',
  SIGN_UP_SUCCESS = 'SIGN_UP_SUCCESS',
  SIGN_UP_FAIL = 'SIGN_UP_FAIL',
  SIGN_IN_START = 'SIGN_IN_START',
  SIGN_IN_SUCCESS = 'SIGN_IN_SUCCESS',
  SIGN_IN_FAIL = 'SIGN_IN_FAIL',
  REMOVE_SESSION = 'REMOVE_SESSION',
  SIGN_UP_STEP_START = 'SIGN_UP_STEP_START',
  SIGN_UP_STEP_SUCCESS = 'SIGN_UP_STEP_SUCCESS',
  SIGN_UP_STEP_FAIL = 'SIGN_UP_STEP_FAIL',
  FORGOT_PASSWORD_STEP_START = 'FORGOT_PASSWORD_STEP_START',
  FORGOT_PASSWORD_STEP_SUCCESS = 'FORGOT_PASSWORD_STEP_SUCCESS',
  FORGOT_PASSWORD_STEP_FAIL = 'FORGOT_PASSWORD_STEP_FAIL',
  GET_USER_START = 'GET_USER_START',
  GET_USER_SUCCESS = 'GET_USER_SUCCESS',
  GET_USER_FAIL = 'GET_USER_FAIL',
}
interface SessionLoadingStartAction {
  type: SessionActionTypes.SESSION_LOADING_START;
}

interface SessionLoadingFinishAction {
  type: SessionActionTypes.SESSION_LOADING_FINISH;
}

interface SignInStartAction {
  type: SessionActionTypes.SIGN_IN_START;
}

interface SetTokensAction {
  type: SessionActionTypes.SET_TOKENS;
  payload: { tokens: SessionTokens };
}

interface SetLanguageAction {
  type: SessionActionTypes.SET_LANGUAGE;
  payload: string;
}

interface RemoveSessionAction {
  type: SessionActionTypes.REMOVE_SESSION;
}

interface GetUserSuccessAction {
  type: SessionActionTypes.GET_USER_SUCCESS;
  payload: {
    user: User;
  };
}

export interface SessionTokens {
  access: string;
  refresh: string;
}
export interface User {
  email: string;
  id: string;
  name: string;
  role: string;
  subscription?: {
    toggles: {
      serversMonitoring: boolean;
      workflowMonitoring: boolean;
      workflowReports: boolean;
    };
    id: string;
  };
  [key: string]: string | any;
}

export interface SessionState {
  interfaceLanguage: string;
  tokens: SessionTokens;
  shouldSaveSession: boolean;
  user: User | null;
  loading: boolean;
  sessionLoading: boolean;
}

export type SessionActions =
  | SessionLoadingStartAction
  | SessionLoadingFinishAction
  | SignInStartAction
  | SetTokensAction
  | RemoveSessionAction
  | GetUserSuccessAction
  | SetLanguageAction;
