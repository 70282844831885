import cn from 'classnames';
import React from 'react';
import { FC } from 'react';
import ReactCodeInput from 'react-code-input';
import { useTranslation } from 'react-i18next';

import { useStyles } from './styles';

type Props = {
  labelKey?: string;
  className?: string;
  disabled?: boolean;
  onChange: (value: string) => void;
  value: string;
  id: string;
  optional?: boolean;
  error?: any;
  fields?: number;
  alignCenter?: boolean;
};

export const InputCode: FC<Props> = ({
  alignCenter,
  className,
  labelKey,
  optional,
  onChange,
  disabled,
  fields = 4,
  error,
  value,
  id,
}) => {
  const { t } = useTranslation();

  const styles = useStyles();

  const renderInput = () => {
    return (
      <div className={cn(styles.inputCodeWrapper, { [styles.alignCenter]: alignCenter })}>
        <ReactCodeInput
          className={cn(styles.inputCodeField, { [styles.inputCodeFieldError]: !!error })}
          onChange={onChange}
          disabled={disabled}
          fields={fields}
          value={value}
          type="number"
          name="codeInput"
          inputMode="numeric"
        />
        {!!error && <div className={styles.error}>{t(error)}</div>}
      </div>
    );
  };

  return (
    <div className={cn(styles.inputCodeContainer, className)} id={id}>
      {labelKey ? (
        <label className={styles.label}>
          <span>{t(labelKey)}</span>

          {optional && <span> (optional)</span>}

          {renderInput()}
        </label>
      ) : (
        renderInput()
      )}
    </div>
  );
};
