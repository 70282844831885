import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  forgotPasswordContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '80px 0 0',

    '& p': {
      margin: '0 0 45px 0',
      fontSize: '14px',
      textAlign: 'center',
      color: '#666666',
    },
    '& form': {
      width: '100%',
    },
  },
  formHeader: {
    ...theme.typography.h5,
    fontSize: '30px',
    margin: '40px 0 24px',
    color: '#212121',
    fontWeight: 700,
  },
  input: {
    '& label': {
      fontWeight: 400,
    },
  },
  submitButton: {
    marginTop: '40px',
  },
  textButton: {
    margin: '0.5rem auto 0',
  },
  backButton: {
    width: 40,
    height: 40,
    border: '2px solid #BFC0CD',
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '40px 0 24px',
    '& svg': {
      height: 16,
      marginRight: 2,
    },
  },
  flexBoxTitle: {
    display: 'flex',
    gap: 16,
  },
}));
