import React, { FC, SyntheticEvent, useCallback } from 'react';
import { Control, useController } from 'react-hook-form';
import DatePicker, { DatePickerProps } from 'components/UI/DatePicker/DatePicker';

export type HookFormDatePicker = Omit<DatePickerProps, 'selected' | 'name'> & {
  control: Control<any, any>;
  name: string;
  onChange?: (date: Date, event?: SyntheticEvent) => unknown;
  defaultValue?: Date;
  disabled?: boolean;
};

const HookFormDatePicker: FC<HookFormDatePicker> = ({
  control,
  name: nameFromProps,
  required,
  defaultValue,
  onChange: propsOnChange,
  ...restProps
}) => {
  const {
    field: { onChange, onBlur, name, value, ref },
  } = useController({
    name: nameFromProps,
    control,
    rules: {
      required,
    },
    ...(typeof defaultValue ? { defaultValue } : {}),
  });

  const changeHandler = useCallback(
    (date: Date, event?: SyntheticEvent) => {
      onChange(event);
      propsOnChange?.(date, event);
    },
    [onChange, propsOnChange],
  );

  return <DatePicker {...restProps} selected={value} name={name} ref={ref} onChange={changeHandler} onBlur={onBlur} />;
};

export default HookFormDatePicker;
