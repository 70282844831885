import { Theme, makeStyles, withStyles } from '@material-ui/core/styles';
import { TableCell } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
  },
  variableWidthContainer: (props: any): any => {
    const styles: Record<string, any> = {
      marginTop: props?.top ?? 0,
      width: '100%',
    };

    return styles;
  },
  basicTableContainer: {
    '& tr th:last-child': {
      borderRight: 'none',
    },
    '& tbody tr:last-child th': {
      borderBottom: 'none',
    },
    '& tr:nth-child(odd)': {
      background: theme.palette.background.paper,
    },
    '& tr:nth-child(even)': {
      background: '#F8F8FB',
    },
    '& .empty': {
      border: 'none',
      width: 'calc(100vw - 115px)',
      height: 'calc(100vh - 390px)',
      maxWidth: 1320,
    },
  },
  headerWrapper: {
    margin: 0,
    fontSize: 13,
    display: 'flex',
    minWidth: '100%',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    columnGap: 4,
    fontWeight: 700,
    color: '#666666',
  },
  flexTableCell: {
    display: 'flex',
    fontSize: 10,
    justifyContent: 'flex-start',
  },
  flexTableCellText: {
    minWidth: '100%',
    width: '100%',
    lineHeight: '2rem',
    color: '#212121',
    fontSize: 13,
    padding: '0px 12px',
    minHeight: 34,
  },
  innerTableWrapper: {
    width: '100%',
  },
}));

export const StyledTableCell = withStyles(() => ({
  root: {
    padding: '2px 12px',
    borderRight: '1px solid #E9E9EE',
    borderBottom: '1px solid #E9E9EE',
    fontSize: 14,
  },
  head: {
    background: '#fff',
    fontWeight: 'bold',
    lineHeight: '2rem',
    textTransform: 'uppercase',
    color: '#666666',
    padding: '0 20px',
  },
  body: {
    fontSize: 14,
    wordBreak: 'break-word',
    '& span': {
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
    },
  },
}))(TableCell);

export const StyledHeaderCell = withStyles(() => ({
  head: {
    background: '#fff',
    fontWeight: 700,
    lineHeight: '36px',
    textTransform: 'capitalize',
    color: '#666666',
    padding: '0 24px',
    borderRight: '1px solid #E9E9EE',
    borderBottom: '1px solid #E9E9EE',
  },
}))(TableCell);
