import { Reducer } from 'redux';
import { produce } from 'immer';
import {
  OrganizationOneActionTypes,
  OrganizationsActionTypes,
  OrganizationsActions,
  OrganizationsState,
} from './types';

const initialState: OrganizationsState = {
  enterpriseAdministratorsCount: 0,
  enterpriseAdministrators: [],
  firstOrganizations: [],
  pagination: {
    page: 0,
    count: 20,
    startOrganization: 1,
    endOrganization: 20,
  },
  search: '',
  enterpriseNameSearch: '',
  counter: 0,
  freeCustomers: { customers: [], customersCount: 0 },
  eatags: [],
  enterprise: {},
  one: {
    id: '',
    email: '',
    name: '',
    phoneNumber: '',
    eatag: '',
    timeFormat: '',
    permissions: {
      users: {
        isEnabled: true,
        permission: 'read',
      },
      accounts: {
        isEnabled: true,
        permission: 'read',
      },
      reports: {
        isEnabled: true,
        permission: 'read',
      },
      finance: {
        isEnabled: true,
        permission: 'read',
      },
    },
    isCustomerPurchase: true,
    isCustomerFinance: true,
    isCustomerOperator: true,
    isCustomerReport: true,
    isSendCompareVersions: true,
  },
  enterpriseCustomers: [],
  freeUsersList: [],
  belongUsersList: [],
  createdUser: {},
};

const organizationsReducer: Reducer<OrganizationsState, OrganizationsActions> = (
  state = initialState,
  action,
): OrganizationsState => {
  return produce(state, (draft: OrganizationsState) => {
    switch (action.type) {
      case OrganizationsActionTypes.ORGANIZATIONS_SET:
        draft.enterpriseAdministrators = action.payload;
        return;
      case OrganizationsActionTypes.ORGANIZATIONS_FIRST_SET:
        draft.firstOrganizations = action.payload;
        return;
      case OrganizationsActionTypes.ORGANIZATIONS_SET_COUNT:
        draft.enterpriseAdministratorsCount = Number(action.payload);
        return;
      case OrganizationsActionTypes.ORGANIZATIONS_SET_COUNT_FIRST:
        draft.counter = Number(action.payload);
        return;
      case OrganizationsActionTypes.ORGANIZATIONS_SET_PAGINATION_COUNT:
        draft.pagination.count = action.payload;
        draft.pagination.page = 0;
        return;

      case OrganizationsActionTypes.ORGANIZATIONS_SET_PAGE:
        draft.pagination.page = action.payload;
        return;

      case OrganizationsActionTypes.ORGANIZATIONS_SET_SHOWN:
        draft.pagination.startOrganization = action.payload.startOrganization;
        draft.pagination.endOrganization = action.payload.endOrganization;
        return;

      case OrganizationsActionTypes.ORGANIZATIONS_SET_SEARCH:
        draft.search = action.payload;
        return;
      case OrganizationsActionTypes.ORGANIZATIONS_SET_ENTERPRISE_NAME_SEARCH:
        draft.enterpriseNameSearch = action.payload;
        return;
      case OrganizationsActionTypes.ORGANIZATIONS_SET_FREE_CUSTOMERS:
        draft.freeCustomers = action.payload;
        return;
      case OrganizationsActionTypes.ORGANIZATIONS_SET_EATAGS:
        draft.eatags = action.payload;
        return;
      case OrganizationOneActionTypes.ORGANIZATION_ONE:
        draft.one = action.payload;
        return;
      case OrganizationOneActionTypes.ORGANIZATION_FREE_USERS_LIST:
        draft.freeUsersList = action.payload;
        return;
      case OrganizationOneActionTypes.ORGANIZATION_BELONG_USERS_LIST:
        draft.belongUsersList = action.payload;
        return;
      case OrganizationOneActionTypes.ORGANIZATION_CREATED_USER:
        draft.createdUser = action.payload;
        return;
      case OrganizationsActionTypes.ORGANIZATIONS_SET_ENTERPRISE:
        draft.enterprise = action.payload;
        return;

      case OrganizationsActionTypes.ORGANIZATIONS_SET_CUSTOMERS:
        draft.enterpriseCustomers = action.payload.enterpriseCustomers;
        return;

      case OrganizationsActionTypes.ORGANIZATIONS_ADD_FREE_CUSTOMERS:
        draft.freeCustomers = {
          customers: [...action.payload, ...state.freeCustomers.customers],
          customersCount: [...action.payload, ...state.freeCustomers.customers].length,
        };
        return;

      default:
        return state;
    }
  });
};

export default organizationsReducer;
