import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { useStyles } from './styles';
import { circles, rectProps, transformProps } from './utils';

const Loader: React.FC = () => {
  const styles = useStyles();
  const { t } = useTranslation();

  return (
    <div className={styles.loader}>
      <div className={styles.whiteCircle}>
        <svg
          className={styles.icon}
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          viewBox="0 0 135 55"
        >
          {circles.map(({ x, fill, values }) => (
            <rect key={fill} x={x} fill={fill} {...rectProps}>
              <animateTransform values={values} {...transformProps} />
            </rect>
          ))}
        </svg>

        <p className={styles.text}>{t('Loading...')}</p>
      </div>
    </div>
  );
};

export default memo(Loader);
