export const API_URL_BASE =
  process.env.NODE_ENV === 'development' ? process.env.REACT_APP_API_URL_BASE_DEV : window.location.origin;
// process.env.NODE_ENV === 'development' ? process.env.REACT_APP_API_URL_BASE_NEW : window.location.origin;
// process.env.NODE_ENV === 'development' ? process.env.REACT_APP_API_URL_BASE_STAGE : window.location.origin;
// process.env.NODE_ENV === 'development' ? process.env.REACT_APP_API_URL_BASE_GIT_STAGE : window.location.origin;

export default {
  API_URL: `${API_URL_BASE}/api`,
  SOCKET_URL: API_URL_BASE,
  USERS_SIGNIN: `auth/login`,
  ACCOUNT_SIGN_UP: 'registration/registerAccount',
  ACCOUNT_ACTIVATE: 'registration/activateAccount',
  ORGANIZATION_SIGN_UP: 'registration/registerOrganization',
  ACTIVATE_ACCOUNT: 'registration/activateAccount',
  RESEND_ACTIVATION_EMAIL: 'registration/resendActivationEmail',
  GET_USER: `users/details`,
  AUTH_REFRESH: `auth/refresh`,
  FORGOT_PASSWORD_REQUEST_EMAIL: `auth/password/reset/first`,
  FORGOT_PASSWORD_SET_NEW: `auth/password/reset/second`,
  USER_PASSWORD_CHANGE: 'users/password/change',
  USER_PARAMETERS: 'users/one', // GET
  USER_PARAMETERS_UPDATE: 'users/update', // PUT
  ORGANIZATION_USER_PARAMETERS_UPDATE: 'enterprise-admin/customers/update', // PUT
  USER_STEPS_NAMES: 'users/steps/names', // GET
  USER_TABLE_DATA: 'users/table/data', // POST
  ORGANIZATION_TABLE_DATA: 'enterprise-admin/table/data', // POST
  GET_USER_DASHBOARD: 'users/dashboard/data', // GET
  GET_ORGANIZATION_CUSTOMER_DASHBOARD: 'enterprise-admin/customers/dashboard/data', // GET

  // STEPS DETAILS
  STEPS_DETAILS_FILTERS: '/users/filters', // GET // TODO: move to users addresses

  // BILLING SETTINGS
  ADMIN_BILLING_SETTINGS: 'billingSettings',

  // ADMIN
  ADMIN_PASSWORD_CHANGE: 'admin/password/change',
  ADMIN_GET_AVAILABLE_DATES: 'admin/customers/dashboard/dates',
  ORGANIZATION_GET_AVAILABLE_DATES: 'enterprise-admin/customers/dashboard/dates',
  ADMIN_CUSTOMERS_DASHBOARD: 'admin/customers/dashboard', // GET
  ORGANIZATION_CUSTOMERS_DASHBOARD: 'enterprise-admin/customers/dashboard', // GET
  ADMIN_DASHBOARD_SEARCH: 'admin/customers/dashboard', // GET
  ORGANIZATION_DASHBOARD_SEARCH: 'enterprise-admin/customers/dashboard',
  ADMIN_CUSTOMERS_LIST: 'admin/customers/list', //GET
  ORGANIZATION_CUSTOMERS_LIST: 'enterprise-admin/customers/list', //GET
  ADMIN_CUSTOMERS_SEARCH: 'admin/customers/search', // GET
  ORGANIZATION_CUSTOMERS_SEARCH_ALL: 'enterprise-admin/customers/list', // GET
  ADMIN_CUSTOMERS_UPDATE: 'admin/customers/update', // PUT
  ADMIN_CUSTOMERS_DELETE: 'admin/customers/delete', // DELETE
  CUSTOMER_PARAMETERS: 'admin/customers/one', //GET
  SELF_CUSTOMER_PARAMETERS: 'users/one', //GET
  ADMIN_GET_ACCOUNT_PARAMETERS: `admin/account/get`, //GET
  ADMIN_UPDATE_ACCOUNT_PARAMETERS: `admin/account/update`, //PUT
  ADMIN_TEST_SMTP: `admin/account/smtp/test`, //GET
  ADMIN_STEPS_NAMES: 'admin/steps/names', // GET
  ADMIN_STEPS_DETAILS_FILTERS: 'admin/filters', // GET
  ORGANIZATION_STEPS_DETAILS_FILTERS: 'enterprise-admin/filters', // GET

  ADMIN_TABLE_DATA: 'admin/table/data', // POST
  ADMIN_GET_USER_DASHBOARD: 'admin/customers/dashboard/data', // GET

  //ADMIN VIEW USERS
  ADMIN_VIEW_USERS_SEARCH: 'admin/view-users/search', // GET
  ADMIN_VIEW_USERS_LIST: 'admin/view-users/list', //GET
  ADMIN_VIEW_USER_DELETE: 'admin/view-users/delete', // DELETE
  ADMIN_VIEW_USERS_CREATE: 'admin/view-users/create/many', // POST
  ADMIN_VIEW_USERS_UPDATE: 'admin/view-users/update/many', //PUT
  SETTINGS_APPEARANCE: 'admin/settings/appearance', //PUT

  //ACCOUNT USERS
  VIEW_USERS_SEARCH: 'view-users/search', // GET
  VIEW_USERS_LIST: 'view-users/list', //GET
  VIEW_USER_DELETE: 'view-users/delete', // DELETE
  VIEW_USER_DETAILS: 'view-users/details', // DELETE
  VIEW_USERS_CREATE: 'view-users/create/many', // POST
  VIEW_USERS_UPDATE: 'view-users/update/many', //PUT
  VIEW_USER_STEPS_NAMES: 'view-users/steps/names',
  GET_VIEW_USER_DASHBOARD: 'view-users/dashboard/data', // GET
  VIEW_USER_TABLE_DATA: 'view-users/table/data', // POST
  VIEW_USER_PARENT: '/view-users/parent', // GET
  VIEW_USER_CREATE: 'system/viewUser/create',
  VIEW_USER_UPDATE: 'system/viewUser/update',
  VIEW_USER_GET_ONE: 'system/viewUser/details',

  ORGANIZATION_VIEW_USERS_SEARCH: 'enterprise-admin/view-users/search', // GET
  ORGANIZATION_VIEW_USERS_LIST: 'enterprise-admin/view-users/list', //GET
  ORGANIZATION_VIEW_USER_DELETE: 'enterprise-admin/view-users/delete', // DELETE
  ORGANIZATION_VIEW_USER_DETAILS: 'enterprise-admin/view-users/details', // DELETE
  ORGANIZATION_VIEW_USERS_CREATE: 'enterprise-admin/view-users/create/many', // POST
  ORGANIZATION_VIEW_USERS_UPDATE: 'enterprise-admin/view-users/update/many', //PUT
  ORGANIZATION_VIEW_USER_STEPS_NAMES: 'enterprise-admin/steps/names',

  //GLOBAL USERS
  GLOBAL_USERS_SEARCH: 'global-users/search', // GET
  GLOBAL_USERS_LIST: 'global-users/list', //GET
  GLOBAL_USER_DELETE: 'global-users/delete', // DELETE
  GLOBAL_USERS_CREATE: 'global-users/create/many', // POST
  GLOBAL_USER_CREATE: 'global-users/create', // POST
  GLOBAL_USERS_UPDATE: 'global-users/update/many', //PUT
  GLOBAL_USERS_GET_ONE: 'system/globalUser/details', //GET
  GLOBAL_USERS_UPDATE_ONE: 'global-users/update', //PUT
  GLOBAL_USER_GET_ACCOUNT_PARAMETERS: `global-users/account/get`, //GET
  GLOBAL_USER_TABLE_DATA: 'global-users/table/data', // POST

  ADMIN_TABLE_XLS: 'admin/table/xls', // POST
  ORGANIZATION_TABLE_XLS: 'enterprise-admin/table/xls', // POST
  GLOBAL_USER_TABLE_XLS: 'global-users/table/xls', // POST
  USERS_TABLE_XLS: 'users/table/xls', // POST
  VIEW_USER_TABLE_XLS: 'view-users/table/xls', // POST

  // SHARED
  LANGUAGES: 'shared/languages', // GET
  STEPS_TYPES: 'shared/steps', // GET
  ORGANIZATION_STEPS_TYPES: 'enterprise-admin/steps', // GET
  THEME: 'shared/theme', // GET

  //CHECK !502
  LIVE: 'app/live', // GET

  /* Additional server points */
  SETTINGS_ADDITIONAL_COLOR: 'settings/appearance', //GET PUT
  SETTINGS_ADDITIONAL_IMAGE: 'settings/appearance/image', //POST
  SETTINGS_ADDITIONAL_LANGUAGE_LIST: 'settings/languages', //GET
  SETTINGS_ADDITIONAL_TABLE: 'settings/filters', //GET POST PUT
  SETTINGS_ADDITIONAL_STEPS: 'settings/steps', //GET POST PUT
  SETTINGS_ADDITIONAL_DOWNLOAD_AGENT: 'agent/downloadMSIUpdate',
  SETTINGS_ADDITIONAL_LANGUAGE_POST: 'settings/languages',
  SETTINGS_ADDITIONAL_LANGUAGE_DELETE: 'settings/languages', //DELETE
  UPLOAD_HELPER: `help/zip`,
  PAYMENT_PURCHASE: 'payment/purchase', // POST
  PAYMENT_HISTORY: 'payment/history', // GET
  ORGANIZATION_PAYMENT_HISTORY: 'enterprise-admin/payment/history', // GET
  PAYMENT_INVOICE: 'payment/invoice', // POST

  // Organization customers
  ORGANIZATION_SEARCH: 'enterprise-admin/search',
  ORGANIZATION_CREATE_MANY: 'enterprise-admin/create/many',
  ORGANIZATION_UPDATE_MANY: 'enterprise-admin/update/many',
  ORGANIZATION_DELETE: 'enterprise-admin/delete',
  ORGANIZATION_CUSTOMERS_SEARCH: 'system/organization/enterprise-details',

  // System users
  COMMON_SYSTEM_USERS: 'system/system-users',
  DELETE_SYSTEM_USER: 'system/system-users/delete',
  GET_CUSTOMERS_LIST: `system/viewUser/customersListForViewUser`,
  CREATE_ACCOUNT: `system/customer/create`,
  UPDATE_ACCOUNT: `system/customer/update`,
  GET_ACCOUNT_ONE: `system/customer/one`,
  CREATE_ORGANIZATION_USER: `system/organizationUser/create`,
  UPDATE_ORGANIZATION_USER: `system/organizationUser/update`,
  GET_ORGANIZATION_USER_BY_ORG_USER_ID: `system/organizationUser/organizationWithUsersByOrgUserId`,

  // Monitoring user
  CREATE_MONITORING_USER: 'system/monitoringUser/create',
  UPDATE_MONITORING_USER: 'system/monitoringUser/update',
  DELETE_MONITORING_USER: 'system/monitoringUser/deleteMonitoringUser',
  GET_MONITORING_USER: 'system/monitoringUser/details',
  GET_MONITORING_USER_LIST: 'system/monitoringUser/monitoringUserCompaniesList',
  GET_MONITORING_USER_LIST_ALL: 'system/monitoringUser/allCompaniesList',
  // Server monitoring
  SERVERS_ALERT: 'alerts/serversAlertHistory',
  WORKFLOW_ALERT: 'alerts/workflowAlertHistory',
  SERVERS_ALERT_XLS: 'alerts/serversAlertHistory/xls',
  WORKFLOW_ALERT_XLS: 'alerts/workflowAlertHistory/xls',
  SERVERS_CHART: 'alerts/serversAlertChart',

  GET_AGENT_MONITORS: 'serverResources/agentMonitors',
  REGISTER_MONITOR: 'serverResources/registerMonitor',
  UPDATE_MONITOR: 'serverResources/updateMonitor',
  DELETE_MONITOR: 'serverResources/deleteMonitor',
  RESET_MONITOR: 'serverResources/resetMonitor',

  // Resources Usage Report
  RESOURCES_USAGE_REPORT_PARAMS: '/resourcesUsageParams',
  RESOURCES_USAGE_REPORT: '/resourcesUsage',

  // Hardware alerts
  GET_HARDWARE_ALERTS: 'alerts/serversAlertToCompanyHistoryServers',
  //VERSION CONTROL
  GLOBAL_MODULE_VERSIONS_LIST: '/globalModulesVersions/getModulesList',
  GLOBAL_MODULE_VERSIONS_EDIT: '/globalModulesVersions',
  CUSTOMER_MODULE_VERSIONS_LIST: '/customerModulesVersions/getModulesList',
  CUSTOMER_MODULE_VERSIONS_MODULE_EDIT: '/customerModulesVersions/module',
  CUSTOMER_MODULE_VERSIONS_SYSTEM_EDIT: '/customerModulesVersions/system',
  SLA_LIST_PARAMS: '/sla/listParams',
  SLA_LIST: '/sla/list',
  WORKFLOW_NOTIFICATIONS: '/alerts/workflowNotifications',
  SOFTWARE_NOTIFICATIONS: '/alerts/softwareNotifications',
  SYSTEM_ERROR_RESOLVE: '/alerts/resolveSystemError',
  WORKFLOW_ERROR_RESOLVE: '/alerts/resolveWorkflowError',
  PAYMENT_HISTORY_WITH_CUSTOMER: '/payment/historyWithCustomer',
  CONTRACT_NOTIFICATIONS: '/alerts/contractNotifications',
  REVERT_PAYMENT: '/payment/revertPayment?invoiceId=',
  SLA_LIST_FILTERS: '/sla/list/filters',
  SETTINGS_WORKFLOW_STEP_KEYS: '/settings/steps/stepKeysList',
  SOFTWARE_INSTALL_CODES: '/admin/security',
  GENERATE_SOFTWARE_INSTALL_CODE: '/admin/security/generateCode',

  // Stripe
  STRIPE_SETUP_INTENT: 'stripe/create-setup-intent',
  ACCOUNT_WITH_CARDS: 'stripe/accountWithCards',
  DELETE_PAYMENT_METHOD: 'stripe/deletePaymentMethod',
  GET_SUBSCRIPTION: 'stripe/subscription/get-subscription',
  START_TRIAL: 'stripe/subscription/startTrial',
  RETRY_PAYMENT: 'stripe/subscription/retryPayment',
  CREATE_SUBSCRIPTION: 'stripe/subscription/create-subscription',
  UPDATE_SUBSCRIPTION: 'stripe/subscription/update-subscription',
  CANCEL_SUBSCRIPTION: 'stripe/subscription/cancel-subscription',
  RENEW_SUBSCRIPTION: 'stripe/subscription/renew-subscription',

  // ORGANIZATION
  PUT_ORGANIZATION_UPDATE: '/system/organization/update',
  PUT_ORGANIZATION_CREATE: '/system/organization/create',
  GET_ORGANIZATION_ONE: '/system/organization/one',
  GET_ORGANIZATION_FREE_USERS_LIST: '/system/organization/freeUsersList',

  // SERVERS DASHBOARD
  GET_SERVERS_DASHBOARD: '/serversDashboard/getServersDashboard',
  GET_SERVERS_DASHBOARD_CHART_LIST: '/serversDashboard/serversAlertChartSmall',

  // BILLING HISTORY
  GET_BILLING_HISTORY: '/billingSettingTable/getBillingHistory',
  GET_BILLING_HISTORY_INVOICE: '/billingSettingTable/downloadInvoice',

  // TABLE HISTORY VIEW
  GET_TABLE_HISTORY_VIEW: '/tableView/history',

  // ORGANIZATION USERS LIST
  GET_ORGANIZATION_USERS_LIST: '/system/organizationUser/getAllowedOrganizations',

  // WORKFLOW FILES MONITORING
  GET_WORKFLOW_FILES_USERS_LIST: '/workflowChartsParams',
  GET_WORKFLOW_FILES_CHARTS: '/workflowCharts',

  // DISC QUOTA
  GET_DISC_QUOTA_USERS_LIST: '/workflowQuotaChartParams',
  GET_DISC_QUOTA_CHARTS: '/workflowQuotaChart',

  // APP MONITORING
  GET_ALL_APPS: '/appMonitoring/getAllApps',
  GET_MONITORED_APPS: '/appMonitoring/getMonitoredApps',
  UPDATE_MONITORED_APPS: '/appMonitoring/updateMonitoredApp',
};
