import React from 'react';
import { useStyles } from './styles';

type Props = {
  type?: string;
  className?: string;
  top?: number;
  isFixed?: boolean;
};

const DEFAULT_TOP_OFFSET = 30;

const PaginationContainer: React.FC<Props> = ({ isFixed, children, className, top }) => {
  const styles = useStyles({ top: top ?? DEFAULT_TOP_OFFSET });

  const getTypeClassName = React.useCallback(() => {
    if (isFixed) return styles.fixedPagination;
    return '';
  }, [styles, isFixed]);

  return <div className={`${getTypeClassName()} ${className || ''} pagination`}>{children}</div>;
};

export default PaginationContainer;
