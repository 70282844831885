import React, { FC } from 'react';

import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import DialogTitle from '@material-ui/core/DialogTitle';
import Cancel from '@material-ui/icons/CloseOutlined';
import { useStyles } from './styles';

interface IProps {
  title?: string;
  closeDisabled?: boolean;
  onClose(): void;
}

export const DialogHeader: FC<IProps> = ({ closeDisabled, onClose, title }) => {
  const styles = useStyles();

  return (
    <DialogTitle disableTypography>
      <Typography variant="h2">{title}</Typography>

      <IconButton
        disabled={closeDisabled}
        aria-label="close-dialog"
        className={styles.closeIconButton}
        onClick={onClose}
        disableFocusRipple
        disableRipple
        disableTouchRipple
      >
        <Cancel className={styles.closeIcon} />
      </IconButton>
    </DialogTitle>
  );
};
