export interface AdminParametersState {
  billingSettings: BillingSettings;
  adminData: AdminData;
  isSomeCompaniesTogglesEnabled: SomeCompaniesTogglesEnabledinData;
}

export interface SomeCompaniesTogglesEnabledinData {
  serversMonitoring: boolean;
  workflowMonitoring: boolean;
  workflowReports: boolean;
}

export interface AdminDataWithSomeCompaniesTogglesEnabled {
  adminData: AdminData;
  isSomeCompaniesTogglesEnabled: SomeCompaniesTogglesEnabledinData;
}

export interface BillingSettings {
  annualDiscount: number;
  trialItemsAmount: { users: number; accounts: number; servers: number; licences: number; workflowSteps: number };
  dataSettings: { access: number | null; removal: number | null };
  isTrialItemsUpdated: boolean;
  itemsPrices: {
    Users: {
      upTo10: number;
      upTo20: number;
      upTo50: number;
      upTo100: number;
      moreThan100: number;
      additionalUnit: number;
    };
    Accounts: {
      upTo10: number;
      upTo20: number;
      upTo50: number;
      upTo100: number;
      moreThan100: number;
      additionalUnit: number;
    };
    Licences: {
      upTo10: number;
      upTo20: number;
      upTo50: number;
      upTo100: number;
      moreThan100: number;
      additionalUnit: number;
    };
    'Workflow Steps': {
      upTo10: number;
      upTo20: number;
      upTo50: number;
      upTo100: number;
      moreThan100: number;
      additionalUnit: number;
    };
    Servers: {
      upTo10: number;
      upTo20: number;
      upTo50: number;
      upTo100: number;
      moreThan100: number;
      additionalUnit: number;
    };
  };
}

export interface AdminData {
  admin: {
    email: string;
    name: string;
    phoneNumber: string;
    dailyReportEmail: string;
    timeFormat: string;
    isAdminSales: boolean;
    isAdminFinance: boolean;
    isErrorReportEmail: boolean;
    isSendHardwareErrorsMail: boolean;
    isSendSystemErrorsMail: boolean;
    isSendCompareVersions: boolean;
  };
  hotFolder: {
    hotFolderPath: string;
    hotFolderFolder?: string;
    hotFolderUserLogin?: string;
    hotFolderUserPassword?: string;
  };
  smtp: { smtpAddress: string; smtpUserName: string; smtpUserPassword: string };
  map: {
    workingThreshold: number;
    workingTimeout: number;
    errorThreshold: number;
    errorTimeout: number;
    constantErrorThreshold: number;
    timeToNoKeepAliveXML: number;
    productType: {
      serversMonitoring: boolean;
      workflowMonitoring: boolean;
      workflowReports: boolean;
    };
    serverMonitoringConfigs: {
      okStatusTime: number;
      keepAliveTime: number;
      resourcesTime: number;
      timeToNoKeepAlive: number;
    };
  };
  serverSettings?: {
    serverName: string;
  };
}

export enum AdminParametersActionTypes {
  SET_ADMIN_DATA = 'SET_ADMIN_DATA',
}
export enum AdminBillingSettingsActionTypes {
  SET_ADMIN_BILLING_SETTINGS = 'SET_ADMIN_BILLING_SETTINGS',
}

export interface AdPSetAdminData {
  type: AdminParametersActionTypes.SET_ADMIN_DATA;
  payload: AdminDataWithSomeCompaniesTogglesEnabled;
}

export interface AdPSetAdminBillingSettings {
  type: AdminBillingSettingsActionTypes.SET_ADMIN_BILLING_SETTINGS;
  payload: any;
}

export type AdminParametersActions = AdPSetAdminData | AdPSetAdminBillingSettings;
