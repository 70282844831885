import { Dispatch } from 'redux';
import { uILoadingFinish, uILoadingStart } from '../ui/actions';
import { showErrorNotification } from '../../utils/notifications';

import { apiGetBillingHistory, apiGetBillingHistoryInvoice } from '../../api';
import { BillingHistoryActionTypes, BillingHistoryActions } from './types';

export const setBillingHistory = (records: any): BillingHistoryActions => {
  return {
    type: BillingHistoryActionTypes.SET_BILLING_HISTORY,
    payload: records,
  };
};

export const getBillingHistoryAction = (data: any) => {
  return async (dispatch: Dispatch): Promise<any> => {
    dispatch(uILoadingStart());

    try {
      const response = await apiGetBillingHistory(data);
      dispatch(setBillingHistory(response?.data));

      return response.data;
    } catch (e) {
      showErrorNotification(e as any);
    } finally {
      dispatch(uILoadingFinish());
    }
    return 0;
  };
};

export const getBillingHistoryInvoiceAction = (fileName: any) => {
  return async (dispatch: Dispatch): Promise<any> => {
    dispatch(uILoadingStart());

    try {
      const response = await apiGetBillingHistoryInvoice(fileName);
      const createdBlob = response.data;

      const saveData = (blob: any, fileName: any): void => {
        const a: any = document.createElement('a');
        const url = window.URL.createObjectURL(blob);

        document.body.appendChild(a);
        a.style = 'display: none';
        a.href = url;
        a.download = fileName;

        a.click();

        window.URL.revokeObjectURL(url);
      };

      saveData(createdBlob, fileName);

      return response.data;
    } catch (e) {
      showErrorNotification(e as any);
    } finally {
      dispatch(uILoadingFinish());
    }
    return 0;
  };
};
