import React, { FC, ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { IconButton } from '@material-ui/core';
import { Icon } from 'components/UI/Icon';
import { StyledHeaderCell, StyledTableCell, useStyles } from './styles';
import { FilterCellDefault, FilterCellMultiSelect } from 'components/CustomerTableViewTable/Cells';
import SearchFilter from 'components/TableFilters/SearchFilter';
import EllipsisText from 'components/EllipsisText';
import { createDebounceClosure } from 'utils/common';
import EmptyState from 'pages/SLAReport/EmptyState';

const STICKY_HEADER_OFFSETS = {
  firstRow: 0,
  secondRow: 37,
};

export type CellDetails = {
  column: Column;
  columnIndex: number;
  row: any;
  rowIndex: number;
};

export type CellRenderer = (details: CellDetails) => ReactElement | string | number | boolean | null;

export type Column = {
  label: string;
  dataKey: string;
  sortable?: boolean;
  filterable?: boolean;
  searchable?: boolean;
  searchableCount?: number;
  render?: CellRenderer;
  isRender?: (details: CellDetails) => boolean;
  bodyCellStyle?: Record<string, any>;
  width?: string;
};

export type FilterOption = {
  value: string;
  label: string;
};

export type FilterOptions = {
  [keyName: string]: FilterOption[];
};

export type SelectedFind = {
  [keyName: string]: string[] | string;
};

export type SortValue = {
  [keyName: string]: string;
};

export type RequestValueFilters = {
  [keyName: string]: string[] | string;
};

export type RequestFilters = {
  order?: SortValue;
  find?: RequestValueFilters;
};

export type RequestFiltersApplyer = (value: RequestFilters) => void;

export type BasicTableProps = {
  columns: Column[];
  data: any[];
  isHide?: {
    accountType?: boolean;
  };
  filtersData?: {
    find?: FilterOptions;
  };
  filters?: {
    order?: SortValue;
    find?: SelectedFind;
  };
  defaultFilters?: {
    order?: SortValue;
    find?: SelectedFind;
  };
  shouldApplyChangesOnMount?: boolean;
  stickyHeader?: boolean;
  stickyHeaderOffsets?: {
    firstRow: number;
    secondRow: number;
  };
  classes?: any;
  applyChanges?: RequestFiltersApplyer;
  cellRenderer?: CellRenderer;
  tableLayout?: 'fixed' | 'auto';
  hasNoFilters?: boolean;
  isReverseSort?: boolean;
};

const BasicTable: FC<BasicTableProps> = ({
  data,
  columns,
  filtersData,
  filters,
  defaultFilters,
  shouldApplyChangesOnMount,
  stickyHeader,
  stickyHeaderOffsets = STICKY_HEADER_OFFSETS,
  classes,
  applyChanges,
  cellRenderer,
  tableLayout,
  hasNoFilters,
  isReverseSort,
}) => {
  const { t } = useTranslation();
  const styles = useStyles({ classes });
  const [checkedFind, setFilters] = useState({} as any);
  const [checkedFindPrev, setFiltersPrev] = useState(null as unknown as SelectedFind);
  const [innerSortValue, setInnerSortValue] = useState(null as unknown as SortValue);

  const selectedSortValue = useMemo(() => {
    return filters?.order || innerSortValue || defaultFilters?.order;
  }, [defaultFilters, filters, innerSortValue]);

  const selectedFiltersPrev = useMemo(
    () => filters?.find || checkedFindPrev || defaultFilters?.find,
    [filters, checkedFindPrev, defaultFilters],
  );

  const setSelectedFilterOptions = useCallback((dataKey: string, items: any): void => {
    setFilters((filters: Record<string, any>) => ({ ...filters, [dataKey]: items }));
  }, []);

  const setSelectedFilterOptionsPrev = useCallback(
    (dataKey: string, items: any): void => {
      const update = { ...(selectedFiltersPrev || {}), [dataKey]: items };

      if (!items?.length) {
        delete update[dataKey];
      }

      setFiltersPrev({ ...update });

      if (applyChanges) {
        applyChanges({
          find: update,
        });
      }
    },
    [applyChanges, selectedFiltersPrev],
  );

  const setSelectedFilterOptionsShown = useCallback((): Record<string, any> => ({}), []);

  useEffect(() => {
    const shouldApply = typeof shouldApplyChangesOnMount === 'boolean' ? shouldApplyChangesOnMount : true;
    if (shouldApply && applyChanges) {
      applyChanges({ find: selectedFiltersPrev, order: selectedSortValue });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const searchDebounceClosure = useMemo(() => createDebounceClosure(250), []);

  const handleSortClick = useCallback(
    (dataKey: string, sortOrder?: string): void => {
      let newSortOrder = 'ASC';

      if (isReverseSort) {
        newSortOrder = 'DESC';
        if (sortOrder === 'DESC') newSortOrder = 'ASC';
      } else {
        if (sortOrder === 'ASC') newSortOrder = 'DESC';
      }

      const newSortValue = {
        [dataKey]: newSortOrder,
      };

      setInnerSortValue(newSortValue);

      if (applyChanges) {
        applyChanges({
          order: newSortValue,
        });
      }
    },
    [applyChanges],
  );

  const headerContent = useMemo(() => {
    return (
      <>
        <TableRow>
          {columns.map(({ label, sortable, dataKey, width }: Column, index: number) => {
            const sortOrder = selectedSortValue?.[dataKey];

            const cellStyle = {
              ...(width ? { width } : {}),
              top: stickyHeaderOffsets.firstRow,
            };
            const cellKey = `${index} ${label}`;
            return (
              <StyledHeaderCell key={cellKey} style={cellStyle}>
                <p className={styles.headerWrapper}>
                  <EllipsisText text={t(label || '')} />
                  {sortable && (
                    <IconButton onClick={(): void => handleSortClick(dataKey, sortOrder)}>
                      <Icon name={`${sortOrder ? sortOrder.toLowerCase() : 'sort'}`} size={10} />
                    </IconButton>
                  )}
                </p>
              </StyledHeaderCell>
            );
          })}
        </TableRow>
        {hasNoFilters ? null : (
          <TableRow>
            {columns.map(({ label, filterable, searchable, dataKey, searchableCount }: Column, index: number) => {
              const cellKey = `${index} ${label} 2`;

              if (filterable) {
                const checkedPrev = selectedFiltersPrev?.[dataKey] || [];
                const checked = checkedFind[dataKey] ? checkedFind[dataKey] : checkedPrev;

                return (
                  <FilterCellMultiSelect
                    key={cellKey}
                    columnName={dataKey}
                    filterOptions={filtersData?.find?.[dataKey] || []}
                    setFilterMultiSelect={setSelectedFilterOptions}
                    setFilterMultiSelectPrev={setSelectedFilterOptionsPrev}
                    setFilterMultiSelectShown={setSelectedFilterOptionsShown}
                    checked={checked}
                    checkedPrev={checkedPrev as string[]}
                    shownOptions={checked}
                    nonState
                    cellStyle={{ top: stickyHeaderOffsets.secondRow }}
                  />
                );
              } else if (searchable) {
                const defaultValue = selectedFiltersPrev?.[dataKey] || '';
                const value = checkedFind[dataKey] || '';

                return (
                  <SearchFilter
                    key={cellKey}
                    value={value}
                    searchableCount={searchableCount}
                    defaultValue={defaultValue as string}
                    onChange={(value) => {
                      searchDebounceClosure(() => {
                        setSelectedFilterOptions(dataKey, value);
                        setSelectedFilterOptionsPrev(dataKey, value);
                      });
                    }}
                    cellStyle={{ top: stickyHeaderOffsets.secondRow }}
                  />
                );
              }

              return <FilterCellDefault key={cellKey} cellStyle={{ top: stickyHeaderOffsets.secondRow }} />;
            })}
          </TableRow>
        )}
      </>
    );
  }, [
    columns,
    hasNoFilters,
    selectedSortValue,
    stickyHeaderOffsets.firstRow,
    stickyHeaderOffsets.secondRow,
    styles.headerWrapper,
    t,
    handleSortClick,
    selectedFiltersPrev,
    checkedFind,
    filtersData?.find,
    setSelectedFilterOptions,
    setSelectedFilterOptionsPrev,
    setSelectedFilterOptionsShown,
    searchDebounceClosure,
  ]);

  return (
    <div className="overflow-hidden-wrapper">
      <TableContainer
        {...(stickyHeader ? { style: { overflowX: 'initial' } } : {})}
        className={cn(styles.basicTableContainer, 'overflow-table-wrapper')}
      >
        <Table
          aria-label="simple table"
          style={{ tableLayout: tableLayout || 'fixed', width: '100%', zIndex: 0, position: 'relative' }}
          stickyHeader={stickyHeader}
        >
          <TableHead>{headerContent}</TableHead>
          <TableBody>
            {data.length
              ? data?.map((row: any, rowIndex: number) => (
                  <TableRow key={row.id}>
                    {cellRenderer
                      ? columns.map((column: Column, columnIndex: number) =>
                          cellRenderer({ column, columnIndex, row, rowIndex }),
                        )
                      : columns.map((column: Column, columnIndex: number) => {
                          const { dataKey, render, isRender, bodyCellStyle } = column;
                          const isUseRender = !isRender || isRender({ column, columnIndex, row, rowIndex });

                          return (
                            <StyledTableCell component="th" scope="row" key={columnIndex} style={bodyCellStyle || {}}>
                              {isUseRender && render ? (
                                render({ column, columnIndex, row, rowIndex })
                              ) : (
                                <div className={styles.flexTableCell}>
                                  <span className={styles.flexTableCellText}>
                                    <EllipsisText text={row[dataKey] ?? ''} />
                                  </span>
                                </div>
                              )}
                            </StyledTableCell>
                          );
                        })}
                  </TableRow>
                ))
              : null}
          </TableBody>
        </Table>
        {!data.length ? <EmptyState text={t('There is nothing here yet...')} /> : null}
      </TableContainer>
    </div>
  );
};

export default BasicTable;
