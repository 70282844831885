import React, { ReactElement, useCallback, useEffect, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Checkbox, InputText } from 'components/UI';
import { useStyles } from '../styles';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { signInSchema } from 'utils/validation';
import { useDispatch } from 'react-redux';
import { getUser, signIn } from 'store/session/actions';
import { useSelector } from 'react-redux';
import { AppState } from 'store';
import { uIGetWorkflowStepTypes, uILoadingStart } from 'store/ui/actions';

const SignIn = (): ReactElement => {
  // Translation
  const { t } = useTranslation();

  const isStripeEnabledFromStore = useSelector((state: AppState) => state.ui.isStripeEnabled);
  const stripeKey: string | undefined = process.env.REACT_APP_STRIPE_PUBLIC_KEY;
  const isStripeEnabled = isStripeEnabledFromStore || (!!stripeKey && stripeKey.length > 0);

  // Routing
  const history = useHistory();
  // Refs
  const formElement = useRef<HTMLFormElement>(null);
  const submitButtonElement = useRef<HTMLButtonElement>(null);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
    resolver: yupResolver(signInSchema, { abortEarly: false }),
  });

  // Styling
  const styles = useStyles();

  // Redux
  const dispatch = useDispatch();

  const onSubmit = async (data: any): Promise<void> => {
    const response = await dispatch(signIn({ ...data }));
    if (response && response.code === 'OK') {
      if (response.authorizationResult.needVerification) {
        history.push({
          pathname: '/sign-up',
          state: {
            isNeedVerification: response.authorizationResult.needVerification,
            email: data.email,
          },
        });
      } else if (
        (response.authorizationResult.needPasswordChange ||
          response.authorizationResult.isTempPassVerificationNeeded) &&
        !response.authorizationResult.isTempCodeVerificationNeeded
      ) {
        history.push({
          pathname: '/set-password',
          state: {
            oldPassword: data.password,
            needPasswordChange: response.authorizationResult.needPasswordChange,
            role: response.authorizationResult.userRole,
            isSetNewPassword: true,
            getDataAfterReload: data,
          },
        });
      } else if (response.authorizationResult.isTempCodeVerificationNeeded) {
        const verificationNeeded = response.authorizationResult.isTempCodeVerificationNeeded;

        history.push({
          pathname: `/sign-up`,
          state: {
            email: data.email,
            verificationNeeded,
          },
        });
      } else {
        await dispatch(uILoadingStart());
        await dispatch(getUser());
        await dispatch(uIGetWorkflowStepTypes());
      }
    } else if (response && response.code === 'ERROR') {
      if (response.message === 'User is not defined') {
        setError('email', { type: 'notMatch', message: 'User is not defined' }, { shouldFocus: true });
      } else if (response.message === 'Incorrect password') {
        setError('password', { type: 'notMatch', message: 'Incorrect password' }, { shouldFocus: true });
      }
    }
  };

  const handleKeyPress = useCallback((event: any): void => {
    if (event.key === 'Enter' && document.activeElement?.closest('#log-in-form')) {
      event.preventDefault();
      if (submitButtonElement.current) {
        submitButtonElement.current.click();
      }
      if (event.target) {
        event.target.blur();
      }
    }
  }, []);

  useEffect(() => {
    document.body.addEventListener('keypress', handleKeyPress);
    if (formElement.current) {
      formElement.current.focus();
    }
    return (): void => {
      document.body.removeEventListener('keypress', handleKeyPress);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className={styles.contentContainer}>
        <h3 className={styles.formHeader}>{t('Welcome back!')}</h3>
        <form
          onSubmit={handleSubmit(onSubmit)}
          ref={formElement}
          tabIndex={-1}
          id="log-in-form"
          className={styles.loginForm}
        >
          <InputText
            onKeyDown={handleKeyPress}
            {...register('email', { required: true })}
            labelKey={t('Email')}
            error={!!errors['email'] ? errors['email'].message : undefined}
            placeholder={t('Email Address')}
            fullWidth
          />
          <div className={styles.space} />
          <InputText
            onKeyDown={handleKeyPress}
            labelKey={t('Password')}
            {...register('password', { required: true })}
            error={!!errors['password'] ? errors['password'].message : undefined}
            placeholder={t('Enter your password')}
            type="password"
            fullWidth
          />
          <Button
            labelKey={t('Forgot your password?')}
            type="button"
            className={styles.textButton}
            onClick={(): void => history.push('/forgot-password')}
            textStyle
          />
          <Checkbox {...register('keepLoggedIn', { required: true })} labelKey={t('Keep me logged in')} />
          <Button
            labelKey={t('Log in')}
            className={styles.submitButton}
            type="submit"
            fullWidth
            ref={submitButtonElement}
          />
        </form>

        {isStripeEnabled && (
          <p className={styles.hyperLinkText}>
            {t("Don't have an account yet")}?{' '}
            <a href="/sign-up" className={styles.hyperLink}>
              {t('Register now')}
            </a>
          </p>
        )}
      </div>
    </>
  );
};

export default SignIn;
