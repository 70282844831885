import React, { FC, ReactNode, useCallback, useMemo, useState } from 'react';
import { DateRangePickerProps } from 'components/DateRangePicker';
import { fixPagination } from 'utils';
import BasicPagination from './BasicPagination';
import PaginationLimitSelect from './PaginationLimitSelect';
import { StyledDateRangePicker, useStyles } from './styles';
import { ChangeListener, PaginationLabels, Value } from './types';
import { getPaginationSetter } from './utils';
import { DEFAULT_PAGINATION_VALUE } from './constants';
import ShowTypeSelect, { ShowTypeSelectProps } from './components/ShowTypeSelect';

export type PaginationWithDateRangeAndShowProps = {
  value?: Value;
  defaultValue?: Value;
  count: number;
  labels?: PaginationLabels;
  dateRangePickerProps: DateRangePickerProps;
  showTypeSelectProps: ShowTypeSelectProps;
  onChange?: ChangeListener;
  accountTypeSelect?: ReactNode;
  hideShow?: boolean;
};

const PaginationWithDateRange: FC<PaginationWithDateRangeAndShowProps> = ({
  value,
  defaultValue,
  count = 0,
  onChange,
  labels,
  dateRangePickerProps,
  showTypeSelectProps,
  hideShow,
}) => {
  const styles = useStyles();
  const [innerValue, setInnerValue] = useState(null as unknown as Value);
  const selectedValue = useMemo(
    () => value || innerValue || defaultValue || DEFAULT_PAGINATION_VALUE,
    [defaultValue, innerValue, value],
  );

  const applyChanges: ChangeListener = useCallback(
    (newValue: Value) => {
      setInnerValue(newValue);
      if (onChange) {
        onChange(newValue);
      }
    },
    [onChange],
  );

  const setPagination = useMemo(() => getPaginationSetter(applyChanges, selectedValue), [applyChanges, selectedValue]);

  return (
    <div className={`${styles.paginationWrapperInnerContent} ${styles.paginationWrapperInnerContentPadding}`}>
      <PaginationLimitSelect
        value={selectedValue.limit}
        count={count}
        label={labels?.limitSelectLabel}
        onChange={(selected): void => setPagination(fixPagination({ ...selectedValue, limit: selected }, count))}
      />
      <StyledDateRangePicker {...dateRangePickerProps} />
      <BasicPagination value={selectedValue} count={count} label={labels?.itemCountLabel} onChange={setPagination} />
      {/*{(isAdmin || isGlobalUser) && <ShowTypeSelect {...showTypeSelectProps} />}*/}
      {!hideShow && <ShowTypeSelect {...showTypeSelectProps} />}
    </div>
  );
};

export default PaginationWithDateRange;
