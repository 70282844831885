import { Icon } from 'components/UI/Icon';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useStyles } from './styles';
import cn from 'classnames';
interface EmptyStateprops {
  text?: string;
}

const EmptyState = ({ text }: EmptyStateprops) => {
  const styles = useStyles();
  const { t } = useTranslation();
  return (
    <div className={cn(styles.emptyWrapper, 'empty')}>
      <Icon name="empty" />
      <p>{text || t('Select an account to proceed')}</p>
    </div>
  );
};

export default EmptyState;
