export interface CustomerTableViewState {
  customerId: string | null;
  customerName: string | null;
  companyName: string;
  availableSteps: AvailableSteps;
  selectedStep: string;
  tableViewDates: TableViewDates;
  creditInfo: CreditInfo;
  recordsCount: number;
  recordsCountHistory: number;
  pagination: Pagination;
  search: string;
  tableColumns: TableColumn[];
  tableHistoryColumns: TableColumn[];
  columnsFilters: ColumnsFilters;
  tableViewData: TableViewRecord[];
  tableHistoryViewData: TableViewRecord[];
  sortBy: SortBy;
  companyId: string;
  availableContractDates: {
    startDate: Date;
    endDate: Date;
  };
}

export interface AvailableSteps {
  [name: string]: {
    startDate: Date;
    endDate: Date;
    credit: number;
    creditUsed: number;
  };
}

export interface SortBy {
  columnName: string;
  order: 'asc' | 'desc';
}

export type FilterType = 'search' | 'multiSelect' | 'ink' | 'state' | 'dateRange' | 'timeRange';

export interface ColumnsFilters {
  [columnName: string]: Filter;
}
export interface TableViewCell {
  columnName: string;
  filterType: FilterType;
  columnData: string | number | Date;
}
export interface TableViewRecord {
  tableRecord: TableViewCell[];
}

export interface TableViewFormattedRecord {
  columnName: string;
  filterType: FilterType;
  columnData: any;
  formatType: string;
  errorDescription: string;
}

export interface TableColumn {
  columnName: string; // unique identifier in camel case
  columnLabel: string;
  filterType: FilterType;
  filterOptions?: [];
  formatType: string;
}

export interface Pagination {
  page: number;
  pageHistory?: number;
  count: number;
  countHistory?: number;
  startRecord: number;
  endRecord: number;
  startRecordHistory?: number;
  endRecordHistory?: number;
}

export interface Filter {
  filterType: FilterType;
  selectedOptions?: string[];
  selectedOptionsPrev?: string[];
  selectedOptionsShown?: string[];
  rangeStart?: string | Date;
  rangeEnd?: string | Date;
  searchString?: string;
  searchStringPrev?: string;
}

export interface CreditInfo {
  creditMonth: string;
  credit: number;
  creditUsed: number;
  creditForLastMonth: number;
  creditUsedLastMonth: number;
  paymentType: string;
  balance: number;
}

export interface TableViewDates {
  startDate: Date;
  endDate: Date;
}

export interface TableViewRequestParameters {
  filters: {
    count?: string;
    page?: string;
    stepName: string;
    columnsFilters: Record<string, any>;
    dates: {
      start: string;
      end: string;
    };
    sortBy: SortBy;
  };
}
export enum CustomerTableViewActionTypes {
  TVH_SET_RECORDS_COUNT = 'TVH_SET_RECORDS_COUNT',
  TVH_SET_COUNT = 'TVH_SET_COUNT',
  TVH_SET_PAGE = 'TVH_SET_PAGE',
  TVH_SET_SHOWN = 'TVH_SET_SHOWN',
  TV_SET_AVAILABLE_CONTRACT_DATES = 'TV_SET_AVAILABLE_CONTRACT_DATES',
  TV_RESET_STATE = 'TV_RESET_STATE',
  TV_SET_CUSTOMER_ID = 'TV_SET_CUSTOMER_ID',
  TV_SET_CUSTOMER_NAME = 'TV_SET_CUSTOMER_NAME',
  TV_SET_COMPANY_NAME = 'TV_SET_COMPANY_NAME',
  TV_SET_TABLE_VIEW_DATA = 'TV_SET_TABLE_VIEW_DATA',
  TV_SET_COUNT = 'TV_SET_COUNT',
  TV_SET_PAGE = 'TV_SET_PAGE',
  TV_SET_SHOWN = 'TV_SET_SHOWN,',
  TV_SET_SEARCH = 'TV_SET_SEARCH,',
  TV_SET_RECORDS_COUNT = 'TV_SET_RECORDS_COUNT',
  TV_SET_START_DATE = 'TV_SET_START_DATE',
  TV_SET_END_DATE = 'TV_SET_END_DATE',
  TV_RESET_DATES = 'TV_RESET_DATES',
  TV_SET_CREDIT_INFO = 'TV_SET_CREDIT_INFO',
  TV_SET_AVAILABLE_STEPS = 'TV_SET_AVAILABLE_STEPS',
  TV_SET_SELECTED_STEP = 'TV_SET_SELECTED_STEP',
  TV_SET_SELECTED_STEP_DATA = 'TV_SET_SELECTED_STEP_DATA',
  TV_SET_TABLE_COLUMNS = 'TV_SET_TABLE_COLUMNS',
  TV_SET_TABLE_HISTORY_COLUMNS = 'TV_SET_TABLE_HISTORY_COLUMNS',
  TV_SET_FILTER_INK = 'TV_SET_FILTER_INK',
  TV_SET_FILTER_DATE_RANGE = 'TV_SET_FILTER_DATE_RANGE',
  TV_SET_FILTER_MULTI_SELECT = 'TV_SET_FILTER_MULTI_SELECT',
  TV_SET_FILTER_MULTI_SELECT_PREV = 'TV_SET_FILTER_MULTI_SELECT_PREV',
  TV_SET_FILTER_MULTI_SELECT_SHOWN = 'TV_SET_FILTER_MULTI_SELECT_SHOWN',
  TV_SET_FILTER_STATE = 'TV_SET_FILTER_STATE',
  TV_SET_FILTER_SEARCH = 'TV_SET_FILTER_SEARCH',
  TV_SET_FILTER_SEARCH_PREV = 'TV_SET_FILTER_SEARCH_PREV',
  TV_TOGGLE_SORT_BY = 'TV_TOGGLE_SORT_BY',
  TV_SET_SORT_BY = 'TV_SET_SORT_BY',
  TV_RESET_FILTERS = 'TV_RESET_FILTERS',
  TV_SET_COMPANY_ID = 'TV_SET_COMPANY_ID',
  TV_SET_TABLE_HISTORY_VIEW_DATA = 'TV_SET_TABLE_HISTORY_VIEW_DATA',
}

export interface TVResetState {
  type: CustomerTableViewActionTypes.TV_RESET_STATE;
}

export interface TVSetCustomerId {
  type: CustomerTableViewActionTypes.TV_SET_CUSTOMER_ID;
  payload: string | null;
}

export interface TVSetCustomerName {
  type: CustomerTableViewActionTypes.TV_SET_CUSTOMER_NAME;
  payload: string | null;
}

export interface TVSetCompanyName {
  type: CustomerTableViewActionTypes.TV_SET_COMPANY_NAME;
  payload: string;
}

export interface TVSetTableViewData {
  type: CustomerTableViewActionTypes.TV_SET_TABLE_VIEW_DATA;
  payload: TableViewRecord[];
}

export interface tVHSetTableHistoryViewData {
  type: CustomerTableViewActionTypes.TV_SET_TABLE_HISTORY_VIEW_DATA;
  payload: TableViewRecord[];
}

export interface TVSetRecordsCount {
  type: CustomerTableViewActionTypes.TV_SET_RECORDS_COUNT;
  payload: number;
}

export interface TVHSetRecordsCount {
  type: CustomerTableViewActionTypes.TVH_SET_RECORDS_COUNT;
  payload: number;
}

export interface TVSetCount {
  type: CustomerTableViewActionTypes.TV_SET_COUNT;
  payload: number;
}

export interface TVSetPage {
  type: CustomerTableViewActionTypes.TV_SET_PAGE;
  payload: number;
}

export interface TVHSetCount {
  type: CustomerTableViewActionTypes.TVH_SET_COUNT;
  payload: number;
}

export interface TVHSetPage {
  type: CustomerTableViewActionTypes.TVH_SET_PAGE;
  payload: number;
}

export interface TVSetShown {
  type: CustomerTableViewActionTypes.TV_SET_SHOWN;
  payload: { startRecord: number; endRecord: number };
}

export interface TVHSetShown {
  type: CustomerTableViewActionTypes.TVH_SET_SHOWN;
  payload: { startRecord: number; endRecord: number };
}

export interface TVSetSearch {
  type: CustomerTableViewActionTypes.TV_SET_SEARCH;
  payload: string;
}

export interface TVSetStartDate {
  type: CustomerTableViewActionTypes.TV_SET_START_DATE;
  payload: Date;
}

export interface TVSetEndDate {
  type: CustomerTableViewActionTypes.TV_SET_END_DATE;
  payload: Date;
}

export interface TVResetDates {
  type: CustomerTableViewActionTypes.TV_RESET_DATES;
}

export interface TVSetCreditInfo {
  type: CustomerTableViewActionTypes.TV_SET_CREDIT_INFO;
  payload: CreditInfo;
}

export interface TVSetAvailableSteps {
  type: CustomerTableViewActionTypes.TV_SET_AVAILABLE_STEPS;
  payload: AvailableSteps;
}

export interface tVSetAvailableContractDates {
  type: CustomerTableViewActionTypes.TV_SET_AVAILABLE_CONTRACT_DATES;
  payload: {
    startDate: Date;
    endDate: Date;
  };
}

export interface TVSetSelectedStepData {
  type: CustomerTableViewActionTypes.TV_SET_SELECTED_STEP_DATA;
  payload: { selectedStep: string; startDate: Date; endDate: Date; credit: number; creditUsed: number };
}

export interface TVSetSelectedStep {
  type: CustomerTableViewActionTypes.TV_SET_SELECTED_STEP;
  payload: string;
}

export interface TVSetTableColumns {
  type: CustomerTableViewActionTypes.TV_SET_TABLE_COLUMNS;
  payload: TableColumn[];
}

export interface tVHSetTableHistoryColumns {
  type: CustomerTableViewActionTypes.TV_SET_TABLE_HISTORY_COLUMNS;
  payload: TableColumn[];
}

export interface TVSetFilterInk {
  type: CustomerTableViewActionTypes.TV_SET_FILTER_INK;
  payload: { columnName: string; selectedOptions: string[] };
}

export interface TVSetFilterDateRange {
  type: CustomerTableViewActionTypes.TV_SET_FILTER_DATE_RANGE;
  payload: { columnName: string; rangeStart: Date | undefined; rangeEnd: Date | undefined };
}

export interface TVSetFilterMultiSelect {
  type: CustomerTableViewActionTypes.TV_SET_FILTER_MULTI_SELECT;
  payload: {
    columnName: string;
    selectedOptions: string[];
  };
}

export interface TVSetFilterMultiSelectPrev {
  type: CustomerTableViewActionTypes.TV_SET_FILTER_MULTI_SELECT_PREV;
  payload: {
    columnName: string;
    selectedOptionsPrev: string[];
  };
}

export interface TVSetFilterMultiSelectShown {
  type: CustomerTableViewActionTypes.TV_SET_FILTER_MULTI_SELECT_SHOWN;
  payload: {
    columnName: string;
    selectedOptionsShown: string[];
  };
}

export interface TVSetFilterState {
  type: CustomerTableViewActionTypes.TV_SET_FILTER_STATE;
  payload: { columnName: string; selectedOptions: string[] };
}

export interface TVSetFilterSearch {
  type: CustomerTableViewActionTypes.TV_SET_FILTER_SEARCH;
  payload: { columnName: string; searchString: string | undefined };
}

export interface TVSetFilterSearchPrev {
  type: CustomerTableViewActionTypes.TV_SET_FILTER_SEARCH_PREV;
  payload: { columnName: string; searchStringPrev: string | undefined };
}

export interface TVToggleSortBy {
  type: CustomerTableViewActionTypes.TV_TOGGLE_SORT_BY;
  payload: string;
}

export interface TVSetSortBy {
  type: CustomerTableViewActionTypes.TV_SET_SORT_BY;
  payload: SortBy;
}
export interface TVResetFilters {
  type: CustomerTableViewActionTypes.TV_RESET_FILTERS;
}

export interface TVSetCompanyId {
  type: CustomerTableViewActionTypes.TV_SET_COMPANY_ID;
  payload: string;
}

export type CustomerTableViewActions =
  | TVResetState
  | TVResetFilters
  | TVSetCustomerId
  | TVSetCustomerName
  | TVSetTableViewData
  | tVHSetTableHistoryViewData
  | TVSetCount
  | TVHSetCount
  | TVSetPage
  | TVHSetPage
  | TVSetShown
  | TVHSetShown
  | TVSetRecordsCount
  | TVHSetRecordsCount
  | TVSetSearch
  | TVSetStartDate
  | TVSetEndDate
  | TVResetDates
  | TVSetCreditInfo
  | TVSetSelectedStep
  | TVSetSelectedStepData
  | TVSetTableColumns
  | tVHSetTableHistoryColumns
  | TVSetFilterInk
  | TVSetFilterDateRange
  | TVSetFilterMultiSelect
  | TVSetFilterMultiSelectPrev
  | TVSetFilterMultiSelectShown
  | TVSetFilterState
  | TVSetFilterSearch
  | TVSetFilterSearchPrev
  | TVSetAvailableSteps
  | tVSetAvailableContractDates
  | TVToggleSortBy
  | TVSetSortBy
  | TVSetCompanyId
  | TVSetCompanyName;
