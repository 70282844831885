import React, { FC } from 'react';
import { Icon } from 'components/UI/Icon';
import { IconButton, TableCell } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { tVGetTableData, tVSetFilterState } from 'store/customerTableView/actions';
import { AppState } from 'store';

import { useStyles } from './styles';

interface Props {
  filterOptions: any[];
  columnName: string;
}

const FilterCellState: FC<Props> = ({ filterOptions, columnName }: Props) => {
  // Styling
  const styles = useStyles();

  // Redux
  const dispatch = useDispatch();
  const checked =
    useSelector((state: AppState) => state.customerTableView.columnsFilters[columnName]?.selectedOptions) || [];

  const handleToggle = (value: string) => (): void => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];
    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    dispatch(tVSetFilterState(columnName, newChecked));
    dispatch(tVGetTableData());
  };

  return (
    <TableCell className={[styles.filterCell, styles.FilterCellState].join(' ')}>
      <div className={styles.cellContent}>
        {filterOptions.map((option: string, index: number) => {
          return (
            <IconButton
              key={index}
              className={[styles.inkButton, checked.indexOf(option) !== -1 ? styles.inkSelected : ''].join(' ')}
              onClick={handleToggle(option)}
            >
              <Icon name={`state-${option}`} />
            </IconButton>
          );
        })}
      </div>
    </TableCell>
  );
};

export default FilterCellState;
