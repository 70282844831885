import React, { FC, memo, useCallback, useRef } from 'react';
import { InputText } from 'components/UI';
import { useStyles } from './styles';

export type PureSearchBarProps = {
  onChange: (value: string) => any;
  defaultValue?: string;
  inputName?: string;
  labelKey?: string;
  placeholder?: string;
  delay?: number;
};

const DEFAULT_DELAY = 500;

const PureSearchBar: FC<PureSearchBarProps> = ({ defaultValue, onChange, inputName, labelKey, placeholder, delay }) => {
  const styles = useStyles();
  const timeoutIdRef = useRef(null as any);

  const handleChange = useCallback(
    ({ target: { value } }): void => {
      if (timeoutIdRef.current) {
        clearTimeout(timeoutIdRef.current);
      }

      timeoutIdRef.current = setTimeout(() => {
        onChange(value ?? '');
        clearTimeout(timeoutIdRef.current);
        timeoutIdRef.current = null;
      }, delay ?? DEFAULT_DELAY);
    },
    [delay, onChange],
  );

  return (
    <div className={styles.SearchBar}>
      <InputText
        className={styles.searchInput}
        type="text"
        name={inputName || ''}
        labelKey={labelKey || ''}
        placeholder={placeholder || ''}
        defaultValue={defaultValue || ''}
        onChange={handleChange}
      />
    </div>
  );
};

export default memo(PureSearchBar);
