export interface ResourcesUsageReportState {
  data: any;
  params: any;
}

export interface ResourcesUsageReportBasic {
  page: number;
  count: number;
  year: any;
  month: any;
  sortBy: any;
  show: string;
  find?: {
    role: any;
    email: any;
  };
}

export interface ResourcesUsageReportParams {
  year: any;
  month: any;
  show: string;
}

export enum ResourcesUsageReportActionTypes {
  RESOURCES_USAGE_REPORTS = 'RESOURCES_USAGE_REPORTS',
  RESOURCES_USAGE_REPORTS_PARAMS = 'RESOURCES_USAGE_REPORTS_PARAMS',
}

export interface SetResourcesUsageReport {
  type: ResourcesUsageReportActionTypes.RESOURCES_USAGE_REPORTS;
  payload: any;
}

export interface SetResourcesUsageReportParams {
  type: ResourcesUsageReportActionTypes.RESOURCES_USAGE_REPORTS_PARAMS;
  payload: any;
}

export type ResourcesUsageActions = SetResourcesUsageReport | SetResourcesUsageReportParams;
