import React, { FC } from 'react';
import Dialog from '@material-ui/core/Dialog';
import { useStyles } from '../TermsAndConditionsModal/styles';
import { DialogHeader } from 'components/DialogHeader';
import DialogContent from '@material-ui/core/DialogContent';
// import cn from 'classnames';

interface Props {
  open: boolean;
  onClose: () => any;
}

const PrivatePolicyModal: FC<Props> = ({ onClose, open }) => {
  const styles = useStyles();

  return (
    <Dialog
      open={open}
      onClose={onClose}
      scroll="body"
      maxWidth="sm"
      fullWidth
      classes={{ paperWidthSm: styles.dialogRoot }}
    >
      <DialogHeader onClose={onClose} />

      <DialogContent className={styles.modalContent}>
        <div
          style={{
            overflow: 'hidden',
          }}
        >
          <iframe
            src="/PrivacyPolicy.pdf#toolbar=0"
            style={{
              width: '100%',
              height: '100%',
              minHeight: 'calc(80vh - 65px)',
              borderRadius: '16px',
              border: 'none',
              transform: 'scale(1.05) translate(-10px, -10px)',
              transformOrigin: '0 0',
              overflow: 'hidden',
            }}
            title="PDF Viewer"
          />
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default PrivatePolicyModal;
