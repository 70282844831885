import React, { FC, useCallback } from 'react';
import { Control, useController } from 'react-hook-form';
import ToggleSwitch from 'components/UI/ToggleSwitch/ToggleSwitch';

export type HookFormToggleSwitchProps = {
  control: Control<any, any>;
  checked?: string;
  name: string;
  labelKey?: string;
  className?: any;
  thumbGB?: boolean;
  thumbActiveGB?: boolean;
  noMargin?: boolean;
  reverseLayout?: boolean;
  required?: boolean;
  disabled?: boolean;
  defaultChecked?: boolean;
  onChange?: (...event: any[]) => unknown;
};

const HookFormToggleSwitch: FC<HookFormToggleSwitchProps> = ({
  control,
  name: nameFromProps,
  required,
  defaultChecked,
  onChange: propsOnChange,
  ...restProps
}) => {
  const {
    field: { onChange, onBlur, name, value, ref },
  } = useController({
    name: nameFromProps,
    control,
    rules: {
      required,
    },
    ...(typeof defaultChecked === 'boolean' ? { defaultValue: defaultChecked } : {}),
  });

  const changeHandler = useCallback(
    (...args: any[]) => {
      onChange(...args);
      propsOnChange?.(...args);
    },
    [onChange, propsOnChange],
  );

  return <ToggleSwitch {...restProps} checked={value} name={name} ref={ref} onChange={changeHandler} onBlur={onBlur} />;
};

export default HookFormToggleSwitch;
