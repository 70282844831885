import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  authorizationFormsContainer: {
    width: '564px',
    backgroundColor: theme.palette.background.paper,
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxHeight: 'calc(100% - 2rem)',
    overflow: 'hidden',
    borderRadius: '40px',
    padding: '30px 42px 30px 82px',
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '10px 0 0',
    '& form': {
      width: '100%',
    },
  },
  formHeader: {
    margin: '0 0 40px',
    textAlign: 'center',
    fontSize: '40px',
    lineHeight: '49px',
    color: '#212121',
    fontWeight: 700,
  },
  littleFormHeader: {
    fontSize: '30px',
    lineHeight: '40px',
    color: '#212121',
    fontWeight: 700,
    margin: '40px 0 0',
  },
  submitButton: {
    marginTop: '18px',
  },
  loginForm: {
    '&:focus': {
      outline: 'none',
    },
  },
  hyperLinkText: {
    margin: '40px 0 0',
    color: '#666666',
    fontSize: '14px',
    fontWeight: 500,
  },
  hyperLink: {
    fontSize: '14px',
    color: '#0F65E5',
    fontWeight: 500,
    textDecoration: 'none',

    '&:hover': {
      color: '#0F65E5',
    },
    '&:active': {
      color: theme.palette.primary.dark,
    },
  },
  cardHeader: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  logo: {
    maxHeight: '42px',
    maxWidth: '245px',
    width: 'fit-content',
  },
  headerTitle: {
    margin: '16px 0 0',
    padding: '10px 16px',
    background: '#F2F2F4',
    borderRadius: '8px',
    fontWeight: 700,
    fontSize: '20px',
    lineHeight: '24px',
    color: '#212121',
  },
  textButton: {
    margin: '8px 0 24px auto',
    height: 'fit-content',
    color: '#212121',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '17px',
    '&:hover': {
      background: 'transparent',
    },
  },
  signUpContainerPaddingBottom: {},
  input: {
    marginBottom: 30,
    '& label': {
      fontWeight: 400,

      '& span:first-child': {
        color: '#000',
      },
    },
  },
  buttonsContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingTop: 0,
  },
  disableButton: {
    background: '#0F65E6',
    opacity: 0.25,
    pointerEvents: 'none',
  },
  formSubTitle: {
    fontSize: 14,
    fontWeight: 400,
    color: '#666666',
    margin: 0,
    marginTop: '-24px',
    marginBottom: '40px',
  },
  cardsWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  textWrapper: {
    marginLeft: 18,
  },
  card: {
    width: '100%',
    maxWidth: '100%',
    border: '2px solid #F2F2F4',
    padding: '22px 26px 24px',
    cursor: 'pointer',
    background: '#F2F2F4',
    borderRadius: 24,
    display: 'flex',

    '&:hover': {
      boxShadow: '5px 5px 50px rgba(0, 0, 0, 0.05)',
      background: '#FFFFFF',
      border: '2px solid #0F65E6',
    },
    '&:first-of-type': {
      marginBottom: 16,
    },
    '& svg': {
      width: 40,
      minWidth: 40,
      height: 40,
    },
  },
  activeCard: {
    boxShadow: '5px 5px 50px rgba(0, 0, 0, 0.05)',
    background: '#FFFFFF',
    border: '2px solid #0F65E6',
  },
  cardTitle: {
    fontSize: '20px',
    color: '#212121',
    fontWeight: 700,
    lineHeight: '25px',
    margin: 0,
    marginBottom: '4px',
  },
  cardDescription: {
    color: theme.palette.grey[700],
    lineHeight: '17px',
    fontSize: '14px',
    margin: 0,
    fontWeight: 500,
  },
  checkBoxLabel: {
    color: '#212121',

    '& span:nth-child(2)': {
      textDecoration: 'underline',
      cursor: 'pointer',
      color: '#0F65E5',
      fontWeight: 700,
    },
    '& span:nth-child(4)': {
      textDecoration: 'underline',
      cursor: 'pointer',
      color: '#0F65E5',
      fontWeight: 700,
    },
  },
  successTitle: {
    fontWeight: 500,
    color: '#212121',
    opacity: '0.7',
    margin: 0,
    textAlign: 'center',
    lineHeight: '1.0625rem',
    marginBottom: '0.875rem',
  },
  overflowContainer: {
    maxHeight: 'calc(100vh - 7rem)',
    overflow: 'auto',
    paddingRight: 40,
  },
  space: {
    marginTop: 25,
  },
  registerHeader: {
    fontWeight: 700,
    fontSize: 28,
    lineHeight: '36px',
    color: '#212121',
    margin: 0,
    width: '100%',
    textAlign: 'center',
  },
  backButton: {
    width: 40,
    height: 40,
    border: '2px solid #BFC0CD',
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& svg': {
      height: 16,
      marginRight: 2,
    },
  },
  flexBoxTitle: {
    display: 'flex',
    alignSelf: 'flex-start',
    gap: 16,
  },
}));
