import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
  root: (props: any): any => ({
    position: 'relative',
    overflowX: 'auto',
    scrollBehavior: 'smooth',
    ...(props.disableScrollbar
      ? {
          scrollbarWidth: 'none',
          '-ms-overflow-style': 'none',

          '&::-webkit-scrollbar': {
            display: 'none',
          },
        }
      : {}),
  }),
  wrapper: {
    position: 'relative',
    overflow: 'hidden',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    '&.shadow-right::after': {
      content: '""',
      pointerEvents: 'none',
      position: 'absolute',
      left: '100%',
      top: 0,
      height: 'calc(100% - 8px)',
      zIndex: 1,
      width: 0,
      boxShadow: '0 0px 40px 25px rgba(255, 255, 255, 0.9)',
    },

    '&.shadow-left::before': {
      content: '""',
      pointerEvents: 'none',
      position: 'absolute',
      left: 0,
      top: 0,
      height: 'calc(100% - 8px)',
      zIndex: 1,
      width: 0,
      boxShadow: '0 0px 40px 25px rgba(255, 255, 255, 0.9)',
    },
  },
}));
