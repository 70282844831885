import { Reducer } from 'redux';
import { produce } from 'immer';

import { CustomerTableViewActionTypes, CustomerTableViewActions, CustomerTableViewState } from './types';
import { paymentTypes } from 'consts';

// const date = new Date();
const initialState: CustomerTableViewState = {
  availableContractDates: {
    startDate: new Date(),
    endDate: new Date(),
  },
  customerId: null,
  companyId: '',
  customerName: null,
  companyName: '',
  tableViewData: [],
  tableHistoryViewData: [],
  availableSteps: {},
  selectedStep: '',
  tableViewDates: {
    startDate: new Date(new Date().setDate(new Date().getDate() - 1)),
    endDate: new Date(),
  },
  creditInfo: {
    creditMonth: '',
    credit: 0,
    creditUsed: 0,
    creditForLastMonth: 0,
    creditUsedLastMonth: 0,
    paymentType: paymentTypes.contract,
    balance: 0,
  },
  recordsCount: 0,
  recordsCountHistory: 0,
  pagination: {
    page: 0,
    count: 25,
    endRecord: 0,
    startRecord: 0,
    startRecordHistory: 0,
    endRecordHistory: 0,
  },
  search: '',
  tableColumns: [],
  tableHistoryColumns: [],
  columnsFilters: {},
  sortBy: { columnName: 'date', order: 'desc' },
};

export const customerTableViewReducer: Reducer<CustomerTableViewState, CustomerTableViewActions> = (
  state = initialState,
  action,
): CustomerTableViewState => {
  return produce(state, (draft: CustomerTableViewState) => {
    switch (action.type) {
      case CustomerTableViewActionTypes.TV_RESET_STATE:
        return initialState;

      case CustomerTableViewActionTypes.TV_SET_CUSTOMER_ID:
        draft.customerId = action.payload;
        draft.sortBy = initialState.sortBy;
        return;

      case CustomerTableViewActionTypes.TV_SET_COMPANY_ID:
        draft.companyId = action.payload;
        return;

      case CustomerTableViewActionTypes.TV_SET_CUSTOMER_NAME:
        draft.customerName = action.payload;
        return;

      case CustomerTableViewActionTypes.TV_SET_COMPANY_NAME:
        draft.companyName = action.payload;
        return;

      case CustomerTableViewActionTypes.TV_SET_TABLE_VIEW_DATA:
        draft.tableViewData = action.payload;
        draft.sortBy = initialState.sortBy;
        return;

      case CustomerTableViewActionTypes.TV_SET_TABLE_HISTORY_VIEW_DATA:
        draft.tableHistoryViewData = action.payload;
        // draft.sortBy = initialState.sortBy;
        return;

      case CustomerTableViewActionTypes.TV_SET_RECORDS_COUNT:
        draft.recordsCount = action.payload;
        return;

      case CustomerTableViewActionTypes.TVH_SET_RECORDS_COUNT:
        draft.recordsCountHistory = action.payload;
        return;

      case CustomerTableViewActionTypes.TV_SET_COUNT:
        draft.pagination.count = action.payload;
        draft.pagination.page = 0;
        return;

      case CustomerTableViewActionTypes.TVH_SET_COUNT:
        draft.pagination.countHistory = action.payload;
        draft.pagination.pageHistory = 0;
        return;

      case CustomerTableViewActionTypes.TV_SET_PAGE:
        draft.pagination.page = action.payload;
        return;

      case CustomerTableViewActionTypes.TVH_SET_PAGE:
        draft.pagination.pageHistory = action.payload;
        return;

      case CustomerTableViewActionTypes.TV_SET_SHOWN:
        draft.pagination.startRecord = action.payload.startRecord;
        draft.pagination.endRecord = action.payload.endRecord;
        return;

      case CustomerTableViewActionTypes.TVH_SET_SHOWN:
        draft.pagination.startRecordHistory = action.payload.startRecord;
        draft.pagination.endRecordHistory = action.payload.endRecord;
        return;

      case CustomerTableViewActionTypes.TV_SET_SEARCH:
        draft.search = action.payload;
        return;

      case CustomerTableViewActionTypes.TV_SET_START_DATE:
        draft.tableViewDates.startDate = action.payload;
        draft.columnsFilters = initialState.columnsFilters;
        return;

      case CustomerTableViewActionTypes.TV_SET_END_DATE:
        draft.tableViewDates.endDate = action.payload;
        draft.columnsFilters = initialState.columnsFilters;
        return;

      case CustomerTableViewActionTypes.TV_RESET_DATES:
        draft.tableViewDates = initialState.tableViewDates;
        return;

      case CustomerTableViewActionTypes.TV_SET_CREDIT_INFO:
        draft.creditInfo = action.payload;
        return;

      case CustomerTableViewActionTypes.TV_SET_AVAILABLE_STEPS:
        draft.availableSteps = action.payload;
        return;

      case CustomerTableViewActionTypes.TV_SET_AVAILABLE_CONTRACT_DATES:
        draft.availableContractDates = action.payload;
        return;

      case CustomerTableViewActionTypes.TV_SET_SELECTED_STEP:
        draft.selectedStep = action.payload;
        // draft.columnsFilters = initialState.columnsFilters;
        draft.sortBy = initialState.sortBy;
        draft.pagination.page = 0;
        return;

      case CustomerTableViewActionTypes.TV_SET_TABLE_COLUMNS:
        draft.tableColumns = action.payload;
        return;

      case CustomerTableViewActionTypes.TV_SET_TABLE_HISTORY_COLUMNS:
        draft.tableHistoryColumns = action.payload;
        return;

      case CustomerTableViewActionTypes.TV_SET_FILTER_INK:
        if (!!action.payload.selectedOptions.length) {
          draft.columnsFilters = {
            ...state.columnsFilters,
            [action.payload.columnName]: { filterType: 'ink', selectedOptions: action.payload.selectedOptions },
          };
        } else {
          delete draft.columnsFilters[action.payload.columnName];
        }
        draft.pagination.page = 0;
        return;

      case CustomerTableViewActionTypes.TV_SET_FILTER_DATE_RANGE:
        if (!!action.payload.rangeStart && !!action.payload.rangeEnd) {
          draft.columnsFilters = {
            ...state.columnsFilters,
            [action.payload.columnName]: {
              filterType: 'dateRange',
              rangeStart: action.payload.rangeStart,
              rangeEnd: action.payload.rangeEnd,
            },
          };
        } else {
          delete draft.columnsFilters[action.payload.columnName];
        }
        draft.pagination.page = 0;
        return;

      case CustomerTableViewActionTypes.TV_SET_FILTER_MULTI_SELECT:
        draft.columnsFilters = {
          ...state.columnsFilters,
          [action.payload.columnName]: {
            ...state.columnsFilters[action.payload.columnName],
            filterType: 'multiSelect',
            selectedOptions: action.payload.selectedOptions,
          },
        };
        draft.pagination.page = 0;
        return;

      case CustomerTableViewActionTypes.TV_SET_FILTER_MULTI_SELECT_PREV:
        draft.columnsFilters[action.payload.columnName] = {
          ...state.columnsFilters[action.payload.columnName],
          selectedOptionsPrev: action.payload.selectedOptionsPrev,
        };
        return;

      case CustomerTableViewActionTypes.TV_SET_FILTER_MULTI_SELECT_SHOWN:
        draft.columnsFilters[action.payload.columnName] = {
          ...state.columnsFilters[action.payload.columnName],
          selectedOptionsShown: action.payload.selectedOptionsShown,
        };
        return;

      case CustomerTableViewActionTypes.TV_SET_FILTER_STATE:
        if (!!action.payload.selectedOptions.length) {
          draft.columnsFilters = {
            ...state.columnsFilters,
            [action.payload.columnName]: { filterType: 'state', selectedOptions: action.payload.selectedOptions },
          };
        } else {
          delete draft.columnsFilters[action.payload.columnName];
        }
        draft.pagination.page = 0;
        return;

      case CustomerTableViewActionTypes.TV_SET_FILTER_SEARCH:
        if (!!action.payload.searchString) {
          draft.columnsFilters = {
            ...state.columnsFilters,
            [action.payload.columnName]: { filterType: 'search', searchString: action.payload.searchString },
          };
        } else {
          delete draft.columnsFilters[action.payload.columnName];
        }
        draft.pagination.page = 0;
        return;

      case CustomerTableViewActionTypes.TV_SET_FILTER_SEARCH_PREV:
        draft.columnsFilters[action.payload.columnName] = {
          ...state.columnsFilters[action.payload.columnName],
          searchStringPrev: action.payload.searchStringPrev,
        };
        return;

      case CustomerTableViewActionTypes.TV_TOGGLE_SORT_BY:
        if (state.sortBy.columnName === action.payload) {
          if (state.sortBy.order === 'asc') {
            draft.sortBy.order = 'desc';
          } else {
            draft.sortBy.order = 'asc';
          }
        } else {
          draft.sortBy.columnName = action.payload;
          draft.sortBy.order = 'asc';
        }
        return;

      case CustomerTableViewActionTypes.TV_SET_SORT_BY:
        draft.sortBy = action.payload;
        return;
      case CustomerTableViewActionTypes.TV_RESET_FILTERS:
        draft.columnsFilters = {};
        return;
      default:
        return state;
    }
  });
};
