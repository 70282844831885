export interface CustomerParametersState {
  customer: Customer;
  contract: Contract;
  steps: Steps;
  company: Company;
  workflowStepTypes: WorkflowStepType[];
  cardsInfo: CardsInfo;
  subscription: Subscription;
  subscriptionUpdatedSuccessful: boolean;
  subscriptionUpdatedReject: boolean;
  subscriptionFirstCardReject: boolean;
  isNotConnectedPaymentCard: IsNotConnectedPaymentCard;
  errorMessage: string;
  isInOrganization: boolean | undefined;
}
export interface Customer {
  isFromUpdate?: boolean;
  isUptimeAccess?: boolean;
  role?: string;
  subscription?: { id: string };
  name: string;
  accountType?: string;
  timeZone: string;
  phoneNumber: string;
  language: string;
  email: string;
  timeFormat: string;
  country: string;
  isDeletedProtection: boolean;
  startTimeSendingReport: string;
  endTimeSendingReport: string;
  isCustomerReport?: boolean;
  isCustomerPurchase?: boolean;
  isCustomerFinance?: boolean;
  isInOrganization?: boolean;
  isCustomerOperator?: boolean;
  isSendCompareVersions?: boolean;
}

export interface SubscriptionItemUsed {
  Accounts: [number, number];
  Licences: [number, number];
  Servers: [number, number];
  Users: [number, number];
  'Workflow Steps': [number, number];
}

export interface CardCustomerIntents {
  [key: string]: string;
}

export interface IsNotConnectedPaymentCard {
  error?: CardError;
  toggle?: boolean;
}

export enum UpdateSubscriptionStatusEnum {
  fail = 'fail',
}

export interface CardsInfo {
  account?: {
    customerIntents?: CardCustomerIntents;
    paymentStatus?: {
      modificationStatus: 'pending' | 'approved' | 'rejected';
      overuse: {
        status: string;
      };
      regular: {
        status: string;
      };
      status: PaymentStatus;
      failInvoiceId: string;
      waitForAction: string;
      isOncePayed: boolean;
    };
    subscription?: {
      toggles?: {
        serversMonitoring: boolean;
        workflowMonitoring: boolean;
        workflowReports: boolean;
      };
    };
  };
  annualDiscount?: number;
  itemsUsed?: SubscriptionItemUsed;
  switchToSubscription?: {
    newPlan: 'monthly';
  };
}

export enum PaymentStatus {
  TRIAL = 'trial',
  TRIALENDED = 'trialEnded',
  FAIL_PAYMENT = 'failPayment',
  PAYED = 'payed',
  BLOCKED = 'blocked',
}

export enum SubscriptionStatus {
  MONTHLY = 'monthly',
  TRIAL = 'trial',
  TRIALENDED = 'trialEnded',
  ANNUALLY = 'annually',
}

export enum SubscriptionPlanVariables {
  MONTHLY = 'monthly',
  ANNUALLY = 'annually',
}

export enum CardError {
  DECLINED = 'card_declined',
  INCOMLPLETE_NUMBER = 'incomplete_number',
  EMPTY = '',
}

export interface Subscription {
  isCanceled?: boolean;
  plan?: SubscriptionPlanVariables;
  status?: string;
  timings?: {
    current_period_end: string;
    current_period_start: string;
  };
  products?: {
    Users?: {
      quantity: number;
      quantityNext: number;
    };
    Accounts?: {
      quantity: number;
      quantityNext: number;
    };
    Licences?: {
      quantity: number;
      quantityNext: number;
    };
    'Workflow Steps'?: {
      quantity: number;
      quantityNext: number;
    };
    Servers?: {
      quantity: number;
      quantityNext: number;
    };
  };
  toggles?: {
    workflowMonitoring: boolean;
    workflowReports: boolean;
    serversMonitoring: boolean;
  };
}

export interface SubscriptionForServer {
  plan: SubscriptionStatus | undefined;
  products: {
    Servers: number;
    'Workflow Steps': number;
    Licences: number;
    Accounts: number;
    Users: number;
  };
  toggles: {
    workflowMonitoring: boolean;
    workflowReports: boolean;
    serversMonitoring: boolean;
  };
}

export interface CustomerDrag {
  id: string;
  name: string;
}

export interface Contract {
  startDate: string;
  endDate: string;
}
export interface Company {
  agentsLimit?: number | null;
  name: string;
  logo?: string;
  address: string;
  url: string;
  autoLoginToken: string;
  coordinates: string;
  customerId?: string | null;
  isXMLKeepAliveEnabled?: boolean;
  isSkipContractNotifications?: boolean;
  productType?: {
    workflowMonitoring?: boolean;
    workflowReports?: boolean;
    serversMonitoring?: boolean;
  };
}
export interface Steps {
  [name: string]: Step;
}
export interface Step {
  credits?: number;
  addCredits?: number;
  isForceSinglePrice?: boolean;
  updatedAt: string | null;
  id: string;
  creditValue: string;
  notificationThreshold1: string;
  notificationThreshold2: string;
  optionalField1: string;
  optionalField2: string;
  optionalField3: string;
  optionalField4: string;
  optionalField5: string;
  isSendDailyReport: boolean;
  paymentType: string;
  toCreate?: boolean;
  toUpdate?: boolean;
  toDelete?: boolean;
  purchaseAmount: number;
  balance: number;
  lastPurchase: {
    amount: number;
    timeStamp: string;
    formattedTimeStamp: string | null;
  };
  isPrePaid: any;
  purchaseClicked: boolean;
  autoPurchase: boolean;
}

export interface WorkflowStepType {
  value: string;
  label: string;
  inUse: boolean;
  credit?: number;
  isForceSinglePrice?: boolean;
}

export enum CustomerCardsInfoActionTypes {
  CUSTOMER_CARDS_INFO_SET = 'CUSTOMER_CARDS_INFO_SET',
}
export enum CustomerSubscriptionActionTypes {
  CUSTOMER_SUBSCRIPTION_SET = 'CUSTOMER_SUBSCRIPTION_SET',
  CUSTOMER_SUBSCRIPTION_UPDATED_SUCCESSFUL = 'CUSTOMER_SUBSCRIPTION_UPDATED_SUCCESSFUL',
  CUSTOMER_SUBSCRIPTION_UPDATED_REJECT = 'CUSTOMER_SUBSCRIPTION_UPDATED_REJECT',
  CUSTOMER_IS_NOT_CONNECTED_PAYMENT_CARD = 'CUSTOMER_IS_NOT_CONNECTED_PAYMENT_CARD',
  CUSTOMER_SET_ERROR_MESSAGE = 'CUSTOMER_SET_ERROR_MESSAGE',
  CUSTOMER_SUBSCRIPTION_FIRST_CARD_REJECT = 'CUSTOMER_SUBSCRIPTION_FIRST_CARD_REJECT',
}

export enum CustomerParametersActionTypes {
  CUSTOMER_PARAMETERS_SET = 'CUSTOMER_PARAMETERS_SET',
  CUSTOMER_PARAMETERS_RESET = 'CUSTOMER_PARAMETERS_RESET',
  CUSTOMER_PARAMETERS_SET_STEP_IN_USE = 'CUSTOMER_PARAMETERS_SET_STEP_IN_USE',
  CUSTOMER_PARAMETERS_SET_WORKFLOW_STEP_TYPES = 'CUSTOMER_PARAMETERS_SET_WORKFLOW_STEP_TYPES',
  CUSTOMER_PARAMETERS_SET_ADDRESS = 'CUSTOMER_PARAMETERS_SET_ADDRESS',
}

export interface CustomerParametersSet {
  type: CustomerParametersActionTypes.CUSTOMER_PARAMETERS_SET;
  payload: {
    customer: Customer;
    contract: Contract;
    steps: Steps;
    company: Company;
    isInOrganization?: boolean;
  };
}

export interface CustomerCardsInfoSet {
  type: CustomerCardsInfoActionTypes.CUSTOMER_CARDS_INFO_SET;
  payload: any;
}

export interface CustomerSubscriptionSet {
  type: CustomerSubscriptionActionTypes.CUSTOMER_SUBSCRIPTION_SET;
  payload: any;
}

export interface CustomerIsNotConnectedPaymentCardSet {
  type: CustomerSubscriptionActionTypes.CUSTOMER_IS_NOT_CONNECTED_PAYMENT_CARD;
  payload: any;
}

export interface setSubscriptionUpdatedSuccessful {
  type: CustomerSubscriptionActionTypes.CUSTOMER_SUBSCRIPTION_UPDATED_SUCCESSFUL;
  payload: any;
}

export interface setSubscriptionUpdatedReject {
  type: CustomerSubscriptionActionTypes.CUSTOMER_SUBSCRIPTION_UPDATED_REJECT;
  payload: any;
}

export interface customerErrorMessageSet {
  type: CustomerSubscriptionActionTypes.CUSTOMER_SET_ERROR_MESSAGE;
  payload: any;
}

export interface CustomerParametersReset {
  type: CustomerParametersActionTypes.CUSTOMER_PARAMETERS_RESET;
}

export interface CustomerParametersSetWorkflowStepTypes {
  type: CustomerParametersActionTypes.CUSTOMER_PARAMETERS_SET_WORKFLOW_STEP_TYPES;
  payload: WorkflowStepType[];
}
export interface CustomerParametersSetAddress {
  type: CustomerParametersActionTypes.CUSTOMER_PARAMETERS_SET_ADDRESS;
  payload: string;
}

export interface CustomerParametersSetStepInUse {
  type: CustomerParametersActionTypes.CUSTOMER_PARAMETERS_SET_STEP_IN_USE;
  payload: WorkflowStepType[];
}

export interface setSubscriptionFirstCardReject {
  type: CustomerSubscriptionActionTypes.CUSTOMER_SUBSCRIPTION_FIRST_CARD_REJECT;
  payload: boolean;
}

export type CustomerParametersActions =
  | CustomerParametersSet
  | CustomerParametersReset
  | CustomerParametersSetStepInUse
  | CustomerParametersSetWorkflowStepTypes
  | CustomerCardsInfoSet
  | CustomerSubscriptionSet
  | setSubscriptionUpdatedSuccessful
  | setSubscriptionUpdatedReject
  | setSubscriptionFirstCardReject
  | CustomerIsNotConnectedPaymentCardSet
  | customerErrorMessageSet
  | CustomerParametersSetAddress;

export type CustomerParameters = Pick<CustomerParametersState, 'customer' | 'contract' | 'steps' | 'company'>;
