export interface BillingHistoryState {
  data: any;
}

export interface IBillingHistory {
  page: number;
  count: number;
  sortBy: any;
  show: 'all';
  find: {
    organizationName: any;
    companyName: any;
    invoiceTotal: string;
  };
}

export enum BillingHistoryActionTypes {
  SET_BILLING_HISTORY = 'SET_BILLING_HISTORY',
}

export interface SetBillingHistory {
  type: BillingHistoryActionTypes.SET_BILLING_HISTORY;
  payload: any;
}

export type BillingHistoryActions = SetBillingHistory;
