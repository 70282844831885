import React, { FC, memo, useCallback, useLayoutEffect, useRef } from 'react';
import { useStyles } from './styles';

export type HorizontalScrollContainerProps = {
  classes?: Record<string, any>;
  disableScrollbar?: boolean;
  disableShadows?: boolean;
  shadowWidth?: number;
};

const SHADOW_LEFT = 'shadow-left';
const SHADOW_RIGHT = 'shadow-right';

const HorizontalScrollContainer: FC<HorizontalScrollContainerProps> = (props) => {
  const classes = useStyles(props);
  const containerRef = useRef(null as unknown as HTMLDivElement);

  const isScrollable = useCallback(
    (element: HTMLDivElement): boolean => element.clientWidth !== element.scrollWidth,
    [],
  );
  const isLeftScrollable = useCallback((element: HTMLDivElement): boolean => {
    return element.scrollLeft !== 0;
  }, []);
  const isRightScrollable = useCallback(
    (element: HTMLDivElement): boolean => Math.ceil(element.scrollLeft + element.clientWidth) < element.scrollWidth,
    [],
  );
  const updateShadows = useCallback(
    (element: HTMLDivElement): void => {
      if (!element.parentElement) {
        return;
      }
      const classList = element.parentElement.classList;
      if (isLeftScrollable(element) && !classList.contains(SHADOW_LEFT)) {
        classList.add(SHADOW_LEFT);
      } else if (!isLeftScrollable(element) && classList.contains(SHADOW_LEFT)) {
        classList.remove(SHADOW_LEFT);
      }
      if (isRightScrollable(element) && !classList.contains(SHADOW_RIGHT)) {
        classList.add(SHADOW_RIGHT);
      } else if (!isRightScrollable(element) && classList.contains(SHADOW_RIGHT)) {
        classList.remove(SHADOW_RIGHT);
      }
    },
    [isLeftScrollable, isRightScrollable],
  );
  const resetShadows = useCallback((element: HTMLDivElement): void => {
    if (!element.parentElement) {
      return;
    }
    const classList = element.parentElement.classList;
    if (classList.contains(SHADOW_LEFT)) {
      classList.remove(SHADOW_LEFT);
    }
    if (classList.contains(SHADOW_RIGHT)) {
      classList.remove(SHADOW_RIGHT);
    }
  }, []);

  const handleScroll = useCallback(({ target }: any): void => updateShadows(target), [updateShadows]);

  const handleWheel = useCallback(
    (event: any) => {
      const { currentTarget, deltaY } = event;
      const isLeftScrolled = deltaY < 0 && !isLeftScrollable(currentTarget);
      const isRightScrolled = deltaY > 0 && !isRightScrollable(currentTarget);

      if (isLeftScrolled || isRightScrolled) {
        return;
      }

      event.preventDefault();

      currentTarget.scrollLeft += deltaY;

      if (!props.disableShadows) {
        updateShadows(currentTarget);
      }
    },
    [isLeftScrollable, isRightScrollable, props.disableShadows, updateShadows],
  );

  useLayoutEffect(() => {
    const container = containerRef.current;
    if (props.disableShadows) {
      resetShadows(container);
      return;
    }
    if (!isScrollable(container)) {
      return;
    }
    updateShadows(container);
  }, [isScrollable, updateShadows, props, resetShadows]);

  return (
    <div className={classes.wrapper}>
      <div
        className={classes.root}
        onScroll={!props.disableShadows ? handleScroll : undefined}
        ref={containerRef}
        onWheel={handleWheel}
      >
        {props.children}
      </div>
    </div>
  );
};

export default memo(HorizontalScrollContainer);
