import React, { FC, ReactNode } from 'react';
import Dialog from '@material-ui/core/Dialog';
import { useStyles } from './styles';
import { useTranslation } from 'react-i18next';
import { Button } from 'components/UI';
import cn from 'classnames';
import { IconButton } from '@material-ui/core';
import { Icon } from 'components/UI/Icon';

interface Props {
  open: boolean;
  onClose: (confirm: boolean) => any;
  children?: ReactNode;
  captionKey?: string;
  messageKey?: string;
  haveErrors?: boolean;
}

const ConfirmDeleteChanel: FC<Props> = ({
  haveErrors,
  onClose,
  open,
  captionKey = 'Attention!',
  messageKey = '',
}: Props) => {
  // Styling
  const styles = useStyles();
  // Translation
  const { t } = useTranslation();

  return (
    <Dialog open={open} onBackdropClick={(): void => onClose(false)}>
      <div className={styles.DisableProtectionModal}>
        <IconButton className={styles.closeButton} onClick={() => onClose(false)}>
          <Icon name="close" color="#8E909D" />
        </IconButton>
        <div className={styles.modalHeader}>{t(captionKey)}</div>
        <div className={styles.modalMessage}>{t(messageKey)}</div>
        <div className={styles.buttonsWrapper}>
          <Button
            labelKey={t('Confirm')}
            type="button"
            onClick={(): void => onClose(true)}
            fullWidth
            attentionStyle
            disabled={haveErrors}
            className={cn({ [styles.disableButton]: haveErrors })}
          />
          <Button labelKey={t('Cancel')} type="button" onClick={(): void => onClose(false)} secondaryStyle fullWidth />
        </div>
      </div>
    </Dialog>
  );
};

export default ConfirmDeleteChanel;

export class ConfirmCommonModal {}
