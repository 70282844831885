import { Theme, makeStyles, withStyles } from '@material-ui/core/styles';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';

export const Accordion = withStyles({
  root: {
    border: 'none',
    boxShadow: 'none',
    padding: 0,

    '&.MuiPaper-root': {
      background: 'none',
    },

    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 0,
    },
    '& .MuiIconButton-label': {
      maxWidth: 15,
      cursor: 'pointer',
    },
    '& .MuiIconButton-edgeEnd': {
      marginRight: 0,
    },
  },
  expanded: {},
})(MuiAccordion);

export const AccordionSummary = withStyles({
  root: {
    background: 'none',
    border: 'none',
    margin: 0,
    padding: 0,
    minHeight: 24,
    borderRadius: '1px',
    '&$expanded': {
      minHeight: '1rem',
    },
    '&.Mui-focused': {
      background: 'none',
      boxShadow: '0 0 0 1px rgb(16, 16, 16)',
    },
    '&.Mui-focusVisible': {
      background: 'none',
      boxShadow: '0 0 0 1px rgb(16, 16, 16)',
    },
  },
  content: {
    margin: 0,
    '&$expanded': {
      margin: 0,
    },
  },
  expanded: {},
})(MuiAccordionSummary);

export const AccordionDetails = withStyles({
  root: {
    padding: 0,
  },
})(MuiAccordionDetails);

export const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100%',
    '& .MuiAccordionSummary-root': {
      padding: '0 25px 0 30px',
      width: 344,
      borderRadius: 16,
      color: '#212121',
      transition: 'all 0.3s',
      '&:hover': {
        background: '#E9E9EE',
      },
    },
    '& .MuiAccordionSummary-root.Mui-expanded': {
      borderRadius: 16,
      background: '#E9E9EE',
    },
  },
  menuBlock: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    background: theme.palette.background.default,
    '&:not(.no-padding)': {
      padding: '64px 20px',
      maxWidth: 384,
    },

    '& svg path': {
      fill: '#8E909D',
    },

    '& span': {
      whiteSpace: 'nowrap',
    },
  },
  logo: {
    maxHeight: 30,
    minHeight: 30,
    marginBottom: 40,
    marginLeft: 20,
  },
  mainMenuBlock: {
    flex: 1,
    background: '#F8F8FA',
    color: '#212121',
    justifyContent: 'flex-start',
  },
  footerMenuBlock: {
    background: theme.palette.background.default,
    color: '#212121',
    fontWeight: 500,
    fontSize: 15,
    lineHeight: '20px',
    borderTop: '2px solid #E9E9EE',

    '&:not(.no-padding)': {
      padding: '32px 20px 24px',
    },

    '& svg path': {
      fill: '#999999',
    },
  },
  menuItemFooter: {
    color: '#212121',
  },
  menuItem: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    border: 'none',
    margin: 0,
    padding: 0,
    background: 'none',
    cursor: 'pointer',
    minHeight: 48,
    textTransform: 'capitalize',
    color: '#212121',
    fontWeight: 500,
    fontSize: 15,
    lineHeight: '20px',

    '& .text-wrapper': {
      position: 'relative',
      fontSize: '15px',
    },

    '& .text-wrapper::before': {
      content: 'attr(data-text)',
      position: 'absolute',
      top: 0,
      left: 0,
      opacity: 1,
    },

    '& .text-wrapper.dim-text': {
      color: '#212121',
      fontSize: 15,
      fontWeight: 400,
    },

    '& .text-wrapper.dim-text::before': {
      color: '#666666',
      fontSize: 15,
      fontWeight: 400,
      opacity: 1,
    },

    '& .text': {
      opacity: 0,
    },

    '&:hover .text-wrapper::before, &.active .text-wrapper::before': {
      opacity: 0,
    },

    '&:hover .text, &.active .text': {
      color: '#212121',
      opacity: 1,
      fontSize: 15,
    },

    '& .icon-wrapper': {
      width: '44px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
    },
  },
  greetingText: {
    fontWeight: 700,
    fontSize: 20,
    lineHeight: '24px',
    color: '#212121',
    margin: '0 0 16px 20px',
  },
  versionText: {
    fontSize: 10,
    lineHeight: 1.2,
    letterSpacing: '0.25em',
    textTransform: 'uppercase',
    textAlign: 'center',
    marginTop: '10px',
  },
  hoverMenuItem: {
    padding: '0 25px 0 30px',
    width: 344,
    borderRadius: 16,
    color: '#212121',
    transition: 'all 0.3s',
    '&:hover': {
      background: '#E9E9EE',
    },
  },
}));
