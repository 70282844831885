import { createTheme } from '@material-ui/core';

export const theme = createTheme({
  typography: {
    fontFamily: [
      'Helvetica Neue',
      'Arial',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
  palette: {
    primary: {
      light: '#488cb4',
      main: '#0F578B',
      dark: '#165f85',
      contrastText: '#fff',
      100: '#ff0000', //red
      200: '#50AC65', //green
    },
    secondary: {
      light: '#8acf26',
      main: '#50AC65',
      dark: '#559e02',
      contrastText: '#fff',
    },
    error: {
      contrastText: '#fff',
      dark: '#962338',
      light: 'rgba(206, 57, 84, 0.25)',
      main: '#CE3954',
    },
    text: {
      disabled: 'rgba(0, 0, 0, 0.38)',
      hint: 'rgba(0, 0, 0, 0.38)',
      primary: 'rgba(0, 0, 0, 0.87)',
      secondary: 'rgba(0, 0, 0, 0.54)',
    },
    background: {
      default: '#F8F8FA',
      paper: '#FFFFFF',
    },
    grey: {
      700: '#666666',
    },
  },
  overrides: {
    MuiIconButton: {
      root: {
        padding: 6,
      },
    },
    MuiButton: {
      label: {
        textTransform: 'none',
      },
    },
    MuiTooltip: {
      tooltip: {
        fontSize: '0.75rem',
      },
    },
  },
});
