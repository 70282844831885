export interface SUBUIState {
  workflowStepTypes: WorkflowStepType[];
  workflowStepKeys: string[];
  langs: Lang[];
  currentLang: Lang;
  tableViewSettings: TableViewSetting[];
  currentSettings: AppearanceSetting;
  allSettings: AppearanceSetting[];
  statusLocal: boolean;
}

export enum SUBUIActionTypes {
  SUBUI_SET_WORKFLOW_STEP_TYPES = 'SUBUI_SET_WORKFLOW_STEP_TYPES',
  SUBUI_SET_WORKFLOW_STEP_KEYS = 'SUBUI_SET_WORKFLOW_STEP_KEYS',
  SUBUI_SET_LANGS = 'SUBUI_SET_LANGS',
  SUBUI_SET_CURRENT_LANG = 'SUBUI_SET_CURRENT_LANG',
  SUBUI_SET_TABLE_VIEW = 'SUBUI_SET_TABLE_VIEW',
  SUBUI_SET_CURRENT_APPEARANCE = 'SUBUI_SET_CURRENT_APPEARANCE',
  SUBUI_SET_ALL_APPEARANCE = 'SUBUI_SET_ALL_APPEARANCE',
  SUBUI_SET_STATUS = 'SUBUI_SET_STATUS',
}

export interface WorkflowStepType {
  value: string;
  label: string;
}
export interface AppearanceSetting {
  brand: string;
  logoUrl: string;
  backgroundUrl: string;
  supportEmail: string;
  id?: string;
}
export interface Lang {
  id?: string;
  fileName: string;
  name: string;
  abbreviation: string;
}
export interface TableViewSetting {
  columnName: string;
  nameInXML: string;
  columnLabel: string;
  filterType: string;
  formatType: string;
}
interface SUBUISetWorkflowStepTypesAction {
  type: SUBUIActionTypes.SUBUI_SET_WORKFLOW_STEP_TYPES;
  payload: WorkflowStepType[];
}
interface SUBUISetWorkflowStepKeysAction {
  type: SUBUIActionTypes.SUBUI_SET_WORKFLOW_STEP_KEYS;
  payload: string[];
}
interface SUBUISetLangAction {
  type: SUBUIActionTypes.SUBUI_SET_LANGS;
  payload: Lang[];
}
interface SUBUISetCurrentLangAction {
  type: SUBUIActionTypes.SUBUI_SET_CURRENT_LANG;
  payload: Lang;
}
interface SUBUISetTableViewAction {
  type: SUBUIActionTypes.SUBUI_SET_TABLE_VIEW;
  payload: TableViewSetting[];
}
interface SUBUISetCurrentApearanceAction {
  type: SUBUIActionTypes.SUBUI_SET_CURRENT_APPEARANCE;
  payload: AppearanceSetting;
}
interface SUBUISetAllApearanceAction {
  type: SUBUIActionTypes.SUBUI_SET_ALL_APPEARANCE;
  payload: AppearanceSetting[];
}
interface SUBUISetStatusAction {
  type: SUBUIActionTypes.SUBUI_SET_STATUS;
  payload: boolean;
}
export type SUBUIActions =
  | SUBUISetWorkflowStepTypesAction
  | SUBUISetWorkflowStepKeysAction
  | SUBUISetLangAction
  | SUBUISetCurrentLangAction
  | SUBUISetCurrentApearanceAction
  | SUBUISetAllApearanceAction
  | SUBUISetStatusAction
  | SUBUISetTableViewAction;
