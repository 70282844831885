import React from 'react';
import { ThemeProvider } from '@material-ui/core/styles';
import { theme } from 'components/UI/materialThemes';
import { Provider } from 'react-redux';
import CssBaseline from '@material-ui/core/CssBaseline';
import Redux, { applyMiddleware, createStore } from 'redux';
import reduxThunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { initSessionSync } from 'utils/storage';
import { logout, setTokens } from 'store/session/actions';
import { useStyles } from './styles';
import reducers from 'store';
import { RootProps } from './types';

const middleware: Redux.Middleware[] = [reduxThunk];

export const store = createStore(reducers, {}, composeWithDevTools(applyMiddleware(...middleware)));

/**
 * There is one more listener at public/session.js script,
 * which requests and saves the session from other tabs into session storage.
 * It is made so to achieve proper sharing, because session storage is async.
 * Session data needs to be requested and recorded before react app script loads and executes.
 */
initSessionSync(store, { logout, setTokens });

const Root: React.FC = ({ children }: RootProps) => {
  const styles = useStyles();
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Provider store={store}>
        <>
          <ToastContainer toastClassName={styles.toast} />
          {children}
        </>
      </Provider>
    </ThemeProvider>
  );
};

export default Root;
