import { HArecord, HardwareAlertActionTypes, HardwareAlertActions, SortBy } from './types';

export const HASetTableData = (records: HArecord[]): HardwareAlertActions => {
  return {
    type: HardwareAlertActionTypes.HA_SET_TABLE_DATA,
    payload: records,
  };
};

export const HAResetFilters = (): HardwareAlertActions => {
  return {
    type: HardwareAlertActionTypes.HA_RESET_FILTERS,
  };
};

export const HAResetState = (): HardwareAlertActions => {
  return {
    type: HardwareAlertActionTypes.HA_RESET_STATE,
  };
};

export const HASetFilterState = (columnName: string, selectedOptions: string[]): HardwareAlertActions => {
  return {
    type: HardwareAlertActionTypes.HA_SET_FILTER_STATE,
    payload: { columnName, selectedOptions },
  };
};

export const HASetFilterMultiSelect = (columnName: string, selectedOptions: string[]): HardwareAlertActions => {
  return {
    type: HardwareAlertActionTypes.HA_SET_FILTER_MULTI_SELECT,
    payload: { columnName, selectedOptions },
  };
};

export const HASetFilterMultiSelectPrev = (columnName: string, selectedOptionsPrev: string[]): HardwareAlertActions => {
  return {
    type: HardwareAlertActionTypes.HA_SET_FILTER_MULTI_SELECT_PREV,
    payload: { columnName, selectedOptionsPrev },
  };
};

export const HASetFilterMultiSelectShown = (
  columnName: string,
  selectedOptionsShown: string[],
): HardwareAlertActions => {
  return {
    type: HardwareAlertActionTypes.HA_SET_FILTER_MULTI_SELECT_SHOWN,
    payload: { columnName, selectedOptionsShown },
  };
};

export const HASetFilterSearch = (columnName: string, searchString: string | undefined): HardwareAlertActions => {
  return {
    type: HardwareAlertActionTypes.HA_SET_FILTER_SEARCH,
    payload: { columnName, searchString },
  };
};

export const HASetFilterSearchPrev = (
  columnName: string,
  searchStringPrev: string | undefined,
): HardwareAlertActions => {
  return {
    type: HardwareAlertActionTypes.HA_SET_FILTER_SEARCH_PREV,
    payload: { columnName, searchStringPrev },
  };
};

export const HASetSortBy = (sortBy: SortBy): HardwareAlertActions => {
  return {
    type: HardwareAlertActionTypes.HA_SET_SORT_BY,
    payload: sortBy,
  };
};

export const HASetTableColumns = (newColumns: Record<string, any>): HardwareAlertActions => {
  return {
    type: HardwareAlertActionTypes.HA_SET_TABLE_COLUMNS,
    payload: newColumns,
  };
};
