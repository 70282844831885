import React, { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from 'store';
import { uIToggleDrawer } from 'store/ui/actions';
import { Icon } from 'components/UI/Icon';
import { useStyles } from './styles';

const UserPanel: FC = () => {
  const styles = useStyles();
  const dispatch = useDispatch();
  const brand = useSelector((state: AppState) => state.ui.themeBrandColor.brand);

  const handleClick = (): void => {
    dispatch(uIToggleDrawer());
  };

  return (
    <div className={styles.container}>
      <button className={styles.menuButton} onClick={handleClick}>
        <Icon name="menu" color="#666666" />
      </button>
      <h1 className={styles.title}>{brand}</h1>
    </div>
  );
};

export default UserPanel;
