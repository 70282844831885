import React, { ChangeEvent, FC, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Select } from 'components/UI';
import { countConst } from 'consts';
import { useStyles } from './styles';
import { DEFAULT_LIMIT_SELECT_LABEL } from './constants';

type Props = {
  value?: number;
  defaultValue?: number;
  count: number;
  label?: string;
  onChange: (value: number) => any;
};

const PaginationLimitSelect: FC<Props> = ({ value, defaultValue, onChange, label }) => {
  const styles = useStyles();
  const { t } = useTranslation();

  const limitSelectLabel = useMemo(() => t(label || DEFAULT_LIMIT_SELECT_LABEL), [label, t]);

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLSelectElement>) => onChange(+event.target.value),
    [onChange],
  );

  return (
    <Select
      labelKey={limitSelectLabel}
      className={styles.countSelect}
      options={countConst}
      defaultValue={defaultValue || 0}
      onChange={handleChange}
      {...(value ? { value } : {})}
      bordered
    />
  );
};

export default PaginationLimitSelect;
