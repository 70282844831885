export interface CustomerDashboardState {
  creditBalance: CreditBalance;
  currentMonthUsage: CurrentMonthUsage;
  yearlyUsage: YearlyUsage;
  paymentType: string;
  summaryCreditsUsage: SummaryCreditsUsage;
}

export interface CreditBalance {
  monthlyCredits: number;
  creditsUsed: number;
  balance: number;
  // autoRenew: string;
}
export interface CurrentMonthUsage {
  currentMonth: string;
  dailyCreditUsageArray: DailyCreditUsage[];
  averageMonthlyUsage: number;
}
export interface DailyCreditUsage {
  day: number;
  creditUsage: number;
  usage: number;
}
export interface YearlyUsage {
  yearlyCreditUsageArray: YearlyCreditUsage[];
  limitExceeded: number;
}
export interface YearlyCreditUsage {
  month: number;
  year: number;
  creditUsage: number;
  creditCount: number;
  balance: number;
  balanceUsage: number;
  purchasedAmount: number;
}

export interface SummaryCreditsUsage {
  yearlyCreditDeviation: number;
  averageMonthlyUsage: number;
  yearlyCredits: number;
  yearlyUsage: number;
  contractDates: {
    start: Date;
    end: Date;
  };
}

export enum CustomerDashboardActionTypes {
  SET_CUSTOMER_DASHBOARD_STEP = 'SET_CUSTOMER_DASHBOARD_STEP',
  SET_CUSTOMER_DASHBOARD_DATA = 'SET_CUSTOMER_DASHBOARD_DATA',
  SET_CUSTOMER_INITIAL_STATE = 'SET_CUSTOMER_INITIAL_STATE',
}

export interface CDPSetStep {
  type: CustomerDashboardActionTypes.SET_CUSTOMER_DASHBOARD_STEP;
  payload: string;
}

export interface CDPSetData {
  type: CustomerDashboardActionTypes.SET_CUSTOMER_DASHBOARD_DATA;
  payload: {
    creditBalance: CreditBalance;
    currentMonthUsage: CurrentMonthUsage;
    yearlyUsage: YearlyUsage;
    summaryCreditsUsage: SummaryCreditsUsage;
    paymentType: string;
  };
}
export interface CDPSetInitialState {
  type: CustomerDashboardActionTypes.SET_CUSTOMER_INITIAL_STATE;
}

export type CustomerDashboardActions = CDPSetStep | CDPSetData | CDPSetInitialState;
