export const resizeTableRef = (
  tableRef: HTMLElement,
  frame: any,
  setFrame: (frame: any) => void,
  resizeProperty?: string,
) => {
  if (!tableRef) return;

  const resizeProp: string = resizeProperty || 'max-width';

  const tableMaxWidth = window.getComputedStyle(tableRef)?.getPropertyValue(resizeProp);

  if (tableMaxWidth) {
    if (frame) {
      window.cancelAnimationFrame(frame);
    }
    setFrame(
      window.requestAnimationFrame(() => {
        const numberWidth = +tableMaxWidth.split(/[a-z]/i)[0];
        const measureRaw = tableMaxWidth.split(/\d/i);
        const measure = measureRaw[measureRaw.length - 1];
        tableRef.style[resizeProp as any] = `${numberWidth + 1}${measure}`;
        setFrame(null);
        window.requestAnimationFrame(() => {
          if (tableRef) {
            tableRef.style[resizeProp as any] = tableMaxWidth;
          }
        });
      }),
    );
  }
};

export const getResizeTableRefFunc = (resizeProperty?: string) => {
  let frame = null as any;
  const setFrame = (frameFresh: any) => {
    frame = frameFresh;
  };
  return (tableRef: HTMLElement) => resizeTableRef(tableRef, frame, setFrame, resizeProperty);
};
