import { Reducer } from 'redux';
import { produce } from 'immer';

import { SessionActionTypes, SessionActions, SessionState, SessionTokens } from './types';
import { isPreviousSessionSaved } from 'utils/storage';

const initialState: SessionState = {
  interfaceLanguage: localStorage.getItem('language') || '',
  tokens: {} as SessionTokens,
  shouldSaveSession: isPreviousSessionSaved(),
  user: null,
  loading: false,
  sessionLoading: false,
};

const sessionReducer: Reducer<SessionState, SessionActions> = (state = initialState, action): SessionState => {
  return produce(state, (draft) => {
    switch (action.type) {
      case SessionActionTypes.SESSION_LOADING_START:
        draft.loading = true;
        return;

      case SessionActionTypes.SESSION_LOADING_FINISH:
        draft.loading = false;
        return;

      case SessionActionTypes.SET_TOKENS:
        draft.tokens = action.payload.tokens;
        return;

      case SessionActionTypes.SET_LANGUAGE:
        draft.interfaceLanguage = action.payload;
        return;

      case SessionActionTypes.GET_USER_SUCCESS:
        draft.user = action.payload.user;
        return;

      case SessionActionTypes.REMOVE_SESSION:
        return {
          ...initialState,
          shouldSaveSession: false,
          interfaceLanguage: localStorage.getItem('language') || '',
        };

      default:
        return state;
    }
  });
};

export default sessionReducer;
