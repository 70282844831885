import { Reducer } from 'redux';
import { produce } from 'immer';
import { ViewUserActionTypes, ViewUsersActionTypes, ViewUsersActions, ViewUsersState } from './types';

const initialState: ViewUsersState = {
  viewUsersCount: 0,
  viewUsers: [],
  firstViewUsers: [],

  pagination: {
    page: 0,
    count: 20,
    startViewUser: 1,
    endViewUser: 20,
  },
  search: '',
  counter: 0,
  viewUser: {
    email: '',
    name: '',
    timeFormat: '',
    isSendSystemErrorsMail: false,
    isSendCompareVersions: false,
    isAdminSales: true,
    isAdminFinance: true,
    isErrorReportEmail: true,
    isSendHardwareErrorsMail: true,
    customerId: '',
    id: '',
    status: false,
  },
};

const viewUsersReducer: Reducer<ViewUsersState, ViewUsersActions> = (state = initialState, action): ViewUsersState => {
  return produce(state, (draft: ViewUsersState) => {
    switch (action.type) {
      case ViewUsersActionTypes.VIEW_USERS_SET:
        draft.viewUsers = action.payload;
        return;

      case ViewUserActionTypes.VIEW_USER_SET:
        draft.viewUser = action.payload;
        return;
      case ViewUsersActionTypes.VIEW_USERS_FIRST_SET:
        draft.firstViewUsers = action.payload;
        return;
      case ViewUsersActionTypes.VIEW_USERS_SET_COUNT:
        draft.viewUsersCount = action.payload;
        return;
      case ViewUsersActionTypes.VIEW_USERS_SET_COUNT_FIRST:
        draft.counter = action.payload;
        return;
      case ViewUsersActionTypes.VIEW_USERS_SET_PAGINATION_COUNT:
        draft.pagination.count = action.payload;
        draft.pagination.page = 0;
        return;

      case ViewUsersActionTypes.VIEW_USERS_SET_PAGE:
        draft.pagination.page = action.payload;
        return;

      case ViewUsersActionTypes.VIEW_USERS_SET_SHOWN:
        draft.pagination.startViewUser = action.payload.startViewUser;
        draft.pagination.endViewUser = action.payload.endViewUser;
        return;

      case ViewUsersActionTypes.VIEW_USERS_SET_SEARCH:
        draft.search = action.payload;
        return;

      case ViewUsersActionTypes.VIEW_USERS_RESET:
        draft.viewUsers = initialState.viewUsers;
        draft.viewUsersCount = initialState.viewUsersCount;
        draft.firstViewUsers = initialState.firstViewUsers;
        draft.pagination = initialState.pagination;
        draft.search = initialState.search;
        draft.counter = initialState.counter;

        return;

      default:
        return state;
    }
  });
};

export default viewUsersReducer;
