export interface GlobalUsersState {
  globalUsers: GlobalUser[];
  firstGlobalUsers: GlobalUser[];
  pagination: Pagination;
  globalUsersCount: number;
  search: string;
  counter: number;
  globalUser: GlobalUserWithStatus;
}

export interface Pagination {
  page: number;
  count: number;
  startGlobalUser: number;
  endGlobalUser: number;
}

export interface GlobalUserWithStatus extends GlobalUser {
  status: boolean;
}

export interface GlobalUser {
  id: string;
  email: string;
  name: string;
  writeAccess: boolean;
  timeFormat: string;
  isSendSystemErrorsMail: boolean;
  isSendCompareVersions: boolean;
  isAdminSales: boolean;
  isAdminFinance: boolean;
  isErrorReportEmail: boolean;
  isSendHardwareErrorsMail: boolean;
}

export enum GlobalUsersActionTypes {
  GLOBAL_USERS_SET = 'GLOBAL_USERS_SET',
  GLOBAL_USERS_FIRST_SET = 'GLOBAL_USERS_FIRST_SET',

  GLOBAL_USERS_SET_COUNT = 'GLOBAL_USERS_SET_COUNT',
  GLOBAL_USERS_SET_COUNT_FIRST = 'GLOBAL_USERS_SET_COUNT_FIRST',
  GLOBAL_USERS_SET_PAGINATION_COUNT = 'GLOBAL_USERS_SET_PAGINATION_COUNT',
  GLOBAL_USERS_SET_PAGE = 'GLOBAL_USERS_SET_PAGE',
  GLOBAL_USERS_SET_SHOWN = 'GLOBAL_USERS_SET_SHOWN,',
  GLOBAL_USERS_SET_SEARCH = 'GLOBAL_USERS_SET_SEARCH,',
  GLOBAL_USER_SET = 'GLOBAL_USER_SET',
}

export interface GlobalUsersSet {
  type: GlobalUsersActionTypes.GLOBAL_USERS_SET;
  payload: GlobalUser[];
}

export interface GlobalUsersFirstSet {
  type: GlobalUsersActionTypes.GLOBAL_USERS_FIRST_SET;
  payload: GlobalUser[];
}

export interface GlobalUsersSetCount {
  type: GlobalUsersActionTypes.GLOBAL_USERS_SET_COUNT;
  payload: number;
}

export interface GlobalUserSetUser {
  type: GlobalUsersActionTypes.GLOBAL_USER_SET;
  payload: GlobalUserWithStatus;
}

export interface GlobalUsersSetCountFirst {
  type: GlobalUsersActionTypes.GLOBAL_USERS_SET_COUNT_FIRST;
  payload: number;
}

export interface GlobalUsersSetPaginationCount {
  type: GlobalUsersActionTypes.GLOBAL_USERS_SET_PAGINATION_COUNT;
  payload: number;
}

export interface GlobalUsersSetPage {
  type: GlobalUsersActionTypes.GLOBAL_USERS_SET_PAGE;
  payload: number;
}

export interface GlobalUsersSetShown {
  type: GlobalUsersActionTypes.GLOBAL_USERS_SET_SHOWN;
  payload: { startGlobalUser: number; endGlobalUser: number };
}

export interface GlobalUsersSetSearch {
  type: GlobalUsersActionTypes.GLOBAL_USERS_SET_SEARCH;
  payload: string;
}

export type GlobalUsersActions =
  | GlobalUsersSet
  | GlobalUsersFirstSet
  | GlobalUsersSetPaginationCount
  | GlobalUsersSetCount
  | GlobalUsersSetCountFirst
  | GlobalUsersSetPage
  | GlobalUsersSetShown
  | GlobalUserSetUser
  | GlobalUsersSetSearch;
