import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
  languageSelectorContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'left',
    alignItems: 'center',
    position: 'absolute',
    left: 30,
    bottom: 30,
    background: '#fff',
    border: '1px solid #F2F2F4',
    boxShadow: '5px 5px 50px rgba(0, 0, 0, 0.05)',
    borderRadius: '43px',
    padding: '0 16px',
    height: 43,
  },
  select: {
    width: '9.6rem',
  },
}));
