import React, { ReactElement } from 'react';
import { Button } from 'components/UI';
import { Icon } from 'components/UI/Icon';
import { useStyles } from './styles';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const PasswordHasBeenSet = (): ReactElement => {
  // Translation
  const { t } = useTranslation();

  // Routing
  const history = useHistory();

  const styles = useStyles();
  return (
    <div className={styles.setPasswordContainer}>
      <div className={styles.iconContainer}>
        <Icon name="auth-success" size={80} />
      </div>
      <h5 className={styles.formHeader}>{t('New password has been set')}</h5>
      <p>{t('Press the button below to log in')}</p>
      <Button
        labelKey={t('Log in')}
        className={styles.submitButton}
        type="button"
        fullWidth
        onClick={() => history.push('/')}
      />
    </div>
  );
};

export default PasswordHasBeenSet;
