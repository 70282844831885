import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => {
  const { palette } = theme;

  return {
    button: {
      appearance: 'none',
      height: '40px',
      border: 'none',
      display: 'flex',
      fontSize: '14px',
      justifyContent: 'center',
      alignItems: 'center',
      cursor: 'pointer',
      whiteSpace: 'nowrap',
      transition: 'all 0.3s',
      padding: '0 1rem',
      color: '#fff',
      backgroundColor: '#0F65E5',
      borderRadius: 10,
      fontWeight: 500,
      '&:hover': {
        backgroundColor: '#0A3677',
        color: '#BFC0CD',
      },
      '&:active': {
        backgroundColor: '#0A3677',
        color: '#BFC0CD',
      },
      '&:disabled': {
        background: '#0F65E6 !important',
        color: '#fff !important',
        opacity: 0.25,
      },
      '& svg': {
        marginRight: 7,
      },
    },
    textStyle: {
      padding: 0,
      color: palette.grey[700],
      background: 'none',
      '&:hover': {
        color: '#0F65E5',
        background: 'none',
      },
      '&:active': {
        color: palette.primary.dark,
      },
    },
    buttonStyle: {},
    attentionStyle: {
      padding: '0 1rem',
      color: palette.error.contrastText,
      backgroundColor: palette.error.main,
      fontWeight: 500,
      '&:hover': {
        backgroundColor: palette.error.dark,
        color: '#F6E1E5',
      },
      '&:active': {
        backgroundColor: palette.error.light,
      },
    },
    secondaryStyle: {
      padding: '0 1rem',
      color: '#666666',
      backgroundColor: '#E9E9EE',
      fontWeight: 500,
      '&:hover': {
        backgroundColor: '#666666',
      },
    },
    fullWidth: {
      width: '100%',
    },
    focused: {
      boxShadow: `0px 0px 2px 0px ${palette.primary.main}`,
    },
    successStyle: {
      padding: '0 1rem',
      color: palette.background.paper,
      backgroundColor: '#50AC65',
      fontWeight: 500,
      '&:hover': {
        backgroundColor: '#277037',
        color: '#E8FAEF',
      },
      '&:active': {
        backgroundColor: '#277037',
      },
    },
    emptyStyle: {
      padding: '0 1rem',
      color: '#0F65E5',
      backgroundColor: palette.background.paper,
      border: '2px solid #0F65E5',
      fontWeight: 500,
      '& svg path': {
        fill: '#0F65E5',
      },
      '&:hover': {
        backgroundColor: palette.background.paper,
        borderColor: '#0A3677',
        color: '#0A3677',
        '& svg path': {
          fill: '#0A3677',
        },
      },
      '&:active': {
        backgroundColor: palette.background.paper,
        borderColor: '#0A3677',
        color: '#0A3677',
        '& svg path': {
          fill: '#0A3677',
        },
      },
      '&:disabled': {
        backgroundColor: `${palette.background.paper} !important`,
        borderColor: '#0F65E5 !important',
        color: '#0F65E5 !important',
        '& svg path': {
          fill: '#0F65E5',
        },
        opacity: 0.4,
      },
    },
    xs: {
      height: 32,
      padding: '0 12px',
    },
  };
});
