import { Theme, makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  filterCell: {
    height: '37px',
    minHeight: '37px',
    padding: '0.2rem 18px',
    fontSize: '0.75rem',
    fontStyle: 'italic',
    borderRight: '1px solid #E9E9EE',
    borderBottom: '1px solid #E9E9EE',
    boxSizing: 'border-box',
    maxWidth: 'min-content',
    whiteSpace: 'nowrap',
    borderColor: '#E9E9EE',
    zIndex: 1,
    backgroundColor: theme.palette.background.default,
  },
  cellContent: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    height: '100%',
    alignItems: 'center',
  },
  searchLabel: {
    appearance: 'none',
    width: 'calc(100% - 16px)',
    border: 'none',
    pointerEvents: 'none',
    backgroundColor: 'transparent',
  },
  searchInput: {
    width: '16rem',
    height: '100%',
    margin: '0.2rem',
    padding: '0.3rem 0.4rem',
    borderRadius: 8,
    borderColor: '#0F65E5',
    outline: 'none',
    fontSize: '0.8125rem',
  },
  searchInputFocused: {
    fontSize: '0.8125rem',
    zIndex: 999,
    borderColor: '#0F65E5',
    borderRadius: 8,
    outline: 'none',
  },
}));
