// CustomerContractExpiration
import React, { FC, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Header from 'components/Header';
import PageWithDrawer from 'components/PageWithDrawer';
import { useStyles } from '../PurchaseReport/styles';
import { TableDataController, TableWithDateRangeAndShowPagination, TableWrapper } from 'components/Tables';
import { useSelector } from 'react-redux';
import { AppState } from 'store';
import { DEFAULT_PAGINATION_VALUE } from 'components/Paginations';
import { useDispatch } from 'react-redux';
import { apiGetBillingHistory } from '../../api';
import { Icon } from '../../components/UI/Icon';
import IconButton from '@material-ui/core/IconButton';
import { getBillingHistoryInvoiceAction } from '../../store/billingHistory/actions';
import { buildDateRange } from '../../utils/formating';
import { convertCentsToDollar } from '../ProductSettings';
import { UserRolesEnum } from 'utils/types';
import { format } from 'date-fns';
import { pageStyle } from 'pages/PurchaseReport/utils';

export interface Row {
  companyId: string;
  contractCreated: string;
  contractEndDate: string;
  contractId: string;
  contractStartDate: string;
  contractUpdated: string;
  createdAt: string;
  customer: string;
  id: string;
  isContractActive: boolean;
  message: string;
  moduleId: string;
  moduleName: string;
  notificationLevel: string;
  recipients: {
    admins: string[];
    customers: string[];
    enterpriseAdmins: string[];
    globalAdmins: string[];
    viewUsers: string[];
  };
  serverId: string;
  serverName: string;
  stepName: string;
  systemId: string;
  systemName: string;
  type: string;
  updatedAt: string;
  userRole: string;
}

export const isNotNull = (value: any) =>
  value !== null &&
  value !== undefined &&
  value !== '' &&
  value !== 'null' &&
  value !== 'undefined' &&
  value !== 'NaN' &&
  value !== 'Invalid Date' &&
  value.toString() !== 'Invalid Date';
export const handleSessionStoragePagination = (name: string, value: any) => {
  sessionStorage.setItem(name, value);
};
export const checkDate = (date: any) => {
  if (!isNotNull(date) && new Date(date).toString() === 'Invalid date') {
    return false;
  }

  return true;
};

export const toFixed_2 = (value: any) => {
  return String(Number(value).toFixed(2)).replace(/\.00/, '');
};

const COLUMNS = [
  {
    label: 'Date',
    dataKey: 'invoiceCreated',
    sortable: true,
    filterable: false,
  },
  {
    label: 'Organization',
    dataKey: 'organizationName',
    sortable: true,
    searchable: true,
  },
  {
    label: 'Account',
    dataKey: 'companyName',
    sortable: true,
    searchable: true,
  },
  {
    label: 'Total',
    dataKey: 'invoiceTotal',
    sortable: true,
    searchable: true,
  },
  {
    label: 'Status',
    dataKey: 'status',
    sortable: true,
    filterable: true,
  },
  {
    label: 'Invoice type',
    dataKey: 'subscriptionType',
    sortable: true,
    filterable: false,
  },
  {
    label: 'Download',
    dataKey: 'download',
    sortable: true,
    filterable: false,
  },
];

// const TIMEZONE_GMT_0 = 'Etc/GMT';

const ResourcesUsageReport: FC = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const classes = useStyles();

  const isAdmin: boolean = useSelector(
    (state: AppState) => !!state.session.user && state.session.user.role === UserRolesEnum.admin,
  );
  const isGlobalUser: boolean = useSelector(
    (state: AppState) => !!state.session.user && state.session.user.role === UserRolesEnum.globalUser,
  );
  const isOrganization: boolean = useSelector(
    (state: AppState) => !!state.session.user && state.session.user.role === UserRolesEnum.organization,
  );
  const isOrganizationUser: boolean = useSelector(
    (state: AppState) => !!state.session.user && state.session.user.role === UserRolesEnum.organizationUser,
  );
  const isCustomer: boolean = useSelector(
    (state: AppState) => !!state.session.user && state.session.user.role === UserRolesEnum.account,
  );

  const user = useSelector((state: AppState) => state.session.user);

  const [defaultFilters] = useState({
    order: {
      invoiceCreated: 'DESC',
    },
    pagination: {
      page: isNotNull(sessionStorage.getItem('billing-history__page'))
        ? Number(sessionStorage.getItem('billing-history__page'))
        : DEFAULT_PAGINATION_VALUE.page,
      limit: isNotNull(sessionStorage.getItem('billing-history__limit'))
        ? Number(sessionStorage.getItem('billing-history__limit'))
        : DEFAULT_PAGINATION_VALUE.limit,
    },
    show: isNotNull(sessionStorage.getItem('billing-history__show'))
      ? (sessionStorage.getItem('billing-history__show') as string)
      : isOrganizationUser || isOrganization
      ? 'organization'
      : isCustomer
      ? 'account'
      : 'all',
    dates: {
      start: new Date(
        isNotNull(sessionStorage.getItem('billing-history__dates-start'))
          ? (sessionStorage.getItem('billing-history__dates-start') as string)
          : new Date(),
      ),
      end: new Date(
        isNotNull(sessionStorage.getItem('billing-history__dates-end'))
          ? (sessionStorage.getItem('billing-history__dates-end') as string)
          : new Date(),
      ),
    },
  });

  const [isFirstPageLoad, setIsFirstPageLoad] = useState(true);

  const [showSelect, setShowSelect] = useState(defaultFilters.show);

  const labelsForSelect: {
    month: 'Month';
    year: 'Year';
  } = {
    month: 'Month',
    year: 'Year',
  };

  const tableProps = useMemo(
    () => ({
      columns: COLUMNS.filter((column) => {
        if (column.dataKey === 'organizationName') {
          return showSelect !== 'account' && (isAdmin || isGlobalUser);
        }

        if (column.dataKey === 'companyName') {
          return showSelect !== 'organization' && (isAdmin || isGlobalUser);
        }

        return true;
      }),
      dateFormat: user?.timeFormat,
      isNotEndDateLimited: false,
      defaultFilters,
      labelsForSelect,
      hideShow: isCustomer || isOrganizationUser || isOrganization,
    }),
    [user, defaultFilters, labelsForSelect],
  );

  const fetchData = async function (filtersChanged: any): Promise<any> {
    const { find, dates, order, pagination, show }: any = filtersChanged;

    if (!isFirstPageLoad) {
      sessionStorage.setItem('billing-history__show', show);
      sessionStorage.setItem('billing-history__page', pagination.page);
      sessionStorage.setItem('billing-history__limit', pagination.limit);
      sessionStorage.setItem('billing-history__dates-start', dates.start);
      sessionStorage.setItem('billing-history__dates-end', dates.end);
    }

    setIsFirstPageLoad(false);

    setShowSelect(show);

    const filters = {
      find: {
        ...(find || {}),
        ...(dates ? { dates: [...Object.values(buildDateRange(dates))] } : {}),
      },
      page: pagination?.page,
      count: pagination?.limit,
      sortBy: { ...order },
      show,
    };

    const {
      data: { data, count },
    } = await apiGetBillingHistory(filters);

    return {
      data: data.map((item: any, index: number) => {
        item.invoiceTotal = `$${toFixed_2(convertCentsToDollar(item.invoiceTotal))}`;

        const getInvoiceType = (type: string) => {
          switch (type) {
            case 'regular':
              return t('Subscription');
            case 'overuse':
              return t('Overuse');
            case 'regularModify':
              return t('Modification');
            default:
              return '';
          }
        };
        item.subscriptionType = getInvoiceType(item.subscriptionType);
        item.invoiceCreated = format(
          new Date(item.invoiceCreated),
          user?.timeFormat === 'DMY' ? 'dd/MM/yyyy' : 'MM/dd/yyyy',
        );

        item.download = (
          <IconButton
            onClick={() => {
              dispatch(getBillingHistoryInvoiceAction(item.invoiceNumber));
            }}
          >
            <Icon name={'download'} />
          </IconButton>
        );

        return {
          ...item,
          id: item.id || index,
        };
      }),
      filtersData: {
        show,
        count: count,
        find: {
          status: Object.values(
            data.reduce((accum: any, item: any) => {
              return {
                ...accum,
                [item.status]: {
                  value: item.status,
                  label: item.status,
                },
              };
            }, {}),
          ),
        },
      },
    };
  };

  return (
    <div className={classes.container}>
      <Header innerHeader={t('Billing History')} />
      <PageWithDrawer pageStyle={pageStyle}>
        <TableWrapper>
          <TableDataController
            render={(dataProps) => <TableWithDateRangeAndShowPagination {...dataProps} {...tableProps} />}
            fetchData={fetchData}
          />
        </TableWrapper>
      </PageWithDrawer>
    </div>
  );
};

export default ResourcesUsageReport;
