const getIconName = (label: string): string => {
  switch (label.toLowerCase()) {
    case 'customer-workflow':
      return 'workflow-icon';
    case '/dashboard':
    case '/workflow-files-monitoring':
    case '/resources-usage-report':
    case 'reports':
      return 'dashboard';
    case 'alerts':
    case 'servers':
    case '/map-dashboard':
    case '/alerts-map-dashboard':
    case '/customer-alerts':
    case '/servers-alert':
    case '/workflow-alert':
      return 'alerts';
    case '/customers':
    case 'customers-and-users':
      return 'customers';
    case '/view-users':
    case '/users':
      return 'view-users';
    case 'software':
    case '/system-and-modules-install-dates':
    case '/system-and-modules-expire-dates':
    case '/up-time-report':
      return 'software';
    case '/global-users':
      return 'global-users';
    case '/table':
      return 'table-view';
    case '/workflow-step':
      return 'purchase-history';
    case '/servers-status':
      return 'servers-status';
    case '/enterprise-administrators':
      return 'enterprise-administrators';
    case 'version-control':
    case '/global-version-control':
    case '/customer-version-control':
    case '/system-and-modules':
      return 'version-control';
    case 'billing-report':
    case '/purchase-report':
    case '/billing-history':
      return 'billing-report';
    case '/system-users':
      return 'system-users';
    case 'settings':
    case '/settings/color':
    case '/settings/language':
    case '/settings/workflow-steps':
    case '/settings/table-view':
      return 'settings';
    case 'help-and-support':
      return 'support';
    case 'account':
    case '/account':
    case '/contact-settings':
    case '/account/contact-settings':
    case '/account/monitoring-settings':
    case '/monitoring-settings':
    case '/account/billing-settings':
    case '/billing-settings':
    case '/product-settings':
    case '/account/product-settings':
      return 'maintenance';
    case 'sign-out':
      return 'exit';
    default:
      return '';
  }
};

export default getIconName;
