export interface DataGraph {
  caption: string;
  value: number;
  year: string;
}

export interface Graphs {
  type: string;
  data: DataGraph[];
  total: string;
}

export type StatusErrorTime = {
  workflow: number;
  software: number;
  hardwareAlive: number;
  workflowAlive: number;
  softwareAlive: number;
};

export enum StepStatus {
  Ok = 'ok',
  Working = 'working',
  Error = 'error',
  ConstantError = 'constant_error',
  NoKeepAlive = 'no_keep_alive',
  AgentError = 'agentError',
  HardwareKeepAliveError = 'keepAliveError',
  WorkflowKeepAliveError = 'keepAliveXMLError',
  SystemError = 'systemXMLError',
}

export interface StatusDetails {
  [StepStatus.Working]: boolean;
  [StepStatus.NoKeepAlive]: boolean;
  [StepStatus.Error]: boolean;
  [StepStatus.ConstantError]: boolean;
  [StepStatus.AgentError]?: boolean;
  [StepStatus.HardwareKeepAliveError]?: boolean;
  [StepStatus.WorkflowKeepAliveError]?: boolean;
  [StepStatus.SystemError]?: boolean;
  systemXMLErrorSeverity?: 'error' | 'warning';
  keepAliveSystemModuleXMLError?: boolean;
  keepAliveSystemXMLError?: boolean;
  isInMaintenance?: boolean;
  errorTime?: StatusErrorTime;
}

export interface MapPoint {
  address: string;
  url: string;
  name: string;
  id: string;
  coordinates: number[];
  userid: string;
  status: StepStatus;
  last_error: string;
  last_error_type: string;
  last_processed: string;
  last_status_processed: string;
  last_system_error: string;
  statusDetails: StatusDetails;
}
export interface MapDashboardState {
  totalYear: number;
  total: number;
  graphs: Graphs[];
  mapPoints: MapPoint[];
}
export enum MapDashboardActionTypes {
  SET_MAP_DASHBOARD_DATA = 'SET_MAP_DASHBOARD_DATA',
  SET_MAP_INITIAL_STATE = 'SET_MAP_INITIAL_STATE',
  SET_MAP = 'SET_MAP',
}
export interface MDPSetData {
  type: MapDashboardActionTypes.SET_MAP_DASHBOARD_DATA;
  payload: {
    totalYear: number;
    total: number;
    graphs: Graphs[];
    mapPoints: MapPoint[];
  };
}
export interface MDPSetMap {
  type: MapDashboardActionTypes.SET_MAP;
  payload: {
    mapPoints: MapPoint[];
  };
}
export interface MDPSetInitialState {
  type: MapDashboardActionTypes.SET_MAP_INITIAL_STATE;
}
export type MapDashboardActions = MDPSetInitialState | MDPSetData | MDPSetMap;
