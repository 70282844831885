import { Permission, Role, RoleWithOptions } from 'routing/types';

export type GetUserRoleArg = {
  role: Role;
  writeAccess: boolean;
  financialAccess: boolean;
};

type Permissions = Permission[];

export function getUserRole(user: GetUserRoleArg): RoleWithOptions {
  const role = user.role;

  const permissions = [] as Permissions;
  if (user.writeAccess) permissions.push('writeAccess');
  if (user.financialAccess) permissions.push('financialAccess');

  return { role, permissions };
}
