type ObjectLike = Record<string, any>;

export const mergeTwoObjects = (firstObject: ObjectLike, secondObject: ObjectLike): ObjectLike => {
  const keys = new Set([...Object.keys(firstObject), ...Object.keys(secondObject)]);
  return Array.from(keys.values()).reduce((mergedObject, key) => {
    const value = firstObject[key] ?? secondObject[key];
    if (value != undefined) {
      mergedObject[key] = value;
    }
    return mergedObject;
  }, {} as ObjectLike);
};
