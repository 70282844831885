import { Reducer } from 'redux';
import { produce } from 'immer';
import { UIActionTypes, UIActions, UIState } from './types';

export const initialState: UIState = {
  isLoading: true,
  isDrawerOpened: false,
  languages: [
    { value: 'en', label: 'English' },
    { value: 'es', label: 'Spanish' },
    { value: 'he', label: 'Hebrew' },
    { value: 'ar', label: 'Arabic' },
  ],
  workflowStepTypes: [],
  themeBrandColor: {
    brand: '',
    backgroundUrl: '',
    logoUrl: '',
  },
  isStripeEnabled: false,
};

const loadingReducer: Reducer<UIState, UIActions> = (state = initialState, action): UIState => {
  return produce(state, (draft: UIState) => {
    switch (action.type) {
      case UIActionTypes.UI_RESET_STATE:
        draft.workflowStepTypes = [];
        return;

      case UIActionTypes.UI_LOADING_START:
        draft.isLoading = true;
        return;

      case UIActionTypes.UI_LOADING_FINISH:
        draft.isLoading = false;
        return;

      case UIActionTypes.UI_TOGGLE_DRAWER:
        if (action.payload === undefined) {
          draft.isDrawerOpened = !state.isDrawerOpened;
        } else {
          draft.isDrawerOpened = action.payload || false;
        }
        return;

      case UIActionTypes.UI_SET_LANGUAGES:
        draft.languages = action.payload;
        return;

      case UIActionTypes.UI_SET_WORKFLOW_STEP_TYPES:
        draft.workflowStepTypes = action.payload;
        return;

      case UIActionTypes.UI_SET_THEME:
        draft.themeBrandColor = action.payload;
        return;

      case UIActionTypes.UI_SET_STRIPE_ENABLED:
        draft.isStripeEnabled = action.payload;
        return;
      default:
        return state;
    }
  });
};

export default loadingReducer;
