import { Reducer } from 'redux';
import { produce } from 'immer';
import {
  CustomerCardsInfoActionTypes,
  CustomerParametersActionTypes,
  CustomerParametersActions,
  CustomerParametersState,
  CustomerSubscriptionActionTypes,
} from './types';
import { initialState as uIinitialState } from 'store/ui/reducer';

const initialState: CustomerParametersState = {
  customer: {
    isUptimeAccess: true,
    name: '',
    timeZone: '',
    phoneNumber: '',
    language: '',
    email: '',
    timeFormat: '',
    country: '',
    isDeletedProtection: false,
    startTimeSendingReport: '',
    endTimeSendingReport: '',
    subscription: { id: '' },
  },
  contract: {
    startDate: '',
    endDate: '',
  },
  steps: {},
  company: {
    agentsLimit: 0,
    name: '',
    logo: '',
    address: '',
    url: '',
    autoLoginToken: '',
    coordinates: '',
    customerId: '',
    productType: {
      workflowMonitoring: true,
      workflowReports: true,
      serversMonitoring: true,
    },
  },
  isInOrganization: false,
  workflowStepTypes: uIinitialState.workflowStepTypes.map(({ value, label }) => {
    return { value, label, inUse: false };
  }),
  cardsInfo: {},
  subscription: {},
  subscriptionUpdatedSuccessful: false,
  subscriptionUpdatedReject: false,
  subscriptionFirstCardReject: false,
  isNotConnectedPaymentCard: {},
  errorMessage: '',
};

const customerParametersReducer: Reducer<CustomerParametersState, CustomerParametersActions> = (
  state = initialState,
  action,
) => {
  return produce(state, (draft: CustomerParametersState) => {
    switch (action.type) {
      case CustomerParametersActionTypes.CUSTOMER_PARAMETERS_SET:
        draft.customer = action.payload.customer;
        draft.contract = action.payload.contract;
        draft.steps = action.payload.steps;
        draft.company = action.payload.company;
        draft.isInOrganization = action.payload.isInOrganization;

        return;

      case CustomerParametersActionTypes.CUSTOMER_PARAMETERS_RESET:
        draft.customer = initialState.customer;
        draft.contract = initialState.contract;
        draft.steps = initialState.steps;
        draft.company.isXMLKeepAliveEnabled = false;
        draft.company.customerId = '';
        // draft.workflowStepTypes = initialState.workflowStepTypes;
        return;

      case CustomerParametersActionTypes.CUSTOMER_PARAMETERS_SET_WORKFLOW_STEP_TYPES:
        draft.workflowStepTypes = action.payload;
        return;

      case CustomerParametersActionTypes.CUSTOMER_PARAMETERS_SET_STEP_IN_USE:
        draft.workflowStepTypes = action.payload;
        return;
      case CustomerParametersActionTypes.CUSTOMER_PARAMETERS_SET_ADDRESS:
        draft.company.address = action.payload;
        return;
      case CustomerCardsInfoActionTypes.CUSTOMER_CARDS_INFO_SET:
        draft.cardsInfo = action.payload;
        return;
      case CustomerSubscriptionActionTypes.CUSTOMER_SUBSCRIPTION_SET:
        draft.subscription = action.payload;
        return;
      case CustomerSubscriptionActionTypes.CUSTOMER_SUBSCRIPTION_UPDATED_SUCCESSFUL:
        draft.subscriptionUpdatedSuccessful = action.payload;
        return;
      case CustomerSubscriptionActionTypes.CUSTOMER_SUBSCRIPTION_UPDATED_REJECT:
        draft.subscriptionUpdatedReject = action.payload;
        return;
      case CustomerSubscriptionActionTypes.CUSTOMER_SUBSCRIPTION_FIRST_CARD_REJECT:
        draft.subscriptionFirstCardReject = action.payload;
        return;
      case CustomerSubscriptionActionTypes.CUSTOMER_IS_NOT_CONNECTED_PAYMENT_CARD:
        draft.isNotConnectedPaymentCard = action.payload;
        return;
      case CustomerSubscriptionActionTypes.CUSTOMER_SET_ERROR_MESSAGE:
        draft.errorMessage = action.payload;
        return;

      default:
        return state;
    }
  });
};

export default customerParametersReducer;
