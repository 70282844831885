import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
  container: {
    position: 'relative',
    overflow: 'hidden',
    display: 'flex',
    margin: '0 0 30px 0',
    backgroundColor: 'rgba(242, 242, 242, 1)',
    width: '100%',
    height: '8px',
    borderRadius: '0.5rem',
  },
  indicator: {
    width: '100%',
    borderRadius: '0.5rem',
    transform: 'translateX(-100%)',
    transition: 'background-color 1s, transform 1s',
  },
  indicator2: {
    width: '100%',
    borderRadius: '0.5rem',
    transform: 'translateX(-100%)',
    transition: 'background-color 1s, transform 1s',
  },
  unTranslate: {
    transform: 'translateX(0)',
    transition: 'transform 1s',
  },
  strong: {
    backgroundColor: 'rgba(112, 182, 3, 1)',
    transform: 'translateX(0)',
    transition: 'background-color 1s, transform 1s',
  },
  moderate: {
    backgroundColor: 'rgba(245, 154, 35, 1)',
    transform: 'translateX(-30%)',
    transition: 'background-color 1s, transform 1s',
  },
  weak: {
    backgroundColor: 'rgba(217, 0, 27, 1)',
    transform: 'translateX(-75%)',
    transition: 'background-color 1s, transform 1s',
  },
  strong2: {
    backgroundColor: 'rgba(112, 182, 3, 1)',
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    borderRadius: '0.5rem',
    zIndex: 101,
    transition: 'background-color 1s, transform 1s',
  },
  moderate2: {
    backgroundColor: 'rgba(245, 154, 35, 1)',
    position: 'absolute',
    top: 0,
    left: 0,
    width: '70%',
    height: '100%',
    borderRadius: '0.5rem',
    zIndex: 102,
    transition: 'background-color 1s, transform 1s',
  },
  weak2: {
    backgroundColor: 'rgba(217, 0, 27, 1)',
    position: 'absolute',
    top: 0,
    left: 0,
    width: '25%',
    height: '100%',
    borderRadius: '0.5rem',
    zIndex: 103,
    transition: 'background-color 1s, transform 1s',
  },
}));
