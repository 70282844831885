import React, { FC } from 'react';
import cn from 'classnames';
import { useStyles } from '../../AdminPricingSettings/styles';

export const TransparentBorder: FC<{
  children: any;
  color: string;
  isActive: boolean;
  isShowShadow?: boolean;
  onClick: () => void;
}> = ({ children, color, isActive, onClick, isShowShadow = true }) => {
  const styles = useStyles();
  const cssContainerActive = isActive
    ? {
        borderColor: color,
      }
    : {
        borderColor: 'transparent',
      };

  return (
    <div
      className={cn(styles.transparentBorder, isActive && isShowShadow && styles.isActiveSubscriptionShadow)}
      style={cssContainerActive}
      onClick={onClick}
    >
      {children}
    </div>
  );
};
