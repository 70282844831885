import React, { useLayoutEffect, useMemo, useRef } from 'react';
import { getResizeTableRefFunc } from 'utils';
import { useStyles } from './styles';

export type TableWrapperProps = {
  isFullWidth?: boolean;
  top?: number;
  className?: string;
  style?: React.CSSProperties;
};

const TableWrapper: React.FC<TableWrapperProps> = ({ children, isFullWidth, top, className, style }) => {
  const tableRef = useRef<any>();
  const resizeTableRef = useMemo(() => getResizeTableRefFunc(), []);
  const styles = useStyles({ isFullWidth, top });

  useLayoutEffect(() => {
    resizeTableRef(tableRef.current);
  });

  return (
    <div className={`${styles.variableWidthContainer} ${className || ''}`} {...(style ? { style } : {})} ref={tableRef}>
      {children}
    </div>
  );
};

export default TableWrapper;
