import { uILoadingFinish, uILoadingStart } from 'store/ui/actions';
import { Dispatch } from 'redux';
import { showErrorNotification } from 'utils/notifications';
import {
  CardError,
  CardsInfo,
  Company,
  Contract,
  Customer,
  CustomerCardsInfoActionTypes,
  CustomerParametersActionTypes,
  CustomerParametersActions,
  CustomerSubscriptionActionTypes,
  Steps,
  Subscription,
  SubscriptionForServer,
  UpdateSubscriptionStatusEnum,
  WorkflowStepType,
} from './types';
import {
  apiGetCustomerParameters,
  apiGetSelfCustomerParameters,
  apiGetUserParameters,
  apiGetViewUserParent,
  cancelSubscription,
  createSubscription,
  getAccountWithCards,
  getSubscription,
  renewSubscription,
  retryPayment,
  startTrial,
  updateSubscription,
} from 'api';
import { WorkflowStepType as UIWorkflowStepType } from 'store/ui/types';
import { UserRolesEnum } from 'utils/types';

export const customerParametersSet = ({
  customer,
  contract,
  company,
  steps,
  isInOrganization,
}: {
  customer: Customer;
  contract: Contract;
  company: Company;
  steps: Steps;
  isInOrganization?: boolean;
}): CustomerParametersActions => {
  const modifiedContract = !!contract
    ? contract
    : {
        startDate: '',
        endDate: '',
      };
  return {
    type: CustomerParametersActionTypes.CUSTOMER_PARAMETERS_SET,
    payload: {
      contract: modifiedContract,
      customer,
      company,
      steps,
      isInOrganization,
    },
  };
};

const delayCallback = (delay: number) => {
  return new Promise((res, _) => {
    setTimeout(() => res(''), delay);
  });
};

const COUNTER_TIME_START = 8000;

/**
 * Returns state that represent current user's parameters to initial values
 */

export const customerCardsInfoSet = (data: CardsInfo): CustomerParametersActions => {
  return {
    type: CustomerCardsInfoActionTypes.CUSTOMER_CARDS_INFO_SET,
    payload: data,
  };
};

export const customerErrorMessageSet = (message: string): CustomerParametersActions => {
  return {
    type: CustomerSubscriptionActionTypes.CUSTOMER_SET_ERROR_MESSAGE,
    payload: message,
  };
};

export const setSubscriptionUpdatedSuccessful = (rule: boolean): CustomerParametersActions => {
  return {
    type: CustomerSubscriptionActionTypes.CUSTOMER_SUBSCRIPTION_UPDATED_SUCCESSFUL,
    payload: rule,
  };
};

export const setSubscriptionUpdatedReject = (rule: boolean): CustomerParametersActions => {
  return {
    type: CustomerSubscriptionActionTypes.CUSTOMER_SUBSCRIPTION_UPDATED_REJECT,
    payload: rule,
  };
};

export const setSubscriptionFirstCardReject = (rule: boolean): CustomerParametersActions => {
  return {
    type: CustomerSubscriptionActionTypes.CUSTOMER_SUBSCRIPTION_FIRST_CARD_REJECT,
    payload: rule,
  };
};

export const customerSubscriptionSet = (data: Subscription): CustomerParametersActions => {
  return {
    type: CustomerSubscriptionActionTypes.CUSTOMER_SUBSCRIPTION_SET,
    payload: data,
  };
};

export const customerIsNotConnectedPaymentCardSet = (rule: {
  error: CardError;
  toggle: boolean;
}): CustomerParametersActions => {
  return {
    type: CustomerSubscriptionActionTypes.CUSTOMER_IS_NOT_CONNECTED_PAYMENT_CARD,
    payload: rule,
  };
};

export const customerParametersReset = (): CustomerParametersActions => {
  return {
    type: CustomerParametersActionTypes.CUSTOMER_PARAMETERS_RESET,
  };
};

export const customerParametersSetWorkflowStepTypes = (receivedSteps: UIWorkflowStepType[]): Record<string, any> => {
  const cpSteps = receivedSteps.map(({ value, label }) => {
    return { value, label, inUse: false };
  });

  return {
    type: CustomerParametersActionTypes.CUSTOMER_PARAMETERS_SET_WORKFLOW_STEP_TYPES,
    payload: cpSteps,
  };
};

export const customerParametersSetAddress = (address: string): Record<string, any> => {
  return {
    type: CustomerParametersActionTypes.CUSTOMER_PARAMETERS_SET_ADDRESS,
    payload: address,
  };
};

/**
 * Set inUse flag. The flag means that current step has been already added to current customer's parameters and is not available to be added again
 * @param name - step's value as name
 */
export const customerParametersSetInUse = (name: string) => {
  return (dispatch: Dispatch, getState: () => Record<string, any>): void => {
    const workflowStepTypes: WorkflowStepType[] = getState().customerParameters.workflowStepTypes;
    const updatedSteps = workflowStepTypes.map(({ value, label, inUse }) => {
      return { value, label, inUse: name === value ? true : inUse };
    });
    dispatch({
      type: CustomerParametersActionTypes.CUSTOMER_PARAMETERS_SET_STEP_IN_USE,
      payload: updatedSteps,
    });
  };
};

/**
 * Reset inUse flag. inUse=false means that current step is available to be added to current customer's parameters
 * @param name - step's value
 */
export const customerParametersResetInUse = (name: string) => {
  return (dispatch: Dispatch, getState: () => Record<string, any>): void => {
    const workflowStepTypes: WorkflowStepType[] = getState().customerParameters.workflowStepTypes;
    const updatedSteps = workflowStepTypes.map(({ value, label, inUse }) => {
      return { value, label, inUse: name === value ? false : inUse };
    });
    dispatch({
      type: CustomerParametersActionTypes.CUSTOMER_PARAMETERS_SET_STEP_IN_USE,
      payload: updatedSteps,
    });
  };
};

export const getCustomerParameters = (id: string) => {
  return async (dispatch: (arg: any) => void, getState: () => Record<string, any>): Promise<any> => {
    dispatch({ type: 'getCustomerParameters' });

    dispatch(uILoadingStart());
    const isAdmin =
      getState().session.user?.role === UserRolesEnum.admin ||
      getState().session.user?.role === UserRolesEnum.globalUser;
    try {
      const response = isAdmin ? await apiGetCustomerParameters(id) : await apiGetUserParameters(id);
      if (response && response.data) {
        const { customer, isInOrganization } = response.data;

        for (const step in customer.steps) {
          customer.steps[step].notificationThreshold1 =
            typeof customer.steps[step].notificationThreshold1 === 'number'
              ? customer.steps[step].notificationThreshold1
              : undefined;
          customer.steps[step].notificationThreshold2 =
            typeof customer.steps[step].notificationThreshold2 === 'number'
              ? customer.steps[step].notificationThreshold2
              : undefined;
          customer.steps[step].purchaseAmount = customer.steps[step].purchaseAmount || undefined;
        }
        Object.keys(customer.steps).forEach((item: any) => {
          dispatch(customerParametersSetInUse(item));
        });

        dispatch(
          customerParametersSet({
            customer: customer.customer,
            contract: customer.contract,
            company: customer.company,
            steps: customer.steps,
            isInOrganization: isInOrganization ?? customer?.isInOrganization,
          }),
        );
        return customer;
      }
    } catch (e) {
      showErrorNotification(e as any);
    } finally {
      dispatch(uILoadingFinish());
    }
  };
};

export const getSelfCustomerParameters = (id: string) => {
  return async (dispatch: (arg: any) => void): Promise<any> => {
    dispatch({ type: 'getCustomerParameters' });

    dispatch(uILoadingStart());

    try {
      const response = await apiGetSelfCustomerParameters(id);
      if (response && response.data) {
        const { customer } = response.data;

        Object.keys(customer.steps).forEach((item: any) => {
          dispatch(customerParametersSetInUse(item));
        });

        dispatch(
          customerParametersSet({
            customer: customer.customer,
            contract: customer.contract,
            company: customer.company,
            steps: customer.steps,
          }),
        );

        return customer;
      }
    } catch (e) {
      showErrorNotification(e as any);
    } finally {
      dispatch(uILoadingFinish());
    }
  };
};

export const getViewUserParent = () => {
  return async (dispatch: (arg: any) => void): Promise<any> => {
    try {
      const response = await apiGetViewUserParent();

      if (response && response.status === 200) {
        return response;
      }
    } catch (e) {
      showErrorNotification(e as any);
    } finally {
      dispatch(uILoadingFinish());
    }
  };
};

export const retryPaymentSubscription = (callback: any) => {
  return async (dispatch: (arg: any) => void, getState: () => Record<string, any>): Promise<any> => {
    dispatch(uILoadingStart());

    try {
      await retryPayment();

      const retryIfIncomplete = async (counter: number) => {
        await dispatch(getSubscriptionDetails());
        await dispatch(getCardsInfo());

        const { subscription } = getState().customerParameters;

        if (subscription.status === 'incomplete' && counter < COUNTER_TIME_START) {
          dispatch(uILoadingStart());
          delayCallback(counter + 2000).then(() => retryIfIncomplete(counter + 2000));
        } else {
          dispatch(uILoadingFinish());

          if (subscription.status === 'incomplete') {
            callback(true);
          }
        }
      };

      delayCallback(3000).then(() => retryIfIncomplete(3000));
    } catch (e: any) {
      // showErrorNotification(e as any);
      callback(true);
      dispatch(getCardsInfo());
    } finally {
    }
  };
};

export const createSubscriptionDetails = (body: SubscriptionForServer, callback: any) => {
  return async (dispatch: (arg: any) => void): Promise<any> => {
    dispatch(uILoadingStart());

    try {
      await createSubscription(body);

      const retryIfIncomplete = async (counter: number) => {
        const response = await getSubscription();

        if (counter < 15000 && response?.data?.subscription?.status === 'incomplete') {
          delayCallback(counter).then(() => retryIfIncomplete(counter + 3000));
        } else if (response?.data?.subscription?.status === 'incomplete') {
          callback(true);
          dispatch(uILoadingFinish());
        } else {
          location.reload();
        }
      };

      delayCallback(3000).then(() => retryIfIncomplete(3000));
    } catch (e: any) {
      dispatch(uILoadingFinish());

      if (e.isAxiosError) {
        dispatch(customerErrorMessageSet(e.response.data.message));
      }

      showErrorNotification(e as any);
    } finally {
    }
  };
};

export const updateSubscriptionDetails = (body: SubscriptionForServer, cardsAmount: number) => {
  return async (dispatch: (arg: any) => void): Promise<any> => {
    dispatch(uILoadingStart());

    try {
      const result = await updateSubscription(body);
      const wasFirstCardRejected = result?.data?.subscription?.updateResult === UpdateSubscriptionStatusEnum.fail;
      if (wasFirstCardRejected && cardsAmount <= 1) {
        dispatch(uILoadingFinish());
        await dispatch(setSubscriptionUpdatedReject(true));
      } else {
        if (wasFirstCardRejected) {
          dispatch(uILoadingFinish());
          dispatch(setSubscriptionFirstCardReject(true));
        }

        const checkModificationStatus = async (counter: number) => {
          const response = await getAccountWithCards();
          const desiredProducts: any = body?.products;
          const currentProducts = response?.data?.account?.subscription?.products;
          const products = Object.keys(body?.products);
          const wasSubscriptionFailed = products.find(
            (product) => Number(currentProducts[product].quantity) !== Number(desiredProducts[product]),
          );
          if (
            !wasSubscriptionFailed &&
            response?.data?.account?.paymentStatus?.modificationStatus === 'pending' &&
            counter < 16000
          ) {
            delayCallback(counter + 2000).then(() => checkModificationStatus(counter + 2000));
          } else {
            dispatch(uILoadingFinish());

            if (response?.data?.account?.paymentStatus?.modificationStatus === 'approved' && !wasSubscriptionFailed) {
              await dispatch(setSubscriptionUpdatedSuccessful(true));
            } else if (
              response?.data?.account?.paymentStatus?.modificationStatus === 'rejected' ||
              wasSubscriptionFailed
            ) {
              await dispatch(setSubscriptionUpdatedReject(true));
            }
          }
          //modificationStatus
        };

        delayCallback(2000).then(() => checkModificationStatus(2000));
      }
    } catch (e) {
      showErrorNotification(e as any);
      dispatch(uILoadingFinish());
    }
  };
};

export const cancelSubscriptionDetails = () => {
  return async (dispatch: (arg: any) => void): Promise<any> => {
    dispatch(uILoadingStart());

    try {
      await cancelSubscription();
      dispatch(getSubscriptionDetails());
      dispatch(getCardsInfo());
    } catch (e) {
      showErrorNotification(e as any);
    } finally {
      dispatch(uILoadingFinish());
    }
  };
};

export const renewSubscriptionDetails = () => {
  return async (dispatch: (arg: any) => void): Promise<any> => {
    dispatch(uILoadingStart());

    try {
      await renewSubscription();
      dispatch(getSubscriptionDetails());
      dispatch(getCardsInfo());
    } catch (e) {
      showErrorNotification(e as any);
    } finally {
      dispatch(uILoadingFinish());
    }
  };
};

export const getSubscriptionDetails = () => {
  return async (dispatch: (arg: any) => void): Promise<any> => {
    dispatch(uILoadingStart());

    try {
      const response = await getSubscription();
      dispatch(customerSubscriptionSet(response.data.subscription));

      return response.data.subscription;
    } catch (e) {
      showErrorNotification(e as any);
    } finally {
      dispatch(uILoadingFinish());
    }
  };
};

export const startTrialAction = (data: any) => {
  return async (dispatch: (arg: any) => void): Promise<any> => {
    dispatch(uILoadingStart());

    try {
      await startTrial(data);
      location.reload();
    } catch (e) {
      showErrorNotification(e as any);
    } finally {
      dispatch(uILoadingFinish());
    }
  };
};

export const getCardsInfo = () => {
  return async (dispatch: (arg: any) => void): Promise<any> => {
    dispatch(uILoadingStart());

    try {
      const response = await getAccountWithCards();
      dispatch(customerCardsInfoSet(response.data));
    } catch (e) {
      showErrorNotification(e as any);
    } finally {
      dispatch(uILoadingFinish());
    }
  };
};

export const getCardsInfoWithRefresh = () => {
  return async (dispatch: (arg: any) => void, getState: () => Record<string, any>): Promise<any> => {
    dispatch(uILoadingStart());

    const handleAccountWithCard = async (counter: number) => {
      try {
        const response = await getAccountWithCards();

        const intentsNext = response.data?.account?.customerIntents;
        const intentsPrev = getState().customerParameters?.cardsInfo?.account?.customerIntents;

        if (
          counter < COUNTER_TIME_START &&
          !!intentsNext &&
          !!intentsPrev &&
          Object.keys(intentsPrev).length !== 2 &&
          Object.keys(intentsNext).length === Object.keys(intentsPrev).length
        ) {
          delayCallback(counter + 2000).then(() => handleAccountWithCard(counter + 2000));
        } else if (
          counter > COUNTER_TIME_START &&
          intentsNext &&
          intentsPrev &&
          Object.keys(intentsPrev).length !== 2 &&
          Object.keys(intentsNext).length === Object.keys(intentsPrev).length
        ) {
          dispatch(
            customerIsNotConnectedPaymentCardSet({
              error: CardError.DECLINED,
              toggle: true,
            }),
          );
          dispatch(uILoadingFinish());
        } else {
          dispatch(customerCardsInfoSet(response.data));
          dispatch(uILoadingFinish());
        }
      } catch (e) {
        showErrorNotification(e as any);
        dispatch(uILoadingFinish());
      } finally {
      }
    };

    delayCallback(3000).then(() => handleAccountWithCard(3000));
  };
};
