import React from 'react';
import cn from 'classnames';
import { useStyles } from '../../AdminPricingSettings/styles';
import { TrialExpiredLimitLabel } from '../types';
import { useTranslation } from 'react-i18next';
import { Icon } from 'components/UI/Icon';

export const TrialLimit = ({ isMaximumReached, label, value }: TrialExpiredLimitLabel) => {
  const styles = useStyles();
  const { t } = useTranslation();

  return (
    <div key={label} className={cn(styles.blockListItem)}>
      <span className={cn(styles.blockListItemTitle)}>{label}</span>
      <div className={styles.trialTextWithIcon}>
        <span
          className={cn(styles.blockListItemText, isMaximumReached ? styles.isMaximumReached : '', styles['fw--700'])}
        >
          {value}
        </span>
        {isMaximumReached && <Icon name="payment-error" />}
      </div>
      {isMaximumReached ? <span className={styles.blockListItemText}>{t('Max. reached')}</span> : <></>}
    </div>
  );
};
