import { Reducer } from 'redux';
import { produce } from 'immer';
import { MapDashboardActionTypes, MapDashboardActions, MapDashboardState } from './types';

const initialState: MapDashboardState = {
  total: 0,
  totalYear: 0,
  mapPoints: [],
  graphs: [{ type: '', data: [{ value: 0, caption: '', year: '' }], total: '' }],
};

export const mapDashboardReducer: Reducer<MapDashboardState, MapDashboardActions> = (state = initialState, action) => {
  return produce(state, (draft: MapDashboardState) => {
    switch (action.type) {
      case MapDashboardActionTypes.SET_MAP_DASHBOARD_DATA:
        draft.total = action.payload.total;
        draft.totalYear = action.payload.totalYear;
        draft.mapPoints = action.payload.mapPoints;
        draft.graphs = action.payload.graphs;
        return;
      case MapDashboardActionTypes.SET_MAP:
        draft.mapPoints = action.payload.mapPoints;
        return;

      default:
        return state;
    }
  });
};
