import React, { FC, ReactElement, memo } from 'react';
import { useStyles } from './styles';

interface Props {
  password: string;
  className?: string;
}

// type PasswordStrength = 'empty' | 'weak' | 'moderate' | 'strong';

const StrengthIndicator: FC<Props> = ({ password = '', className }: Props): ReactElement => {
  const styles = useStyles();
  const containerClasses = [styles.container, className ? className : ''].join(' ');

  let indicator = '';

  switch (true) {
    // eslint-disable-next-line
    case !!password.match(/^(?=.*?[A-Z])(?=(.*[a-z])+)(?=(.*\d)+)(?=(.*[!#$%&()*+@^\-])+)(?!.*\s).{9,}$/g):
      indicator = styles.strong;
      break;
    // eslint-disable-next-line
    case !!password.match(/^(?=.*?[A-Z])(?=(.*[a-z])+)(?=(.*\d)+)(?=(.*[!#$%&()*+@^\-])*)(?!.*\s).{8,}$/g):
      indicator = styles.moderate;
      break;
    case !!password.match(/^(?=(.*[A-Z])*)(?=(.*[a-z])*)(?=(.*\d)*).+$/g):
      indicator = styles.weak;
  }
  const indicatorClasses = [styles.indicator, indicator].join(' ');

  const renderFirst = (): ReactElement => {
    return (
      <div className={containerClasses}>
        <div className={indicatorClasses} />
      </div>
    );
  };
  const renderSecond = (): ReactElement => {
    return (
      <div className={containerClasses}>
        <div className={styles.indicator2}>
          <div className={styles.weak2}></div>
          <div className={styles.moderate2}></div>
          <div className={styles.strong2}></div>
        </div>
      </div>
    );
  };
  if (true) {
    return renderFirst();
  } else {
    return renderSecond();
  }
};

export default memo(StrengthIndicator);
