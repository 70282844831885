import { ChangeListener, Value } from './types';

export const getStartRecord = (value: Value, count: number): number => {
  if (!value || !count) {
    return 0;
  }
  const page = value.page - 1;

  return page * value.limit + 1;
};

export const getEndRecord = (value: Value, count: number): number => {
  if (!value || !count) {
    return 0;
  }
  const result = value.page * value.limit;

  return result > count ? count : result;
};

export const getNextPageDisabled = (value: Value, count: number): boolean => {
  const currentCount = value.page * value.limit;
  return currentCount >= count;
};

export const getPrevPageDisabled = (value: Value): boolean => value?.page === 1;

export const getPaginationSetter =
  (onChange: ChangeListener, value: Value) =>
  (args: Partial<Value>): any =>
    onChange({ ...value, ...args });
