// eslint-disable-next-line sort-imports
import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, useStyles } from './styles';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from 'store/session/actions';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { uIToggleDrawer } from 'store/ui/actions';
import { getAccountSettingsRoutes, getRoutes } from 'routing/routes';
import { RouteObj } from 'routing/types';
import { AppState } from 'store';
import { Icon, MenuIcon } from 'components/UI/Icon';
import { API_URL_BASE } from '../../api/apiAddresses';
import { GetUserRoleArg, getUserRole } from 'utils/getUserRole';
import getIconName from './getIconName';
import cn from 'classnames';
import { UserRolesEnum } from 'utils/types';

const DrawerMenu: FC = () => {
  const styles = useStyles();
  const history = useHistory();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const logo = useSelector((state: AppState) => state.ui.themeBrandColor.logoUrl);
  const user = useSelector((state: AppState) => state.session.user);
  const accessToken = useSelector((state: AppState) => state.session.tokens?.access);
  const footerMenuItemClassNames = useMemo(() => ({ icon: 'dim' }), []);
  const [expandedMenu, setExpandedMenu] = useState('');
  const [selectedRoutes, setSelectedRoutes] = useState([] as RouteObj[]);
  const [selectedAccountRoutes, setSelectedAccountRoutes] = useState([] as RouteObj[]);
  const [accountParametersRoute, setAccountParametersRoute] = useState(null as unknown as RouteObj);
  const containerRef = useRef(null as unknown as any);

  const subscriptionByOrgUser: any = useSelector((state: AppState) => state.customerParameters?.cardsInfo?.account);
  const togglesByOrgUser = subscriptionByOrgUser?.subscription?.toggles;

  const handleMenuClick = useCallback(
    (to: string): void => {
      dispatch(uIToggleDrawer(false));
      setTimeout(() => {
        history.push(to);
      }, 200);
    },
    [dispatch, history],
  );

  const handleSupportClick = useCallback((): void => {
    dispatch(uIToggleDrawer(false));
    window.open(`${API_URL_BASE}/api/help?authToken=${accessToken}`, '_blank');
  }, [accessToken, dispatch]);

  const getMenuExpandHandler = useCallback(
    (menuName: string) =>
      (_: any, isExpanded: boolean): void => {
        setExpandedMenu(isExpanded ? menuName : '');
      },
    [],
  );

  useEffect(() => {
    if (user?.role) {
      const roleWithPermissions = getUserRole(user as unknown as GetUserRoleArg);
      setSelectedRoutes(
        getRoutes(roleWithPermissions, {
          filterNav: true,
          omit: ['/account'],
          permissions: user.permissions,
          productType: user.productType,
          user,
          togglesByOrgUser,
        }),
      );
      if (
        user?.role === UserRolesEnum.account ||
        user?.role === UserRolesEnum.accountUser ||
        user?.role === UserRolesEnum.admin ||
        user?.role === UserRolesEnum.organization ||
        user?.role === UserRolesEnum.organizationUser
      ) {
        setSelectedAccountRoutes(
          getAccountSettingsRoutes(roleWithPermissions, {
            filterNav: true,
            omit: ['/account'],
            permissions: user?.permissions,
            productType: user?.productType,
            user,
          }),
        );
      }

      setAccountParametersRoute(
        getRoutes(roleWithPermissions, {
          filterNav: true,
          pick: ['/account'],
          permissions: user.permissions,
          productType: user.productType,
          user,
          togglesByOrgUser,
        })[0] || null,
      );
    } else {
      setSelectedRoutes([]);
      if (
        user?.role === UserRolesEnum.account ||
        user?.role === UserRolesEnum.accountUser ||
        user?.role === UserRolesEnum.admin ||
        user?.role === UserRolesEnum.organization ||
        user?.role === UserRolesEnum.organizationUser
      ) {
        setSelectedAccountRoutes([]);
      }
      setAccountParametersRoute(null as unknown as RouteObj);
    }
  }, [user]);

  const renderMenuButtonContent = useCallback(
    (route: string, labelKey: string, classNames: { icon?: string; text?: string }, isOmitIcon?: boolean) => {
      const iconName = !isOmitIcon && getIconName(route);
      const text = t(labelKey);

      return (
        <>
          <div className="icon-wrapper">
            {iconName ? <MenuIcon name={iconName} className={classNames.icon || ''} /> : null}
          </div>
          {/* wrapper with data-text is required to make ::before element hack to avoid container width changes caused by font weight shift. */}
          <span className={`${classNames.text || ''} text-wrapper`} data-text={text}>
            <span className="text">{text}</span>
          </span>
        </>
      );
    },
    [t],
  );

  const renderMenuButton = useCallback(
    ({
      content,
      isSubRoute,
      ...buttonProps
    }: {
      key?: string;
      onClick: () => void;
      content: React.ReactElement;
      isSubRoute?: boolean;
    }) => (
      <button
        {...buttonProps}
        type="button"
        className={cn(styles.menuItem, isSubRoute ? '' : styles.hoverMenuItem)}
        role="link"
      >
        {content}
      </button>
    ),
    [styles.menuItem],
  );

  const mapRoute = useCallback(
    function mapRouteFunction({ to, labelKey, subRoutes }: RouteObj, isSubRoute?: boolean): React.ReactElement | null {
      const classNames = { icon: 'dim', text: isSubRoute ? 'dim-text' : '' };
      const isFooterStyle = ['account'].includes(to);

      if (subRoutes?.length) {
        const isExpanded = expandedMenu === to;
        return (
          <Accordion square expanded={isExpanded} onChange={getMenuExpandHandler(to)} key={to}>
            <AccordionSummary
              aria-controls={`${to}-expandable-menu-content`}
              id={`${to}-expandable-menu-header`}
              expandIcon={<Icon name="arrowDown" />}
            >
              <div
                className={cn(styles.menuItem, {
                  ['active']: isExpanded,
                  [styles.menuItemFooter]: isFooterStyle,
                })}
              >
                {renderMenuButtonContent(
                  to,
                  labelKey,
                  isFooterStyle ? footerMenuItemClassNames : classNames,
                  isSubRoute,
                )}
              </div>
            </AccordionSummary>
            <AccordionDetails>
              <div className={cn(styles.menuBlock, 'no-padding')}>
                {subRoutes.map((subroute) => mapRouteFunction(subroute, true))}
              </div>
            </AccordionDetails>
          </Accordion>
        );
      }

      return renderMenuButton({
        key: to,
        onClick: () => handleMenuClick(to),
        content: renderMenuButtonContent(to, labelKey, classNames, isSubRoute),
        isSubRoute,
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      expandedMenu,
      getMenuExpandHandler,
      handleMenuClick,
      renderMenuButtonContent,
      styles.menuBlock,
      styles.menuItem,
      footerMenuItemClassNames,
      styles.footerMenuBlock,
      styles.menuItemFooter,
    ],
  );

  const supportButton = useMemo(
    () =>
      renderMenuButton({
        onClick: handleSupportClick,
        content: renderMenuButtonContent('help-and-support', 'Help & Support', footerMenuItemClassNames),
      }),
    [footerMenuItemClassNames, handleSupportClick, renderMenuButton, renderMenuButtonContent],
  );

  const accountParametersButton = useMemo(
    () =>
      accountParametersRoute &&
      renderMenuButton({
        onClick: () => handleMenuClick(accountParametersRoute.to),
        content: renderMenuButtonContent(
          accountParametersRoute.to,
          accountParametersRoute.labelKey,
          footerMenuItemClassNames,
        ),
      }),
    [accountParametersRoute, footerMenuItemClassNames, handleMenuClick, renderMenuButton, renderMenuButtonContent],
  );

  const signOutButton = useMemo(() => {
    const handleSignOut = (): void => {
      dispatch(uIToggleDrawer(false));
      setTimeout(async () => {
        await dispatch(logout());
        history.push('/');
      }, 200);
    };

    return renderMenuButton({
      onClick: handleSignOut,
      content: renderMenuButtonContent('sign-out', t('Sign Out'), footerMenuItemClassNames),
    });
  }, [dispatch, footerMenuItemClassNames, history, renderMenuButton, renderMenuButtonContent]);

  return (
    <div className={`${styles.container}`} ref={containerRef}>
      <div className={`${styles.menuBlock} ${styles.mainMenuBlock}`}>
        <div>
          <img className={styles.logo} src={logo} alt="logo" />
        </div>
        <div className={`${styles.menuBlock} no-padding`}>{selectedRoutes.map((route) => mapRoute(route))}</div>
      </div>
      <div className={`${styles.menuBlock} ${styles.footerMenuBlock}`}>
        <p className={styles.greetingText}>{t('Hello, {0}!').replace('{0}', user?.name || 'anonymous user')}</p>
        <div className={`${styles.menuBlock} no-padding`}>
          {supportButton}
          {user?.role === UserRolesEnum.account ||
          user?.role === UserRolesEnum.admin ||
          user?.role === UserRolesEnum.accountUser ||
          user?.role === UserRolesEnum.organization ||
          user?.role === UserRolesEnum.organizationUser ? (
            <div className={`${styles.menuBlock} no-padding`}>
              {selectedAccountRoutes.map((route) => mapRoute(route))}
            </div>
          ) : (
            accountParametersButton
          )}
          {signOutButton}
        </div>
      </div>
    </div>
  );
};

export default DrawerMenu;
