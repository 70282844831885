import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  container: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    width: '100vw',
    height: '64px',
    minHeight: '64px',
    padding: '17px 32px',
    color: '#212121',
    alignItems: 'center',
    justifyContent: 'space-between',
    background: '#FFFFFF',
    boxShadow: '0px 0px 32px rgba(0, 0, 0, 0.05)',
    zIndex: 10,
  },
  headersWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    '& h1': {
      margin: '0 0.5rem',
      fontSize: '1.6rem',
    },
    '& h2': {
      margin: '0 0.5rem',
      fontSize: '1.1rem',
    },
  },
  logo: {
    '& .agfaSt0': {
      fill: theme.palette.primary.contrastText,
    },
  },
  innerHeader: {
    fontSize: '20px',
    fontWeight: 700,
    color: '#212121',
    textTransform: 'capitalize',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  breadcrumbs: {
    display: 'flex',
    alignItems: 'center',
    width: 'calc(100% - 40px)',
    maxWidth: 1400,
    top: 64,
    height: 48,
    zIndex: 10,
    position: 'absolute',
    '& svg': {
      height: 11,
      marginRight: 13,
      marginLeft: 13,
      '& path': {
        fill: '#8E909D',
      },
    },
  },
  lastBreadcrumb: {
    '& span': {
      fontWeight: 700,
      color: '#212121',
    },
    '& svg path': {
      fill: '#212121',
    },
  },
  breadcrumb: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  disabledBreadcrumb: {
    cursor: 'default',
  },
  headerLink: {
    cursor: 'pointer',
    display: 'inline-block',
    maxWidth: '25rem',
    overflow: 'hidden',
    wordBreak: 'keep-all',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    fontWeight: 500,
    fontSize: '13px',
    lineHeight: '16px',
    color: '#8E909D',
  },
  logoImg: { maxHeight: '30px' },
  space: { width: '10rem', visibility: 'hidden' },
  disabledText: {
    cursor: 'auto',
  },
  /////////////////
  // Atomic Css //
  ////////////////
  ['cp']: {
    cursor: 'pointer',
  },
}));
