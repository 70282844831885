import {
  ForwardRefRenderFunction,
  ReactElement,
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import { Value as PaginationValue } from 'components/Paginations';
import { /*  fixPagination, */ mergeTwoObjects } from 'utils';

export type FiltersData = {
  count: number;
  [keyName: string]: any;
};

export type TableDataControllerAPIResponse = {
  data: any[];
  filtersData?: FiltersData;
};

export type TableDataControllerRequestFilters = {
  pagination?: PaginationValue;
  accountType?: string;
  [keyName: string]: any;
};

export type TableDataControllerProps = {
  shouldRenderIfHasData?: boolean;
  render: (dataProps: {
    data: any[];
    filtersData: FiltersData;
    applyChanges: (filtersUpdate: TableDataControllerRequestFilters) => void;
    filters: TableDataControllerRequestFilters;
  }) => ReactElement | null;
  fetchData: (filters: TableDataControllerRequestFilters) => Promise<TableDataControllerAPIResponse>;
};

export type TableDataControllerImperativeHandle = {
  refetch: () => void;
  updateData: (updater: (data: any[]) => any[]) => void;
  updateRequestFilters: (
    updater: (requestFilters: TableDataControllerRequestFilters) => TableDataControllerRequestFilters,
  ) => void;
};

const TableDataController: ForwardRefRenderFunction<TableDataControllerImperativeHandle, TableDataControllerProps> = (
  { render, fetchData, shouldRenderIfHasData },
  ref,
) => {
  const [tableData, setTableData] = useState([] as any[]);
  const [savedFiltersData, setSavedFiltersData] = useState({} as FiltersData);
  const [requestFilters, setRequestFilters] = useState({} as TableDataControllerRequestFilters);

  const getUpdatedRequestFilters = useCallback(
    (filters: TableDataControllerRequestFilters): TableDataControllerRequestFilters => {
      return mergeTwoObjects(filters || {}, requestFilters || {});
    },
    [requestFilters],
  );

  const setData = useCallback(
    (dataToProcess: TableDataControllerAPIResponse /* , filters: TableDataControllerRequestFilters */) => {
      if (dataToProcess) {
        const { data, filtersData } = dataToProcess;

        setTableData(data);

        if (!filtersData) {
          return;
        }

        setSavedFiltersData(filtersData);
      }
    },
    [],
  );

  const getData = useCallback(
    async (filters: TableDataControllerRequestFilters): Promise<void> => {
      setData(await fetchData({ ...filters }) /* , filters */);
    },
    [fetchData, setData],
  );

  const applyChanges = useCallback(
    (filtersUpdate: TableDataControllerRequestFilters) => {
      const filters = getUpdatedRequestFilters(filtersUpdate);
      if (!filtersUpdate.pagination && filters.pagination) {
        filters.pagination = { ...filters.pagination, page: 1 };
      }

      setRequestFilters(filters);
      getData(filters);
    },
    [getData, getUpdatedRequestFilters],
  );

  const refetch = useCallback(async () => {
    return await getData(requestFilters);
  }, [getData, requestFilters]);

  useImperativeHandle(ref, () => ({
    refetch,
    updateData: (updater: (data: any[]) => any[]) => {
      setTableData(updater(tableData));
    },
    updateRequestFilters: (
      updater: (requestFilters: TableDataControllerRequestFilters) => TableDataControllerRequestFilters,
    ) => {
      setRequestFilters(updater(requestFilters));
    },
  }));

  useEffect(() => {
    refetch();
  }, []);

  const mergedTableProps = {
    data: tableData,
    applyChanges,
    filtersData: savedFiltersData,
    filters: requestFilters,
  };

  if (shouldRenderIfHasData && !tableData.length) {
    return null;
  }

  return render(mergedTableProps);
};

export default forwardRef(TableDataController);
