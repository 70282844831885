import { SArecord, ServersAlertActionTypes, ServersAlertActions, SortBy } from './types';

export const SASetTableData = (records: SArecord[]): ServersAlertActions => {
  return {
    type: ServersAlertActionTypes.SA_SET_TABLE_DATA,
    payload: records,
  };
};

export const SASetRecordsCount = (customersCount: number): ServersAlertActions => {
  return {
    type: ServersAlertActionTypes.SA_SET_RECORDS_COUNT,
    payload: customersCount,
  };
};

export const SASetCount = (count: number): ServersAlertActions => {
  return {
    type: ServersAlertActionTypes.SA_SET_COUNT,
    payload: count,
  };
};

export const SASetAccountType = (type: string): ServersAlertActions => {
  return {
    type: ServersAlertActionTypes.SA_SET_ACCOUNT_TYPE,
    payload: type,
  };
};

export const SASetPage = (page: number): ServersAlertActions => {
  return {
    type: ServersAlertActionTypes.SA_SET_PAGE,
    payload: page,
  };
};

export const SASetShown = (startRecord: number, endRecord: number): ServersAlertActions => {
  return {
    type: ServersAlertActionTypes.SA_SET_SHOWN,
    payload: { startRecord, endRecord },
  };
};

export const SAResetFilters = (): ServersAlertActions => {
  return {
    type: ServersAlertActionTypes.SA_RESET_FILTERS,
  };
};

export const SASetFilterState = (columnName: string, selectedOptions: string[]): ServersAlertActions => {
  return {
    type: ServersAlertActionTypes.SA_SET_FILTER_STATE,
    payload: { columnName, selectedOptions },
  };
};

export const SASetFilterMultiSelect = (columnName: string, selectedOptions: string[]): ServersAlertActions => {
  return {
    type: ServersAlertActionTypes.SA_SET_FILTER_MULTI_SELECT,
    payload: { columnName, selectedOptions },
  };
};

export const SASetFilterMultiSelectPrev = (columnName: string, selectedOptionsPrev: string[]): ServersAlertActions => {
  return {
    type: ServersAlertActionTypes.SA_SET_FILTER_MULTI_SELECT_PREV,
    payload: { columnName, selectedOptionsPrev },
  };
};

export const SASetFilterMultiSelectShown = (
  columnName: string,
  selectedOptionsShown: string[],
): ServersAlertActions => {
  return {
    type: ServersAlertActionTypes.SA_SET_FILTER_MULTI_SELECT_SHOWN,
    payload: { columnName, selectedOptionsShown },
  };
};

export const SASetFilterSearch = (columnName: string, searchString: string | undefined): ServersAlertActions => {
  return {
    type: ServersAlertActionTypes.SA_SET_FILTER_SEARCH,
    payload: { columnName, searchString },
  };
};

export const SASetFilterSearchPrev = (
  columnName: string,
  searchStringPrev: string | undefined,
): ServersAlertActions => {
  return {
    type: ServersAlertActionTypes.SA_SET_FILTER_SEARCH_PREV,
    payload: { columnName, searchStringPrev },
  };
};

export const SAToggleSortBy = (columnName: string): ServersAlertActions => {
  return {
    type: ServersAlertActionTypes.SA_TOGGLE_SORT_BY,
    payload: columnName,
  };
};

export const SASetSortBy = (sortBy: SortBy): ServersAlertActions => {
  return {
    type: ServersAlertActionTypes.SA_SET_SORT_BY,
    payload: sortBy,
  };
};

export const SAGetTableData = (): ServersAlertActions => {
  return {
    type: ServersAlertActionTypes.SA_GET_TABLE_DATA,
  };
};

export const SASetSearch = (columnName: string, value: string): ServersAlertActions => {
  return {
    type: ServersAlertActionTypes.SA_SET_SEARCH,
    payload: { columnName, value },
  };
};

export const SASetStartDate = (date: Date): ServersAlertActions => {
  return {
    type: ServersAlertActionTypes.SA_SET_START_DATE,
    payload: date,
  };
};

export const SASetEndDate = (date: Date): ServersAlertActions => {
  return {
    type: ServersAlertActionTypes.SA_SET_END_DATE,
    payload: date,
  };
};
