import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import DateRangePicker from 'components/DateRangePicker';
import HorizontalScrollContainer from 'components/HorizontalScrollContainer';

const gap = '32px';

export const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100vh',
    backgroundColor: theme.palette.background.default,
  },
  accountTypeSelect: {
    '& label': {
      margin: 0,

      '& div': {
        width: '8rem',
      },
    },
  },
  accountSelect: {
    '& div': {
      minWidth: '280px',
      maxWidth: '280px',
    },
  },
  filtersWrapper: {
    display: 'flex',
    gap,
    marginBottom: 32,
  },
  chartsWrapper: {
    display: 'flex',
    flexWrap: 'nowrap',
    flexDirection: 'row',
    gap: '24px',
  },
  tableWrapper: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    maxWidth: 1400,
    width: '100%',
    height: '100%',
    margin: '48px auto 32px',
    padding: '32px',
    background: theme.palette.background.paper,
    border: '1px solid #E9E9EE',
    borderRadius: 16,
    maxHeight: 'calc(100vh - 145px)',
  },
  tableContainer: {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    borderRadius: 16,
    border: '1px solid #E9E9EE',
    maxHeight: 'calc(100vh - 472px)',
  },
  innerTableWrapper: {
    flex: 1,
    overflow: 'auto',
    '&::-webkit-scrollbar': {
      borderLeft: '1px solid #e9e9ee',
    },
  },
  emptyWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    background: theme.palette.background.paper,
    border: '1px solid #E9E9EE',
    borderRadius: 16,
    height: '100%',
    fontSize: 20,
    fontWeight: 700,
    lineHeight: '24px',
    color: '#212121',
  },
}));

export const StyledDateRangePicker = withStyles({
  root: {
    gap: 8,
  },
  datePicker: {
    width: '140px',
  },
})(DateRangePicker);

export const StyledHorizontalScrollContainer = withStyles({
  root: {
    paddingBottom: 14,
    position: 'relative',
  },
  wrapper: {
    marginBottom: 30,
    flexBasis: 127,
  },
})(HorizontalScrollContainer);
