import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => {
  return {
    label: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      fontSize: '13px',
      color: '#212121',
      fontWeight: 500,
      cursor: 'pointer',
      width: 'fit-content',
    },
    input: {
      margin: '0 8px 0 0',
      width: 18,
      height: 18,
    },
    labelKey: {
      marginTop: 0,
      marginBottom: 0,
    },
  };
});
