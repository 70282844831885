import { Dispatch } from 'redux';
import {
  apiAdminUpdateCustomer,
  apiCustomerSearch,
  apiDeleteCustomer,
  apiDeleteSystemUser,
  apiEnterpriseCustomerSearch,
  apiGetAdminCustomersList,
  apiGetHardwareAlerts,
  apiGetOrganizationCustomersList,
  apiGetPurchaseHistory,
  apiGetServersAlertCharts,
  apiGetServersAlertHistory,
  apiGetServersAlertHistoryXLS,
  apiGetSystemUsers,
  apiGetSystemUsersCustomersList,
  apiGetWorkflowAlertHistory,
  apiGetWorkflowAlertHistoryXLS,
  apiPostPurchasePayment,
  apiSendInvoice,
  apiUpdateUserAccountParameters,
} from 'api';
import { uILoadingFinish, uILoadingStart } from 'store/ui/actions';
import { Customer, CustomersActionTypes, CustomersActions } from './types';
import { NotificationTypes, showErrorNotification, showNotification } from 'utils/notifications';
import { buildDateRange } from 'utils/formating';
import { CustomerParameters } from 'store/customerParameters/types';
import { AxiosResponse } from 'axios';
import { getUser } from '../session/actions';
import { getADashboardAvailableDates } from '../adminDashboard/actions';
import { HASetTableColumns } from 'store/hardwareAlert/actions';
import { suSetCustomersList } from '../systemUsers/actions';
import { isNotNull } from '../../pages/BillingHistory';
import { t } from 'i18next';
import { AccountTypesEnum, UserRolesEnum } from 'utils/types';

const setCustomers = (customers: Customer[]): CustomersActions => {
  return {
    type: CustomersActionTypes.CUSTOMERS_SET_CUSTOMERS,
    payload: customers,
  };
};

export const setAccountType = (type: string): CustomersActions => {
  return {
    type: CustomersActionTypes.SET_ACCOUNT_TYPE,
    payload: type,
  };
};

export const setCustomersCount = (customersCount: number): CustomersActions => {
  return {
    type: CustomersActionTypes.CUSTOMERS_SET_CUSTOMERS_COUNT,
    payload: customersCount,
  };
};

export const setCount = (count: number): CustomersActions => {
  return {
    type: CustomersActionTypes.CUSTOMERS_SET_COUNT,
    payload: count,
  };
};

export const setPage = (page: number): CustomersActions => {
  return {
    type: CustomersActionTypes.CUSTOMERS_SET_PAGE,
    payload: page,
  };
};

export const setShown = (startRecord: number, endRecord: number): CustomersActions => {
  return {
    type: CustomersActionTypes.CUSTOMERS_SET_SHOWN,
    payload: { startRecord, endRecord },
  };
};

export const setSearch = (search: string): CustomersActions => {
  return {
    type: CustomersActionTypes.CUSTOMERS_SET_SEARCH,
    payload: search,
  };
};

export const getAdminCustomersList = (isFirstPageLoad?: boolean) => {
  return async (dispatch: Dispatch, getState: () => Record<string, any>): Promise<any> => {
    dispatch(uILoadingStart());
    const { count: countFromState } = getState().customers.pagination;
    const count =
      isFirstPageLoad && isNotNull(sessionStorage.getItem('accounts__count'))
        ? Number(sessionStorage.getItem('accounts__count'))
        : countFromState;

    const sessionAccountType = getState().session.user && getState().session.user.accountType;

    const accountTypeFromState =
      sessionAccountType === AccountTypesEnum.selfServed
        ? AccountTypesEnum.selfServed
        : getState().customers.accountType;
    const accountType: string =
      isFirstPageLoad && isNotNull(sessionStorage.getItem('accounts__account-type'))
        ? (sessionStorage.getItem('accounts__account-type') as string)
        : accountTypeFromState;

    const { page: pageFromState } = getState().customers.pagination;
    let page =
      isFirstPageLoad && isNotNull(sessionStorage.getItem('accounts__page'))
        ? Number(sessionStorage.getItem('accounts__page'))
        : pageFromState;

    const role = getState().session.user && getState().session.user.role;
    const roleOrgAdmin = role === UserRolesEnum.organization || role === UserRolesEnum.organizationUser;

    const { search } = getState().customers;

    try {
      const hasSearchRequest = roleOrgAdmin
        ? await apiEnterpriseCustomerSearch(search, page, count, accountType)
        : await apiCustomerSearch(search, page, count, accountType);

      const noSearchRequest = roleOrgAdmin
        ? await apiGetOrganizationCustomersList(page, count, accountType)
        : await apiGetAdminCustomersList(page, count, accountType);
      let response = search ? hasSearchRequest : noSearchRequest;

      if (response && response.data) {
        if (response.data.customers.customers?.length === 0 && page !== 0) {
          page = page - 1;

          dispatch(setPage(page));
          response = roleOrgAdmin
            ? await apiGetOrganizationCustomersList(page, count, accountType)
            : await apiGetAdminCustomersList(page, count, accountType); //for deleting last item on page
        }
      }

      if (response && response.data) {
        const startRecord = !!response.data.customers.customersCount ? page * count + 1 : 0;
        const endAvailableCustomer = (page + 1) * count;
        dispatch(setCustomers(response.data.customers.customers));
        let endRecord = endAvailableCustomer;
        if (response.data.customers.customersCount < endAvailableCustomer) {
          endRecord = response.data.customers.customersCount;
        }
        await dispatch(setCustomersCount(response.data.customers.customersCount));

        await dispatch(setShown(startRecord, endRecord));
      }
    } catch (e) {
      showErrorNotification(e as any);
    } finally {
      dispatch(uILoadingFinish());
    }
  };
};

export const updateCustomer = (id: string, customerParameters: CustomerParameters) => {
  return async (dispatch: (arg: any) => void, getState: () => Record<string, any>): Promise<number> => {
    dispatch(uILoadingStart());
    const isAdmin =
      getState().session.user.role === UserRolesEnum.admin || getState().session.user.role === UserRolesEnum.globalUser;
    const isOrganization =
      getState().session.user.role === UserRolesEnum.organization ||
      getState().session.user.role === UserRolesEnum.organizationUser;

    try {
      const response: AxiosResponse = isAdmin
        ? await apiAdminUpdateCustomer(id, customerParameters)
        : await apiUpdateUserAccountParameters(id, customerParameters, isOrganization);
      if (response && (response.status === 200 || response.status === 201)) {
        showNotification(NotificationTypes.success, t('Your changes are saved successfully!'));
        await dispatch(getUser());
        return response.status;
      }
    } catch (e) {
      showErrorNotification(e as any);
    } finally {
      dispatch(uILoadingFinish());
    }
    return 0;
  };
};

export const deleteCustomer = (id: string) => {
  return async (dispatch: (arg: any) => void): Promise<any> => {
    dispatch(uILoadingStart());
    try {
      const response: AxiosResponse = await apiDeleteCustomer(id);
      if (response && response.status === 200) {
        await dispatch(getADashboardAvailableDates());
        await dispatch(getAdminCustomersList());
        return response.status;
      }
    } catch (e) {
      showErrorNotification(e as any);
    } finally {
      dispatch(uILoadingFinish());
    }
    return 0;
  };
};

export const handlePrePaidPurchase = (data: Record<string, any>) => {
  return async (dispatch: (arg: any) => void): Promise<any> => {
    dispatch(uILoadingStart());

    try {
      const response: false | AxiosResponse = await apiPostPurchasePayment(data);
      if (response && (response.status === 201 || response.status === 200)) {
        return response;
      }
    } catch (e) {
      showErrorNotification(e as any);
    }
  };
};

export const handleGetPurchaseHistory = (params: Record<string, any>) => {
  return async (dispatch: (arg: any) => void, getState: () => Record<string, any>): Promise<any> => {
    dispatch(uILoadingStart());
    const isOrganization = getState().session.user.role === UserRolesEnum.organization;

    try {
      const response = await apiGetPurchaseHistory(params, isOrganization);
      if (response && response.status === 201) {
        dispatch(uILoadingFinish());
        return response;
      }
    } catch (e) {
      showErrorNotification(e as any);
      dispatch(uILoadingFinish());
    }
  };
};

export const handlePostInvoiceFile = (params: Record<string, any>, formData: FormData) => {
  return async (dispatch: (arg: any) => void): Promise<any> => {
    dispatch(uILoadingStart());
    try {
      const response = await apiSendInvoice(params, formData);
      if (response && response.status === 201) {
        return response;
      }
    } catch (e) {
      showErrorNotification(e as any);
    } finally {
      dispatch(uILoadingFinish());
    }
  };
};

export const handleGetSystemUsers = (params: Record<string, any>, isOrganization: boolean) => {
  return async (dispatch: (arg: any) => void): Promise<any> => {
    dispatch(uILoadingStart());
    try {
      const response = await apiGetSystemUsers(params, isOrganization);
      if (response && response.status === 201) {
        return response;
      }
    } catch (e) {
      showErrorNotification(e as any);
    } finally {
      dispatch(uILoadingFinish());
    }
  };
};

export const handleDeleteSystemUser = (id: string) => {
  return async (dispatch: (arg: any) => void): Promise<any> => {
    dispatch(uILoadingStart());

    try {
      const response = await apiDeleteSystemUser(id);

      if (response && (response.status === 201 || response.status === 200)) {
        return response;
      }
    } catch (e) {
      showErrorNotification(e as any);
    } finally {
      dispatch(uILoadingFinish());
    }
  };
};

export const handleGetSystemUsersCustomersList = (params: string) => {
  return async (dispatch: (arg: any) => void): Promise<any> => {
    dispatch(uILoadingStart());
    try {
      const response = await apiGetSystemUsersCustomersList(params);
      if (response && (response.status === 201 || response.status === 200)) {
        dispatch(suSetCustomersList(response.data));
        return response;
      }
    } catch (e) {
      showErrorNotification(e as any);
    } finally {
      dispatch(uILoadingFinish());
    }
  };
};

export const handleGetServersAlertHistory = (params: Record<string, any>) => {
  return async (dispatch: (arg: any) => void): Promise<any> => {
    dispatch(uILoadingStart());
    try {
      const optional = params.dates ? { dates: buildDateRange(params.dates) } : {};

      const response = await apiGetServersAlertHistory({ ...params, ...optional });

      if (response && response.status === 201) {
        return response;
      }
    } catch (e) {
      showErrorNotification(e as any);
    } finally {
      dispatch(uILoadingFinish());
    }
  };
};

export const handleGetServersAlertCharts = (params: Record<string, any>) => {
  return async (): Promise<any> => {
    try {
      const response = await apiGetServersAlertCharts(params);

      if (response && response.status === 201) {
        return response;
      }
    } catch (e) {
      showErrorNotification(e as any);
    } finally {
    }
  };
};

export const handleGetWorkflowAlertHistory = (params: Record<string, any>) => {
  return async (dispatch: (arg: any) => void): Promise<any> => {
    try {
      const dates = params.dates ? { dates: buildDateRange(params.dates) } : {};

      const response = await apiGetWorkflowAlertHistory({ ...params, ...dates });

      if (response && response.status === 201) {
        return response;
      }
    } catch (e) {
      showErrorNotification(e as any);
    } finally {
      dispatch(uILoadingFinish());
    }
  };
};

export const handleGetWorkflowAlertHistoryXLS = (params: Record<string, any>) => {
  return async (dispatch: (arg: any) => void): Promise<any> => {
    try {
      const response = await apiGetWorkflowAlertHistoryXLS({ ...params, dates: buildDateRange(params.dates) });

      if (response && response.status === 201) {
        return response;
      }
    } catch (e) {
      showErrorNotification(e as any);
    } finally {
      dispatch(uILoadingFinish());
    }
  };
};

export const handleGetServersAlertHistoryXLS = (
  params: Record<string, any>,
): ((dispatch: (arg: any) => void) => Promise<any>) => {
  if (params.dates) {
    params.dates = buildDateRange(params.dates);
  }
  return async (dispatch: (arg: any) => void): Promise<any> => {
    try {
      const response = await apiGetServersAlertHistoryXLS(params);

      if (response && response.status === 201) {
        return response;
      }
    } catch (e) {
      showErrorNotification(e as any);
    } finally {
      dispatch(uILoadingFinish());
    }
  };
};

export const getHardwareCustomersList = (customerName?: string, isFirstPageLoad?: boolean) => {
  return async (dispatch: Dispatch, getState: () => Record<string, any>): Promise<any> => {
    dispatch(uILoadingStart());
    const { count: countFromState } = getState().customers.pagination;

    const user = getState().session.user;

    const isAdmin: boolean = user?.role === UserRolesEnum.admin;
    const isGlobalUser: boolean = user?.role === UserRolesEnum.globalUser;
    const isOrganization: boolean = user?.role === UserRolesEnum.organization;
    const isOrganizationUser: boolean = user?.role === UserRolesEnum.organizationUser;
    const isCustomerSession: boolean = user?.role === UserRolesEnum.account;
    const isViewUser: boolean = user?.role === UserRolesEnum.accountUser;

    const userSessionAccountType: string | undefined = user?.accountType;
    const isSelf = userSessionAccountType === AccountTypesEnum.selfServed;
    const isHideAccountTypeSelect = isSelf && (isOrganization || isOrganizationUser || isCustomerSession || isViewUser);

    const count =
      isFirstPageLoad && isNotNull(sessionStorage.getItem('accounts__count'))
        ? Number(sessionStorage.getItem('accounts__count'))
        : countFromState;

    const { search } = getState().customers;
    const accountTypeFromState = getState().customers.accountType;
    const accountType: string =
      isFirstPageLoad && isNotNull(sessionStorage.getItem('accounts__account-type'))
        ? (sessionStorage.getItem('accounts__account-type') as string)
        : isHideAccountTypeSelect
        ? AccountTypesEnum.selfServed
        : isAdmin || isGlobalUser
        ? accountTypeFromState
        : AccountTypesEnum.managed;

    const { page: pageFromState } = getState().customers.pagination;
    let page =
      isFirstPageLoad && isNotNull(sessionStorage.getItem('accounts__page'))
        ? Number(sessionStorage.getItem('accounts__page'))
        : pageFromState;

    const { columnsFilters } = getState().hardwareAlerts;
    const enterpriseId =
      getState().user?.role === UserRolesEnum.organization ? { enterpriseId: getState().user?.enterpriseId } : {};

    const searchObject = { columnName: 'name', value: customerName || search };
    const columnsFind = Object.keys(columnsFilters).map((item) => ({
      columnName: item,
      value: columnsFilters[item]?.searchString,
      values: columnsFilters[item]?.selectedOptions,
    }));

    const params = {
      accountType,
      page: page + 1,
      limit: count,
      find: [searchObject, ...columnsFind],
      ...enterpriseId,
    };

    try {
      const response = await apiGetHardwareAlerts(params);

      if (response && response.data) {
        if (response.data.data.length === 0 && page !== 0) {
          page = page - 1;

          dispatch(setPage(page));
        }
      }

      if (response && response.data) {
        const startRecord = !!response.data.count ? page * count + 1 : 0;
        const endAvailableCustomer = (page + 1) * count;
        await dispatch(setCustomers(response.data.data));
        let endRecord = endAvailableCustomer;
        if (response.data.count < endAvailableCustomer) {
          endRecord = response.data.count;
        }
        await dispatch(setCustomersCount(response.data.count));
        await dispatch(setShown(startRecord, endRecord));
        await dispatch(HASetTableColumns(response.data.modulesAndSeverities));
      }
    } catch (e) {
      showErrorNotification(e as any);
    } finally {
      dispatch(uILoadingFinish());
    }
  };
};
