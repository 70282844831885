import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  ToggleSwitch: {},
  root: {
    width: 36,
    height: 20,
    padding: 0,
    '& .MuiSwitch-colorSecondary.Mui-disabled': {
      color: '#bdbdbd !important',
      '& + $track': {
        backgroundColor: 'black!important',
        opacity: '0.12',
        border: '1px solid #bdbdbd',
      },
    },
  },
  switchBase: {
    padding: 1,
    '&$checked': {
      transform: 'translateX(16px)',
      color: theme.palette.common.white,
      '& + $track': {
        backgroundColor: theme.palette.secondary.main,
        opacity: 1,
        border: 'none',
      },
      '& $thumb': {
        backgroundColor: theme.palette.background.paper,
      },
    },
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  thumb: {
    width: 16,
    height: 16,
    boxShadow: 'none',
    border: 'none',
    outline: 'none',
    backgroundColor: '#E9E9EE',
    margin: 1,
    transition: theme.transitions.create(['background-color']),
  },
  thumbGB: {
    width: 24,
    height: 24,
    position: 'relative',
    '&::after': {
      content: '"GB"',
      fontSize: '11px',
      fontWeight: '700',
      position: 'absolute',
      color: '#333333',
      bottom: 0,
      width: 24,
      height: 24,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      opacity: 0.2,
    },
  },
  thumbActiveGB: {
    width: 24,
    height: 24,
    position: 'relative',
    '&::after': {
      content: '"GB"',
      fontSize: '11px',
      fontWeight: '700',
      position: 'absolute',
      color: '#333333',
      bottom: 0,
      width: 24,
      height: 24,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
  },
  track: {
    borderRadius: 32,
    border: `1px solid #E9E9EE`,
    backgroundColor: theme.palette.background.paper,
    opacity: 1,
    transition: theme.transitions.create(['background-color', 'border']),
  },
  checked: {},
  focusVisible: {},
  switchLabel: {
    display: 'flex',
    flexDirection: 'row',
    margin: 0,
    alignItems: 'center',
    fontSize: '0.8125rem',
    color: theme.palette.grey['700'],

    '&.no-margin': {
      margin: 0,
      paddingBottom: 1,
    },

    '&.reverse-layout': {
      flexDirection: 'row-reverse',
      justifyContent: 'flex-end',
    },
  },
  switchTitle: {
    color: '#212121',
    margin: '0 10px',
    fontWeight: 500,
    fontSize: 14,
  },
}));
