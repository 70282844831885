import {
  MonitoringUser,
  MonitoringUsersActionTypes,
  SUSetCustomersList,
  SUSetCustomersListReset,
  SortBy,
  SystemUser,
  SystemUsersActionTypes,
  SystemUsersActions,
  SystemUsersCustomersActionTypes,
} from './types';
import { Dispatch } from 'redux';
import { uILoadingFinish, uILoadingStart } from '../ui/actions';
import {
  apiAccountCreate,
  apiAccountGetOne,
  apiAccountUpdate,
  apiMonitoringUserCreate,
  apiMonitoringUserDelete,
  apiMonitoringUserList,
  apiMonitoringUserListAll,
  apiMonitoringUserOne,
  apiMonitoringUserUpdate,
} from '../../api';
import { NotificationTypes, showErrorNotification, showNotification } from '../../utils/notifications';
import { t } from 'i18next';

export const setMonitoringUser = (data: MonitoringUser[]): SystemUsersActions => {
  return {
    type: MonitoringUsersActionTypes.SET_MONITORING_USERS,
    payload: data,
  };
};
// TODO change any to normal type/interface view
export const setMonitoringUserList = (data: any): SystemUsersActions => {
  return {
    type: MonitoringUsersActionTypes.SET_MONITORING_USERS_LIST,
    payload: data,
  };
};
// TODO change any to normal type/interface view
export const setMonitoringUserListAll = (data: any): SystemUsersActions => {
  return {
    type: MonitoringUsersActionTypes.SET_MONITORING_USERS_LIST_ALL,
    payload: data,
  };
};

export const suSetRecords = (records: SystemUser[]): SystemUsersActions => {
  return {
    type: SystemUsersActionTypes.SU_SET_RECORDS,
    payload: records,
  };
};

export const suSetCount = (count: number): SystemUsersActions => {
  return {
    type: SystemUsersActionTypes.SU_SET_COUNT,
    payload: count,
  };
};

export const suSetCustomersList = (customers: any): SUSetCustomersList => {
  return {
    type: SystemUsersCustomersActionTypes.SU_SET_CUSTOMERS_LIST,
    payload: customers,
  };
};

export const suSetCustomersListReset = (): SUSetCustomersListReset => {
  return {
    type: SystemUsersCustomersActionTypes.SU_SET_CUSTOMERS_RESET_LIST,
    payload: '',
  };
};

export const suSetAccountType = (type: string): SystemUsersActions => {
  return {
    type: SystemUsersActionTypes.SU_SET_ACCOUNT_TYPE,
    payload: type,
  };
};

export const suSetPage = (page: number): SystemUsersActions => {
  return {
    type: SystemUsersActionTypes.SU_SET_PAGE,
    payload: page,
  };
};

export const suSetSearch = (name: string): SystemUsersActions => {
  return {
    type: SystemUsersActionTypes.SU_SET_SEARCH,
    payload: name,
  };
};

export const suSetRecordsCount = (recordsCount: number): SystemUsersActions => {
  return {
    type: SystemUsersActionTypes.SU_SET_RECORDS_COUNT,
    payload: recordsCount,
  };
};

export const suSetShown = (startRecord: number, endRecord: number): SystemUsersActions => {
  return {
    type: SystemUsersActionTypes.SU_SET_SHOWN,
    payload: { startRecord, endRecord },
  };
};

export const suToggleSortBy = (columnName?: string): SystemUsersActions => {
  return {
    type: SystemUsersActionTypes.SU_TOGGLE_SORT_BY,
    payload: columnName ? columnName : '',
  };
};

export const suSetSortBy = (sortBy: SortBy): SystemUsersActions => {
  return {
    type: SystemUsersActionTypes.SU_SET_SORT_BY,
    payload: sortBy,
  };
};

export const suSetAccount = (data: any): SystemUsersActions => {
  return {
    type: SystemUsersCustomersActionTypes.SU_SET_ACCOUNT,
    payload: data,
  };
};

export const getAccountOne = (id: string, isFromUpdate?: boolean): any => {
  return async (dispatch: Dispatch): Promise<any> => {
    dispatch(uILoadingStart());

    try {
      const response = await apiAccountGetOne(id);
      if (response && response.status === 200) {
        await dispatch(
          suSetAccount({
            ...response.data.customer,
            isFromUpdate,
            customer: { ...response.data.customer.customer, status: true, isFromUpdate, id },
          }),
        );
        return response;
      }
    } catch (e) {
      showErrorNotification(e as any);
    } finally {
      dispatch(uILoadingFinish());
    }
    return 0;
  };
};

export const createAccount = (accountParameters: any) => {
  return async (dispatch: Dispatch): Promise<any> => {
    dispatch(uILoadingStart());

    try {
      const response = await apiAccountCreate(accountParameters);

      if (response && response.status === 201) {
        dispatch(getAccountOne(response.data.createdResult.id, true));
        return response;
      }
    } catch (e) {
      showErrorNotification(e as any);
    } finally {
      dispatch(uILoadingFinish());
    }
    return 0;
  };
};

export const updateAccount = (accountParameters: any, id: string) => {
  return async (dispatch: Dispatch): Promise<any> => {
    dispatch(uILoadingStart());

    try {
      const response = await apiAccountUpdate(accountParameters, id);
      if (response && response.status === 200) {
        dispatch(getAccountOne(id, true));
        return response.status;
      }
    } catch (e) {
      showErrorNotification(e as any);
    } finally {
      dispatch(uILoadingFinish());
    }
    return 0;
  };
};

export const updateMonitoringUser = (parameters: any, id: string) => {
  return async (dispatch: Dispatch): Promise<any> => {
    dispatch(uILoadingStart());

    try {
      const response = await apiMonitoringUserUpdate(parameters, id);
      if (response && (response.status === 200 || response.status === 201)) {
        dispatch(setMonitoringUser({ ...response.data.updatedResult, status: true }));
        showNotification(NotificationTypes.success, t('Your changes are saved successfully!'));
        return response;
      }
    } catch (e) {
      showErrorNotification(e as any);
    } finally {
      dispatch(uILoadingFinish());
    }
    return 0;
  };
};

export const createMonitoringUser = (parameters: any) => {
  return async (dispatch: Dispatch): Promise<any> => {
    dispatch(uILoadingStart());

    try {
      const response = await apiMonitoringUserCreate(parameters);
      if (response && (response.status === 200 || response.status === 201)) {
        dispatch(setMonitoringUser({ ...response.data.createdResult, status: true }));
        showNotification(NotificationTypes.success, t('Your changes are saved successfully!'));
        return response.status;
      }
    } catch (e) {
      showErrorNotification(e as any);
    } finally {
      dispatch(uILoadingFinish());
    }
    return 0;
  };
};

export const deleteMonitoringUser = (id: string) => {
  return async (dispatch: Dispatch): Promise<any> => {
    dispatch(uILoadingStart());

    try {
      const response = await apiMonitoringUserDelete(id);
      if (response && response.status === 200) {
        // dispatch(getAccountOne(id));
        return response.status;
      }
    } catch (e) {
      showErrorNotification(e as any);
    } finally {
      dispatch(uILoadingFinish());
    }
    return 0;
  };
};

export const getMonitoringUserOne = (id: string) => {
  return async (dispatch: Dispatch): Promise<any> => {
    dispatch(uILoadingStart());

    try {
      const response = await apiMonitoringUserOne(id);
      if (response && (response.status === 200 || response.status === 201)) {
        dispatch(setMonitoringUser(response.data.details.monitoringUser));
        dispatch(setMonitoringUserList(response.data.details.relatedCompanies));
        return response.status;
      }
    } catch (e) {
      showErrorNotification(e as any);
    } finally {
      dispatch(uILoadingFinish());
    }
    return 0;
  };
};

export const getMonitoringUserListAll = (id: string) => {
  return async (dispatch: Dispatch): Promise<any> => {
    dispatch(uILoadingStart());

    try {
      const response = await apiMonitoringUserListAll(id);
      if (response && (response.status === 200 || response.status === 201)) {
        dispatch(setMonitoringUserListAll(response.data.companies.companiesAllowedToAdd));
        return response.status;
      }
    } catch (e) {
      showErrorNotification(e as any);
    } finally {
      dispatch(uILoadingFinish());
    }
    return 0;
  };
};

export const getMonitoringUserList = (id: string) => {
  return async (dispatch: Dispatch): Promise<any> => {
    dispatch(uILoadingStart());

    try {
      const response = await apiMonitoringUserList(id);
      if (response && (response.status === 200 || response.status === 201)) {
        dispatch(setMonitoringUserList(response.data));
        return response.status;
      }
    } catch (e) {
      showErrorNotification(e as any);
    } finally {
      dispatch(uILoadingFinish());
    }
    return 0;
  };
};
