import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
  root: (props: any): any => ({
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    display: props?.isInline ? 'inline-block' : 'block',
  }),
}));
