import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    left: 0,
  },
  menuButton: {
    appearance: 'none',
    outline: 'none',
    background: 'none',
    border: 'none',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    cursor: 'pointer',
    padding: 0,
  },
  userName: {
    margin: '0 1rem',
    fontSize: '1.2rem',
  },
  title: {
    fontSize: '16px',
    marginLeft: '22px',
    color: '#212121',
    fontWeight: 700,
  },
}));
