import { Reducer } from 'redux';
import { produce } from 'immer';
import { BillingHistoryActionTypes, BillingHistoryActions, BillingHistoryState } from './types';

const initialState: BillingHistoryState = {
  data: {},
};

export const billingHistory: Reducer<BillingHistoryState, BillingHistoryActions> = (
  state = initialState,
  action,
): BillingHistoryState => {
  return produce(state, (draft: BillingHistoryState) => {
    switch (action.type) {
      case BillingHistoryActionTypes.SET_BILLING_HISTORY:
        draft.data = action.payload;
        return;
      default:
        return state;
    }
  });
};
