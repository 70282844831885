import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    gap: '0.5rem',
  },
  datePicker: {
    width: '9rem',
    '& input': {
      width: '6rem',
    },
  },
}));
