export interface UIState {
  isLoading: boolean;
  isDrawerOpened: boolean;
  languages: Language[];
  workflowStepTypes: WorkflowStepType[];
  themeBrandColor: ThemeBrandColor;
  isStripeEnabled: boolean;
}

export enum UIActionTypes {
  UI_LOADING_START = 'UI_LOADING_START',
  UI_LOADING_FINISH = 'UI_LOADING_FINISH',
  UI_TOGGLE_DRAWER = 'UI_TOGGLE_DRAWER',
  UI_SET_LANGUAGES = 'UI_SET_LANGUAGES',
  UI_SET_WORKFLOW_STEP_TYPES = 'UI_SET_WORKFLOW_STEP_TYPES',
  UI_SET_THEME = 'UI_SET_THEME',
  UI_SET_STRIPE_ENABLED = 'UI_SET_STRIPE_ENABLED',
  UI_RESET_STATE = 'UI_RESET_STATE',
}
export interface Language {
  value: string;
  label: string;
}
export interface WorkflowStepType {
  value: string;
  label: string;
}
export interface ThemeBrandColor {
  brand: string;
  logoUrl: string;
  backgroundUrl: string;
}
interface UILoadingStartAction {
  type: UIActionTypes.UI_LOADING_START;
}

interface UILoadingFinishAction {
  type: UIActionTypes.UI_LOADING_FINISH;
}

interface UIToggleDrawerAction {
  type: UIActionTypes.UI_TOGGLE_DRAWER;
  payload?: boolean | undefined;
}

interface UISetLanguagesAction {
  type: UIActionTypes.UI_SET_LANGUAGES;
  payload: Language[];
}

interface UISetWorkflowStepTypesAction {
  type: UIActionTypes.UI_SET_WORKFLOW_STEP_TYPES;
  payload: WorkflowStepType[];
}

interface UISetTheme {
  type: UIActionTypes.UI_SET_THEME;
  payload: ThemeBrandColor;
}
interface UISetStripeEnabled {
  type: UIActionTypes.UI_SET_STRIPE_ENABLED;
  payload: boolean;
}
interface UIResetState {
  type: UIActionTypes.UI_RESET_STATE;
}
export type UIActions =
  | UILoadingStartAction
  | UILoadingFinishAction
  | UIToggleDrawerAction
  | UISetLanguagesAction
  | UISetWorkflowStepTypesAction
  | UISetTheme
  | UISetStripeEnabled
  | UIResetState;
