import { Reducer } from 'redux';
import { produce } from 'immer';

import { HardwareAlertActionTypes, HardwareAlertActions, HardwareAlertState } from './types';

const initialState: HardwareAlertState = {
  columnsFilters: {},
  sortBy: { columnName: 'serverName', order: 'desc' },
  modulesAndSeverities: { modules: [], severities: [] },
};

export const hardwareAlertsReducer: Reducer<HardwareAlertState, HardwareAlertActions> = (
  state = initialState,
  action,
): HardwareAlertState => {
  return produce(state, (draft: HardwareAlertState) => {
    switch (action.type) {
      case HardwareAlertActionTypes.HA_RESET_STATE:
        return initialState;

      case HardwareAlertActionTypes.HA_SET_FILTER_MULTI_SELECT:
        draft.columnsFilters = {
          ...state.columnsFilters,
          [action.payload.columnName]: {
            ...state.columnsFilters[action.payload.columnName],
            filterType: 'multiSelect',
            selectedOptions: action.payload.selectedOptions,
          },
        };
        return;

      case HardwareAlertActionTypes.HA_SET_FILTER_MULTI_SELECT_PREV:
        draft.columnsFilters[action.payload.columnName] = {
          ...state.columnsFilters[action.payload.columnName],
          selectedOptionsPrev: action.payload.selectedOptionsPrev,
        };
        return;

      case HardwareAlertActionTypes.HA_SET_FILTER_MULTI_SELECT_SHOWN:
        draft.columnsFilters[action.payload.columnName] = {
          ...state.columnsFilters[action.payload.columnName],
          selectedOptionsShown: action.payload.selectedOptionsShown,
        };
        return;

      case HardwareAlertActionTypes.HA_SET_FILTER_SEARCH:
        if (!!action.payload.searchString) {
          draft.columnsFilters = {
            ...state.columnsFilters,
            [action.payload.columnName]: { filterType: 'search', searchString: action.payload.searchString },
          };
        } else {
          delete draft.columnsFilters[action.payload.columnName];
        }
        return;

      case HardwareAlertActionTypes.HA_SET_FILTER_SEARCH_PREV:
        draft.columnsFilters[action.payload.columnName] = {
          ...state.columnsFilters[action.payload.columnName],
          searchStringPrev: action.payload.searchStringPrev,
        };
        return;

      case HardwareAlertActionTypes.HA_SET_SORT_BY:
        draft.sortBy = action.payload;
        return;
      case HardwareAlertActionTypes.HA_RESET_FILTERS:
        draft.columnsFilters = {};
        return;
      case HardwareAlertActionTypes.HA_SET_TABLE_COLUMNS:
        draft.modulesAndSeverities = action.payload;
        return;
      default:
        return state;
    }
  });
};
