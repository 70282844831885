import cn from 'classnames';
import PrivatePolicyModal from 'components/Modals/PrivatePolicyModal';
import TermsAndConditionsModal from 'components/Modals/TermsAndConditionsModal';
import { Button, Checkbox, InputText, SearchInput, StrengthIndicator } from 'components/UI';
import React, { ReactElement, useCallback, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { signUp } from 'store/session/actions';
import { signUpSchema } from 'utils/validation';
import { yupResolver } from '@hookform/resolvers/yup';

import { useStyles } from '../styles';
import { useHistory } from 'react-router-dom';
import CodeVerifying from '../CodeVerifying';
import { Icon } from 'components/UI/Icon';
import { IconButton } from '@material-ui/core';

enum SignUpStepsEnum {
  CODE_VERIFYING = 'CODE_VERIFYING',
  REGISTER_AS = 'REGISTER_AS',
  WELCOME = 'WELCOME',
  SUCCESS = 'SUCCESS',
}

export enum SignUpFormTypeEnum {
  ORGANIZATION = 'ORGANIZATION',
  ACCOUNT = 'ACCOUNT',
}

enum ModalTypeEnum {
  TOC = 'TOC',
  PP = 'PP',
}

const SignUp = (): ReactElement => {
  const history = useHistory();

  const state: any = history.location.state;
  const verificationNeeded = (history.location.state && state.verificationNeeded) || false;
  const verifyingEmail = (history.location.state && state.email) || '';

  const [modalIsOpen, setModalOpen] = useState<ModalTypeEnum | null>(null);
  const [formType, setFormType] = useState<SignUpFormTypeEnum | null>(null);
  const [registrationEmail, setRegistrationEmail] = useState<string>('');
  const [step, setStep] = useState<SignUpStepsEnum>(
    verificationNeeded || state?.isNeedVerification ? SignUpStepsEnum.CODE_VERIFYING : SignUpStepsEnum.WELCOME,
  );

  const isAccount = formType === SignUpFormTypeEnum.ACCOUNT;
  const isOrganization = formType === SignUpFormTypeEnum.ORGANIZATION;

  const { t } = useTranslation();

  const formElement = useRef<HTMLFormElement>(null);
  const submitButtonElement = useRef<HTMLButtonElement>(null);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
    watch,
    setError,
    clearErrors,
  } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onSubmit',
    resolver: yupResolver(signUpSchema(isAccount, t), { abortEarly: false }),
  });

  const haveErrors = Object.keys(errors).length > 0;

  const styles = useStyles();

  const dispatch = useDispatch();

  useEffect(() => {
    if (isAccount) {
      setValue('coordinates' as any, '', { shouldValidate: false });
      setValue('timeZone' as any, '', { shouldValidate: false });
      setValue('address' as any, '', { shouldValidate: false });
      setValue('country' as any, '', { shouldValidate: false });
    }
  }, [register, reset, isAccount, setValue]);

  const onSubmit = async (data: any): Promise<void> => {
    if (!watch('docsAreAgreed')) {
      return;
    }

    const response = await dispatch(signUp({ ...data, formType }));

    if (response && response.data && response.data.code === 'OK') {
      setRegistrationEmail(data.email);
      setStep(SignUpStepsEnum.CODE_VERIFYING);
    } else if (response && response.statusCode === 500) {
      if (response.message === 'Email already in use') {
        setError('email', { type: 'notMatch', message: 'Email already in use' }, { shouldFocus: true });
      }
    }
  };

  const handleKeyPress = useCallback((event: any): void => {
    if (event.key === 'Enter' && document.activeElement?.closest('#sign-up-form')) {
      event.preventDefault();
      if (submitButtonElement.current) {
        submitButtonElement.current.click();
      }
      if (event.target) {
        event.target.blur();
      }
    }
  }, []);

  useEffect(() => {
    document.body.addEventListener('keypress', handleKeyPress);
    if (formElement.current) {
      formElement.current.focus();
    }
    return (): void => {
      document.body.removeEventListener('keypress', handleKeyPress);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formElement]);

  const openModalHandler = (modalType: ModalTypeEnum) => {
    setModalOpen(modalType);
  };

  const renderWelcomeStep = () => {
    return (
      <>
        <h5 className={styles.formHeader}>{t('Welcome')}!</h5>

        <p className={styles.formSubTitle}>{t('Register as')}</p>

        <div className={styles.cardsWrapper}>
          <div
            className={cn(styles.card, {
              [styles.activeCard]: formType === SignUpFormTypeEnum.ORGANIZATION,
            })}
            onClick={() => setFormType(SignUpFormTypeEnum.ORGANIZATION)}
          >
            <Icon name="organization-auth" />
            <div className={styles.textWrapper}>
              <p className={styles.cardTitle}>{t('Organization')}</p>
              <p className={styles.cardDescription}>
                {t('Choose Organization in case you would like to manage several Companies.')}
              </p>
            </div>
          </div>

          <div
            className={cn(styles.card, {
              [styles.activeCard]: formType === SignUpFormTypeEnum.ACCOUNT,
            })}
            onClick={() => setFormType(SignUpFormTypeEnum.ACCOUNT)}
          >
            <Icon name="account-auth" />
            <div className={styles.textWrapper}>
              <p className={styles.cardTitle}>{t('Account')}</p>
              <p className={styles.cardDescription}>
                {t('Choose Account in case you would like to manage just your Company.')}
              </p>
            </div>
          </div>
        </div>

        <Button
          disabled={formType === null}
          labelKey="Next"
          className={cn(styles.submitButton, { [styles.disableButton]: formType === null })}
          type="submit"
          fullWidth
          onClick={() => setStep(SignUpStepsEnum.REGISTER_AS)}
        />

        <p className={styles.hyperLinkText}>
          {t('Have an account')}?{' '}
          <a href="/" className={styles.hyperLink}>
            {t('Log in')}
          </a>
        </p>
      </>
    );
  };

  const isDisabledButton = !watch('docsAreAgreed') || haveErrors;

  const renderSignUpForm = () => {
    return (
      <>
        <div className={styles.flexBoxTitle}>
          <IconButton className={styles.backButton} onClick={() => setStep(SignUpStepsEnum.WELCOME)}>
            <Icon name="arrowLeft" color="#666666" />
          </IconButton>
          <h5 className={styles.registerHeader}>
            {t(formType === SignUpFormTypeEnum.ORGANIZATION ? 'Register as organization' : 'Register as account')}
          </h5>
        </div>

        <form
          onSubmit={handleSubmit(onSubmit)}
          ref={formElement}
          tabIndex={-1}
          id="sign-up-form"
          className={styles.loginForm}
          autoComplete="off"
        >
          <input
            name="password"
            type="password"
            autoComplete="none"
            defaultValue="hidden"
            style={{
              opacity: 0,
              height: 0,
              margin: 0,
              padding: 0,
              width: 0,
              border: 'none',
            }}
          />

          <InputText
            onKeyDown={handleKeyPress}
            {...register('companyName', {
              onChange: () => !!errors['companyName'] && clearErrors('companyName'),
              required: true,
            })}
            error={!!errors['companyName'] ? errors['companyName'].message : undefined}
            fullWidth
            className={styles.input}
            placeholder={t('Company Name')}
          />

          <InputText
            onKeyDown={handleKeyPress}
            {...register('fullName', {
              onChange: () => !!errors['fullName'] && clearErrors('fullName'),
              required: true,
            })}
            error={!!errors['fullName'] ? errors['fullName'].message : undefined}
            fullWidth
            className={styles.input}
            placeholder={t('Full Name')}
          />

          <InputText
            onKeyDown={handleKeyPress}
            {...register('email', {
              onChange: () => !!errors['email'] && clearErrors('email'),
              required: true,
            })}
            error={!!errors['email'] ? errors['email'].message : undefined}
            fullWidth
            className={styles.input}
            placeholder={t('Email Address')}
          />

          <InputText
            className={styles.input}
            {...register('phoneNumber', {
              onChange: () => !!errors['phoneNumber'] && clearErrors('phoneNumber'),
              required: true,
            })}
            error={!!errors['phoneNumber'] ? errors['phoneNumber'].message : undefined}
            placeholder="+XXXXXXXXXXXX"
            optional
          />

          {isAccount && (
            <SearchInput
              {...register('address', { required: true })}
              setValue={setValue}
              newCustomer
              error={!!errors['address'] ? errors['address'].message : undefined}
              secondValue="timeZone"
              fullWidth
              className={styles.input}
            />
          )}

          <InputText
            onKeyDown={handleKeyPress}
            {...register('password', {
              onChange: () => !!errors['password'] && clearErrors('password'),
              required: true,
            })}
            error={!!errors['password'] ? errors['password'].message : undefined}
            fullWidth
            type="password"
            className={styles.input}
            placeholder={t('Password')}
          />

          <StrengthIndicator password={watch('password')} />

          <InputText
            onKeyDown={handleKeyPress}
            {...register('confirmPassword', {
              onChange: () => !!errors['confirmPassword'] && clearErrors('confirmPassword'),
              required: true,
            })}
            error={!!errors['confirmPassword'] ? errors['confirmPassword'].message : undefined}
            fullWidth
            type="password"
            className={styles.input}
            placeholder={t('Confirm Password')}
          />

          <div className={styles.buttonsContainer}>
            <Checkbox
              {...register('docsAreAgreed', { required: true })}
              labelKey={
                <>
                  <span>{t('I Agree to')} </span>
                  <span onClick={() => openModalHandler(ModalTypeEnum.PP)}>{t('Privacy Policy')}</span>
                  <span> {t('and')} </span>
                  <span onClick={() => openModalHandler(ModalTypeEnum.TOC)}>{t('Terms & Conditions')}</span>
                </>
              }
              className={styles.checkBoxLabel}
            />
          </div>

          <Button
            labelKey={t('Register')}
            className={cn(styles.submitButton, {
              [styles.disableButton]: isDisabledButton,
            })}
            type="submit"
            fullWidth
            ref={submitButtonElement}
          />
        </form>
      </>
    );
  };

  const renderVerifyCodeForm = () => {
    return (
      <CodeVerifying
        registrationEmail={verifyingEmail || registrationEmail}
        onVerifySuccess={() => setStep(SignUpStepsEnum.SUCCESS)}
      />
    );
  };

  const renderSuccess = () => {
    return (
      <>
        <Icon name="auth-success" />
        <h5 className={styles.littleFormHeader}>{t('Registration successful')}</h5>
        <Button
          labelKey={t('Log In')}
          type="button"
          className={styles.submitButton}
          onClick={() => history.push('/')}
          fullWidth
        />
      </>
    );
  };

  const renderSteps = () => {
    switch (step) {
      case SignUpStepsEnum.WELCOME:
        return renderWelcomeStep();

      case SignUpStepsEnum.REGISTER_AS:
        return renderSignUpForm();

      case SignUpStepsEnum.CODE_VERIFYING:
        return renderVerifyCodeForm();

      case SignUpStepsEnum.SUCCESS:
        return renderSuccess();

      default:
        return null;
    }
  };

  return (
    <>
      <div
        className={cn(styles.contentContainer, {
          [styles.signUpContainerPaddingBottom]: isOrganization || isAccount,
        })}
      >
        {renderSteps()}
      </div>

      <PrivatePolicyModal
        onClose={() => {
          setModalOpen(null);
        }}
        open={modalIsOpen === ModalTypeEnum.PP}
      />

      <TermsAndConditionsModal
        onClose={() => {
          setModalOpen(null);
        }}
        open={modalIsOpen === ModalTypeEnum.TOC}
      />
    </>
  );
};

export default SignUp;
