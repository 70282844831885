import React, { FC, ReactElement, Suspense, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { AppState } from 'store';

import { useStyles } from './styles';
import AuthorizationForms from 'components/AuthorizationForms';
import LanguageSelector from 'components/LanguageSelector';

const Entry: FC = (): ReactElement => {
  const styles = useStyles();

  const theme = useSelector((state: AppState) => state.ui.themeBrandColor);
  const backgroundUrl = useSelector((state: AppState) => state.ui.themeBrandColor.backgroundUrl);

  const [background, setBackground] = useState('');
  useEffect(() => {
    setBackground(`url( ${backgroundUrl})`);
  }, [theme, backgroundUrl]);

  return (
    <div className={styles.entryContainer}>
      <div className={styles.formsContainer} style={{ backgroundImage: background }}>
        <Suspense fallback={<></>}>
          <AuthorizationForms />
          <LanguageSelector />
        </Suspense>
      </div>
    </div>
  );
};

export default Entry;
