import React, { ForwardRefRenderFunction, ReactElement, forwardRef } from 'react';
import Switch from '@material-ui/core/Switch';
import { useTranslation } from 'react-i18next';

import { useStyles } from './styles';

export interface ToggleSwitchProps {
  labelKey?: string;
  name?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;
  onBlur?: (event: React.FocusEvent) => unknown;
  checked?: boolean;
  className?: any;
  disabled?: any;
  thumbGB?: boolean;
  thumbActiveGB?: boolean;
  defaultChecked?: boolean;
  classes?: any;
  noMargin?: boolean;
  reverseLayout?: boolean;
}

const getBooleanProp = (propName: string, value: any) => {
  return typeof value === 'boolean' ? { [propName]: value } : { [propName]: false };
};

const ToggleSwitch: ForwardRefRenderFunction<typeof Switch, ToggleSwitchProps> = (
  {
    labelKey,
    name,
    disabled,
    onChange,
    onBlur,
    checked,
    defaultChecked,
    className,
    thumbGB,
    thumbActiveGB,
    classes,
    noMargin = false,
    reverseLayout = false,
  },
  ref,
): ReactElement => {
  // Styling
  const styles = useStyles(classes);
  // Translation
  const { t } = useTranslation();

  const getThumbStyle = () => {
    if (thumbGB) {
      return styles.thumbGB;
    }
    if (thumbActiveGB) {
      return styles.thumbActiveGB;
    }
    return styles.thumb;
  };

  const renderSwitch = (): ReactElement => {
    return (
      <div className={styles.ToggleSwitch}>
        <Switch
          inputRef={ref}
          name={name}
          focusVisibleClassName={styles.focusVisible}
          disableRipple
          disabled={disabled}
          classes={{
            root: styles.root,
            switchBase: styles.switchBase,
            thumb: getThumbStyle(),
            track: styles.track,
            checked: styles.checked,
          }}
          onChange={onChange}
          onBlur={onBlur}
          {...getBooleanProp('checked', checked)}
          {...getBooleanProp('defaultChecked', defaultChecked)}
        />
      </div>
    );
  };

  return (
    <div className={styles.ToggleSwitch}>
      {labelKey ? (
        <label
          className={[
            styles.switchLabel,
            !!className ? className : '',
            noMargin ? 'no-margin' : '',
            reverseLayout ? 'reverse-layout' : '',
          ].join(' ')}
        >
          {renderSwitch()}
          <p className={styles.switchTitle}>{t(labelKey)}</p>
        </label>
      ) : (
        renderSwitch()
      )}
    </div>
  );
};

export default forwardRef(ToggleSwitch);
