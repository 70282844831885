export interface ServersDashboard {
  companies: any;
  charts: any;
}

export interface IStatus {
  isAliveExpiredMain: false;
  isHardwareError: false;
  isInMaintenance: false;
  isSystemXMLError: false;
  keepAliveSystemModuleXMLError: boolean;
  keepAliveSystemXMLError: boolean;
  applicationError: boolean;
  severity: string;
}

export type IStatusIcon =
  | 'active'
  | 'orange_circle'
  | 'red_circle'
  | 'blue_circle'
  | 'red_triangle'
  | 'orange_triangle';

export type IStatusTitle =
  | 'Okay'
  | 'No keep alive'
  | 'System error'
  | 'Maintenance'
  | 'Hardware error'
  | 'Application is down'
  | 'System warning';

export interface IStatusIconMap {
  status: IStatusIcon;
  title: IStatusTitle;
  id: number;
}

export type IServerDashboardStatus = {
  status: IStatus | undefined;
};

export type ServersItem = { id: number; name: string };

export type ServersDashboardData = { companyId: string; serverName: string };

export type ServersChartList = {
  monitorId: string;
  interval: number;
  step: number;
  units: any;
  date: number;
  tz: string;
};

export enum ServersDashboardActionTypes {
  SD_SET_DATA = 'SD_SET_DATA',
  SD_SET_CHART_LIST = 'SD_SET_CHART_LIST',
}

export interface ServersDashboardSet {
  type: ServersDashboardActionTypes.SD_SET_DATA;
  payload: ServersItem[];
}

export interface ServersChartListSet {
  type: ServersDashboardActionTypes.SD_SET_CHART_LIST;
  payload: any;
}

export type ServersDashboardActions = ServersDashboardSet | ServersChartListSet;
