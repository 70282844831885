import { Reducer } from 'redux';
import { produce } from 'immer';
import { ResourcesUsageActions, ResourcesUsageReportActionTypes, ResourcesUsageReportState } from './types';

const initialState: ResourcesUsageReportState = {
  data: {},
  params: {},
};

export const resourcesUsageReport: Reducer<ResourcesUsageReportState, ResourcesUsageActions> = (
  state = initialState,
  action,
): ResourcesUsageReportState => {
  return produce(state, (draft: ResourcesUsageReportState) => {
    switch (action.type) {
      case ResourcesUsageReportActionTypes.RESOURCES_USAGE_REPORTS:
        draft.data = action.payload;
        return;
      case ResourcesUsageReportActionTypes.RESOURCES_USAGE_REPORTS_PARAMS:
        draft.params = action.payload;
        return;
      default:
        return state;
    }
  });
};
