import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  setPasswordContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '80px 0 0',

    '& p': {
      margin: '0 0 40px 0',
      fontSize: '14px',
      textAlign: 'center',
      color: '#666666',
    },
  },
  iconContainer: {
    display: 'flex',
    width: '100%',
    height: '8.5rem',
    alignItems: 'center',
    justifyContent: 'center',
  },
  formHeader: {
    ...theme.typography.h5,
    margin: '40px 0 24px',
    textAlign: 'center',
    color: '#212121',
    fontWeight: 700,
    fontSize: 30,
  },
  submitButton: {
    marginTop: '1rem',
  },
  textButton: {
    margin: '0 auto',
  },
  backButton: {
    width: 40,
    height: 40,
    border: '2px solid #BFC0CD',
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '40px 0 24px',
    '& svg': {
      height: 16,
      marginRight: 2,
    },
  },
  flexBoxTitle: {
    display: 'flex',
    gap: 16,
  },
}));
