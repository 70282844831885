import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
  dialogRoot: {
    maxWidth: '43rem',
    borderRadius: 16,
  },
  modalContent: {
    width: '100%',
    minHeight: '80vh',
    maxHeight: '80vh',
    display: 'flex',
    justifyContent: 'flex-start',
    padding: '2rem 1rem',
    flexDirection: 'column',
  },
  modalTitle: {
    fontSize: '2.125rem',
    fontWeight: 600,
    margin: 0,
    textAlign: 'center',
    marginBottom: '0.75rem',
    lineHeight: '2.5rem',
  },
  modalSubtitle: {
    fontWeight: 500,
    color: '#212121',
    opacity: '0.7',
    maxWidth: '28rem',
    lineHeight: '1.0625rem',
    margin: '1rem 0',
  },
  paragraphTitle: {
    margin: '1rem 0',
  },

  modalText: {
    color: '#212121',
    opacity: '0.7',
    margin: 0,
    marginBottom: '1rem',

    '&:last-child': {
      marginBottom: 0,
    },
  },
}));
