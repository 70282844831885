import { Reducer } from 'redux';
import { produce } from 'immer';

import { AdminDashboardActionTypes, AdminDashboardActions, AdminDashboardState } from './types';
import { endOfMonth, startOfMonth } from 'date-fns';

const today = new Date();

const initialState: AdminDashboardState = {
  customersCount: 0,
  customers: [],
  filters: {
    from: startOfMonth(today),
    to: endOfMonth(today),
    availableDates: {},
  },
  pagination: {
    page: 0,
    count: 50,
    startRecord: 1,
    endRecord: 10,
  },
  customerId: '',
  search: '',
  sortBy: { columnName: 'customer', order: 'asc' },
  accountType: 'all',
  monitoringAccounts: true,
};

const adminDashboardReducer: Reducer<AdminDashboardState, AdminDashboardActions> = (
  state = initialState,
  action,
): AdminDashboardState => {
  return produce(state, (draft: AdminDashboardState) => {
    switch (action.type) {
      case AdminDashboardActionTypes.AD_RESET_STATE:
        return initialState;

      case AdminDashboardActionTypes.AD_SET_AVAILABLE_DATES:
        draft.filters.availableDates = action.payload;
        return;

      case AdminDashboardActionTypes.AD_SET_ACCOUNT_TYPE:
        draft.accountType = action.payload;
        return;

      case AdminDashboardActionTypes.AD_SET_MONITORING_ACCOUNTS:
        draft.monitoringAccounts = action.payload;
        draft.pagination.page = 0;
        return;

      case AdminDashboardActionTypes.AD_SET_FILTER_FROM:
        draft.filters.from = action.payload;
        draft.pagination.page = 0;
        return;

      case AdminDashboardActionTypes.AD_SET_FILTER_TO:
        draft.filters.to = action.payload;
        draft.pagination.page = 0;
        return;

      case AdminDashboardActionTypes.AD_SET_CUSTOMERS:
        draft.customers = action.payload;
        return;

      case AdminDashboardActionTypes.AD_SET_CUSTOMERS_COUNT:
        draft.customersCount = action.payload;
        return;

      case AdminDashboardActionTypes.AD_SET_COUNT:
        draft.pagination.count = action.payload;
        draft.pagination.page = 0;
        return;

      case AdminDashboardActionTypes.AD_SET_PAGE:
        draft.pagination.page = action.payload;
        return;

      case AdminDashboardActionTypes.AD_SET_SHOWN:
        draft.pagination.startRecord = action.payload.startRecord;
        draft.pagination.endRecord = action.payload.endRecord;
        return;

      case AdminDashboardActionTypes.AD_SET_SEARCH:
        draft.search = action.payload;
        return;

      case AdminDashboardActionTypes.AD_SET_CUSTOMER_ID:
        draft.customerId = action.payload;
        return;

      case AdminDashboardActionTypes.AD_TOGGLE_SORT_BY:
        if (state.sortBy.columnName === action.payload) {
          if (state.sortBy.order === 'asc') {
            draft.sortBy.order = 'desc';
          } else {
            draft.sortBy.order = 'asc';
          }
        } else {
          draft.sortBy.columnName = action.payload;
          draft.sortBy.order = 'asc';
        }
        return;

      case AdminDashboardActionTypes.AD_SET_SORT_BY:
        draft.sortBy = action.payload;
        return;

      default:
        return state;
    }
  });
};

export default adminDashboardReducer;
