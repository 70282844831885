export type List = Record<string, string[]>;
export type Option = { value: string; label: string };
export type OptionList = Record<string, Option[]>;

export const mapListToOptions = (list: List): OptionList => {
  const mappedListEntries = Object.entries(list)
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    .filter(([_key, valueArray]) => valueArray && valueArray?.length)
    .map(([key, valueArray]) => [
      key,
      valueArray?.filter((value) => value)?.map((value: string) => ({ value, label: value })),
    ]);

  return Object.fromEntries(mappedListEntries);
};
