import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, InputText, StrengthIndicator } from 'components/UI';
import { Icon } from 'components/UI/Icon';
import { useStyles } from './styles';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { setPasswordSchema } from 'utils/validation';
import { useDispatch } from 'react-redux';
import {
  activateNewAccount,
  changePassword,
  forgotPasswordSetNewPassword,
  setLanguage,
  signIn,
} from 'store/session/actions';
import i18n from 'utils/i18n';
import { storage } from 'utils';
import { NotificationTypes, showErrorNotification, showNotification } from 'utils/notifications';
import { getTokens } from 'api/index';
import { IconButton } from '@material-ui/core';

const SetPassword = (): ReactElement => {
  localStorage.removeItem('rememberMe');
  localStorage.removeItem('session');

  // Translation
  const { t } = useTranslation();

  // State
  const [validLink, setValidLink] = useState(true);

  // Routing
  const history = useHistory();
  const state: any = history.location.state;
  const oldPassword = (history.location.state && state.oldPassword) || undefined;
  const needPasswordChange = (history.location.state && state.needPasswordChange) || undefined;
  const role = (history.location.state && state.role) || undefined;
  const search = history.location.search;
  const params = new URLSearchParams(search);
  const hash = params.get('hash');
  const lng = params.get('lng');
  const first = params.get('first');
  const activate = params.get('action') === 'activate' || false;

  const [isActivate, setIsActivated] = useState(false);
  const [activationFetching, setActivationFetching] = useState(false);

  // Redux
  const dispatch = useDispatch();

  const activationHandler = useCallback(async (): Promise<any> => {
    if (hash) {
      setActivationFetching(true);

      const response = await dispatch(activateNewAccount(hash));

      if (response && response.id) {
        setActivationFetching(false);
        setIsActivated(true);
      }

      if (response && response.statusCode === 403) {
        setActivationFetching(false);
        setIsActivated(false);
        showNotification(NotificationTypes.error, response.message);
      }
    }
  }, [hash, dispatch]);

  useEffect(() => {
    const storedTokens: any = getTokens();

    if (state?.isSetNewPassword && state?.getDataAfterReload && storedTokens && !storedTokens.access) {
      dispatch(signIn({ ...state.getDataAfterReload }));
    }
  }, []);

  useEffect(() => {
    if (!!lng) {
      i18n.changeLanguage(lng).catch((e) => {
        showErrorNotification(e);
      });
      storage.saveLanguage(lng);
      dispatch(setLanguage(lng));
    }
  }, [dispatch, lng]);

  useEffect(() => {
    if (activate && hash) {
      activationHandler();
    }
  }, [hash, activate, activationHandler]);

  if (!oldPassword && !hash && !activate) {
    history.push('/');
  }
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
    resolver: yupResolver(setPasswordSchema(t)),
  });

  const styles = useStyles();

  const onSubmit = async (data: any): Promise<void> => {
    if (!activate) {
      if (hash) {
        const setStatus = await dispatch(forgotPasswordSetNewPassword(data.newPassword, hash));
        if (typeof setStatus === 'number' && (setStatus === 200 || setStatus === 201)) {
          history.push('/set');
        }
        if (typeof setStatus === 'number' && setStatus === 403) {
          // history.push('/forgot-password');
          setValidLink(false);
        }
      } else if (oldPassword) {
        const response: any = await dispatch(changePassword(oldPassword, data.newPassword, role));

        if (response.data && response.data.isPasswordChanged) {
          history.push('/set');
        }

        if (response.data && !response.data.isPasswordChanged) {
          history.push('/');
        }

        if (typeof response === 'number' && response === 403) {
          history.push('/');
        }
      }
    }
  };

  if (activate) {
    return (
      <div className={styles.setPasswordContainer}>
        <h5 className={styles.activatedHeader}>
          {activationFetching ? t('Activation...') : isActivate ? 'Activated' : 'Not activated'}
        </h5>

        <div className={styles.secondLineActive}>
          {activationFetching
            ? t('Please wait while the account activation process is in progress')
            : isActivate
            ? 'Account successfully activated'
            : 'Please try again later with new activation link'}
        </div>

        {isActivate && (
          <Button
            labelKey={t('Return to log in')}
            className={styles.submitButton}
            type="button"
            fullWidth
            onClick={() => history.push('/')}
          />
        )}
      </div>
    );
  }

  const returnToLogIn = () => {
    history.push('/');
  };

  return (
    <div className={styles.setPasswordContainer}>
      {validLink ? (
        <>
          {hash && !first ? (
            <h5 className={styles.formHeader}>{t('Set password')}</h5>
          ) : first || needPasswordChange ? (
            <h5 className={styles.formHeader}>{t('First Login')}</h5>
          ) : (
            <div className={styles.flexBoxTitle}>
              <IconButton className={styles.backButton} onClick={returnToLogIn}>
                <Icon name="arrowLeft" color="#666666" />
              </IconButton>
              <h5 className={styles.formHeader}>{t('Enter New Password')}</h5>
            </div>
          )}
          <div className={styles.secondLine}>
            {hash && !first
              ? t('Enter New Password')
              : first || needPasswordChange
              ? t('Please reset your password')
              : ' '}
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <InputText
              placeholder={t('New Password')}
              {...register('newPassword', { required: true })}
              error={!!errors['newPassword'] ? errors['newPassword'].message : undefined}
              fullWidth
              type="password"
              className={styles.input}
            />
            <InputText
              placeholder={t('Confirm Password')}
              {...register('confirmPassword', { required: true })}
              error={!!errors['confirmPassword'] ? errors['confirmPassword'].message : undefined}
              fullWidth
              type="password"
              className={styles.input}
            />
            <div>
              <StrengthIndicator password={watch('newPassword')} />

              <Button
                labelKey={hash && !first ? t('Change my password') : t('Set and Log in')}
                className={styles.submitButton}
                type="submit"
                fullWidth
              />
              <Button
                labelKey={t('Return to log in')}
                textStyle
                type="button"
                className={styles.textButton}
                onClick={returnToLogIn}
              />
            </div>
          </form>
        </>
      ) : (
        <>
          <div className={styles.iconContainer}>
            <Icon name="roundedCross" size={80} />
          </div>
          <h5 className={styles.formHeader}>{t('The link is not valid')}</h5>

          <Button
            labelKey={t('Reset your password')}
            textStyle
            type="button"
            className={styles.textButton}
            onClick={() => history.push('/forgot-password')}
          />
        </>
      )}
    </div>
  );
};

export default SetPassword;
