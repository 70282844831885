import { Reducer } from 'redux';
import { produce } from 'immer';
import { GlobalUsersActionTypes, GlobalUsersActions, GlobalUsersState } from './types';

const initialState: GlobalUsersState = {
  globalUsersCount: 0,
  globalUsers: [],
  firstGlobalUsers: [],
  pagination: {
    page: 0,
    count: 20,
    startGlobalUser: 1,
    endGlobalUser: 20,
  },
  search: '',
  counter: 0,
  globalUser: {
    id: '',
    email: '',
    name: '',
    writeAccess: false,
    timeFormat: '',
    isSendSystemErrorsMail: false,
    isSendCompareVersions: false,
    isAdminSales: false,
    isAdminFinance: false,
    isErrorReportEmail: false,
    isSendHardwareErrorsMail: false,
    status: false,
  },
};

const globalUsersReducer: Reducer<GlobalUsersState, GlobalUsersActions> = (
  state = initialState,
  action,
): GlobalUsersState => {
  return produce(state, (draft: GlobalUsersState) => {
    switch (action.type) {
      case GlobalUsersActionTypes.GLOBAL_USERS_SET:
        draft.globalUsers = action.payload;
        return;
      case GlobalUsersActionTypes.GLOBAL_USERS_FIRST_SET:
        draft.firstGlobalUsers = action.payload;
        return;
      case GlobalUsersActionTypes.GLOBAL_USERS_SET_COUNT:
        draft.globalUsersCount = action.payload;
        return;
      case GlobalUsersActionTypes.GLOBAL_USERS_SET_COUNT_FIRST:
        draft.counter = action.payload;
        return;
      case GlobalUsersActionTypes.GLOBAL_USERS_SET_PAGINATION_COUNT:
        draft.pagination.count = action.payload;
        draft.pagination.page = 0;
        return;

      case GlobalUsersActionTypes.GLOBAL_USERS_SET_PAGE:
        draft.pagination.page = action.payload;
        return;

      case GlobalUsersActionTypes.GLOBAL_USER_SET:
        draft.globalUser = action.payload;
        return;

      case GlobalUsersActionTypes.GLOBAL_USERS_SET_SHOWN:
        draft.pagination.startGlobalUser = action.payload.startGlobalUser;
        draft.pagination.endGlobalUser = action.payload.endGlobalUser;
        return;

      case GlobalUsersActionTypes.GLOBAL_USERS_SET_SEARCH:
        draft.search = action.payload;
        return;

      default:
        return state;
    }
  });
};

export default globalUsersReducer;
