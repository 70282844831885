import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => {
  const { palette } = theme;

  return {
    container: {
      display: 'flex',
    },
    label: {
      fontSize: '0.8125rem',
      fontWeight: 700,
      color: palette.grey['700'],
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      justifyContent: 'flex-start',
      width: '100%',
      '& svg': {
        marginLeft: 8,
      },
    },
    inputWrapper: {
      position: 'relative',
      display: 'flex',
      flexDirection: 'row',
      maxHeight: '43px',
      padding: '0 16px',
      border: '2px solid #E9E9EE',
      borderRadius: '10px',
      backgroundColor: '#fff',
      width: '100%',
      alignItems: 'center',
      '& input:disabled': { background: '#E9E9EE', cursor: 'not-allowed' },
      '& input[type="tooltip"]:disabled': { background: '#E9E9EE' },
      '& input[type="text"]:disabled': { background: '#E9E9EE' },
      '& input[type="number"]:disabled': { background: '#E9E9EE' },
      '& #password': {
        fontFamily: 'text-security-disc',
        '-webkit-text-security': 'disc',
      },
      '& input:-webkit-autofill': {
        '-webkit-box-shadow': '0 0 0 1000px white inset !important',
      },
    },
    labelBold: {
      cursor: 'pointer',
      padding: '0 0 6px 17px',
      lineHeight: '16px',
      fontSize: 13,
      fontWeight: 400,
      color: '#666',
    },
    disabledWrapper: { background: '#E9E9EE', cursor: 'not-allowed', color: '#212121' },
    tooltipDisabled: {
      display: 'flex',
      background: '#E9E9EE !important',
    },
    wrapperBorderNone: {
      border: 'none',
    },
    wrapperLabel: {
      margin: 'auto',
      marginLeft: '6px',
      fontWeight: 400,
      fontSize: 13,
      lineHeight: '16px',
      color: '#666666',
      maxWidth: 155,
    },
    input: {
      width: '100%',
      fontSize: '14px',
      height: 39,
      fontWeight: 400,
      padding: 0,
      color: '#212121',
      border: 'none',
      outline: 'none',
      backgroundColor: palette.background.paper,
      '&::placeholder': { fontWeight: 400, fontSize: '14px', lineHeight: '17px', color: '#8E909D' },
    },
    wrapperInput: {
      width: '56px',
      border: '2px solid #E9E9EE',
      borderRadius: '10px',
      padding: '0 16px',
      '-moz-appearance': 'textfield',
      '&::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
        margin: 0,
      },
      '&:focus': {
        border: '2px solid #0F65E5',
      },
    },
    number: {
      '-moz-appearance': 'textfield',
      '&:-webkit-outer-spin-button': {
        '-webkit-appearance': 'none',
        margin: 0,
      },
      '&:-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
        margin: 0,
      },
    },
    adornmentButton: {
      appearance: 'none',
      padding: '0',
      background: 'none',
      border: 'none',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: palette.background.paper,

      '& *': {
        pointerEvents: 'none',
      },
    },
    error: {
      fontSize: '13px',
      lineHeight: '13px',
      fontWeight: 400,
      color: palette.error.main,
      position: 'absolute',
      top: '46px',
      left: '16px',
      backgroundColor: 'transparent',
      padding: 0,
    },
    fullWidth: {
      width: '100%',
    },
    focused: {
      border: '2px solid #0F65E6',
      transition: 'all 0.4s',
    },
    focusedLabel: {
      color: palette.primary.main,
      '& span': {
        color: '#212121',
        transition: 'all 0.4s',
      },
    },
    inputTitle: {
      padding: '0 0 6px 17px',
      display: 'inline-block',
      color: '#666',
      fontWeight: 400,
      fontSize: '13px',
      lineHeight: '16px',
    },
    errorInputWrapper: {
      border: `2px solid ${palette.error.main}`,
    },
    noMargin: {
      '&:hover': {
        background: 'transparent',
      },
      '& svg': {
        margin: 0,
      },
    },
  };
});
