import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  littleFirstCellsContainer: {
    '& th:nth-child(-n+3)': {
      minWidth: 40,
      padding: '0 2px',
      '& button': {
        width: 36,
        height: 36,
      },
    },
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100vw',
    height: '100vh',
    backgroundColor: theme.palette.background.default,
    '& .overflow-table-wrapper': {
      overflow: 'auto',
      maxHeight: 'calc(100vh - 310px)',
      border: '1px solid #E9E9EE',
      borderRadius: 16,
      '&::-webkit-scrollbar': {
        borderLeft: '1px solid #e9e9ee',
      },
    },
    '& .overflow-hidden-wrapper': {
      overflow: 'hidden',
      borderRadius: 16,
    },
    '& .pagination': {
      backgroundColor: theme.palette.background.paper,
      borderRadius: 16,
      margin: '48px auto 24px',
      padding: '12px 32px',
      maxWidth: 1800,
      border: '1px solid #E9E9EE',
    },
    '& .empty': {
      width: '100% !important',
      maxWidth: '100% !important',
      borderRadius: '0 0 16px 16px',
    },
  },
  uploadButton: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    padding: '6px',

    '& svg': {
      width: 20,
      height: 20,
    },
  },
  fileInput: {
    opacity: 0,
    height: 36,
    width: 36,
    maxWidth: 36,
    zIndex: 3,
    position: 'absolute',
  },
  headerCellWrapper: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 0,
    margin: 0,
    minWidth: '11.5rem',
  },
  bigCellWrapper: {
    minWidth: '13rem',
  },
  headerCellLabel: {
    marginBottom: '0.4rem',
    fontSize: '0.9125rem',
  },
  th: {
    width: '12.5%',
    position: 'sticky',
    top: '0',
    background: theme.palette.background.paper,
    borderLeft: '1px solid #D4D2D2',
    fontWeight: 700,
    zIndex: 1,
    padding: 0,
    color: '#666666',
    '& p': {
      padding: '0.4rem 0 0 0',
      borderTop: '1px solid #D4D2D2',
      borderBottom: '1px solid #D4D2D2',
      height: '2.7rem',
    },
  },
  tableWrapper: {
    '& table': {
      position: 'relative',
      borderCollapse: 'collapse',
      width: '100%',
    },
    '& tr:first-child td': {
      borderTop: 0,
    },
    '& tr td:first-child': {
      borderLeft: 0,
      borderTop: 0,
    },
    '& tr td:last-child': {
      borderRight: 0,
    },
  },
  bodyRow: {
    '& td div': {
      height: '1.8rem',
    },
  },
  countSelect: {
    margin: '0 0.5rem ',

    '& label': {
      display: 'flex',
      flexDirection: 'row',
      margin: 0,
      alignItems: 'center',

      '& div': {
        marginLeft: '0.5rem',
      },
    },
  },
  shownItems: {
    display: 'inline',
    padding: '0.5rem 0.65rem',
    border: '1px solid gray',
    textAlign: 'center',
    backgroundColor: theme.palette.background.paper,
    fontSize: '0.8125rem',
    fontWeight: 400,
    minWidth: '3.5rem',
  },
  fixedPagination: {
    background: theme.palette.background.default,
    display: 'flex',
    alignItems: 'center',
    minHeight: '6.4rem',
    width: '100%',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      width: '100vw',
      overflow: 'auto',
      alignItems: 'center',
      justifyContent: 'flex-start',
    },
  },
  paginationWrapperInnerContent: {
    display: 'flex',
    alignItems: 'center',
    minWidth: '52rem',
    justifyContent: 'start',
  },
  sortButton: {
    width: '1rem',
    height: '40px',
    marginLeft: 'auto',
    padding: '0.1rem',
    alignSelf: 'flex-end',
    position: 'absolute',
    bottom: '0',
    right: '0.3rem',
    '& svg': {
      width: '100%',
      height: '100%',
    },
  },
  whiteSpace: {
    whiteSpace: 'nowrap',
  },
}));
