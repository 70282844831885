/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, ReactElement, Suspense, lazy, useMemo } from 'react';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { AppState } from 'store';
import App from 'components/App/App';
import Entry from 'pages/Entry';
import { getAccountSettingsRoutes, getRoutes, routeNames, routes } from './routes';
import { Permission, Role, RouteObj } from './types';
import { storage } from 'utils';
import Loader from 'components/UI/Loader';
import { AccountTypesEnum, UserRolesEnum } from 'utils/types';

const Page404 = lazy(() => import('components/Page404'));

/**
 * Router component
 * ================
 * Configures client-side routing
 */
export const Router: FC = (): ReactElement | null => {
  // Redux
  const user: any = useSelector((state: AppState) => state.session.user);
  const subscription = useSelector((state: AppState) => state.session.user?.subscription);
  const sessionLoading = useSelector((state: AppState) => state.session.loading);
  const subscriptionByOrgUser: any = useSelector((state: AppState) => state.customerParameters?.cardsInfo?.account);
  const togglesByOrgUser = subscriptionByOrgUser?.subscription?.toggles;
  /**
   * Renders App Entry screens for unauthenticated users
   */
  const renderEntry = (): ReactElement => (
    <Switch>
      <Route exact={true} path={['/sign-up', '/forgot-password', '/', '/set-password', '/set', '/email']}>
        <Entry />
      </Route>

      {routes.map((route: RouteObj) => {
        if (route.subRoutes !== undefined) {
          return route.subRoutes.map((subroute: RouteObj) => {
            return <Redirect key={subroute.to} from={subroute.to} to="/" />;
          });
        }
        return <Redirect key={route.to} from={route.to} to="/" />;
      })}
      <Suspense fallback={<></>}>
        <Route component={Page404} />
      </Suspense>
    </Switch>
  );

  const renderedRoutes = useMemo(() => {
    const isAdmin =
      (!!storage.getTokens() && storage.getRole() === 'e69ee3b1-0495-44ff-987b-4f27b01535cc') ||
      user?.role === UserRolesEnum.admin;
    const isCustomer =
      (!!storage.getTokens() && storage.getRole() === '364b431c-71b4-463f-bb52-213f2754db82') ||
      user?.role === UserRolesEnum.account;
    const isGlobalUser =
      (!!storage.getTokens() && storage.getRole() === '7b71386f-73b1-4789-89b9-ee5e09610a00') ||
      user?.role === UserRolesEnum.globalUser;
    const isViewUser =
      (!!storage.getTokens() && storage.getRole() === 'a6b71146-7a2f-11ea-bc55-0242ac130003') ||
      user?.role === UserRolesEnum.accountUser;
    const isOrganization = user?.role === UserRolesEnum.organization;
    const isOrganizationUser = user?.role === UserRolesEnum.organizationUser;
    const isMonitoringUser = user?.role === UserRolesEnum.monitoringUser;

    let role = '' as Role;

    if (isAdmin) {
      role = UserRolesEnum.admin;
    } else if (isCustomer) {
      role = UserRolesEnum.account;
    } else if (isGlobalUser) {
      role = UserRolesEnum.globalUser;
    } else if (isViewUser) {
      role = UserRolesEnum.accountUser;
    } else if (isOrganization) {
      role = UserRolesEnum.organization;
    } else if (isOrganizationUser) {
      role = UserRolesEnum.organizationUser;
    } else if (isMonitoringUser) {
      role = UserRolesEnum.monitoringUser;
    }

    const permissions = [] as Permission[];

    if (user?.writeAccess) permissions.push('writeAccess');
    if (user?.financialAccess) permissions.push('financialAccess');

    if (!role) {
      return renderEntry();
    }

    const roleWithOptions = { role, permissions };

    const routes = [
      ...getRoutes(roleWithOptions, {
        permissions: user?.permissions,
        productType: user?.productType,
        user,
        togglesByOrgUser,
      }),
    ];

    if (
      role === UserRolesEnum.accountUser ||
      role === UserRolesEnum.account ||
      role === UserRolesEnum.admin ||
      role === UserRolesEnum.organization ||
      role === UserRolesEnum.organizationUser
    ) {
      routes.push(
        ...getAccountSettingsRoutes(roleWithOptions, {
          permissions: user?.permissions,
          productType: user?.productType,
          user,
        }),
      );
    }

    const redirectMainPage = () => {
      if (isAdmin) {
        return <Redirect from="/" to="/alerts-map-dashboard" />;
      }

      const isOrgUserOrCustomerSubscriptionBlocked =
        (isOrganizationUser || isCustomer) &&
        user?.paymentStatusOrganization?.payment &&
        user?.paymentStatusOrganization?.payment === 'blocked';
      const isNotTrial =
        user?.paymentStatus?.status !== 'trial' &&
        user?.paymentStatus?.status !== 'trialEnded' &&
        user?.paymentStatusOrganization?.payment !== 'trial' &&
        user?.paymentStatusOrganization?.payment !== 'trialEnded';
      const isNotExistSubscription =
        !isOrganizationUser &&
        !isCustomer &&
        (!user?.subscription?.status ||
          user?.subscriptions?.status === 'empty' ||
          user?.paymentStatus?.status === 'blocked');

      const isServerSubscriptionActive = subscription?.toggles?.serversMonitoring;
      const isWorkflowMonitoringSubscriptionActive = subscription?.toggles?.workflowMonitoring;
      const isWorkflowReportsSubscriptionActive = subscription?.toggles?.workflowReports;
      const isServerActive = user?.productType?.companyProductType?.serversMonitoring;
      const isWorkflowMonitoringActive = user?.productType?.companyProductType?.workflowMonitoring;
      const isWorkflowReportsActive = user?.productType?.companyProductType?.workflowReports;
      const isServerGlobalActive = user?.productType?.serverProductType?.serversMonitoring;
      const isWorkflowMonitoringGlobalActive = user?.productType?.serverProductType?.workflowMonitoring;
      const isWorkflowReportsGlobalActive = user?.productType?.serverProductType?.workflowReports;

      if (isMonitoringUser && !isWorkflowReportsSubscriptionActive && !isWorkflowMonitoringSubscriptionActive) {
        return <Redirect from="/" to="/alerts-map-dashboard" />;
      }
      if (
        user?.accountType === AccountTypesEnum.selfServed &&
        isOrganization &&
        !(isWorkflowMonitoringActive && isWorkflowMonitoringGlobalActive) &&
        !(isWorkflowReportsActive && isWorkflowReportsGlobalActive) &&
        isServerGlobalActive &&
        isServerActive
      ) {
        return <Redirect from="/" to="/alerts-map-dashboard" />;
      }
      if (user?.accountType === AccountTypesEnum.selfServed) {
        const isWithoutOrganizationFirstLogin =
          !user?.isInOrganization &&
          !Object.values(user?.paymentStatus ?? {}).length &&
          !Object.values(user?.paymentStatusOrganization ?? {}).length;
        const isWithOrganizationFirstLogin =
          role === UserRolesEnum.organization &&
          user?.isInOrganization &&
          !Object.values(user?.paymentStatus ?? {}).length;
        if (
          role === UserRolesEnum.accountUser &&
          togglesByOrgUser?.serversMonitoring &&
          !togglesByOrgUser?.workflowMonitoring &&
          !togglesByOrgUser?.workflowReports
        ) {
          return <Redirect from="/" to="/servers-dashboard" />;
        }

        if (isWithoutOrganizationFirstLogin || isWithOrganizationFirstLogin) {
          return (
            <>
              <Redirect from="/" to="/product-settings" />
            </>
          );
        } else if (isNotTrial && (isNotExistSubscription || isOrgUserOrCustomerSubscriptionBlocked)) {
          return (
            <>
              <Redirect from="/" to="/dashboard" />
            </>
          );
        }

        if (
          isOrganizationUser &&
          togglesByOrgUser?.serversMonitoring &&
          (!togglesByOrgUser?.workflowMonitoring || !togglesByOrgUser?.workflowReports) &&
          !user?.permissions?.reports?.isEnabled
        ) {
          return <Redirect from="/" to="/contact-settings" />;
        }

        if (
          (isOrganizationUser &&
            togglesByOrgUser?.serversMonitoring &&
            (!togglesByOrgUser?.workflowMonitoring || !togglesByOrgUser?.workflowReports)) ||
          ((isCustomer || isViewUser) &&
            isServerSubscriptionActive &&
            (!isWorkflowMonitoringSubscriptionActive || !isWorkflowReportsSubscriptionActive))
        ) {
          return <Redirect from="/" to="/servers-dashboard" />;
        }

        if (
          (isOrganization || isOrganizationUser) &&
          isServerSubscriptionActive &&
          !isWorkflowReportsSubscriptionActive &&
          !isWorkflowMonitoringSubscriptionActive
        ) {
          return <Redirect from="/" to="/alerts-map-dashboard" />;
        }

        if (
          user?.isInOrganization &&
          !user?.permissions?.reports?.isEnabled &&
          (!isServerActive || !isServerGlobalActive || !togglesByOrgUser?.serversMonitoring)
        ) {
          return (
            <>
              <Redirect from="/" to={routeNames.contactSettings} />
            </>
          );
        }

        return <Redirect from="/" to="/dashboard" />;
      } else if (user?.accountType === AccountTypesEnum.managed) {
        if ((isOrganization || isOrganizationUser) && !user?.permissions?.reports?.isEnabled) {
          return <Redirect from="/" to="/system-users" />;
        }

        if (
          (isCustomer || isViewUser) &&
          isServerActive &&
          isServerGlobalActive &&
          (!(isWorkflowMonitoringActive && isWorkflowMonitoringGlobalActive) ||
            !(isWorkflowReportsActive && isWorkflowReportsGlobalActive))
        ) {
          return <Redirect from="/" to="/servers-dashboard" />;
        }
        return <Redirect from="/" to="/dashboard" />;
      }
    };

    return (
      <Suspense fallback={<></>}>
        <Switch>
          {routes.map(({ exact, to, getComponent, subRoutes }) => {
            if (subRoutes?.length) {
              return subRoutes.map((subRoute) => {
                const RouteComponent = subRoute?.getComponent?.(roleWithOptions);
                return (
                  <Route key={subRoute.to} exact={subRoute.exact} path={subRoute.to}>
                    {RouteComponent ? <RouteComponent /> : null}
                  </Route>
                );
              });
            }

            const RouteComponent = getComponent?.(roleWithOptions);
            return (
              <Route key={to} exact={exact} path={to}>
                {RouteComponent ? <RouteComponent /> : null}
              </Route>
            );
          })}

          {redirectMainPage()}
        </Switch>
      </Suspense>
    );
  }, [user, togglesByOrgUser]);

  /**
   * Renders page content depending on user's auth state
   */
  const renderRouter = (): ReactElement | ReactElement[] => {
    if (sessionLoading || (!user && storage.getTokens())) {
      return <Loader />;
    }
    return renderedRoutes;
  };

  return (
    <BrowserRouter>
      <App>{renderRouter()}</App>
    </BrowserRouter>
  );
};
