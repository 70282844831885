import { Customer } from 'store/adminDashboard/types';
import { ColumnsFilters, FindProp, SortBy } from 'store/serversAlert/types';

export interface Server {
  agentMonitorId: string;
  companyId: string[];
  id: string;
  alertWindow: number;
  okStatusTime: number;
  monitorId: string;
  name: string;
  cpuAlert: number;
  ramAlert: number;
  foldersAlert: any;
  drivesAlert: any;
  keepAliveTime: number;
  timeToNoKeepAlive: number;
  resourcesTime: number;
  isEnabled: boolean;
  isRegistered: boolean;
  data?: any;
}

export interface ServersMonitoringState {
  servers: Server[];
  find: FindProp[];
  columnsFilters: ColumnsFilters;
  sortBy: SortBy;
}

export interface DefaultServerMonitoring {
  email: string;
  name: string;
  writeAccess: boolean;
  dailyReportEmail: boolean;
  errorReportEmail: boolean;
  timeFormat: string;
  enterpriseName: string;
  enterpriseCustomers?: Customer[];
}

export interface ServerMonitoringCreate {
  enterpriseAdmin: Server;
  enterprise: {
    name: string;
  };
  enterpriseCustomers?: {
    toAddIds?: string[];
    toRemoveIds?: string[];
  };
}

export interface ServerMonitoringCreateMany {
  enterprises: ServerMonitoringCreate[];
}

export enum ServersMonitoringActionTypes {
  SERVERS_MONITORING_SET = 'SERVERS_MONITORING_SET',
  SERVERS_MONITORING_SET_SEARCH = 'SERVERS_MONITORING_SET_SEARCH',
  SERVERS_MONITORING_SET_FIELD = 'SERVERS_MONITORING_SET_FIELD',
  SERVERS_MONITORING_SET_FILTER_MULTI_SELECT = 'SERVERS_MONITORING_SET_FILTER_MULTI_SELECT',
  SERVERS_MONITORING_SET_FILTER_MULTI_SELECT_PREV = 'SERVERS_MONITORING_SET_FILTER_MULTI_SELECT_PREV',
  SERVERS_MONITORING_SET_FILTER_SEARCH = 'SERVERS_MONITORING_SET_FILTER_SEARCH',
  SERVERS_MONITORING_SET_FILTER_SEARCH_PREV = 'SERVERS_MONITORING_SET_FILTER_SEARCH_PREV',
  SERVERS_MONITORING_SET_FILTER_MULTI_SELECT_SHOWN = 'SERVERS_MONITORING_SET_FILTER_MULTI_SELECT_SHOWN',
  SERVERS_MONITORING_GET_TABLE_DATA = 'SERVERS_MONITORING_GET_TABLE_DATA',
  SERVERS_MONITORING_SET_SORT_BY = 'SERVERS_MONITORING_SET_SORT_BY',
  SERVERS_MONITORING_TOGGLE_SORT_BY = 'SERVERS_MONITORING_TOGGLE_SORT_BY',
}

export interface SMSet {
  type: ServersMonitoringActionTypes.SERVERS_MONITORING_SET;
  payload: Server[];
}

export interface SMSetSearch {
  type: ServersMonitoringActionTypes.SERVERS_MONITORING_SET_SEARCH;
  payload: string;
}

export interface SMSetField {
  type: ServersMonitoringActionTypes.SERVERS_MONITORING_SET_FIELD;
  payload: { id: string; data: any };
}

export interface SMSetFilterMultiSelect {
  type: ServersMonitoringActionTypes.SERVERS_MONITORING_SET_FILTER_MULTI_SELECT;
  payload: { columnName: string; selectedOptions: string[] };
}

export interface SMSetFilterMultiSelectPrev {
  type: ServersMonitoringActionTypes.SERVERS_MONITORING_SET_FILTER_MULTI_SELECT_PREV;
  payload: { columnName: string; selectedOptionsPrev: string[] };
}

export interface SMSetFilterSearch {
  type: ServersMonitoringActionTypes.SERVERS_MONITORING_SET_FILTER_SEARCH;
  payload: { columnName: string; searchString: string | undefined };
}

export interface SMSetFilterSearchPrev {
  type: ServersMonitoringActionTypes.SERVERS_MONITORING_SET_FILTER_SEARCH_PREV;
  payload: { columnName: string; searchStringPrev: string | undefined };
}

export interface SMSetFilterMultiSelectShown {
  type: ServersMonitoringActionTypes.SERVERS_MONITORING_SET_FILTER_MULTI_SELECT_SHOWN;
  payload: { columnName: string; selectedOptionsShown: string[] };
}

export interface SMGetTableData {
  type: ServersMonitoringActionTypes.SERVERS_MONITORING_GET_TABLE_DATA;
}

export interface SMToggleSortBy {
  type: ServersMonitoringActionTypes.SERVERS_MONITORING_TOGGLE_SORT_BY;
  payload: string;
}

export interface SMSetSortBy {
  type: ServersMonitoringActionTypes.SERVERS_MONITORING_SET_SORT_BY;
  payload: SortBy;
}

export type ServersMonitoringActions =
  | SMSet
  | SMSetSearch
  | SMSetField
  | SMSetFilterMultiSelect
  | SMSetFilterSearch
  | SMSetFilterMultiSelectPrev
  | SMSetFilterMultiSelectShown
  | SMSetFilterSearchPrev
  | SMGetTableData
  | SMToggleSortBy
  | SMSetSortBy;
