import { Reducer } from 'redux';
import { produce } from 'immer';
import { SUBUIActionTypes, SUBUIActions, SUBUIState } from './types';

export const initialState: SUBUIState = {
  workflowStepTypes: [
    { value: 'hotFolder', label: 'Hot Folder' },
    { value: 'proOut', label: 'ProOut' },
    { value: 'eco', label: 'ECO' },
    { value: 'rip', label: 'Form RIP' },
    { value: 'preflight', label: 'Preflight' },
    { value: 'impose', label: 'Impose' },
    { value: 'ctp', label: 'CTP' },
  ],
  workflowStepKeys: [],
  currentLang: {
    name: '',
    abbreviation: '',
    id: '',
    fileName: '',
  },
  langs: [],
  tableViewSettings: [],
  currentSettings: {
    logoUrl: '',
    backgroundUrl: '',
    brand: '',
    supportEmail: '',
  },
  allSettings: [],
  statusLocal: true,
};

const loadingReducer: Reducer<SUBUIState, SUBUIActions> = (state = initialState, action): SUBUIState => {
  return produce(state, (draft: SUBUIState) => {
    switch (action.type) {
      case SUBUIActionTypes.SUBUI_SET_WORKFLOW_STEP_TYPES:
        draft.workflowStepTypes = action.payload;
        return;
      case SUBUIActionTypes.SUBUI_SET_WORKFLOW_STEP_KEYS:
        draft.workflowStepKeys = action.payload;
        return;
      case SUBUIActionTypes.SUBUI_SET_CURRENT_LANG:
        draft.currentLang = action.payload;
        return;
      case SUBUIActionTypes.SUBUI_SET_LANGS:
        draft.langs = action.payload;
        return;
      case SUBUIActionTypes.SUBUI_SET_TABLE_VIEW:
        draft.tableViewSettings = action.payload;
        return;
      case SUBUIActionTypes.SUBUI_SET_CURRENT_APPEARANCE:
        draft.currentSettings = action.payload;
        return;
      case SUBUIActionTypes.SUBUI_SET_ALL_APPEARANCE:
        draft.allSettings = action.payload;
        return;
      case SUBUIActionTypes.SUBUI_SET_STATUS:
        draft.statusLocal = action.payload;
        return;
      default:
        return state;
    }
  });
};

export default loadingReducer;
