import React, { ChangeEvent, FC, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Select } from 'components/UI';
import { accountTypeOptions } from 'consts';
import { useStyles } from './styles';
import { useSelector } from 'react-redux';
import { AppState } from '../../store';
import { UserRolesEnum } from 'utils/types';

export type AccountTypeSelectProps = {
  value?: string;
  defaultValue?: number;
  label?: string;
  onChange: (value: string) => any;
  isHideAccountType?: boolean;
};

const AccountTypeSelect: FC<AccountTypeSelectProps> = ({ value, defaultValue, onChange, label }) => {
  const styles = useStyles();
  const { t } = useTranslation();

  const isAdmin: boolean = useSelector(
    (state: AppState) => !!state.session.user && state.session.user.role === UserRolesEnum.admin,
  );
  const isGlobalUser: boolean = useSelector(
    (state: AppState) => !!state.session.user && state.session.user.role === UserRolesEnum.globalUser,
  );

  const isStripeEnabledFromStore = useSelector((state: AppState) => state.ui.isStripeEnabled);
  const stripeKey: string | undefined = process.env.REACT_APP_STRIPE_PUBLIC_KEY;
  const isStripeEnabled = isStripeEnabledFromStore || (!!stripeKey && stripeKey.length > 0);

  const limitSelectLabel = useMemo(() => t(label || 'Account type'), [label, t]);

  const handleChange = useCallback((event: ChangeEvent<HTMLSelectElement>) => onChange(event.target.value), [onChange]);

  if (isStripeEnabled && (isAdmin || isGlobalUser)) {
    return (
      <Select
        labelKey={limitSelectLabel}
        className={styles.accountTypeSelect}
        options={accountTypeOptions.map((item) => ({ ...item, label: t(item.label) }))}
        defaultValue={defaultValue || 'all'}
        onChange={handleChange}
        {...(value ? { value } : {})}
        bordered
      />
    );
  }

  return <></>;
};

export default AccountTypeSelect;
