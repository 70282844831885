import React, { FC, useCallback, useMemo, useState } from 'react';
import { fixPagination } from 'utils';
import BasicPagination from './BasicPagination';
import PaginationLimitSelect from './PaginationLimitSelect';
import { useStyles } from './styles';
import { ChangeListener, Value } from './types';
import { getPaginationSetter } from './utils';
import { DEFAULT_PAGINATION_VALUE } from './constants';
import AccountTypeSelect, { AccountTypeSelectProps } from './AccountTypeSelect';

export type PaginationWithLimitSelectProps = {
  value?: Value;
  defaultValue?: Value;
  count: number;
  labels?: {
    limitSelectLabel?: string;
    itemCountLabel?: string;
  };
  onChange?: ChangeListener;
  accountTypeSelectProps: AccountTypeSelectProps;
  isHide?: {
    accountType?: boolean;
  };
};

const PaginationWithLimitSelect: FC<PaginationWithLimitSelectProps> = ({
  value,
  defaultValue,
  count = 0,
  onChange,
  labels,
  accountTypeSelectProps,
  isHide,
}) => {
  const styles = useStyles();
  const [innerValue, setInnerValue] = useState(null as unknown as Value);
  const selectedValue = useMemo(
    () => value || innerValue || defaultValue || DEFAULT_PAGINATION_VALUE,
    [defaultValue, innerValue, value],
  );

  const applyChanges: ChangeListener = useCallback(
    (newValue: Value) => {
      setInnerValue(newValue);
      if (onChange) {
        onChange(newValue);
      }
    },
    [onChange],
  );

  const setPagination = useMemo(() => getPaginationSetter(applyChanges, selectedValue), [applyChanges, selectedValue]);

  return (
    <div className={styles.paginationWrapperInnerContent}>
      <PaginationLimitSelect
        value={selectedValue.limit}
        count={count}
        label={labels?.limitSelectLabel}
        onChange={(selected): void => setPagination(fixPagination({ ...selectedValue, limit: selected }, count))}
      />
      <BasicPagination value={selectedValue} count={count} label={labels?.itemCountLabel} onChange={setPagination} />
      {!isHide?.accountType && <AccountTypeSelect {...accountTypeSelectProps} />}
    </div>
  );
};

export default PaginationWithLimitSelect;
