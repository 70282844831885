/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  ChangeEvent,
  FocusEvent,
  ForwardRefRenderFunction,
  ReactElement,
  forwardRef,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useStyles } from '../InputText/styles';
import { useTranslation } from 'react-i18next';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import { useSelector } from 'react-redux';
import { AppState } from 'store';
import cn from 'classnames';
interface Props {
  error?: any;
  setValue: any;
  name: string;
  secondValue: any;
  newCustomer: boolean;
  fullWidth?: boolean;
  className?: string;
  disabled?: boolean;
  required?: boolean;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => unknown;
  onBlur?: (event: FocusEvent) => unknown;
}

const SearchInput: ForwardRefRenderFunction<HTMLInputElement, Props> = (
  { error, setValue, name, secondValue, newCustomer, disabled, required, onBlur, fullWidth, className },
  ref,
): ReactElement => {
  // Translation
  const { t } = useTranslation();
  const [address, setAddress] = useState('');

  // Styling
  const styles = useStyles();
  const [wrapperClasses, setWrapperClasses] = useState(styles.inputWrapper);
  const [labelClasses, setLabelClasses] = useState(styles.label);
  // Ref
  const inputElement = useRef(ref || (null as any));
  const companyAddress = useSelector((state: AppState) => state.customerParameters.company.address);

  useEffect(() => {
    if (!newCustomer) {
      setAddress(companyAddress);
    } else {
      setAddress('');
    }
  }, [companyAddress]);

  const handleSelect = (address: any) => {
    setAddress(address);

    setValue('address', address);

    const arrayAddress = address.split(', ');

    setValue('country', arrayAddress[arrayAddress.length - 1]);

    geocodeByAddress(address)
      .then((results) => getLatLng(results[0]))
      .then((latLng) => {
        const { lat, lng } = latLng;
        async function getTimezone(lat: any, long: any) {
          const url =
            'https://maps.googleapis.com/maps/api/timezone/json?location=' +
            lat +
            ',' +
            long +
            '&timestamp=1331161200&key=AIzaSyCfGOpM_1y2MBx2jgoLATj2iSrcuTJMdg0';
          const response: any = await fetch(url);
          const data = await response.json();
          if (data) {
            setValue(secondValue, data.timeZoneId);
          }
        }
        getTimezone(lat, lng);
        setValue('coordinates', [lat, lng]);
      })
      .catch((error) => {
        setValue('coordinates', undefined);
        console.error('Error', error);
      });
  };
  // Focusing
  const handleFocus = (): void => {
    setWrapperClasses([styles.inputWrapper, styles.focused].join(' '));
    setLabelClasses([styles.label, styles.focusedLabel].join(' '));
  };
  const handleBlur = (event: FocusEvent): void => {
    onBlur?.(event);
    setWrapperClasses(styles.inputWrapper);
    setLabelClasses(styles.label);
    geocodeByAddress(address)
      .then((results) => getLatLng(results[0]))
      .then((latLng) => {
        const { lat, lng } = latLng;
        setValue('coordinates', [lat, lng]);
      })
      .catch((error) => {
        setValue('coordinates', undefined);
        console.error('Error', error);
      });
  };

  const renderInput = (): ReactElement => {
    return (
      <div className={wrapperClasses}>
        <PlacesAutocomplete
          value={address}
          onChange={setAddress}
          onSelect={handleSelect}
          onError={() => setValue('coordinates', undefined)}
        >
          {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
            <>
              <input
                {...getInputProps({
                  placeholder: 'Address',
                  className: styles.input,
                  onFocus: handleFocus,
                  onBlur: handleBlur,
                  name,
                  disabled,
                  required,
                  ref: (element: HTMLInputElement): void => {
                    if (ref && typeof ref === 'function') {
                      ref(element);
                    } else if (ref && ref.current) {
                      ref.current = element;
                    }
                    inputElement.current = element;
                  },
                })}
              />
              {!!error && <div className={styles.error}>{t(error)}</div>}
              <div className="autocomplete-dropdown-container">
                {loading && <div>{t('Loading...')}</div>}
                {suggestions.map((suggestion, index) => {
                  const className = suggestion.active ? 'suggestion-item--active' : 'suggestion-item';
                  // inline style for demonstration purpose
                  const style = suggestion.active
                    ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                    : { backgroundColor: '#ffffff', cursor: 'pointer' };
                  return (
                    // eslint-disable-next-line react/jsx-key
                    <div
                      {...getSuggestionItemProps(suggestion, {
                        className,
                        style,
                      })}
                      key={index}
                    >
                      <span>{suggestion.description}</span>
                    </div>
                  );
                })}
              </div>
            </>
          )}
        </PlacesAutocomplete>
      </div>
    );
  };
  return (
    <div className={cn(styles.container, { [styles.fullWidth]: fullWidth }, className)}>
      <label className={labelClasses}>
        {/* {t('Address')} */}
        {renderInput()}
      </label>
    </div>
  );
};

export default forwardRef(SearchInput);
