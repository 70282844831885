import React, { FC, ReactElement, useState } from 'react';
import { useStyles } from './styles';
import RDatePicker from 'react-datepicker';
import { useTranslation } from 'react-i18next';

interface Props {
  labelKey?: string;
  name?: string;
  className?: string;
  onChange: (date: Date, event: React.SyntheticEvent<any>) => void;
  selected?: Date;
  placeholder?: string;
}

const DatePicker: FC<Props> = ({ labelKey, name, className, onChange, selected, placeholder }: Props): ReactElement => {
  // Styling
  const styles = useStyles();

  const containerClasses = [styles.container, className].join(' ');
  const [labelClasses, setLabelClasses] = useState(styles.label);

  // Translation
  const { t } = useTranslation();

  // Focusing
  const handleFocus = (): void => {
    setLabelClasses([styles.label, styles.focusedLabel].join(' '));
  };

  const handleBlur = (): void => {
    setLabelClasses(styles.label);
  };

  const renderDataPicker = (): ReactElement => {
    return (
      <RDatePicker
        name={name}
        placeholderText={placeholder}
        selected={selected}
        onChange={onChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
        showPopperArrow={false}
        inline={true}
        dateFormat="dd/MM/yy"
        wrapperClassName={styles.rDatePicker}
        calendarClassName={styles.rDatePickerCalendar}
        fixedHeight
      />
    );
  };
  return (
    <div className={containerClasses}>
      {labelKey ? (
        <label className={labelClasses}>
          {t(labelKey)}
          {renderDataPicker()}
        </label>
      ) : (
        renderDataPicker()
      )}
    </div>
  );
};

export default DatePicker;
