import { Dispatch } from 'redux';
import {
  apiAdminDashboardSearch,
  apiGetADAvailableDates,
  apiGetAdminDashboard,
  apiGetEAAvailableDates,
  apiGetOrganizationDashboard,
  apiOrganizationDashboardSearch,
} from 'api';
import { uILoadingFinish, uILoadingStart } from 'store/ui/actions';
import { AdminDashboardActionTypes, AdminDashboardActions, AvailableDates, Customer, SortBy, Step } from './types';
import { showErrorNotification } from 'utils/notifications';
import { isNotNull } from '../../pages/BillingHistory';
import { AccountTypesEnum, UserRolesEnum } from 'utils/types';
import { getFromDate, getToDate } from 'components/XlsDownload/helpers';

const aDSetAvailableDates = (dates: AvailableDates): AdminDashboardActions => {
  return {
    type: AdminDashboardActionTypes.AD_SET_AVAILABLE_DATES,
    payload: dates,
  };
};

export const aDSetAccountType = (type: string): AdminDashboardActions => {
  return {
    type: AdminDashboardActionTypes.AD_SET_ACCOUNT_TYPE,
    payload: type,
  };
};

export const aDSetFilterFrom = (from: Date): AdminDashboardActions => {
  return {
    type: AdminDashboardActionTypes.AD_SET_FILTER_FROM,
    payload: from,
  };
};

export const aDSetFilterTo = (to: Date): AdminDashboardActions => {
  return {
    type: AdminDashboardActionTypes.AD_SET_FILTER_TO,
    payload: to,
  };
};

export const aDSetCustomers = (customers: Customer[]): AdminDashboardActions => {
  return {
    type: AdminDashboardActionTypes.AD_SET_CUSTOMERS,
    payload: customers,
  };
};

export const aDSetCustomersCount = (customersCount: number): AdminDashboardActions => {
  return {
    type: AdminDashboardActionTypes.AD_SET_CUSTOMERS_COUNT,
    payload: customersCount,
  };
};

export const aDSetCount = (count: number): AdminDashboardActions => {
  return {
    type: AdminDashboardActionTypes.AD_SET_COUNT,
    payload: count,
  };
};

export const aDSetPage = (page: number): AdminDashboardActions => {
  return {
    type: AdminDashboardActionTypes.AD_SET_PAGE,
    payload: page,
  };
};

export const aDSetShown = (startRecord: number, endRecord: number): AdminDashboardActions => {
  return {
    type: AdminDashboardActionTypes.AD_SET_SHOWN,
    payload: { startRecord, endRecord },
  };
};

export const aDSetSearch = (search: string): AdminDashboardActions => {
  return {
    type: AdminDashboardActionTypes.AD_SET_SEARCH,
    payload: search,
  };
};

export const aDSetCustomerId = (search: string): AdminDashboardActions => {
  return {
    type: AdminDashboardActionTypes.AD_SET_CUSTOMER_ID,
    payload: search,
  };
};

export const aDToggleSortBy = (columnName?: string): AdminDashboardActions => {
  return {
    type: AdminDashboardActionTypes.AD_TOGGLE_SORT_BY,
    payload: columnName ? columnName : UserRolesEnum.account,
  };
};

export const aDSetSortBy = (sortBy: SortBy): AdminDashboardActions => {
  return {
    type: AdminDashboardActionTypes.AD_SET_SORT_BY,
    payload: sortBy,
  };
};

export const getAdminDashboard = (isFirstPageLoad?: boolean): any => {
  return async (dispatch: any, getStore: any): Promise<void> => {
    dispatch(uILoadingStart());
    const { page: pageFromState, count: countFromState } = getStore().adminDashboard.pagination;
    const user = getStore().session.user;

    const isAdmin: boolean = user?.role === UserRolesEnum.admin;
    const isGlobalUser: boolean = user?.role === UserRolesEnum.globalUser;
    const isOrganization: boolean = user?.role === UserRolesEnum.organization;
    const isOrganizationUser: boolean = user?.role === UserRolesEnum.organizationUser;
    const isCustomerSession: boolean = user?.role === UserRolesEnum.account;
    const isViewUser: boolean = user?.role === UserRolesEnum.accountUser;

    const userSessionAccountType: string | undefined = user?.accountType;
    const isSelf = userSessionAccountType === AccountTypesEnum.selfServed;
    const isHideAccountTypeSelect = isSelf && (isOrganization || isOrganizationUser || isCustomerSession || isViewUser);

    const count =
      isFirstPageLoad && isNotNull(sessionStorage.getItem('admin-dashboard__count'))
        ? Number(sessionStorage.getItem('admin-dashboard__count'))
        : countFromState;
    const page =
      isFirstPageLoad && isNotNull(sessionStorage.getItem('admin-dashboard__page'))
        ? Number(sessionStorage.getItem('admin-dashboard__page'))
        : pageFromState;

    const dates = getStore().adminDashboard.filters;
    const from = getFromDate(dates.from);
    const to = getToDate(dates.to);
    const { search, sortBy, customerId } = getStore().adminDashboard;

    const role = getStore().session.user && getStore().session.user.role;
    const accountTypeFromState = getStore().adminDashboard.accountType;
    const accountType: string =
      isFirstPageLoad && isNotNull(sessionStorage.getItem('admin-dashboard__account-type'))
        ? (sessionStorage.getItem('admin-dashboard__account-type') as string)
        : isHideAccountTypeSelect
        ? AccountTypesEnum.selfServed
        : isAdmin || isGlobalUser
        ? accountTypeFromState
        : AccountTypesEnum.managed;

    const hideAccounts = !getStore().adminDashboard.monitoringAccounts;
    try {
      const hasSearchRequest =
        role === UserRolesEnum.organization || role === UserRolesEnum.organizationUser
          ? () =>
              apiOrganizationDashboardSearch({
                search,
                page,
                count,
                from,
                to,
                sortBy,
                customerId,
                accountType,
                hideAccounts,
              })
          : () =>
              apiAdminDashboardSearch({ search, page, count, sortBy, from, to, customerId, accountType, hideAccounts });

      const noSearchRequest =
        role === UserRolesEnum.organization || role === UserRolesEnum.organizationUser
          ? () => apiGetOrganizationDashboard({ page, count, from, to, sortBy, accountType, hideAccounts })
          : () => apiGetAdminDashboard({ page, count, from, to, sortBy, accountType, hideAccounts });
      const response = search || customerId ? await hasSearchRequest() : await noSearchRequest();
      if (response && response.data && response.data.dashboardCustomers) {
        const dashboardCustomers = response.data.dashboardCustomers;

        const customers = dashboardCustomers.customers.map((customer: any) => {
          return {
            ...customer,
            steps: customer.steps.map((step: Step) => {
              return {
                credit: Math.round(step.credit),
                monthlyUsed: Math.round(step.monthlyUsed),
                yearlyUsed: Math.round(step.yearlyUsed),
                overUse: Math.round(step.overUse),
                errorFiles: Math.round(step.errorFiles),
                name: step.name,
                balance: step.balance,
                creditBalance: step.creditBalance,
                paymentType: step.paymentType,
              };
            }),
          };
        });
        const startRecord = !!dashboardCustomers.customersCount ? page * count + 1 : 0;
        const endAvailableCustomer = (page + 1) * count;
        await dispatch(aDSetCustomers(customers));
        let endRecord = endAvailableCustomer;
        if (dashboardCustomers.customersCount < endAvailableCustomer) {
          endRecord = dashboardCustomers.customersCount;
        }
        await dispatch(aDSetCustomersCount(dashboardCustomers.customersCount));
        await dispatch(aDSetShown(startRecord, endRecord));
      }
    } catch (e) {
      showErrorNotification(e as any);
    } finally {
      dispatch(uILoadingFinish());
    }
  };
};

export const getADashboardAvailableDates = () => {
  return async (dispatch: Dispatch, getStore: () => Record<string, any>): Promise<any> => {
    dispatch(uILoadingStart());
    try {
      const role = getStore().session.user && getStore().session.user.role;
      const { data } =
        role === UserRolesEnum.organization || role === UserRolesEnum.organizationUser
          ? await apiGetEAAvailableDates()
          : await apiGetADAvailableDates();
      if (data) {
        dispatch(aDSetAvailableDates(data.dates));
        let year = new Date().getUTCFullYear();
        if (!data.dates.hasOwnProperty(year)) {
          if (Object.keys(data.dates)[0]) {
            year = +Object.keys(data.dates)[0];
          }
        }
        let month = new Date().getMonth() + 1;
        if (data.dates[year]) {
          if (!data.dates[year].includes(month)) {
            month = data.dates[year][0];
          }
        }
        await dispatch(getAdminDashboard(true));
      }
    } catch (e) {
      showErrorNotification(e as any);
    } finally {
      dispatch(uILoadingFinish());
    }
  };
};

export const aDSetMonitoringAccounts = (boolean: boolean): AdminDashboardActions => {
  return {
    type: AdminDashboardActionTypes.AD_SET_MONITORING_ACCOUNTS,
    payload: boolean,
  };
};

export const adResetState = (): { type: string } => ({ type: AdminDashboardActionTypes.AD_RESET_STATE });
