import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
  PageWithDrawer: (props: { hasInnerHeader: boolean }) => ({
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    minHeight: 'calc(100vh - 64px)',
    alignItems: 'center',
    overflow: 'auto',
    // backgroundColor: theme.palette.background.default,
    ...(props.hasInnerHeader ? { marginTop: 35 } : {}),
    '& .MuiDrawer-paper': {
      '&::-webkit-scrollbar': {
        width: 0,
        height: 0,
      },
    },
    '@media (max-width: 1400px)': {
      alignItems: 'flex-start',
    },
  }),
  innerHeader: {
    display: 'flex',
    width: '100%',
    height: '35px',
    minHeight: '35px',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '17px',
    fontWeight: 700,
    color: '#5A5A5A',
    background: '#ffffff',
    borderBottom: '1px solid #CCCCCC',
    position: 'fixed',
    top: '45px',
    left: 0,
    zIndex: 1,
    textTransform: 'capitalize',
  },
  button: {
    fontSize: '0.9rem',
    borderRadius: '0',
    fontWeight: 700,
  },
  buttonActive: {
    color: 'white',
    backgroundColor: '#70b603',
    fontSize: '0.9rem',
    fontWeight: 700,
    borderRadius: '0',
    '&:hover': {
      backgroundColor: '#8acf26',
    },
  },
  switcher: {
    background: 'white',
    borderRadius: '3px',
    position: 'absolute',
    transform: 'translate(360px,0%)',
  },
}));
