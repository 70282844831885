import { Reducer } from 'redux';
import { produce } from 'immer';

import { ServersDashboard, ServersDashboardActionTypes, ServersDashboardActions } from './types';

const initialState: ServersDashboard = {
  companies: [],
  charts: [],
};

const serversDashboardReducer: Reducer<ServersDashboard, ServersDashboardActions> = (
  state = initialState,
  action,
): ServersDashboard => {
  return produce(state, (draft: ServersDashboard) => {
    switch (action.type) {
      case ServersDashboardActionTypes.SD_SET_DATA:
        draft.companies = action.payload;
        return;

      case ServersDashboardActionTypes.SD_SET_CHART_LIST:
        draft.charts = action.payload;
        return;

      default:
        return state;
    }
  });
};

export default serversDashboardReducer;
