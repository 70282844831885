import { Reducer } from 'redux';
import { produce } from 'immer';

import {
  MonitoringUsersActionTypes,
  SystemUsersActionTypes,
  SystemUsersActions,
  SystemUsersCustomersActionTypes,
  SystemUsersState,
} from './types';
import { isNotNull } from '../../pages/BillingHistory';

const initialState: SystemUsersState = {
  recordsCount: 0,
  records: [],
  pagination: {
    page: 1,
    count: isNotNull(sessionStorage.getItem('system-users__count'))
      ? Number(sessionStorage.getItem('system-users__count'))
      : 25,
    startRecord: isNotNull(sessionStorage.getItem('system-users__page'))
      ? Number(sessionStorage.getItem('system-users__page'))
      : 1,
    endRecord: 10,
  },
  name: '',
  sortBy: { columnName: 'name', order: 'ASC' },
  accountType: 'all',
  customers: [],
  account: {
    customer: {
      name: '',
      timeZone: '',
      timeFormat: '',
      phoneNumber: '',
      language: '',
      country: '',
      isDeletedProtection: true,
      email: '',
      dailyReportEmail: '',
      errorReportEmail: '',
      startTimeSendingReport: '',
      endTimeSendingReport: '',
    },
    contract: {
      startDate: '',
      endDate: '',
    },
    company: {
      name: '',
      url: '',
      logo: '',
      autoLoginToken: '',
      address: '',
      coordinates: [''],
      customerId: '',
      isXMLKeepAliveEnabled: false,
      isSkipContractNotifications: true,
      productType: {
        workflowMonitoring: true,
        workflowReports: true,
        serversMonitoring: true,
      },
    },
  },
  monitoringUser: {
    list: [],
    listAll: [],
    user: {
      id: '',
      email: '',
      name: '',
      role: '',
      writeAccess: true,
      timeFormat: '',
      isCustomerOperator: true,
      isCustomerReport: true,
      status: false,
    },
  },
};

const SystemUsersReducer: Reducer<SystemUsersState, SystemUsersActions> = (
  state = initialState,
  action,
): SystemUsersState => {
  return produce(state, (draft: SystemUsersState) => {
    switch (action.type) {
      case MonitoringUsersActionTypes.SET_MONITORING_USERS:
        draft.monitoringUser.user = action.payload;
        return;

      case MonitoringUsersActionTypes.SET_MONITORING_USERS_LIST:
        draft.monitoringUser.list = action.payload;
        return;

      case MonitoringUsersActionTypes.SET_MONITORING_USERS_LIST_ALL:
        draft.monitoringUser.listAll = action.payload;
        return;

      case SystemUsersActionTypes.SU_SET_RECORDS:
        draft.records = action.payload;
        return;

      case SystemUsersCustomersActionTypes.SU_SET_ACCOUNT:
        draft.account = action.payload;
        return;

      case SystemUsersCustomersActionTypes.SU_SET_CUSTOMERS_LIST:
        draft.customers = action.payload.customers;
        return;
      case SystemUsersCustomersActionTypes.SU_SET_CUSTOMERS_RESET_LIST:
        draft.customers = [];
        return;

      case SystemUsersActionTypes.SU_SET_RECORDS_COUNT:
        draft.recordsCount = action.payload;
        return;

      case SystemUsersActionTypes.SU_SET_ACCOUNT_TYPE:
        draft.accountType = action.payload;
        return;

      case SystemUsersActionTypes.SU_SET_COUNT:
        draft.pagination.count = action.payload;
        draft.pagination.page = 1;
        return;

      case SystemUsersActionTypes.SU_SET_PAGE:
        draft.pagination.page = action.payload;
        return;

      case SystemUsersActionTypes.SU_SET_SHOWN:
        draft.pagination.startRecord = action.payload.startRecord;
        draft.pagination.endRecord = action.payload.endRecord;
        return;

      case SystemUsersActionTypes.SU_SET_SEARCH:
        draft.name = action.payload;
        return;

      case SystemUsersActionTypes.SU_TOGGLE_SORT_BY:
        if (state.sortBy.columnName === action.payload) {
          if (state.sortBy.order === 'ASC') {
            draft.sortBy.order = 'DESC';
          } else {
            draft.sortBy.order = 'ASC';
          }
        } else {
          draft.sortBy.columnName = action.payload;
          draft.sortBy.order = 'ASC';
        }
        return;

      case SystemUsersActionTypes.SU_SET_SORT_BY:
        draft.sortBy = action.payload;
        return;

      default:
        return state;
    }
  });
};

export default SystemUsersReducer;
