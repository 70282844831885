import { makeStyles, withStyles } from '@material-ui/core/styles';
import DateRangePicker from 'components/DateRangePicker';
import { Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  fixedPagination: (props?: { top?: number }): any => ({
    position: 'sticky',
    zIndex: 1,
    display: 'flex',
    alignItems: 'center',
    top: props?.top || 0,
    // height: '6.4rem',
    width: '100%',
    left: 0,
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      width: '100vw',
      overflow: 'auto',
      alignItems: 'center',
      justifyContent: 'flex-start',
    },
  }),
  paginationWrapperInnerContent: {
    display: 'flex',
    alignItems: 'center',
    minWidth: '100%',
    justifyContent: 'flex-start',
    gap: 28,
  },
  paginationWrapperInnerContentPadding: {
    padding: '20px 0',
  },
  flexboxContainer: {
    display: 'flex',
    alignItems: 'center',
    alignSelf: 'flex-end',
    height: 43,
  },
  infoBlockWrapper: {
    display: 'flex',
    alignItems: 'center',
    columnGap: '1ch',
  },
  countSelect: {
    margin: 0,
    minWidth: 140,
    '& label': {
      margin: 0,
      alignItems: 'center',
    },
  },
  accountTypeSelect: {
    margin: '0',
    whiteSpace: 'nowrap',
    '& label': {
      display: 'flex',
      flexDirection: 'column',
      margin: 0,

      '& select': {
        width: '140px',
      },
    },
  },
  shownItems: {
    minWidth: '40px',
    display: 'flex',
    flexDirection: 'column',
    padding: '0',
    margin: '0 8px',
    textAlign: 'center',
    fontSize: '13px',
    fontWeight: 700,
    whiteSpace: 'nowrap',
    color: '#212121',
  },
  recordsCount: {
    fontWeight: 500,
    fontSize: 11,
    lineHeight: '140%',
    color: '#8E909D',
    marginTop: 3,
  },
  whiteSpace: {
    whiteSpace: 'nowrap',
  },
  paginationButton: {
    padding: '0 4px',
    '&:hover': {
      background: 'none',
      '& svg rect': {
        fill: '#0A3677',
      },
    },
  },
  pagination: { display: 'flex', height: 43, alignItems: 'center', alignSelf: 'flex-end' },
}));

export const StyledDateRangePicker = withStyles({
  root: {
    gap: 0,
  },
  datePicker: {
    padding: '0',
  },
})(DateRangePicker);
