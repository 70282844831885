export const transformProps = {
  begin: '0',
  dur: '1s',
  attributeType: 'xml',
  attributeName: 'transform',
  type: 'translate',
  repeatCount: 'indefinite',
};

export const rectProps = { y: '15', width: '15', height: '15', rx: '15' };

export const circles = [
  { x: '0', fill: '#ff0100', values: '0 0; 0 15; 0 -15; 0 0; 0 0; 0 0; 0 0; 0 0' },
  { x: '30', fill: '#fbb03b', values: '0 0; 0 0; 0 15; 0 -15; 0 0; 0 0; 0 0; 0 0' },
  { x: '60', fill: '#0071bc', values: '0 0; 0 0; 0 0; 0 15; 0 -15; 0 0; 0 0; 0 0' },
  { x: '90', fill: '#662d91', values: '0 0; 0 0; 0 0; 0 0; 0 15; 0 -15; 0 0; 0 0' },
  { x: '120', fill: '#9e015d', values: '0 0; 0 0; 0 0; 0 0; 0 0; 0 15; 0 -15; 0 0' },
];
