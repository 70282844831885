import { Reducer } from 'redux';
import { produce } from 'immer';
import {
  AdminBillingSettingsActionTypes,
  AdminParametersActionTypes,
  AdminParametersActions,
  AdminParametersState,
} from './types';

export const defaultBillingSettings = {
  annualDiscount: 0,
  isTrialItemsUpdated: false,
  trialItemsAmount: { users: 0, accounts: 0, servers: 0, licences: 0, workflowSteps: 0 },
  dataSettings: { access: null, removal: null },
  itemsPrices: {
    Users: { upTo10: 0, upTo20: 0, upTo50: 0, upTo100: 0, moreThan100: 0, additionalUnit: 0 },
    Accounts: { upTo10: 0, upTo20: 0, upTo50: 0, upTo100: 0, moreThan100: 0, additionalUnit: 0 },
    Licences: { upTo10: 0, upTo20: 0, upTo50: 0, upTo100: 0, moreThan100: 0, additionalUnit: 0 },
    'Workflow Steps': { upTo10: 0, upTo20: 0, upTo50: 0, upTo100: 0, moreThan100: 0, additionalUnit: 0 },
    Servers: { upTo10: 0, upTo20: 0, upTo50: 0, upTo100: 0, moreThan100: 0, additionalUnit: 0 },
  },
};

const initialState: AdminParametersState = {
  billingSettings: defaultBillingSettings,
  isSomeCompaniesTogglesEnabled: {
    serversMonitoring: true,
    workflowMonitoring: true,
    workflowReports: true,
  },
  adminData: {
    admin: {
      email: '',
      name: '',
      phoneNumber: '',
      dailyReportEmail: '',
      timeFormat: '',
      isAdminSales: false,
      isAdminFinance: false,
      isErrorReportEmail: false,
      isSendHardwareErrorsMail: false,
      isSendSystemErrorsMail: false,
      isSendCompareVersions: false,
    },
    hotFolder: {
      hotFolderPath: '',
      hotFolderUserLogin: '',
      hotFolderUserPassword: '',
    },
    smtp: { smtpAddress: '', smtpUserName: '', smtpUserPassword: '' },
    map: {
      workingThreshold: 0,
      workingTimeout: 0,
      errorThreshold: 0,
      errorTimeout: 0,
      constantErrorThreshold: 0,
      timeToNoKeepAliveXML: 0,
      productType: {
        serversMonitoring: true,
        workflowMonitoring: true,
        workflowReports: true,
      },
      serverMonitoringConfigs: {
        okStatusTime: 0,
        keepAliveTime: 0,
        resourcesTime: 0,
        timeToNoKeepAlive: 0,
      },
    },
  },
};

export const adminParametersReducer: Reducer<AdminParametersState, AdminParametersActions> = (
  state = initialState,
  action,
): AdminParametersState => {
  return produce(state, (draft: AdminParametersState) => {
    switch (action.type) {
      case AdminParametersActionTypes.SET_ADMIN_DATA:
        draft.adminData = action.payload.adminData;
        draft.isSomeCompaniesTogglesEnabled = action.payload.isSomeCompaniesTogglesEnabled;
        return;
      case AdminBillingSettingsActionTypes.SET_ADMIN_BILLING_SETTINGS:
        draft.billingSettings = action.payload;
        return;

      default:
        return state;
    }
  });
};
